export interface BackofficeSection {
  name: string;
  url: string;
  role: string;
  icon: string;
}

export enum BackofficeRole {
  ANALYTICS = 'ANALYTICS',
  ANALYSIS = 'ANALYSIS',
  INSURANCE_COMISSIONS = 'INSURANCE_COMISSIONS',
  POLICIES = 'POLICIES',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  HOMEOWNER_MANAGER = 'HOMEOWNER_MANAGER',
  EXTERNAL_LAWYER = 'EXTERNAL_LAWYER',
  SINISTERS = 'SINISTERS'
}

export enum BackofficeDocumentPipeFunction {
  TYPE,
  VALIDATED
}
