import { AssetDto } from '@core/models';

export interface MoreCandidatureOptionsPopoverResponse {
  option: MoreCandidatureOptionEnum;
  newAsset?: AssetDto;
}

export enum MoreCandidatureOptionEnum {
  EDIT_CANDIDATURE = 'EditCandidature',
  MOVE_CANDIDATURE = 'MoveCandidature',
  RECALCULATE_SCORE = 'ReCalculateScore',
  NOTE_CANDIDATURE = 'NoteCandidature',
  APPOINTMENT = 'Appointment'
}
