/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @angular-eslint/component-class-suffix */
import {
  Component,
  Input,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { RequestAnalysisApiService } from '@core/api-services/request-analysis-api/request-analysis-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  GetInternalEventsFilter,
  GetRequestAnalysisDto,
  ReportInternalEventDto
} from '@core/models';
import { ModalController, ToastController } from '@ionic/angular';
import { first } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-internal-events',
  templateUrl: './internal-events.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InternalEventsComponent implements OnInit, OnChanges {
  @Input() candidatureId: string;
  @Input() requestAnalysisId: string;
  @Input() tenantId: string;
  @Input() loadEvents: ReportInternalEventDto[];
  @Input() modalMode: boolean;

  internalEvents: ReportInternalEventDto[] = [];
  showTextArea: boolean;
  description: string;

  constructor(
    private modalController: ModalController,
    private usersService: UsersApiService,
    private toastController: ToastController,
    private requestAnalysisService: RequestAnalysisApiService
  ) {}

  ngOnInit(): void {
    if (this.loadEvents) {
      this.internalEvents = this.loadEvents;
    } else {
      this.getInternalEventsByFilter();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requestAnalysisId) {
      this.requestAnalysisId = changes.requestAnalysisId.currentValue;
      this.getInternalEventsByFilter();
    }

    if (changes.tenantId) {
      this.tenantId = changes.tenantId.currentValue;
      this.getInternalEventsByFilter();
    }
  }

  getInternalEventsByFilter(): void {
    const requestFilter = new GetInternalEventsFilter();
    requestFilter.type = ['comment'];
    if (this.candidatureId) {
      requestFilter.candidatureId = this.candidatureId;
    }
    if (this.requestAnalysisId) {
      requestFilter.requestAnalysisId = this.requestAnalysisId;
    }
    if (this.tenantId) {
      requestFilter.tenantId = this.tenantId;
    }

    this.usersService
      .getInternalEventsByFilter(requestFilter)
      .pipe(first())
      .subscribe((events: ReportInternalEventDto[]) => {
        if (events) {
          this.internalEvents = events;
          this.internalEvents.forEach(
            (internalEvent: ReportInternalEventDto) => {
              if (internalEvent.requestAnalysisId) {
                this.requestAnalysisService
                  .getRequestAnalysisById(internalEvent.requestAnalysisId)
                  .pipe(first())
                  .subscribe((response: GetRequestAnalysisDto) => {
                    internalEvent.tenantName =
                      response.user.firstName + ' ' + response.user.surname1;
                  });
              }
            }
          );
        } else {
          this.internalEvents = [];
        }
      });
  }

  createNewComment(): void {
    const newEvent: ReportInternalEventDto = new ReportInternalEventDto();
    newEvent.type = 'comment';
    newEvent.candidatureId = this.candidatureId;
    newEvent.requestAnalysisId = this.requestAnalysisId;
    newEvent.tenantId = this.tenantId;
    newEvent.description = this.description;
    this.usersService
      .createEvent(newEvent)
      .pipe(first())
      .subscribe((updatedEvent: ReportInternalEventDto) => {
        this.internalEvents.push(updatedEvent);
        this.description = null;
        this.showTextArea = false;
      });
  }

  async presentToast(messages: any, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'success' : 'danger',
      duration: 5000
    });
    toast.present();
  }

  viewTextArea(): void {
    this.showTextArea = true;
  }

  hideTextArea(): void {
    this.showTextArea = false;
  }

  dismiss(): void {
    this.modalController.dismiss();
  }
}
