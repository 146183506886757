import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { DocumentDTO, FileDocumentDTO, MyCustomEvent } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';

import { BackofficeDocumentPipeFunction } from '../../models/backoffice.model';
import { DocumentModalComponent } from '../document-modal/document.modal.component';

@Component({
  selector: 'el-buen-inquilino-backoffice-user-document-item',
  templateUrl: './backoffice-user-document-item.component.html'
})
export class BackofficeUserDocumentItemComponent {
  @Input() document: DocumentDTO;

  documentPipeFunction = BackofficeDocumentPipeFunction;

  @Output() viewDocumentEmitter = new EventEmitter<boolean>();

  constructor(
    private loadingService: LoadingService,
    private docService: DocumentsApiService,
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  async viewDocument(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);

    this.viewDocumentEmitter.emit(true);

    this.docService
      .getFileByDocument(this.document.id)
      .pipe(first())
      .subscribe(async (res: FileDocumentDTO) => {
        const blob = await this.utilsService.UrlToBlob(res.url);
        this.loadViewDocumentModal(blob, this.document);
        await this.loadingService.dismissSecondLoader();
      });
  }

  downloadDocument(): void {
    this.docService
      .getFileByDocument(this.document.id)
      .pipe(first())
      .subscribe(async (res: FileDocumentDTO) => {
        const blob = await this.utilsService.UrlToBlob(res.url);
        this.utilsService.downloadPDF(
          `${this.document.name}.${this.document.extension}`,
          blob
        );
      });
  }

  verifyDocument(e: Event): void {
    const isChecked = (e as CustomEvent<MyCustomEvent>).detail.checked;

    this.document.validated = isChecked;

    this.docService.verifyDocument(this.document.id, isChecked).subscribe();
  }

  private async loadViewDocumentModal(
    responseBlob: Blob,
    document: DocumentDTO
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: DocumentModalComponent,
      cssClass: 'custom-modal-xl',
      componentProps: {
        pdfSrc: responseBlob,
        documentData: document,
        downloadable: true
      }
    });
    modal.onDidDismiss().then(() => {
      this.viewDocumentEmitter.emit(false);
    });
    return await modal.present();
  }
}
