import { Pipe, PipeTransform } from '@angular/core';
import { Incidence } from '@core/models';

@Pipe({
  name: 'incidenceTenant'
})
export class IncidenceTenantPipe implements PipeTransform {
  transform(incidence: Incidence): string {
    if (!incidence.userName && incidence.userId === 'salesforce') {
      return 'Salesforce';
    } else {
      return incidence.userName;
    }
  }
}
