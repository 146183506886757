/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  Auth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential
} from '@angular/fire/auth';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { DataLogin } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { WindowService } from '@core/services/window/window.service';
// TODO: import { IonIntlTelInputValidators } from '@crodriguezdominguez/ion-intl-tel-input';
import { IonItem, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';

import { PhoneRegisterComponent } from '../phone-register/phone-register.component';

const ENTER_CODE = 13;
@Component({
  selector: 'el-buen-inquilino-data-login',
  templateUrl: './data-login.component.html'
})
export class DataLoginComponent implements OnInit, AfterViewInit {
  @Input() homeownerLogin = false;
  @Output() login = new EventEmitter<DataLogin>();
  @Output() recoveryPassword = new EventEmitter<string>();
  @Output() register = new EventEmitter();

  loginForm: UntypedFormGroup;
  phoneForm: UntypedFormGroup;
  codeForm: UntypedFormGroup;
  windowRef: any;

  loginByPhone = false;
  isLoginByPhone = false;
  isLoginByEmail = true;

  validationErrorMessages = appValidators.validationErrorMessages;

  @ViewChild('phoneItem') phoneItem: IonItem;
  @ViewChild('phoneInput') phoneInput: any;

  constructor(
    private navigationService: NavigationService,
    private snackBarService: SnackBarService,
    private modalController: ModalController,
    private win: WindowService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private toastController: ToastController,
    private loadingService: LoadingService,
    private usersService: UsersApiService,
    private translateService: TranslateService,
    private auth: Auth,
    private utilsService: UtilsService
  ) {
    // Descomentar si queremos logarnos por redirección
    // this.verifyUserRedirect();
  }

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'sign-in-button',
      {
        size: 'invisible'
      }
    );
    this.createLoginForm();
    this.createPhoneForm();
    this.createCodeForm();
  }

  ngAfterViewInit(): void {
    if (this.phoneItem && this.phoneInput) {
      this.phoneForm.get('phone').markAsPristine();
      this.phoneInput['el'].nativeElement.classList.remove(
        'ion-invalid',
        'ion-dirty',
        'ng-dirty',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.remove('ion-invalid', 'ion-dirty');
    }
  }

  createLoginForm(): void {
    this.loginForm = this.fb.group({
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, appValidators.emailValidator])
      ),
      password: new UntypedFormControl(
        '',
        Validators.compose([Validators.maxLength(55), Validators.required])
      )
    });
  }

  createPhoneForm(): void {
    this.phoneForm = this.fb.group({
      phone: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required
          // TODO: IonIntlTelInputValidators.phone
        ])
      )
    });
  }

  checkPhoneInputStatus(codeSelect: boolean = false): void {
    const input = this.phoneForm.get('phone');

    if (input.invalid && input.errors && (input.dirty || codeSelect)) {
      this.phoneInput['el'].nativeElement.classList.add(
        'ion-invalid',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.add('ion-invalid');
    } else {
      this.phoneInput['el'].nativeElement.classList.remove(
        'ion-invalid',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.remove('ion-invalid');
    }
  }

  createCodeForm(): void {
    this.codeForm = this.fb.group({
      code: new UntypedFormControl(
        '',
        Validators.compose([Validators.required])
      )
    });
  }

  showCodeField(): void {
    // Si se ha introducido un teléfono válido, se muestra el campo de código
    const validatePhone = this.utilsService.formatPhone(
      this.phoneForm.value.phone
    );
    if (validatePhone) {
      this.checkExistingPhone();
      return;
    }

    // Marcamos los campos como dirty para que muestre los errores no visibles
    Object.values(this.phoneForm.controls).forEach(
      (control: UntypedFormControl) => {
        control.markAsDirty();
        control.markAsTouched();
      }
    );

    this.checkPhoneInputStatus(true);

    // Si llegamos hasta esta línea de código, es que no han introducido nada válido
    this.presentToast(
      this.translateService.instant('pages.login.data.login.phone_not_valid'),
      false
    );
  }

  choosePhone(): void {
    this.loginByPhone = true;
    this.isLoginByPhone = false;
    this.isLoginByEmail = false;
  }

  chooseEmail(): void {
    this.loginByPhone = false;
    this.isLoginByPhone = false;
    this.isLoginByEmail = true;
  }

  chooseGmail(): void {
    this.authService.gmailLogin();
  }

  // Descomentar si queremos logarnos por redirección
  /*async verifyUserRedirect(): Promise<void> {
    await firebase.auth().getRedirectResult().then(auth => {
        // user property exists; this was a redirect
        if (auth.user) {
          this.authService.processAlternativeTokenLogin(auth, 'gmail');
        }
    }, (error) => {
        console.log('error');
        console.log(error);
    });
  }*/

  goBackPhoneNumber(): void {
    this.loginByPhone = true;
    this.isLoginByPhone = false;
  }

  checkExistingPhone(): void {
    // Endpoint para verificar si algún usuario tiene asociado un teléfono
    this.usersService
      .checkExistingPhone(
        this.utilsService.formatPhone(this.phoneForm.value.phone)
      )
      .subscribe((res) => {
        if (res.found) {
          // Si existe en la colección user mostramos el paso de verificar código en pantalla
          this.verifyPhone();
        } else {
          this.presentToast(
            this.translateService.instant(
              'pages.login.data.login.phone_not_register'
            ),
            false
          );
        }
      });
  }

  verifyPhone(): void {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const formatedPhoneNumber = this.utilsService.formatPhone(
      this.phoneForm.value.phone
    );
    this.isLoginByPhone = true;
    this.authService
      .phoneLogin(formatedPhoneNumber, appVerifier)
      .then((res) => {
        this.windowRef.confirmationResult = res;
        this.presentToast(
          this.translateService.instant('pages.login.data.login.code_sent'),
          true
        );
      })
      .catch((err) => {
        this.loadingService.dismissSecondLoader();
        this.isLoginByPhone = false;
        if (err?.code === 'auth/too-many-requests') {
          this.presentToast(
            this.translateService.instant(
              'pages.login.data.login.too_many_failures'
            ),
            false
          );
        } else {
          this.presentToast(
            this.translateService.instant(
              'pages.login.data.login.phone_not_valid_2'
            ),
            false
          );
        }
      });
  }

  onKey(event: any, loginForm: UntypedFormGroup): void {
    if (loginForm.status === 'INVALID') return;
    if (event.keyCode === ENTER_CODE) {
      this.clickLogin();
    }
  }

  onKeyPhone(event: any, phoneForm: UntypedFormGroup): void {
    if (phoneForm.status === 'INVALID') return;
    if (event.keyCode === ENTER_CODE) {
      this.showCodeField();
    }
  }

  onKeyCode(event: any, codeForm: UntypedFormGroup): void {
    if (codeForm.status === 'INVALID') return;
    if (event.keyCode === ENTER_CODE) {
      this.verifyCode(codeForm);
    }
  }

  clickLogin(): void {
    if (this.loginForm.controls.email.errors) {
      this.presentToast(
        this.translateService.instant('pages.login.data.login.email_not_valid'),
        false
      );
      return;
    } else if (this.loginForm.controls.password.errors) {
      this.presentToast(
        this.translateService.instant(
          'pages.login.data.login.password_not_valid'
        ),
        false
      );
      return;
    }

    this.snackBarService.hide();
    if (this.loginForm.invalid) {
      this.presentToast(
        this.translateService.instant(
          'pages.login.data.login.fill_correct_input'
        ),
        false
      );
    } else {
      const dataLogin = new DataLogin(
        this.loginForm.value.email,
        this.loginForm.value.password
      );
      this.login.emit(dataLogin);
    }
  }

  clickRegister(): void {
    this.snackBarService.hide();
    this.register.emit(null);
  }

  clickRecoveryPassword(): void {
    if (!this.loginForm.value.email) {
      this.presentToast(
        this.translateService.instant(
          'pages.login.data.login.fill_correct_input'
        ),
        false
      );
    } else {
      this.recoveryPassword.emit(this.loginForm.value.email);
    }
  }

  back(): void {
    this.navigationService.goLanding();
  }

  async presentPhoneRegisterModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: PhoneRegisterComponent,
      componentProps: {
        modalMode: true,
        documentData: document
      },
      cssClass: 'auto-height modal-extend'
    });
    return await modal.present();
  }

  async presentToast(messages: any, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'success' : 'danger',
      duration: 5000
    });
    toast.present();
  }

  verifyCode(codeForm: UntypedFormGroup): void {
    this.loadingService.presentSecondLoader(null, true);
    const code: string = codeForm.value.code;
    const credentials = PhoneAuthProvider.credential(
      this.windowRef.confirmationResult.verificationId,
      code
    );
    signInWithCredential(this.auth, credentials)
      .then((p) => {
        this.loadingService.dismissSecondLoader();
        this.authService.processAlternativeTokenLogin(p, 'phone');
      })
      .catch(() => {
        this.loadingService.dismissSecondLoader();
        this.presentToast(
          this.translateService.instant(
            'pages.login.data.login.incorrect_code'
          ),
          false
        );
      });
  }
}
