/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import {
  AssetCreateCandidatureTenantDto,
  AssetDto,
  EmitterUserEmailInvitation,
  NotificationDto,
  NotificationTypeEnum,
  UserRequestCreateCandidatureDto
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-add-candiature',
  templateUrl: './add.candidature.modal.html'
})
export class AddCandidatureModalComponent {
  @Input() asset: AssetDto;
  userRequestCreateCandidatureDto = new UserRequestCreateCandidatureDto();
  saving = false;
  emailIsValid = true;
  cancelAlertClicked = false;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private candidatureService: CandidaturesApiService,
    private alertController: AlertController,
    private authenticationService: AuthService,
    private notificationsService: NotificationsApiService
  ) {}

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  async preAddCandidature(): Promise<void> {
    if (!this.emailIsValid) {
      const alert = await this.alertController.create({
        message: this.translateService.instant(
          'components.add-candidature_modal.email_problem'
        ),
        buttons: [
          {
            text: this.translateService.instant(
              'components.add-candidature_modal.cancel'
            ),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (): void => {
              // Limpiamos los campos
              this.cancelAlertClicked = true;
            }
          },
          {
            text: this.translateService.instant(
              'components.add-candidature_modal.continue'
            ),
            handler: (): void => {
              this.addCandidature();
            }
          }
        ]
      });

      await alert.present();
    } else {
      this.addCandidature();
    }
  }

  addCandidature(): void {
    this.saving = true;
    this.candidatureService
      .homeOwnerRegisterCandidatureForAllSelected(
        this.asset.id,
        this.userRequestCreateCandidatureDto
      )
      .pipe(first())
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (data: any) => {
          // Se crean las notificaciones de candidatura
          this.createNotifications(
            data.id,
            this.asset,
            this.userRequestCreateCandidatureDto
          );
          this.saving = false;
          this.dismiss(true);
          this.presentToast(true);
        },
        error: (err) => {
          if (err.status === 409) {
            this.presentToast(
              false,
              this.translateService.instant(
                'components.add-candidature_modal.tenant_in_other_candidature'
              )
            );
          } else {
            this.presentToast(false);
          }
          this.saving = false;
        }
      });
  }

  selectedUser(e: EmitterUserEmailInvitation): void {
    if (!e) {
      e = {
        user: null,
        emailIsValid: true
      };
    }
    this.userRequestCreateCandidatureDto.user = e.user;
    this.emailIsValid = e.emailIsValid;
    this.checkCandidature();
  }

  checkCandidature(): boolean {
    if (this.userRequestCreateCandidatureDto.user) {
      if (
        this.userRequestCreateCandidatureDto.user.email.length > 5 &&
        this.userRequestCreateCandidatureDto.user.email.includes('@')
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async presentToast(ok: boolean, message?: string): Promise<void> {
    let messageKey = ok
      ? this.translateService.instant(
          'components.add-candidature_modal.candidature_created'
        )
      : this.translateService.instant(
          'components.add-candidature_modal.candidature_fail_created'
        );
    const colorKey = ok ? 'success' : 'danger';
    if (message) {
      messageKey = message;
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  private createNotifications(
    candidatureId: string,
    asset: AssetCreateCandidatureTenantDto,
    userRequestCreateCandidatureDto: UserRequestCreateCandidatureDto
  ): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.CANDIDATURE;
    notification.candidatureId = candidatureId;
    notification.address = `${asset?.street ? asset.street : ''} ${
      asset?.number ? asset.number : ''
    }, ${asset?.town} (${asset?.province})`;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    notification.recipientEmail = userRequestCreateCandidatureDto.user.email;
    if (!!asset.vppModality) {
      notification.vpp = true;
    }
    this.notificationsService.newNotification(notification).subscribe();
  }
}
