import { AlertController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ButtonsRole } from '@shared/models/button.model';

export function showRejectCandidatureAlert(
  alertController: AlertController,
  message: string,
  cancelBtnTxt: string,
  rejectBtnTxt: string
): Promise<boolean> {
  return new Promise(async (resolve: (value: boolean) => void) => {
    const alert = await alertController.create({
      message,
      backdropDismiss: false,
      cssClass: 'reject-candidature-alert',
      buttons: [
        {
          text: cancelBtnTxt,
          role: ButtonsRole.CANCEL,
          cssClass: 'reject-candidature-alert-cancel'
        },
        {
          text: rejectBtnTxt,
          role: ButtonsRole.REJECT,
          cssClass: 'reject-candidature-alert-reject'
        }
      ]
    });

    alert.onWillDismiss().then((resp: OverlayEventDetail) => {
      const { role } = resp;

      if (role === ButtonsRole.REJECT) {
        resolve(true);
      } else {
        resolve(false);
      }
    });

    alert.present();
  });
}
