import { Injectable } from '@angular/core';
import { MaskitoOptions } from '@maskito/core';
import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import metadata from 'libphonenumber-js/min/metadata';
import { CountryCode } from 'libphonenumber-js/types.d';

@Injectable()
export class PhoneMaskService {
  private phoneUtil: PhoneNumberUtil;

  constructor() {
    this.phoneUtil = PhoneNumberUtil.getInstance();
  }

  getPhoneMaskOptions(countryIsoCode: CountryCode): Required<MaskitoOptions> {
    return maskitoPhoneOptionsGenerator({
      countryIsoCode,
      metadata,
      separator: ' '
    });
  }

  getPhonePlaceholder(countryIsoCode: string): string {
    const exampleNumber = this.phoneUtil.getExampleNumber(countryIsoCode);
    return this.phoneUtil.format(
      exampleNumber,
      PhoneNumberFormat.INTERNATIONAL
    );
  }

  formatPhone(number: string, countryIsoCode: string): string {
    const parsedNumber = this.phoneUtil.parse(number, countryIsoCode);
    return this.phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  }

  isValidPhoneNumberWithIsoCode(
    phoneNumber: string,
    countryIsoCode: string
  ): boolean {
    try {
      const parsedNumber = this.phoneUtil.parse(phoneNumber, countryIsoCode);
      return this.phoneUtil.isValidNumberForRegion(
        parsedNumber,
        countryIsoCode
      );
    } catch (error) {
      return false;
    }
  }

  getPhoneCountry(phone: string): CountryCode {
    try {
      const numero = this.phoneUtil.parse(phone, 'ES');
      // Obtener el código de país ISO
      return this.phoneUtil.getRegionCodeForNumber(numero);
    } catch (error) {
      return 'ES';
    }
  }
}
