/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import { PolicyIssue, Sinister, User } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { CancelPolicyModalComponent } from '../cancel-policy-modal/cancel-policy-modal.component';
import { CreateSinesterModalComponent } from '../create-sinester-modal/create-sinester-modal.component';
import { InsuranceOptionsPopoverComponent } from '../insurance-options-popover/insurance-options-popover.component';

@Component({
  selector: 'el-buen-inquilino-insurances-list',
  templateUrl: './insurances-list.component.html'
})
export class InsurancesListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() originalInsurances: PolicyIssue[] = [];
  @Input() isActivitySinisters = false;
  user: User;
  insurances: PolicyIssue[] = [];
  items: PolicyIssue[] = [];
  portal: string;
  floor: string;
  door: string;
  searchInternalCode = '';

  width: number;
  widthChangeSub: Subscription;

  searchbarValue: string = null;

  unpaidInsurances: PolicyIssue[] = [];
  tenantInsurances: PolicyIssue[] = [];
  tab = 1;

  constructor(
    private windowMeasuresService: WindowMeasuresService,
    private authenticationService: AuthService,
    private candidaturesService: CandidaturesApiService,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private insuranceService: InsurancesApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.user = this.authenticationService.user;
    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => {
        this.width = width;
      });
  }

  setLists(): void {
    this.unpaidInsurances = this.insurances.filter(
      (i: PolicyIssue) => !i.type || i.type === 'DEFAULT'
    );
    this.tenantInsurances = this.insurances.filter(
      (i: PolicyIssue) => i.type === 'TENANT_RC'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['originalInsurances']) {
      this.insurances = changes['originalInsurances']
        .currentValue as PolicyIssue[];
      this.filterList();
      this.getAddressFilters();
    }
  }

  filterList(): void {
    this.setLists();

    let array: PolicyIssue[] = [];

    if (this.tab === 1) {
      array = this.unpaidInsurances;
    } else {
      array = this.tenantInsurances;
    }

    array = this.insuranceService.filterPolicies(
      array,
      null,
      this.portal,
      this.floor,
      this.door,
      this.searchInternalCode,
      this.searchbarValue || null
    );

    if (this.tab === 1) {
      this.unpaidInsurances = array;
    } else {
      this.tenantInsurances = array;
    }
  }

  changeSearchbar(e: string): void {
    this.searchbarValue = e?.toLowerCase() || null;

    this.filterList();
  }

  filterByAddress(e: { portal: string; floor: string; door: string }): void {
    this.portal = e.portal;
    this.floor = e.floor;
    this.door = e.door;

    this.filterList();
  }

  clearFilters(): void {
    this.insuranceService.clearPoliciesFilter.emit();
  }

  async presentUtilsOptionPopover(
    ev: Event,
    insurance: PolicyIssue,
    unpaid: boolean = false
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: InsuranceOptionsPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        unpaid
      }
    });
    await popover.present();

    const { data } = await popover.onWillDismiss();
    if (data) {
      switch (data.option) {
        case 'SEE_DETAILS':
          this.seeInsuranceDetails(insurance);
          break;
        case 'CREATE_SINISTER':
          this.createSinister(insurance);
          break;
        case 'POLICY_CANCELATION':
          this.cancelPolicy(insurance);
      }
    }
  }

  async seeInsuranceDetails(insurance: PolicyIssue): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateSinesterModalComponent,
      backdropDismiss: false,
      componentProps: {
        insurance,
        seeDetails: true,
        user: this.user
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      this.candidaturesService
        .createSinister(insurance.id, data.sinister as Sinister)
        .pipe(first())
        .subscribe({
          next: () => this.getInsurances(),
          error: (error: string) => console.log('ERROR EN CREACION', error)
        });
    }
  }

  async createSinister(insurance: PolicyIssue): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateSinesterModalComponent,
      backdropDismiss: false,
      componentProps: {
        insurance,
        user: this.user
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      this.candidaturesService
        .createSinister(insurance.id, data.sinister as Sinister)
        .pipe(first())
        .subscribe(
          () => this.getInsurances(),
          (error: string) => console.log('ERROR EN CREACION', error)
        );
    }
  }

  async cancelPolicy(insurance: PolicyIssue): Promise<void> {
    const modal = await this.modalController.create({
      component: CancelPolicyModalComponent,
      backdropDismiss: false,
      componentProps: {
        insurance,
        user: this.user
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      this.candidaturesService
        .createSinister(insurance.id, data.sinister as Sinister)
        .pipe(first())
        .subscribe(
          () => this.getInsurances(),
          (error: string) => console.log('ERROR EN CREACION', error)
        );
    }
  }

  getInsurances(): void {
    let call: Observable<PolicyIssue[]>;
    if (this.user.portfolioId) {
      call = this.candidaturesService.getContractedPoliciesResume(
        this.user.portfolioId
      );
    } else if (this.user.apiRol && this.user.apiRol === 'API_ADMIN') {
      call = this.candidaturesService.getContractedPoliciesResume(
        null,
        this.user.apiId
      );
    } else {
      call = this.candidaturesService.getContractedPoliciesResume(null, null);
    }

    call.pipe(first()).subscribe((resp: PolicyIssue[]) => {
      this.originalInsurances = resp;
      this.insurances = resp;
      this.setLists();
      this.filterList();
    });
  }

  getResolvedSinisters(sinisters: Sinister[]): number {
    return (
      sinisters?.filter((s: Sinister) => s.endDate && s.isCancellation)
        ?.length || 0
    );
  }

  getSinisterWithoutClosing(sinisters: Sinister[]): number {
    return (
      sinisters.filter((s: Sinister) => !s.endDate && !s.isCancellation)
        ?.length || 0
    );
  }

  getCancellationRequest(sinisters: Sinister[]): number {
    return (
      sinisters.filter((s: Sinister) => s.isCancellation && !s.endDate)
        ?.length || 0
    );
  }

  getPolicies(date?: string): void {
    this.insuranceService.getPoliciesEmitter.emit(date || null);
  }

  async searchByPolicyId(policyId: string): Promise<void> {
    await this.loadingService.presentLoading('Cargando pólizas...');

    this.candidaturesService
      .getContractedPoliciesByPolicyNumber(policyId)
      .pipe(first())
      .subscribe({
        next: async (policies: PolicyIssue[]) => {
          if (policies.length === 1 && policies[0] === null) {
            policies = [];
          }

          if (this.tab === 1) {
            this.unpaidInsurances = policies;
          } else {
            this.tenantInsurances = policies;
          }
          await this.loadingService.dismissLoading();
        },
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  getAddressFilters(): void {
    if (this.tab === 1) {
      this.items = this.unpaidInsurances;
    } else {
      this.items = this.tenantInsurances;
    }
  }

  ngOnDestroy(): void {
    this.widthChangeSub?.unsubscribe();
  }
}
