/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import {
  Candidature,
  NotificationDto,
  NotificationTypeEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-add-tenant-insurance',
  templateUrl: './add-tenant-insurance.component.html'
})
export class AddTenantInsuranceComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() totalAnnualReceipt: number;

  user: User;
  form: UntypedFormGroup;

  isDisabledPolicyButton = false;

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthService,
    private notificationsService: NotificationsApiService,
    private candidatureService: CandidaturesApiService
  ) {}

  ngOnInit(): void {
    this.user = this.authenticationService.user;
    this.loadForm();
  }

  loadForm(): void {
    this.form = new UntypedFormGroup({
      addressInsured: new UntypedFormControl(this.calculateAdress()),
      cityInsured: new UntypedFormControl(this.candidature.asset.town),
      postalCodeInsured: new UntypedFormControl(
        this.candidature.asset.postalCode
      ),
      assetRent: new UntypedFormControl(this.candidature.asset.rentalPrice)
    });
  }

  calculateAdress(): string {
    const emptySpace = ' ';
    return (
      (this.candidature.asset.street
        ? this.candidature.asset.street + emptySpace
        : '') +
      (this.candidature.asset.number
        ? this.candidature.asset.number + emptySpace
        : '') +
      (this.candidature.asset.portal
        ? this.candidature.asset.portal + emptySpace
        : '') +
      (this.candidature.asset.floor
        ? this.candidature.asset.floor + emptySpace
        : '') +
      (this.candidature.asset.door ? this.candidature.asset.door : '')
    );
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  showIPID(): void {
    this.seePDF('../../../assets/pdfs/arag/IPID.pdf');
  }

  showGeneralConditions(): void {
    this.seePDF('../../../assets/pdfs/arag/Condiciones_Generales.pdf');
  }

  checkCreateInsurance(): boolean {
    return this.form?.valid;
  }

  createInsurance(): void {
    this.isDisabledPolicyButton = true;
    this.createNotifications();
    this.updateDraftFlagCandidature();
    this.dismiss(true);
  }

  private seePDF(url: string): void {
    window.open(url, '_blank');
  }

  private createNotifications(): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.INSURANCE_RC;
    notification.candidatureId = this.candidature.id;
    notification.address = `${this.candidature.asset.street} ${this.candidature.asset.number}, ${this.candidature.asset.town} (${this.candidature.asset.province})`;
    notification.senderEmail = this.user.email;
    notification.senderFirstname = this.user.firstname;
    notification.senderSurname1 = this.user.surname1;
    notification.senderSurname2 = this.user.surname2;
    this.candidature.tenantCandidatureList?.forEach((userCandidature) => {
      notification.recipientEmail = userCandidature.user.email;
      notification.recipientFirstname = userCandidature.user.firstname;
      notification.recipientSurname1 = userCandidature.user.surname1;
      notification.recipientSurname2 = userCandidature.user.surname2;
      this.notificationsService
        .newNotification(notification)
        .subscribe(() => null);
    });
  }

  private updateDraftFlagCandidature(): void {
    this.candidatureService
      .updateDraftFlagCandidature(this.candidature?.id)
      .subscribe();
  }
}
