import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsurancesApiService } from '@app/modules/core/api-services/insurances-api/insurances-api.service';
import { UtilsService } from '@app/modules/core/services/utils/utils.service';
import { ButtonsRole } from '@app/modules/shared/models/button.model';
import { ModalController } from '@ionic/angular';

import { InsuranceTypeModalForm } from '../../../insurance/models/insurance-page.model';

@Component({
  selector: 'el-buen-inquilino-upload-retool-template-modal',
  templateUrl: './upload-retool-template-modal.component.html'
})
export class UploadRetoolTemplateModalComponent implements OnInit {
  form: FormGroup<InsuranceTypeModalForm>;

  get fileControl(): FormControl<File> {
    return this.form.controls.file;
  }

  get notesPlaceholder(): string {
    return 'Aporta información extra que quieras que tengamos en cuenta';
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private insuranceService: InsurancesApiService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL, saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    }, role);
  }

  fileChange(file: File): void {
    if (file) {
      this.fileControl.setValue(file);
      this.fileControl.setErrors(null);
    } else {
      this.fileControl.setValue(null);
      this.fileControl.setErrors({ required: true });
    }

    this.fileControl.markAsTouched();
    this.fileControl.markAsDirty();
  }

  save(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    this.insuranceService
      .sendTemplate(this.form.value.file, this.form.value.notes)
      .subscribe(() => {
        this.dismiss(ButtonsRole.ACCEPT, true);
      });
  }

  private setForm(): void {
    this.form = new FormGroup<InsuranceTypeModalForm>({
      file: new FormControl<File>(null, Validators.required),
      notes: new FormControl<string>(null)
    });
  }
}
