/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { Candidature, User } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-edit-candidature',
  templateUrl: './edit-candidature.html'
})
export class EditCandidatureComponent implements OnInit {
  // Si se recibe es que es una modificación
  @Input() updatedCandidature: Candidature;

  //Si se trata de reforzar candidatura
  @Input() reinforce: boolean;

  title = 'Editar candidatura';
  // Manejadores de sección
  viewTenantsSection = true;
  viewConfirmSection = false;

  // Usuario autenticado
  user: User;

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {
    this.setUser();
    if (this.reinforce) {
      this.title = 'Reforzar candidatura';
    }
  }

  dismiss(saving?): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  confirmCertification(event: User[]): void {
    this.updatedCandidature.tenantCandidatureList = [];
    event.forEach((tenant) => {
      this.updatedCandidature.tenantCandidatureList.push({ user: tenant });
    });
    this.viewTenantsSection = false;
    this.viewConfirmSection = true;
  }

  closeModal(event: boolean): void {
    this.dismiss(event);
  }

  resetCertification(): void {
    this.viewConfirmSection = false;
  }

  private setUser(): void {
    this.user = this.authenticationService.user;
  }
}
