/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Pipe, PipeTransform } from '@angular/core';

import {
  BankAccountValidation,
  BankAccountValidationStatus
} from '../models/bbva-table.model';

@Pipe({
  name: 'bbvaAccountStatus'
})
export class BbvaAccountStatusPipe implements PipeTransform {
  transform(item: BankAccountValidation, functionNumber: 1 | 2): string {
    if (functionNumber === 1) {
      return this.chipColor(item);
    } else {
      return this.chipText(item);
    }
  }
  private chipColor(item: BankAccountValidation): string {
    switch (item.response) {
      case BankAccountValidationStatus.CONFIRMED:
        return 'success';
      case BankAccountValidationStatus.REJECTED:
        return 'danger';
      case BankAccountValidationStatus.PENDING:
        return 'secondary';
    }
  }
  private chipText(item: BankAccountValidation): string {
    switch (item.response) {
      case BankAccountValidationStatus.CONFIRMED:
        return 'Validado - Correcto';
      case BankAccountValidationStatus.REJECTED:
        return 'Titular incorrecto - Revisar DNI/NIE';
      case BankAccountValidationStatus.PENDING:
        return 'En estudio';
    }
  }
}
