/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CandidaturesApiService } from '../../api-services/candidatures-api/candidatures-api.service';
import {
  CandidatureDto,
  ScoreResponseDto,
  TrialCandidatureRequestDto,
  ValidateUserDto
} from '../../models';

@Injectable()
export class TrialFlowService {
  MAXIMUN_TENANT_ON_CANDIDATURE = 8;
  invalidTenant = false;
  invalidGuarantor = false;
  invalidOwner = false;
  private pCreateCandidatureAsset = new TrialCandidatureRequestDto();
  private createCandidatureAssetDtoSubject$ =
    new BehaviorSubject<TrialCandidatureRequestDto>(
      new TrialCandidatureRequestDto()
    );
  createCandidatureAssetDto$ =
    this.createCandidatureAssetDtoSubject$.asObservable();

  constructor(private _candidatureService: CandidaturesApiService) {}

  get createCandidatureAsset(): TrialCandidatureRequestDto {
    return this.pCreateCandidatureAsset;
  }
  set createCandidatureAsset(v: TrialCandidatureRequestDto) {
    this.pCreateCandidatureAsset = v;
    this.createCandidatureAssetDtoSubject$.next(v);
  }

  verifyTenantTrialFlow(request: ValidateUserDto): Promise<ScoreResponseDto> {
    return new Promise((resolve, reject) => {
      this._candidatureService.verifyTenantTrialFlow(request).subscribe(
        (res: ScoreResponseDto) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  createCandidatureTrialFlow(
    portfolioId: string,
    clientId?: string
  ): Promise<CandidatureDto> {
    if (portfolioId) {
      this.createCandidatureAsset.portfolioId = portfolioId;
    }
    return new Promise((resolve, reject) => {
      this._candidatureService
        .createCandidatureTrialFlow(this.createCandidatureAsset, clientId)
        .subscribe(
          (res: CandidatureDto) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
