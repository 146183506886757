/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'el-buen-inquilino-profile-preferences-content',
  templateUrl: './profile-preferences-content.component.html'
})
export class ProfilePreferencesContentComponent {
  @Input() elements = [];
  @Input() isEditable = false;
  @Output() changeElementStatus = new EventEmitter();
  @Input() isModal = false;

  emitEvent(ele, val): void {
    const data = {
      feature: ele,
      element: val
    };
    this.changeElementStatus.emit(data);
  }
}
