/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { presentToast } from '@shared/utils/toast.utils';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { CandidaturesApiService } from '../../api-services/candidatures-api/candidatures-api.service';
import { InsurancesCompanyApiService } from '../../api-services/insurances-company-api/insurances-company-api.service';
import { AuxiliaryFilter, Candidature, PolicyIssue } from '../../models';
import { LoadingService } from '../loading/loading.service';

@Injectable()
export class InsuranceUtilsService {
  policiesList: PolicyIssue[] = [];
  policies$: BehaviorSubject<PolicyIssue[]> = new BehaviorSubject([]);
  operationsList: Candidature[] = [];
  operations$: BehaviorSubject<Candidature[]> = new BehaviorSubject([]);
  auxiliariesFilterList: AuxiliaryFilter[] = [];
  auxiliariesFilter$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(
    private loadingService: LoadingService,
    private insuranceCompanyService: InsurancesCompanyApiService,
    private translateService: TranslateService,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    private translate: TranslateService
  ) {}

  loadMultipageData(code: string, page?: string): void {
    this.loadingService.presentSecondLoader(
      this.translateService.instant('pages.multipage.loading_data') as string,
      true
    );

    forkJoin({
      // Listado de pólizas
      policies: this.insuranceCompanyService.getInsurancesByCompanyCode(
        code,
        null,
        null
      ),
      // Listado de operaciones
      operations: this.insuranceCompanyService.getOperationsByCompanyCode(
        code,
        null,
        null
      )
    })
      .pipe(first())
      .subscribe({
        next: (resp) => {
          this.policiesList = resp.policies;
          this.policies$.next(this.policiesList);

          this.operationsList = resp.operations;
          this.operations$.next(this.operationsList);

          this.auxiliariesFilter(page);

          // Se pone un setTimeout para que se oculte el loader
          setTimeout(() => {
            this.loadingService.dismissSecondLoader();
          }, 500);
        },
        error: (err) => {
          console.error('Error en forkjoin', err);
          // Se pone un setTimeout para que se oculte el loader
          setTimeout(() => {
            this.loadingService.dismissSecondLoader();
            // this.presentToast('No hay pólizas que mostrar.', 'danger');
          }, 500);
        }
      });
  }

  /**
   * Filtrado de tablas en pantalla multipage
   * @param page 1 - Polizas | 2 - Operaciones
   * @param filter
   */
  filter(page: number, filter: string): any {
    let array = [];

    if (page === 1) {
      array = this.policiesList;
    } else {
      array = this.operationsList;
    }

    if (filter) {
      array = array.filter((item: any) => {
        if (page === 1) {
          if (
            item?.policyNumber
              ?.toLowerCase()
              .includes(filter.toLocaleLowerCase()) ||
            item?.firstName
              ?.toLowerCase()
              .includes(filter.toLocaleLowerCase()) ||
            item?.emailPolicyHolder
              ?.toLowerCase()
              .includes(filter.toLocaleLowerCase())
          ) {
            return item;
          }
        } else {
          if (
            item?.homeowner?.user?.firstname
              ?.toLowerCase()
              .includes(filter.toLocaleLowerCase()) ||
            item?.homeowner?.user?.email
              ?.toLowerCase()
              .includes(filter.toLocaleLowerCase()) ||
            item?.asset?.street
              ?.toLowerCase()
              .includes(filter.toLocaleLowerCase())
          ) {
            return item;
          }
        }
      });
      if (page === 1) {
        this.policies$.next(array);
      } else {
        this.operations$.next(array);
      }
    } else {
      if (page === 1) {
        this.policies$.next(this.policiesList);
      } else {
        this.operations$.next(this.operationsList);
      }
    }
  }

  /**
   * Rellena el componente de filtros de auxiliares
   * @param page
   */
  auxiliariesFilter(page: string): void {
    this.auxiliariesFilterList = [];
    let array = [];
    const pageNumber = page === 'policies-list' ? 1 : 2;
    // Pagina de polizas
    if (pageNumber === 1) {
      array = this.policiesList;
    } else {
      // Pagina de operaciones
      array = this.operationsList.map((p) => p.homeowner);
    }

    array.forEach((item) => {
      const document =
        pageNumber === 1 ? item.insuranceAsset.document : item.user.dni;
      const agentName =
        pageNumber === 1
          ? item.agentName
          : item.user.firstname + ' ' + item.user.surname1;
      const id = pageNumber === 1 ? item.userId : item.user.id;
      // Buscamos si el auxiliar está en el array
      const find = this.auxiliariesFilterList.find(
        (af: AuxiliaryFilter) => af.id === id
      );
      // Si no está, creamos una nueva entrada
      if (!find) {
        const name = pageNumber === 1 ? item.name : item.user.firstname;
        const surname1 = pageNumber === 1 ? item.surname : item.user.surname1;
        const surname2 = pageNumber === 1 ? item.surname2 : item.user.surname2;
        const newAuxiliaryFilter: AuxiliaryFilter = {
          id: id,
          name,
          surname1,
          surname2,
          documentNumber: document,
          timesAppears: 1,
          agentName
        };
        this.auxiliariesFilterList.push(newAuxiliaryFilter);
      } else {
        // Si está, sumamos uno a las veces que aparece
        find.timesAppears++;
      }
    });
    this.auxiliariesFilter$.next(this.auxiliariesFilterList);
  }

  filterByAuxiliaryFilter(page: string, auxiliary: AuxiliaryFilter): void {
    let array = [];
    if (page === 'policies-list') {
      if (!auxiliary) {
        array = this.policiesList;
      } else {
        array = this.policiesList.filter(
          (p: PolicyIssue) => p.userId === auxiliary.id
        );
      }
      this.policies$.next(array);
    } else {
      if (!auxiliary) {
        array = this.operationsList;
      } else {
        array = this.operationsList.filter(
          (a: Candidature) => a.homeowner?.user?.id === auxiliary.id
        );
      }
      this.operations$.next(array);
    }
  }

  /**
   * Resetea el valor de las listas para que no se quede desactualizada por filtros
   */
  resetLists(): void {
    this.policies$.next(this.policiesList);
    this.operations$.next(this.operationsList);
  }

  getPolicies(): Observable<PolicyIssue[]> {
    return this.policies$.asObservable();
  }

  getOperations(): Observable<Candidature[]> {
    return this.operations$.asObservable();
  }

  getAuxiliariesFilter(): Observable<any[]> {
    return this.auxiliariesFilter$.asObservable();
  }

  async downloadTenantPolicy(insurance: PolicyIssue): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .downloadTenantPolicy(insurance.policyNumber)
      .pipe(first())
      .subscribe({
        next: async (blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              this.translate.instant(
                'pages.profile.home-owner.assets.disable_popup_blocker'
              )
            );
          }
          await this.loadingService.dismissSecondLoader();
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
          presentToast(
            this.toastController,
            this.translate.instant(
              'components.add-arag-insurance.download_policy_fail'
            ) as string,
            'danger'
          );
        }
      });
  }
}
