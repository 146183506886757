import { Pipe, PipeTransform } from '@angular/core';
import { AssetDto, LabelDto } from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';

import { getAssetColor } from '../utils/asset.utils';

@Pipe({
  name: 'assetLabelChipStyle'
})
export class AssetLabelChipStylePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(asset: AssetDto, labels: LabelDto[]): unknown {
    return {
      background: getAssetColor(asset, true, labels, this.utilsService),
      color: getAssetColor(asset, false, labels, this.utilsService)
    };
  }
}
