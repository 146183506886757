import { Pipe, PipeTransform } from '@angular/core';
import { AssetDto } from '@core/models';
import { TranslateService } from '@ngx-translate/core';

import { AssetAddressReturnType } from '../models/asset-pipes.model';

@Pipe({
  name: 'assetAddress'
})
export class AssetAddressPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    asset: AssetDto,
    returnType: AssetAddressReturnType
  ): string | boolean {
    if (!!asset) {
      switch (returnType) {
        case AssetAddressReturnType.BUILDING:
          return this.getBuildingAssetAddress(asset);
        case AssetAddressReturnType.ASSET:
          return this.getAssetAddress(asset);
        case AssetAddressReturnType.ASSET_BUILDING:
          return this.getAssetBuildingAddress(asset);
        case AssetAddressReturnType.SHOW_ASSET_BUILDING_BADGE:
          return this.showAssetBuildingBadge(asset);
        case AssetAddressReturnType.CUSTOMER_CARE:
          return this.getCustomerCareAddress(asset);
        case AssetAddressReturnType.COMPLETE_ADDRESS:
          return this.getCompleteAddress(asset);
      }
    } else {
      return '';
    }
  }

  private getCustomerCareAddress(asset: AssetDto): string {
    let address = `${asset.street} ${asset.number}, ${asset.postalCode}`;
    address = `${address} - ${asset.town} (${asset.province})`;
    return address;
  }

  private getBuildingAssetAddress(asset: AssetDto): string {
    return this.getBuildingAddress(asset, ' | ');
  }

  private getAssetAddress(asset: AssetDto): string {
    const street = !!asset.street ? asset.street : 'Calle sin nombre';
    const number = !!asset.number ? asset.number : 'S/N';

    const address: string[] = [street, number, asset.town];

    return address.join(', ');
  }

  private getAssetBuildingAddress(asset: AssetDto): string {
    return this.getBuildingAddress(asset);
  }

  private getBuildingAddress(asset: AssetDto, separator?: string): string {
    const address: string[] = [];

    if (asset.portal) {
      address.push(
        this.translate.instant('pages.profile.home-owner.assets.portal', {
          portal: asset.portal
        }) as string
      );
    }

    if (asset.stairs) {
      address.push(
        this.translate.instant('pages.profile.home-owner.assets.stairs', {
          stairs: asset.stairs
        }) as string
      );
    }

    if (asset.floor) {
      address.push(
        this.translate.instant('pages.profile.home-owner.assets.floor', {
          floor: asset.floor
        }) as string
      );
    }

    if (asset.door) {
      address.push(
        this.translate.instant('pages.profile.home-owner.assets.door', {
          door: asset.door
        }) as string
      );
    }

    if (!!separator) {
      return address.join(separator);
    } else {
      return address.join(' ');
    }
  }

  private showAssetBuildingBadge(asset: AssetDto): boolean {
    return !!asset.portal || !!asset.stairs || !!asset.floor || !!asset.door;
  }

  private getCompleteAddress(asset: AssetDto): string | boolean {
    let address = `${asset.street}, ${asset.number}`;
    if (asset.portal) {
      address = `${address} ${asset.portal}`;
    }
    if (asset.floor) {
      address = `${address} ${asset.portal}`;
    }
    if (asset.door) {
      address = `${address} ${asset.portal}`;
    }
    address = `${address}, ${asset.postalCode} ${asset.town} (${asset.province})`;
    return address;
  }
}
