import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docChipIcon'
})
export class DocChipIconPipe implements PipeTransform {
  transform(value: unknown): 'checkmark-outline' | 'cloud-upload-outline' {
    return !!value ? 'checkmark-outline' : 'cloud-upload-outline';
  }
}
