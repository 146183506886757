/* eslint-disable @typescript-eslint/typedef */
import { Component } from '@angular/core';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-delete-account',
  templateUrl: './delete.account.modal.html'
})
export class DeleteAccountModalComponent {
  reasons: string;

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthService,
    private navigationService: NavigationService,
    private usersService: UsersApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {}

  dismiss(): void {
    this.modalController.dismiss();
  }

  async presentToast(messages: string, success: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: success ? 'success' : 'danger',
      duration: 2000
    });
    toast.present();
  }

  removeUser(): void {
    this.loadingService.presentSecondLoader(null, true);
    this.usersService
      .removeUser(this.authenticationService.user.id, this.reasons)
      .pipe(
        switchMap(() => {
          // Lanzamos la cloud function de aquí
          return this.usersService.deactivateUser(
            this.authenticationService.user.id
          );
        })
      )
      .subscribe({
        next: () => {
          this.loadingService.dismissSecondLoader();
          this.presentToast(
            this.translate.instant(
              'components.delete_account.success'
            ) as string,
            true
          );
          this.dismiss();
          this.navigationService.logout();
        },
        error: (err) => {
          this.loadingService.dismissSecondLoader();
          console.log(err);
          this.presentToast(
            this.translate.instant('components.delete_account.error') as string,
            false
          );
        }
      });
  }
}
