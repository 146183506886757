/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataLogin } from '@core/models';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

import { MessageBean } from '../../utils/message-bean.utils';

const ENTER_CODE = 13;

@Component({
  selector: 'el-buen-inquilino-custom-data-login',
  templateUrl: './custom-data-login.component.html'
})
export class CustomDataLoginComponent implements OnInit {
  @Input() img;

  loginValue: { email: string; password: string };

  @Output() login = new EventEmitter<DataLogin>();

  constructor(
    private translateService: TranslateService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.loginValue = { email: undefined, password: undefined };
  }

  onKey(event: any, loginForm: NgForm): void {
    if (event.keyCode === ENTER_CODE) {
      this.clickLogin(loginForm);
    }
  }

  clickLogin(loginForm: NgForm): void {
    this.snackBarService.hide();
    if (loginForm.invalid) {
      this.snackBarService.show(
        MessageBean.buildMessages(loginForm, this.translateService),
        'danger'
      );
    } else {
      const dataLogin = new DataLogin(
        this.loginValue.email,
        this.loginValue.password
      );
      this.login.emit(dataLogin);
    }
  }
}
