import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsurancesApiService } from '@app/modules/core/api-services/insurances-api/insurances-api.service';
import { Candidature, TenantCandidature } from '@app/modules/core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';

import {
  TenantOwnershipRequest,
  ValidateBankAccountModalForm
} from '../../models/bbva-table.model';
import { ButtonsRole } from '../../models/button.model';
import { presentToast } from '../../utils/toast.utils';

@Component({
  selector: 'el-buen-inquilino-validate-bank-account-modal',
  templateUrl: './validate-bank-account-modal.component.html'
})
export class ValidateBankAccountModalComponent implements OnInit {
  form: FormGroup<ValidateBankAccountModalForm>;
  validationErrorMessages = appValidators.validationErrorMessages;

  candidature: Candidature;
  tenantOptions: { id: string; fullName: string }[] = [];

  get accountOwnerControl(): FormControl<string> {
    return this.form.controls.accountOwner;
  }

  get cccControl(): FormControl<string> {
    return this.form.controls.ccc;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private loadingService: LoadingService,
    private insuranceService: InsurancesApiService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.setupTenantOptions();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL, data: unknown = null): void {
    this.modalController.dismiss(data, role);
  }

  async validateBankAccount(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    await this.loadingService.presentSecondLoader(null);
    const request: TenantOwnershipRequest = {
      candidatureId: this.candidature.id,
      userId: this.form.value.accountOwner,
      accountNumber: this.form.value.ccc
    };
    this.insuranceService.checkTenantOwnership(request).subscribe(
      () => {
        this.loadingService.dismissSecondLoader();
        this.dismiss(ButtonsRole.ACCEPT);
        presentToast(
          this.toastController,
          'El proceso de verificación se ha iniciado correctamente.',
          'success'
        );
      },
      () => {
        presentToast(
          this.toastController,
          'Error en la conexión con el servidor. Inténtalo más tarde.',
          'danger'
        );
        this.dismiss();
      }
    );
  }

  cccMaskChanges(event: Event): void {
    this.utilsService.cccMaskChanges(event, this.form, 'ccc');
  }

  private setForm(): void {
    this.form = new FormGroup({
      accountOwner: new FormControl<string>(null, Validators.required),
      ccc: new FormControl<string>(null, [
        Validators.required,
        Validators.maxLength(34),
        appValidators.bankAccountValidator
      ])
    });
  }

  private setupTenantOptions(): void {
    // Mapear las opciones para el ion-select
    this.tenantOptions =
      this.candidature?.tenantCandidatureList?.map(
        (tenant: TenantCandidature) => ({
          id: tenant.user.id,
          fullName: `${tenant.user.firstname} ${tenant.user.surname1}${
            tenant.user.surname2 ? ' ' + tenant.user.surname2 : ''
          }`
        })
      ) || [];
  }
}
