import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateGetTime'
})
export class DateGetTimePipe implements PipeTransform {
  transform(value: Date): number {
    return new Date(value).getTime();
  }
}
