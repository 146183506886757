import { Component, Input } from '@angular/core';

@Component({
  selector: 'el-buen-inquilino-no-items-message',
  templateUrl: './no-items-message.component.html'
})
export class NoItemsMessageComponent {
  @Input({ required: true }) message: string;
  @Input() noPadding = false;
}
