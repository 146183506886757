/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { ContactNewApiDto } from '@core/models';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

@Component({
  selector: 'el-buen-inquilino-contact-new-api',
  templateUrl: './contact-new-api.component.html'
})
export class ContactNewApiComponent implements OnInit {
  newApiInfo: string;
  newApiInfoForm: UntypedFormGroup;
  sending = false;
  validationErrorMessages = appValidators.validationErrorMessages;
  country: CountryCode = 'ES';

  get phoneControl(): FormControl<string> {
    return this.newApiInfoForm.controls.phone as FormControl<string>;
  }

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private assetService: AssetsApiService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.createNewApiInfoForm();
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  createNewApiInfoForm(): void {
    this.newApiInfoForm = this.fb.group({
      firstname: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(40),
          appValidators.namesValidator
        ])
      ),
      surname1: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          appValidators.namesValidator
        ])
      ),
      surname2: new UntypedFormControl(''),
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, appValidators.emailValidator])
      ),
      phone: new UntypedFormControl(
        '',
        Validators.compose(this.getPhoneControlValidators())
      ),
      postalCode: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          appValidators.postalCodeValidator,
          Validators.minLength(4),
          Validators.maxLength(5)
        ])
      ),
      newApiInfo: new UntypedFormControl('')
    });
  }

  getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  sendInfo(): void {
    if (this.newApiInfoForm.invalid) {
      // Marcamos los campos como dirty para que muestre los errores no visibles
      Object.values(this.newApiInfoForm.controls).forEach(
        (control: UntypedFormControl) => {
          control.markAsDirty();
          control.markAsTouched();
        }
      );
      this.presentToast(
        this.translateService.instant('pages.login.data.signin.empty_field'),
        'danger',
        2000
      );
      return;
    }
    this.sending = true;
    const contactNewApiDto: ContactNewApiDto = { ...this.newApiInfoForm.value };
    contactNewApiDto.phone = this.formatPhone(contactNewApiDto.phone);
    this.assetService.notifyCCToContactAddNewApi(contactNewApiDto).subscribe({
      next: () => {
        this.presentToast(
          `Se ha enviado la solicitud correctamente.`,
          'success',
          3000
        );
        this.sending = false;
        this.dismiss();
      },
      error: () => {
        this.sending = false;
        this.presentToast(
          `No se ha podido enviar la solicitud.`,
          'danger',
          3000
        );
      }
    });
  }

  async presentToast(
    msg: string,
    toastcolor: string,
    duration: number = 2000
  ): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  private formatPhone(phone: any): string {
    return this.utilsService.formatPhone(phone);
  }
}
