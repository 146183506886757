/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import {
  AssetDto,
  Candidature,
  CandidatureDto,
  NotificationDto,
  NotificationTypeEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-confirm-section',
  templateUrl: './confirm-section.component.html'
})
export class ConfirmSectionComponent {
  @Input() user: User;
  @Input() candidatureToUpdate: Candidature;
  @Input() reinforce: boolean;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  confirmationButtonDisabled = false;

  constructor(
    private toastController: ToastController,
    private translateService: TranslateService,
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService,
    private authenticationService: AuthService,
    private notificationsService: NotificationsApiService
  ) {}

  modifyCandidature(): void {
    const tenantList = this.candidatureToUpdate.tenantCandidatureList.map(
      (tenant) => tenant.user
    );
    this.confirmationButtonDisabled = true;
    this.loadingService.presentSecondLoader('Procesando operación...');
    if (this.reinforce) {
      this.reinforceCandidature(tenantList);
    } else {
      this.editCandidature(tenantList);
    }
  }

  async presentToast(ok: boolean, message?: string): Promise<void> {
    let messageKey = ok
      ? 'Operación realizada correctamente'
      : 'Hemos tenido algún problema al intentar realizar la operación.';
    const colorKey = ok ? 'success' : 'danger';
    if (message) {
      messageKey = message;
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  private reinforceCandidature(tenantList: User[]): void {
    this.candidatureService
      .reinforceCandidature(
        this.candidatureToUpdate.asset.id,
        this.candidatureToUpdate.id,
        tenantList
      )
      .subscribe(
        (newCandidature: CandidatureDto) => {
          this.presentToast(true);
          // Se notificará sólo a los usuarios recien agregados a la candidatura
          if (!this.user.trialVersion) {
            const userToNotify: User[] = tenantList.filter(
              (tenant) => !tenant.unremovable
            );
            this.createNotifications(
              newCandidature.id,
              newCandidature.asset,
              userToNotify
            );
          }
          this.loadingService.dismissSecondLoader();

          this.closeModal.emit(true);
        },
        () => {
          this.confirmationButtonDisabled = false;
          this.loadingService.dismissSecondLoader();
          this.presentToast(false);
          this.closeModal.emit(false);
        }
      );
  }

  private editCandidature(tenantList: User[]): void {
    this.candidatureService
      .editCandidature(
        this.candidatureToUpdate.asset.id,
        this.candidatureToUpdate.id,
        tenantList
      )
      .subscribe(
        () => {
          this.presentToast(true);
          // Se notificará sólo a los usuarios recien agregados a la candidatura
          if (!this.user.trialVersion) {
            const userToNotify: User[] = tenantList.filter(
              (tenant) => !tenant.unremovable
            );
            this.createNotifications(
              this.candidatureToUpdate.id,
              this.candidatureToUpdate.asset,
              userToNotify
            );
          }
          this.loadingService.dismissSecondLoader();
          this.closeModal.emit(true);
        },
        () => {
          this.confirmationButtonDisabled = false;
          this.loadingService.dismissSecondLoader();
          this.presentToast(false);
          this.closeModal.emit(false);
        }
      );
  }

  private createNotifications(
    candidatureId: string,
    asset: AssetDto,
    userToNotify: User[]
  ): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.CANDIDATURE;
    notification.candidatureId = candidatureId;
    notification.address = `${asset.street} ${asset.number}, ${asset.town} (${asset.province})`;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    if (!!asset.vppModality) {
      notification.vpp = true;
    }
    userToNotify?.forEach((user) => {
      notification.recipientEmail = user.email;
      //Si los usuarios agregados no tienen id, se les tiene que enviar un email para su registro
      if (!user.id) {
        const reSendEmailDto = { asset: asset, userTenant: user };
        this.candidatureService.reSendEmailToTenant(reSendEmailDto).subscribe();
      }
      //Se crea una notificación para que acepten la candidatura
      this.notificationsService.newNotification(notification).subscribe();
    });
  }
}
