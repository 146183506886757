export class AnalysisUser {
  analysisId: string;
  userId: string;
  endDate: Date;
  rentalPrice: number;

  constructor(
    analysisId: string,
    userId: string,
    endDate: Date,
    rentalPrice: number
  ) {
    this.analysisId = analysisId;
    this.userId = userId;
    this.endDate = endDate;
    this.rentalPrice = rentalPrice;
  }
}

export enum RecoveryMotivationTypeEnum {
  CLIENT = 'CLIENT',
  INTERNAL = 'INTERNAL'
}
