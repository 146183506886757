/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import {
  ApiDto,
  ApiMemberDto,
  BuildingDto,
  LabelDto,
  User
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';

import { ContactNewApiComponent } from '../contact-new-api/contact-new-api.component';

@Component({
  selector: 'el-buen-inquilino-add-building-modal',
  templateUrl: './add-building-modal.component.html'
})
export class AddBuildingModalComponent implements OnInit {
  @Input() user: User;
  @Input() apis: ApiDto[];
  apisSelected: string[] = [];
  @Input() building: BuildingDto;

  form: UntypedFormGroup;

  // Campo dirección autocompletar
  autocomplete: { input: string };
  autocompleteItems: any[];
  formVisible = false;
  newLabelName = '';
  selectedLabel: LabelDto;

  // Mapa
  latitude: number;
  longitude: number;
  coords: { lat: number; lng: number } = { lat: null, lng: null };

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      street: new UntypedFormControl(
        this.building?.street,
        Validators.required
      ),
      number: new UntypedFormControl(
        this.building?.number,
        Validators.required
      ),
      postalCode: new UntypedFormControl(
        this.building?.postalCode,
        Validators.required
      ),
      town: new UntypedFormControl(this.building?.town, Validators.required),
      province: new UntypedFormControl(
        this.building?.province,
        Validators.required
      ),
      internalCode: new UntypedFormControl(this.building?.internalCode)
    });
    if (this.building) {
      this.form.get('street').disable();
      this.form.get('number').disable();
      this.form.get('postalCode').disable();
      this.form.get('town').disable();
      this.form.get('province').disable();
      this.latitude = this.building?.location?.latitude;
      this.longitude = this.building?.location?.longitude;
      this.apisSelected = this.building?.apisId || [];
      this.formVisible = this.building ? true : false;
      this.coords = { lat: this.latitude, lng: this.longitude };
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  updateAddress(event): void {
    if (event && event.address_components.length > 0) {
      // De esta forma evitamos el catalán y demás purria
      this.form.get('street').setValue(event.name);
      if (event.geometry.location !== null) {
        this.latitude = event.geometry.location.lat();
        this.longitude = event.geometry.location.lng();
      }
      event.address_components.forEach((component) => {
        if (component.types.includes('route')) {
          this.form.get('street').setValue(component.long_name);
        }
        if (component.types.includes('street_number')) {
          this.form.get('number').setValue(component.long_name);
        }
        if (component.types.includes('locality')) {
          this.form.get('town').setValue(component.long_name);
          this.form.get('town').disable();
        }
        if (component.types.includes('administrative_area_level_2')) {
          this.form.get('province').setValue(component.long_name);
          this.form.get('province').disable();
        }
        if (component.types.includes('postal_code')) {
          this.form.get('postalCode').setValue(component.long_name);
        }
      });
    }
  }

  continue(): void {
    this.formVisible = true;
  }

  saveNewBuilding(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const adminSelected: ApiMemberDto[] = [];
    if (this.apisSelected?.length > 0) {
      this.apisSelected.forEach((apiId) => {
        const details = this.apis.find((a) => a.id === apiId)?.adminDetails;
        if (details) {
          adminSelected.push(details);
        }
      });
    }

    const newBuilding: BuildingDto = {
      ...this.form.getRawValue(),
      apisId: this.apisSelected?.length > 0 ? this.apisSelected : null,
      location: {
        latitude: this.latitude,
        longitude: this.longitude
      },
      portfolioId: this.user.portfolioId,
      portfolioOwnerId: this.user.id,
      creationDate: new Date(),
      adminDetailsSelected: adminSelected?.length > 0 ? adminSelected : null
    };
    if (this.building) {
      newBuilding.id = this.building.id;
    }
    this.modalController.dismiss({ newBuilding });
  }

  selectApi(apiId: string): void {
    if (!this.building) {
      // Si no está presente "this.building" (no edit), se puede agregar o eliminar el "apiId"
      // Si no la incluye lo añadimos
      if (!this.apisSelected.includes(apiId)) {
        this.apisSelected.push(apiId);
      } else {
        // Si la incluye, la eliminamos de la lista
        const index = this.apisSelected.findIndex((api) => api === apiId);
        this.apisSelected.splice(index, 1);
      }
    } else {
      // Si "this.building" (edit) está presente, no se permite eliminar "apiId"
      if (!this.apisSelected.includes(apiId)) {
        this.apisSelected.push(apiId);
      }
    }
  }

  async contactNewApiModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ContactNewApiComponent,
      cssClass: 'auto-height modal-extend',
      id: 'contactNewApiComponent',
      showBackdrop: true
    });

    return await modal.present();
  }
}
