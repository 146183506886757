/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import {
  ApiDto,
  ApiMemberDto,
  AssetDto,
  AssetStatus,
  AssociatedCandidature,
  BuildingDto,
  CandidatureStatusEnum,
  ChangeApiDto,
  ChangeLabelDto,
  CustomPopoverButtonRole,
  LabelDto,
  MyCustomEvent,
  ReassignApiDto,
  User,
  UserRol
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { FiltersService } from '@core/services/filters/filters.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import {
  PopoverController,
  ToastController,
  ModalController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonsRole } from '@shared/models/button.model';
import * as moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { ShowLabelPopover } from '../../models/asset-filtes.model';
import { MonthSelect } from '../../models/month-year-selects.model';
import {
  MultipleApiAssignFunction,
  MultipleApiAssignModalData,
  MultipleApiAssignModalResponse
} from '../../models/multiple-api-assign.model';
import { reassignAsset } from '../../utils/asset.utils';
import { copyObject } from '../../utils/global.utils';
import {
  calculateMonthDefaultValue,
  calculateYearDefaultValue,
  generateArrayOfYears,
  months
} from '../../utils/month-year.utils';
import { presentToast } from '../../utils/toast.utils';
import { AssetsApiFilterPopoverComponent } from '../assets-api-filter-popover/assets-api-filter-popover.component';
import { AssetColorLabelPopoverComponent } from '../assets-color-label-popover/assets-color-label-popover.component';
import { MoreFiltersPopoverComponent } from '../more-filters-popover/more-filters-popover.component';
import { MultipleApiAssignComponent } from '../multiple-api-assign/multiple-api-assign.component';
import { UpdateAssetPricesModalComponent } from '../update-asset-prices-modal/update-asset-prices-modal.component';

@Component({
  selector: 'el-buen-inquilino-asset-filters',
  templateUrl: './asset-filters.component.html'
})
export class AssetFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showSearchbar = false;
  @Input() showAddress = false;
  @Input() showApi = false;
  @Input() showMember = false;
  @Input() user: User;
  @Input() showLabel = true;
  @Input() showOpenClosed = false;
  @Input() showIncidences = false;
  @Input() showDates = false;
  @Input() isBuildingPage = false;
  @Input() currentBuilding: BuildingDto;
  @Input() showLabelPopover: ShowLabelPopover;
  @Input() btnApi: ApiDto;
  @Input() closedPage: boolean;
  @Input() showSearchbarBorders = false;
  @Input() showCleanFilters = false;
  @Input() showAdvanceFilters = false;
  @Input() isOpenAssetsPage = false;

  userApis: ApiDto[] = [];
  originalItems: AssetDto[];
  items: AssetDto[];
  selectedItems: AssetDto[];
  searchbarValue: string = null;
  portals: string[] = [];
  currentPortalFilter = '';
  stairs: string[] = [];
  currentStairsFilter = '';
  originalFloors: string[] = [];
  floors: string[] = [];
  currentFloorFilter = '';
  originalDoors: string[] = [];
  doors: string[] = [];
  currentDoorFilter = '';
  filteredApisList: ApiDto[] = [];
  filteredMembersList: ApiMemberDto[] = [];
  hasApiFilter = false;
  btnMember: ApiMemberDto;
  hasMemberFilter = false;
  labels: LabelDto[] = [];
  defaultLabels: LabelDto[] = [];
  btnLabel: LabelDto;
  isShowOpenClosedChecked = false;
  incidencesChecked = false;
  monthFilter: string = null;
  yearFilter: number = null;
  defaultMonth: string = calculateMonthDefaultValue();
  defaultYear: number = calculateYearDefaultValue();
  monthSelect: MonthSelect[] = months;
  years: number[] = generateArrayOfYears();
  screenWidth: number;
  firstTime = true;
  candidaturesChecked = false;
  reportsChecked = false;
  advancedFiltersNumber = 0;
  tenantEmail: string;
  tenantDni: string;
  showWithoutInsurance = false;

  filteredLabels: LabelDto[] = [];
  private tenantEmailChange = false;
  private tenantDniChange = false;
  multipleApiAssignFunction = MultipleApiAssignFunction;

  widthChangeSub: Subscription;
  getOriginalItemsSub: Subscription;
  getApisSub: Subscription;
  getSelectedItemsSubs: Subscription;

  @Output() getItemsEmitter = new EventEmitter();
  @Output() getLabelsEvent = new EventEmitter<LabelDto[]>();
  @Output() changeLabelEvent = new EventEmitter<ChangeLabelDto>();
  @Output() openedClosedEmitter = new EventEmitter<AssetStatus>();

  get showNoInsurance(): boolean {
    return this.isBuildingPage && this.isShowOpenClosedChecked;
  }

  constructor(
    private windowMeasuresService: WindowMeasuresService,
    private popoverController: PopoverController,
    private assetService: AssetsApiService,
    private filtersService: FiltersService,
    private authenticationService: AuthService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private utilsService: UtilsService,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private translate: TranslateService,
    private modalController: ModalController
  ) {
    this.filtersService.cleanFilters.subscribe(() => this.cleanFilters(true));
  }

  ngOnInit(): void {
    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => {
        this.screenWidth = width;
      });

    this.getLabels();

    this.getItems();
    this.getSelectedItems();

    if (this.user?.portfolioId || this.user?.apiRol === 'API_ADMIN') {
      this.getApis();
    }
  }

  getItems(): void {
    // Subscripcion para cada vez que se obtengan los items se hagan ciertas acciones
    this.getOriginalItemsSub = this.filtersService
      .getOriginalItems()
      .subscribe((resp: AssetDto[]) => {
        this.originalItems = resp;
        if (this.originalItems?.length > 0) {
          this.initializeFilters();
          this.filterList();
        } else {
          this.filtersService.setItems([]);
        }
      });
  }

  getApis(): void {
    this.getApisSub = this.authenticationService
      .getApisObservable()
      .subscribe((apis: ApiDto[]) => {
        if (!apis) {
          this.userApis = [];
        } else {
          this.userApis = apis;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userApis'] && changes['userApis'].currentValue) {
      this.filterApis();
      this.filterList();
    }
    if (
      changes['showLabelPopover'] &&
      changes['showLabelPopover'].currentValue
    ) {
      this.presentAssetLabelColorPopover(
        this.showLabelPopover.ev,
        this.showLabelPopover.showOptions,
        this.showLabelPopover.asset
      );
    }
  }

  initializeFilters(): void {
    // Obtenemos filtros de address
    this.getAddressFilters();
    // Se fuerza a detectar los cambios para solucionar errores de cambio de valores al abrir modal de biometria
    this.ref.detectChanges();

    this.btnApi = null;
    this.filterApis();
    this.filterList();
  }

  changeSearchbar(e): void {
    this.searchbarValue = e.target.value.toLowerCase();
    this.filterList();
  }

  addressFilterChange(
    event: any,
    filter: 'portal' | 'stairs' | 'floor' | 'door'
  ): void {
    const value = event.target.value;

    switch (filter) {
      case 'portal':
        this.currentPortalFilter = value;
        this.filterStairs(value, !this.currentPortalFilter ? true : false);
        break;
      case 'stairs':
        this.currentStairsFilter = value;
        this.filterFloors(
          this.currentPortalFilter,
          this.currentStairsFilter,
          this.stairs,
          !this.currentStairsFilter ? true : false
        );
        break;
      case 'floor':
        this.currentFloorFilter = value;
        this.filterDoors(
          this.currentPortalFilter,
          this.currentStairsFilter,
          this.stairs,
          this.currentFloorFilter
        );
        break;
      case 'door':
        this.currentDoorFilter = value;
        break;
    }
    this.filterList();
  }

  async presentApiFilterPopover(ev: any): Promise<void> {
    const popover = await this.popoverController.create({
      component: AssetsApiFilterPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        apis: this.filteredApisList,
        members: this.filteredMembersList,
        user: this.user
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data) {
      switch (data.option) {
        case 'FILTER':
          if (data.type === 'api') {
            this.filterListByApi(data.object);
          } else if (data.type === 'member') {
            this.filterListByMember(data.object);
          }
          break;
        case 'RESET_FILTER':
          this.btnApi = null;
          this.btnMember = null;
          this.filterList();
          this.hasApiFilter = false;
          this.hasMemberFilter = false;
          break;
      }
    }
  }

  async presentAssetLabelColorPopover(
    ev: any,
    showOptions: boolean,
    asset?: AssetDto
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: AssetColorLabelPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {
        labels: this.filteredLabels,
        showOptions,
        asset: asset
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    if (data) {
      switch (data.options) {
        case 'UPDATE_ASSET_LABEL':
          if (data.label) {
            this.updateAssetLabel(data.asset, data.label.id);
          } else {
            this.updateAssetLabel(data.asset, -1);
          }
          break;
      }
    }
  }

  getColor(label: LabelDto): string {
    return label !== null &&
      label !== undefined &&
      label.value !== null &&
      label.value !== undefined
      ? label.value[0] === '#'
        ? label.value
        : '#' + label.value
      : '#fafafa';
  }

  changeShowOpenClosed(event: Event): void {
    this.isShowOpenClosedChecked = (event as CustomEvent<MyCustomEvent>).detail
      .value as boolean;

    if (this.isBuildingPage) {
      if (this.isShowOpenClosedChecked) {
        this.openedClosedEmitter.emit(AssetStatus.RENTED);
      } else {
        this.openedClosedEmitter.emit(AssetStatus.OPEN);
      }
    } else {
      this.filterList();
      this.getAddressFilters();
    }
  }

  handleChangeMonth(event): void {
    this.monthFilter = event.detail.value;
    if (!this.closedPage) {
      if (this.isBuildingPage) {
        if (this.firstTime) {
          this.firstTime = false;
        }
        this.filterList();
      } else {
        // Si estamos en la pestaña de operaciones cerradas, seteamos un observable para obtenerlas al obtener los assets
        this.filtersService.setDatesFilter({
          month: this.monthFilter,
          year: this.yearFilter
        });
        this.getItemsEmitter.emit(true);
      }
    }
  }

  handleChangeYear(event): void {
    this.yearFilter = event.detail.value;
    if (!this.closedPage) {
      if (this.isBuildingPage) {
        if (this.firstTime) {
          this.firstTime = false;
        }
        this.filterList();
      } else {
        // Si estamos en la pestaña de operaciones cerradas, seteamos un observable para obtenerlas al obtener los assets
        this.filtersService.setDatesFilter({
          month: this.monthFilter,
          year: this.yearFilter
        });
        this.getItemsEmitter.emit(true);
      }
    }
  }

  filterList(): void {
    this.filtersService
      .getOriginalItems()
      .pipe(first())
      .subscribe((resp: AssetDto[]) => {
        this.items = resp;

        if (this.items?.length > 0) {
          // Cuando se busca por filtro de email de tenant, no se devuelve el estado
          if (!this.tenantEmail) {
            // Abiertas o cerradas
            if (this.showOpenClosed) {
              if (!this.isShowOpenClosedChecked) {
                this.items = this.items.filter(
                  (a: AssetDto) => a.status === 'OPEN'
                );
              } else {
                this.items = this.items.filter(
                  (a: AssetDto) => a.status === 'RENTED'
                );
              }
            }
          }

          // Searchbar
          if (
            this.showSearchbar &&
            this.searchbarValue &&
            this.searchbarValue?.trim() !== ''
          ) {
            this.items = this.items.filter(
              (item: AssetDto) =>
                item.street?.toLowerCase().includes(this.searchbarValue) ||
                item.province?.toLowerCase().includes(this.searchbarValue) ||
                item.postalCode?.includes(this.searchbarValue) ||
                item.town?.toLowerCase().includes(this.searchbarValue) ||
                item.number?.toLowerCase().includes(this.searchbarValue)
            );
          }

          // Con candidaturas
          if (this.candidaturesChecked) {
            this.items = this.items.filter(
              (item: AssetDto) =>
                item?.associatedCandidatures?.filter(
                  (cand: AssociatedCandidature) =>
                    cand.status !== CandidatureStatusEnum.REJECT
                )?.length > 0
            );
          }

          // Con informes
          if (this.reportsChecked) {
            this.items = this.items.filter(
              (item: AssetDto) => item?.downloadableReports > 0
            );
          }

          // Incidencias
          if (this.incidencesChecked) {
            this.items = this.items.filter(
              (item: AssetDto) => item?.incidences
            );
          }

          // Fechas
          if (this.isBuildingPage && !this.firstTime) {
            const year = this.yearFilter || this.defaultYear;
            const month = this.monthFilter || this.defaultMonth;

            const firstDay = moment(
              `${year}-${month}-01`,
              'YYYY-MM-DD'
            ).startOf('month');
            const lastDay = moment(`${year}-${month}-01`, 'YYYY-MM-DD').endOf(
              'month'
            );

            this.items = this.items.filter(
              (a: AssetDto) =>
                moment(a.creationDate).isSameOrAfter(firstDay) &&
                moment(a.creationDate).isSameOrBefore(lastDay)
            );
          }

          // Labels
          if (this.btnLabel) {
            this.items = this.items.filter(
              (item: AssetDto) => item.labelId === this.btnLabel.id
            );
          }

          // Apis
          if (this.btnApi) {
            // Filtro por propias para homeowner
            if (this.btnApi.id === 'null') {
              this.items = this.items.filter(
                (item: AssetDto) => !item.apiId || item.apiId === 'null'
              );
            } else {
              this.items = this.items.filter(
                (item: AssetDto) => item.apiId === this.btnApi.id
              );
            }
          }

          // Members
          if (this.btnMember) {
            // Filtro por propias para homeowner
            if (this.btnMember.id === this.user.id) {
              this.items = this.items.filter(
                (item: AssetDto) => item.userId === this.user.id
              );
            } else {
              this.items = this.items.filter(
                (item: AssetDto) =>
                  item.userId ===
                  this.userApis[0]?.members?.find(
                    (m: ApiMemberDto) => m.id === this.btnMember.id
                  )?.id
              );
            }
          }

          // Filtrado por address
          if (this.currentPortalFilter) {
            this.items = this.items.filter(
              (a: AssetDto) =>
                a.portal?.toLowerCase() ===
                this.currentPortalFilter?.toLowerCase()
            );
          }
          if (this.currentStairsFilter) {
            this.items = this.items.filter(
              (a: AssetDto) =>
                a.stairs?.toLowerCase() ===
                this.currentStairsFilter?.toLowerCase()
            );
          }
          if (this.currentFloorFilter) {
            this.items = this.items.filter(
              (a: AssetDto) =>
                a.floor?.toLowerCase() ===
                this.currentFloorFilter?.toLowerCase()
            );
          }
          if (this.currentDoorFilter) {
            this.items = this.items.filter(
              (a: AssetDto) =>
                a.door?.toLowerCase() === this.currentDoorFilter?.toLowerCase()
            );
          }

          // Sin seguro
          if (this.showWithoutInsurance) {
            this.items = this.items.filter(
              (a: AssetDto) => a.hasPolicyNumber === null
            );
          }

          this.orderList(
            this.closedPage || this.isShowOpenClosedChecked ? 'closed' : null
          );
        }

        // Se setea un observable para obtener los items filtrados en el componente asset-list
        this.filtersService.setItems(this.items);
      });
  }
  assetUserIdIsNotInApi(userId: string): boolean {
    const findInApi = this.userApis.find(
      (api: ApiDto) => api.adminId === userId
    );
    const findInMember = this.userApis.find((api: ApiDto) =>
      api.members.find((member: ApiMemberDto) => member.id === userId)
    );
    return !findInApi && !findInMember;
  }

  orderList(type?: 'buildings' | 'closed'): void {
    this.items.sort((a: AssetDto, b: AssetDto) => {
      let firstDate: Date, secondDate: Date;

      switch (type) {
        case 'closed': {
          firstDate = a.rentedDate;
          secondDate = b.rentedDate;
          break;
        }
      }

      return new Date(secondDate).getTime() - new Date(firstDate).getTime();
    });
  }

  getClosedAssets(): void {
    this.filtersService.setDatesFilter({
      month: this.monthFilter,
      year: this.yearFilter
    });
    this.getItemsEmitter.emit(true);
  }

  cleanFilters(fromObservable: boolean = false): void {
    this.searchbarValue = null;
    this.currentPortalFilter = '';
    this.currentDoorFilter = '';
    this.currentFloorFilter = '';
    this.incidencesChecked = false;
    this.btnApi = null;
    this.btnMember = null;
    this.hasApiFilter = false;
    this.hasMemberFilter = false;
    this.btnLabel = null;
    this.incidencesChecked = false;
    this.candidaturesChecked = false;
    this.reportsChecked = false;
    this.advancedFiltersNumber = 0;
    if (this.tenantEmailChange) {
      this.getItemsEmitter.emit(false);
      this.tenantEmail = null;
      this.tenantEmailChange = false;
    }
    if (this.tenantDniChange) {
      this.getItemsEmitter.emit(false);
      this.tenantDni = null;
      this.tenantDniChange = false;
    }

    if (!fromObservable) {
      if (this.isOpenAssetsPage) {
        this.filtersService.setDatesFilter({
          month: null,
          year: null
        });
        this.getItemsEmitter.emit(false);
      }

      this.monthFilter = null;
      this.yearFilter = null;
      this.firstTime = true;
    } else if (this.monthFilter && this.yearFilter) {
      this.advancedFiltersNumber++;
    }

    this.filterList();
  }

  async showMoreFiltersPopover(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: MoreFiltersPopoverComponent,
      event: ev,
      backdropDismiss: false,
      componentProps: {
        candidaturesChecked: this.candidaturesChecked,
        reportsChecked: this.reportsChecked,
        incidencesChecked: this.incidencesChecked,
        labels: this.labels,
        defaultLabels: this.defaultLabels,
        filteredLabels: this.filteredLabels,
        btnLabel: this.btnLabel,
        monthFilter: this.monthFilter,
        yearFilter: this.yearFilter,
        defaultMonth: this.defaultMonth,
        defaultYear: this.defaultYear,
        years: this.years,
        btnApi: this.btnApi,
        btnMember: this.btnMember,
        filteredApisList: this.filteredApisList,
        filteredMembersList: this.filteredMembersList,
        user: this.user,
        showApi: this.showApi,
        showMember: this.showMember,
        tenantEmail: this.tenantEmail,
        tenantDni: this.tenantDni
      },
      cssClass: 'more-filters-popover'
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data) {
      this.candidaturesChecked = data?.candidaturesChecked;
      this.reportsChecked = data?.reportsChecked;
      this.incidencesChecked = data?.incidencesChecked;
      this.btnLabel = data?.btnLabel;
      const monthFilter = this.monthFilter;
      const yearFilter = this.yearFilter;
      this.monthFilter = data?.monthFilter;
      this.yearFilter = data?.yearFilter;
      this.btnApi = data?.btnApi;
      this.btnMember = data?.btnMember;
      this.tenantEmail = data?.tenantEmail;
      this.tenantEmailChange = data?.tenantEmailChange;
      this.tenantDni = data?.tenantDni;
      this.tenantDniChange = data?.tenantDniChange;
      delete data?.tenantEmailChange;
      delete data?.tenantDniChange;

      if (data?.labels) {
        this.setLabels(data?.labels);
      }
      delete data.labels;
      delete data.monthFilter;
      delete data.yearFilter;

      this.advancedFiltersNumber = 0;
      Object.keys(data).forEach((key: string) => {
        if (data[key]) {
          this.advancedFiltersNumber++;
        }
      });

      // Si se ha añadio filtro de fechas
      if (this.monthFilter && this.yearFilter) {
        this.advancedFiltersNumber++;
        // Si se ha cambiado el valor de los filtros
        if (!this.closedPage) {
          if (this.isBuildingPage) {
            if (this.firstTime) {
              this.firstTime = false;
            }
            this.filterList();
          } else {
            if (
              this.monthFilter !== monthFilter ||
              this.yearFilter !== yearFilter
            ) {
              // Si estamos en la pestaña de operaciones cerradas, seteamos un observable para obtenerlas al obtener los assets
              this.filtersService.setDatesFilter({
                month: this.monthFilter,
                year: this.yearFilter
              });
              this.getItemsEmitter.emit(true);
            } else {
              this.filterList();
            }
          }
        }
      } else if (this.tenantEmailChange) {
        this.filtersService.setTenantEmail(this.tenantEmail);
        this.getItemsEmitter.emit(true);
      } else if (this.tenantDniChange) {
        this.filtersService.setTenantDni(this.tenantDni);
        this.getItemsEmitter.emit(true);
      } else {
        this.filterList();
      }
    } else {
      this.getItemsEmitter.emit(true);
    }
  }

  isThereAnyFilter(): boolean {
    return (
      !!this.searchbarValue ||
      !!this.currentPortalFilter ||
      !!this.currentFloorFilter ||
      !!this.currentDoorFilter ||
      !!this.hasApiFilter ||
      !!this.hasMemberFilter ||
      !!this.hasMemberFilter ||
      this.advancedFiltersNumber > 0
    );
  }

  async showMultipleApiAssignPopover(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: MultipleApiAssignComponent,
      event: ev,
      translucent: true,
      backdropDismiss: false,
      cssClass: 'multiple-api-assign-popover',
      componentProps: {
        apis: this.userApis,
        members: this.filteredMembersList,
        user: this.user
      } as MultipleApiAssignModalData
    });

    popover
      .onWillDismiss()
      .then(
        ({
          role,
          data
        }: OverlayEventDetail<MultipleApiAssignModalResponse>) => {
          if (role === CustomPopoverButtonRole.ACCEPT) {
            this.onAcceptMultipleApiAssign(data);
          }
        }
      );

    await popover.present();
  }

  async updateMassivePrices(): Promise<void> {
    const modal = await this.modalController.create({
      component: UpdateAssetPricesModalComponent,
      backdropDismiss: true,
      componentProps: {
        isPortfolioPage: true,
        buildingId: this.currentBuilding?.id
      }
    });

    await modal.present();
    const { role } = await modal.onWillDismiss();

    if (role === ButtonsRole.ACCEPT) {
      presentToast(
        this.toastController,
        'Actualización de precios realizada con éxito',
        'success',
        5000
      );
    }
  }

  getAssetsIdFromSelected(): string[] {
    return this.selectedItems.map((asset: AssetDto) => asset.id);
  }

  showWithoutInsuranceChange(): void {
    this.filterList();
  }

  ngOnDestroy(): void {
    this.widthChangeSub?.unsubscribe();
    this.getOriginalItemsSub?.unsubscribe();
    this.getApisSub?.unsubscribe();
    this.filtersService.setDatesFilter({
      month: null,
      year: null
    });
    this.getSelectedItemsSubs?.unsubscribe();
    this.filtersService.setSelectedItems([]);
  }

  private filterApiById(apiId: string): ApiDto {
    return this.userApis.find((api: ApiDto) => api.id === apiId);
  }

  private filterListByApi(api: ApiDto): void {
    this.btnApi = api;
    this.filterList();
    this.hasApiFilter = true;
  }

  private filterListByMember(member: ApiMemberDto): void {
    this.btnMember = member;
    this.filterList();
    this.hasMemberFilter = true;
  }

  private getAddressFilters(): void {
    this.currentPortalFilter = '';
    const items = this.prepareItemsOnStatus(this.originalItems);
    let portals = items?.map((a: AssetDto) => a.portal);
    portals = [...new Set(portals)];
    portals = this.deleteNull(portals);
    portals = this.deleteBlank(portals);
    this.portals = this.orderStringArray(portals);

    this.filterStairs();
    this.filterDoors();
  }

  private filterStairs(portal?: string, reset?: boolean): void {
    let stairs: string[] = [];
    let items = copyObject(
      this.prepareItemsOnStatus(this.originalItems)
    ) as AssetDto[];
    this.currentStairsFilter = '';
    if (portal && !reset) {
      items = items?.filter((a: AssetDto) => a.portal === portal);
      stairs = items.map((a: AssetDto) => a.stairs);
    } else {
      stairs = items?.map((a: AssetDto) => a.stairs);
    }

    stairs = [...new Set(stairs)];

    stairs = this.deleteUndefined(stairs);
    if (stairs?.length > 0) {
      stairs = this.deleteNull(stairs);
    }
    if (stairs?.length > 0) {
      stairs = this.deleteBlank(stairs);
    }

    this.stairs = this.orderStringArray(stairs);

    this.filterFloors(
      portal,
      null,
      this.orderStringArray(stairs),
      !this.currentPortalFilter && !this.currentFloorFilter ? true : false
    );
  }

  private filterFloors(
    portal?: string,
    stair?: string,
    stairs?: string[],
    reset?: boolean
  ): void {
    let floors: string[] = [];
    let items = copyObject(
      this.prepareItemsOnStatus(this.originalItems)
    ) as AssetDto[];
    this.currentFloorFilter = '';
    if ((portal || stair || stairs?.length > 0) && !reset) {
      if (portal) {
        items = items?.filter((a: AssetDto) => a.portal === portal);
      }

      if (stairs?.length > 0) {
        const copyStairs = copyObject(stairs) as string[];
        copyStairs.unshift(null);
        items = items?.filter((a: AssetDto) => copyStairs.includes(a.stairs));
      } else if (stair) {
        items = items?.filter((a: AssetDto) => a.stairs === stair);
      }

      floors = items?.map((a: AssetDto) => a.floor);
    } else {
      floors = items?.map((a: AssetDto) => a.floor);
    }

    floors = [...new Set(floors)];
    floors = this.deleteUndefined(floors);
    if (floors?.length > 0) {
      floors = this.deleteNull(floors);
    }
    if (floors?.length > 0) {
      floors = this.deleteBlank(floors);
    }
    this.floors = this.orderStringArray(floors);

    this.filterDoors(
      portal,
      stair,
      stairs,
      null,
      this.orderStringArray(floors),
      !this.currentPortalFilter && !this.currentFloorFilter ? true : false
    );
  }

  private filterDoors(
    portal?: string,
    stair?: string,
    stairs?: string[],
    floor?: string,
    floors?: string[],
    reset?: boolean
  ): void {
    let doors: string[] = [];
    let items = this.prepareItemsOnStatus(this.originalItems);

    this.currentDoorFilter = '';
    if ((portal || floor || floors?.length > 0) && !reset) {
      if (portal) {
        items = items?.filter((a: AssetDto) => a.portal === portal);
      }

      if (stairs?.length > 0) {
        const copyStairs = copyObject(stairs) as string[];
        copyStairs.unshift(null);
        items = items?.filter((a: AssetDto) => copyStairs.includes(a.stairs));
      } else if (stair) {
        items = items?.filter((a: AssetDto) => a.stairs === stair);
      }

      if (floors?.length > 0) {
        items = items?.filter((a: AssetDto) => floors.includes(a.floor));
      } else if (floor) {
        items = items?.filter((a: AssetDto) => a.floor === floor);
      }

      doors = items?.map((a: AssetDto) => a.door);
    } else {
      doors = items?.map((a: AssetDto) => a.door);
    }

    doors = [...new Set(doors)];
    doors = this.deleteUndefined(doors);
    if (doors?.length > 0) {
      doors = this.deleteNull(doors);
    }
    if (doors?.length > 0) {
      doors = this.deleteBlank(doors);
    }
    this.doors = this.orderStringArray(doors);
  }

  private prepareItemsOnStatus(items: AssetDto[]): AssetDto[] {
    let status: string;
    if (this.isBuildingPage || this.router.url.includes('/other-assets')) {
      if (!this.isShowOpenClosedChecked) {
        status = 'OPEN';
      } else {
        status = 'RENTED';
      }
    } else if (this.closedPage) {
      status = 'RENTED';
    } else {
      status = 'OPEN';
    }

    return items?.filter((i: AssetDto) => i.status === status);
  }

  private deleteUndefined(array: string[]): string[] {
    const index = array.findIndex((a: string) => a === undefined);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  private deleteNull(array: string[]): string[] {
    const index = array.findIndex((a: string) => a === null);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  private deleteBlank(array: string[]): string[] {
    const index = array.findIndex((a: string) => a === '');
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  private filterApis(): void {
    if (this.user?.portfolioId || this.user?.apiRol === 'API_ADMIN') {
      this.filteredApisList = [];
      this.filteredMembersList = [];
      if (this.isBuildingPage) {
        // Obtenemos las apis para el filtrado
        if (this.user?.portfolioId) {
          let apis: string[] = [];
          if (this.originalItems?.length > 0) {
            apis = this.originalItems?.map((a: AssetDto) => a.apiId);

            // Borrado de duplicados
            apis = [...new Set(apis)];

            const indexNull = apis?.findIndex((id: string) => id === 'null');
            if (indexNull && indexNull !== -1) {
              apis.splice(indexNull, 1);
            }
          } else {
            apis = this.currentBuilding?.apisId;
          }

          apis?.forEach((apiId: string) => {
            const api = this.userApis?.find((a: ApiDto) => a.id === apiId);
            if (api) {
              this.filteredApisList.push(api);
            }
          });
        } else {
          // Obtenemos los members para el filtrado
          if (this.originalItems?.length > 0) {
            let members = this.originalItems?.map((a: AssetDto) => a.userId);
            members = [...new Set(members)];

            const apiMemberIndex = members?.findIndex(
              (id: string) => id === this.user.id
            );
            if (apiMemberIndex && apiMemberIndex !== -1) {
              members.splice(apiMemberIndex, 1);
            }

            members.forEach((memberId: string) => {
              const member = this.userApis[0]?.members?.find(
                (a: ApiMemberDto) => a.id === memberId
              );
              if (member) {
                this.filteredMembersList.push(member);
              }
            });
          } else {
            this.filteredMembersList = this.userApis[0]?.members;
          }
        }
      } else {
        if (this.user.portfolioId) {
          this.filteredApisList = this.userApis;
        } else {
          this.filteredMembersList = this.userApis[0]?.members;
        }
      }
    }
  }

  private orderStringArray(array: string[]): string[] {
    return array.sort((a: string, b: string) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  private getLabels(): void {
    forkJoin({
      usersLabels: this.assetService.getLabels(this.user.id),
      defaultLabels: this.assetService.getLabels('DEFAULT')
    })
      .pipe(first())
      .subscribe({
        next: (resp: {
          usersLabels: LabelDto[];
          defaultLabels: LabelDto[];
        }) => {
          this.setLabels(resp.usersLabels, resp.defaultLabels);
        }
      });
  }

  private setLabels(labels: LabelDto[], defaults?: LabelDto[]): void {
    this.labels = labels;
    if (defaults) {
      this.defaultLabels = defaults;
    }

    this.filteredLabels = this.utilsService.filterLabels(
      this.defaultLabels,
      JSON.parse(JSON.stringify(this.labels))
    );

    this.getLabelsEvent.emit(this.labels);
  }

  private updateAssetLabel(asset: AssetDto, value: number): void {
    this.assetService.updateAssetLabel(asset.id, value).subscribe(() => {
      this.changeLabelEvent.emit({
        newLabelId: value,
        assetId: asset.id
      });
    });
  }

  private getSelectedItems(): void {
    this.getSelectedItemsSubs = this.filtersService
      .getSelectedItems()
      .subscribe(
        (items: AssetDto[]) =>
          (this.selectedItems = copyObject(items) as AssetDto[])
      );
  }

  private async onAcceptMultipleApiAssign(
    data: MultipleApiAssignModalResponse
  ): Promise<void> {
    const { api, member } = data;
    const assets = this.getAssetsIdFromSelected();

    const reassignObject: ReassignApiDto = {
      assets,
      adminId: api.adminId,
      apiId: api.id,
      memberEmail: member?.email || null,
      memberId: member?.id || null
    };

    await this.loadingService.presentLoading(null);
    this.assetService
      .reassignMassiveAssets(this.currentBuilding.id, reassignObject)
      .pipe(first())
      .subscribe(
        async () => {
          this.onSuccessReassingMassiveAssets(assets, data);
          await this.loadingService.dismissLoading();
        },
        async () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'components.asset_filters.api_assign_error'
            ) as string,
            'danger',
            5000
          );
          await this.loadingService.dismissLoading();
        }
      );
  }

  private onSuccessReassingMassiveAssets(
    assets: string[],
    data: MultipleApiAssignModalResponse
  ): void {
    presentToast(
      this.toastController,
      this.translate.instant(
        'components.asset_filters.multiple_change_ok_msg'
      ) as string,
      'success'
    );
    assets.forEach((assetId: string) => {
      const { api, member } = data;
      let changeType = 'admin';
      let newApiId = api.id;
      let userId = api.adminId;

      if (this.user.apiRol === UserRol.API_ADMIN) {
        changeType = 'member';
        newApiId = null;
        userId = member.id;
      }

      const event: ChangeApiDto = {
        assetId,
        changeType,
        newApiId,
        userId
      };
      this.originalItems = reassignAsset(this.originalItems, event);
    });
    this.filtersService.setOriginalItems(this.originalItems);
  }
}
