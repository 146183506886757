import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-terms-of-use',
  templateUrl: './terms-of-use.component.html'
})
export class TermsOfUseComponent {
  @Input() privacy: boolean;
  @Input() profilePrivacy: boolean;

  constructor(private modalController: ModalController) {}

  accept(): void {
    this.modalController.dismiss({
      accept: true
    });
  }

  reject(): void {
    this.modalController.dismiss({
      accept: false
    });
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
