import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { SectionDTO, UserSectionDTO } from '../../models';

@Injectable()
export class ProfilesApiService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.profiles;
  }

  getSections(fromHomeowner: boolean): Observable<SectionDTO[]> {
    return this.http.get<SectionDTO[]>(this.url + `/sections/${fromHomeowner}`);
  }

  getSectionsByUserId(userId: string): Observable<UserSectionDTO> {
    return this.http.get<UserSectionDTO>(
      this.url + '/profiles/' + `${userId}/sections`
    );
  }
}
