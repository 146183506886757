import {
  ComercialNote,
  SinisterThread,
  TypeUserEnum,
  User
} from '@core/models';

export function copyObject(object: unknown): unknown {
  return JSON.parse(JSON.stringify(object));
}

export const tableSize: number[] = [25, 50, 100];

export function getWriterName(
  users: User[],
  userId: string,
  message: ComercialNote | SinisterThread
): string {
  const user = getUser(users, message.userId);
  if (isIncofiso(user)) {
    return 'Soporte EBI';
  } else {
    const loggedUser = getUser(users, userId);
    if (loggedUser?.id === message.userId) {
      return 'Yo';
    } else if (isIncofiso(loggedUser)) {
      return 'Cliente';
    } else {
      return `${user.firstname} ${user.surname1}`;
    }
  }
}

export function getUser(users: User[], userId: string): User {
  return users?.find((u: User) => u.id === userId);
}

function isIncofiso(user: User): boolean {
  return user?.userType === TypeUserEnum.INCOFISA_ADMINISTRATIVE;
}
