import { formatPercent } from '@angular/common';
import {
  AnalysisCatalogue,
  AnalysisOption,
  Candidature,
  CreateAnalysisDto,
  TenantCandidature
} from '@core/models';
import { format } from 'date-fns';

export function findUser(
  candidature: Candidature,
  userId: string
): TenantCandidature {
  return candidature.tenantCandidatureList?.find(
    (tc: TenantCandidature) => tc.user.id === userId
  );
}

export function getTenantDni(candidature: Candidature, userId: string): string {
  return findUser(candidature, userId).user.dni;
}

export function getTenantReportRole(
  candidature: Candidature,
  userId: string
): string {
  return findUser(candidature, userId).user.guarantor
    ? 'Avalista'
    : 'Inquilino';
}

export function getTenantName(
  candidature: Candidature,
  userId: string
): string {
  const tc = findUser(candidature, userId);

  return `${tc.user.firstname ? tc.user.firstname : tc.user.firstName} ${
    tc.user.surname1
  } ${tc.user.surname2 ? tc.user.surname2 : ''}`;
}

export function getTenantEmail(
  candidature: Candidature,
  userId: string
): string {
  const tc = findUser(candidature, userId);

  return tc.user.email;
}

export function getTenantPhone(
  candidature: Candidature,
  userId: string
): string {
  const tc = findUser(candidature, userId);

  return tc.user.phone;
}

export function getAssetAddress(candidature: Candidature): string {
  const { asset } = candidature;
  let address = `${asset.street}, Número ${asset.number}`;

  if (!!asset.portal) {
    address = `${address}, Portal ${asset.portal}`;
  }

  if (!!asset.stairs) {
    address = `${address}, Escalera ${asset.stairs}`;
  }

  if (!!asset.floor) {
    address = `${address}, ${asset.floor}°`;
  }

  if (!!asset.door) {
    address = `${address} ${asset.door}`;
  }

  address = `${address} - ${asset.postalCode} ${asset.town}`;

  return address;
}

export function getAssetRentalArea(
  candidature: Candidature,
  report: CreateAnalysisDto
): number {
  return report?.rentalArea ? report?.rentalArea : candidature.asset.rentalArea;
}

export function getPosition(
  position: string,
  catalogue: AnalysisCatalogue,
  lang: string
): string {
  const found = catalogue.position.find(
    (p: AnalysisOption) => p.id === position
  );

  return found ? (found[lang] as string) : '';
}

export function calculateEffort(
  report: CreateAnalysisDto,
  candidature: Candidature
): string {
  const rentalPrice = report?.rentalPrice ?? candidature.asset.rentalPrice;
  const effort = rentalPrice / report.income;
  return formatPercent(effort, 'es-ES', '1.0-1');
}

export function getBusinessActivitySector(
  sector: string,
  catalogue: AnalysisCatalogue,
  lang: string
): string {
  const found = catalogue.businessActivitySector.find(
    (s: AnalysisOption) => s.id === sector
  );

  return found ? (found[lang] as string) : '';
}

export function getContractType(
  contractType: string,
  catalogue: AnalysisCatalogue,
  lang: string
): string {
  const type = catalogue.contractType.find(
    (s: AnalysisOption) => s.id === contractType
  );
  if (!!type) {
    return type[lang] as string;
  }

  return '';
}

export function getAntiquity(
  antiquity: string,
  catalogue: AnalysisCatalogue,
  lang: string,
  business: boolean = false
): string {
  const found = catalogue[business ? 'businessAntiquity' : 'antiquity'].find(
    (ji: AnalysisOption) => ji.id === antiquity
  );

  return found ? (found[lang] as string) : '';
}

export function getJudicialIncidents(
  judicialIncidents: string,
  catalogue: AnalysisCatalogue,
  lang: string,
  business: boolean = false
): string {
  const catalogueFounded = catalogue[
    business ? 'businessJudicialIncidents' : 'judicialIncidents'
  ].find((ji: AnalysisOption) => ji.id === judicialIncidents);
  if (!!catalogueFounded) {
    return catalogueFounded[lang] as string;
  }
  return '';
}

export function getTraceabilityValue(traceability: string): string {
  let value: string;

  switch (traceability) {
    case 'WITHOUT_CHANGES': {
      value = 'enums.traceability.WITHOUT_CHANGES';
      break;
    }
    case 'ONE_CHANGE': {
      value = 'enums.traceability.ONE_CHANGE';
      break;
    }
    case 'TWO_CHANGES': {
      value = 'enums.traceability.TWO_CHANGES';
      break;
    }
    case 'THREE_MORE_CHANGES': {
      value = 'enums.traceability.THREE_MORE_CHANGES';
      break;
    }
  }
  return value;
}

export function getNonPayments(
  judicialIncidents: string,
  catalogue: AnalysisCatalogue,
  lang: string,
  business: boolean = false
): string {
  const catalogueFounded = catalogue[
    business ? 'businessNoPaymentsConfirmed' : 'nonPaymentsConfirmed'
  ].find((ji: AnalysisOption) => ji.id === judicialIncidents);

  if (!!catalogueFounded) {
    return catalogueFounded[lang] as string;
  }
  return '';
}

export function getInternationalBlacklist(report: CreateAnalysisDto): string {
  return !report?.internationalBlackList
    ? 'components.preview_report.no_international_sanctions'
    : 'components.preview_report.international_sanctions';
}

export function getBirthdate(candidature: Candidature, userId: string): string {
  const tc = findUser(candidature, userId);
  if (!!tc.user.birthDate) {
    return format(new Date(tc.user.birthDate), 'dd/MM/yyyy');
  } else {
    return '';
  }
}

export function calculateJointEffort(
  candidature: Candidature,
  reports: CreateAnalysisDto[]
): string {
  let jointIncome = 0;
  reports?.map((report: CreateAnalysisDto) => {
    jointIncome += report.income;
  });
  const rentalPrice = reports[0]?.rentalPrice ?? candidature.asset.rentalPrice;
  const effort = rentalPrice / jointIncome;
  return formatPercent(effort, 'es-ES', '1.0-1');
}

export function getRole(candidature: Candidature, userId: string): string {
  const tenant: TenantCandidature = findUser(candidature, userId);

  let role: string;

  if (tenant.user?.student) {
    role = 'student';
  } else if (tenant.user?.business) {
    role = 'business';
  }

  return role;
}

export function getBusinessRepresentativeName(
  candidature: Candidature,
  userId: string
): string {
  const tenant: TenantCandidature = findUser(candidature, userId);
  let name = `${tenant.user.firstname} ${tenant.user.surname1}`;

  if (tenant.user.surname2) {
    name = `${name} ${tenant.user.surname2}`;
  }

  return `${name} - ${tenant.user.dni}`;
}

export function calculatePreviousPeriodResultsValue(
  previousPeriodResults: number
): string | null {
  if (previousPeriodResults) {
    if (previousPeriodResults > 0) {
      return 'components.preview_report.positive';
    } else if (previousPeriodResults < 0) {
      return 'components.preview_report.negative';
    } else {
      return 'components.preview_report.neutral';
    }
  } else {
    return '';
  }
}

export function calculateBusinessPaymentCapacity(
  candidature: Candidature,
  report: CreateAnalysisDto,
  catalogue: AnalysisCatalogue,
  lang: string
): string {
  const capacity = catalogue.businessPaymentCapacity;
  const E = (Number(report.previousYearSales) * 0.2) / 12;
  const F = (Number(report.lastQuarterSales) * 0.2) / 3;
  const rentalPrice = report?.rentalPrice ?? candidature.asset.rentalPrice;

  if (E <= rentalPrice && F <= rentalPrice) {
    return capacity.find((it: AnalysisOption) => it.id === 'LOW')[
      lang
    ] as string;
  } else if (E > rentalPrice && F > rentalPrice) {
    return capacity.find((it: AnalysisOption) => it.id === 'HIGH')[
      lang
    ] as string;
  } else {
    return capacity.find((it: AnalysisOption) => it.id === 'MEDIUM')[
      lang
    ] as string;
  }
}

export function calculateBusinesssRisk(
  report: CreateAnalysisDto,
  catalogue: AnalysisCatalogue,
  lang: string
): string {
  const risk = catalogue.businessRisk;
  const B = Number(report.previousYearExploit);
  const C = Number(report.previousPeriodResults);

  if ((B < 0 && C < 0) || (C > 0 && B < 0)) {
    return risk.find((it: AnalysisOption) => it.id === 'HIGH')[lang] as string;
  } else if (C < 0 && B > 0) {
    return risk.find((it: AnalysisOption) => it.id === 'MEDIUM')[
      lang
    ] as string;
  } else if (B > 0 && C > 0) {
    return risk.find((it: AnalysisOption) => it.id === 'LOW')[lang] as string;
  }
}
