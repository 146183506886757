/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[elBuenInquilinoOnlyNumber]'
})
export class OnlyNumberDirective {
  @HostListener('keypress', ['$event'])
  onInput(event: any): boolean {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(
      event.which ? event.which : event.keyCode
    );

    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
