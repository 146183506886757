/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import { Subscription } from 'rxjs';

import {
  CANARIAS_COORDINATES,
  MAP_CONFIGURATION,
  MAP_COORDINATES,
  MAP_STYLE,
  MARKER_CLUSTERER_IMAGE_PATH
} from '../../utils/ebi-google-maps.utils';

@Component({
  selector: 'el-buen-inquilino-google-maps',
  templateUrl: './google-maps.component.html'
})
export class GoogleMapsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() items: MapAsset[] = [];

  mapCoordinates = MAP_COORDINATES;
  canariasCenter = CANARIAS_COORDINATES;
  mapCenter;
  mapStyle = MAP_STYLE;
  mapConfigurations = MAP_CONFIGURATION;
  markers = [];
  @ViewChild(GoogleMap) map: google.maps.Map;

  showMarkers = false;
  markerClustererImagePath = MARKER_CLUSTERER_IMAGE_PATH;
  mapZoom = 6;
  itemSelected: MapAsset;
  widthChangeSub: Subscription;
  width: number;

  constructor(private windowMeasuresService: WindowMeasuresService) {}

  ngOnInit(): void {
    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => {
        this.width = width;
        this.initializeMap();
      });
  }

  ngOnChanges(): void {
    this.buildMarkers();
  }

  initializeMap(): void {
    const canarias = this.checkIfCanariasCoords();
    if (canarias) {
      this.mapCenter = this.canariasCenter;
      if (this.width >= 2500) {
        this.mapZoom = 6;
      } else if (this.width <= 575) {
        this.mapZoom = 4;
      } else {
        this.mapZoom = 5;
      }
    } else {
      this.mapCenter = this.mapCoordinates;
      if (this.width >= 1800) {
        this.mapZoom = 6;
      } else {
        this.mapZoom = 5;
      }
    }
  }

  buildMarkers(): void {
    this.markers = [];
    if (!!this.items.length) {
      this.createMarkers();
    }
  }

  createMarkers(): any {
    const coords = this.items?.map((b: MapAsset) => {
      return {
        lat: b.lat,
        lng: b.lng
      };
    });

    coords?.forEach((coord: MapAsset) => {
      this.markers.push({
        position: coord,
        zoom: 22,
        options: {
          animation: google.maps.Animation.DROP
        },
        map: this.map
      });
    });
  }

  showMarkerInfo(index: number): void {
    if (index === null) {
      this.itemSelected = null;
      return;
    }
    this.itemSelected = this.items[index];

    if (this.mapZoom <= 6) {
      this.mapCenter = {
        lat: this.itemSelected.lat,
        lng: this.itemSelected.lng
      };
      this.mapZoom = 18;
    }
  }

  checkIfCanariasCoords(): boolean {
    const canarias = this.items?.some(
      (item: MapAsset) =>
        item.lat > 27 && item.lat < 29 && item.lng > -18 && item.lng < -13
    );

    return canarias ? true : false;
  }

  ngOnDestroy(): void {
    this.widthChangeSub?.unsubscribe();
  }
}

export class MapAsset {
  lat: number;
  lng: number;
  street?: string;
  number?: string;
  postalCode?: string;
  town?: string;
  province?: string;
}
