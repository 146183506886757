/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import {
  AssetCreateCandidatureTenantDto,
  BuildingDto,
  CreateCandidatureDto,
  NotificationDto,
  NotificationTypeEnum,
  User,
  UserCreateCandidatureDto,
  UserRequestCreateMultipleCandidatureDto
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { StepsService } from '@core/services/steps/steps.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { StepTwoMultipleComponent } from '../step-two-multiple/step-two-multiple.component';

@Component({
  selector: 'el-buen-inquilino-add-multiple-candidature',
  templateUrl: './add-multiple-candidature.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class AddMultipleCandidatureComponent {
  selectedIndex = 0;
  stepOne: any;
  saving = false;
  maximunTenantsReached = false;
  @ViewChild('stepTwo') stepTwo: StepTwoMultipleComponent;

  @Input() building: BuildingDto;
  @Input() user: User;
  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private modalController: ModalController,
    public stepsService: StepsService,
    private translateService: TranslateService,
    private toastController: ToastController,
    private candidatureService: CandidaturesApiService,
    private notificationsService: NotificationsApiService,
    private authenticationService: AuthService
  ) {
    this.stepsService.createCandidatureAsset = new CreateCandidatureDto();
    this.stepsService.userRequestCreateCandidatureDto.users = [];
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
    this.stepsService.createCandidatureAsset = new CreateCandidatureDto();
  }

  selectChange(index: number, stepsForm: NgForm): void {
    switch (index) {
      case 2: {
        if (!this.stepsService.userRequestCreateCandidatureDto.users) {
          this.selectedUser(stepsForm.form.value.stepTwo);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  submitForm(): void {
    this.saving = true;
    if (
      !this.stepsService.userRequestCreateCandidatureDto.users &&
      this.stepsService.userRequestCreateCandidatureDto.users.length === 0
    ) {
      this.presentToast(
        false,
        this.translateService.instant(
          'pages.add-tenant.multiple_candidature.error_multiple_candidature_form'
        )
      );
      this.saving = false;
    } else {
      const assetId = this.stepsService.createCandidatureAsset.asset.id;
      const userRequestCreateCandidatureDto =
        new UserRequestCreateMultipleCandidatureDto();
      userRequestCreateCandidatureDto.users =
        this.stepsService.userRequestCreateCandidatureDto.users;
      this.candidatureService
        .homeOwnerRegisterCandidatureMultipleForAllSelected(
          assetId,
          userRequestCreateCandidatureDto
        )
        .subscribe(
          (data: any) => {
            // Se crean las notificaciones de candidatura
            this.createNotifications(
              data.id,
              this.stepsService.createCandidatureAsset.asset,
              userRequestCreateCandidatureDto
            );
            this.dismiss();
            this.saving = false;
            this.presentToast(true);
          },
          (error) => {
            this.saving = false;
            if (error.status === 409) {
              this.presentToast(
                false,
                this.translateService.instant(
                  'pages.add-tenant.multiple_candidature.error_tenant_other_candidature'
                )
              );
            } else {
              this.presentToast(false);
            }
          }
        );
    }
  }

  private createNotifications(
    candidatureId: string,
    asset: AssetCreateCandidatureTenantDto,
    userRequestCreateCandidatureDto: UserRequestCreateMultipleCandidatureDto
  ): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.CANDIDATURE;
    notification.candidatureId = candidatureId;
    notification.address = `${asset.street} ${asset.number}, ${asset.town} (${asset.province})`;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    if (!!asset.vppModality) {
      notification.vpp = true;
    }
    userRequestCreateCandidatureDto.users?.forEach((user) => {
      notification.recipientEmail = user.email;
      this.notificationsService
        .newNotification(notification)
        .subscribe(() => null);
    });
  }

  async presentToast(ok: boolean, message?: string): Promise<void> {
    let messageKey = ok
      ? 'components.add-candidature_modal.candidature_created'
      : 'components.add-candidature_modal.candidature_fail_created';
    const colorKey = ok ? 'success' : 'danger';
    if (message) {
      messageKey = message;
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  nextIndex(): void {
    this.selectedIndex++;
    this.stepper?.next();
  }

  previousIndex(): void {
    this.selectedIndex--;
    this.stepper?.previous();
  }

  selectedAsset(e): void {
    // Objeto necesario para cargar el resumen de la candidatura al final
    this.stepsService.createCandidatureAsset.asset = e;
    this.stepsService.createCandidatureAsset.user = [];
    // Objeto con el que jugar en el componente
    this.stepsService.userRequestCreateCandidatureDto.users = [];
  }

  selectedUser(e): void {
    if (this.isUniqueUser(e)) {
      this.stepsService.userRequestCreateCandidatureDto.users.push(e);
      this.stepsService.createCandidatureAsset.user.push(e);
    }
    this.checkCandidature();
    this.checkMaximunTenantForCandidature();
  }

  isUniqueUser(e): boolean {
    if (e) {
      return (
        this.stepsService.userRequestCreateCandidatureDto.users
          .map((it) => it.email)
          .indexOf(e.email) === -1
      );
    } else {
      return false;
    }
  }

  checkCandidature(): boolean {
    if (this.stepsService.userRequestCreateCandidatureDto.users === undefined) {
      this.stepsService.userRequestCreateCandidatureDto.users = [];
      this.stepsService.createCandidatureAsset.user = [];
    }
    if (this.stepsService.userRequestCreateCandidatureDto.users?.length > 0) {
      this.stepsService.userRequestCreateCandidatureDto.users.forEach((it) => {
        if (it.email.length > 5 && it.email.includes('@')) {
          return false;
        }
      });
    } else {
      return true;
    }
  }

  checkStep(): boolean {
    if (this.selectedIndex === 0) {
      if (
        this.stepsService.createCandidatureAsset &&
        this.stepsService.createCandidatureAsset.asset
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        this.selectedIndex === 1 &&
        this.stepsService.userRequestCreateCandidatureDto.users &&
        this.stepsService.userRequestCreateCandidatureDto.users.length === 0
      ) {
        return true;
      } else {
        if (
          this.stepsService.userRequestCreateCandidatureDto.users &&
          this.stepsService.userRequestCreateCandidatureDto.users.length > 0
        ) {
          this.stepsService.userRequestCreateCandidatureDto.users.forEach(
            (it) => {
              if (it.email.length > 5 && it.email.includes('@')) {
                return false;
              }
            }
          );
        } else {
          return true;
        }
      }
    }
  }

  checkMaximunTenantForCandidature(): void {
    if (
      this.stepsService.userRequestCreateCandidatureDto.users &&
      this.stepsService.userRequestCreateCandidatureDto.users.length >=
        this.stepsService.MAXIMUN_TENANT_ON_CANDIDATURE
    ) {
      this.maximunTenantsReached = true;
      this.presentToast(
        false,
        this.translateService.instant(
          'pages.add-tenant.multiple_candidature.error_max_tenant_candidature'
        )
      );
    } else {
      this.maximunTenantsReached = false;
    }
  }

  removeUser(userEmail: any): void {
    const user: UserCreateCandidatureDto =
      this.stepsService.userRequestCreateCandidatureDto.users.find((tenant) => {
        return tenant.email === userEmail;
      });
    const idList = [user.id];
    if (user.coTenants) {
      user.coTenants.forEach((cotenantId) => {
        idList.push(cotenantId);
        const index =
          this.stepsService.userRequestCreateCandidatureDto.users.findIndex(
            (user) => {
              return user.id === cotenantId;
            }
          );
        this.stepsService.userRequestCreateCandidatureDto.users.splice(
          index,
          1
        );
        this.stepsService.createCandidatureAsset.user.splice(index, 1);
      });
    }

    // Ahora borramos del listado al propio usuario
    const index =
      this.stepsService.userRequestCreateCandidatureDto.users.findIndex(
        (userCandidature) => {
          return userCandidature.email === user.email;
        }
      );
    this.stepsService.userRequestCreateCandidatureDto.users.splice(index, 1);
    this.stepsService.createCandidatureAsset.user.splice(index, 1);

    this.stepTwo.removeUserFromParent(idList);
    this.checkMaximunTenantForCandidature();
  }

  mask(name): string {
    const regex1 = /\b[^\W]{2}([^\W]{1,2})\b/g;
    const regex2 = /\b[^\W]{2}([^\W]{2,})[^\W]\b/g;
    return name.replace(regex1, '**$1').replace(regex2, '**$1*');
  }
}
