import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { User } from '@core/models';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  CurrentAddressFormGroup,
  PersonalDataFormGroup,
  ProfileForm
} from '@shared/models/form-profile.model';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

@Component({
  selector: 'el-buen-inquilino-tenant-profile-form',
  templateUrl: './tenant-profile-form.component.html'
})
export class TenantProfileFormComponent implements OnInit, OnChanges {
  @Input({ required: true }) user: User;
  form: FormGroup<ProfileForm>;
  validationErrorMessages = appValidators.validationErrorMessages;
  maxDate: Date;
  country: CountryCode = 'ES';

  get firstnameControl(): FormControl<string> {
    return this.form.controls.personalData.controls.firstname;
  }

  get surname1Control(): FormControl<string> {
    return this.form.controls.personalData.controls.surname1;
  }

  get surname2Control(): FormControl<string> {
    return this.form.controls.personalData.controls.surname2;
  }

  get dniControl(): FormControl<string> {
    return this.form.controls.personalData.controls.dni;
  }

  get birthDateControl(): FormControl<Date> {
    return this.form.controls.personalData.controls.birthDate;
  }

  get birthPlaceControl(): FormControl<string> {
    return this.form.controls.personalData.controls.birthPlace;
  }

  get emailControl(): FormControl<string> {
    return this.form.controls.personalData.controls.email;
  }

  get phoneControl(): FormControl<string> {
    return this.form.controls.personalData.controls.phone;
  }

  get streetControl(): FormControl<string> {
    return this.form.controls.currentAddress.controls.street;
  }

  get numberControl(): FormControl<string> {
    return this.form.controls.currentAddress.controls.number;
  }

  get cityControl(): FormControl<string> {
    return this.form.controls.currentAddress.controls.city;
  }

  get postalCodeControl(): FormControl<string> {
    return this.form.controls.currentAddress.controls.postalCode;
  }

  @Output() updateEmitter = new EventEmitter<FormGroup<ProfileForm>>();

  constructor(
    private utilsService: UtilsService,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.setMaxDate();
    this.getPhoneCountry();
    this.initializeForm();
  }

  ngOnChanges(): void {
    this.initializeForm();
  }

  getPhoneCountry(): void {
    this.country = this.phoneMaskService.getPhoneCountry(this.user.phone);
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  update(): void {
    this.updateEmitter.emit(this.form);
  }

  private setMaxDate(): void {
    this.maxDate = this.utilsService.calculate18YearsAgoStringDate();
  }

  private initializeForm(): void {
    this.form = new FormGroup<ProfileForm>({
      personalData: this.getPersonalDataFormGroup(),
      currentAddress: this.getCurrentAddressFormGroup()
    });
  }

  private getPersonalDataFormGroup(): FormGroup<PersonalDataFormGroup> {
    return new FormGroup<PersonalDataFormGroup>({
      firstname: this.getFirstnameControl(),
      surname1: this.getSurname1Control(),
      surname2: this.getSurname2Control(),
      dni: this.getDniControl(),
      birthDate: this.getBirthDateControl(),
      birthPlace: this.getBirthPlaceControl(),
      email: this.getEmailControl(),
      phone: this.getPhoneControl()
    });
  }

  private getFirstnameControl(): FormControl<string> {
    return new FormControl<string>(this.user?.firstname || null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
      appValidators.namesValidator
    ]);
  }

  private getSurname1Control(): FormControl<string> {
    return new FormControl<string>(this.user?.surname1 || null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(40),
      appValidators.namesValidator
    ]);
  }

  private getSurname2Control(): FormControl<string> {
    return new FormControl<string>(this.user?.surname2 || null);
  }

  private getDniControl(): FormControl<string> {
    return new FormControl<string>(this.user?.dni || null, [
      Validators.required,
      Validators.maxLength(9),
      appValidators.dniAndNieAndPassportValidator
    ]);
  }

  private getBirthDateControl(): FormControl<Date> {
    return new FormControl<Date>(this.user?.birthDate || null, [
      Validators.required
    ]);
  }

  private getBirthPlaceControl(): FormControl<string> {
    return new FormControl<string>(this.user?.birthPlace || null, [
      Validators.required
    ]);
  }

  private getEmailControl(): FormControl<string> {
    return new FormControl<string>(this.user?.email || null);
  }

  private getPhoneControl(): FormControl<string> {
    let value = '';

    if (!!this.user.phone) {
      value = this.phoneMaskService.formatPhone(this.user.phone, this.country);
    }

    return new FormControl<string>(value, this.getPhoneControlValidators());
  }

  private getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  private getCurrentAddressFormGroup(): FormGroup<CurrentAddressFormGroup> {
    return new FormGroup<CurrentAddressFormGroup>({
      street: this.getStreetControl(),
      number: this.getNumberControl(),
      city: this.getCityControl(),
      postalCode: this.getPostalCodeControl()
    });
  }

  private getStreetControl(): FormControl<string> {
    return new FormControl<string>(this.user?.street || null);
  }

  private getNumberControl(): FormControl<string> {
    return new FormControl<string>(this.user?.number?.toString() || null);
  }

  private getCityControl(): FormControl<string> {
    return new FormControl<string>(this.user?.city || null);
  }

  private getPostalCodeControl(): FormControl<string> {
    return new FormControl<string>(this.user?.postalCode || null);
  }
}
