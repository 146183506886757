import { TypeUserEnum, User } from './users.model';

export class DataLogin {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class DataSignIn {
  user: User;
  password: string;

  constructor(user: User, password: string) {
    this.user = user;
    this.password = password;
  }
}

export class Credentials {
  token: string;
  tokenRefresh: string;
  typeUser: TypeUserEnum;

  constructor(token: string, typeUser: TypeUserEnum, tokenRefresh: string) {
    this.token = token;
    this.typeUser = typeUser;
    this.tokenRefresh = tokenRefresh;
  }
}

export interface AuthenticationPage {
  showLogin: boolean;
}
