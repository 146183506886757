import { Pipe, PipeTransform } from '@angular/core';
import {
  Candidature,
  CandidatureStatusEnum,
  TenantCandidature
} from '@core/models';

@Pipe({
  name: 'showPensionerTotalAmount'
})
export class ShowPensionerTotalAmountPipe implements PipeTransform {
  transform(tenant: TenantCandidature, candidature: Candidature): boolean {
    return (
      tenant.pensionerTotalAmount &&
      tenant.user.retired &&
      candidature.candidatureStatusEnum !==
        CandidatureStatusEnum.WITHOUT_CHECKING
    );
  }
}
