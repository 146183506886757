import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinaerGuard } from '@core/guards/private/finaer.guard';
import { InsuranceCompanyGuard } from '@core/guards/private/insurance-company.guard';

import { FinaerModule } from './modules/finaer/finaer.module';
import { InsuranceCompaniesModule } from './modules/insurance-companies/insurance-companies.module';
import { ArrentaLoginModule } from '../../../public/modules/arrenta-login/arrenta-login.module';
import { FinaerLoginModule } from '../../../public/modules/finaer-login/finaer-login.module';
import { InsuranceCompaniesLoginModule } from '../../../public/modules/insurance-companies-login/insurance-companies-login.module';

const routes: Routes = [
  // FINAER
  {
    path: 'finaer-backoffice',
    loadChildren: async (): Promise<typeof FinaerModule> =>
      (await import('./modules/finaer/finaer.module')).FinaerModule,
    canLoad: [FinaerGuard],
    canActivate: [FinaerGuard]
  },
  {
    path: 'finaer-backoffice-login',
    loadChildren: async (): Promise<typeof FinaerLoginModule> =>
      (await import('../../../public/modules/finaer-login/finaer-login.module'))
        .FinaerLoginModule
  },
  // END FINAER

  // INSURANCE COMPANIES
  {
    path: 'insurance-companies-login',
    loadChildren: async (): Promise<typeof InsuranceCompaniesLoginModule> =>
      (
        await import(
          // eslint-disable-next-line max-len
          '../../../public/modules/insurance-companies-login/insurance-companies-login.module'
        )
      ).InsuranceCompaniesLoginModule
  },
  {
    path: 'arrenta-admin',
    loadChildren: async (): Promise<typeof ArrentaLoginModule> =>
      (
        await import(
          '../../../public/modules/arrenta-login/arrenta-login.module'
        )
      ).ArrentaLoginModule
  },
  {
    path: 'insurance-companies',
    loadChildren: async (): Promise<typeof InsuranceCompaniesModule> =>
      (await import('./modules/insurance-companies/insurance-companies.module'))
        .InsuranceCompaniesModule,
    canLoad: [InsuranceCompanyGuard],
    canActivate: [InsuranceCompanyGuard]
  }

  // END INSURANCE COMPANIES
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThirdpartyRoutingModule {}
