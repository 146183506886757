import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sinister, SinisterPhase } from '@core/models';
import { ModalController } from '@ionic/angular';

import { DisplayedColumns } from '../../models/update-sinister-modal.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-sinister-chronology-modal',
  templateUrl: './sinister-chronology-modal.component.html'
})
export class SinisterChronologyModalComponent implements OnInit {
  @Input({ required: true }) sinister: Sinister;

  displayedColumnsEnum = DisplayedColumns;
  displayedColumns: string[] = [DisplayedColumns.DATE, DisplayedColumns.STATUS];
  dataSource: MatTableDataSource<SinisterPhase> =
    new MatTableDataSource<SinisterPhase>([]);

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.setDataSource();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  private setDataSource(): void {
    this.dataSource = new MatTableDataSource<SinisterPhase>(
      copyObject(this.sinister.phases.reverse()) as SinisterPhase[]
    );
  }
}
