/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NavigationService } from '../services/navigation/navigation.service';
import { StoreService } from '../services/store/store.service';

const HEADER_AUTHORIZACION_KEY = 'Authorization';
const TOKEN_BEARER_PREFIX = 'Bearer ';
const HTTP_STATUS_FORBIDDEN = 403;
const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_NOT_FOUND = 404;

export const IS_NO_TOKEN_REQUEST = new HttpContextToken(() => false);
export function noTokenRequest(): HttpContext {
  return new HttpContext().set(IS_NO_TOKEN_REQUEST, true);
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  messageForbidden: string;
  user: User;

  constructor(
    private storeService: StoreService,
    private navigationService: NavigationService,
    private auth: Auth
  ) {
    this.auth.onAuthStateChanged((user) => {
      this.user = user;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.endsWith('.json')) {
      const token = this.storeService.getCredentials()?.token;

      if (!token) {
        return next.handle(req);
        // si persisten problemas de session con el refreshToken aqui se puede controlar
        // return next.handle(req).pipe(tap(
        //   () => {
        //     console.log('handle oK Inter user',this.user);
        //   },
        //   (error)=> this.logoutRedirection()
        // ));
      }
      let headers: HttpRequest<any>;

      if (isNoTokenRequest(req)) {
        headers = req.clone();
      } else {
        headers = req.clone({
          headers: req.headers.set(
            HEADER_AUTHORIZACION_KEY,
            TOKEN_BEARER_PREFIX + token
          )
        });
      }
      return next.handle(headers).pipe(
        tap(
          () => {},
          (error) => {
            if (
              error.status === HTTP_STATUS_FORBIDDEN ||
              error.status === HTTP_STATUS_UNAUTHORIZED
            ) {
              return this.refreshToken(req, next);
            } else {
              if (error.status === HTTP_STATUS_NOT_FOUND) {
                const tokenDecoded = jwt_decode(token);
                if (tokenDecoded.user_id !== this.user.uid) {
                  this.logoutRedirection();
                }
              }
              if (error.status !== HTTP_STATUS_NOT_FOUND) {
                console.error(error);
              }
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }

  private refreshToken(req: HttpRequest<any>, next: HttpHandler) {
    if (this.user) {
      return this.user
        .getIdToken(true)
        .then((idToken) => {
          this.storeService.setToken(idToken);
          const headers = req.clone({
            headers: req.headers.set(
              HEADER_AUTHORIZACION_KEY,
              TOKEN_BEARER_PREFIX + idToken
            )
          });
          return next.handle(headers);
        })
        .catch((_error) => {
          this.navigationService.logout();
        });
    } else {
      this.logoutRedirection();
    }
  }

  private logoutRedirection() {
    window.location.href.includes('certifications')
      ? this.navigationService.logout(null, 'certifications', null)
      : window.location.href.includes('request-analysis-list') ||
          window.location.href.includes('request-analysis')
        ? this.navigationService.logout(null, 'incofisa', null)
        : window.location.href.includes('finaer-backoffice')
          ? this.navigationService.logout(null, 'finaer-back-office', null)
          : window.location.href.includes('customer-care')
            ? this.navigationService.logout(null, 'customer-care', null)
            : this.navigationService.logout();
  }
}

function isNoTokenRequest(request: HttpRequest<unknown>): boolean {
  return request.context.get(IS_NO_TOKEN_REQUEST);
}
