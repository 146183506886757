/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { ApiDto } from '@core/models';
import { UtilsService, prepareFile } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';

import { ContactNewApiComponent } from '../contact-new-api/contact-new-api.component';

@Component({
  selector: 'el-buen-inquilino-upload-assets-excel-modal',
  templateUrl: './upload-assets-excel-modal.component.html'
})
export class UploadAssetsExcelModalComponent implements OnInit {
  @Input() apis: ApiDto[] = [];
  file: File;
  useAgent = true;
  form: UntypedFormGroup;

  @Input() isPortfolioPage = false;

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.isPortfolioPage) {
      this.form = new UntypedFormGroup({
        file: new UntypedFormControl(null, [Validators.required])
      });
    } else {
      this.form = new UntypedFormGroup({
        file: new UntypedFormControl(null, [Validators.required]),
        selectedApiId: new UntypedFormControl(null, [
          this.useAgent ? Validators.required : null
        ])
      });
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  changeListener(event): void {
    this.file = prepareFile(event.target.files[0] as File);
  }

  toggleChange(event): void {
    const value = event.detail.checked;
    if (!value) {
      this.form.get('selectedApiId').setValue(null);
      this.form.get('selectedApiId').clearValidators();
      this.form.get('selectedApiId').updateValueAndValidity();
    } else {
      this.form.get('selectedApiId').setValidators([Validators.required]);
      this.form.get('selectedApiId').updateValueAndValidity();
    }
    this.useAgent = value;
  }

  import(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    let selectedApiId: string = null;
    let apiAdminId: string = null;
    if (!this.isPortfolioPage && this.useAgent) {
      selectedApiId = this.form.get('selectedApiId').value;
      apiAdminId = this.apis.find((api) => api.id === selectedApiId)?.adminId;
    }

    this.modalController.dismiss({
      file: this.file,
      selectedApiId,
      apiAdminId
    });
  }

  async contactNewApiModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ContactNewApiComponent,
      cssClass: 'auto-height modal-extend',
      id: 'contactNewApiComponent'
    });

    return await modal.present();
  }
}
