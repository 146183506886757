import { Pipe, PipeTransform } from '@angular/core';
import { PolicyIssue, Sinister } from '@core/models';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';
import { format } from 'date-fns';

@Pipe({
  name: 'homeInsurancesInitDateData'
})
export class HomeInsurancesInitDateDataPipe implements PipeTransform {
  transform(value: PolicyIssue | Sinister, type: ChildRoutesEnum): string {
    if (type === ChildRoutesEnum.ACTIVE) {
      return (value as PolicyIssue).inceptionDate;
    } else {
      const date = new Date((value as Sinister).creationDate);
      return format(date, 'dd/MM/yyyy');
    }
  }
}
