import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { SinisterDocumentType, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService, prepareFile } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';
import { first } from 'rxjs';

import { AddSinisterDocumentModalForm } from '../../models/add-sinister-document.model';

@Component({
  selector: 'el-buen-inquilino-add-sinister-document',
  templateUrl: './add-sinister-document.component.html'
})
export class AddSinisterDocumentComponent implements OnInit {
  @Input({ required: true }) type: SinisterDocumentType;
  @Input({ required: true }) user: User;
  @Input({ required: true }) policyNumber: string;
  @Input() disabledTypeDocument = false;
  @Input() showContractTermination = false;

  form: FormGroup<AddSinisterDocumentModalForm>;
  file: File;
  sinisterDocumentTypeEnum = SinisterDocumentType;
  validationErrorMessages = appValidators.validationErrorMessages;

  get typeControl(): FormControl<SinisterDocumentType> {
    return this.form.controls.type;
  }

  get fileControl(): FormControl<File> {
    return this.form.controls.file;
  }

  get accept(): string {
    // eslint-disable-next-line max-len
    return 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/jpeg, image/png, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/vnd.oasis.opendocument.text';
  }

  get showFileExtensionError(): boolean {
    if (!!this.file && !this.accept.includes(this.file?.type)) {
      return true;
    }
    return false;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private candidatureService: CandidaturesApiService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(saving: boolean = false): void {
    this.modalController.dismiss({ saving });
  }

  changeListener(event: Event): void {
    this.file = prepareFile((event.target as HTMLInputElement).files[0]);
  }

  async saveDocument(): Promise<void> {
    if (this.form.invalid || this.showFileExtensionError) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    await this.loading.presentSecondLoader(null);
    this.candidatureService
      .addSinisterDocument(this.policyNumber, this.file, this.typeControl.value)
      .pipe(first())
      .subscribe({
        next: () => this.onSuccessSaveDocument(),
        error: async () => await this.loading.dismissSecondLoader()
      });
  }

  async onSuccessSaveDocument(): Promise<void> {
    await this.loading.dismissSecondLoader();
    this.dismiss(true);
  }

  private setForm(): void {
    this.form = new FormGroup<AddSinisterDocumentModalForm>({
      type: new FormControl<SinisterDocumentType>(
        this.type,
        Validators.required
      ),
      file: new FormControl<File>(null, Validators.required)
    });
  }
}
