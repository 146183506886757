import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { DataSignIn, TypeUserEnum, User, UserDto } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { ToastController } from '@ionic/angular';

const TITLE_TENANT = 'pages.login.register.tenant';
const TITLE_HOMEOWNER = 'pages.login.register.homeowner';
const TITLE_SIGNIN = 'pages.login.register.signin';
const TITLE_GUARANTOR = 'pages.login.register.guarantor';

@Component({
  selector: 'el-buen-inquilino-data-signin',
  templateUrl: './data-signin.component.html'
})
export class DataSigninComponent implements OnInit {
  @Output() login = new EventEmitter();
  @Output() register = new EventEmitter<DataSignIn>();
  typeUser = TypeUserEnum.ANONYMOUS;
  typeUserRadio: string;
  user = new User();
  password: string;
  confirmPassword: string;
  title = TITLE_SIGNIN;
  params: Params;
  paramsId: string;
  idContact: string;
  mobileRegister = false;
  emailRegister = false;
  gmailRegister = false;
  isGuarantor = false;

  constructor(
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private toastController: ToastController,
    private navigationService: NavigationService,
    private usersService: UsersApiService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // Título de la página
    if (this.typeUser === TypeUserEnum.TENANT) {
      this.title = TITLE_TENANT;
    } else if (this.typeUser === TypeUserEnum.HOMEOWNER) {
      this.title = TITLE_HOMEOWNER;
    } else {
      this.title = TITLE_SIGNIN;
    }

    this.route.queryParams.subscribe((params: Params) => {
      if (params && params.id) {
        // Si se recibe un id, entonces es un tenant invitado desde candidature
        // y se busca en la colección userRegisterRequest
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.paramsId = params.id;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.fillUserFromUserRegisterRequest(params.id);
        // this.user.email = decodeURIComponent(params.email);
        if (params.guarantor) {
          this.title = TITLE_GUARANTOR;
          this.isGuarantor = true;
        }
      }
    });
    this.user.userType = this.typeUser;
  }

  choosePhone(): void {
    this.mobileRegister = true;
    this.emailRegister = false;
    this.gmailRegister = false;
  }

  chooseEmail(): void {
    this.emailRegister = true;
    this.mobileRegister = false;
    this.gmailRegister = false;
  }

  chooseGmail(): void {
    this.authService.gmailLogin(this.user);
  }

  // Descomentar si queremos logarnos por redirección
  /*async verifyUserRedirect(): Promise<void> {
    await firebase.auth().getRedirectResult().then(auth => {
        console.log(auth);
        // user property exists; this was a redirect
        if (auth.user) {
          this.authService.processAlternativeTokenLogin(auth, 'gmail');
        }
    }, (error) => {
        console.log('error');
        console.log(error);
    });
  }*/

  gobackLoginType(): void {
    this.emailRegister = false;
    this.mobileRegister = false;
  }

  clickLogin(): void {
    this.snackBarService.hide();
    this.navigationService.goLoginByTypeUser(this.typeUser, this.idContact);
    this.login.emit(null);
  }

  clickMailRegister(dataSignIn: DataSignIn): void {
    this.register.emit(dataSignIn);
  }

  back(): void {
    if (this.params?.signin) {
      if (this.params.typeUser === TypeUserEnum.TENANT) {
        this.navigationService.goLandingTenant();
      } else {
        this.navigationService.goLandingHomeOwner();
      }
    } else {
      this.navigationService.goLanding();
    }
  }

  async presentToast(messages?: string): Promise<void> {
    const toast = await this.toastController.create({
      message:
        '<ion-icon name="alert-circle-outline" slot="start"></ion-icon> ' +
        messages,
      position: 'top',
      color: 'dark',
      duration: 2000
    });
    toast.present();
  }

  private fillUserFromUserRegisterRequest(requestId: string): void {
    this.typeUser = TypeUserEnum.TENANT;
    this.typeUserRadio = TypeUserEnum.TENANT;
    this.usersService
      .getUserRegisterRequestById(requestId)
      .subscribe((userRequestRegister: UserDto) => {
        this.mapUserForm(userRequestRegister);
      });
  }

  private mapUserForm(userRegisterRequest: UserDto): void {
    this.user.email = userRegisterRequest.email;
    this.user.firstname = userRegisterRequest.firstname;
    this.user.surname1 = userRegisterRequest.surname1;
    this.user.surname2 = userRegisterRequest.surname2;
    this.user.phone = userRegisterRequest.phone;
    this.user.userType = this.typeUser;
    this.user.guarantor = userRegisterRequest.guarantor;
  }
}
