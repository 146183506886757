import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  CreateCandidatureDto,
  UserCreateCandidatureDto,
  UserRequestCreateMultipleCandidatureDto
} from '../../models';

@Injectable()
export class StepsService {
  userRequestCreateCandidatureDto =
    new UserRequestCreateMultipleCandidatureDto();

  tenants = signal<UserCreateCandidatureDto[]>([]);

  MAXIMUN_TENANT_ON_CANDIDATURE = 8;
  invalidTenant = false;
  invalidGuarantor = false;
  invalidOwner = false;
  businessError = false;

  private pCreateCandidatureAsset = new CreateCandidatureDto();
  private createCandidatureAssetDtoSubject$ =
    new BehaviorSubject<CreateCandidatureDto>(new CreateCandidatureDto());
  createCandidatureAssetDto$ =
    this.createCandidatureAssetDtoSubject$.asObservable();

  get createCandidatureAsset(): CreateCandidatureDto {
    return this.pCreateCandidatureAsset;
  }
  set createCandidatureAsset(v: CreateCandidatureDto) {
    this.pCreateCandidatureAsset = v;
    this.createCandidatureAssetDtoSubject$.next(v);
  }

  addTenant(newTenant: UserCreateCandidatureDto): void {
    this.tenants.update((list: UserCreateCandidatureDto[]) => [
      ...list,
      newTenant
    ]);
  }

  setTenants(list: UserCreateCandidatureDto[]): void {
    this.tenants.set(list);
  }

  restart(): void {
    this.userRequestCreateCandidatureDto =
      new UserRequestCreateMultipleCandidatureDto();
    this.setTenants([]);
    this.invalidTenant = false;
    this.invalidGuarantor = false;
    this.invalidOwner = false;
    this.businessError = false;
  }
}
