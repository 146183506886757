/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration } from 'chart.js';
import * as moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  AllDataOwnerAnalytics,
  AssetStatistic,
  PolicyIssue,
  RequestAnalysisByCandidature
} from '../../models';
import { CandidaturesApiService } from '../candidatures-api/candidatures-api.service';

@Injectable()
export class HomeownerAnalyticsApiService {
  url: string;
  urlCloudFunctions: string;
  originalInsurances: PolicyIssue[] = [];
  allStadisticsData: AllDataOwnerAnalytics;

  constructor(
    private http: HttpClient,
    private candidaturesService: CandidaturesApiService,
    private translateService: TranslateService
  ) {
    this.url = environment.services.assets;
    this.urlCloudFunctions = environment.services.ebiCloudFunctions;
  }

  getAnalysisByOwnerAnalyticsFilter(
    dateFrom: string,
    dateTo: string
  ): Observable<RequestAnalysisByCandidature[]> {
    const from = moment(dateFrom, 'DD/MM/YYYY').format('DD-MM-YYYY');
    const to = moment(dateTo, 'DD/MM/YYYY').format('DD-MM-YYYY');

    return this.http
      .get<
        RequestAnalysisByCandidature[]
      >(`${this.urlCloudFunctions}/requestAnalitycsOwnerList/${from}/${to}`)
      .pipe(
        map((resp: RequestAnalysisByCandidature[]) =>
          resp.map((request: RequestAnalysisByCandidature) => {
            request.candidature.archived =
              request?.candidature?.archived || false;
            return request;
          })
        )
      );
  }

  getAssetsStatistics(
    portFolioId?: string,
    apiId?: string,
    dateFrom?: string,
    dateTo?: string
  ): Observable<AssetStatistic[]> {
    const params = {};

    if (portFolioId) {
      params['portfolioId'] = portFolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (
      dateFrom !== null &&
      dateFrom !== '' &&
      dateTo !== null &&
      dateTo !== ''
    ) {
      const from: string = moment(dateFrom, 'DD/MM/YYYY').format('DD/MM/YYYY');
      const to: string = moment(dateTo, 'DD/MM/YYYY')
        .add(1, 'days')
        .format('DD/MM/YYYY');
      params['startDate'] = from;
      params['endDate'] = to;
    }

    return this.http
      .get<AssetStatistic[]>(`${this.url}/assets/general-statistics`, {
        params
      })
      .pipe(first());
  }

  getAllData(
    portFolioId?: string,
    apiId?: string,
    dateFrom?: string,
    dateTo?: string
  ): Observable<AllDataOwnerAnalytics> {
    const assetStatistic$ = this.getAssetsStatistics(
      portFolioId,
      apiId,
      dateFrom,
      dateTo
    );
    const insurances$ = this.candidaturesService.getContractedPoliciesResume(
      portFolioId,
      apiId,
      dateFrom,
      dateTo
    );
    const requestByCandidature$ = this.getAnalysisByOwnerAnalyticsFilter(
      dateFrom,
      dateTo
    );

    const monthLabels$ = this.calculateMonthsLabels();

    return forkJoin([
      assetStatistic$,
      insurances$,
      requestByCandidature$,
      monthLabels$
    ]).pipe(
      map(
        // eslint-disable-next-line @typescript-eslint/typedef
        ([
          assetStatisticData,
          insurancesData,
          requestByCandidatureData,
          monthLabelsData
        ]) => {
          const activityCharts = this.calculateActivityCharts(
            requestByCandidatureData,
            assetStatisticData,
            monthLabelsData
          );
          const timesCharts = this.calculateTimesCharts(
            requestByCandidatureData
          );
          const sinisterCharts = this.calculateSinisterCharts(
            insurancesData,
            monthLabelsData
          );

          return {
            insurancesData: insurancesData,
            assetStatistic: assetStatisticData,
            requestByCandidatureData: requestByCandidatureData,
            activityCharts: activityCharts,
            timesCharts: timesCharts,
            sinisterCharts: sinisterCharts
          };
        }
      )
    );
  }

  private calculateActivityCharts(
    requestByCandidatureData: RequestAnalysisByCandidature[],
    assetStatisticData: AssetStatistic[],
    monthLabelsData: string[]
  ): any {
    // Calcula los datos de activityCharts usando requestByCandidatureData
    return {
      analysisByMonth: this.calculateAnalysisByMoth(
        requestByCandidatureData,
        monthLabelsData
      ),
      ocupationRate: this.calculateOcupationRate(assetStatisticData)
    };
  }

  private calculateAnalysisByMoth(
    requestByCandidatureData: RequestAnalysisByCandidature[],
    monthLabelsData: string[]
  ): any {
    const showChart: boolean =
      requestByCandidatureData && requestByCandidatureData?.length > 0
        ? true
        : false;
    const counts = Array(12).fill(0);
    if (requestByCandidatureData && requestByCandidatureData?.length > 0) {
      for (const candidature of requestByCandidatureData) {
        const month = new Date(candidature.closingDate).getMonth();
        counts[month]++;
      }
    }

    const backgroundColor = [];
    const hoverBackgroundColor = [];

    // Ordenamos los valores de mayor a menor para luego aplicar el color distintivo
    let analysisMonthDataOrdered = [...counts];
    analysisMonthDataOrdered = analysisMonthDataOrdered.sort((a, b) => b - a);

    counts.forEach((value) => {
      let color = '#dddddd';
      let hoverColor = '#ddddddd4';
      if (value === analysisMonthDataOrdered[0]) {
        color = '#4082d0';
        hoverColor = '#4082d0d4';
      }
      backgroundColor.push(color);
      hoverBackgroundColor.push(hoverColor);
    });

    const labels = monthLabelsData;

    const analysisMonthData: ChartConfiguration<'bar'>['data'] = {
      labels,
      datasets: [
        {
          data: counts,
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor
        }
      ]
    };

    const analysisMonthOptions: ChartConfiguration<'bar'>['options'] = {
      responsive: true,
      animation: false,
      elements: {
        bar: {
          borderRadius: 10
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            callback: function (tickValue, index) {
              return this.getLabelForValue(index).slice(0, 3);
            }
          }
        },
        y: {
          display: false
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false
        }
      }
    };

    return {
      counts: counts,
      data: analysisMonthData,
      options: analysisMonthOptions,
      showChart
    };
  }

  private async calculateMonthsLabels(): Promise<string[]> {
    const monthsValues = await this.translateService
      .get('pages.home_owner_analytics')
      .toPromise();
    const labels = Object.values(monthsValues);
    return labels as string[];
  }

  private calculateOcupationRate(assetStatisticData: AssetStatistic[]): any {
    const showChart: boolean =
      assetStatisticData && assetStatisticData?.length > 0 ? true : false;
    // Filtramos los datos de estadísticas por provincia
    let labels = assetStatisticData?.map((a) => a.province);
    // Eliminamos las labels duplicadas
    labels = [...new Set(labels)];

    const dataOpen = [];
    const dataRented = [];
    labels?.forEach((province) => {
      const statistics = assetStatisticData;
      const provinceStatistics = statistics.filter(
        (s) => s.province === province
      );
      // Sacamos el tamaño de los assets sin alquilar
      dataOpen.push(
        provinceStatistics.filter((p) => p.status === 'OPEN')?.length || 0
      );
      // Sacamos el tamaño de los assets alquilados
      dataRented.push(
        provinceStatistics.filter((p) => p.status === 'RENTED')?.length || 0
      );
    });
    const volumeData: ChartConfiguration<'bar'>['data'] = {
      labels: labels,
      datasets: [
        {
          data: dataRented,
          backgroundColor: '#5dc988',
          hoverBackgroundColor: '#5dc988d4',
          label: 'Alquilados',
          stack: 'a'
        },
        {
          data: dataOpen,
          backgroundColor: '#c95d5d',
          hoverBackgroundColor: '#c95d5dd4',
          label: 'Sin alquilar',
          stack: 'a'
        }
      ]
    };

    const volumeOptions: ChartConfiguration<'bar'>['options'] = {
      responsive: true,
      animation: false,
      indexAxis: 'y',
      elements: {
        bar: {
          borderRadius: 10
        }
      },
      scales: {
        x: {
          display: false
        },
        y: {
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: true
        },
        title: {
          display: false
        }
      }
    };

    return {
      labels,
      dataOpen,
      dataRented,
      data: volumeData,
      options: volumeOptions,
      showChart
    };
  }

  private calculateTimesCharts(
    requestByCandidatureData: RequestAnalysisByCandidature[]
  ): any {
    // Calcula los datos de timesCharts usando requestByCandidatureData y policiesIssuesData

    return {
      sla: this.calculateSlA(requestByCandidatureData),
      incidentAnalysis: this.calculateAnalysisWithIncidences(
        requestByCandidatureData
      ),
      tenantByCandidature: this.calculateTenantByCandidature(
        requestByCandidatureData
      ),
      candidatureWithGuarantor: this.calculateCandidatureWithGuarantor(
        requestByCandidatureData
      )
    };
  }

  private calculateSlA(
    requestByCandidatureData: RequestAnalysisByCandidature[]
  ): any {
    const showChart: boolean =
      requestByCandidatureData && requestByCandidatureData?.length > 0
        ? true
        : false;
    // agrupar las candidaturas por diferencia de tiempo entre la fecha de creación y la fecha de cierre
    let less12 = 0;
    let between12and24 = 0;
    let more24 = 0;
    if (requestByCandidatureData && requestByCandidatureData?.length > 0) {
      for (const request of requestByCandidatureData) {
        if (!request?.creationDate || !request?.closingDate) return;
        const creationDate = new Date(request.creationDate);
        const closingDate = new Date(request.closingDate);
        const timeDiff: number = closingDate.getTime() - creationDate.getTime();
        const timeDiffHours = timeDiff / 1000 / 60 / 60;

        if (timeDiffHours < 12) {
          less12 += 1;
        } else if (timeDiffHours < 24) {
          between12and24 += 1;
        } else {
          more24 += 1;
        }
      }
    }

    const slaData: ChartConfiguration<'pie'>['data'] = {
      labels: ['Menos de 12h', 'Entre 12h y 24h', 'Más de 24h'],
      datasets: [
        {
          data: [less12, between12and24, more24],
          backgroundColor: ['#5dc988', '#60f39a', '#c95d5d'],
          hoverBackgroundColor: ['#5dc988d4', '#60f39ad4', '#c95d5dd4'],
          hoverBorderColor: ['#5dc988', '#60f39a', '#c95d5d']
        }
      ]
    };

    const slaOptions: ChartConfiguration<'pie'>['options'] = {
      responsive: true,
      animation: false
    };

    return {
      less12,
      between12and24,
      more24,
      data: slaData,
      options: slaOptions,
      showChart
    };
  }

  private calculateAnalysisWithIncidences(
    requestByCandidatureData: RequestAnalysisByCandidature[]
  ): any {
    const showChart: boolean =
      requestByCandidatureData && requestByCandidatureData?.length > 0
        ? true
        : false;
    let analysisWithIncidences = 0;
    let analysisWithoutIncidences = 0;

    for (let i = 0; i < requestByCandidatureData?.length; i++) {
      if (requestByCandidatureData[i].incidences?.length > 0) {
        analysisWithIncidences++;
      } else {
        analysisWithoutIncidences++;
      }
    }

    const numReports = analysisWithIncidences + analysisWithoutIncidences;
    const numIncidences = analysisWithIncidences;
    const incidencePercentage = (numIncidences * 100) / numReports;

    const incidencesData: ChartConfiguration<'pie'>['data'] = {
      labels: ['% Informes', '% Incidencias'],
      datasets: [
        {
          data: [100, incidencePercentage],
          backgroundColor: ['#5dc988', '#c95d5d'],
          hoverBackgroundColor: ['#5dc988d4', '#c95d5dd4'],
          hoverBorderColor: ['#5dc988', '#c95d5d']
        }
      ]
    };

    const incidencesOptions: ChartConfiguration<'pie'>['options'] = {
      responsive: true,
      animation: false
    };

    return {
      incidencePercentage,
      data: incidencesData,
      options: incidencesOptions,
      showChart
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private calculateTenantByCandidature(
    requestByCandidatureData: RequestAnalysisByCandidature[]
  ) {
    const showChart: boolean =
      requestByCandidatureData && requestByCandidatureData?.length > 0
        ? true
        : false;
    const candidaturesByTenantCount = requestByCandidatureData?.reduce(
      (acc, candidature) => {
        // número de tenants para esta candidatura
        const tenantCount = candidature.tenantList.length;
        if (tenantCount >= 5) {
          // si el número de tenants es 5 o más, incrementamos el contador en el índice 5
          acc[5] = (acc[5] || 0) + 1;
        } else {
          if (!acc[tenantCount]) {
            // si aún no hay ninguna candidatura con este número de tenants, inicializamos el contador a 0
            acc[tenantCount] = 0;
          }
          // incrementamos el contador para este número de tenants
          acc[tenantCount]++;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return acc;
      },
      []
    );
    const candidaturesByTenantNumber =
      candidaturesByTenantCount && candidaturesByTenantCount?.length > 0
        ? candidaturesByTenantCount.filter(Boolean)
        : [0]; // elimina los elementos vacíos del array

    //calculamos colores
    let candidaturesDataOrdered = [...candidaturesByTenantNumber];
    candidaturesDataOrdered = candidaturesDataOrdered.sort((a, b) => b - a);
    const backgroundColor = [];
    const hoverBackgroundColor = [];

    candidaturesByTenantNumber.forEach((value) => {
      let color = '#dddddd';
      let hoverColor = '#ddddddd4';
      if (value === candidaturesDataOrdered[0]) {
        color = '#4082d0';
        hoverColor = '#4082d0d4';
      }
      backgroundColor.push(color);
      hoverBackgroundColor.push(hoverColor);
    });
    const candidatureTenantsData: ChartConfiguration<'bar'>['data'] = {
      labels: ['1', '2', '3', '4', '5 o más'],
      datasets: [
        {
          data: candidaturesByTenantNumber ?? [0, 0, 0, 0, 0],
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor
        }
      ]
    };

    const candidatureTenantsOptions: ChartConfiguration<'bar'>['options'] = {
      responsive: true,
      animation: false,
      elements: {
        bar: {
          borderRadius: 10
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          display: false
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: function () {
              return null;
            }
          }
        }
      }
    };
    return {
      candidaturesByTenantNumber,
      backgroundColor,
      hoverBackgroundColor,
      data: candidatureTenantsData,
      options: candidatureTenantsOptions,
      showChart
    };
  }

  private calculateCandidatureWithGuarantor(
    requestByCandidatureData: RequestAnalysisByCandidature[]
  ): any {
    const showChart: boolean =
      requestByCandidatureData && requestByCandidatureData?.length > 0
        ? true
        : false;
    let candidaturesWithGuarantor = 0;
    let candidaturesWithoutGuarantor = 0;

    for (let i = 0; i < requestByCandidatureData?.length; i++) {
      const tenantCandidatureList =
        requestByCandidatureData[i].candidature.tenantCandidatureList;
      let hasGuarantor = false;
      for (let j = 0; j < tenantCandidatureList.length; j++) {
        if (tenantCandidatureList[j].user.guarantor === true) {
          hasGuarantor = true;
          break;
        }
      }
      if (hasGuarantor) {
        candidaturesWithGuarantor++;
      } else {
        candidaturesWithoutGuarantor++;
      }
    }
    const candidaturesWithGuarantorData = [
      candidaturesWithoutGuarantor,
      candidaturesWithGuarantor
    ];
    const candidatureGuarantorData: ChartConfiguration<'doughnut'>['data'] = {
      labels: ['Candidaturas', 'Con avalistas'],
      datasets: [
        {
          data: candidaturesWithGuarantorData ?? [0, 0],
          backgroundColor: ['#dddddd', '#5dc988'],
          hoverBackgroundColor: ['#ddddddd4', '#5dc988d4'],
          hoverBorderColor: ['#dddddd', '#5dc988']
        }
      ]
    };

    const candidatureGuarantorOptions: ChartConfiguration<'doughnut'>['options'] =
      {
        responsive: true,
        animation: false,
        elements: {
          bar: {
            borderRadius: 10
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          }
        }
      };

    return {
      candidaturesWithGuarantorData,
      data: candidatureGuarantorData,
      options: candidatureGuarantorOptions,
      showChart
    };
  }

  private calculateSinisterCharts(
    insurancesData: PolicyIssue[],
    monthLabelsData: string[]
  ): any {
    const showChart: boolean =
      insurancesData && insurancesData?.length > 0 ? true : false;
    // Calcula los datos de sinisterCharts usando policiesIssuesData
    return {
      sinestersByMonth: this.calculateSinesterByMonth(
        insurancesData,
        monthLabelsData
      ),
      provinceRatio: this.calculateProvinceRatio(insurancesData),
      priceRatio: this.calculatePriceRatio(insurancesData),
      showChart
    };
  }

  private calculateSinesterByMonth(
    insurancesData: PolicyIssue[],
    monthLabelsData: string[]
  ): any {
    const showChart: boolean =
      insurancesData && insurancesData?.length > 0 ? true : false;
    const totalPolicies = this.obtainPoliciesByMonth(insurancesData);
    const totalPoliciesWithSinister = this.obtainPoliciesByMonth(
      insurancesData,
      true
    );

    const labels = monthLabelsData;

    const sinesterMonthRatioData: ChartConfiguration<'bar'>['data'] = {
      labels,
      datasets: [
        {
          data: totalPoliciesWithSinister,
          backgroundColor: '#c95d5d',
          hoverBackgroundColor: '#c95d5dd4',
          label: 'Con siniestro',
          stack: 'a'
        },
        {
          data: totalPolicies,
          backgroundColor: '#dddddd',
          hoverBackgroundColor: '#ddddddd4',
          label: 'Sin siniestro',
          stack: 'a'
        }
      ]
    };

    const sinesterMonthRatioOptions: ChartConfiguration<'bar'>['options'] = {
      responsive: true,
      animation: false,
      elements: {
        bar: {
          borderRadius: 10
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            callback: function (tickValue, index) {
              return this.getLabelForValue(index).slice(0, 3);
            }
          }
        },
        y: {
          display: false
        }
      },
      plugins: {
        legend: {
          display: true
        },
        tooltip: {
          displayColors: false
        }
      }
    };

    return {
      totalPolicies,
      totalPoliciesWithSinister,
      data: sinesterMonthRatioData,
      options: sinesterMonthRatioOptions,
      showChart
    };
  }

  private calculateProvinceRatio(insurancesData: PolicyIssue[]): any {
    const showChart: boolean =
      insurancesData && insurancesData?.length > 0 ? true : false;
    const assets = insurancesData.map((e) => e.insuranceAsset);
    // Se agrupan las pólizas por el atributo province
    const groupedByProvince = assets.reduce((group, asset) => {
      const { province } = asset;
      group[province] = group[province] ?? [];
      group[province].push(asset);
      return group;
    }, {});

    // Aquí están los nombres de las provincias con póliza
    const existingProvinces = Object.keys(groupedByProvince);

    // Ahora sacamos el número de pólizas por cada provincia para engordar el quesito
    const provinceRatio = [];
    existingProvinces.forEach((provinceName) => {
      provinceRatio.push(groupedByProvince[provinceName].length);
    });

    let monthRatioDataOrdered = [...provinceRatio];
    monthRatioDataOrdered = monthRatioDataOrdered.sort((a, b) => b - a);
    const backgroundColor = [];
    const hoverBackgroundColor = [];

    provinceRatio.forEach((value) => {
      let color = '#dddddd';
      let hoverColor = '#ddddddd4';
      if (value === monthRatioDataOrdered[0]) {
        color = '#4082d0';
        hoverColor = '#4082d0d4';
      }
      backgroundColor.push(color);
      hoverBackgroundColor.push(hoverColor);
    });

    const labels = Object.keys(groupedByProvince);

    const provinceRatioData: ChartConfiguration<'pie'>['data'] = {
      labels: labels,
      datasets: [
        {
          data: provinceRatio,
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
          hoverBorderColor: backgroundColor
        }
      ]
    };

    const provinceRatioOptions: ChartConfiguration<'pie'>['options'] = {
      responsive: true,
      animation: false,
      plugins: {
        legend: {
          display: true
        }
      }
    };

    return {
      provinceRatio,
      labels,
      backgroundColor,
      hoverBackgroundColor,
      data: provinceRatioData,
      options: provinceRatioOptions,
      showChart
    };
  }
  private calculatePriceRatio(insurancesData: PolicyIssue[]): any {
    const showChart: boolean =
      insurancesData && insurancesData?.length > 0 ? true : false;
    const priceRatio = [
      insurancesData.filter((asset) => asset.assetRent < 500).length,
      insurancesData.filter(
        (asset) => asset.assetRent >= 500 && asset.assetRent <= 700
      ).length,
      insurancesData.filter(
        (asset) => asset.assetRent > 700 && asset.assetRent <= 900
      ).length,
      insurancesData.filter(
        (asset) => asset.assetRent > 900 && asset.assetRent <= 1200
      ).length,
      insurancesData.filter((asset) => asset.assetRent > 1200).length
    ];
    let priceRatioDataOrdered = [...priceRatio];
    priceRatioDataOrdered = priceRatioDataOrdered.sort((a, b) => b - a);
    const backgroundColor = [];
    const hoverBackgroundColor = [];

    priceRatio.forEach((value) => {
      let color = '#dddddd';
      let hoverColor = '#ddddddd4';
      if (value === priceRatioDataOrdered[0]) {
        color = '#4082d0';
        hoverColor = '#4082d0d4';
      }
      backgroundColor.push(color);
      hoverBackgroundColor.push(hoverColor);
    });

    const priceRatioData: ChartConfiguration<'pie'>['data'] = {
      labels: ['<500', '500-700', '700-900', '900-1200', '>1200'],
      datasets: [
        {
          data: priceRatio,
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
          hoverBorderColor: backgroundColor
        }
      ]
    };

    const priceRatioOptions: ChartConfiguration<'pie'>['options'] = {
      responsive: true,
      animation: false,
      plugins: {
        legend: {
          display: true
        }
      }
    };

    return {
      priceRatio,
      backgroundColor,
      hoverBackgroundColor,
      data: priceRatioData,
      options: priceRatioOptions,
      showChart
    };
  }

  private obtainPoliciesByMonth(
    insurancesData: PolicyIssue[],
    withSinister: boolean = false
  ): any[] {
    insurancesData?.forEach(
      (asset) => (asset.month = moment(asset.creationDate).month() + 1)
    );
    let insurances = insurancesData;
    if (withSinister) {
      insurances = insurances.filter(
        (insurance) => insurance.sinisters?.length > 0
      );
    } else {
      insurances = insurances.filter(
        (insurance) => !insurance.sinisters || insurance.sinisters.length === 0
      );
    }

    const groupedByMonth = insurances.reduce((group, asset) => {
      const { month } = asset;
      group[month] = group[month] ?? [];
      group[month].push(asset);
      return group;
    }, {});

    const monthRatioData = [
      groupedByMonth[1] ? groupedByMonth[1].length : 0,
      groupedByMonth[2] ? groupedByMonth[2].length : 0,
      groupedByMonth[3] ? groupedByMonth[3].length : 0,
      groupedByMonth[4] ? groupedByMonth[4].length : 0,
      groupedByMonth[5] ? groupedByMonth[5].length : 0,
      groupedByMonth[6] ? groupedByMonth[6].length : 0,
      groupedByMonth[7] ? groupedByMonth[7].length : 0,
      groupedByMonth[8] ? groupedByMonth[8].length : 0,
      groupedByMonth[9] ? groupedByMonth[9].length : 0,
      groupedByMonth[10] ? groupedByMonth[10].length : 0,
      groupedByMonth[11] ? groupedByMonth[11].length : 0,
      groupedByMonth[12] ? groupedByMonth[12].length : 0
    ];
    return monthRatioData;
  }
}
