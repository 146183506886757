import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import {
  Candidature,
  GeneralModalResponse,
  Sinister,
  SinisterStatusEnum,
  User,
  UserRol
} from '@core/models';
import { CandidatureUtilsService } from '@core/services/candidature-utils/candidature-utils.service';
import { LoadingService } from '@core/services/loading/loading.service';
import {
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { endOfMonth, parse } from 'date-fns';
import { first } from 'rxjs/operators';

import { ButtonsRole } from '../../models/button.model';
import {
  InsuranceOptionPopoverData,
  InsuranceOptionPopoverEnum,
  InsuranceOptionPopoverResp
} from '../../models/insurance-options-popover.model';
import { OpenClosedFilter } from '../../models/insurances-management-filters.model';
import {
  DisplayedColumnsEnum,
  SinistersChildRoutesEnum
} from '../../models/shared-sinisters.model';
import { SinisterPhaseStatusPipe } from '../../pipes/sinister-phase-status.pipe';
import { copyObject, tableSize } from '../../utils/global.utils';
import { HistoricalDebtModalComponent } from '../historical-debt-modal/historical-debt-modal.component';
import { InsuranceOptionsPopoverComponent } from '../insurance-options-popover/insurance-options-popover.component';
import { SinisterChronologyModalComponent } from '../sinister-chronology-modal/sinister-chronology-modal.component';
import { TenantListPopoverComponent } from '../tenant-list-popover/tenant-list-popover.component';
import { UpdateDebtSinisterModalComponent } from '../update-debt-sinister-modal/update-debt-sinister-modal.component';
import { UpdateSinisterModalComponent } from '../update-sinister-modal/update-sinister-modal.component';

@Component({
  selector: 'el-buen-inquilino-shared-sinisters',
  templateUrl: './shared-sinisters.component.html'
})
export class SharedSinistersComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() showSearchbarFilter = false;
  @Input() showHomeownerFilter = true;
  @Input() showHomeownerColumn = true;
  @Input() showPaginator = true;
  @Input() showTotal = true;
  @Input() portfolioId: string;
  @Input() apiId: string;
  @Input() userId: string;
  @Input() user: User;
  @Input() isOwnerPage = false;
  @Input() isSinistersPage = false;
  @Input() type: SinistersChildRoutesEnum;
  @Input() showOpenClosedFilter = true;
  @Input() showFilterAddress = true;
  @Input() showFilters = true;
  @Input() tenantSinisters: Sinister[] = [];
  @Input() doGetSinisters = true;
  @Input() doShowStatistics = true;
  @Input() originalList: Sinister[] = [];
  @Input() isCandidatureSinister = false;
  @Input() showCandidatureOption = true;

  homeOwnerFilterValue = '';
  homeOwnersList: string[] = [];
  searchInternalCode = '';
  searchbarValue: string = null;
  dateFilter: string = null;
  insuranceCodeSearchbarValue: string = null;
  policyIdFilterValue: string = null;
  sinisterStatusFilterValue: SinisterStatusEnum = null;
  sinisterStatusEnum = SinisterStatusEnum;
  userRolEnum = UserRol;

  portal: string;
  floor: string;
  door: string;

  items: Sinister[] = [];
  // TABLA
  displayedColumns = [
    DisplayedColumnsEnum.ID,
    DisplayedColumnsEnum.START_DATE,
    DisplayedColumnsEnum.ADDRESS,
    this.isOwnerPage
      ? DisplayedColumnsEnum.TENANTS
      : DisplayedColumnsEnum.EMAIL_POLICY_HOLDER,
    DisplayedColumnsEnum.STATUS,
    DisplayedColumnsEnum.DEBT,
    DisplayedColumnsEnum.DEBT_DATE,
    DisplayedColumnsEnum.INSURANCE_CODE,
    DisplayedColumnsEnum.ACTIONS
  ];

  dataSource: MatTableDataSource<Sinister> = new MatTableDataSource<Sinister>(
    []
  );
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableSize = tableSize;
  displayedColumnsEnum = DisplayedColumnsEnum;
  // END TABLA

  // Filtros por estado
  notifiedToEbiFilter = false;
  insuranceCompanyInitialFilter = false;
  insuranceCompanyBurofaxSentFilter = false;
  insuranceCompanyComplaintFilledFilter = false;
  pendingTrialFilter = false;
  scheduledEvictionFilter = false;

  getSinisterEvent = new EventEmitter();

  private openClosedFilterValue: OpenClosedFilter;
  private passBySetDataSource = false;

  get optionsButtonOffset(): number {
    let offset = 0;
    if (!this.showHomeownerColumn) {
      offset = 2;
    }

    return offset;
  }

  get premiumTotal(): number {
    let total = 0;
    this.items?.forEach(
      (sinister: Sinister) => (total += sinister.totalDebt ?? 0)
    );
    return total;
  }

  get notifiedToEbiPhase(): number {
    return (
      this.items.filter(
        (s: Sinister) => s.status === SinisterStatusEnum.NOTIFIED_TO_EBI
      )?.length ?? 0
    );
  }

  get insuranceCompanyInitialPhase(): number {
    return (
      this.items.filter(
        (s: Sinister) =>
          s.status === SinisterStatusEnum.INSURANCE_COMPANY_INITIAL_STATUS
      )?.length ?? 0
    );
  }

  get insuranceCompanyBurofaxSentPhase(): number {
    return (
      this.items.filter(
        (s: Sinister) =>
          s.status === SinisterStatusEnum.INSURANCE_COMPANY_BUROFAX_SENT
      )?.length ?? 0
    );
  }

  get insuranceCompanyComplaintFilledPhase(): number {
    return (
      this.items.filter(
        (s: Sinister) =>
          s.status === SinisterStatusEnum.INSURANCE_COMPANY_COMPLAINT_FILLED
      )?.length ?? 0
    );
  }

  get pendingTrialPhase(): number {
    return (
      this.items.filter(
        (s: Sinister) => s.status === SinisterStatusEnum.PENDING_TRIAL
      )?.length ?? 0
    );
  }

  get scheduledEvictionPhase(): number {
    return (
      this.items.filter(
        (s: Sinister) => s.status === SinisterStatusEnum.SCHEDULED_EVICTION
      )?.length ?? 0
    );
  }

  get showStatistics(): boolean {
    return (
      (!!this.user.portfolioId ||
        (!!this.user.apiRol &&
          this.user.apiRol === (this.userRolEnum.API_ADMIN as string)) ||
        !this.isOwnerPage) &&
      this.doShowStatistics &&
      this.tenantSinisters.length === 0
    );
  }

  get tableTotalDebt(): number {
    return this.dataSource.data
      .map((s: Sinister) => s.totalDebt)
      .reduce((acc: number, value: number) => acc + value, 0);
  }

  constructor(
    private loadingService: LoadingService,
    private candidatureService: CandidaturesApiService,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private toastController: ToastController,
    private sinisterPhaseStatusPipe: SinisterPhaseStatusPipe,
    private candidatureUtilsService: CandidatureUtilsService,
    private insuranceService: InsurancesApiService
  ) {}

  ngOnInit(): void {
    this.setDataSource();
    this.getSinisters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['type'] ||
      (this.isCandidatureSinister && changes['originalList'])
    ) {
      this.setList();
      this.setTableData();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  trackBySinister(_index: number, sinister: Sinister): Sinister {
    return sinister;
  }

  changeSearchbar(e: string): void {
    this.searchbarValue = e?.toLowerCase() || null;

    this.filters();
  }

  changeDateFilter(event: string): void {
    this.dateFilter = event;

    this.filters();
  }

  filterByInsuranceCode(e: string): void {
    this.insuranceCodeSearchbarValue = e || null;

    this.filters();
  }

  filterByHomeOwner(homeowner: string): void {
    // Guardamos el valor del filtro en la variable global
    this.homeOwnerFilterValue = homeowner;

    // Aplicamos los filtros
    this.filters();
  }

  filterByOpenClosed(value: OpenClosedFilter): void {
    this.openClosedFilterValue = value;

    this.filters();
  }

  filterByAddress(e: { portal: string; floor: string; door: string }): void {
    this.portal = e.portal;
    this.floor = e.floor;
    this.door = e.door;

    this.filters();
  }

  filterByStatus(status: SinisterStatusEnum, value: boolean): void {
    this.notifiedToEbiFilter = false;
    this.insuranceCompanyInitialFilter = false;
    this.insuranceCompanyBurofaxSentFilter = false;
    this.insuranceCompanyComplaintFilledFilter = false;
    this.pendingTrialFilter = false;
    this.scheduledEvictionFilter = false;

    switch (status) {
      case SinisterStatusEnum.NOTIFIED_TO_EBI:
        this.notifiedToEbiFilter = value;
        break;
      case SinisterStatusEnum.INSURANCE_COMPANY_INITIAL_STATUS:
        this.insuranceCompanyInitialFilter = value;
        break;
      case SinisterStatusEnum.INSURANCE_COMPANY_BUROFAX_SENT:
        this.insuranceCompanyBurofaxSentFilter = value;
        break;
      case SinisterStatusEnum.INSURANCE_COMPANY_COMPLAINT_FILLED:
        this.insuranceCompanyComplaintFilledFilter = value;
        break;
      case SinisterStatusEnum.PENDING_TRIAL:
        this.pendingTrialFilter = value;
        break;
      case SinisterStatusEnum.SCHEDULED_EVICTION:
        this.scheduledEvictionFilter = value;
        break;
    }

    if (value) {
      this.sinisterStatusFilterValue = status;
    } else {
      this.sinisterStatusFilterValue = null;
    }
    this.filters();
  }

  /**
   * Aplica los filtros a la lista
   */
  filters(): void {
    let items = copyObject(this.items) as Sinister[];
    if (!!this.searchbarValue && this.searchbarValue.trim() !== '') {
      items = items.filter(
        (s: Sinister) =>
          s.insuranceAsset?.address
            .toLowerCase()
            .includes(this.searchbarValue) ||
          s.policyNumber?.toLowerCase().includes(this.searchbarValue) ||
          s.emailPolicyHolder?.toLowerCase().includes(this.searchbarValue) ||
          s.insuranceAsset?.address.toLowerCase().includes(this.searchbarValue)
      );
    }

    if (
      !!this.insuranceCodeSearchbarValue &&
      this.insuranceCodeSearchbarValue.trim() !== ''
    ) {
      items = items.filter((s: Sinister) =>
        s.insuranceCode
          ?.toLowerCase()
          .includes(this.insuranceCodeSearchbarValue)
      );
    }

    if (!!this.policyIdFilterValue && this.policyIdFilterValue.trim() !== '') {
      items = items.filter((s: Sinister) =>
        s.policyNumber?.toLowerCase().includes(this.policyIdFilterValue)
      );
    }

    if (!!this.sinisterStatusFilterValue) {
      items = items.filter(
        (s: Sinister) =>
          !!s.status && s.status === this.sinisterStatusFilterValue
      );
    }

    if (!!this.dateFilter) {
      const date1 = parse(this.dateFilter, 'dd/MM/yyyy', new Date());
      const date2 = endOfMonth(date1);
      if (this.type === SinistersChildRoutesEnum.ACTIVE) {
        items = items.filter((s: Sinister) =>
          this.insuranceService.isBetween(date1, date2, new Date(s.startDate))
        );
      } else {
        items = items.filter(
          (s: Sinister) =>
            !!s.endDate &&
            this.insuranceService.isBetween(date1, date2, new Date(s.endDate))
        );
      }
    }

    this.setDataSourceData(items);
  }

  searchByPolicyId(policyId: string): void {
    this.policyIdFilterValue = policyId;
    this.filters();
  }

  searchBySinisterStatus(status: SinisterStatusEnum): void {
    this.sinisterStatusFilterValue = status;
    this.filters();
  }

  async download(userId: string, policyNumber: string): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .getPolicyReport(userId, policyNumber)
      .pipe(first())
      .subscribe({
        next: async (blob: File) => {
          await this.loadingService.dismissSecondLoader();
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              // eslint-disable-next-line max-len
              'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
            );
          }
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
        }
      });
  }

  async presentUtilsOptionPopover(
    ev: Event,
    sinister: Sinister
  ): Promise<void> {
    if (!sinister?.policyNumber) return;
    const popover = await this.popoverController.create({
      component: InsuranceOptionsPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        fromBackoffice: !this.isOwnerPage,
        policyNumber: sinister.policyNumber,
        unpaid: true,
        showCancel: false,
        showCreateSinister: false,
        isSinisterPage: true,
        showSeeDetails: true,
        showHistoricalDebt: true,
        showChronology: true,
        isClosed: this.type === SinistersChildRoutesEnum.FINALIZED,
        seeCandidature: this.showCandidatureOption
      } as InsuranceOptionPopoverData
    });
    await popover.present();

    const { data }: OverlayEventDetail<InsuranceOptionPopoverResp> =
      await popover.onWillDismiss();
    if (data) {
      switch (data?.option) {
        case InsuranceOptionPopoverEnum.SEE_DETAILS:
          this.presentSinisterDetailModal(sinister);
          // Aquí
          break;
        case InsuranceOptionPopoverEnum.UPDATE_SINISTER:
          this.updateSinister(sinister);
          // Aquí
          break;
        case InsuranceOptionPopoverEnum.HISTORICAL_DEBT:
          this.seeHistoricalDebt(sinister);
          break;
        case InsuranceOptionPopoverEnum.CHRONOLOGY:
          this.seeChronology(sinister);
          break;
        case InsuranceOptionPopoverEnum.SEE_CANDIDATURE:
          this.seeCandidature(sinister);
          break;
      }
    }
  }

  getResolvedSinisters(sinisters: Sinister[]): number {
    return sinisters?.filter((s: Sinister) => s.endDate)?.length || 0;
  }

  getUnresolvedSinisters(sinisters: Sinister[]): number {
    return sinisters?.filter((s: Sinister) => !s.endDate)?.length || 0;
  }

  async updateSinistersDebt(): Promise<void> {
    const modal = await this.modalController.create({
      component: UpdateDebtSinisterModalComponent,
      backdropDismiss: false,
      showBackdrop: true
    });

    modal
      .onDidDismiss()
      .then((resp: OverlayEventDetail<GeneralModalResponse>) => {
        if (resp.role === (ButtonsRole.ACCEPT as string)) {
          this.onSuccessUpdateSinisterDebt();
        }
      });

    modal.present();
  }

  onSuccessUpdateSinisterDebt(): void {
    // TODO: meter el emitter cuando se mergee con la rama de pestañas de candidatura seleccionada
    this.getSinisters();
  }

  exportToExcel(): void {
    this.loadingService.presentSecondLoader('Generando fichero Excel...', true);
    this.candidatureService
      .getSinisterExcel(this.portfolioId, this.apiId, this.userId)
      .pipe(first())
      .subscribe({
        next: (data: Blob) => {
          const blobUrl = window.URL.createObjectURL(data);

          // Crear un enlace oculto
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'siniestros_ebi.xlsx';

          // Simular clic en el enlace para iniciar la descarga
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Limpiar y eliminar el enlace
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);

          this.loadingService.dismissSecondLoader();
        },
        error: async () => {
          this.loadingService.dismissSecondLoader();
          const toast = await this.toastController.create({
            message: 'No se puede generar el Excel de siniestros.',
            duration: 2000,
            position: 'top',
            color: 'danger'
          });
          toast.present();
        }
      });
  }

  tableSort(event: Sort): void {
    if (!event.active || event.direction === '') {
      this.dataSource.data = this.items;
      return;
    }

    const isAsc = event.direction === 'asc';
    const data = JSON.parse(JSON.stringify(this.items)) as Sinister[];
    const sortedData = data.sort((a: Sinister, b: Sinister) => {
      switch (event.active as DisplayedColumnsEnum) {
        case DisplayedColumnsEnum.START_DATE: {
          return this.compare(a.startDate, b.startDate, isAsc);
        }
        case DisplayedColumnsEnum.ADDRESS: {
          return this.compare(
            a.insuranceAsset.address,
            b.insuranceAsset.address,
            isAsc
          );
        }
        case DisplayedColumnsEnum.EMAIL_POLICY_HOLDER: {
          return this.compare(a.emailPolicyHolder, b.emailPolicyHolder, isAsc);
        }
        case DisplayedColumnsEnum.STATUS: {
          return this.compare(
            this.sinisterPhaseStatusPipe.transform(a.status),
            this.sinisterPhaseStatusPipe.transform(b.status),
            isAsc
          );
        }
      }
    });
    this.dataSource.data = sortedData;
  }

  async showTenantListPopover(event: Event, sinister: Sinister): Promise<void> {
    const popover = await this.popoverController.create({
      component: TenantListPopoverComponent,
      componentProps: {
        list: sinister.insuranceAsset.cotenants
      },
      event
    });
    popover.present();
  }

  private setList(): void {
    if (this.tenantSinisters.length > 0) {
      this.items = this.originalList;
    } else {
      if (this.originalList?.length > 0) {
        if (this.type === SinistersChildRoutesEnum.ACTIVE) {
          this.items = this.originalList.filter((s: Sinister) => !s.endDate);
        } else {
          this.items = this.originalList.filter((s: Sinister) => s.endDate);
        }
      } else {
        this.items = [];
      }
    }
  }

  private setTableData(): void {
    if (!this.passBySetDataSource) {
      this.setDataSource();
    } else {
      this.setDataSourceData(this.items);
    }
  }

  private compare(
    a: number | Date | string,
    b: number | Date | string,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private async getSinisters(): Promise<void> {
    if (!this.isCandidatureSinister) {
      if (this.tenantSinisters.length > 0) {
        this.originalList = this.tenantSinisters;
        this.setList();
        this.setTableData();
      } else {
        await this.loadingService.presentLoading('Cargando pólizas...');
        this.candidatureService
          .getSinisterPolicies(this.portfolioId, this.apiId, this.userId)
          .pipe(first())
          .subscribe({
            next: async (list: Sinister[]) => {
              this.originalList = list.filter(
                (s: Sinister) => !s.isCancellation
              );
              this.setList();
              this.setTableData();

              await this.loadingService.dismissLoading();
            },
            error: async () => {
              await this.loadingService.dismissLoading();
            }
          });
      }
    }
  }

  private async presentSinisterDetailModal(sinister: Sinister): Promise<void> {
    const modal = await this.modalController.create({
      component: UpdateSinisterModalComponent,
      backdropDismiss: false,
      componentProps: {
        sinister,
        isOwnerPage: this.isOwnerPage,
        user: this.user,
        seeDetails: true
      } as Partial<UpdateSinisterModalComponent>
    });
    modal
      .onDidDismiss()
      .then((resp: OverlayEventDetail<GeneralModalResponse>) => {
        if (resp.data.saving) {
          if (this.isCandidatureSinister) {
            this.getSinisterEvent.emit();
          } else {
            this.getSinisters();
          }
        }
        if (this.isOwnerPage) {
          this.candidatureService
            .updateVisitedSinister(sinister.id)
            .subscribe(() => {
              if (!this.isCandidatureSinister) {
                this.getSinisters();
              }
            });
        }
      });
    await modal.present();
  }

  private async updateSinister(sinister: Sinister): Promise<void> {
    const modal = await this.modalController.create({
      component: UpdateSinisterModalComponent,
      backdropDismiss: false,
      componentProps: {
        sinister,
        isOwnerPage: this.isOwnerPage,
        user: this.user
      } as Partial<UpdateSinisterModalComponent>
    });

    modal
      .onDidDismiss()
      .then((resp: OverlayEventDetail<GeneralModalResponse>) => {
        if (resp.data.saving) {
          if (this.isCandidatureSinister) {
            this.getSinisterEvent.emit();
          } else {
            this.getSinisters();
          }
          if (this.isOwnerPage) {
            this.candidatureService
              .updateVisitedSinister(sinister.id)
              .subscribe(() => {
                if (!this.isCandidatureSinister) {
                  this.getSinisters();
                }
              });
          }
        }
      });
    await modal.present();
  }

  private async seeHistoricalDebt(sinister: Sinister): Promise<void> {
    const modal = await this.modalController.create({
      component: HistoricalDebtModalComponent,
      componentProps: {
        sinister
      } as Partial<HistoricalDebtModalComponent>
    });
    await modal.present();
  }

  private async seeChronology(sinister: Sinister): Promise<void> {
    const modal = await this.modalController.create({
      component: SinisterChronologyModalComponent,
      componentProps: {
        sinister
      } as Partial<SinisterChronologyModalComponent>
    });
    await modal.present();
  }

  private setDataSource(): void {
    this.dataSource = new MatTableDataSource<Sinister>(
      copyObject(this.items) as Sinister[]
    );
    this.dataSource.paginator = null;
    this.dataSource.paginator = this.paginator;
    this.passBySetDataSource = true;
  }

  private setDataSourceData(list: Sinister[]): void {
    this.dataSource.data = copyObject(list) as Sinister[];
    this.dataSource.paginator = this.paginator;
  }

  private async seeCandidature(sinister: Sinister): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .getCandidaturesById(sinister.candidatureId)
      .pipe(first())
      .subscribe((candidature: Candidature) =>
        this.onSuccessGetCandidature(candidature)
      );
  }

  private async onSuccessGetCandidature(
    candidature: Candidature
  ): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.candidatureUtilsService.presentSelectedCandidatureModal(candidature);
  }
}
