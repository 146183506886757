import { Component, Input } from '@angular/core';
import { ApiDto, ApiMemberDto, User } from '@core/models';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-assets-api-filter-popover',
  templateUrl: './assets-api-filter-popover.component.html'
})
export class AssetsApiFilterPopoverComponent {
  @Input() apis: ApiDto[];
  @Input() members: ApiMemberDto[];
  @Input() user: User;

  get userIsPortfolio(): boolean {
    return !!this.user.portfolioId;
  }

  constructor(private popoverController: PopoverController) {}

  onClickUtilOptions(
    option: string,
    type?: 'api' | 'member',
    object?: ApiDto | ApiMemberDto
  ): void {
    if (type && !object) {
      if (type === 'api') {
        object = new ApiDto();
        object.id = 'null';
        object.name = 'Propia';
      } else {
        object = new ApiMemberDto();
        object.id = this.user.id;
        object.firstname = 'Propia';
      }
    }

    this.popoverController.dismiss({
      option,
      type,
      object
    });
  }
}
