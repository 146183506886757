import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '@app/modules/core/models';

@Component({
  selector: 'el-buen-inquilino-tabs-menu',
  templateUrl: './tabs-menu.component.html'
})
export class TabsMenuComponent implements OnInit {
  @Input() tabs: MenuItem[] = [];
  @Output() selectedTab = new EventEmitter<string>();

  pathActive: string;

  constructor() {}

  ngOnInit(): void {
    this.pathActive = this.tabs[0].path;
  }

  navigate(tab: MenuItem): void {
    this.pathActive = tab.path;
    this.selectedTab.emit(tab.path);
  }
}
