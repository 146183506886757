import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationService {
  constructor(private translateService: TranslateService) {}

  setAppLanguage(lang: string): void {
    this.translateService.use(lang);
    localStorage.setItem('language', lang);
  }
}
