import { Pipe, PipeTransform } from '@angular/core';
import {
  Candidature,
  CandidatureStatusEnum,
  TenantCandidature
} from '@core/models';

@Pipe({
  name: 'showLastPayslipAmount'
})
export class ShowLastPayslipAmountPipe implements PipeTransform {
  transform(tenant: TenantCandidature, candidature: Candidature): boolean {
    return (
      tenant.lastPayslipAmount &&
      !tenant.user.student &&
      !tenant.user.freelance &&
      !tenant.user.retired &&
      candidature.candidatureStatusEnum !==
        CandidatureStatusEnum.WITHOUT_CHECKING
    );
  }
}
