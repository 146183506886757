import { Pipe, PipeTransform } from '@angular/core';
import { Incidence } from '@core/models';
import { incidenceStatusList } from '@core/utils/incidences-api.utils';

import { SelectOption } from '../models/select-option.model';

@Pipe({
  name: 'incidenceStatus'
})
export class IncidenceStatusPipe implements PipeTransform {
  incidenceStatusList: SelectOption[] = incidenceStatusList;
  transform(incidence: Incidence): string {
    if (!incidence.status) {
      return '';
    }
    return (
      this.incidenceStatusList.find(
        (option: SelectOption) => option.value === incidence.status
      )?.label || ''
    );
  }
}
