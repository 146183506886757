import { Pipe, PipeTransform } from '@angular/core';
import { SinisterStatusEnum } from '@core/models';

@Pipe({
  name: 'sinisterStatusChipClass'
})
export class SinisterStatusChipClassPipe implements PipeTransform {
  transform(status: SinisterStatusEnum): string {
    switch (status) {
      case SinisterStatusEnum.NOTIFIED_TO_EBI:
        return 'notified-to-ebi';
      case SinisterStatusEnum.INSURANCE_COMPANY_INITIAL_STATUS:
        return 'ic-initial';
      case SinisterStatusEnum.INSURANCE_COMPANY_BUROFAX_SENT:
        return 'ic-burofax';
      case SinisterStatusEnum.INSURANCE_COMPANY_COMPLAINT_FILLED:
        return 'ic-complaint-filled';
      case SinisterStatusEnum.PENDING_TRIAL:
        return 'pending-trial';
      case SinisterStatusEnum.SCHEDULED_EVICTION:
        return 'scheduled-eviction';
    }
  }
}
