/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreferencesApiService } from '@core/api-services/preferences-api/preferences-api.service';
import { PreferenceDTO, PreferenceValueDTO, User } from '@core/models';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { ProfilePreferencesModalComponent } from '../profile-preferences-modal/profile-preferences-modal.component';

@Component({
  selector: 'el-buen-inquilino-profile-preferences',
  templateUrl: './profile-preferences.component.html'
})
export class ProfilePreferencesComponent implements OnInit {
  activatedPreferences: PreferenceDTO[];
  subscriptions: Subscription[] = [];
  @Input() user: User;
  @Input() showButtons = true;
  @Input() fromHomeowner = false;
  @Output() refresh = new EventEmitter();

  constructor(
    private modalController: ModalController,
    private preferencesService: PreferencesApiService
  ) {}

  ngOnInit(): void {
    this.getActivatedPreferences();
  }

  getActivatedPreferences(): void {
    const subs = this.preferencesService
      .getActivatedPreferences(this.user?.id)
      .subscribe((res: PreferenceDTO[]) => {
        this.activatedPreferences = res;
        res.forEach((val: PreferenceDTO) => {
          val.elements.forEach((element: PreferenceValueDTO) => {
            element.checked = true;
          });
        });
      });
    this.subscriptions.push(subs);
  }

  async presentModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ProfilePreferencesModalComponent,
      componentProps: {
        userId: this.user.id
      }
    });
    modal.onDidDismiss().then((close) => {
      if (close && close.data && close.data.anyChange) {
        this.getActivatedPreferences();
      }
      this.refresh.emit();
    });
    return await modal.present();
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }
}
