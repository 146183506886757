/* eslint-disable @typescript-eslint/no-explicit-any */
import { SafeUrl } from '@angular/platform-browser';
import { StripeError } from '@stripe/stripe-js';
import { Observable } from 'rxjs/internal/Observable';

import { AssetDto } from './asset.model';
import { Candidature, Certification } from './candidature.model';
import { ContactAssetDto, ContactDto } from './contact.model';
import {
  AttachDocument,
  DocumentDTO,
  TypeDocumentEnum
} from './document.model';
import { FirebaseDate } from './general.model';
import { RecoveryMotivationTypeEnum } from './request-analysis.model';

export enum TypeUserEnum {
  TENANT = 'TENANT',
  HOMEOWNER = 'HOMEOWNER',
  ANONYMOUS = 'ANONYMOUS',
  INCOFISA_ADMINISTRATIVE = 'INCOFISA_ADMINISTRATIVE',
  HOMEOWNER_UNPAID = 'HOMEOWNER_UNPAID',
  CERTIFIER = 'CERTIFIER',
  CUSTOMER_CARE = 'CUSTOMER_CARE'
}

export enum StripePriceType {
  BASIC = 'BASIC',
  PRO = 'PRO',
  PREMIUM = 'PREMIUM'
}

export enum StripePriceReportType {
  BASIC_REPORT = 'BASIC_REPORT',
  PRO_REPORT = 'PRO_REPORT',
  PREMIUM_REPORT = 'PREMIUM_REPORT'
}
export interface Score {
  date: Date;
  value: number;
  real: boolean;
}
export interface ReportCount {
  requested: number;
  verified: number;
  totalRequested: number;
  totalVerified: number;
}
export interface StripeSession {
  id: string;
  paymentStatus: string;
  maxCandidaturesMonth: number;
  maxReportsMonth: number;
  planCode: string;
  expirationDate: Date;
  initialPaymentDate: string;
  sessionId: string;
  subscriptionId: string;
  userEmail: string;
  maxReportsByAssetMonth: number;
  maxCandidaturesByAssetMonth: number;
}
export interface EBIStripeError {
  error: StripeError;
}
export class User {
  id?: string;
  email: string;
  password?: string;
  firstName?: string;
  firstname: string;
  reports?: ReportCount;
  session?: StripeSession;
  surname1: string;
  surname2: string;
  score?: Score;
  street?: string;
  number?: number;
  postalCode?: string;
  city?: string;
  userType?: TypeUserEnum;
  phone?: string;
  dni?: string;
  occupation?: string;
  receiveOffers?: boolean;
  student?: boolean;
  retired?: boolean;
  business?: boolean;
  profData?: ProfDataDto;
  birthDate?: Date;
  birthPlace?: string;
  profilePhotoId?: string;
  profilePhoto?: any;
  profilePhoto$?: Observable<SafeUrl>;
  guarantor?: boolean;
  bigTenant?: boolean;
  coTenants?: string[];
  documents?: DocumentDTO[];
  freelance?: boolean;
  freeDays?: number;
  couponCode?: string;
  ccc?: string;
  allowedHomeownersForUploadDocumentation?: string[];
  professionalData?: ProfDataDto;
  incidences?: Incidence[];
  trialVersion?: boolean;
  trialWithoutScore?: boolean;
  profileType?: OccupationEnum;
  automatedReports?: boolean;
  businessName?: string;
  cif?: string;
  taxResidence?: string;
  certifications?: Certification[];
  associatedUsers?: string[];
  noValidated?: boolean;
  researchLevels?: ResearchLevels;
  defaultNotifications?: boolean;
  hasFeePocket?: boolean;
  isBottomUp?: boolean;
  language?: string;
  registerWithGoogle?: boolean;
  biometricResult?: string;
  portfolioId?: string;
  apiRolId?: string;
  apiRol?: string;
  apiId?: string;
  policyNumber?: string;
  creationDate?: Date;
  urlLogin?: string;
  commissionFee?: number;
  commissionFeeTenantRC?: number;
  unremovable?: boolean;
  backofficeRoles?: string[];
  pendingFeePocketPayment?: boolean;
  blockCandidatureStatus?: boolean;
  blockCreateAsset?: boolean;
  blockCreatePolicy?: boolean;
  blockShowAnalysisReport?: boolean;
  savePolicyHolderData?: boolean;
  policyHolderData?: PolicyHolderData;
  deletedUser?: boolean;
  automatedTenantPolicies?: boolean;
  vppSurvey?: VppSurvey;
  hasCalendar?: boolean;
  incidencesActivated?: boolean;
  foreignIncomes?: boolean;
  documentsGeneration?: boolean;
  retoolAccount?: string;
  bankAccountValidator?: boolean;
  extraDocumentation?: string;

  fullName(): string {
    let fullName = this.firstName + ' ' + this.surname1;
    if (this.surname2) {
      fullName += ' ' + this.surname2;
    }
    return fullName;
  }
}
export class UpdateUser {
  receiveOffers: boolean;
  student: boolean;
  retired: boolean;
  business: boolean;
}

export interface UpdateCompleteUser {
  bigTenant?: boolean;
  birthDate?: Date;
  birthPlace?: string;
  blockCandidatureStatus?: boolean;
  blockCreateAsset?: boolean;
  blockCreatePolicy?: boolean;
  blockShowAnalysisReport?: boolean;
  business?: boolean;
  businessName?: string;
  ccc?: string;
  cif?: string;
  city?: string;
  commissionFee?: number;
  commissionFeeTenantRC?: number;
  dni?: string;
  firstname: string;
  hasCalendar?: boolean;
  hasFeePocket?: boolean;
  isBottomUp?: boolean;
  language?: string;
  number?: string;
  phone?: string;
  portfolioId?: string;
  postalCode: string;
  receiveOffers?: boolean;
  retired?: boolean;
  street?: string;
  student?: boolean;
  surname1: string;
  surname2?: string;
  taxResidence?: string;
  trialVersion?: boolean;
  urlLogin?: string;
}

export class TenantProfessionalData {
  activityInAsset?: boolean;
  educational?: string;
  educationalLevel?: string;
  freelance?: boolean;
  linkedIn?: string;
  maritalStatus?: string;
  position?: string;
  sector?: string;
  foreignIncomes?: boolean;
}

export class ProfDataDto {
  educationalLevel: EducationalLevelEnum;
  educational: StudiesEnum;
  maritalStatus: CivilStatusEnum;
  sector: ActivitySectorEnum;
  position: ProfessionEnum;
  freelance: boolean;
  activityInAsset: boolean;
  linkedIn?: string;
  foreignIncomes?: boolean;
}

export enum CivilStatusEnum {
  CASADO = 'Casado',
  DIVORCIADO = 'Divorciado',
  SEPARADO = 'Separado',
  SOLTERO = 'Soltero',
  UNIONDEHECHO = 'Unión de hecho',
  UNIONLIBRE = 'Unión libre',
  VIUDO = 'Viudo'
}

export enum EducationalLevelEnum {
  BACHILLERATO = 'Bachillerato',
  DIPLOMATURA = 'Diplomatura',
  ESO = 'ESO',
  FPMEDIA = 'FP Media',
  FPSUPERIOR = 'FP Superior',
  LICENCIATURA = 'Licenciatura',
  OTROS = 'Otros',
  PRIMARIOS = 'Primarios',
  SINESTUDIOS = 'Sin estudios'
}

export enum StudiesEnum {
  BELLASARTES = 'Bellas artes',
  CONTABILIDAD = 'Contabilidad',
  DERECHO = 'Derecho',
  ECONOMICAS = 'Económicas',
  EDUCACION = 'Educación',
  ENERGIA = 'Energía',
  HOSTELERIA = 'Hostelería',
  INGENIERIA = 'Ingeniería',
  MEDICINA = 'Medicina',
  OTROS = 'Otros',
  SERVICIOS = 'Servicios',
  SIDERURGIA = 'Siderurgia',
  SINESTUDIOS = 'Sin Estudios',
  TECNOLIGIA = 'Tecnología',
  TURISMO = 'Turismo'
}

export enum ActivitySectorEnum {
  ADMINISTRACIONPUBLICA = 'Administración pública',
  AGRICULTURA = 'Agricultura',
  ARTE = 'Actividades artísticas',
  CONSTRUCCION = 'Construcción',
  CONTABILIDAD = 'Contabilidad',
  EDUCACION = 'Educación',
  ENERGIA = 'Energía',
  FINANCIERO = 'Financiero',
  HOSTELERIA = 'Hostelería',
  OTROS = 'Otros',
  SANIDAD = 'Sanidad',
  SERVICIOS = 'Servicios',
  SIDERURGIA = 'Siderurgia',
  SUMINISTROS = 'Suministros',
  TECNOLIGIA = 'Tecnología',
  TURISMO = 'Turismo'
}

export enum ProfessionEnum {
  ADMINISTRATIVO = 'Administrativo',
  AYUDANTENOTITULADO = 'Ayudante no titulado',
  DIRECTIVO = 'Directivo',
  INGENIEROLICENCIADO = 'Ingeniero/Licenciado',
  MENOR = 'Menores',
  OFICIALADMINISTRATIVO = 'Oficial administrativo',
  OFICIALPRIMERA = 'Oficial de 1ª o de 2ª',
  OFICIALTERCERA = 'Oficial de 3ª o especialista',
  OTROS = 'Otros',
  PEON = 'Peón',
  SUBALTERNO = 'Subalterno',
  TECNICO = 'Técnico'
}
export interface TenantDto {
  userData: UserDto;
  contactData: ContactDto;
  enterpriseData: ContactAssetDto;
}
export class UserDto {
  id?: string;
  userId?: string;
  email: string;
  firstname: string;
  surname1: string;
  surname2: string;
  postalCode: string;
  phone: string;
  dni: string;
  userType: TypeUserEnum;
  emailSender?: string;
  guarantor?: boolean;
  registerWithGoogle?: boolean;
  savePolicyHolderData?: boolean;
  policyHolderData?: PolicyHolderData;
  vppSurvey?: VppSurvey;
}
export interface PolicyHolderData {
  typeDocument: string;
  document: string;
  dateOfBirth?: Date;
  firstname?: string;
  surname1: string;
  surname2?: string;
  street: string;
  number: string;
  city: string;
  postalCode: string;
  province: string;
  phone: string;
  email: string;
  ccc: string;
}
export class HomeOwnerEnterpriseDataDto {
  cif: string;
  enterpriseName: string;
  taxResidenceOwner?: string;
}

export enum StatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export class RequestAnalysisDto {
  id: string;
  userId: string;
  candidatureId: string;
  creationDate: Date;
  endDate: Date;
  homeownerEmail: string;
  homeownerId?: string;
  incidences?: Incidence[];
  invoiced?: boolean;
  conditioning?: Conditioning;
  pendingToDownload?: boolean;
  reLaunched?: boolean;
  rentalPrice?: number;
}

export class RequestAnalysisForTenantDto {
  id: string;
  incidences?: Incidence[];
}

export class RequestAnalysisByCandidature {
  candidatureId: string;
  address: string;
  closed: boolean;
  creationDate: Date;
  closingDate: Date;
  analyses: RequestAnalysisDto[];
  tenantList: User[];
  candidature: Candidature;
  incidences?: Incidence[];
  homeownerEmail?: string;
  conditioning?: Conditioning;
  warrantyId?: string;
  hasWorklife?: boolean;
  internalEvents?: ReportInternalEventDto[];
}
export class Incidence {
  creationDate: Date;
  closingDate?: Date | null;
  description: string;
  endDate: Date;
  id: string;
  type: string;
  assetId?: string;
  candidatureId?: string;
  document?: DocumentDTO;
  buildingId?: string;
  address: string;
  attachDocuments: AttachDocument[];
  chat: ChatThread[];
  status: IncidenceStatusEnum;
  subject: string;
  typology: string;
  userId: string;
  userName: string;
  internal: boolean;
  userEmail?: string;
  userPhone?: string;
  completeAddress?: string;
  salesforceId?: string;
  houseId?: string;
  origin?: any;
}

export class IncidenceFromEbiDto {
  assetId: string;
  candidatureId: string;
  incidenceType: string;
  incidenceTypology: string;
  subject: string;
  message: string;
}

export enum IncidenceStatusEnum {
  CANCELLED = 'Cancelled',
  NEW = 'New',
  EJECUCION = 'Ejecucion',
  CLOSED = 'Closed',
  ESCALATED = 'Escalated'
}

export interface ChatThread {
  date: string | Date;
  message: string;
  userId: string;
}

export interface Conditioning {
  id: string;
  creationDate: Date;
  description: string;
}
export interface CandidateAttachedDocument {
  id: string;
  name: string;
  type: TypeDocumentEnum;
  validated: boolean;
  extension: string;
  creationDate: Date;
  requestAnalysisId: string;
  expirationDate?: Date;
  date?: Date;
  netAmount?: string;
  deleted?: boolean;
}
export interface UserAnalysis {
  candidature: Candidature;
  dnis: CandidateAttachedDocument[];
  payslips: CandidateAttachedDocument[];
  incomeTaxDeclaration: CandidateAttachedDocument[];
  pensionerRecognition: CandidateAttachedDocument[];
  is200Models: CandidateAttachedDocument[];
  ivaQuarterModels: CandidateAttachedDocument[];
  otherDocument: CandidateAttachedDocument[];
  workLifes: CandidateAttachedDocument[];
  model130s: CandidateAttachedDocument[];
  ssCertificates: CandidateAttachedDocument[];
  aeatCertificates: CandidateAttachedDocument[];
  pygQuarters: CandidateAttachedDocument[];
  workContracts: CandidateAttachedDocument[];
  bankStatements: CandidateAttachedDocument[];
  remoteJobCertificates: CandidateAttachedDocument[];
  invoiceRecordBooks: CandidateAttachedDocument[];
  invoicePaymentConfirmations: CandidateAttachedDocument[];

  user: User;
  incidences: Incidence[];
  conditioning?: Conditioning;
  requiredCalls?: boolean;
  internationalBlackList?: boolean;
}
export class CreateAnalysisDto {
  antiquity: string;
  assetId: string;
  candidatureId: string;
  businessName: string;
  cadastralReference: string;
  contractType: string;
  income: number;
  judicialIncidents: string;
  nonPaymentsConfirmed: string;
  position: string;
  rentalArea: number;
  rentalProperty: string;
  surface?: number;
  adnexalSurface?: number;
  constructionDate?: string;
  reformYear?: string;
  sector: string;
  stayLastAddress: string;
  traceability: string;
  userId: string;
  riskWarning: boolean;
  id?: string;
  creationDate?: Date;
  businessAntiquity?: string;
  businessActivitySector?: string;
  businessJudicialIncidents?: string;
  businessNoPaymentsConfirmed?: string;
  previousYearExploit?: string;
  previousPeriodResults?: string;
  lastQuarterSales?: string;
  previousYearSales?: string;
  businessAnalysis?: boolean;
  cif?: string;
  taxResidence?: string;
  isDraft?: boolean;
  scoreConditioningValue?: number;
  motivationRecovery?: RecoveryMotivationTypeEnum;
  motivationRecoveryDescription?: string;
  reportId?: string;
  conditioning?: Conditioning;
  requiredCalls?: boolean;
  internationalBlackList?: boolean;
  rentalPrice?: number;
  updatedDate?: Date;
  aml: AMLCheck;
}

export interface AnalysisOption {
  id: string;
  description: string;
}
export interface AnalysisCatalogue {
  antiquity: AnalysisOption[];
  contractType: AnalysisOption[];
  judicialIncidents: AnalysisOption[];
  nonPaymentsConfirmed: AnalysisOption[];
  position: AnalysisOption[];
  sector: AnalysisOption[];
  stayLastAddress: AnalysisOption[];
  traceability: AnalysisOption[];
  businessActivitySector: AnalysisOption[];
  businessAntiquity: AnalysisOption[];
  businessJudicialIncidents: AnalysisOption[];
  businessNoPaymentsConfirmed: AnalysisOption[];
  businessPaymentCapacity: AnalysisOption[];
  businessRisk: AnalysisOption[];
}

export enum OrderStateEnum {
  PENDING = 'PENDING',
  FINISH = 'FINISH',
  REVISION = 'REVISION'
}

export enum OrderByDateEnum {
  ORDER_BY_ASC = 'ORDER_BY_ASC',
  ORDER_BY_DESC = 'ORDER_BY_DESC'
}

export enum OrderByIncidencesEnum {
  PENDING = 'I_PENDING',
  SOLVED = 'I_SOLVED'
}

export class TenantRequestDto {
  firstname: string;
  surname: string;
  email: string;
}

export interface Coupon {
  code: string;
  freeDays: number;
  remainCoupons: number;
}

export class HomeownerCommissionDto {
  insurancesMonth: number;
  insurancesGlobal: number;
  pendingProfit: number;
  paidProfit: number;
  totalProfit: number;
}

export enum OccupationEnum {
  WORKER = 'WORKER',
  STUDENT = 'STUDENT',
  FREELANCE = 'FREELANCE',
  RETIRED = 'RETIRED',
  BUSINESS = 'BUSINESS'
}

export class GetInternalEventsFilter {
  candidatureId?: string;
  requestAnalysisId?: string;
  tenantId?: string;
  type: string[];
}

export class ReportInternalEventDto {
  id: string;
  candidatureId: string;
  requestAnalysisId: string;
  tenantId: string;
  type: string;
  agentId: string;
  agentEmail: string;
  agentName: string;
  creationDate: Date;
  description: string;
  tenantName?: string;
}

export interface GetRequestAnalysisDto {
  user: User;
}

export interface TenantCertification {
  email: string;
  firstname: string;
  surname1: string;
  surname2: string;
  postalCode: string;
  phone: number;
  dni: string;
  guarantor?: boolean;
  student?: boolean;
  freelance?: boolean;
  retired?: boolean;
  business?: boolean;
  businessName?: string;
  cif?: string;
  taxResidence?: string;
  score?: number;
}

export interface UpdateBasicInfoHomeownerDto {
  email: string;
  firstname: string;
  surname1: string;
  surname2?: string;
  password?: string;
  dni?: string;
  occupation: string;
  businessName?: string;
  cif?: string;
  taxResidence?: string;
  phone?: string;
  birthDate?: Date;
  birthPlace?: string;
  retryDNI?: boolean;
  foreignIncomes?: boolean;
  userType: TypeUserEnum;
}

export interface VerifyExistingPhoneDto {
  found: boolean;
}

export interface VerifyCrrRequest {
  candidatureId: string;
  dni: string;
  firstname: string;
  surname1: string;
  surname2: string;
  zipCode: string;
  isManual?: boolean;
}

export interface VerifyCrrResponse {
  date: Date;
  value: number;
}

export interface ResearchLevels {
  incomeSources: number;
  historical: number;
  socialMedia: number;
  lastUpdate?: Date;
  income: number;
  businessName: string;
  sector: string;
  contractType: string;
  antiquity: string;
  position: string;
}

export interface ResearchLevelsRequest {
  incomeSources?: number;
  historical?: number;
  socialMedia: number;
  socialMediaInfo?: SocialMediaInfo[];
  socialMediaReliability?: string;
  socialMediaNote?: string;
  income?: number;
  businessName?: string;
  sector?: string;
  contractType?: string;
  antiquity?: string;
  position?: string;
}

export interface SocialMediaInfo {
  socialNetwork: string;
  url: string;
}

export interface ValidateEmailResponse {
  address: string;
  status: string;
  subStatus: string;
  freeEmail: boolean;
  didYouMean: string;
  account: string;
  domain: string;
  domainAgeDays: number;
  smtpProvider: string;
  mxRecord: string;
  mxFound: boolean;
  firstname: string;
  lastname: string;
  gender: string;
  country: string;
  region: string;
  city: string;
  zipcode: string;
}

export interface EmitterUserEmailInvitation {
  user: any;
  emailIsValid: boolean;
}

export interface ReportHistory {
  id: string;
  creationDate: Date;
  candidatureId: string;
  reportId: string;
  userId: string;
  dni: string;
  bornDate: Date;
  completeName: string;
  phone: string;
  email: string;
  cadastralReference: string;
  address: string;
  postalCode: string;
  town: string;
  rentalArea: number;
  rentalPrice: number;
  position: string;
  income: number;
  effort: number;
  contractType: string;
  antiquity: string;
  businessName: string;
  sector: string;
  traceability: string;
  traceabilityCode: string;
  judicialIncidents: string;
  stayLastAddress: string;
  nonPaymentsConfirmed: string;
  riskWarning: boolean;
  score: number;
  conditioningDescription: string;
  isStudent: boolean;
  homeownerId: string;

  // Business data
  businessAnalysis: boolean;
  businessRisk: string;
  businessPaymentCapacity: string;
  previousYearExploit: number;
  previousPeriodResults: number;
  lastQuarterSales: number;
  previousYearSales: number;
  cif: string;
  // Extra investigation
  requiredCalls?: boolean;
  internationalBlackList?: boolean;
}

export interface CustomerCareRequestCandidature {
  candidatureId: string;
  address: string;
  closed: boolean;
  creationDate: Date;
  expirationDate: Date;
  analyses: CustomerCareAnalysis[];
  tenantList: CustomerCareTenantList[];
  candidature: CustomerCareCandidature;
  incidences?: Incidence[];
  homeownerEmail: string;
  status: string;
  socialMediaStatus: string;
}

export interface ResultQueryCustomerCare {
  filteredCCR: CustomerCareRequestCandidature[];
  lastDoc: any;
  hasMore: boolean;
}

export interface CustomerCareAnalysis {
  id: string;
  userId: string;
  homeownerId: string;
  candidatureId: string;
  creationDate: Date;
  endDate?: Date;
  incidences?: any;
  pendingToDownload?: boolean;
  conditioning?: any;
  invoiced?: any;
}

export interface CustomerCareTenantList {
  id: string;
  creationDate?: Date | FirebaseDate;
  email: string;
  firstname: string;
  surname1: string;
  surname2: string;
  postalCode?: string;
  dni: string;
  phone?: any;
  birthDate?: Date;
  userType?: any;
  receiveOffers?: any;
  student?: boolean;
  retired?: boolean;
  business?: boolean;
  profilePhotoId?: any;
  score: CustomerCareScore;
  birthPlace?: any;
  coTenants?: any;
  guarantor?: boolean;
  freelance?: boolean;
  bigTenant?: any;
  reports: CustomerCareReports;
  dniIsInBlackList?: any;
  session?: any;
  ccc?: any;
  associatedUsers?: any;
  allowedHomeownersForUploadDocumentation?: any;
  professionalData: CustomerCareProfessionalData;
  trialVersion?: any;
  profileType?: any;
  automatedReports?: any;
  businessName?: any;
  cif?: any;
  taxResidence?: any;
  certifications: any[];
  researchLevels?: ResearchLevelsRequest;
  noValidated?: boolean;
  acceptedAllowHomeOwnerUploadDocuments?: boolean;
  duplicated?: boolean;
  foreignIncomes?: boolean;
}

export interface CustomerCareScore {
  value: number;
  date: Date;
}

export interface CustomerCareReports {
  requested: number;
  verified: number;
  totalRequested: number;
  totalVerified: number;
}

export interface CustomerCareProfessionalData {
  educationalLevel?: any;
  educational?: any;
  maritalStatus?: any;
  sector?: any;
  position?: any;
  freelance: boolean;
  activityInAsset: boolean;
  linkedIn?: any;
}

export interface CustomerCareUser {
  id: string;
  creationDate?: any;
  email: string;
  firstname: string;
  surname1: string;
  surname2: string;
  postalCode?: string;
  dni: string;
  phone?: any;
  birthDate?: Date;
  userType?: any;
  receiveOffers?: any;
  student?: boolean;
  retired?: boolean;
  business?: any;
  profilePhotoId?: any;
  score: CustomerCareScore;
  birthPlace?: any;
  coTenants?: any;
  guarantor?: boolean;
  bigTenant?: any;
  reports: CustomerCareReports;
  dniIsInBlackList?: any;
  session?: any;
  ccc?: any;
  associatedUsers?: any;
  allowedHomeownersForUploadDocumentation?: any;
  professionalData: CustomerCareProfessionalData;
  trialVersion?: any;
  profileType?: any;
  automatedReports?: any;
  businessName?: any;
  cif?: any;
  taxResidence?: any;
  certifications: any[];
  researchLevels?: any;
  noValidated?: boolean;
  acceptedAllowHomeOwnerUploadDocuments?: boolean;
}

export interface CustomerCareCandidature {
  id: string;
  asset?: AssetDto;
  tenantCandidatureList: CustomerCareTenantCandidatureList[];
  candidatureStatusEnum: string;
  creationDate?: any;
  expirationDate: Date;
  homeowner: CustomerCareHomeowner;
  score?: number;
  draftScore?: any;
  originalScore?: any;
  blockPolicy?: any;
  warrantyId: string;
  certifier: string;
  closingForced?: any;
  archived?: boolean;
}

export interface CustomerCareTenantCandidatureList {
  user: CustomerCareUser;
}

export interface CustomerCareHomeowner {
  user: CustomerCareUser;
}

export enum InOutEnum {
  NOT_REGISTERED = 'Sin registrar',
  NOT_ACCEPTED = 'Sin Aceptar',
  NO_DOCUMENTATION = 'Sin documentación',
  INCOMPLETE_DOCUMENTATION = 'Documentación incompleta',
  OWNER = 'Propietario',
  INCIDENT = 'Incidentado',
  NO_ANALYSIS = 'Sin análisis',
  FINISHED = 'Finalizado',
  UNKNOWN = 'Desconocido'
}

export enum SocialNetworkStatusEnum {
  FILLED = 'FILLED',
  EMPTY = 'EMPTY',
  UNKNOWN = 'UNKNOWN'
}

export interface SubjectAnalysis {
  creationDate: Date;
  traceability: string;
  judicialIncidents: string;
  stayLastAddress: string;
  nonPaymentsConfirmed: string;
  defaultAmount: number;
  residenceQuantity: number;
  residenceLastFound: number;
  details: SubjectAnalysisDetailDto[];
  alerts: SubjectAnalysisAlertDto[];
}

export interface SubjectAnalysisDetailDto {
  financedProduct: string;
  paymentStatus: string;
  quantity: number;
  informant: string;
  startDate: string;
  endDate: string;
}

export interface SubjectAnalysisAlertDto {
  date: string;
  debtCollection: number;
  credit: number;
}

export interface Language {
  code: string;
  text: string;
}
export const languages: Language[] = [
  {
    code: 'es',
    text: 'components.header.languages.es'
  },
  {
    code: 'en',
    text: 'components.header.languages.en'
  }
];

export interface VerifyWithoutDoc {
  motivation: string;
}

export class ApiDto {
  id: string;
  name: string;
  adminId: string;
  adminDetails: ApiMemberDto;
  members: ApiMemberDto[];
  portfolioOwners: string[];
}

export class ApiMemberDto {
  id: string;
  firstname: string;
  surname1: string;
  surname2: string;
  email: string;
}
export interface ContactNewApiDto {
  firstname: string;
  surname1: string;
  surname2: string;
  email: string;
  phone: string;
  postalCode: string;
  newApiInfo: string;
}

export interface AMLCheck {
  isPublicPerson: boolean;
  wasPublicPerson: boolean;
  publicPersonDate: string;
  isSanctionated: boolean;
  wasSanctionated: boolean;
  sanctionatedDate: string;
  isTerrorist: boolean;
  hasMedia: boolean;
  hasAdverseInfo: boolean;
  isHighRisk: boolean;
}

export interface RequestUserAuthInfo {
  email?: string;
  phone?: string;
}

export interface UserAuthInfo {
  disabled: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  id: string;
  phone: string;
  providers: string[];
}

export interface Condition {
  description: string;
  id: string;
  options: ConditionOption[];
  value: string;
}

export interface ConditionOption {
  conditionText: string;
  description: string;
  value: string;
}

export interface VppSurvey {
  lowEarning: boolean;
  incomeTaxDeclaration: boolean;
  maritalStatus: MaritalStatusEnum;
  homeowner: boolean;
  registered: boolean;
}

export enum MaritalStatusEnum {
  SINGLE = 'SINGLE',
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED'
}

export enum UserRol {
  API_ADMIN = 'API_ADMIN',
  API_MEMBER = 'API_MEMBER'
}

export interface CalendarAppointment {
  agentName: string;
  creationDate: Date;
  date: Date;
  description: string;
  id: string;
  status: AppointmentStatus;
  title: string;
  userId: string;
  assetId?: string;
  candidatureId?: string;
}

export interface EventMetaData {
  id: string;
}

export enum AppointmentStatus {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED'
}

export interface CreateUserByEmail {
  firstname: string;
  surname1: string;
  surname2?: string;
  email: string;
  userType: TypeUserEnum;
  password: string;
  noValidated: boolean;
}
