/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, OnInit } from '@angular/core';
import { AssetsApiService } from '@app/modules/core/api-services/assets-api/assets-api.service';
import { CertificationsApiService } from '@app/modules/core/api-services/certifications-api/certifications-api.service';
import { BuildingAmlAlertDto, BuildingDto, PageDto, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { first, map } from 'rxjs';



@Component({
  selector: 'el-buen-inquilino-shared-money-laundering',
  templateUrl: './shared-money-laundering.component.html'
})
export class SharedMoneyLaunderingComponent implements OnInit {
  @Input({ required: true }) user: User;

  buildings: BuildingDto[] = [];
  aimAlertBuildings: BuildingAmlAlertDto[] = []

  constructor(
    private certificationService: CertificationsApiService,
    private loadingService: LoadingService,
    private assetService: AssetsApiService,
  ) {}

  ngOnInit(): void {
    this.getBuidings()
  }

  private async getBuidings(): Promise<void> {
    await this.loadingService.presentLoading(null)
    this.assetService
    .getBuildings(50, this.user.portfolioId)
    .pipe(
        first(),
        map((resp: PageDto<BuildingDto>) => resp.elements)
    )
    .subscribe({
        next: async (resp: BuildingDto[]) => {
            this.buildings = resp.filter((item:BuildingDto) => item.amlAlert === true)
            if(this.buildings.length) 
                this.getAlertsAml()
            else
                await this.loadingService.dismissLoading()
        },
        error: async () => await this.loadingService.dismissLoading()
    });
  }

  private getAlertsAml(): void {
    this.certificationService.getBuildingAmlAlert(this.user.portfolioId).subscribe({
        next: async (data) => {
            this.aimAlertBuildings = data
            await this.loadingService.dismissLoading()
        },
        error: async () => {
            await this.loadingService.dismissLoading()
        }
    })
  }
}
