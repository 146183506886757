import { Pipe, PipeTransform } from '@angular/core';
import { AssetDto } from '@core/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'multipleApiTooltip'
})
export class MultipleApiTooltipPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(items: AssetDto[]): string {
    if (this.getDisabledButton(items)) {
      return this.translate.instant(
        'components.asset_filters.multiple_disable_tooltip'
      ) as string;
    } else {
      return null;
    }
  }

  private getDisabledButton(items: AssetDto[]): boolean {
    if (!items || items?.length < 2) {
      return true;
    } else {
      return false;
    }
  }
}
