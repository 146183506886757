/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  AmlAlertDto,
  BuildingAmlAlertDto,
  Candidature,
  CandidatureDetailedDto,
  CreateUserForCertificationsRegistry,
  UpdateConditionsUserDto,
  User
} from '../../models';

@Injectable()
export class CertificationsApiService {
  candidatureForCertification: Candidature = {} as Candidature;
  tenantListForIncidences: User[] = [];
  url: string;
  ebiCloudFunctions: string;
  isScorePresent = false;
  finalizedCertifications: CandidatureDetailedDto[];
  pendingCertifications: CandidatureDetailedDto[];
  selectedCertificationOnBackOffice: CandidatureDetailedDto;
  candidatureForCertification$ = new BehaviorSubject<Candidature>(
    this.candidatureForCertification
  );

  filterDates$ = new BehaviorSubject<{
    from: string;
    to: string;
    search: boolean;
  }>({ from: null, to: null, search: false });

  constructor(private http: HttpClient) {
    this.url = environment.services.certifications;
    this.ebiCloudFunctions = environment.services.ebiCloudFunctions;
  }

  setFilterDates(from: string, to: string, search: boolean): void {
    this.filterDates$.next({ from, to, search });
  }

  getFilterDates(): Observable<{
    from: string;
    to: string;
    search: boolean;
  }> {
    return this.filterDates$.asObservable();
  }

  getBuildingAmlAlert(portfolioId: string): Observable<BuildingAmlAlertDto[]> {
    return this.http.get<BuildingAmlAlertDto[]>(
      `${this.url}/alerts/portfolios/${portfolioId}`
    );
  }

  updateBuildingAmlAlert(
    buildingId: string,
    amlBody: AmlAlertDto
  ): Observable<any> {
    return this.http.patch(
      `${this.url}/alerts/buildings/${buildingId}`,
      amlBody
    );
  }

  finalizeCandidature(candidatureId: string): Observable<any> {
    return this.http.patch(
      this.url +
        `/backend-operations/candidatures/${candidatureId}/finalize-candidature`,
      {}
    );
  }

  approveByDecreeCandidature(candidatureId: string): Observable<any> {
    return this.http.patch(
      this.url +
        `/backend-operations/candidatures/${candidatureId}/approve-by-decree`,
      {}
    );
  }

  encryptPassword(pass: string): string {
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const encrypted = CryptoJS.AES.encrypt(pass, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  registerUserForCertifications(
    createUserForCertificationsRegistry: CreateUserForCertificationsRegistry
  ): Observable<any> {
    const obj = createUserForCertificationsRegistry;
    obj.password = this.encryptPassword(obj.password);
    delete obj.confirmPassword;
    return this.http.post(this.url + '/notifications/register-new-user', obj);
  }

  updateConditionsUser(
    updateConditionsUser: UpdateConditionsUserDto
  ): Observable<any> {
    const obj: UpdateConditionsUserDto = updateConditionsUser;
    return this.http.post(
      this.url + '/notifications/update-conditions-user',
      obj
    );
  }

  createRequestAnalyisisKamino(
    candidatureId: string,
    homeownerId: string
  ): Observable<any> {
    return this.http.put<string>(
      this.url +
        '/kamino/candidatures/' +
        candidatureId +
        '/new-request-analysis-from-cc',
      { homeownerId }
    );
  }
}
