/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-verify-dni-alert-modal',
  templateUrl: './verify-dni-alert-modal.component.html',
  styleUrls: ['./verify-dni-alert-modal.component.scss']
})
export class VerifyDniAlertModalComponent {
  constructor(private modalController: ModalController) {}

  dismiss(saving?): void {
    this.modalController.dismiss({
      dismissed: true,
      saving,
      data: saving ? { continue: true } : null
    });
  }
}
