/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
    this.nextPageLabel = this.translateService.instant(
      'paginator.next'
    ) as string;
    this.previousPageLabel = this.translateService.instant(
      'paginator.previous'
    ) as string;
    this.firstPageLabel = this.translateService.instant(
      'paginator.first'
    ) as string;
    this.lastPageLabel = this.translateService.instant(
      'paginator.last'
    ) as string;
    this.itemsPerPageLabel = this.translateService.instant(
      'paginator.perPage'
    ) as string;
  }

  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    const ofTotalPages = this.translateService.instant(
      'paginator.of'
    ) as string;
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    if (length === 0 || pageSize === 0) {
      return `0 ${ofTotalPages} ${length}`;
    }

    return `${startIndex + 1} - ${endIndex} ${ofTotalPages} ${length}`;
  };
}
