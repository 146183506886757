/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
import {
  state,
  style,
  transition,
  animate,
  trigger
} from '@angular/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { Subscription } from 'rxjs';

import { MessageBean } from '../../utils/message-bean.utils';

// TODO: eliminar todo lo que tenga que ver con snackbar y utilizar toast
@Component({
  selector: 'el-buen-inquilino-snack-bar',
  templateUrl: './snack-bar.component.html',
  animations: [
    trigger('openClose', [
      state(
        'hide',
        style({ display: 'none', height: 'auto', padding: '1rem' })
      ),
      state(
        'show',
        style({ display: 'block', height: 'auto', padding: '1rem' })
      ),
      transition('hide => show', [animate('0.2s')]),
      transition('show => hide', [animate('0.2s')])
    ])
  ]
})
export class SnackBarComponent implements OnInit, OnDestroy {
  private show = false;
  message: MessageBean;
  private type: string;
  private snackbarSubscription: Subscription;

  constructor(private snackbarService: SnackBarService) {}

  ngOnInit(): void {
    this.snackbarSubscription = this.snackbarService.snackbarState.subscribe(
      (data) => {
        if (data.type) {
          this.type = data.type;
        }
        this.message = data.message;
        this.show = data.show;
        if (data.duration) {
          setTimeout(() => {
            this.show = false;
            this.message = null;
          }, data.duration);
        }
      }
    );
  }

  isError(): boolean {
    return this.type === 'danger';
  }

  ngOnDestroy(): void {
    this.snackbarSubscription.unsubscribe();
  }

  isShow(): boolean {
    return this.show;
  }

  getClassStyles(): string[] {
    return ['snackbar-wrap', this.type ? this.type : ''];
  }
}
