/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { DocumentDTO } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-multiple-document-modal',
  templateUrl: './multiple.document.modal.html'
})
export class MultipleDocumentModalComponent implements OnInit, OnDestroy {
  pdfSrc: Blob;
  @Input() documentsData: DocumentDTO[];
  @Input() source: string | null;
  fileBlobUrl: string;
  isPdf = true;
  selectedDoc: DocumentDTO;
  isDisabledDownload = false;
  subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private loadingService: LoadingService,
    private docService: DocumentsApiService,
    private toastController: ToastController,
    private utils: UtilsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadingService.dismissSecondLoader();
    if (this.documentsData && this.documentsData.length > 0) {
      this.previewFile(this.documentsData[0], 0);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.every((subs) => subs.unsubscribe());
  }

  previewFile(document: DocumentDTO, index: number): void {
    this.loadingService.presentSecondLoader(null, true);
    this.selectedDoc = document;
    this.documentsData.map((doc) => (doc.selected = false));
    this.documentsData[index].selected = true;
    const fileSub = this.getFilesForCandidatureModal(document);

    this.subscriptions.push(fileSub);
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  onClickVerifiedDoc(documents: DocumentDTO[]): void {
    if (documents) {
      this.isDisabledDownload = true;
      this.loadingService.presentSecondLoader(
        this.translate.instant(
          'components.multiple_document_modal.downloading'
        ),
        true
      );
      documents.map((document) => {
        this.downloadVerifiedDocument(document);
      });
      this.loadingService.dismissSecondLoader();
      this.isDisabledDownload = false;
    } else {
      this.presentToast(
        this.translate.instant(
          'components.multiple_document_modal.do_not_exist_documents'
        ),
        'danger'
      );
    }
  }

  downloadVerifiedDocument(documentDownload: DocumentDTO): void {
    this.docService.getFileByDocument(documentDownload.id).subscribe(
      async (res) => {
        const blob = await this.utils.UrlToBlob(res.url);
        this.utils.downloadPDF(
          documentDownload.name + '.' + documentDownload.extension,
          blob
        );
        this.loadingService.dismissSecondLoader();
      },
      () => {
        this.loadingService.dismissSecondLoader();
        this.isDisabledDownload = false;
        this.presentToast(
          this.translate.instant(
            'components.multiple_document_modal.error_downloading'
          ),
          'danger'
        );
      }
    );
    this.loadingService.dismissSecondLoader();
  }

  checkSourceSelectedCandidature(): boolean {
    return this.source === 'selectedCandidature';
  }

  checkSourceTenantHomeFromOwner(): boolean {
    return this.source === 'tenantHomeFromOwner';
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  private getFilesForCandidatureModal(document: DocumentDTO): any {
    let fileSub;
    if (document.extension !== 'pdf') {
      fileSub = this.docService
        .getFileByDocument(document.id)
        .subscribe(async (res) => {
          const blob = await this.utils.UrlToBlob(res.url);
          this.isPdf = false;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.addEventListener(
            'load',
            () => {
              this.fileBlobUrl = reader.result as string;
            },
            false
          );
          this.loadingService.dismissSecondLoader();
        });
    } else {
      fileSub = this.docService
        .getFileByDocument(document.id)
        .subscribe(async(res) => {
          const blob = await this.utils.UrlToBlob(res.url);
          this.isPdf = true
          const url = window.URL.createObjectURL(blob);
          this.fileBlobUrl = url;
          this.loadingService.dismissSecondLoader();
        });
    }
    return fileSub;
  }
}
