import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-header-user-popover',
  templateUrl: './header-user-popover.component.html'
})
export class HeaderUserPopoverComponent {
  @Input() isUser: boolean;

  constructor(private popoverController: PopoverController) {}

  onClickUtilOptions(option: string): void {
    this.popoverController.dismiss({
      option
    });
  }
}
