/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { getBase64ImageFromURL, getImage } from '@shared/utils/pdfmake.utils';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';

// const WIDTH = 595.28;
const PAGE_MARGIN_H = 40;
const PAGE_MARGIN_V = 100;
// const PAGE_WIDTH = WIDTH - 2 * PAGE_MARGIN_H;
const LINE_HEIGHT = 1.5;

@Injectable()
export class BuildingPdfService {
  get pdfTitle(): string {
    return 'Informe Resumen Edificio';
  }

  get pdfDate(): string {
    return format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: es });
  }

  constructor() {}

  async getReport(
    address: string,
    occupationLevel: string,
    pdfTenantAnalysis: string,
    pdfRiskAnalysis: string,
    countryImage: string,
    cityImage: string
  ): Promise<void> {
    let bgImg: string;
    let logoEbi: string;

    try {
      bgImg = await getBase64ImageFromURL(
        'https://images.unsplash.com/photo-1565953522043-baea26b83b7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=595&q=90'
      );
    } catch (error) {
      bgImg = '';
    }

    try {
      logoEbi = await getImage('./assets/images/ebi-login-logo.png');
    } catch (error) {
      logoEbi = '';
    }

    // Se crea el pdf con toda la info
    const pdfDefinition: TDocumentDefinitions = {
      header: (currentPage: number) => {
        return this.getHeader(currentPage);
      },
      content: this.getContent(address),
      background: function (currentPage: number) {
        if (currentPage === 1) {
          return {
            image: 'imgCover',
            absolutePosition: { x: 0, y: 0 },
            pageBreak: 'after'
          } as Content;
        }
      },
      footer: (currentPage: number) => {
        return this.getFooter(currentPage);
      },
      styles: {
        sectionTitle: {
          fontSize: 18,
          bold: true
        },
        sectionSubtitle: {
          marginTop: 10,
          fontSize: 16,
          bold: true
        },
        sectionDesc: {
          fontSize: 14,
          marginTop: 10,
          lineHeight: LINE_HEIGHT
        },
        sectionImage: {
          marginTop: 16
        },
        h1: {
          fontSize: 24,
          bold: true,
          margin: [0, 16, 0, 0],
          color: '#021F37'
        },
        h2: {
          fontSize: 18,
          bold: true,
          margin: [0, 16, 0, 0],
          color: '#021F37'
        },
        subheader: {
          fontSize: 16,
          bold: false,
          margin: [0, 16, 0, 16],
          color: '#021F37'
        },
        textLight: {
          color: 'white'
        },
        smallText: {
          fontSize: 10,
          lineHeight: LINE_HEIGHT,
          margin: [0, 0, 0, 8],
          color: '#021F37'
        },
        tableOfContent: {
          fontSize: 14,
          lineHeight: LINE_HEIGHT
        },
        tableOfContentTitle: {
          bold: true,
          fontSize: 16
        }
      },
      images: {
        imgCover: bgImg,
        logoEbi,
        occupationLevel,
        pdfTenantAnalysis,
        pdfRiskAnalysis,
        countryImage,
        cityImage
      },
      pageMargins: [PAGE_MARGIN_H, PAGE_MARGIN_V, PAGE_MARGIN_H, PAGE_MARGIN_V],
      info: {
        title: this.pdfTitle,
        author: 'EBI',
        subject: this.pdfDate
      }
    };

    const pdf = pdfMake.createPdf(
      pdfDefinition,
      null,
      null,
      pdfFonts.pdfMake.vfs
    );
    pdf.open();
  }

  private getHeader(currentPage: number): Content[] {
    if (currentPage > 1) {
      return [
        {
          width: '*',
          stack: [
            {
              image: 'logoEbi',
              fit: [40, 40],
              margin: [PAGE_MARGIN_H, PAGE_MARGIN_H, 0, PAGE_MARGIN_H],
              alignment: 'right'
            }
          ]
        } as Content
      ];
    } else {
      return [];
    }
  }

  private getFooter(currentPage: number): Content[] {
    if (currentPage !== 1) {
      const dateNow = format(new Date(), 'dd/MM/yyyy');
      return [
        {
          // columnGap: 0,
          columns: [
            {
              stack: [
                {
                  text: 'Informe EBI - ' + dateNow
                }
              ]
            },
            {
              stack: [
                {
                  text: ['· ', currentPage, ' ·'].join(''),
                  alignment: 'right'
                }
              ]
            }
          ],
          style: 'smallText',
          margin: [40, 60, 40, 0]
        }
      ];
    } else {
      return [];
    }
  }

  private getContent(address: string): Content[] {
    return [
      this.getFrontPage(address),
      ...this.getTableOfContents(),
      ...this.getFirstSection(),
      ...this.getSecondSection(),
      ...this.getThirdSection()
    ];
  }

  private getFrontPage(address: string): Content {
    return {
      stack: [
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 140,
              h: 140, // aumentar el valor de h
              r: 80,
              lineWidth: 0,
              color: '#ffffff'
            }
          ]
        },
        {
          columns: [
            {
              image: 'logoEbi',
              fit: [80, 80]
            }
          ],
          relativePosition: {
            x: 30,
            y: -90
          }
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 200,
              y: -140,
              w: 500,
              h: 140, // aumentar el valor de h
              r: 80,
              lineWidth: 0,
              color: '#337ab7'
            }
          ]
        },
        {
          stack: [
            {
              text: this.pdfTitle,
              style: ['h1', 'textLight'],
              margin: [0, 8, 0, 0]
            },
            {
              text: address,
              style: ['h2', 'textLight'],
              margin: [0, 0, 0, 0]
            },
            {
              text: this.pdfDate,
              style: ['subheader', 'textLight'],
              margin: [0, 0, 0, 8]
            }
          ],
          relativePosition: {
            x: 240,
            y: -110
          }
        }
      ],
      pageBreak: 'after'
    };
  }

  private getTableOfContents(): Content[] {
    return [
      {
        type: 'lower-roman',
        ol: [
          [
            {
              text: 'Análisis General',
              style: ['tableOfContent', 'tableOfContentTitle']
            },
            [
              {
                ol: [
                  { text: 'Ubicación del edificio', style: ['tableOfContent'] },
                  {
                    text: 'Nivel de Ocupación & Rentas activadas',
                    style: ['tableOfContent']
                  }
                ]
              }
            ]
          ],
          [
            {
              text: 'Análisis de la Demanda',
              style: ['tableOfContent', 'tableOfContentTitle']
            },
            [
              {
                ol: [
                  { text: 'Candidaturas recibidas', style: ['tableOfContent'] }
                ]
              }
            ]
          ],
          [
            {
              text: 'Análisis de Riesgo',
              style: ['tableOfContent', 'tableOfContentTitle']
            },
            [
              {
                ol: [
                  {
                    text: 'Evaluación de las candidaturas',
                    style: ['tableOfContent']
                  },
                  { text: 'Coberturas contratadas', style: ['tableOfContent'] }
                ]
              }
            ]
          ]
        ],
        pageBreak: 'after'
      }
    ];
  }

  private getFirstSection(): Content[] {
    return [
      {
        text: 'Análisis general',
        style: 'sectionTitle'
      },
      {
        text: 'Ubicación del edificio',
        style: 'sectionSubtitle'
      },
      {
        text: 'En el presente informe, se presenta un resumen detallado de los indicadores clave que caracterizan el desempeño de un edificio destinado en su totalidad al alquiler (BTR). Este análisis abarca, los indicadores y aspectos más básicos sobre la gestión y rentabilidad financiera en el contexto de una propiedad destinada al alquiler residencial. A través de este resumen de indicadores, se busca proporcionar una visión integral y objetiva del rendimiento de un edificio BTR de alquiler, a todos los clientes que han confiado en EBI como su partner de confianza.',
        style: 'sectionDesc'
      },
      {
        style: 'sectionImage',
        image: 'countryImage',
        fit: [500, 500],
        marginTop: 10
      },
      {
        style: 'sectionImage',
        image: 'cityImage',
        fit: [500, 500],
        marginTop: 10
      },
      {
        text: '',
        pageBreak: 'after'
      },
      {
        text: 'Nivel de Ocupación & Rentas activadas',
        style: 'sectionSubtitle'
      },
      {
        style: 'sectionDesc',
        text: 'El nivel de ocupación de un edificio de alquiler es un indicador fundamental que refleja la eficacia y el atractivo de la propiedad tanto para los inquilinos actuales como potenciales. El nivel de ocupación no solo es un reflejo del éxito financiero de la inversión inmobiliaria, sino también un indicador de la calidad de la oferta y la experiencia del inquilino. A medida que el mercado inmobiliario evoluciona y las preferencias de los inquilinos cambian, es esencial comprender cómo el edificio en cuestión se adapta y responde a estas dinámicas.'
      },
      {
        style: 'sectionImage',
        image: 'occupationLevel',
        fit: [500, 500]
      },
      {
        text: '',
        pageBreak: 'after'
      }
    ];
  }

  private getSecondSection(): Content[] {
    return [
      {
        text: 'Análisis de la Demanda',
        style: 'sectionTitle'
      },
      {
        marginTop: 15,
        style: 'sectionDesc',
        text: 'La demanda de alquiler no solo refleja la dinámica económica y social de una región, sino que también está influenciada por factores culturales, demográficos y tecnológicos. En este informe se resumen los principales aspectos sobre las candidaturas recibidas.'
      },
      {
        style: 'sectionImage',
        image: 'pdfTenantAnalysis',
        fit: [500, 500]
      },
      {
        text: '',
        pageBreak: 'after'
      }
    ];
  }

  private getThirdSection(): Content[] {
    return [
      {
        text: 'Análisis de Riesgos',
        style: 'sectionTitle'
      },
      {
        marginTop: 15,
        style: 'sectionDesc',
        text: 'En el marco de la gestión inmobiliaria, el análisis de scoring de inquilinos se ha convertido una pieza relevante en un entorno de mercado en constante cambio, donde la seguridad financiera y la mitigación de riesgos son prioridades para los propietarios y administradores de propiedades de alquiler. A continuación, se presenta un resumen de los resultados obtenidos en las candidaturas recibidas para este edificio.'
      },
      {
        style: 'sectionImage',
        image: 'pdfRiskAnalysis',
        fit: [500, 500]
      }
    ];
  }
}
