import { Pipe, PipeTransform } from '@angular/core';
import {
  AssetDto,
  Candidature,
  TenantCandidature,
  TypeDocumentEnum,
  User
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';

@Pipe({
  name: 'showCandidatureDocumentChip'
})
export class ShowCandidatureDocumentChipPipe implements PipeTransform {
  private asset: AssetDto;
  constructor(private utilsService: UtilsService) {}
  transform(
    tenant: TenantCandidature,
    document: TypeDocumentEnum,
    candidature?: Candidature,
    asset?: AssetDto
  ): boolean {
    switch (document) {
      case TypeDocumentEnum.PAYSLIP:
        return this.showPaysSlipChip(candidature, tenant);
      case TypeDocumentEnum.PENSIONER_RECOGNITION:
        return this.showPensionerRecognitionChip(tenant);
      case TypeDocumentEnum.IS200_MODEL:
        return this.showIs200ModelChip(tenant);
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        return this.showIvaQuarterModelChip(tenant);
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        return this.showIvaQuarterModelChip(tenant);
      case TypeDocumentEnum.INCOME_TAX_DECLARATION: {
        this.asset = asset;
        return this.showRentChip(candidature, tenant);
      }
      case TypeDocumentEnum.MODEL_130:
        return this.showModel130Chip(tenant);
      case TypeDocumentEnum.SS_CERTIFICATE:
        return this.showSSCertificateChip(tenant);
      case TypeDocumentEnum.AEAT_CERTIFICATE:
        return this.showAEATCertificateChip(tenant);
      case TypeDocumentEnum.PYG_QUARTER:
        return this.showPYGQuarterChip(tenant);
      case TypeDocumentEnum.WORK_CONTRACT:
        return this.showWorkContractChip(tenant);
      case TypeDocumentEnum.BANK_STATEMENT:
        return this.showBankStatementChip(tenant);
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE:
        return this.showRemoteJobCertificateChip(tenant);
      case TypeDocumentEnum.WORK_LIFE:
        return this.showWorkLifeChip(tenant);
      case TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION:
        return this.showExtraDocumentationFreelanceChip(tenant);
      case TypeDocumentEnum.INVOICE_RECORD_BOOK:
        return this.showExtraDocumentationFreelanceChip(tenant);
    }
  }

  showPaysSlipChip(
    candidature: Candidature,
    tenant: TenantCandidature
  ): boolean {
    return (
      !this.checkIsStudentNoPaySlip(candidature, tenant.user) &&
      !this.checkIsFreelance(tenant.user) &&
      this.checkIsStudentForshowPayslipOrRentChip(tenant.user) &&
      !tenant.user.retired &&
      !tenant.user.business
    );
  }

  checkIsStudentNoPaySlip(candidature: Candidature, user: User): boolean {
    if (
      user.student &&
      this.checkTenantHasGuarantor(candidature) &&
      this.checkStudentTenant(candidature)
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIsFreelance(user: User): boolean {
    if (user.freelance) {
      return true;
    } else {
      return false;
    }
  }

  checkIsStudentForshowPayslipOrRentChip(user: User): boolean {
    if (user.student) return false;
    return true;
  }

  /**
   * Check use case when tenant is unemployed or student but need have a valid
   * guarantor to allow request analysis, now in masked guarantor feature
   * we need accept only valid Dni and payslips
   */
  checkTenantHasGuarantor(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) =>
          tenant.hasDni &&
          (tenant.hasPayslip ||
            tenant.hasRent ||
            tenant.hasPensionerRecognition)
      ).length > 0
    );
  }

  /**
   * Check if tenant checked student toggle on his profile,
   * also need have a garantor to complete use case
   */
  checkStudentTenant(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) => tenant.user.student
      ).length > 0
    );
  }

  showPensionerRecognitionChip(tenant: TenantCandidature): boolean {
    return tenant.user.retired;
  }

  showIs200ModelChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showIvaQuarterModelChip(tenant: TenantCandidature): boolean {
    return (
      tenant.user.business ||
      (tenant.user.freelance &&
        tenant.user?.extraDocumentation === null &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          tenant.hasIvaQuarterModel,
          tenant.hasModel130
        ))
    );
  }

  showRentChip(candidature: Candidature, tenant: TenantCandidature): boolean {
    return (
      (!this.checkIsStudentNoRent(candidature, tenant.user) &&
        this.checkIsFreelance(tenant.user) &&
        tenant.user?.extraDocumentation === null &&
        this.checkIsStudentForshowPayslipOrRentChip(tenant.user)) ||
      !!this.asset.vppModality
    );
  }

  checkIsStudentNoRent(candidature: Candidature, user: User): boolean {
    if (
      user.student &&
      this.checkTenantHasGuarantor(candidature) &&
      this.checkStudentTenant(candidature)
    ) {
      return true;
    } else {
      return false;
    }
  }

  showModel130Chip(tenant: TenantCandidature): boolean {
    return (
      tenant.user?.extraDocumentation === null &&
      tenant.user.freelance &&
      this.utilsService.checkModel130ForFreelance(
        tenant.hasIvaQuarterModel,
        tenant.hasModel130
      )
    );
  }

  showSSCertificateChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showAEATCertificateChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showPYGQuarterChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showWorkContractChip(tenant: TenantCandidature): boolean {
    return (
      tenant.user.foreignIncomes ||
      (!!tenant.user?.extraDocumentation &&
        tenant.user?.extraDocumentation !== '' &&
        tenant.user.freelance)
    );
  }

  showBankStatementChip(tenant: TenantCandidature): boolean {
    return (
      tenant.user.foreignIncomes ||
      (!!tenant.user?.extraDocumentation &&
        tenant.user?.extraDocumentation !== '' &&
        !tenant.user.student &&
        !tenant.user.business &&
        !tenant.user.freelance)
    );
  }

  showRemoteJobCertificateChip(tenant: TenantCandidature): boolean {
    return tenant.user.foreignIncomes;
  }

  showWorkLifeChip(tenant: TenantCandidature): boolean {
    return (
      !!tenant.user?.extraDocumentation &&
      tenant.user?.extraDocumentation !== '' &&
      !tenant.user.student &&
      !tenant.user.business &&
      !tenant.user.retired &&
      !tenant.user.freelance
    );
  }

  showExtraDocumentationFreelanceChip(tenant: TenantCandidature): boolean {
    return (
      !!tenant.user?.extraDocumentation &&
      tenant.user?.extraDocumentation !== '' &&
      !tenant.user?.student &&
      !tenant.user?.business &&
      !tenant.user?.retired &&
      tenant.user?.freelance
    );
  }
}
