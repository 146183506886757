import { Pipe, PipeTransform } from '@angular/core';
import { ComercialNote } from '@core/models';
import { format, isToday } from 'date-fns';

import {
  CandidatureNotesFunction,
  CandidatureNotesPipeArgs
} from '../models/candidature-notes.model';
import { getWriterName } from '../utils/global.utils';

@Pipe({
  name: 'candidatureNotes'
})
export class CandidatureNotesPipe implements PipeTransform {
  transform(
    note: ComercialNote,
    args: CandidatureNotesPipeArgs
  ): boolean | string {
    const { pipeFunction, userId, users } = args;
    switch (pipeFunction) {
      case CandidatureNotesFunction.OWN:
        return this.ownMessage(note, userId);
      case CandidatureNotesFunction.WRITER_NAME:
        return getWriterName(users, userId, note);
      case CandidatureNotesFunction.MSG_DATE:
        return this.getMsgDate(note);
    }
  }

  private ownMessage(note: ComercialNote, userId: string): boolean {
    return note.userId === userId;
  }

  private getMsgDate(note: ComercialNote): string {
    const date = new Date(note.date);
    if (isToday(date)) {
      return format(date, 'HH:mm');
    } else {
      return format(date, 'dd/MM/yyyy HH:mm');
    }
  }
}
