/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InsurancesApiService } from '@app/modules/core/api-services/insurances-api/insurances-api.service';
import { TemplatesDocumentDto, TemplatesDto } from '@app/modules/core/models';
import { LoadingService } from '@app/modules/core/services/loading/loading.service';
import { UtilsService } from '@app/modules/core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';

import { copyObject } from '../../utils/global.utils';
import { BackofficeTemplateStatusModalComponent } from '../backoffice-template-status-modal/backoffice-template-status-modal.component';
import { ModalReasonTemplateComponent } from '../modal-reason-template/modal-reason-template.component';

@Component({
  selector: 'el-buen-inquilino-shared-templates-list',
  templateUrl: './shared-templates-list.component.html'
})
export class TemplatesListComponent implements OnChanges, OnInit {
  @Input() showBackOffice = false;
  @Input() filterValue = '';
  @Input() updateList = false;

  fileBlobUrl: string;
  isPdf = true;
  items: TemplatesDto[] = [];

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<TemplatesDto> =
    new MatTableDataSource<TemplatesDto>([]);

  constructor(
    private insuranceService: InsurancesApiService,
    private loadingService: LoadingService,
    private utilsService: UtilsService,
    private modalController: ModalController,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.getTemplates();
  }

  ngOnChanges(): void {
    this.setDisplayedColumns();
    this.filter();
    if (this.updateList) this.getTemplates();
  }

  setDisplayedColumns(): void {
    this.displayedColumns = ['date', 'file', 'status', 'actions'];

    if (this.showBackOffice) {
      this.displayedColumns.splice(2, 0, 'portfolio', 'notes', 'client');
    }
  }

  setDataSource(data: TemplatesDto[]): void {
    this.dataSource = new MatTableDataSource<TemplatesDto>(
      copyObject(data) as TemplatesDto[]
    );
  }

  downloadDocument(fileId: string, fileName: string): void {
    this.loadingService.presentLoading(null);
    this.insuranceService.documentTemplate(fileId).subscribe({
      next: async (data: TemplatesDocumentDto) => {
        const response = await fetch(data.url);
        const blob = await response.blob();
        this.utilsService.downloadPDF(fileName, blob);
        this.loadingService.dismissLoading();
      },
      error: () => {
        this.loadingService.dismissLoading();
      }
    });
  }

  filter(): void {
    let items = copyObject(this.items) as TemplatesDto[];
    if (!!this.filterValue && this.filterValue.trim() !== '') {
      items = items.filter((t: TemplatesDto) => {
        const matchesExistingFields = this.showBackOffice
          ? t.fileName?.toLowerCase().includes(this.filterValue) ||
            t.notes?.toLowerCase().includes(this.filterValue) ||
            t.portfolioId?.toLowerCase().includes(this.filterValue) ||
            t.userEmail?.toLowerCase().includes(this.filterValue) ||
            t.status?.toLowerCase().includes(this.filterValue)
          : t.fileName?.toLowerCase().includes(this.filterValue) ||
            t.rejectReason?.toLowerCase().includes(this.filterValue) ||
            t.status?.toLowerCase().includes(this.filterValue);

        return matchesExistingFields;
      });

      this.setDataSource(items);
    } else {
      this.setDataSource(items);
    }
  }

  async openStatusModal(template: TemplatesDto): Promise<void> {
    if (this.showBackOffice) {
      const modal = await this.modalController.create({
        component: BackofficeTemplateStatusModalComponent,
        cssClass: 'auto-height modal-extend',
        backdropDismiss: true,
        componentProps: {
          templateDoc: template
        }
      });
      await modal.present();

      const { data } = await modal.onWillDismiss();
      if (data.saving) {
        this.presentToast('La plantilla ha sido actualizada', 'success', 5000);
        this.getTemplates();
      }
    }
  }

  async openReasonModal(template: TemplatesDto): Promise<void> {
    if (!this.showBackOffice) {
      const modal = await this.modalController.create({
        component: ModalReasonTemplateComponent,
        cssClass: 'auto-height modal-extend',
        backdropDismiss: true,
        componentProps: {
          templateDoc: template
        }
      });
      await modal.present();
    }
  }

  async openUploadTemplate(template: TemplatesDto): Promise<void> {
    if (!this.showBackOffice) {
      const modal = await this.modalController.create({
        component: ModalReasonTemplateComponent,
        cssClass: 'auto-height modal-extend',
        backdropDismiss: true,
        componentProps: {
          templateDoc: template
        }
      });
      await modal.present();
    }
  }

  private getTemplates(): void {
    this.loadingService.presentLoading(null);
    this.insuranceService.getTemplates().subscribe({
      next: (data: TemplatesDto[]) => {
        this.setDataSource(data);
        this.items = data;
        this.loadingService.dismissLoading();
      },
      error: () => {
        this.loadingService.dismissLoading();
      }
    });
  }

  private async presentToast(
    msg: string,
    toastcolor: string,
    duration: number = 2000
  ): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }
}
