/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import {
  Auth,
  ConfirmationResult,
  User as FirebaseUser,
  GoogleAuthProvider,
  UserCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup
} from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { environment } from '@environments/environment';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BackofficeRole } from '@shared/models/backoffice.model';
import { MessageBean } from '@shared/utils/message-bean.utils';
import * as CryptoJS from 'crypto-js';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

import { UsersApiService } from '../../api-services/users-api/users-api.service';
import {
  ApiDto,
  AuthenticationPage,
  DataSignIn,
  TypeUserEnum,
  User,
  UserDto
} from '../../models';
import { LoadingService } from '../loading/loading.service';
import { NavigationService } from '../navigation/navigation.service';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { StoreService } from '../store/store.service';
import { TranslationService } from '../translation/translation.service';

const WRONG_PASSWORD = 'auth/wrong-password';
const WRONG_EMAIL = 'auth/invalid-email';
const EMAIL_NOT_FOUND = 'auth/user-not-found';
const MISSING_FIELD = 'auth/argument-error';
const TYPE_USER_DIFFERENT = 'type-user-different';
const TOO_MANY_ATTEMPTS_TRY_LATER = 'auth/too-many-requests';
const EMAIL_EXISTS = 'auth/email-already-in-use';

@Injectable()
export class AuthService {
  user: User;
  private user$ = new BehaviorSubject<User>(null);
  apis: ApiDto[] = [];
  private apis$ = new BehaviorSubject<ApiDto[]>([]);

  private wrongPasswordError: string;
  private wrongEmailError: string;
  private emailNotFoundError: string;
  private missingFieldError: string;
  private typeUserDifferent: string;
  private unknowError: string;
  private tooManyAttempsError: string;
  private emailExistsError: string;
  private undefinedError: string;
  private tryLoginOnCertification = false;
  retryDNI = false;

  get token(): string | undefined {
    return this.storeService.getToken();
  }

  get isLoggedIn(): boolean {
    return !!this.token ? true : false;
  }

  constructor(
    private translateService: TranslateService,
    private userService: UsersApiService,
    private loadingService: LoadingService,
    private snackBarService: SnackBarService,
    private storeService: StoreService,
    private navigationService: NavigationService,
    private toastController: ToastController,
    private translationService: TranslationService,
    private auth: Auth,
    private router: Router
  ) {
    this.initializeErrorMessages();
  }

  login(email: string, password: string, idContact?: string): void {
    this.snackBarService.hide();
    this.loadingService.presentLoading(null);
    if (this.isUserLogged()) {
      this.navigationService.goHome(this.user, idContact);
    } else {
      this.processLoginFirebase(email, password, idContact);
    }
  }

  getUser(): Observable<User> {
    return this.user$.asObservable();
  }

  setUser(user: User): void {
    this.user$.next(user);
  }

  getApisObservable(): Observable<ApiDto[]> {
    return this.apis$.asObservable();
  }

  clearUser(): void {
    this.user = null;
    this.user$.next = null;
  }

  getNewValidToken(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then(
          () => {
            this.auth.currentUser.getIdToken(true).then((token) => {
              this.storeService.setToken(token);
              resolve(true);
            });
          },
          () => {
            this.navigationService.goLoginPage();
            reject(null);
          }
        )
        .catch(() => reject(null));
    });
  }

  register(
    dataSignIn: DataSignIn,
    authenticationPage: AuthenticationPage
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.snackBarService.hide();
      this.loadingService.presentSecondLoader('Registrando usuario...');
      if (this.isUserLogged()) {
        this.navigationService.goHome(this.user);
        resolve(true);
      } else {
        dataSignIn.user.password = this.encryptPassword(dataSignIn.password);
        this.registerUserInBackend(dataSignIn.user, authenticationPage);
      }
    });
  }

  recoveryPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.snackBarService.hide();
      lastValueFrom(this.userService.sendPasswordResetEmail(email))
        .then(() => {
          this.snackBarService.showAutoHide(
            MessageBean.buildMessageWithTranslation(
              'application.validations.errors.recovery-password',
              this.translateService
            ),
            5000
          );
          this.loadingService.dismissLoading();
          resolve();
        })
        .catch((error) => {
          this.processError(this.getMessageErrorSignIn(error.code));
          reject();
        });
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Añado condición para cargar página en mantenimiento según esté activo el campo en evironment
    if (environment.maintenance) {
      this.navigationService.goMaintenancePage();
      return false;
    }
    const credentials = this.storeService.getCredentials();
    if (!this.user?.id) {
      this.user = null;
      this.user$.next(null);
    }
    if (credentials?.token) {
      if (this.user) {
        if (this.isPageAnonymous(route)) {
          return true;
        } else {
          return this.processRoles(route.data?.roles);
        }
      } else {
        const tokenDecoded = jwt_decode(credentials.token);
        const expiredDate: Date = new Date(tokenDecoded.exp * 1000);

        // Si el token ha caducado
        if (expiredDate < new Date()) {
          this.logout(state.url);
          return false;
        }

        return this.userService.getUser(tokenDecoded.user_id).then(
          (userServiceData) => {
            this.user = userServiceData;
            this.user$.next(this.user);

            if (this.user.portfolioId || this.user.apiRol) {
              let apiAdminId: string = null;
              let portfolioOwnerId: string = null;
              if (this.user.portfolioId) {
                portfolioOwnerId = this.user.id;
              } else if (this.user.apiRol === 'API_ADMIN') {
                apiAdminId = this.user.id;
              }

              this.getApis(apiAdminId, portfolioOwnerId);
            }
            if (this.isPageAnonymous(route)) {
              //Users-api devuelve datos user ofuscados detectamos y deslogueamos.
              // ya no tenemos refresh token
              if (this.user?.firstname?.includes('*')) {
                this.removeUserFromAuthentication();
                this.navigationService.goLanding();
              }
              return true;
            } else if (this.processRoles(route.data?.roles)) {
              return true;
            } else if (
              route?.url[0]?.path === 'certifications' &&
              this.isHomeOwnerUser()
            ) {
              return true;
            } else if (route?.url[0]?.path === 'certifications') {
              this.navigationService.goCertificationHome(route.params.id);
            } else {
              this.navigationService.goLanding();
            }
          },
          (error) => {
            if (error.status === 403) {
              this.user = null;
              this.user$.next(null);
              this.storeService.removeCredentials();
              if (state.url.includes('recommendation-form')) {
                this.navigationService.goToEnterpriseLogin();
              } else {
                this.navigationService.goLoginPage();
              }
            } else {
              return false;
            }
          }
        );
      }
    } else if (this.isPageAnonymous(route)) {
      return true;
    } else {
      if (route?.url[0]?.path === 'certifications') {
        this.navigationService.goCertificationHome(route.params.id);
      } else if (state.url.includes('recommendation-form')) {
        this.storeService.setLastUrl(state.url);
        this.navigationService.goToEnterpriseLogin();
      } else if (!this.navigationService.isLoginPage(state.url)) {
        this.storeService.setLastUrl(state.url);
        this.navigationService.goLoginPage();
      }
    }
    return true;
  }

  isPageAnonymous(route: ActivatedRouteSnapshot): boolean {
    return (
      route.data?.roles &&
      this.hasRole(TypeUserEnum.ANONYMOUS, route.data?.roles)
    );
  }

  isUserLogged(): boolean {
    // Añado la discriminación por userType porque cuando dejas la pestaña
    // abierta mucho ese dato es el único que se vuelve false
    return (
      this.user !== null &&
      this.user !== undefined &&
      this.user?.id !== null &&
      this.user?.id !== undefined &&
      this.storeService.getCredentials()?.token !== null &&
      this.user?.userType !== null &&
      this.user?.userType !== undefined
    );
  }

  encryptPassword(pass: string): string {
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const encrypted = CryptoJS.AES.encrypt(pass, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  loginCertifications(): void {
    const token = localStorage.getItem('EBI-FINAER-TOKEN');
    const candidature = localStorage.getItem('CANDIDATURE_ID');
    if (token) {
      signInWithCustomToken(this.auth, token).then((data) => {
        this.tryLoginOnCertification = true;
        this.processToken(data, candidature);
      });
    } else {
      this.navigationService.goToLoginCertificationsHome();
    }
  }

  phoneLogin(phone: any, appVerifier: any): Promise<ConfirmationResult> {
    return signInWithPhoneNumber(this.auth, phone, appVerifier);
  }

  processAlternativeTokenLogin(
    dataFirebaseLogin: UserCredential,
    type: string,
    user?: UserDto,
    fromRegister: boolean = false,
    userType?: TypeUserEnum
  ): void {
    if (dataFirebaseLogin && dataFirebaseLogin.user) {
      this.auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          this.storeService.setToken(idToken);
          const tokenDecoded = jwt_decode(idToken);
          this.storeService.setTokenRefresh(
            dataFirebaseLogin.user.refreshToken
          );
          this.userService.getUser(tokenDecoded.user_id).then(
            (userServiceData) => {
              this.processLogin(userServiceData);
            },
            (_) => {
              if (type === 'phone') {
                this.createMinimalUserFromPhone(
                  dataFirebaseLogin.user,
                  user,
                  fromRegister,
                  userType
                );
              } else if (type === 'gmail') {
                this.createMinimalUserFromGoogle(
                  dataFirebaseLogin.user,
                  user,
                  fromRegister,
                  userType
                );
              }
            }
          );
        })
        .catch((error) => {
          this.processError(error);
        });
    } else {
      this.processError(this.unknowError);
    }
  }

  gmailLogin(
    user?: any,
    fromRegister: boolean = false,
    userType?: TypeUserEnum
  ): void {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    signInWithPopup(this.auth, provider)
      .then((auth) => {
        this.processAlternativeTokenLogin(
          auth,
          'gmail',
          user,
          fromRegister,
          userType
        );
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
      });
  }

  //TODO llevar a un servicio todas las toast usable para todo EBI,
  async presentToast(messages: string, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages ? messages : 'Error inesperado.',
      position: 'top',
      color: error ? 'danger' : 'success',
      duration: 5000
    });
    toast.present();
  }

  removeUserFromAuthentication(): void {
    this.user = null;
    this.user$.next(null);
    this.storeService.removeCredentials();
    this.navigationService.goLanding();
  }

  private processLoginFirebase(
    email: string,
    password: string,
    idContact?: string
  ): void {
    const passwordEncoding = password;
    signInWithEmailAndPassword(this.auth, email, passwordEncoding)
      .then(
        (dataFirebaseLogin) => {
          this.processToken(dataFirebaseLogin, idContact);
        },
        (error) => {
          if (error.code === 'auth/user-disabled') {
            this.presentToast(
              this.translateService.instant('pages.login.error.user-disabled'),
              true
            );
          } else {
            this.processError(this.getMessageErrorLogin(error.code));
          }
        }
      )
      .catch((error) => {
        this.processError(this.getMessageErrorLogin(error));
      });
  }

  private processToken(
    dataFirebaseLogin: UserCredential,
    idContact?: string
  ): void {
    if (dataFirebaseLogin && dataFirebaseLogin.user) {
      this.auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          this.storeService.setToken(idToken);
          const tokenDecoded = jwt_decode(idToken);
          if (tokenDecoded.email_verified) {
            this.processUser(dataFirebaseLogin, idContact);
          } else {
            this.auth.signOut();
            this.user = null;
            this.user$.next(null);
            this.storeService.removeCredentials();
            this.verifiedAccount(
              dataFirebaseLogin.user,
              'application.validations.errors.email-not-verified'
            );
          }
        })
        .catch((error) => {
          this.processError(error);
        });
    } else {
      this.processError(this.unknowError);
    }
  }

  private processUser(
    dataFirebaseLogin: UserCredential,
    idContact?: string
  ): void {
    this.userService.getUser(dataFirebaseLogin.user.uid).then(
      (userServiceData) => {
        this.storeService.setTokenRefresh(dataFirebaseLogin.user.refreshToken);
        this.processLogin(userServiceData, idContact);
      },
      (error) => {
        this.processError(error);
      }
    );
  }

  private processLogin(user: User, idContact?: string): void {
    this.user = user;

    if (user.portfolioId || user.apiRol) {
      let apiAdminId: string = null;
      let portfolioOwnerId: string = null;
      if (user.portfolioId) {
        portfolioOwnerId = user.id;
      } else if (user.apiRol === 'API_ADMIN') {
        apiAdminId = user.id;
      }
      this.getApis(apiAdminId, portfolioOwnerId);
    }

    //seteamos lenguaje por defecto a español si no existe
    if (!this.user?.language) {
      this.user.language = 'es';
    }
    this.user$.next(user);

    // Lenguaje de la app
    if (this.user.language) {
      this.translationService.setAppLanguage(this.user.language);
    }

    const credentials = this.storeService.getCredentials();
    credentials.typeUser = user.userType;
    this.storeService.setCredentials(credentials);

    // Para evitar que desde el login de building center se quede pillado, se añade un setTimeout
    if (
      localStorage.getItem('loginPage') &&
      localStorage.getItem('loginPage') === 'building-center'
    ) {
      setTimeout(() => {
        this.loadingService.dismissLoading();
      }, 250);
    } else {
      this.loadingService.dismissLoading();
    }

    const lastUrl = this.storeService.getLastUrl();
    if (lastUrl) {
      this.storeService.removeLastUrl();
      this.navigationService.goToLastUrl(lastUrl);
    } else {
      // this.tryLoginOnCertification
      //   ? this.navigationService.goToDocumentsCertification(idContact)
      //   : this.navigationService.goHome(this.user, idContact);
      if (this.tryLoginOnCertification) {
        this.navigationService.goToDocumentsCertification(idContact);
      } else {
        // this.navigationService.goHome(this.user, idContact);
        this.goHome();
      }
    }
  }

  private goHome(): void {
    let url: string;
    if (this.user.userType === TypeUserEnum.TENANT) {
      if (this.user.guarantor) {
        url = `/guarantor/${this.user.id}`;
      } else {
        url = `/tenant/${this.user.id}`;
      }
    } else if (this.user.userType === TypeUserEnum.HOMEOWNER) {
      if (
        (this.user?.portfolioId || this.user?.apiRol === 'API_ADMIN') &&
        !this.user.backofficeRoles?.includes(BackofficeRole.EXTERNAL_LAWYER)
      ) {
        url = `/home-owner/${this.user.id}/portfolio`;
      } else if (
        this.user.backofficeRoles?.includes(BackofficeRole.EXTERNAL_LAWYER)
      ) {
        url = `/home-owner/${this.user.id}/sinisters`;
      } else {
        url = `/home-owner/${this.user.id}`;
      }
    }
    this.router.navigateByUrl(url);
  }

  private processError(error: any): void {
    this.user = null;
    this.user$.next(null);
    this.storeService.removeCredentials();
    this.loadingService.dismissLoading();
    //Comentado antiguo sistema de notificaciones
    //this.snackBarService.show(MessageBean.buildMessage(error), 'danger');
    this.presentToast(MessageBean?.buildMessage(error)?.messages?.[0], true);
  }

  private getApis(apiAdminId: string, portfolioOwnerId: string): void {
    this.userService
      .getApis(apiAdminId, portfolioOwnerId)
      .pipe(first())
      .subscribe(
        (apis) => {
          this.apis = apis;
          this.apis$.next(apis);
        },
        (_) => {
          this.apis = [];
          this.apis$.next([]);
        }
      );
  }

  private registerUserInBackend(
    user: any,
    authenticationPage: AuthenticationPage
  ): Promise<boolean> {
    delete user.confirmPassword;
    user.noValidated = this.retryDNI;
    return new Promise(() => {
      this.userService
        .registerUser(user)
        .toPromise()
        .then(() => {
          this.auth.signOut();
          this.storeService.removeCredentials();
          authenticationPage.showLogin = true;
          this.snackBarService.showAutoHide(
            MessageBean.buildMessageWithTranslation(
              'application.validations.errors.email-to-verified',
              this.translateService
            ),
            5000
          );
          this.loadingService.dismissLoading();

          if (user.userType === TypeUserEnum.HOMEOWNER) {
            this.navigationService.goWithoutHideSnackBar(
              this.navigationService.pathEnterpriseLogin()
            );
          } else if (user.userType === TypeUserEnum.TENANT) {
            this.navigationService.goWithoutHideSnackBar(
              this.navigationService.pathLogin()
            );
          }
          if (this.retryDNI) {
            this.retryDNI = false;
          }
          return true;
        })
        .catch((error) => {
          this.loadingService.dismissSecondLoader();
          if (this.auth.currentUser) {
            this.auth.currentUser.delete();
          }
          if (error.error?.code === '100') {
            this.navigationService.goLoginByEmail(user.email);
            this.snackBarService.show(
              MessageBean.buildMessageWithTranslation(
                'pages.login.data.signin.invalid-dni',
                this.translateService
              )
            );
            this.retryDNI = true;
          } else if (error.error?.code === '108') {
            this.snackBarService.showAutoHide(
              MessageBean.buildMessageWithTranslation(
                'pages.login.error.email-exist',
                this.translateService
              ),
              5000
            );
          } else if (error.error?.code === '218') {
            this.snackBarService.showAutoHide(
              MessageBean.buildMessageWithTranslation(
                'pages.login.error.phone-exist',
                this.translateService
              ),
              5000
            );
          } else {
            this.processError('Error inesperado');
          }
          return false;
        });
    });
  }

  private verifiedAccount(
    user: FirebaseUser,
    keyMessage: string,
    pageRedirect?: string
  ): void {
    this.userService.sendEmailVerification(user.email).subscribe(() => null);
    this.snackBarService.showAutoHide(
      MessageBean.buildMessageWithTranslation(
        keyMessage,
        this.translateService
      ),
      5000
    );
    this.loadingService.dismissLoading();
    if (pageRedirect) {
      this.navigationService.goWithoutHideSnackBar(pageRedirect);
    }
  }

  private initializeErrorMessages(): void {
    this.translateService
      .get('pages.login.error.wrong-password')
      .subscribe((data) => {
        this.wrongPasswordError = data;
      });
    this.translateService
      .get('pages.login.error.invalid-email')
      .subscribe((data) => {
        this.wrongEmailError = data;
      });
    this.translateService
      .get('pages.login.error.user-not-found')
      .subscribe((data) => {
        this.emailNotFoundError = data;
      });
    this.translateService
      .get('pages.login.error.argument-error')
      .subscribe((data) => {
        this.missingFieldError = data;
      });
    this.translateService
      .get('pages.login.error.unknow-error')
      .subscribe((data) => {
        this.unknowError = data;
      });
    this.translateService
      .get('pages.login.error.' + TYPE_USER_DIFFERENT)
      .subscribe((data) => {
        this.typeUserDifferent = data;
      });
    this.translateService
      .get('pages.login.error.too-many-attemps-try-later')
      .subscribe((data) => {
        this.tooManyAttempsError = data;
      });
    this.translateService
      .get('pages.login.error.email-exist')
      .subscribe((data) => {
        this.emailExistsError = data;
      });
    this.translateService
      .get('pages.login.error.user-not-found')
      .subscribe((data) => {
        this.undefinedError = data;
      });
  }

  private getMessageErrorLogin(error: string): string {
    let messageError: string;
    if (error === WRONG_PASSWORD) {
      messageError = this.wrongPasswordError;
    } else if (error === WRONG_EMAIL) {
      messageError = this.wrongEmailError;
    } else if (error === EMAIL_NOT_FOUND) {
      messageError = this.wrongPasswordError;
    } else if (error === MISSING_FIELD) {
      messageError = this.missingFieldError;
    } else if (error === TYPE_USER_DIFFERENT) {
      messageError = this.typeUserDifferent;
    } else if (error === TOO_MANY_ATTEMPTS_TRY_LATER) {
      messageError = this.tooManyAttempsError;
    } else {
      messageError = this.unknowError;
    }
    return messageError;
  }

  private getMessageErrorSignIn(error: string): string {
    let messageError: string;
    if (error === WRONG_PASSWORD) {
      messageError = this.wrongPasswordError;
    } else if (error === WRONG_EMAIL) {
      messageError = this.wrongEmailError;
    } else if (error === EMAIL_NOT_FOUND) {
      messageError = this.emailNotFoundError;
    } else if (error === MISSING_FIELD) {
      messageError = this.missingFieldError;
    } else if (error === EMAIL_EXISTS) {
      messageError = this.emailExistsError;
    } else if (error === undefined) {
      messageError = this.undefinedError;
    } else {
      messageError = this.unknowError;
    }
    return messageError;
  }

  private logout(url?: string): void {
    this.user = null;
    this.storeService.removeCredentials();
    if (url && url.includes('recommendation-form')) {
      this.navigationService.goToEnterpriseLogin();
    } else {
      this.navigationService.goLanding();
    }
  }

  private processRoles(roles: string[]): boolean {
    let result = true;
    if (roles) {
      result = this.hasRole(this.storeService.getCredentials().typeUser, roles);
    }
    return result;
  }

  private hasRole(typeUser: TypeUserEnum, roles: string[]): boolean {
    return roles && roles.length > 0 ? roles.indexOf(typeUser) >= 0 : false;
  }

  private isHomeOwnerUser(): boolean {
    return (
      this.storeService.getCredentials().typeUser === TypeUserEnum.HOMEOWNER
    );
  }

  private createMinimalUserFromPhone(
    firebaseUser: FirebaseUser,
    userToRegister: UserDto,
    fromRegister: boolean = false,
    userType?: TypeUserEnum
  ): void {
    if (fromRegister) {
      userToRegister = new UserDto();
      userToRegister.userId = firebaseUser.uid;
      userToRegister.phone = firebaseUser.phoneNumber;
      userToRegister.userType = userType;
    } else {
      if (userToRegister) {
        // Si existe este objeto, es que se está dando de alta un tenant
        userToRegister.userId = firebaseUser.uid;
        userToRegister.phone = firebaseUser.phoneNumber;
        userToRegister.userType = TypeUserEnum.TENANT;
      } else {
        // Si no existe, es que estamos dando de alta un homeowner
        userToRegister = new UserDto();
        userToRegister.userId = firebaseUser.uid;
        userToRegister.phone = firebaseUser.phoneNumber;
        userToRegister.userType = TypeUserEnum.HOMEOWNER;
      }
    }
    this.userService
      .createMinimalUser(userToRegister)
      .subscribe((user: User) => {
        this.processLogin(user);
      });
  }

  private createMinimalUserFromGoogle(
    firebaseUser: FirebaseUser,
    userToRegister: UserDto,
    fromRegister: boolean = false,
    userType?: TypeUserEnum
  ): void {
    if (fromRegister) {
      userToRegister = new UserDto();
      userToRegister.userId = firebaseUser.uid;
      userToRegister.phone = firebaseUser.phoneNumber;
      userToRegister.userType = userType;
    } else {
      if (userToRegister) {
        // Si existe este objeto, es que se está dando de alta un tenant
        userToRegister.userId = firebaseUser.uid;
        userToRegister.email = firebaseUser.email;
        userToRegister.userType = TypeUserEnum.TENANT;
      } else {
        // Si no existe, es que estamos dando de alta un homeowner
        userToRegister = new UserDto();
        userToRegister.userId = firebaseUser.uid;
        userToRegister.email = firebaseUser.email;
        userToRegister.userType = TypeUserEnum.HOMEOWNER;
      }
    }
    userToRegister.registerWithGoogle = true;
    this.userService
      .createMinimalUser(userToRegister)
      .subscribe((user: User) => {
        this.processLogin(user);
      });
  }

  //Método de login con redirección en vez de con popup
  /*async gmailLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    await firebase.auth().
    signInWithRedirect(provider)
      .then((auth) => {
        console.log('authenticated with redirect');
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
      });
  }*/
}
