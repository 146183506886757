/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
import { Component, OnInit, Input } from '@angular/core';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { DocumentDTO, DocumentIncidenceDTO } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { base64ToBlob } from '../../utils/sinister.utils';

@Component({
  selector: 'el-buen-inquilino-document-modal',
  templateUrl: './document.modal.html'
})
export class DocumentModalComponent implements OnInit {
  @Input() pdfSrc: Blob | string;
  @Input() documentData: DocumentDTO | DocumentIncidenceDTO;
  @Input() downloadable = false;
  @Input() extension: string;
  @Input() name: string;
  @Input() srcIsBlob = true;

  isDisabledDownload = false;
  fileBlobUrl: string;
  isPdf = false;

  get modalTitle(): string {
    if (!!this.documentData && this.documentData instanceof DocumentDTO) {
      return this.documentData.name;
    } else {
      return this.name;
    }
  }

  constructor(
    private modalController: ModalController,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private docService: DocumentsApiService,
    private utils: UtilsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (
      (!!this.documentData && this.documentData.extension.includes('pdf')) ||
      (!!this.extension && this.extension.includes('pdf'))
    ) {
      this.isPdf = true;
    }

    if (this.srcIsBlob) {
      this.previewFileBlob(this.pdfSrc as Blob);
    } else {
      this.fileBlobUrl = this.pdfSrc as string;
    }
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  onClickVerifiedDoc(document: DocumentDTO): void {
    if (document) {
      this.isDisabledDownload = true;
      this.loadingService.presentLoading(
        this.translateService.instant('components.document_modal.downloading')
      );

      this.downloadVerifiedDocument(document);
    } else {
      this.presentToast(
        this.translateService.instant('components.document_modal.not_exist'),
        'danger'
      );
    }
  }

  private previewFileBlob(file: Blob): void {
    if (!this.isPdf) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        'load',
        () => {
          this.fileBlobUrl = reader.result as string;
        },
        false
      );
    } else {
      const url = window.URL.createObjectURL(file);
      this.fileBlobUrl = url;
    }
  }

  private async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  private downloadVerifiedDocument(documentDownload: DocumentDTO): void {
    if (documentDownload.inputStream) {
      this.loadingService.dismissLoading();

      this.utils.downloadPDF(
        `${documentDownload.name || documentDownload.metadata?.type}.${documentDownload.extension}`,
        base64ToBlob(documentDownload.inputStream, documentDownload.extension)
      );
    } else {
      this.docService.getFileByDocument(documentDownload.id).subscribe(
        (res) => {
          this.loadingService.dismissLoading();

          this.utils.downloadPDF(
            `${documentDownload.name}.${documentDownload.extension}`,
            res
          );
        },
        () => {
          this.loadingService.dismissLoading();
          this.isDisabledDownload = false;

          this.presentToast(
            this.translateService.instant(
              'components.document_modal.download_fail'
            ),
            'danger'
          );
        }
      );
    }
  }
}
