import { SelectOption } from '@shared-private/models/select-option.model';

import { Incidence, IncidenceStatusEnum } from '../models';

export const incidenceStatusList: SelectOption[] = [
  {
    label: 'Cancelada',
    value: IncidenceStatusEnum.CANCELLED
  },
  {
    label: 'Pendiente',
    value: IncidenceStatusEnum.NEW
  },
  {
    label: 'Asignado',
    value: IncidenceStatusEnum.ESCALATED
  },
  {
    label: 'En progreso',
    value: IncidenceStatusEnum.EJECUCION
  },
  {
    label: 'Cerrada',
    value: IncidenceStatusEnum.CLOSED
  }
];

export function filterIncidences(incidences: Incidence[]): Incidence[] {
  return incidences.filter(
    (incidence: Incidence) =>
      incidence.endDate === null || incidence.endDate === undefined
  );
}
