import { User } from './users.model';

export class InsuranceCompany {
  id: string;
  creationDate: Date;
  companyEmail: string;
  companyName: string;
  contactEmail: string;
  contactName: string;
  cif: string;
  active: boolean;
  code: string;
}

export class Insurance {
  candidatureId: string;
  policyNumber: string;
  premium: number;
  creationDate: Date;
  assetId: string;
  assetAddress: string;
  homeowner: string;
  tenants: User[];
}
