/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
import { DecimalPipe } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  AnalysisCatalogue,
  AssetDto,
  Candidature,
  CandidatureDto,
  CandidatureStatusEnum,
  ChangeTenantEmailObj,
  CheckMinimumDoc,
  CreateAnalysisDto,
  CustomModalButtonRole,
  DocumentDTO,
  Incidence,
  MyCustomEvent,
  NotificationDto,
  NotificationTypeEnum,
  TenantCandidature,
  User,
  UserAnalysisStatusEnum
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { IncofisaReportPdfService } from '@core/services/incofisa-report-pdf/incofisa-report-pdf.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import { environment } from '@environments/environment';
import {
  AlertController,
  ModalController,
  NavController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { AddTrialCandidatureComponent } from '@shared/components/add-trial-candidature/add-trial-candidature.component';
import { IncidencesModalComponent } from '@shared/components/incidences-modal/incidences-modal.component';
import { MultipleDocumentModalComponent } from '@shared/components/multiple-document-modal/multiple.document.modal.component';
import { PreviewReportComponent } from '@shared/components/preview-report/preview-report.component';
import { SelectedCandidatureModalComponent } from '@shared/components/selected-candidature-modal/selected-candidature-modal.component';
import { loadStripe } from '@stripe/stripe-js';
import { Subscription, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { AddCandidatureModalComponent } from '../../../private/modules/home-owner/modules/asset/components/add-candidature-modal/add.candidature.modal';
import { AddMultipleCandidatureModalComponent } from '../../../private/modules/home-owner/modules/asset/components/add-multiple-candidature-modal/add-multiple-candidature-modal.component';
import { EditTenantEmailModalComponent } from '../../../private/modules/home-owner/modules/asset/components/edit-tenant-email-modal/edit-tenant-email-modal.component';
import { MoreCandidatureOptionsPopoverComponent } from '../../../private/modules/home-owner/modules/asset/components/more-candidature-options-popover/more-candidature-options-popover.component';
import { RequestInsurancePolicyModalComponent } from '../../../private/modules/home-owner/modules/asset/components/request-insurance-policy-modal/request-insurance-policy-modal.component';
import { RequestReportModalComponent } from '../../../private/modules/home-owner/modules/asset/components/request-report-modal/request-report-modal.component';
import { CreateNewAppointmentModalComponent } from '../../components/create-new-appointment-modal/create-new-appointment-modal.component';
import { AppointmentFormValue } from '../../models/calendar.model';
import {
  MoreCandidatureOptionEnum,
  MoreCandidatureOptionsPopoverResponse
} from '../../models/more-candidature-options-popover.model';
import { presentToast } from '../../utils/toast.utils';

@Component({
  selector: 'el-buen-inquilino-candidatures-page',
  templateUrl: './candidatures-page.component.html'
})
export class CandidaturesPageComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  asset: AssetDto;

  user: User;

  candidatures: Candidature[] = [];
  rejectedAndExpiredCandidatures: Candidature[] = [];
  candidatureStatusEnum = CandidatureStatusEnum;
  notify: boolean;
  userAnalysisStatusEnum = UserAnalysisStatusEnum;
  savingAnalysis = false;
  today = new Date();

  subscriptions: Subscription[] = [];

  screenWidth: number;
  showBackground = true;

  candidatureChangeValue = false;
  showRejected = false;
  buttonName = 'Mostrar descartadas / caducadas';

  widthChangeSub: Subscription;

  fromClosedPage = false;

  @ViewChild('scoringBusiness', { static: false })
  canvasElement: ElementRef<HTMLCanvasElement>;

  get showVppModality(): boolean {
    return !!this.asset?.vppModality;
  }

  get vppModality(): string {
    return this.asset?.vppModality;
  }

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthService,
    private candidatureService: CandidaturesApiService,
    private assetService: AssetsApiService,
    private usersService: UsersApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private alertController: AlertController,
    private utilsService: UtilsService,
    private notificationsService: NotificationsApiService,
    private popoverController: PopoverController,
    private windowMeasuresService: WindowMeasuresService,
    private translateService: TranslateService,
    private docService: DocumentsApiService,
    private numberPipe: DecimalPipe,
    private incofisaReportPdfService: IncofisaReportPdfService,
    private userService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.setUser();
    this.setAsset();
    this.setNotify();
    this.candidatureService.shouldBeRefreshCandidature = false;
    this.initCandidatures();

    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width) => {
        this.screenWidth = width;
      });
  }

  ionViewWillEnter(): void {
    // Cuando se vuelve desde la pagina del usuario
    // Se vuelve a buscar los datos para actualizar la información
    if (history.state?.return) {
      this.initCandidatures();
    }

    // Si se viene de la redirección al cambiar la candidatura de asset
    if (history.state?.moveCandidature) {
      this.asset = null;
      this.initCandidatures();
    }
  }

  ngAfterViewChecked(): void {
    if (this.candidatureService.shouldBeRefreshCandidature) {
      this.initCandidatures();
    }
  }

  showCandidatureStatusSelect(candidature: Candidature): boolean {
    return (
      candidature.candidatureStatusEnum !==
        this.candidatureStatusEnum.EXPIRED && !this.user?.blockCandidatureStatus
    );
  }

  showSelectOption(
    candidature: Candidature,
    status: CandidatureStatusEnum
  ): boolean {
    switch (status) {
      case CandidatureStatusEnum.WITHOUT_CHECKING:
        return (
          (candidature.candidatureStatusEnum ===
            CandidatureStatusEnum.WITHOUT_CHECKING ||
            candidature.candidatureStatusEnum ===
              CandidatureStatusEnum.PENDING) &&
          this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );

      case CandidatureStatusEnum.PENDING:
        return (
          candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
          !this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );

      case CandidatureStatusEnum.PRE_SELECT:
        return (
          (candidature.candidatureStatusEnum ===
            CandidatureStatusEnum.PENDING ||
            candidature.candidatureStatusEnum ===
              CandidatureStatusEnum.PRE_SELECT) &&
          !this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );

      case CandidatureStatusEnum.SELECT:
        return (
          candidature.candidatureStatusEnum !== CandidatureStatusEnum.PENDING &&
          !this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );
    }
  }

  getCandidatureStatusSelectValue(
    candidature: Candidature
  ): CandidatureStatusEnum {
    if (
      candidature.candidatureStatusEnum ===
      CandidatureStatusEnum.WITHOUT_CHECKING
    ) {
      return CandidatureStatusEnum.WITHOUT_CHECKING;
    }

    if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      !this.checkCandidatureTenants(candidature)
    ) {
      return CandidatureStatusEnum.WITHOUT_CHECKING;
    }

    return candidature.candidatureStatusEnum;
  }

  disableCandidatureStatusSelect(candidature: Candidature): boolean {
    return (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.REJECT ||
      this.checkAllUserAreStudent(candidature) ||
      this.isInAnalysis(candidature)
    );
  }

  initCandidatures(): void {
    const isAssetClosed =
      this.router.url.split('/')[this.router.url.split('/').length - 3];

    if (isAssetClosed === 'closed') {
      this.fromClosedPage = true;
      this.callAssetByIdServiceWithParams();
    } else {
      this.asset
        ? this.getCandidatures(this.asset)
        : this.callAssetByIdServiceWithParams();
    }

    this.candidatureService.shouldBeRefreshCandidature = false;
  }

  callAssetByIdServiceWithParams(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.assetService.getAsset(params.id).subscribe((asset) => {
        this.asset = asset;
        this.getCandidatures(asset);
      });
    });
  }

  getCandidatures(asset: AssetDto): void {
    if (this.user.portfolioId || this.user.apiRol === 'API_ADMIN') {
      this.candidatureService
        .getCandidaturesForBuildings(
          asset.id,
          this.authenticationService.user.apiId,
          this.authenticationService.user.portfolioId,
          this.fromClosedPage ? CandidatureStatusEnum.SELECT : null
        )
        .subscribe((data: Candidature[]) => {
          this.prepareGetCandidaturesResponse(asset, data);
        });
    } else {
      this.candidatureService.getCandidaturesByAsset(asset.id).subscribe(
        (data: Candidature[]) => {
          this.prepareGetCandidaturesResponse(asset, data);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  prepareGetCandidaturesResponse(asset: AssetDto, data: Candidature[]): void {
    this.candidatures = data.map((e) => {
      e.asset.street = asset.street;
      e.asset.number = asset.number;
      e.asset.portal = asset.portal;
      e.asset.floor = asset.floor;
      e.asset.door = asset.door;
      e.asset.postalCode = asset.postalCode;
      e.asset.province = asset.province;
      e.asset.town = asset.town;
      e.asset.rentalPrice = asset.rentalPrice;
      e.asset.reports = asset.reports;
      if (new Date(e.expirationDate) < new Date()) {
        if (e.candidatureStatusEnum === CandidatureStatusEnum.SELECT) {
          e.isCandidatureSelectAndExpired = true;
        }
        e.candidatureStatusEnum = this.candidatureStatusEnum.EXPIRED;
      }
      return e;
    });

    // Separo los reject
    this.rejectedAndExpiredCandidatures = this.candidatures.filter(
      (item) =>
        item.candidatureStatusEnum === CandidatureStatusEnum.REJECT ||
        item.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED
    );
    this.candidatures = this.candidatures.filter(
      (item) =>
        item.candidatureStatusEnum !== CandidatureStatusEnum.REJECT &&
        item.candidatureStatusEnum !== CandidatureStatusEnum.EXPIRED
    );

    if (this.candidatures && this.candidatures.length > 0) {
      this.orderCandidatures();
      this.checkArag();
    }
  }

  async presentToast(
    msg: string,
    toastcolor: string,
    duration: number = 2000
  ): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  goBackToAssets(): void {
    const currentUrl = this.router.url.split('/');

    currentUrl.shift();
    currentUrl.pop();
    currentUrl.pop();

    this.navCtrl.navigateBack([...currentUrl], {
      state: { return: true }
    });
  }

  goBackToAssetsWithRefresh(): void {
    this.router.navigate(['home-owner', this.user.id, 'assets'], {
      state: { return: true }
    });
  }

  downloadAssetReport(asset): void {
    this.loadingService.presentSecondLoader(
      this.translate.instant(
        'pages.profile.home-owner.assets.candidature.generating_pdf'
      ),
      true
    );
    this.assetService.getAssetReport(asset.id).subscribe(
      (blob: any) => {
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
          alert(
            this.translate.instant(
              'pages.profile.home-owner.assets.disable_popup_blocker'
            )
          );
        }
        this.loadingService.dismissSecondLoader();
      },
      () => {
        this.presentToast(
          this.translate.instant(
            'pages.profile.home-owner.assets.can_not_generate_report'
          ),
          'danger'
        );
        this.loadingService.dismissSecondLoader();
      }
    );
  }

  checkCandidatureTenants(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.every((tenant) => {
      return this.checkTenant(tenant);
    });
  }

  checkTenant(tenant: TenantCandidature): boolean {
    if (tenant && tenant.user && tenant.user.id) {
      return true;
    } else {
      return false;
    }
  }

  checkTenantRejected(tenant: TenantCandidature): boolean {
    if (
      tenant &&
      tenant.user &&
      tenant.user.id &&
      tenant.userCandidatureStatusDtoEnum === 'REJECT'
    ) {
      return true;
    } else {
      return false;
    }
  }

  async changeCandidatureStatus(
    ev: Event,
    candidature: Candidature
  ): Promise<void> {
    candidature.candidatureStatusEnum = (ev as CustomEvent<MyCustomEvent>)
      .detail.value as CandidatureStatusEnum;

    if (this.candidatureChangeValue) {
      // Aquí entra cuando se ha seleccionado una candidatura, pero se vuelve a estado preseleccionado por pedir informe
      this.candidatureChangeValue = false;
      return;
    }

    if (
      candidature.candidatureStatusEnum === this.candidatureStatusEnum.SELECT
    ) {
      // Si se elige seleccionado, se mira si tiene análisis
      if (!this.isInAnalysis(candidature) && !candidature?.score) {
        // Si no tiene análisis, sacamos modal para ofrecerlo
        const modal = await this.modalController.create({
          component: RequestReportModalComponent,
          backdropDismiss: false,
          cssClass: 'request-report-modal'
        });
        await modal.present();

        const { data } = await modal.onWillDismiss();

        // Si pide el informe
        if (data.action) {
          // Comprobamos que tienen todos los documentos necesarios
          if (!this.checkRequestAnalysis(candidature)) {
            this.presentToast(
              this.translate.instant('components.request_report_modal.doc_msg'),
              'danger',
              5000
            );
          } else {
            // Si tiene toda la documentación, pedimos el informe
            this.requestAnalysis(candidature);
          }
          const index = this.candidatures.findIndex(
            (c) => c.id === candidature.id
          );
          this.candidatures[index].candidatureStatusEnum =
            this.candidatureStatusEnum.PRE_SELECT;
          this.candidatureChangeValue = true;
          return;
        }
      }
    }

    this.loadingService.presentSecondLoader(null);

    if (this.user.trialVersion) {
      this.notify = false;
    }

    this.candidatureService
      .updateCandidatureStatus(
        candidature,
        candidature.candidatureStatusEnum,
        this.notify
      )
      .subscribe(
        () => {
          if (
            candidature.candidatureStatusEnum ===
            this.candidatureStatusEnum.REJECT
          ) {
            this.presentToast(
              this.translate.instant(
                'pages.profile.home-owner.assets.candidature.has_ruled_out_candidature'
              ),
              'success'
            );
          } else if (
            candidature.candidatureStatusEnum ===
            this.candidatureStatusEnum.PRE_SELECT
          ) {
            this.presentToast(
              this.translate.instant(
                'pages.profile.home-owner.assets.candidature.preselect_candidature'
              ),
              'success'
            );
          } else if (
            candidature.candidatureStatusEnum ===
            this.candidatureStatusEnum.SELECT
          ) {
            this.presentToast(
              this.translate.instant(
                'pages.profile.home-owner.assets.candidature.candidature_has_been_selected'
              ),
              'success'
            );
            this.candidatureService
              .getCandidaturesById(candidature.id)
              .subscribe((updatedCandidature: Candidature) => {
                candidature = updatedCandidature;
                this.presentSelectedCandidatureModal(candidature);
              });
          }
          this.loadingService.dismissSecondLoader();
        },
        () => {
          const status = this.getCandidatureStatus(candidature);
          this.presentToast(
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.transition_not_allowed',
              { status: status }
            ),
            'danger'
          );
          this.loadingService.dismissSecondLoader();
        }
      );
  }

  async presentSelectedCandidatureModal(
    selectedCandidature: Candidature,
    closed?: boolean
  ): Promise<void> {
    const closedOperation: boolean = closed || false;
    const modal = await this.modalController.create({
      component: SelectedCandidatureModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature: selectedCandidature,
        closedOperation
      },
      id: 'selected-candidature'
    });
    modal.onDidDismiss().then(() => {
      this.goBackToAssets();
    });
    return await modal.present();
  }

  async requestInsurancePolicy(
    selectedCandidature: Candidature,
    closed?: boolean
  ): Promise<void> {
    const closedOperation: boolean = closed || false;
    const modal = await this.modalController.create({
      component: RequestInsurancePolicyModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature: selectedCandidature,
        closedOperation
      },
      id: 'request-insurance-policy'
    });
    modal.onDidDismiss().then((_) => {
      this.goBackToAssetsWithRefresh();
    });
    return await modal.present();
  }

  getCandidatureStatus(candidature: Candidature): string {
    switch (candidature.candidatureStatusEnum) {
      case this.candidatureStatusEnum.WITHOUT_CHECKING:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.without_checking'
        );
      case this.candidatureStatusEnum.PENDING:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.pending'
        );
      case this.candidatureStatusEnum.PRE_SELECT:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.preselect'
        );
      case this.candidatureStatusEnum.REJECT:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.reject'
        );
      case this.candidatureStatusEnum.SELECT:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.select'
        );
      case this.candidatureStatusEnum.EXPIRED:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.expired'
        );
      default:
        return '';
    }
  }

  checkCandidatureStatus(candidature: Candidature): boolean {
    if (
      candidature.candidatureStatusEnum ===
      this.candidatureStatusEnum.WITHOUT_CHECKING
    ) {
      return false;
    } else if (
      candidature.candidatureStatusEnum === this.candidatureStatusEnum.REJECT
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkWithOutChecking(candidature: Candidature): boolean {
    if (
      candidature.candidatureStatusEnum ===
      this.candidatureStatusEnum.WITHOUT_CHECKING
    ) {
      return false;
    } else {
      return true;
    }
  }

  async presentInfoRating(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_rating.header'
      ),
      subHeader: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_rating.subheader'
      ),
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_rating.message'
      ),
      buttons: [
        this.translate.instant(
          'pages.profile.home-owner.assets.candidature.info_rating.button'
        )
      ]
    });
    await alert.present();
  }

  async presentInfoNoValidated(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_no_validated.message'
      ),
      buttons: [
        this.translate.instant(
          'pages.profile.home-owner.assets.candidature.info_rating.button'
        )
      ]
    });
    await alert.present();
  }

  async presentInfoProgress(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_progress.header'
      ),
      subHeader: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_progress.subheader'
      ),
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_progress.message'
      ),
      buttons: [
        this.translate.instant(
          'pages.profile.home-owner.assets.candidature.info_rating.button'
        )
      ]
    });
    await alert.present();
  }

  updateColor(progress): string {
    return this.candidatureService.updateColor(progress);
  }

  checkIncidences(incidences: Incidence[]): boolean {
    return (
      incidences &&
      incidences.length > 0 &&
      incidences.some(
        (incidence) =>
          incidence.endDate === null || incidence.endDate === undefined
      )
    );
  }

  filterIncidences(incidences: Incidence[]): Incidence[] {
    return incidences.filter(
      (incidence) =>
        incidence.endDate === null || incidence.endDate === undefined
    );
  }

  async showIncidences(tenant: TenantCandidature): Promise<void> {
    const incidenceModal = await this.modalController.create({
      component: IncidencesModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        incidenceList: this.filterIncidences(tenant.incidences),
        user: this.authenticationService.user,
        analysisId: tenant.requestAnalysisId
      }
    });
    incidenceModal.onDidDismiss().then((close) => {
      if (close.data.saving) this.getCandidatures(this.asset);
    });
    await incidenceModal.present();
  }

  async presentRequestDocumentation(
    candidature: Candidature,
    tenantId: string
  ): Promise<void> {
    const candidatureDto = candidature as unknown as CandidatureDto;
    const alert = await this.alertController.create({
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.request_documentation.header'
      ),
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.request_documentation.message'
      ),
      buttons: [
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.request_documentation.cancel'
          ),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.request_documentation.send'
          ),
          cssClass: 'danger',
          handler: (): void => {
            this.requestDocumentation(candidatureDto, tenantId);
          }
        }
      ]
    });
    await alert.present();
  }

  requestDocumentation(candidature: CandidatureDto, tenantId: string): void {
    this.usersService.requestDocumentation(candidature.id, tenantId).subscribe(
      () => {
        this.presentToast(
          this.translate.instant(
            'pages.profile.home-owner.assets.requestDocumentOk'
          ),
          'success'
        );
        this.getCandidatures(this.asset);
      },
      () => {
        this.presentToast(
          this.translate.instant(
            'pages.profile.home-owner.assets.requestDocumentError'
          ),
          'danger'
        );
      }
    );
  }

  checkTenantDoc(candidature: Candidature): boolean {
    if (
      this.checkTenantHasGuarantor(candidature) &&
      this.checkStudentTenant(candidature)
    ) {
      const tenantCandidatureListWithoutStudent =
        candidature.tenantCandidatureList.filter(
          (tenant: TenantCandidature) => !tenant.user.student
        );

      return this.calculateTenantIsStudentHasGuarantor(
        candidature,
        tenantCandidatureListWithoutStudent
      );
    } else {
      return this.checkAllTenantDocumentAreReady(
        candidature.tenantCandidatureList,
        candidature?.score
      );
    }
  }

  checkAllTenantDocumentAreReady(
    tenantCandidatureList: TenantCandidature[],
    score: number
  ): boolean {
    return tenantCandidatureList.every((tenant: TenantCandidature) => {
      const minDoc = this.prepareMinDocForTenant(tenant, score);
      return this.utilsService.checkMinimumDocForTenantRol(minDoc);
    });
  }

  businessDocumentsCheck(tenant: TenantCandidature): boolean {
    return tenant.hasDni && tenant.hasIs200Model && tenant.hasIvaQuarterModel;
  }

  retiredDocumentsCheck(tenant: TenantCandidature): boolean {
    return tenant.hasDni && tenant.hasPensionerRecognition;
  }

  freelanceDocumentsCheck(tenant: TenantCandidature): boolean {
    return (
      tenant.hasDni &&
      tenant.hasRent &&
      tenant.hasModel130 &&
      tenant.hasIvaQuarterModel
    );
  }

  workerDocumentsCheck(tenant: TenantCandidature): boolean {
    return tenant.hasDni && tenant.hasPayslip;
  }

  calculateTenantIsStudentHasGuarantor(
    candidature: Candidature,
    tenantCandidatureListWithoutStudent: TenantCandidature[]
  ): boolean {
    return (
      candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) => tenant.hasDni
      ) &&
      tenantCandidatureListWithoutStudent.length > 0 &&
      this.checkAllTenantDocumentAreReady(
        tenantCandidatureListWithoutStudent,
        candidature?.score
      )
    );
  }

  requestAnalysis(candidature: Candidature, reLaunched?: boolean): void {
    this.savingAnalysis = true;
    if (this.user.bigTenant) {
      // For Bigtenants
      if (
        this.asset.reports.requested >= this.user.session.maxReportsByAssetMonth
      ) {
        this.presentModalMaxReportByAssetReached();
      } else {
        this.requestMutipleAnalysis(candidature, reLaunched);
      }
    } else {
      // For APIs
      if (this.user.reports.requested >= this.user.session.maxReportsMonth) {
        this.presentStripeModal(candidature);
      } else {
        this.requestMutipleAnalysis(candidature, reLaunched);
      }
    }
  }

  checkRequestMultipleAnalysis(candidature): boolean {
    return (
      candidature.tenantCandidatureList.length > 0 &&
      candidature.tenantCandidatureList.filter((tenant) => tenant.status === 0)
        .length > 0
    );
  }

  requestMutipleAnalysis(candidature: Candidature, reLaunched?: boolean): void {
    this.candidatureService
      .requestMutipleAnalysis(candidature, reLaunched)
      .subscribe((_) => {
        this.savingAnalysis = false;
        this.getCandidatures(candidature.asset);
      });
  }

  async presentStripeModal(candidature: Candidature): Promise<void> {
    this.savingAnalysis = false;
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.stripe_modal.header'
      ),
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.stripe_modal.message'
      ),
      buttons: [
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.stripe_modal.cancel'
          ),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.stripe_modal.request'
          ),
          cssClass: 'danger',
          handler: (): void => {
            this.candidatureService
              .requestMutipleAnalysis(candidature) //aki?
              .subscribe((_) => {
                this.createStripeExtraSession(candidature);
              });
          }
        }
      ]
    });
    await alert.present();
  }

  checkRequestCandidature(candidature: Candidature): boolean {
    // Si hay score no necesitamos mas comprobaciones y habilitamos botón
    if (candidature && candidature.score) return false;
    if (candidature && candidature.tenantCandidatureList.length > 0) {
      return !candidature.tenantCandidatureList.every(
        (tenant) => tenant.status === UserAnalysisStatusEnum.REPORTED_ANALYSIS
      );
    } else {
      return true;
    }
  }

  async previewReport(candidature: Candidature): Promise<void> {
    await this.loadingService.presentSecondLoader(null, true);

    // Preparamos la llamada para obtener los informes
    const calls: unknown[] = [];

    candidature.tenantCandidatureList.forEach((tc: TenantCandidature) => {
      calls.push(
        this.usersService.getCompletedAnalysis(candidature.id, tc.user.id)
      );
    });

    // Añadimos la llamada para obtener el catálogo del análisis
    calls.push(this.usersService.getCatalogue());

    forkJoin(calls)
      .pipe(first())
      .subscribe(
        async (resp: unknown[]) => {
          // Recuperamos la información del catálogo y la eliminamos del array
          const catalogue: AnalysisCatalogue = resp.pop() as AnalysisCatalogue;

          await this.loadingService.dismissSecondLoader();
          const modal = await this.modalController.create({
            component: PreviewReportComponent,
            cssClass: 'preview-report',
            componentProps: {
              reports: resp as CreateAnalysisDto[],
              candidature,
              catalogue
            }
          });
          await modal.present();

          const dismissEvent = await modal.onWillDismiss();
          if (dismissEvent.role === 'download') {
            // this.incofisaReportPdfService.getReport(
            //   candidature,
            //   resp as CreateAnalysisDto[],
            //   catalogue,
            //   this.canvasElement
            // );
            await this.loadingService.presentSecondLoader(null);
            this.usersService
              .createReport(candidature.id)
              .pipe(first())
              .subscribe({
                next: async (blob: Blob) => {
                  this.loadingService.dismissSecondLoader();
                  const url = window.URL.createObjectURL(blob);
                  const pwa = window.open(url);
                  if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
                    alert(
                      'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
                    );
                  }
                  await this.loadingService.dismissSecondLoader();
                },
                error: async () =>
                  await this.loadingService.dismissSecondLoader()
              });
          }
        },
        async () => await this.loadingService.dismissSecondLoader()
      );
  }

  checkTenantsStatus(
    candidature: Candidature,
    status: UserAnalysisStatusEnum,
    inProcess?: UserAnalysisStatusEnum
  ): boolean {
    if (!this.checkAllUserHaveAnalysis(candidature, inProcess)) {
      return candidature.tenantCandidatureList.every(
        (tenant) => tenant.status === status || tenant.status === inProcess
      );
    } else {
      return candidature.tenantCandidatureList.every(
        (tenant) => tenant.status === status
      );
    }
  }

  checkAllUserAreStudent(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.every(
      (tenant) => tenant.user.student === true
    );
  }

  checkBusinessTenantNotAlone(candidature: Candidature): boolean {
    return (
      candidature?.tenantCandidatureList?.length > 1 &&
      candidature.tenantCandidatureList.some((tenant) => tenant.user.business)
    );
  }

  checkIsStudentNoPaySlip(candidature: Candidature, user: User): boolean {
    if (
      user.student &&
      this.checkTenantHasGuarantor(candidature) &&
      this.checkStudentTenant(candidature)
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIsStudentForshowPayslipOrRentChip(user: User): boolean {
    if (user.student) return false;
    return true;
  }

  checkIsFreelance(user: User): boolean {
    if (user.freelance) {
      return true;
    } else {
      return false;
    }
  }

  checkIsStudentNoRent(candidature: Candidature, user: User): boolean {
    if (
      user.student &&
      this.checkTenantHasGuarantor(candidature) &&
      this.checkStudentTenant(candidature)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check use case when tenant is unemployed or student but need have a valid guarantor to allow request analysis, now in masked guarantor feature we need accept only valid Dni and payslips
   */
  checkTenantHasGuarantor(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) =>
          tenant.hasDni &&
          (tenant.hasPayslip ||
            tenant.hasRent ||
            tenant.hasPensionerRecognition)
      ).length > 0
    );
  }

  /**
   * Check if tenant checked student toggle on his profile, also need have a garantor to complete use case
   */
  checkStudentTenant(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) => tenant.user.student
      ).length > 0
    );
  }

  async addCandidature(isMultiple: boolean = false): Promise<void> {
    let addCandidatureModal: any;
    if (
      this.user?.bigTenant &&
      this.user.session.maxCandidaturesByAssetMonth === this.candidatures.length
    ) {
      // Un bigtenant tiene un máximo de candidaturas por asset
      const alert = await this.alertController.create({
        cssClass: 'custom-alert',
        header: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.add_candidature.header'
        ),
        message: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.add_candidature.message'
        ),
        buttons: [
          {
            text: this.translate.instant(
              'pages.profile.home-owner.assets.candidature.add_candidature.accept'
            ),
            role: 'cancel',
            cssClass: 'secondary'
          }
        ]
      });
      await alert.present();
    } else if (this.user.trialVersion) {
      addCandidatureModal = await this.modalController.create({
        component: AddTrialCandidatureComponent,
        cssClass: 'auto-height modal-extend',
        componentProps: {
          user: this.authenticationService.user,
          asset: this.asset
        }
      });
      addCandidatureModal.onDidDismiss().then((close) => {
        if (close && close.data && close.data.saving) {
          this.getCandidatures(this.asset);
        }
      });
      await addCandidatureModal.present();
    } else {
      const candidaturesCurrentMonth =
        Number(localStorage.getItem('candidaturesCurrentMonth')) || 0;
      if (this.user.session.maxCandidaturesMonth <= candidaturesCurrentMonth) {
        const alert = await this.alertController.create({
          cssClass: 'custom-alert',
          header: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.add_candidature.header'
          ),
          message: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.add_candidature.message2'
          ),
          buttons: [
            {
              text: this.translate.instant(
                'pages.profile.home-owner.assets.candidature.add_candidature.cancel'
              ),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: this.translate.instant(
                'pages.profile.home-owner.assets.candidature.add_candidature.upgrade'
              ),
              handler: (): void => {
                this.router.navigateByUrl(
                  `/home-owner/${this.user.id}/profile`
                );
              }
            }
          ]
        });
        await alert.present();
      } else {
        // let addCandidatureModal;
        if (isMultiple) {
          addCandidatureModal = await this.modalController.create({
            component: AddMultipleCandidatureModalComponent,
            cssClass: 'auto-height modal-extend',
            componentProps: {
              user: this.authenticationService.user,
              asset: this.asset
            }
          });
        } else {
          addCandidatureModal = await this.modalController.create({
            component: AddCandidatureModalComponent,
            cssClass: 'auto-height modal-extend',
            componentProps: {
              user: this.authenticationService.user,
              asset: this.asset
            }
          });
        }
      }

      if (addCandidatureModal) {
        addCandidatureModal.onDidDismiss().then((close) => {
          if (close && close.data && close.data.saving) {
            let candidaturesCurrentMonth =
              Number(localStorage.getItem('candidaturesCurrentMonth')) || 0;
            candidaturesCurrentMonth++;
            localStorage.setItem(
              'candidaturesCurrentMonth',
              candidaturesCurrentMonth.toString()
            );
            this.getCandidatures(this.asset);
          }
        });

        await addCandidatureModal.present();
      }
    }
  }

  async presentModalMaxReportByAssetReached(): Promise<void> {
    this.savingAnalysis = false;
    if (this.user?.bigTenant) {
      // Un bigtenant tiene un máximo de reports por asset
      const alert = await this.alertController.create({
        cssClass: 'custom-alert',
        header: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.max_reports_reached.header'
        ),
        message: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.max_reports_reached.message'
        ),
        buttons: [
          {
            text: this.translate.instant(
              'pages.profile.home-owner.assets.candidature.max_reports_reached.accept'
            ),
            role: 'cancel',
            cssClass: 'secondary'
          }
        ]
      });
      await alert.present();
    }
  }

  async viewDocuments(
    documents: DocumentDTO[],
    exists: boolean
  ): Promise<void> {
    if (exists) {
      this.loadingService.presentSecondLoader(null, true);
      const modal = await this.modalController.create({
        component: MultipleDocumentModalComponent,
        cssClass: 'custom-modal-xl modal-extend',
        componentProps: {
          documentsData: documents
        }
      });
      return await modal.present();
    }
  }

  checkRequestAnalysis(candidature: Candidature): boolean {
    if (this.user.bigTenant) {
      return (
        this.checkTenantDoc(candidature) &&
        this.checkTenantsStatus(
          candidature,
          this.userAnalysisStatusEnum.WITHOUT_ANALYSIS
        ) &&
        this.checkCandidatureStatus(candidature)
      );
    } else {
      return (
        this.checkTenantDoc(candidature) &&
        this.checkTenantsStatus(
          candidature,
          this.userAnalysisStatusEnum.WITHOUT_ANALYSIS
        ) &&
        this.checkCandidatureStatus(candidature)
      );
    }
  }

  dateFormat(date): number {
    return new Date(date).getTime();
  }

  showPaysSlipChip(
    candidature: Candidature,
    tenant: TenantCandidature
  ): boolean {
    return (
      !this.checkIsStudentNoPaySlip(candidature, tenant.user) &&
      !this.checkIsFreelance(tenant.user) &&
      this.checkIsStudentForshowPayslipOrRentChip(tenant.user) &&
      !tenant.user.retired &&
      !tenant.user.business
    );
  }

  showPensionerRecognitionChip(tenant: TenantCandidature): boolean {
    return tenant.user.retired;
  }

  showIs200ModelChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showModel130Chip(tenant: TenantCandidature): boolean {
    return (
      tenant.user.freelance &&
      this.utilsService.checkModel130ForFreelance(
        tenant.hasIvaQuarterModel,
        tenant.hasModel130
      )
    );
  }

  showSSCertificateChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showAEATCertificateChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showPYGQuarterChip(tenant: TenantCandidature): boolean {
    return tenant.user.business;
  }

  showIvaQuarterModelChip(tenant: TenantCandidature): boolean {
    return (
      tenant.user.business ||
      (tenant.user.freelance &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          tenant.hasIvaQuarterModel,
          tenant.hasModel130
        ))
    );
  }

  showRentChip(candidature: Candidature, tenant: TenantCandidature): boolean {
    return (
      (!this.checkIsStudentNoRent(candidature, tenant.user) &&
        this.checkIsFreelance(tenant.user) &&
        this.checkIsStudentForshowPayslipOrRentChip(tenant.user)) ||
      !!this.asset.vppModality
    );
  }

  validationToShowDownloadAnalysis(
    candidature: Candidature,
    userAnalysisStatusEnumReportedAnalysis
  ): boolean {
    return (
      this.checkTenantDoc(candidature) &&
      this.checkTenantsStatus(
        candidature,
        userAnalysisStatusEnumReportedAnalysis
      )
    );
  }

  checkPendingToDownload(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) => tenant.pendingToDownload
    );
  }

  navigateToTenantFromOwnerView(tenant: User, candidatureId: string): void {
    if (this.authenticationService.user?.trialVersion) {
      const candidature = this.candidatures.find(
        (candidature) => candidature.id === candidatureId
      );
      this.candidatureService.selectedCandidature = candidature;
      this.router.navigate([this.router.url + '/documents/' + tenant.id]);
    } else {
      window.location.href =
        'tenant/null/home?idContact=' + tenant.id + '&fromHomeowner=true';
    }
  }

  reSendEmailToTenant(userTenant: User): void {
    const reSendEmailDto = { asset: this.asset, userTenant: userTenant };
    this.candidatureService.reSendEmailToTenant(reSendEmailDto).subscribe(
      () => {
        this.presentToast(
          this.translate.instant(
            'pages.profile.home-owner.assets.candidature.resend_email.re-invite'
          ),
          'success'
        );
        this.getCandidatures(this.asset);
      },
      () => {
        this.presentToast(
          this.translate.instant(
            'pages.profile.home-owner.assets.candidature.resend_email.can_not_send'
          ),
          'danger'
        );
      }
    );
  }

  async editEmailModal(
    tenantEmail: string,
    candidature: Candidature
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: EditTenantEmailModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        tenantEmail: tenantEmail,
        candidature: candidature
      } as Partial<EditTenantEmailModalComponent>
    });
    modal.onDidDismiss().then((close) => {
      if (close && close.data && close.data.changeTenantEmailObj) {
        this.changeTenantEmailForNewTenant(close.data.changeTenantEmailObj);
      }
    });
    return await modal.present();
  }

  changeTenantEmailForNewTenant(
    changeTenantEmailObj: ChangeTenantEmailObj
  ): void {
    this.loadingService.presentSecondLoader(null, true);
    this.candidatureService
      .changeTenantEmailForNewTenantsAndSendEmail(changeTenantEmailObj)
      .subscribe(
        () => {
          this.presentToast(
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.change_email.change'
            ),
            'success'
          );
          // Borramos la vieja notificación al email original y en el callback creamos una nueva notificación al email correcto
          this.notificationsService
            .deleteNotification(
              NotificationTypeEnum.CANDIDATURE,
              changeTenantEmailObj.candidatureId,
              changeTenantEmailObj.email
            )
            .subscribe((_) => {
              this.createNotification(
                changeTenantEmailObj.candidatureId,
                this.asset,
                changeTenantEmailObj.newTenantEmail
              );
            });
          this.getCandidatures(this.asset);
          this.loadingService.dismissSecondLoader();
        },
        () => {
          this.presentToast(
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.change_email.can_not_change'
            ),
            'danger'
          );
          this.loadingService.dismissSecondLoader();
        }
      );
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
    this.widthChangeSub?.unsubscribe();
  }

  isInAnalysis(candidature: Candidature): boolean {
    return this.checkTenantsStatus(
      candidature,
      this.userAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
      this.userAnalysisStatusEnum.REPORTED_ANALYSIS
    );
  }

  setEmptyListMessage(): string {
    if (this.user.apiId) {
      return this.translate.instant(
        'pages.profile.home-owner.assets.candidature.unavailable_candidatures_portfolio'
      );
    } else {
      return this.translate.instant(
        'pages.profile.home-owner.assets.candidature.unavailable_candidatures'
      );
    }
  }

  toggleRejected(): void {
    this.showRejected = !this.showRejected;

    // Change the name of the button.
    if (this.showRejected) this.buttonName = 'Ocultar descartadas / caducadas';
    else this.buttonName = 'Mostrar descartadas / caducadas';
  }

  hideEditCandidature(candidature: Candidature): boolean {
    return (
      !(candidature.tenantCandidatureList.length > 0) ||
      this.isInAnalysis(candidature) ||
      // this.user.trialVersion ||
      candidature.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED ||
      !!candidature?.score
    );
  }

  hideReCalculateScore(candidature: Candidature): boolean {
    return (
      // !this.checkRequestAnalysis(candidature) ||
      candidature.tenantCandidatureList.every(
        (tenant) => tenant.status === UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS
      ) ||
      !this.checkCandidatureStatus(candidature) ||
      !this.checkTenantDoc(candidature)
    );
  }

  async showCandidatureMoreOptionsPopover(
    ev,
    candidature: Candidature
  ): Promise<void> {
    if (candidature.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED) {
      return;
    }
    let building = null;
    if (this.asset.buildingId) {
      building = { id: this.asset.buildingId };
    }

    const popover = await this.popoverController.create({
      component: MoreCandidatureOptionsPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        candidature,
        disableEdition: this.hideEditCandidature(candidature),
        building,
        user: this.user,
        disabledReCalculate: this.hideReCalculateScore(candidature),
        disableReinforce: candidature.score === null,
        disableRelocate: this.isInAnalysis(candidature),
        userHasCalendar: this.user.hasCalendar
      } as Partial<MoreCandidatureOptionsPopoverComponent>
    });
    await popover.present();

    const { data }: OverlayEventDetail<MoreCandidatureOptionsPopoverResponse> =
      await popover.onWillDismiss();

    if (data) {
      if (data?.option) {
        switch (data?.option) {
          case MoreCandidatureOptionEnum.EDIT_CANDIDATURE: {
            this.getCandidatures(this.asset);
            break;
          }
          case MoreCandidatureOptionEnum.MOVE_CANDIDATURE: {
            const id = data?.newAsset?.id;

            const urlArray = this.router.url.split('/');
            urlArray.shift();
            urlArray.pop();
            urlArray.pop();

            this.router.navigate([...urlArray, id, 'candidatures'], {
              state: { moveCandidature: true }
            });
            break;
          }
          case MoreCandidatureOptionEnum.RECALCULATE_SCORE: {
            this.getCandidatures(this.asset);
            break;
          }
          case MoreCandidatureOptionEnum.NOTE_CANDIDATURE: {
            this.getCandidatures(this.asset);
            break;
          }
          case MoreCandidatureOptionEnum.APPOINTMENT: {
            this.addAppointment(candidature.id);
            break;
          }
        }
      }
    }
  }

  async addAppointment(candidatureId: string): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateNewAppointmentModalComponent,
      backdropDismiss: true,
      componentProps: {
        user: this.user,
        showLinkTemplate: false
      } as Partial<CreateNewAppointmentModalComponent>
    });
    await modal.present();

    const { data, role }: OverlayEventDetail<AppointmentFormValue> =
      await modal.onWillDismiss();

    if (role === (CustomModalButtonRole.CREATE as string)) {
      this.onNewAppointmentDismiss(data, candidatureId);
    }
  }

  async launchViewDocuments(tenant: TenantCandidature): Promise<void> {
    return new Promise((resolve, reject) => {
      this.docService.getDocuments('20', tenant?.user?.id).subscribe(
        (documents) => {
          this.viewDocumentsFull(documents);
          resolve();
        },
        () => {
          this.presentToast(
            'Ha ocurrido un error, inténtelo de nuevo. Si el error persiste, contacte con el administrador.',
            'danger'
          );
          reject();
        }
      );
    });
  }

  async viewDocumentsFull(documents: DocumentDTO[]): Promise<void> {
    this.loadingService.presentSecondLoader(null, true);
    const modal = await this.modalController.create({
      component: MultipleDocumentModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        documentsData: documents,
        source: 'selectedCandidature'
      }
    });
    return await modal.present();
  }

  async seeLastPayslip(ev: string): Promise<void> {
    const amount = Number(ev);
    const stringAmount = this.numberPipe.transform(amount, '1.0-0');
    const msg: string = this.translateService.instant(
      'pages.profile.home-owner.assets.candidature.last_payroll'
    );
    const msg2: string = this.translateService.instant(
      'pages.profile.home-owner.assets.candidature.money_month',
      { amount: stringAmount }
    );

    const alert = await this.alertController.create({
      message: `<b>${msg}</b><br /><span>${msg2}</span>`,
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  async candidatureRejectedAlert(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translateService.instant(
        'pages.profile.home-owner.assets.candidature.rejected_by_tenant'
      ),
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  hasUnValidatedDocuments(candidature: Candidature): boolean {
    const tenantCandidatures: TenantCandidature[] =
      candidature.tenantCandidatureList;

    for (const tenantCandidature of tenantCandidatures) {
      const documents: DocumentDTO[] = [
        ...(Array.isArray(tenantCandidature.dni) ? tenantCandidature.dni : []),
        ...(Array.isArray(tenantCandidature.payslips)
          ? tenantCandidature.payslips
          : []),
        ...(Array.isArray(tenantCandidature.rent)
          ? tenantCandidature.rent
          : []),
        ...(Array.isArray(tenantCandidature.otherDocument)
          ? tenantCandidature.otherDocument
          : []),
        ...(Array.isArray(tenantCandidature.workLife)
          ? tenantCandidature.workLife
          : []),
        ...(Array.isArray(tenantCandidature.pensionerRecognition)
          ? tenantCandidature.pensionerRecognition
          : []),
        ...(Array.isArray(tenantCandidature.is200Model)
          ? tenantCandidature.is200Model
          : []),
        ...(Array.isArray(tenantCandidature.ivaQuarterModel)
          ? tenantCandidature.ivaQuarterModel
          : []),
        ...(Array.isArray(tenantCandidature.model130)
          ? tenantCandidature.model130
          : []),
        ...(Array.isArray(tenantCandidature.ssCertificate)
          ? tenantCandidature.ssCertificate
          : []),
        ...(Array.isArray(tenantCandidature.aeatCertificate)
          ? tenantCandidature.aeatCertificate
          : []),
        ...(Array.isArray(tenantCandidature.pygQuarter)
          ? tenantCandidature.pygQuarter
          : [])
      ];

      for (const document of documents) {
        if (document && document.validated !== true) {
          return false;
        }
      }
    }

    return true;
  }

  relaunchRequestAnalysis(candidature: Candidature): void {
    this.requestAnalysis(candidature, true);
  }

  showRequestDocumentsBtn(
    tenant: TenantCandidature,
    candidature: Candidature
  ): boolean {
    if (this.user.trialVersion) {
      return false;
    }

    if (
      this.checkTenantDocumentAreReady(tenant, candidature.score) ||
      !!candidature.score
    ) {
      return false;
    } else {
      return true;
    }
  }

  showResendInvitation(
    candidature: Candidature,
    tenant: TenantCandidature
  ): boolean {
    return (
      this.dateFormat(candidature.expirationDate) >
        this.dateFormat(this.today) &&
      tenant.userCandidatureStatusDtoEnum !== 'ACCEPT'
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.every((subs) => subs.unsubscribe());
    this.widthChangeSub?.unsubscribe();
  }

  private async onNewAppointmentDismiss(
    data: AppointmentFormValue,
    candidatureId: string
  ): Promise<void> {
    data.candidatureId = candidatureId;
    await this.loadingService.presentLoading(
      this.translate.instant(
        'components.owner_calendar.create_loading'
      ) as string
    );
    this.userService
      .createAppointment(data)
      .pipe(first())
      .subscribe({
        next: async () => {
          presentToast(this.toastController, 'Cita creada correctamente');
          await this.loadingService.dismissLoading();
        },
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  private setUser(): void {
    this.user = this.authenticationService.user;
  }

  private setAsset(): void {
    this.asset = this.assetService.selectedAsset;
  }

  private setNotify(): void {
    this.notify = this.user.defaultNotifications;
  }

  private areThereRejectTenants(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) =>
        tenant.userCandidatureStatusDtoEnum === CandidatureStatusEnum.REJECT
    );
  }

  private areThereWithoutChekingTenants(candidature: Candidature): boolean {
    if (
      this.areThereRejectTenants(candidature) ||
      candidature.tenantCandidatureList.some(
        (tenant: TenantCandidature) =>
          tenant.userCandidatureStatusDtoEnum ===
          CandidatureStatusEnum.WITHOUT_CHECKING
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  private checkArag(): void {
    this.candidatures.forEach((candidature) => {
      if (candidature.candidatureStatusEnum === CandidatureStatusEnum.SELECT) {
        this.showBackground = false;
        this.presentSelectedCandidatureModal(candidature, true);
      } else if (
        candidature.isCandidatureSelectAndExpired &&
        candidature.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED
      ) {
        this.showBackground = false;
        this.presentSelectedCandidatureModal(candidature, true);
      }
    });
  }

  private orderCandidatures(): void {
    this.candidatures.sort((a, b) => {
      const mapCandidatures = {};
      mapCandidatures[CandidatureStatusEnum.SELECT] = 0;
      mapCandidatures[CandidatureStatusEnum.PRE_SELECT] = 1;
      mapCandidatures[CandidatureStatusEnum.PENDING] = 2;
      mapCandidatures[CandidatureStatusEnum.WITHOUT_CHECKING] = 3;
      mapCandidatures[CandidatureStatusEnum.REJECT] = 4;
      if (
        mapCandidatures[a.candidatureStatusEnum] <
        mapCandidatures[b.candidatureStatusEnum]
      ) {
        return -1;
      }
      if (
        mapCandidatures[a.candidatureStatusEnum] >
        mapCandidatures[b.candidatureStatusEnum]
      ) {
        return 1;
      }
      return 0;
    });
  }

  private checkTenantDocumentAreReady(
    tenant: TenantCandidature,
    score: number
  ): boolean {
    const minDoc = this.prepareMinDocForTenant(tenant, score);
    return this.utilsService.checkMinimumDocForTenantRol(minDoc);
  }

  private prepareMinDocForTenant(
    tenant: TenantCandidature,
    score: number
  ): CheckMinimumDoc {
    if (score && score > 0) {
      tenant.hasPayslip = true;
    }
    return {
      student: tenant?.user?.student || false,
      freelance: tenant?.user?.freelance || false,
      retired: tenant?.user?.retired || false,
      business: tenant?.user?.business || false,
      hasDni: tenant?.hasDni || false,
      hasRent: tenant?.hasRent || false,
      hasModel130: tenant?.hasModel130 || false,
      hasIvaQuarterModel: tenant?.hasIvaQuarterModel || false,
      hasPensionerRecognition: tenant?.hasPensionerRecognition || false,
      hasIs200Model: tenant?.hasIs200Model || false,
      hasPayslips: tenant?.hasPayslip || false,
      hasSSCertificate: tenant?.hasSSCertificate || false,
      hasAEATCertificate: tenant?.hasAEATCertificate || false,
      hasPYGQuarter: tenant?.hasPYGQuarter || false
    };
  }

  private createStripeExtraSession(candidature: Candidature): void {
    this.usersService
      .createStripeExtraSession(
        this.user.id,
        this.user.email,
        this.user.session.id,
        this.usersService.getPriceExtra(this.user.session.planCode),
        this.usersService.createRequestAnalysisSuccessUrl(
          this.user.id,
          candidature
        )
      )
      .subscribe(async (data) => {
        const stripe = await loadStripe(environment.stripe_key);
        // Call Stripe.js method to redirect to the new Checkout page
        stripe
          .redirectToCheckout({
            sessionId: data.id
          })
          .then((result) => this.handleResult(result));
      });
  }

  private handleResult(result: any): void {
    if (result.error) {
      // showErrorMessage(result.error.message);
    }
  }

  private checkAllUserHaveAnalysis(
    candidature: Candidature,
    inProcess: UserAnalysisStatusEnum
  ): boolean {
    return candidature.tenantCandidatureList.every(
      (tenant) => tenant.status === inProcess
    );
  }

  private createNotification(
    candidatureId: string,
    asset: AssetDto,
    email
  ): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.CANDIDATURE;
    notification.candidatureId = candidatureId;
    notification.address = `${asset.street} ${asset.number}, ${asset.town} (${asset.province})`;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    notification.recipientEmail = email;
    if (!!asset.vppModality) {
      notification.vpp = true;
    }
    this.notificationsService.newNotification(notification).subscribe();
  }
}
