import { FormControl } from '@angular/forms';
import { ApiDto, ApiMemberDto, User } from '@core/models';

export interface MultipleApiAssign {
  pipeFunction: MultipleApiAssignFunction;
  isBuildingPage?: boolean;
}

export enum MultipleApiAssignFunction {
  SHOW_BUTTON,
  DISABLE_BUTTON,
  TOOLTIP
}

export interface MultipleApiAssignModalData {
  apis: ApiDto[];
  members: ApiMemberDto[];
  user: User;
}

export interface MultipleApiAssignModalForm {
  api: FormControl<ApiDto>;
  member?: FormControl<ApiMemberDto>;
}

export interface MultipleApiAssignModalResponse {
  api: ApiDto;
  member?: ApiMemberDto;
}
