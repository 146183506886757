import { Component, Input } from '@angular/core';
import { Candidature } from '@app/modules/core/models';

@Component({
  selector: 'el-buen-inquilino-profitability-candidature-information',
  templateUrl: './profitability-candidature-information.component.html'
})
export class ProfitabilityCandidatureInformationComponent {
  @Input() candidature: Candidature;

  constructor() {}
}
