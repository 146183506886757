import { MenuItem } from './menu.model';

export const MENU_CUSTOMER_CARE = [
  new MenuItem(
    'Candidaturas',
    'file-tray-full-outline',
    '/customer-care/customer-care-request-analysis-list'
  ),
  new MenuItem(
    'Gestión propietarios',
    'people-outline',
    '/customer-care/homeowner-management'
  )
];
