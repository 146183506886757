import { ToastController } from '@ionic/angular';

export async function presentToast(
  toastController: ToastController,
  message: string,
  color: 'success' | 'danger' | 'warning' = 'success',
  duration: number = 2500
): Promise<void> {
  const toast = await toastController.create({
    message,
    color,
    duration,
    position: 'top'
  });

  await toast.present();
}
