import { ApiDto, AssetStatus, LabelDto, User } from '@core/models';

export enum AssetAddressReturnType {
  BUILDING = 'BUILDING',
  ASSET = 'ASSET',
  ASSET_BUILDING = 'ASSET_BUILDING',
  SHOW_ASSET_BUILDING_BADGE = 'SHOW_ASSET_BUILDING_BADGE',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  COMPLETE_ADDRESS = 'COMPLETE_ADDRESS'
}

export enum AssetItemFunction {
  SHOW_LAST_UPDATED_BADGE = 'SHOW_LAST_UPDATED_BADGE',
  LAST_UPDATED_TEXT = 'LAST_UPDATED_TEXT',
  LAST_UPDATED_TEXT_FORMATTED = 'LAST_UPDATED_TEXT_FORMATTED',
  CLOSED_TEXT = 'CLOSED_TEXT',
  CLOSED_TEXT_FORMATTED = 'CLOSED_TEXT_FORMATTED',
  SHOW_RC_INSURANCE_CHIP = 'SHOW_RC_INSURANCE_CHIP',
  ASSOCIATED_CANDIDATURES_LENGTH = 'ASSOCIATED_CANDIDATURES_LENGTH',
  SHOW_DOWNLOABLE_REPORTS = 'SHOW_DOWNLOABLE_REPORTS',
  DOWNLOABLE_REPORTS_ICON = 'DOWNLOABLE_REPORTS_ICON',
  SHOW_INCIDENCES = 'SHOW_INCIDENCES',
  SHOW_RENTED_CHIP = 'SHOW_RENTED_CHIP',
  SHOW_NO_ASSET_LABEL_CHIP = 'SHOW_NO_ASSET_LABEL_CHIP',
  SHOW_ASSET_LABEL_CHIP = 'SHOW_ASSET_LABEL_CHIP',
  ASSET_LABEL_CHIP_ICON_STYLE = 'ASSET_LABEL_CHIP_ICON_STYLE',
  ASSET_LABEL_CHIP_NAME = 'ASSET_LABEL_CHIP_NAME',
  API_NAME = 'API_NAME',
  EDITION_PERMISSION = 'EDITION_PERMISSION'
}

export interface AssetItemPipeArgs {
  itemFunction: AssetItemFunction;
  labels?: LabelDto[];
  usersApi?: ApiDto[];
  user?: User;
  openedClosedStatus?: AssetStatus;
  isBuildingPage?: boolean;
}
