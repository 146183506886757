import { Component, Input, OnInit } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { Candidature, Sinister, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { IonSegmentCustomEvent, SegmentChangeEventDetail } from '@ionic/core';
import { first } from 'rxjs';

import { SinistersChildRoutesEnum } from '../../models/shared-sinisters.model';

@Component({
  selector: 'el-buen-inquilino-selected-candidature-sinisters',
  templateUrl: './selected-candidature-sinisters.component.html'
})
export class SelectedCandidatureSinistersComponent implements OnInit {
  @Input({ required: true }) candidature: Candidature;
  @Input({ required: true }) user: User;

  sinisters: Sinister[] = [];
  items: Sinister[] = [];
  childRoutesEnum = SinistersChildRoutesEnum;
  childRoutesValue = SinistersChildRoutesEnum.ACTIVE;

  constructor(
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.getCandidatureSinisters();
  }

  pageChange(ev: IonSegmentCustomEvent<SegmentChangeEventDetail>): void {
    this.childRoutesValue = ev.detail.value as SinistersChildRoutesEnum;
  }

  async getCandidatureSinisters(): Promise<void> {
    await this.loadingService.presentSecondLoader(null, true);
    this.candidatureService
      .getSinistersByCandidatureId(this.candidature.id)
      .pipe(first())
      .subscribe({
        next: (resp: Sinister[]) => this.onSuccessGetCandidatureSinisters(resp),
        error: () => this.onErrorSuccessGetCandidatureSinisters()
      });
  }

  private async onSuccessGetCandidatureSinisters(
    resp: Sinister[]
  ): Promise<void> {
    this.sinisters = resp;

    await this.loadingService.dismissSecondLoader();
  }

  private async onErrorSuccessGetCandidatureSinisters(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
  }
}
