/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { CertificationsApiService } from '@core/api-services/certifications-api/certifications-api.service';
import { ModalController, ToastController } from '@ionic/angular';

import { PayCertificationModalMessages } from '../../models/pay-certification.model';

@Component({
  selector: 'el-buen-inquilino-pay-certification-modal',
  templateUrl: './pay-certification-modal.page.html',
  encapsulation: ViewEncapsulation.None
})
export class PayCertificationModalPage implements OnInit {
  @Input() certificationId: string;
  @Input() paid: boolean;

  messages: PayCertificationModalMessages;

  constructor(
    private modalController: ModalController,
    private certificationsService: CertificationsApiService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.loadMessages();
  }

  dismiss(): void {
    this.modalController.dismiss({
      paid: false,
      certificationUpdated: false
    });
  }

  continue(): void {
    this.certificationsService
      .payCertification(this.certificationId, this.paid)
      .subscribe(
        (data: any) => {
          this.modalController.dismiss({
            paid: data.paid,
            certificationUpdated: true
          });
          if (data.paid) {
            this.presentToast(
              'El estado del pago se ha cambiado con éxito.',
              true
            );
          }
        },
        () => {
          this.modalController.dismiss({
            paid: this.paid,
            certificationUpdated: false
          });
        }
      );
  }

  async presentToast(messages: any, success: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: success ? 'success' : 'danger',
      duration: 5000
    });
    toast.present();
  }

  private loadMessages(): void {
    this.messages = {
      paid: {
        title: 'Confirmación de pago',
        message:
          'Recuerda que este servicio de confirmación del pago solo sirve para marcar como pagados los certificados en EBI y poder descargar el certificado. Este proceso no significa que se haya realizado un pago real en la plataforma de Finaer.',
        buttonMessage: 'Confirmar pago'
      },
      unpaid: {
        title: 'Confirmación de la devolución',
        message:
          'Recuerda que este servicio de confirmación de la devolución solo sirve para marcar como no pagados los certificados en EBI y poder descargar el certificado. Este proceso no significa que se haya realizado una devolución real en la plataforma de Finaer.',
        buttonMessage: 'Devolver pago'
      }
    };
  }
}
