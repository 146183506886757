/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IncidencesApiService } from '@app/modules/core/api-services/incidences-api/incidences-api.service';
import {
  AttachDocument,
  ChatThread,
  DocumentIncidenceDTO,
  Incidence,
  IncidenceStatusEnum
} from '@app/modules/core/models';
import { AuthService } from '@app/modules/core/services/auth/auth.service';
import { incidenceStatusList } from '@app/modules/core/utils/incidences-api.utils';
import { DocumentModalComponent } from '@app/modules/shared/components/document-modal/document.modal.component';
import { presentToast } from '@app/modules/shared/utils/toast.utils';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { first } from 'rxjs';

import { base64ToBlob } from '../../utils/sinister.utils';
import { AddDocumentToIncidenceModalComponent } from '../add-document-to-incidence-modal/add-document-to-incidence-modal.component';

@Component({
  selector: 'el-buen-inquilino-incidence-detail-modal',
  templateUrl: './incidence-detail-modal.component.html'
})
export class IncidenceDetailModalComponent implements OnInit {
  @Input() incidence: Incidence;
  @Input() canChangeStatusOnDetail: boolean;

  form: FormGroup;
  statusList = incidenceStatusList;
  hasBeenUpdated = false;

  get areThereDocuments(): boolean {
    return this.incidence.attachDocuments?.length > 0;
  }

  get statusClass(): string {
    return `status-${this.incidence.status.toLowerCase()}`;
  }

  get reversedChat(): ChatThread[] {
    return this.incidence.chat.sort(
      (a: ChatThread, b: ChatThread) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }

  get reversedAttachDocuments(): AttachDocument[] {
    return this.incidence.attachDocuments.sort(
      (a: AttachDocument, b: AttachDocument) =>
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    );
  }

  get canSubmitComment(): boolean {
    return this.incidence.status !== IncidenceStatusEnum.CANCELLED;
  }

  constructor(
    private modalController: ModalController,
    private incidenceService: IncidencesApiService,
    private authService: AuthService,
    private toastController: ToastController,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  dismiss(): void {
    this.modalController.dismiss(this.hasBeenUpdated);
  }

  getInitialsNameByUsername(): string {
    const names = this.incidence.userName.split(' ');

    return `${names[0][0]}${names[1][0]}`.toUpperCase();
  }

  async presentUploadDocumentsToIncidenceModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: AddDocumentToIncidenceModalComponent,
      cssClass: 'auto-height modal-backdrop',
      componentProps: {
        incidence: this.incidence
      } as unknown as Partial<AddDocumentToIncidenceModalComponent>
    });

    modal.onDidDismiss().then((response: OverlayEventDetail) => {
      if (response.data?.attachDocument) {
        this.incidence.attachDocuments.push(response.data.attachDocument);
        this.cdRef.detectChanges();
      }
    });

    modal.present();
  }

  /* DOCUMENTS */
  loadViewDocumentModal(document: AttachDocument): void {
    this.incidenceService
      .getDocument(this.incidence.id, document.fileId)
      .subscribe({
        next: (response: DocumentIncidenceDTO) =>
          this.openDocumentModal(response),
        error: () => this.showToast('Error al cargar el documento', 'danger')
      });
  }

  deleteDocument(document: AttachDocument): void {
    this.incidenceService
      .deleteAttachDocument(this.incidence.id, document.fileId)
      .subscribe({
        next: () => {
          this.showToast('Documento eliminado con éxito', 'success');

          const i = this.reversedAttachDocuments.findIndex(
            (doc: AttachDocument) => doc.fileId === document.fileId
          );

          this.incidence.attachDocuments.splice(i, 1);
          this.cdRef.detectChanges();
        },
        error: () => this.showToast('Error al eliminar el documento', 'danger')
      });
  }

  statusChange(): void {
    this.incidenceService
      .changeIncidenceStatus(this.incidence)
      .pipe(first())
      .subscribe({
        next: () => {
          this.hasBeenUpdated = true;
          this.showToast('Estado actualizado con éxito', 'success');
        },
        error: () => this.showToast('Error al actualizar el estado', 'danger')
      });
  }

  sendComment(): void {
    const newComment: ChatThread = {
      date: new Date(),
      message: this.form.get('comment').value as string,
      userId: this.authService.user.id
    };

    this.incidenceService
      .addComment(this.incidence.id, newComment)
      .pipe(first())
      .subscribe(() => {
        this.incidence.chat.push(newComment);
        this.form.get('comment').reset();
        this.hasBeenUpdated = true;
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      comment: new FormControl('', Validators.required)
    });
  }

  private showToast(
    message: string,
    color: 'success' | 'danger' | 'warning'
  ): void {
    presentToast(this.toastController, message, color);
  }

  private async openDocumentModal(
    document: DocumentIncidenceDTO
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: DocumentModalComponent,
      cssClass: 'custom-modal-xl modal-backdrop',
      componentProps: {
        pdfSrc: base64ToBlob(document.inputStream, document.extension),
        documentData: document,
        downloadable: true,
        extension: document.extension
      } as Partial<DocumentModalComponent>
    });

    await modal.present();
  }
}
