/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { User } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-error-on-component',
  templateUrl: './error-on-component.component.html'
})
export class ErrorOnComponentComponent implements OnInit {
  user: User;
  @Input() errorTitle: string;
  @Input() paragraphOne: string;
  @Input() paragraphTwo: string;
  @Input() errorType: string;
  @Input() redirection: boolean;

  constructor(
    private authenticationService: AuthService,
    private navigationService: NavigationService,
    private translateSevice: TranslateService
  ) {}

  ngOnInit(): void {
    this.setUser();
    this.setErrorMsg();
  }

  goToHome(): void {
    //TODO: redirection button
    this.navigationService.goHome(this.user);
  }

  private setUser(): void {
    this.user = this.authenticationService.user;
  }

  private setErrorMsg(): void {
    this.errorTitle = this.translateSevice.instant(
      'components.error_on_component.oops'
    );
    this.errorType = this.translateSevice.instant(
      'components.error_on_component.error_type'
    );
  }
}
