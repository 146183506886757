import { AssetStatistic, BuildingDto } from '@core/models';
import { environment } from '@environments/environment';
import { MapAsset, MapStyle } from '@shared-private/models/google-maps.model';

export const MAP_COORDINATES = {
  lat: 40.416667,
  lng: -3.703889
};
export const CANARIAS_COORDINATES = {
  lat: 35.551493,
  lng: -8.476342
};

export const MAP_STYLE: MapStyle[] = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
];

export const MAP_CONFIGURATION = {
  componentRestrictions: { country: 'ES' },
  styles: MAP_STYLE,
  disableDefaultUI: true,
  streetViewControl: false,
  zoomControl: true,
  mapTypeControl: false,
  gestureHandling: 'cooperative'
};

export const MARKER_CLUSTERER_IMAGE_PATH =
  'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

export function getLocations(
  building: BuildingDto,
  assetsStatistics?: AssetStatistic[]
): MapAsset[] {
  let assets = [];

  assets = assetsStatistics?.filter(
    (a: AssetStatistic) => a.buildingId === building.id
  );

  if (assets?.length > 0) {
    return assets.map(
      (a: AssetStatistic) =>
        ({
          lat: a.location.latitude,
          lng: a.location.longitude,
          street: building.street,
          number: building.number,
          postalCode: building.postalCode,
          town: building.town
        }) as MapAsset
    );
  } else {
    return [
      {
        lat: building.location?.latitude,
        lng: building.location?.longitude,
        street: building.street,
        number: building.number,
        postalCode: building.postalCode,
        town: building.town
      } as MapAsset
    ];
  }
}

export function makeStaticMapImageUrl(
  centerLat: number,
  centerLng: number,
  lat: number,
  lng: number,
  zoom: number
): string {
  let url = 'https://maps.googleapis.com/maps/api/staticmap?';

  // Centro del mapa
  url = `${url}center=${centerLat},${centerLng}`;

  // Zoom
  url = `${url}&zoom=${zoom}`;

  // Tamaño
  url = `${url}&size=400x300`;

  // Marcador
  url = `${url}&markers=color:red%7C${lat},${lng}`;

  // Estilos
  MAP_STYLE.forEach((style: MapStyle) => {
    url = `${url}${prepareUriStyleString(style)}`;
  });
  url = `${url}&scale=2`;

  // Api key
  url = `${url}&key=${environment.firebaseConfig.apiKey}`;

  return url;
}

function prepareUriStyleString(style: MapStyle): string {
  let uriString = '&style=';

  const { featureType, elementType, stylers } = style;

  if (!!featureType) {
    uriString = `${uriString}feature:${featureType}|`;
  }

  uriString = `${uriString}element:${elementType}|`;

  stylers.forEach(
    (
      value: {
        [key: string]: unknown;
      },
      index: number
    ) => {
      const key = Object.keys(value)[0];

      uriString = `${uriString}${key}:${(value[key] as string).replace(
        /#/g,
        '0x'
      )}`;

      if (index + 1 < stylers.length) {
        uriString = `${uriString}|`;
      }
    }
  );

  return uriString;
}
