import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CertificationsRoutingModule } from './certifications-routing.module';

const MODULES = [SharedModule, CertificationsRoutingModule];

@NgModule({
  declarations: [],
  imports: [...MODULES]
})
export class CertificationsModule {}
