import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { CustomModalButtonRole } from '@core/models';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { ModalController } from '@ionic/angular';
import {
  InputInputEventDetail,
  IonInputCustomEvent,
  OverlayEventDetail
} from '@ionic/core';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

import { PhoneFlagModalComponent } from '../phone-flag-modal/phone-flag-modal.component';

@Component({
  selector: 'el-buen-inquilino-phone-input',
  templateUrl: './phone-input.component.html'
})
export class PhoneInputComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @Input({ required: true }) country: CountryCode;
  @Input() phoneControlClasses: string[] = [];
  @Input() showEndPhoneIcon = false;
  @Input() label: string;
  @HostBinding('class') className = 'w-100';
  phoneControl: FormControl<string> = new FormControl('');
  options: MaskitoOptions;
  placeholder: string;
  validationErrorMessages = appValidators.validationErrorMessages;

  @Output() changeCountryEvent = new EventEmitter<CountryCode>();

  get flag(): string {
    return this.country.toLowerCase();
  }

  constructor(
    @Optional() @Self() public controlDir: NgControl,
    private phoneMaskService: PhoneMaskService,
    private _changeDetectorRef: ChangeDetectorRef,
    private modalController: ModalController
  ) {
    if (!!controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  readonly predicate: MaskitoElementPredicate = (
    element: HTMLIonInputElement
  ) => element.getInputElement();
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    this.setPhoneMaskOptionsAndPlaceHolder();
  }

  ngAfterViewInit(): void {
    this.phoneControl = this.controlDir.control as FormControl<string>;
    this._changeDetectorRef.detectChanges();
  }

  setPhoneMaskOptionsAndPlaceHolder(): void {
    this.options = this.phoneMaskService.getPhoneMaskOptions(this.country);
    this.placeholder = this.phoneMaskService.getPhonePlaceholder(this.country);

    this.phoneControl = this.controlDir?.control as FormControl<string>;
    this._changeDetectorRef.detectChanges();
  }

  onInput(event: IonInputCustomEvent<InputInputEventDetail>): void {
    const value = event.detail.value;
    this.onTouched();
    this.onChange(value);
  }

  writeValue(value: string): void {
    if (value !== this.phoneControl.value) {
      this.phoneControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneControl.disable();
    } else {
      this.phoneControl.enable();
    }
  }

  async showPhoneFlagModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: PhoneFlagModalComponent,
      showBackdrop: true,
      backdropDismiss: true
    });

    modal.onWillDismiss().then((resp: OverlayEventDetail<CountryCode>) => {
      if (resp.role === (CustomModalButtonRole.ACCEPT as string)) {
        this.changeLang(resp.data);
      }
    });
    modal.present();
  }

  changeLang(value: CountryCode): void {
    this.phoneControl.setValue(null);
    this.country = value;
    this.changeCountryEvent.emit(this.country);
    this._changeDetectorRef.detectChanges();
    this.setPhoneMaskOptionsAndPlaceHolder();
  }
}
