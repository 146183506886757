import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-confirmation-sinister-modal',
  templateUrl: './confirmation-sinister-modal.component.html'
})
export class ConfirmationSinisterModalComponent {
  constructor(private modalController: ModalController) {}

  dismiss(): void {
    this.modalController.dismiss();
  }
}
