/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AssetDto,
  AssetStatistic,
  BuildingDto,
  ContactNewApiDto,
  CreateAssetDto,
  CreateAssetFromBuildingDto,
  LabelDto,
  PageDto,
  PolicyHolderData,
  ReassignApiDto,
  UploadExcelResponse
} from '../../models';

@Injectable()
export class AssetsApiService {
  url: string;
  selectedAsset: AssetDto;

  constructor(private http: HttpClient) {
    this.url = environment.services.assets;
  }

  getAssets(
    userId: string,
    status?: string,
    selectedDate?: string,
    portfolioId?: string,
    apiId?: string
  ): Observable<AssetDto[]> {
    let httpParams = new HttpParams().append('userId', userId);
    if (selectedDate) {
      httpParams = httpParams.append('selectedDate', selectedDate);
    }
    if (portfolioId) {
      httpParams = httpParams.append('portfolioId', portfolioId);
    }
    if (apiId) {
      httpParams = httpParams.append('apiId', apiId);
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    //   return this.http
    //     .get<AssetDto[]>(this.url + '/assets', { params: httpParams })
    //     .pipe(
    //       map((res: AssetDto[]) => {
    //         return res.filter((it: AssetDto) => it.status === status);
    //       })
    //     );
    // } else {
    return this.http
      .get<AssetDto[]>(this.url + '/assets', { params: httpParams })
      .pipe(map((res: AssetDto[]) => res));
    // }
  }

  createAsset(assetsData: CreateAssetDto): Observable<AssetDto> {
    return this.http.post<AssetDto>(this.url + '/assets', assetsData);
  }

  updateAsset(assetsId: string, assetsData: AssetDto): Observable<any> {
    return this.http.put(this.url + `/assets/${assetsId}`, assetsData);
  }

  deleteAsset(assetsId: string): Observable<any> {
    return this.http.delete(this.url + '/assets/' + assetsId);
  }

  getAsset(assetId: string): Observable<AssetDto> {
    return this.http.get<AssetDto>(this.url + `/assets/${assetId}`);
  }

  getAssetReport(assetsId: string): Observable<any> {
    return this.http.get(this.url + `/assets/${assetsId}/report`, {
      responseType: 'blob'
    });
  }

  updateVisitedStatus(assetId: string, visited: boolean): Observable<any> {
    return this.http.patch(
      this.url + `/assets/${assetId}/update-visited-status`,
      { visited }
    );
  }

  getLabels(userId: string): Observable<LabelDto[]> {
    return this.http.get<LabelDto[]>(`${this.url}/labels/${userId}`);
  }

  updateAssetLabel(assetId: string, labelId: number): Observable<any> {
    return this.http.patch(
      this.url + `/assets/${assetId}/updateLabel/${labelId}`,
      null
    );
  }

  updateLabelName(labelId: number, name: string): Observable<any> {
    return this.http.patch(
      this.url + `/labels/updateLabel/${labelId}?labelName=${name}`,
      null
    );
  }

  updateLabels(labels: LabelDto[]): Observable<any> {
    return this.http.put(this.url + `/labels/updateLabels`, labels);
  }

  updateAssetCadastralReferenceAndRentalArea(
    assetId: string,
    cadastralReference: string,
    rentalArea: number
  ): Observable<any> {
    return this.http.patch(
      this.url + `/assets/${assetId}/update-cadastral-reference-rental-area`,
      { cadastralReference, rentalArea }
    );
  }

  createBuilding(building: BuildingDto): Observable<BuildingDto> {
    return this.http.post<BuildingDto>(`${this.url}/building`, building);
  }

  createBuildingsFromExcel(
    portfolioId: string,
    file: File
  ): Observable<UploadExcelResponse> {
    const formData = new FormData();
    formData.append('portfolioId', portfolioId);
    formData.append('file', file);

    return this.http.post<UploadExcelResponse>(
      this.url + '/building/by-excel',
      formData
    );
  }

  getBuildings(
    numElements: number = 20,
    portFolioId?: string,
    apiId?: string
  ): Observable<PageDto<BuildingDto>> {
    const params = {
      numElements
    };

    if (portFolioId) {
      params['portFolioId'] = portFolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    return this.http.get<PageDto<BuildingDto>>(`${this.url}/building`, {
      params
    });
  }

  getBuildingById(buildingId: string): Observable<BuildingDto> {
    return this.http.get<BuildingDto>(`${this.url}/building/${buildingId}`);
  }

  getAssetsByBuildingId(
    buildingId: string,
    status = 'OPEN'
  ): Observable<AssetDto[]> {
    const params = new HttpParams().append('status', status);
    return this.http.get<AssetDto[]>(
      `${this.url}/building/${buildingId}/assets`,
      { params }
    );
  }

  createAssetsFromForm(
    buildingId: string,
    apiId: string,
    apiAdminId: string,
    createAsset: CreateAssetFromBuildingDto
  ): Observable<AssetDto> {
    return this.http.post<AssetDto>(
      this.url +
        `/create-from-building/by-form/${buildingId}/api/${apiId}/admin/${apiAdminId}`,
      createAsset
    );
  }

  reassignHomeowner(assetId: string, apiId: string): Observable<any> {
    return this.http.put(`${this.url}/assets/${assetId}/reassign-homeowner`, {
      newHomeownerId: apiId
    });
  }

  createAssetsFromExcel(
    buildingData: BuildingDto,
    file: File
  ): Observable<UploadExcelResponse> {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(buildingData).forEach((data) => {
      if (data !== 'file') {
        formData.append(`${data}`, buildingData[data]);
      }
    });
    return this.http.post<UploadExcelResponse>(
      this.url + '/create-from-building/by-excel',
      formData
    );
  }

  getAssetsStatistics(
    portFolioId?: string,
    apiId?: string,
    dateFrom?: string,
    dateTo?: string
  ): Observable<AssetStatistic[]> {
    const params = {};

    if (portFolioId) {
      params['portfolioId'] = portFolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    // eslint-disable-next-line eqeqeq
    if (dateFrom != null && dateFrom != '' && dateTo != null && dateTo != '') {
      const from: string = moment(dateFrom, 'DD/MM/YYYY').format('DD/MM/YYYY');
      const to: string = moment(dateTo, 'DD/MM/YYYY')
        .add(1, 'days')
        .format('DD/MM/YYYY');
      params['startDate'] = from;
      params['endDate'] = to;
    }

    return this.http.get<AssetStatistic[]>(
      `${this.url}/assets/general-statistics`,
      { params }
    );
  }

  notifyCCToContactAddNewApi(
    contactNewApiDto: ContactNewApiDto
  ): Observable<any> {
    return this.http.patch(
      this.url + '/building/notifyToContactAddNewApi',
      contactNewApiDto
    );
  }

  updateBuilding(building: BuildingDto): Observable<BuildingDto> {
    return this.http.put<BuildingDto>(
      `${this.url}/building/${building.id}`,
      building
    );
  }

  reassignApi(
    assetId: string,
    adminId: string,
    apiId: string
  ): Observable<any> {
    return this.http.put<any>(`${this.url}/assets/${assetId}/reassign-api`, {
      adminId,
      apiId
    });
  }

  updateFinancialDataBuilding(
    buildingId: string,
    data: PolicyHolderData
  ): Observable<void> {
    return this.http.post<void>(
      `${this.url}/building/${buildingId}/addPolicyHolderData`,
      data
    );
  }

  updateCadastralReference(
    buildingId: string,
    cadastralReference: string
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.url}/building/${buildingId}/update-cadastral-reference/${cadastralReference}`,
      null
    );
  }

  reassignMassiveAssets(
    buildingId: string,
    data: ReassignApiDto
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.url}/building/${buildingId}/reassign-massive-assets`,
      data
    );
  }

  getPricesExcelTemplate(buildingId: string): Observable<Blob> {
    return this.http.get(
      `${this.url}/building/${buildingId}/download-prices-excel`,
      {
        responseType: 'blob'
      }
    );
  }

  updateMassivePrices(buildingId: string, file: File): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file);
    const endpoint = `${this.url}/building/${buildingId}/update-prices-by-excel`;
    return this.http.post(endpoint, formData);
  }
}
