import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  ErrorResponse,
  OccupationEnum,
  TypeUserEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  ModalController,
  RadioGroupCustomEvent,
  SelectCustomEvent,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { sub } from 'date-fns';
import { CountryCode } from 'libphonenumber-js/types.d';
import { first } from 'rxjs';

import { MoreRegisterInfoModalForm } from '../../models/more-register-info-modal.model';
import { presentToast } from '../../utils/toast.utils';

@Component({
  selector: 'el-buen-inquilino-more-register-info-modal',
  templateUrl: './more-register-info-modal.component.html'
})
export class MoreRegisterInfoModalComponent implements OnInit {
  @Input({ required: true }) user: User;
  form: FormGroup<MoreRegisterInfoModalForm>;
  country: CountryCode = 'ES';
  documentOption = 1;
  dniPlaceholder: string;
  validationErrorMessages = appValidators.validationErrorMessages;
  retryDni = false;
  roles = TypeUserEnum;
  occupationEnum = OccupationEnum;
  maxBirthDate: Date;

  get roleControl(): FormControl<TypeUserEnum> {
    return this.form.controls.role;
  }
  get firstnameControl(): FormControl<string> {
    return this.form.controls.firstname;
  }
  get surname1Control(): FormControl<string> {
    return this.form.controls.surname1;
  }
  get surname2Control(): FormControl<string> {
    return this.form.controls.surname2;
  }
  get emailControl(): FormControl<string> {
    return this.form.controls.email;
  }
  get dniControl(): FormControl<string> {
    return this.form.controls.dni;
  }
  get phoneControl(): FormControl<string> {
    return this.form.controls.phone;
  }
  get birthDateControl(): FormControl<Date> {
    return this.form.controls.birthDate;
  }
  get birthPlaceControl(): FormControl<string> {
    return this.form.controls.birthPlace;
  }
  get occupationControl(): FormControl<OccupationEnum> {
    return this.form.controls.occupation;
  }
  get businessNameControl(): FormControl<string> {
    return this.form.controls.businessName;
  }
  get cifControl(): FormControl<string> {
    return this.form.controls.cif;
  }
  get taxResidenceControl(): FormControl<string> {
    return this.form.controls.taxResidence;
  }
  get passwordControl(): FormControl<string> {
    if (this.showPasswordTempl) {
      return this.form.controls.password;
    } else {
      return null;
    }
  }
  get confirmPasswordControl(): FormControl<string> {
    if (this.showPasswordTempl) {
      return this.form.controls.confirmPassword;
    } else {
      return null;
    }
  }

  get isTenant(): boolean {
    if (this.roleControl.value === TypeUserEnum.TENANT) {
      return true;
    } else {
      return false;
    }
  }

  get isBusiness(): boolean {
    if (this.occupationControl.value === OccupationEnum.BUSINESS) {
      return true;
    } else {
      return false;
    }
  }

  get showPasswordTempl(): boolean {
    return !this.user?.email || this.user?.registerWithGoogle;
  }

  get showForeignIncomeControl(): boolean {
    return this.user.userType === TypeUserEnum.TENANT;
  }

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private userService: UsersApiService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.setMaxBirthDate();
    this.setForm();
    this.documentChange(null);
  }
  setMaxBirthDate(): void {
    this.maxBirthDate = sub(new Date(), { years: 18 });
  }

  dismiss(): void {
    this.modalController.dismiss({});
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  documentChange(event: SelectCustomEvent): void {
    const value = (event?.detail?.value as number) || this.documentOption;
    this.documentOption = value;
    if (Number(value) === 2) {
      this.dniPlaceholder = this.translate.instant(
        'pages.login.data.signin.dni-placeholder'
      ) as string;
      this.dniControl.removeValidators(appValidators.passportValidator);
      this.dniControl.addValidators(appValidators.dniAndNieValidator);
    } else if(Number(value) === 3) {
      this.dniPlaceholder = this.translate.instant(
        'pages.login.data.signin.email_signin_component.passport'
      ) as string;
      this.dniControl.removeValidators(appValidators.dniAndNieValidator);
      this.dniControl.addValidators(appValidators.passportValidator);
    }else{
      this.dniControl.setValue('')
      this.dniPlaceholder = this.translate.instant(
        'pages.login.data.signin.email_signin_component.select'
      ) as string;
    }
    this.dniControl.updateValueAndValidity();
  }

  roleChange(ev: RadioGroupCustomEvent<TypeUserEnum>): void {
    if (ev.detail.value === TypeUserEnum.HOMEOWNER) {
      this.changeToTypeHomeowner();
    }
  }

  selectChanged(event: SelectCustomEvent): void {
    if (event.detail.value === this.occupationEnum.BUSINESS) {
      this.validationsForBusinessRol(true);
    } else {
      this.validationsForBusinessRol(false);
    }
  }

  validationsForBusinessRol(businessIsActive: boolean): void {
    this.businessNameControl.setValidators(
      businessIsActive
        ? Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
            appValidators.complexNameValidator
          ])
        : null
    );
    this.businessNameControl.updateValueAndValidity();
    this.cifControl.setValidators(
      businessIsActive
        ? Validators.compose([Validators.required, appValidators.cifValidator])
        : null
    );
    this.cifControl.updateValueAndValidity();
    this.taxResidenceControl.setValidators(
      businessIsActive
        ? Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
            appValidators.addressValidator
          ])
        : null
    );
    this.taxResidenceControl.updateValueAndValidity();

    if (!businessIsActive) {
      this.form.patchValue({
        businessName: '',
        cif: '',
        taxResidence: ''
      });
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const data = this.form.getRawValue();

    if (this.documentOption === 3) {
      this.retryDni = true;
    }

    Object.keys(data).forEach((key: string) => {
      if (typeof data[key] === 'string') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        data[key] = data[key].trim();
      }
    });

    await this.loadingService.presentSecondLoader(null);

    this.userService
      .updateBasicInfoHomeowner(
        this.user.id,
        data.email.toLowerCase(),
        data.firstname,
        data.surname1,
        data.surname2,
        !!data.password
          ? this.authService.encryptPassword(data.password)
          : null,
        data.dni,
        data.occupation,
        data.businessName,
        data.cif,
        data.taxResidence,
        this.utilsService.formatPhone(data.phone),
        data.birthDate,
        data.birthPlace,
        data.role,
        this.showForeignIncomeControl ? data.foreignIncome : false,
        this.retryDni
      )
      .pipe(first())
      .subscribe({
        next: (resp: User) => this.onSuccessUpdateUser(resp),
        error: (error: HttpErrorResponse) =>
          this.onErrorUpdateUser(error.error as ErrorResponse)
      });
  }

  async onSuccessUpdateUser(user: User): Promise<void> {
    await this.loadingService.dismissLoading();
    this.user = user;
    this.authService.user = user;
    presentToast(
      this.toastController,
      this.translate.instant('pages.profile.tenant.savedOK') as string,
      'success',
      5000
    );

    this.dismiss();
  }

  async onErrorUpdateUser(error: ErrorResponse): Promise<void> {
    await this.loadingService.dismissLoading();
    switch (error.code) {
      case '100':
        presentToast(
          this.toastController,
          this.translate.instant(
            'pages.profile.home-owner.invalidDniWarn'
          ) as string,
          'warning',
          8000
        );
        this.retryDni = true;
        break;
      case '108':
        presentToast(
          this.toastController,
          this.translate.instant('pages.login.error.email-exist') as string,
          'danger',
          5000
        );
        break;
      case '218':
        presentToast(
          this.toastController,
          this.translate.instant('pages.login.error.phone-exist') as string,
          'danger',
          5000
        );
        break;
    }
  }

  private changeToTypeHomeowner(): void {
    if (this.occupationControl.value === OccupationEnum.BUSINESS) {
      this.validationsForBusinessRol(false);
    }
    this.occupationControl.setValue(OccupationEnum.WORKER);
  }

  private setForm(): void {
    this.form = new FormGroup<MoreRegisterInfoModalForm>({
      role: this.setRoleControl(),
      firstname: this.setFirstnameControl(),
      surname1: this.setSurname1Control(),
      surname2: this.setSurname2Control(),
      email: this.setEmailControl(),
      dni: this.setDniControl(),
      phone: this.setPhoneControl(),
      birthDate: this.setBirthDateControl(),
      birthPlace: this.setBirthPlaceControl(),
      occupation: this.setOccupationControl(),
      businessName: this.setBusinessNameControl(),
      cif: this.setCifControl(),
      taxResidence: this.setTaxResidenceControl()
    });

    if (this.showForeignIncomeControl) {
      this.form.addControl('foreignIncome', this.setForeignIncomeControl());
    }

    if (this.showPasswordTempl) {
      this.form.addControl('password', this.setPasswordControl());
      this.form.addControl('confirmPassword', this.setConfirmPasswordControl());
      this.form.addValidators(appValidators.passwordMatchingValidator);
    }
  }

  private setRoleControl(): FormControl<TypeUserEnum> {
    return new FormControl<TypeUserEnum>(this.user.userType ?? null, [
      Validators.required
    ]);
  }

  private setFirstnameControl(): FormControl<string> {
    return new FormControl<string>(this.user.firstname ?? null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
      appValidators.namesValidator
    ]);
  }
  private setSurname1Control(): FormControl<string> {
    return new FormControl<string>(this.user.surname1 ?? null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(40),
      appValidators.namesValidator
    ]);
  }
  private setSurname2Control(): FormControl<string> {
    return new FormControl<string>(this.user.surname2 ?? null, []);
  }
  private setEmailControl(): FormControl<string> {
    const value = this.user.email ?? null;
    return new FormControl<string>({ value, disabled: value !== null }, [
      Validators.required,
      Validators.email,
      appValidators.emailValidator
    ]);
  }
  private setDniControl(): FormControl<string> {
    if(this.user.dni) this.documentOption = 2
    return new FormControl<string>(this.user.dni ?? null, [
      Validators.required,
      appValidators.dniAndNieValidator
    ]);
  }
  private setPhoneControl(): FormControl<string> {
    let value = '';

    if (!!this.user.phone) {
      value = this.phoneMaskService.formatPhone(this.user.phone, this.country);
    }

    return new FormControl<string>(value, this.getPhoneControlValidators());
  }
  private getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }
  private setBirthDateControl(): FormControl<Date> {
    return new FormControl<Date>(null, [Validators.required]);
  }
  private setBirthPlaceControl(): FormControl<string> {
    return new FormControl<string>(null, [Validators.required]);
  }
  private setOccupationControl(): FormControl<OccupationEnum> {
    return new FormControl<OccupationEnum>(OccupationEnum.WORKER);
  }
  private setBusinessNameControl(): FormControl<string> {
    return new FormControl<string>(null);
  }
  private setCifControl(): FormControl<string> {
    return new FormControl<string>(null);
  }
  private setTaxResidenceControl(): FormControl<string> {
    return new FormControl<string>(null);
  }
  private setForeignIncomeControl(): FormControl<boolean> {
    return new FormControl<boolean>(false, Validators.required);
  }
  private setPasswordControl(): FormControl<string> {
    return new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(55)
    ]);
  }
  private setConfirmPasswordControl(): FormControl<string> {
    return new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(55)
    ]);
  }
}
