/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { CertificationsApiService } from '@app/modules/core/api-services/certifications-api/certifications-api.service';
import { LoadingService } from '@app/modules/core/services/loading/loading.service';
import {
  BuildingDto,
} from '@core/models';
import {
  AlertController,
  ModalController,
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonsRole } from '@shared/models/button.model';

@Component({
  selector: 'el-buen-inquilino-modal-money-laundring-alert-component',
  templateUrl: './modal-money-laundering-alert.component.html'
})
export class ModalMoneyLaunderingAlertComponent implements OnInit {
  @Input() buildingData: BuildingDto;

  alertForm: FormGroup;
  errorRequest = false;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private alertController: AlertController,
    private formBuilder: FormBuilder,
    private certificationService: CertificationsApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void  {
    this.initForm()
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  onSubmitAlert(): void {
    if (this.alertForm.valid) {
      if(!this.alertForm.value.alertToggle)
        this.deactiveAlert()
      else 
        this.updateAlertAML()
    } 
  }

  private initForm() : void {
    this.alertForm = this.formBuilder.group({
      alertToggle: [this.buildingData.amlAlert ? this.buildingData.amlAlert : false], 
      email: [this.buildingData.amlAlertNotifyEmail ? this.buildingData.amlAlertNotifyEmail : '',
        [Validators.email]] 
    });

    this.alertForm.get('alertToggle')?.valueChanges.subscribe((isAlertEnabled) => {
      const emailControl = this.alertForm.get('email');

      if (isAlertEnabled) {
        emailControl?.setValidators([Validators.required, Validators.email]); 
      } else {
        emailControl?.clearValidators(); 
      }
      emailControl?.updateValueAndValidity(); 
    });

  }

  private async deactiveAlert(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translate.instant(
        'components.modal_money_laundering_alert.deactive_alert_msg'
      ) as string,
      backdropDismiss: false,
      cssClass: 'reject-candidature-alert',
      buttons: [
        {
          text: this.translate.instant('common.cancel') as string,
          role: ButtonsRole.CANCEL,
          cssClass: 'reject-candidature-alert-cancel'
        },
        {
          text: this.translate.instant('common.accept') as string,
          role: ButtonsRole.ACCEPT,
          cssClass: 'reject-candidature-alert-reject'
        }
      ]
    });

    alert.onWillDismiss().then((resp: OverlayEventDetail) => {
      const { role } = resp;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (role === ButtonsRole.ACCEPT) {
        this.updateAlertAML()
      }
    });

    alert.present();
  }

  private updateAlertAML(): void {
    this.loadingService.presentLoading(null)
    const params = {
      activate: this.alertForm.get('alertToggle').value,
      notifyEmail: this.alertForm.get('alertToggle').value 
        ? this.alertForm.get('email').value : null
    };
    this.certificationService.updateBuildingAmlAlert(this.buildingData.id, params).subscribe({
      next: () => {
        this.loadingService.dismissLoading()
        this.dismiss(true)
      },
      error: () => {
        this.loadingService.dismissLoading()
        this.errorRequest = true
      }
    })
  }
}
