import { Component, Input } from '@angular/core';
import { CustomModalButtonRole } from '@core/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
  @Input({ required: true }) msg: string;
  role = CustomModalButtonRole;

  constructor(private modalController: ModalController) {}

  dismiss(role: CustomModalButtonRole): void {
    this.modalController.dismiss(null, role);
  }
}
