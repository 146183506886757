export interface AssetUtilsPopoverResponse {
  options: AssetUtilsPopoverOption;
}

export enum AssetUtilsPopoverOption {
  VIEW_ASSET = 'VIEW_ASSET',
  EDIT_ASSET = 'EDIT_ASSET',
  DELETE_ASSET = 'DELETE_ASSET',
  DOWNLOAD_ASSET = 'DOWNLOAD_ASSET',
  APPOINTMENT = 'APPOINTMENT'
}
