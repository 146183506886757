import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import {
  AssetDto,
  CalendarAppointment,
  Candidature,
  CustomModalButtonRole,
  CustomPopoverButtonRole,
  User
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { AssetAddressReturnType } from '@shared/models/asset-pipes.model';
import {
  AppointmentForm,
  AppointmentFormValue
} from '@shared/models/calendar.model';
import { format, isBefore, setHours, setMinutes } from 'date-fns';
import { Subscription, first } from 'rxjs';

import { LinkAssetComponent } from '../link-asset/link-asset.component';
import { LinkTenantsComponent } from '../link-tenants/link-tenants.component';
import { TenantListPopoverComponent } from '../tenant-list-popover/tenant-list-popover.component';
import { TimePopoverComponent } from '../time-popover/time-popover.component';

@Component({
  selector: 'el-buen-inquilino-appointment-form',
  templateUrl: './appointment-form.component.html'
})
export class AppointmentFormComponent implements OnInit {
  @Input() private appointment: CalendarAppointment;
  @Input() private isEdit = false;
  @Input() private isSameUser = false;
  @Input({ required: true }) private user: User;
  @Input({ required: true }) showLinkTemplate = true;

  form: FormGroup<AppointmentForm>;
  min = new Date();
  selectedAsset: AssetDto;
  selectedCandidature: Candidature;
  addressPipeFunctions = AssetAddressReturnType;

  formChangeSub: Subscription;
  dateControlSub: Subscription;

  @Output() formValueEmit = new EventEmitter<AppointmentFormValue>();
  @Output() formValueChangeEmit = new EventEmitter<void>();

  get dateControl(): FormControl<Date> {
    return this.form?.controls?.date;
  }

  get timeControl(): FormControl<string> {
    return this.form?.controls?.time;
  }

  set timeControl(value: string) {
    this.form?.controls?.time.setValue(value);
  }

  get titleControl(): FormControl<string> {
    return this.form?.controls?.title;
  }

  get descriptionControl(): FormControl<string> {
    return this.form?.controls?.description;
  }

  get borderError(): string | null {
    if (
      this.dateControl?.errors &&
      this.dateControl?.touched &&
      this.dateControl?.dirty
    ) {
      return 'border-error';
    }
    return null;
  }

  get completedDate(): Date | null {
    if (!!this.dateControl?.value && !!this.timeControl?.value) {
      let date = this.dateControl?.value;
      const timeArray = this.timeControl.value.split(':');
      date = setHours(date, Number(timeArray[0]));
      date = setMinutes(date, Number(timeArray[1]));
      return date;
    }
    return null;
  }

  get invalidTimeError(): ValidationErrors {
    return { invalidTime: true };
  }

  get linkTitle(): string {
    if (!!this.selectedAsset) {
      return 'Inmueble asociado';
    }
    if (!!this.selectedCandidature) {
      return 'Candidatura asociada';
    }

    return 'Asociar cita';
  }

  constructor(
    private utilsService: UtilsService,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private assetService: AssetsApiService,
    private loadingService: LoadingService,
    private candidatureService: CandidaturesApiService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.isAppointmentLinked();
  }

  emitFormValue(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const { title, description } = this.form.value;
    const value: AppointmentFormValue = {
      title,
      description,
      date: this.completedDate
    };

    if (!!this.selectedAsset) {
      value.assetId = this.selectedAsset.id;
    } else {
      value.assetId = null;
    }

    if (!!this.selectedCandidature) {
      value.candidatureId = this.selectedCandidature.id;
    } else {
      value.candidatureId = null;
    }

    this.formValueEmit.emit(value);
  }

  async openTimePopover(event: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: TimePopoverComponent,
      event,
      componentProps: {
        formDate: this.completedDate,
        minHour: 8,
        maxHour: 21
      }
    });

    await popover.present();

    const { data, role }: OverlayEventDetail<string> =
      await popover.onWillDismiss();
    if (!!role && role === (CustomPopoverButtonRole.ACCEPT as string)) {
      this.timeControl = data;
    }
  }

  async linkAsset(): Promise<void> {
    const modal = await this.modalController.create({
      component: LinkAssetComponent,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        user: this.user,
        selectedAsset: this.selectedAsset
      } as Partial<LinkAssetComponent>
    });

    modal.onWillDismiss().then((resp: OverlayEventDetail<AssetDto>) => {
      if (
        (resp.role as CustomModalButtonRole) === CustomModalButtonRole.ACCEPT
      ) {
        this.setAsset(resp.data);
      }
    });
    modal.present();
  }

  removeAsset(): void {
    this.selectedAsset = null;
    this.formValueChangeEmit.emit();
  }

  removeCandidature(): void {
    this.selectedCandidature = null;
    this.formValueChangeEmit.emit();
  }

  async showTenantListPopover(
    event: Event,
    candidature: Candidature
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: TenantListPopoverComponent,
      componentProps: {
        tenantsList: candidature.tenantCandidatureList,
        isTenantsList: true
      } as Partial<TenantListPopoverComponent>,
      event
    });
    popover.present();
  }

  async linkTenants(): Promise<void> {
    const modal = await this.modalController.create({
      component: LinkTenantsComponent,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        selectedCandidature: this.selectedCandidature
      } as Partial<LinkTenantsComponent>
    });

    modal.onWillDismiss().then((resp: OverlayEventDetail<Candidature>) => {
      if (
        (resp.role as CustomModalButtonRole) === CustomModalButtonRole.ACCEPT
      ) {
        this.setCandidature(resp.data);
      }
    });
    modal.present();
  }

  private setForm(): void {
    let date: Date = null;
    let time: string = null;
    if (!!this.appointment) {
      date = new Date(this.appointment.date);
      time = format(new Date(this.appointment.date), 'HH:mm');
    }
    this.form = new FormGroup<AppointmentForm>({
      date: this.getDateControl(date),
      time: this.getTimeControl(time),
      title: this.getTitleControl(this.appointment?.title ?? null),
      description: this.getDescriptionControl(
        this.appointment?.description ?? null
      )
    });

    if (this.isEdit && this.isSameUser) {
      this.subscribeToFormChange();
    }

    this.subscribeToDateControlChange();
  }

  private subscribeToFormChange(): void {
    this.formChangeSub = this.form.valueChanges.subscribe(() => {
      this.formValueChangeEmit.emit();
    });
  }

  private subscribeToDateControlChange(): void {
    this.dateControlSub = this.dateControl.valueChanges.subscribe(() =>
      this.checkTimeFromDateControlChange()
    );
  }

  private getDateControl(value: Date): FormControl<Date> {
    return new FormControl<Date>(
      { value, disabled: this.getDisabledControl() },
      Validators.required
    );
  }

  private getTimeControl(value: string): FormControl<string> {
    return new FormControl<string>(
      { value, disabled: this.getDisabledControl() },
      [Validators.required, this.checkTime.bind(this)]
    );
  }

  private getTitleControl(value: string): FormControl<string> {
    return new FormControl<string>(
      { value, disabled: this.getDisabledControl() },
      Validators.required
    );
  }

  private getDescriptionControl(value: string): FormControl<string> {
    return new FormControl<string>(
      { value, disabled: this.getDisabledControl() },
      Validators.required
    );
  }

  private getDisabledControl(): boolean {
    if (this.isEdit && !this.isSameUser) {
      return true;
    }
    return false;
  }

  private checkTime(): ValidationErrors {
    if (this.isInvalidTime()) {
      return this.invalidTimeError;
    }
    return null;
  }

  private isInvalidTime(): boolean {
    return isBefore(this.completedDate, new Date());
  }

  /**
   * Controla que la hora de la fecha sea superior a la que es actualmente
   */
  private checkTimeFromDateControlChange(): void {
    if (this.isInvalidTime()) {
      const errors = this.timeControl.errors || [];
      errors['invalidTime'] = true;
      this.timeControl.setErrors(errors);
    } else if (this.timeControl.hasError('invalidTime')) {
      const errors = this.timeControl.errors;
      delete errors['invalidTime'];
      this.timeControl.setErrors(
        Object.keys(errors).length > 0 ? errors : null
      );
    }
  }

  private setAsset(data: AssetDto, fromModal: boolean = true): void {
    this.selectedAsset = data;
    if (fromModal) {
      this.formValueChangeEmit.emit();
    }
  }

  private setCandidature(data: Candidature, fromModal: boolean = true): void {
    this.selectedCandidature = data;
    if (fromModal) {
      this.formValueChangeEmit.emit();
    }
  }

  private isAppointmentLinked(): void {
    if (this.isEdit) {
      if (!!this.appointment.assetId) {
        this.getAsset(this.appointment.assetId);
      }
      if (!!this.appointment.candidatureId) {
        this.getCandidature(this.appointment.candidatureId);
      }
    }
  }

  private async getAsset(assetId: string): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.assetService
      .getAsset(assetId)
      .pipe(first())
      .subscribe({
        next: (asset: AssetDto) => this.onSuccessGetAsset(asset),
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  private async onSuccessGetAsset(asset: AssetDto): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.setAsset(asset, false);
  }

  private async getCandidature(candidatureId: string): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .getCandidaturesById(candidatureId)
      .pipe(first())
      .subscribe({
        next: (candidature: Candidature) =>
          this.onSuccessGetCandidature(candidature),
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  private async onSuccessGetCandidature(
    candidature: Candidature
  ): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.setCandidature(candidature, false);
  }
}
