import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { PrivateRoutingModule } from './private-routing.module';

const MODULES = [SharedModule, PrivateRoutingModule];

@NgModule({
  declarations: [],
  imports: [...MODULES]
})
export class PrivateModule {}
