/* eslint-disable max-len */
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BuildingDto,
  BuildingStatus,
  KeyValuePair,
  ModalBuildingReportData
} from '@core/models';
import { BuildingPdfService } from '@core/services/building-pdf/building-pdf.service';
import { GoogleMapsService } from '@core/services/google-maps/google-maps.service';
import {
  ACTIVE_RENTS_DATA,
  ACTIVE_RENTS_OPTIONS,
  CANDIDATURES_WITH_GUARANTOR_DATA,
  CANDIDATURES_WITH_GUARANTOR_OPTIONS,
  SCORING_OPTIONS,
  TENANTS_CANDIDATURE_OPTIONS
} from '@core/utils/chart.utils';
import {
  TENANTS_CANDIDATURE_DATA,
  SCORING_DATA
} from '@core/utils/chart.utils';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { getLocations } from '../../utils/ebi-google-maps.utils';
import {
  elementRefToImage,
  googleMapsStaticImgToBase64
} from '../../utils/pdfmake.utils';

const WITH_GUARANTOR_KEY = 'withGuarantor';

@Component({
  selector: 'el-buen-inquilino-modal-building-report',
  templateUrl: './modal-building-report.component.html'
})
export class ModalBuildingReportComponent implements OnInit {
  readonly activeRentOptions = ACTIVE_RENTS_OPTIONS;
  readonly candidaturesWithGuarantorOptions =
    CANDIDATURES_WITH_GUARANTOR_OPTIONS;
  readonly tenantsCandidatureOptions = TENANTS_CANDIDATURE_OPTIONS;
  readonly scoringOptions = SCORING_OPTIONS;
  activeRentData;
  candidaturesWithGuarantorData;
  tenantsCandidatureData;
  scoringData;

  imgUrl1: string;
  imgUrl2: string;
  @ViewChild('map1')
  canvasMap1: ElementRef<HTMLDivElement>;
  @ViewChild('map2')
  canvasMap2: ElementRef<HTMLDivElement>;
  @ViewChild('occupationLevel')
  occupationLevelElRef: ElementRef<HTMLDivElement>;
  @ViewChild('pdfTenantAnalysis')
  pdfTenantAnalysisElRef: ElementRef<HTMLDivElement>;
  @ViewChild('pdfRiskAnalysis')
  pdfRiskAnalysisElRef: ElementRef<HTMLDivElement>;
  @ViewChild('countryImg')
  countryImgElRef: ElementRef<HTMLImageElement>;
  @ViewChild('cityImg')
  cityImgElRef: ElementRef<HTMLImageElement>;

  mapImage: string;
  showLoadingBtn = false;

  get chartsContainerMaxHeight(): number {
    let height = 1300;
    if (!this.hasOccupation) {
      height -= 305;
    }
    if (!this.hasReports) {
      height -= 305;
    }
    return height;
  }

  get occupationPercentage(): string {
    return `width:${this.buildingStatus?.generalInfo.occupationPercentage}%`;
  }

  get hasOccupation(): boolean {
    const { occupation } = this.buildingStatus;

    return !!occupation?.total;
  }

  get hasReports(): boolean {
    const { reports } = this.buildingStatus;

    return !!reports?.total;
  }

  get activatedRents(): number {
    const { activatedRents, totalRents } = this.buildingStatus?.generalInfo;
    const percentage = Math.round((activatedRents / totalRents) * 100);

    return percentage;
  }

  get withGuarantorPercentage(): number | string {
    const { occupation } = this.buildingStatus;
    const withGuarantor = occupation.values.find(
      (value: KeyValuePair) => value.key === WITH_GUARANTOR_KEY
    );

    return !!withGuarantor?.value
      ? ((withGuarantor.value / occupation.total) * 100).toFixed(1)
      : 0;
  }

  get insuredIncome(): number {
    const { activatedRents, totalInsuredRents } =
      this.buildingStatus?.generalInfo;

    return !!totalInsuredRents && !!activatedRents
      ? Math.round((totalInsuredRents / activatedRents) * 100)
      : 0;
  }

  get insuredIncomePercentage(): string {
    return `width:${this.insuredIncome}%`;
  }

  get buildingStatus(): BuildingStatus {
    return this.data?.buildingStatus || null;
  }

  get building(): BuildingDto {
    return this.data?.building || null;
  }

  get pdfButtonText(): string {
    if (this.showLoadingBtn) {
      return 'Generando PDF...';
    } else {
      return 'Generar PDF';
    }
  }

  get address(): string {
    return `${this.building.street} ${this.building.number}`;
  }

  constructor(
    public dialogRef: MatDialogRef<ModalBuildingReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalBuildingReportData,
    private buildingPdfService: BuildingPdfService,
    private googleMapsService: GoogleMapsService,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.setChartsData();
    this.markMaxTenantsCandidature();
    // this.prepareMapItem();
  }

  setChartsData(): void {
    this.setActiveRentData();
    this.setCandidaturesWithGuarantorData();
    this.setTenantsCandidatureData();
    this.setScoringData();
  }

  setActiveRentData(): void {
    this.activeRentData = {
      labels: ACTIVE_RENTS_DATA.labels,
      datasets: [
        {
          data: this.buildActiveRentData(),
          ...ACTIVE_RENTS_DATA.datasets
        }
      ]
    };
  }

  setCandidaturesWithGuarantorData(): void {
    this.candidaturesWithGuarantorData = {
      labels: CANDIDATURES_WITH_GUARANTOR_DATA.labels,
      datasets: [
        {
          data: this.candidatureWithGuarantorData(),
          ...CANDIDATURES_WITH_GUARANTOR_DATA.datasets
        }
      ]
    };
  }

  setTenantsCandidatureData(): void {
    this.tenantsCandidatureData = {
      labels: this.buildTenantCandidatureLabels(),
      datasets: [
        {
          data: this.buildTenantCandidatureData(),
          ...TENANTS_CANDIDATURE_DATA.datasets
        }
      ]
    };
  }

  setScoringData(): void {
    this.scoringData = {
      labels: this.buildScoringLabels(),
      datasets: [
        {
          data: this.buildScoringData(),
          ...SCORING_DATA.datasets
        }
      ]
    };
  }

  async prepareMapItem(): Promise<void> {
    const item = getLocations(this.building)[0];

    this.imgUrl1 = await this.googleMapsService.getStaticMapImage(
      true,
      item.lat,
      item.lng
    );
    this.imgUrl2 = await this.googleMapsService.getStaticMapImage(
      false,
      item.lat,
      item.lng
    );
  }

  onClickClose(): void {
    if (this.showLoadingBtn) {
      this.presentToast(
        this.translateService.instant(
          'components.modal_building_report.closing_warning_msg'
        ) as string,
        true
      );
      return;
    }
    this.dialogRef.close();
  }

  async presentToast(messages: string, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'danger' : 'success',
      duration: 2000
    });
    toast.present();
  }

  async generatePdf(): Promise<void> {
    this.showLoadingBtn = true;
    const occupationLevel = await elementRefToImage(this.occupationLevelElRef);
    const pdfTenantAnalysis = await elementRefToImage(
      this.pdfTenantAnalysisElRef
    );
    const pdfRiskAnalysis = await elementRefToImage(this.pdfRiskAnalysisElRef);
    let countryImg: string, cityImg: string;
    try {
      countryImg = await googleMapsStaticImgToBase64(this.countryImgElRef);
      cityImg = await googleMapsStaticImgToBase64(this.cityImgElRef);
    } catch (error) {
      console.error('ERROR IMAGENES ESTATICAS', error);
    }

    this.showLoadingBtn = false;

    this.buildingPdfService.getReport(
      this.address,
      occupationLevel,
      pdfTenantAnalysis,
      pdfRiskAnalysis,
      countryImg,
      cityImg
    );
  }

  private buildActiveRentData(): number[] {
    const { activatedRents, totalRents } = this.buildingStatus?.generalInfo;

    const percentage = Math.round((activatedRents / totalRents) * 100);
    const rest = 100 - percentage;

    return [percentage, rest];
  }

  private candidatureWithGuarantorData(): number[] {
    const { occupation } = this.buildingStatus;
    const withGuarantor = occupation.values.find(
      (value: KeyValuePair) => value.key === WITH_GUARANTOR_KEY
    );

    return [withGuarantor.value, occupation.total];
  }

  private buildTenantCandidatureLabels(): string[] {
    const { occupation } = this.buildingStatus;

    return occupation.values
      .filter((value: KeyValuePair) => value.key !== WITH_GUARANTOR_KEY)
      .map((value: KeyValuePair) => value.key)
      .reverse();
  }

  private buildTenantCandidatureData(): number[] {
    const { occupation } = this.buildingStatus;

    return occupation.values
      .filter((value: KeyValuePair) => value.key !== WITH_GUARANTOR_KEY)
      .map((value: KeyValuePair) => value.value)
      .reverse();
  }

  private buildScoringLabels(): string[] {
    const { reports } = this.buildingStatus;

    return reports.values.map((value: KeyValuePair) => value.key);
  }

  private buildScoringData(): number[] {
    const { reports } = this.buildingStatus;

    return reports.values.map((value: KeyValuePair) => value.value);
  }

  private markMaxTenantsCandidature(): void {
    const values = this.buildTenantCandidatureData();
    const positionMax = values.indexOf(Math.max(...values));

    if (Number.isInteger(positionMax)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.tenantsCandidatureData.datasets[0].backgroundColor[positionMax] =
        '#4082d0';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.tenantsCandidatureData.datasets[0].hoverBackgroundColor[
        positionMax
      ] = '#4082d0';
    }
  }
}
