import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class SplitPaneMenuService {
  showSplitPane = true;
  showSplitPane$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isPortfolioPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showMenuBtn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  getShowSplitPane(): Observable<boolean> {
    return this.showSplitPane$.asObservable();
  }

  setShowSplitPane(): void {
    this.showSplitPane = !this.showSplitPane;
    this.showSplitPane$.next(this.showSplitPane);
  }

  getShowMenuBtn(): Observable<boolean> {
    return this.showMenuBtn$.asObservable();
  }

  setShowMenuBtn(val: boolean): void {
    this.showMenuBtn$.next(val);
  }
}
