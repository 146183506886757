import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { InsuranceOptionPopoverEnum } from '../../models/insurance-options-popover.model';
import { ChildRoutesEnum } from '../../models/insurance-page.model';

@Component({
  selector: 'el-buen-inquilino-insurance-options-popover',
  templateUrl: './insurance-options-popover.component.html'
})
export class InsuranceOptionsPopoverComponent {
  @Input() fromBackoffice = false;
  @Input() unpaid = false;
  @Input() policyNumber = '';
  @Input() showCancel = true;
  @Input() showSeeDetails = true;
  @Input() isClient = false;
  @Input() showCreateSinister = true;
  @Input() isSinisterPage = false;
  @Input() showHistoricalDebt = false;
  @Input() showChronology = false;
  @Input() isClosed = false;
  @Input() seeCandidature = false;
  @Input() type: ChildRoutesEnum;

  optionsEnum = InsuranceOptionPopoverEnum;

  get showModifyOption(): boolean {
    return !this.isSinisterPage && this.type !== ChildRoutesEnum.CANCELLED;
  }

  constructor(private popoverController: PopoverController) {}

  onClickUtilOptions(option: InsuranceOptionPopoverEnum): void {
    this.popoverController.dismiss({
      option
    });
  }
}
