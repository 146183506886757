import { Pipe, PipeTransform } from '@angular/core';
import { PolicyIssue, Sinister } from '@core/models';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';
import { add, format, parse } from 'date-fns';

@Pipe({
  name: 'homeownerInsurancesRenovationDate'
})
export class HomeownerInsurancesRenovationDatePipe implements PipeTransform {
  transform(value: PolicyIssue | Sinister, type: ChildRoutesEnum): string {
    let date: Date;
    if (type === ChildRoutesEnum.ACTIVE) {
      if (!!(value as PolicyIssue).inceptionDate) {
        date = parse(
          (value as PolicyIssue).inceptionDate,
          'dd/MM/yyyy',
          new Date()
        );
        date = add(date, { years: 1 });
      } else {
        return null;
      }
    } else {
      const sinister = value as Sinister;
      if (!sinister.endDate) {
        return 'En proceso';
      } else {
        date = new Date(sinister.endDate);
      }
    }
    return format(date, 'dd/MM/yyyy');
  }
}
