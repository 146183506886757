import { Pipe, PipeTransform } from '@angular/core';
import { Incidence } from '@core/models';

@Pipe({
  name: 'candidatureTenantCheckIncidences'
})
export class CandidatureTenantCheckIncidencesPipe implements PipeTransform {
  transform(incidences: Incidence[]): boolean {
    return (
      incidences &&
      incidences.length > 0 &&
      incidences.some(
        (incidence: Incidence) =>
          incidence.endDate === null || incidence.endDate === undefined
      )
    );
  }
}
