import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { Sinister, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController } from '@ionic/angular';

import { PolicyDetailForm } from '../../models/policy-detail-modal.model';
import { getUsers } from '../../utils/sinister.utils';

@Component({
  selector: 'el-buen-inquilino-sinister-detail-modal',
  templateUrl: './sinister-detail-modal.component.html'
})
export class SinisterDetailModalComponent implements OnInit {
  @Input() sinister: Sinister;
  @Input() user: User;
  @Input() isIncofisa = false;

  form: FormGroup<PolicyDetailForm>;
  showSinisterConversation = false;
  today: Date = new Date();
  users: User[] = [];

  constructor(
    private modalController: ModalController,
    private loadingService: LoadingService,
    private userService: UsersApiService
  ) {}

  async ngOnInit(): Promise<void> {
    this.setForm();
    await this.getUsers();
  }

  dismiss(): void {
    if (this.isIncofisa) {
      this.modalController.dismiss({ sinister: this.sinister });
    } else {
      this.modalController.dismiss();
    }
  }

  private async getUsers(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.users = await getUsers(this.userService, this.user, this.sinister);
    await this.loadingService.dismissSecondLoader();
  }

  private setForm(): void {
    this.form = new FormGroup<PolicyDetailForm>({
      address: new FormControl<string>({
        value: this.sinister.insuranceAsset.address,
        disabled: true
      }),
      city: new FormControl<string>({
        value: this.sinister.insuranceAsset.city,
        disabled: true
      }),
      policyHolder: new FormControl<string>({
        value: this.sinister.emailPolicyHolder,
        disabled: true
      })
    });
  }
}
