/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import {
  CustomerCareRequestCandidature,
  ResultQueryCustomerCare
} from '../../models';

@Injectable()
export class CustomerCareApiService {
  customerCareRequestCandidatureListData: CustomerCareRequestCandidature[] = [];
  url: string;
  urlCloudFunctions: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.users;
    this.urlCloudFunctions = environment.services.ebiCloudFunctions;
  }

  getAnalysisByDateFilter(
    requestDate: string,
    toDate: string,
    pageSize: number,
    lastVisibleDoc?: any,
    direction?: string
  ): Observable<ResultQueryCustomerCare> {
    const queryParams = `?pageSize=${pageSize}`;
    return this.http.post<ResultQueryCustomerCare>(
      this.urlCloudFunctions +
        `/customerCare/notRegisteredCandidaturesByDate/${requestDate}/${toDate}${queryParams}`,
      { lastVisibleDoc, direction }
    );
  }

  getAnalysisByCandidatureId(
    candidatureId: string
  ): Observable<CustomerCareRequestCandidature[]> {
    return this.http.get<CustomerCareRequestCandidature[]>(
      this.urlCloudFunctions + `/customerCare/candidatureById/${candidatureId}`
    );
  }
}
