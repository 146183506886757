/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ControlContainer,
  NgForm,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  TenantCandidature,
  TypeUserEnum,
  User,
  ValidateEmailResponse
} from '@core/models';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { first } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-tenants-section',
  templateUrl: './tenants-section.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TenantsSectionComponent implements OnInit {
  @Input() user: User;
  @Input() usersToUpdate: TenantCandidature[];
  @Output() confirmCertification = new EventEmitter<User[]>();

  users: User[] = [];
  userToAdd: User;
  emailForm: UntypedFormGroup;
  tenantForm: UntypedFormGroup;
  verifyError = false;
  warningText = '';
  verifySuccess = false;
  verifySuccessText = '';
  showInputSearch = true;
  showConfirmButton = false;
  showFormNewUser = false;
  validationErrorMessages = appValidators.validationErrorMessages;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private toastController: ToastController,
    private usersService: UsersApiService
  ) {}

  ngOnInit(): void {
    if (this.user.trialVersion) {
      this.initForm(null);
      this.showInputSearch = false;
    } else {
      this.initEmailForm();
      this.showInputSearch = true;
    }
    this.fillTenantsToUpdate();
  }

  fillTenantsToUpdate(): void {
    this.usersToUpdate.forEach((tenant) => {
      tenant.user.unremovable = true;
      this.users.push(tenant.user);
    });
  }

  initEmailForm(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, appValidators.emailValidator]]
    });
  }

  initForm(email: string): void {
    this.tenantForm = this.formBuilder.group({
      firstname: [
        '',
        [
          Validators.required,
          appValidators.namesValidator,
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      surname1: [
        '',
        [
          Validators.required,
          appValidators.namesValidator,
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      surname2: ['', [appValidators.namesValidator, Validators.maxLength(40)]]
    });

    if (this.user.trialVersion) {
      this.tenantForm.addControl(
        'dni',
        this.formBuilder.control(null, [
          Validators.required,
          appValidators.dniAndNieAndPassportValidator
        ])
      );
    } else {
      this.tenantForm.addControl(
        'email',
        this.formBuilder.control(email, [
          Validators.required,
          appValidators.emailValidator
        ])
      );
    }
  }

  searchUser(email: string): void {
    this.verifyError = false;
    this.userToAdd = null;
    this.showFormNewUser = false;
    if (this.usersToUpdate && this.usersToUpdate.length > 0) {
      const existsUserInTenantList = this.usersToUpdate.some(
        (user) => user.user.email === email
      );
      if (existsUserInTenantList) {
        this.verifyError = true;
        this.warningText =
          'El email introducido está duplicado en la candidatura.';
        return;
      }
    }
    this.usersService.getUserByEmail(email.toLowerCase()).subscribe(
      (user) => {
        if (user[0].userType === TypeUserEnum.HOMEOWNER) {
          // Si es homeowner, no se puede continuar
          this.verifyError = true;
          this.warningText =
            'El email introducido pertenece a un usuario que no puede utilizarse como inquilino o avalista.';
          return;
        } else if (this.checkBusinessUser(user[0])) {
          // Si es de tipo empresa, no se puede continuar
          this.verifyError = true;
          this.warningText =
            'Uno de los integrantes de la candidatura es de tipo Empresa y esto impide agregar más miembros.';
        } else {
          if (user[0].coTenants) {
            // Si tiene co-inquilinos se le añade como cotenant directamente sin opción a ser avalista
            user[0].coTenants.forEach((cotenantId) => {
              this.usersService.getUser(cotenantId).then((cotenant) => {
                this.users.push(cotenant);
              });
              if (user[0].guarantor) {
                this.warningText =
                  user[0].firstname +
                  ' ya está avalando a otros inquilinos. Si le invitas, les estarás invitando también a la candidatura.';
              } else {
                this.warningText =
                  user[0].firstname +
                  ' tiene ya co-inquilinos o un avalista asociados. Si le invitas, estarás invitando a todos los demás.';
              }
              this.verifyError = true;
            });
            this.users.push(user[0]);
            this.showConfirmButton = true;
          } else {
            if (user[0].guarantor) {
              // Si es avalista sin co-inquilinos, se le añade directamente con el rol de avalista.
              this.users.push(user[0]);
              this.verifyError = true;
              this.warningText =
                user[0].firstname +
                ' está en EBI como avalista. Si le invitas, avalará tu candidatura una vez acepte.';
              this.showConfirmButton = true;
            } else {
              this.verifyError = true;
              this.showInputSearch = false;
              this.warningText =
                'Parece que ' +
                user[0].firstname +
                ' ya está EBI. Puedes añadirle como nuevo inquilino o avalista a la candidatura.';
              this.userToAdd = user[0];
            }
          }

          this.emailForm.reset();
        }
      },
      (error) => {
        if (error.error.code === '102') {
          this.initForm(email);
          this.showFormNewUser = true;
          this.showInputSearch = false;

          // Si el usuario no existe, comprobamos que sea un email valido
          this.usersService
            .verifyUserEmail(email)
            .pipe(first())
            .subscribe({
              next: (response: ValidateEmailResponse) => {
                const validateUserEmail = response;

                if (validateUserEmail?.status === 'invalid') {
                  this.verifyError = true;
                  this.warningText =
                    'El email introducido no ha podido ser validado. Revisa que sea correcto.';
                  return;
                }

                this.verifyError = true;
                this.warningText =
                  'El email introducido no pertenece a ningún usuario dado de alta. Rellena el formulario y le enviaremos una invitación a participar en esta candidatura.';
              },
              error: (err) => {
                console.error(err);
              }
            });
        }
      }
    );
  }

  addNewUser(isGuarantor: boolean): void {
    if (this.userToAdd) {
      // Si estamos añadiendo un tenant ya registrado y conocido
      this.userToAdd.guarantor = isGuarantor;
      this.users.push(this.userToAdd);
      this.verifyError = false;
      this.emailForm.reset();
      this.showInputSearch = true;
      this.showConfirmButton = true;
      this.userToAdd = null;
    } else {
      // Estamos añadiendo desde el formulario de nuevo tenant
      this.touchForm();
      if (!this.tenantForm.invalid) {
        if (this.tenantForm.value.surname2 === null) {
          this.tenantForm.value.surname2 = '';
        }
        const validateUser: User = {
          dni: this.tenantForm.value.dni,
          firstname: this.tenantForm.value.firstname,
          surname1: this.tenantForm.value.surname1,
          surname2: this.tenantForm.value.surname2,
          guarantor: isGuarantor,
          email: this.tenantForm.value?.email || null,
          fullName: () => ''
        };

        this.verifyError = false;
        this.emailForm?.reset();
        if (!this.user.trialVersion) {
          this.showInputSearch = true;
        }
        this.showConfirmButton = true;
        this.users.push(validateUser);
        this.tenantForm.reset();
      }
    }
  }

  removeUser(index: number): void {
    this.verifyError = false;
    this.verifySuccess = false;
    const userToRemove = this.users[index];
    //Se borra el usuario
    this.users.splice(index, 1);

    //Se comprueba si tiene tenants
    if (userToRemove.id && userToRemove.coTenants?.length > 0) {
      userToRemove.coTenants.forEach((cotenantId) => {
        const indexCotenant = this.users.findIndex(
          (cotenant) => cotenant.id === cotenantId
        );
        this.users.splice(indexCotenant, 1);
      });
    }

    if (this.users.length === this.usersToUpdate.length) {
      this.showConfirmButton = false;
    }
  }

  removeTempUser(): void {
    if (this.user.trialVersion) {
      this.tenantForm?.reset();
    } else {
      this.verifyError = false;
      this.verifySuccess = false;
      this.userToAdd = null;
      this.tenantForm?.reset();
      this.emailForm?.reset();
      this.showInputSearch = true;
    }
  }

  finishCandidature(): void {
    this.confirmCertification.emit(this.users);
  }

  async presentToast(messages?: any): Promise<void> {
    let messageKey = 'pages.profile.home-owner.assets.add-assets.savedOK';
    let colorKey = 'default';

    if (!messages) {
      messageKey = this.translateService.instant(messageKey);
      colorKey = 'success';
    } else {
      messageKey = messages;
      colorKey = 'danger';
    }

    const toast = await this.toastController.create({
      message: messageKey,
      position: 'top',
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  hasFormErrors(controlName: string): boolean {
    return this.tenantForm.get(controlName).errors !== null;
  }

  getErrorMessage(controlName: string): string {
    const errors = Object.keys(this.tenantForm.get(controlName).errors);

    let errorMessage: string;
    this.validationErrorMessages[controlName]?.filter((error) => {
      if (error.type === errors[0]) {
        errorMessage = error.message;
      }
    });

    return errorMessage;
  }

  validateEmailFormat(email: string): boolean {
    if (
      email &&
      email.length > 5 &&
      email.includes('@') &&
      email.includes('.')
    ) {
      return false;
    } else {
      return true;
    }
  }

  private touchForm(): void {
    const controls = Object.values(this.tenantForm.controls);
    controls.forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();
    });
  }

  private checkBusinessUser(userToAdd: User): boolean {
    if (this.users?.length > 0) {
      return (
        userToAdd.business ||
        this.users.some((tenant) => tenant.business === true)
      );
    } else {
      return false;
    }
  }
}
