import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  EmitterUserEmailInvitation,
  User,
  ValidateEmailResponse
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

@Component({
  selector: 'el-buen-inquilino-step-two-new-tenant',
  templateUrl: './step-two-new-tenant.page.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class StepTwoNewTenantPage implements OnInit {
  @Input() email: string;
  @Output() verifyEmail = new EventEmitter<EmitterUserEmailInvitation>();
  @Input() validateEmail: ValidateEmailResponse;

  user: User = new User();
  emailIsValid = true;
  validationErrorMessages = appValidators.validationErrorMessages;
  country: CountryCode = 'ES';

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.validateEmail?.status === 'invalid') {
      this.emailIsValid = false;
    } else {
      this.emailIsValid = true;
    }

    this.user.email = this.email;
    this.user.firstname = this.validateEmail?.firstname || null;
    this.user.surname1 = this.validateEmail?.lastname || null;
  }

  checkForm(): void {
    if (
      this.user &&
      this.user.email &&
      this.user.email.length > 5 &&
      this.user.email.includes('@') &&
      this.user.firstname &&
      this.user.surname1
    ) {
      if (this.user.phone) {
        this.user.phone = this.formatNumber(this.user.phone);
      }

      this.verifyEmail.emit({
        user: this.user,
        emailIsValid: this.emailIsValid
      });
    }
  }

  /**
   * Se hace trim al valor del input
   * @param input Form control del fomulario
   */
  deleteSpacesFormControl(input: string): void {
    this.user[input] = (this.user[input] as string)?.trim();
  }

  changeCountry(event: CountryCode): void {
    this.country = event;
  }

  formatNumber(phone: string): string {
    return this.utilsService.formatPhone(phone);
  }
}
