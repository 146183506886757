/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppointmentFormValue } from '@shared-home-owner/models/calendar.model';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AnalysisCatalogue,
  ApiDto,
  CalendarAppointment,
  Candidature,
  ChangePolicyPaymentStatusDto,
  Condition,
  Coupon,
  CreateAnalysisDto,
  CreateUserByEmail,
  GetInternalEventsFilter,
  HomeOwnerEnterpriseDataDto,
  HomeOwnerInsuranceFeeDto,
  Incidence,
  InsuranceTenantRequestDto,
  InvoiceGroup,
  PolicyHolderData,
  ProfDataDto,
  ReportHistory,
  ReportInternalEventDto,
  RequestAnalysisDto,
  RequestAnalysisForTenantDto,
  RequestUserAuthInfo,
  ResearchLevels,
  ResearchLevelsRequest,
  Session,
  StripePriceReportType,
  StripePriceType,
  StripeSession,
  SubjectAnalysis,
  SubjectAnalysisRequest,
  TenantDto,
  TenantProfessionalData,
  TypeUserEnum,
  UpdateBasicInfoHomeownerDto,
  UpdateCompleteUser,
  UpdateUser,
  User,
  UserAnalysis,
  UserAuthInfo,
  UserDto,
  ValidateEmailResponse,
  VerifyCrrRequest,
  VerifyCrrResponse,
  VerifyExistingPhoneDto,
  VerifyWithoutDoc,
  VppSurvey
} from '../../models';

@Injectable()
export class UsersApiService {
  url: string;
  urlCloudFunctions: string;

  userId: string;

  // Compartimos aquí el cotenantsList para la página de documentación
  cotenantsList: User[] = [];

  revisionUserId: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.users;
    this.urlCloudFunctions = environment.services.ebiCloudFunctions;
  }

  getUser(keyCode: string, ofuscate?: boolean): Promise<User> {
    if (ofuscate) {
      return lastValueFrom(
        this.http.get<User>(
          this.url + '/users/' + keyCode + '?ofuscate=' + ofuscate
        )
      );
    } else {
      return lastValueFrom(this.http.get<User>(this.url + '/users/' + keyCode));
    }
  }
  getUserSubscribe(keyCode: string): Observable<User> {
    return this.http.get<User>(this.url + '/users/' + keyCode);
  }

  getUserDto(keyCode: string): Promise<UserDto> {
    return this.http.get<UserDto>(this.url + '/users/' + keyCode).toPromise();
  }

  getUserObservable(keyCode: string): Observable<User> {
    return this.http.get<User>(this.url + '/users/' + keyCode);
  }

  registerUser(user: User): Observable<any> {
    return this.http.post(this.url + '/users', user);
  }

  registerUserByEmail(user: CreateUserByEmail): Observable<User> {
    return this.http.post<User>(this.url + '/users/email-register', user);
  }

  sendPasswordResetEmail(email: string): Observable<any> {
    return this.http.get(this.url + `/users/${email}/send-password-email`);
  }

  updateReceiveOffers(
    keyCode: string,
    updateUser: UpdateUser
  ): Observable<any> {
    return this.http.patch(this.url + '/users/' + keyCode, updateUser);
  }

  getUserByEmail(
    email: string,
    notObfuscated: boolean = false
  ): Observable<User[]> {
    return this.http.get<User[]>(
      this.url +
        `/users?email=${encodeURIComponent(
          email
        )}&notObfuscate=${notObfuscated}`
    );
  }

  getUserByEmail2(email: string): Observable<User> {
    return this.http.get<User>(`${this.url}/users/by-email/${email}`);
  }

  getUserEnterpriseData(
    userId: string
  ): Observable<HomeOwnerEnterpriseDataDto> {
    return this.http.get<HomeOwnerEnterpriseDataDto>(
      this.url + '/homeowner/' + userId + '/enterprise-data'
    );
  }

  setUserEnterpriseData(
    userId: string,
    userEnterpriseData: HomeOwnerEnterpriseDataDto
  ): Observable<HomeOwnerEnterpriseDataDto> {
    return this.http.put<HomeOwnerEnterpriseDataDto>(
      this.url + '/homeowner/' + userId + '/enterprise-data',
      userEnterpriseData
    );
  }

  updateUser(keyCode: string, updateUser: User): Observable<any> {
    return this.http.put(this.url + '/users/' + keyCode, updateUser);
  }

  updateUser2(id: string, data: UpdateCompleteUser): Observable<User> {
    const endpoint = `${this.url}/users/${id}`;
    return this.http.put<User>(endpoint, data);
  }

  getProfessionalData(userId: string): Observable<ProfDataDto> {
    return this.http.get<ProfDataDto>(
      this.url + '/tenants/' + userId + '/professional-data'
    );
  }

  saveProfessionalData(
    userId: string,
    profData: TenantProfessionalData
  ): Observable<ProfDataDto> {
    const endpoint = `${this.url}/tenants/${userId}/professional-data`;
    return this.http.put<ProfDataDto>(endpoint, profData);
  }

  addTenant(tenant: TenantDto): Observable<any> {
    return this.http.post(this.url + '/tenants', tenant);
  }

  getCotenantList(userId: string): Observable<any> {
    return this.http.get<any>(this.url + '/tenants/' + userId + '/co-tenants');
  }

  removeCotenant(requestCotenantId: string): Observable<unknown> {
    return this.http.delete(
      this.url + '/tenants/' + requestCotenantId + '/co-tenants'
    );
  }

  requestAnalysis(
    candidatureId: string,
    reLaunched?: boolean,
    rentalPrice?: number,
    homeownerId?: string
  ): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const obj: RequestAnalysisDto = new RequestAnalysisDto();
    obj.candidatureId = candidatureId;
    obj.reLaunched = reLaunched ?? false;
    obj.rentalPrice = rentalPrice;
    obj.homeownerId = homeownerId;
    return this.http.post(this.url + '/requests-analysis', obj);
  }

  getAnalysis(requestAnalysisId: string): Observable<UserAnalysis> {
    return this.http.get<UserAnalysis>(
      this.url + '/requests-analysis/' + requestAnalysisId
    );
  }
  getCompletedAnalysis(
    candidatureId: string,
    userId: string
  ): Observable<CreateAnalysisDto> {
    return this.http.get<CreateAnalysisDto>(
      this.url +
        '/analysis?candidatureId=' +
        candidatureId +
        '&userId=' +
        userId
    );
  }

  createAnalysis(analysis: CreateAnalysisDto): Observable<any> {
    return this.http.post(this.url + '/analysis', analysis);
  }

  verifyWithoutDoc(
    requestAnalysisId: string,
    verifyWithoutDoc: VerifyWithoutDoc
  ): Observable<any> {
    return this.http.put(
      // eslint-disable-next-line max-len
      `${this.url}/requests-analysis/${requestAnalysisId}/validate-without-documentation`,
      verifyWithoutDoc
    );
  }

  updateAnalysis(analysis: CreateAnalysisDto): Observable<any> {
    return this.http.patch(this.url + '/analysis', analysis);
  }

  createRequestAnalysisIncidence(
    requestAnalysisId: string,
    incidence: Incidence
  ): Observable<any> {
    return this.http.put<any>(
      this.url + '/requests-analysis/' + requestAnalysisId + '/incidences',
      incidence
    );
  }

  closeRequestAnalysisIncidence(
    requestAnalysisId: string,
    incidence: Incidence
  ): Observable<any> {
    const url =
      this.url +
      '/requests-analysis/' +
      requestAnalysisId +
      '/incidences/' +
      incidence.id +
      '/close';
    return this.http.patch<any>(url, null);
  }

  getRequestAnalysisByUser(
    userId: string,
    candidatureId: string
  ): Observable<RequestAnalysisDto> {
    const httpParams = new HttpParams()
      .append('userId', userId)
      .append('candidatureId', candidatureId);
    return this.http.get<RequestAnalysisDto>(this.url + '/requests-analysis/', {
      params: httpParams
    });
  }

  getRequestAnalysisByUserFromTenant(
    userId: string,
    candidatureId: string
  ): Observable<RequestAnalysisForTenantDto> {
    const httpParams = new HttpParams()
      .append('userId', userId)
      .append('candidatureId', candidatureId);
    return this.http.get<RequestAnalysisForTenantDto>(
      this.url + '/requests-analysis/from-tenant',
      {
        params: httpParams
      }
    );
  }

  getReport(candidatureId: string): Observable<Blob> {
    return this.http.get(
      this.url + '/tenants/candidature/' + candidatureId + '/report',
      { responseType: 'blob' }
    );
  }

  getCatalogue(): Observable<AnalysisCatalogue> {
    return this.http.get<AnalysisCatalogue>(this.url + '/catalogues');
  }

  requestDocumentation(candidatureId: string, userId: string): Observable<any> {
    const obj = { candidatureId };
    return this.http.post(
      this.url + '/tenants/' + userId + '/request-documentation',
      obj
    );
  }

  getRequestDocumentation(tenantId: string): Observable<any> {
    return this.http.get(
      this.url + '/tenants/' + tenantId + '/request-documentation'
    );
  }

  updateGuarantorStatus(userId: string): Observable<any> {
    return this.http.patch(this.url + '/guarantor/' + userId, null);
  }

  createStripeSession(
    userId: string,
    email: string,
    customerId: string,
    price: StripePriceType
  ): Observable<StripeSession> {
    const obj = {
      customerId,
      customerEmail: email,
      priceId: price,
      successUrl: this.createSuccessUrl(userId)
    };
    this.userId = userId;
    return this.http.post<StripeSession>(
      this.url + '/homeowner/' + userId + '/checkout/sessions',
      obj
    );
  }
  createSuccessUrl(userId: string): string {
    return environment.successUrl + userId + '/payment-success';
  }

  createStripeExtraSession(
    userId: string,
    email: string,
    customerId: string,
    price: StripePriceReportType,
    successUrl: string
  ): Observable<StripeSession> {
    const obj = {
      customerEmail: email,
      customerId,
      priceId: price,
      successUrl
    };
    return this.http.post<StripeSession>(
      this.url + '/homeowner/' + userId + '/checkout/sessions',
      obj
    );
  }

  createRequestAnalysisSuccessUrl(
    userId: string,
    candidature: Candidature
  ): string {
    return (
      environment.successUrl +
      userId +
      '/payment-analysis-success?candidatureId=' +
      candidature.id
    );
  }

  getPriceExtra(price: string): StripePriceReportType {
    switch (price) {
      case 'BASIC':
        return StripePriceReportType.BASIC_REPORT;
      case 'PRO':
        return StripePriceReportType.PRO_REPORT;
      case 'PREMIUM':
        return StripePriceReportType.PREMIUM_REPORT;
      default:
        return StripePriceReportType.BASIC_REPORT;
    }
  }

  getStripeSubscription(
    userId: string,
    sessionId: string
  ): Observable<StripeSession> {
    return this.http.get<StripeSession>(
      this.url + '/homeowner/' + userId + '/checkout/sessions/' + sessionId
    );
  }

  editStripeSubscription(
    homeownerId?: string,
    stripeUserId?: string
  ): Observable<any> {
    return this.http.get(
      this.url +
        '/homeowner/' +
        homeownerId +
        '/customerPortal/' +
        stripeUserId +
        '/sessions'
    );
  }

  removeUser(userId: string, reasons: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        reasons
      }
    };
    return this.http.delete(this.url + '/users/' + userId, options);
  }

  deactivateUser(userId: string): Observable<any> {
    return this.http.post(
      `${this.urlCloudFunctions}/deactivateUser/${userId}`,
      {}
    );
  }

  removeGuarantorFromTenantGroup(cotenantIdList: string[]): Observable<any> {
    const options = {
      params: {
        cotenantIdList
      }
    };
    return this.http.delete(this.url + '/guarantor/cotenantsGroup', options);
  }

  getPromotionalCoupons(): Observable<Coupon[]> {
    return this.http.get<Coupon[]>(this.url + '/users/coupons');
  }

  getInsuranceFeeListBackOffice(): Observable<HomeOwnerInsuranceFeeDto[]> {
    return this.http.get<HomeOwnerInsuranceFeeDto[]>(
      this.url + '/homeowner/insurances-backoffice'
    );
  }

  updatePaymentStatus(
    userId: string,
    policyNumber: string,
    changePolicyPaymentStatusDto: ChangePolicyPaymentStatusDto
  ): Observable<any> {
    return this.http.patch(
      this.url +
        '/homeowner/insurances-backoffice/' +
        userId +
        '/policies/' +
        policyNumber +
        '/updatePaymentStatus',
      changePolicyPaymentStatusDto
    );
  }

  getAssociatedUsersByUserId(userId: string): Observable<string[]> {
    return this.http.get<string[]>(
      this.url + '/homeowner/' + userId + '/associated-users'
    );
  }

  sendEmailVerification(userEmail: string): Observable<any> {
    return this.http.get(
      this.url + '/users/' + userEmail + '/email-verification'
    );
  }

  createRequestAnalysisConditioning(
    requestAnalysisId: string,
    conditioning: string
  ): Observable<any> {
    const obj = {
      conditioning
    };
    return this.http.put<any>(
      this.url + `/requests-analysis/${requestAnalysisId}/conditionings`,
      obj
    );
  }

  deleteRequestAnalysisConditioning(
    requestAnalysisId: string
  ): Observable<any> {
    return this.http.delete<any>(
      this.url + `/requests-analysis/${requestAnalysisId}/conditionings`
    );
  }

  updateAutomatedReports(automatedReports: boolean): Observable<any> {
    return this.http.patch<any>(
      this.url +
        '/homeowner/updateAutomatedReports?automatedReports=' +
        automatedReports,
      null
    );
  }

  updateDefaultNotifications(defaultNotifications: boolean): Observable<any> {
    return this.http.patch<any>(
      this.url +
        '/homeowner/updateDefaultNotifications?defaultNotifications=' +
        defaultNotifications,
      null
    );
  }

  updateAutomatedTenantPolicies(
    userId: string,
    enabled: boolean
  ): Observable<any> {
    return this.http.patch<any>(
      this.url + `/users/${userId}/update-automated-tenant-policies/${enabled}`,
      null
    );
  }

  getInternalEventsByFilter(
    requestFilter: GetInternalEventsFilter
  ): Observable<ReportInternalEventDto[]> {
    return this.http.post<ReportInternalEventDto[]>(
      this.url + '/events/by-filter',
      requestFilter
    );
  }

  createEvent(
    newEvent: ReportInternalEventDto
  ): Observable<ReportInternalEventDto> {
    return this.http.post<ReportInternalEventDto>(
      this.url + '/events/',
      newEvent
    );
  }

  deleteRevisionMark(candidatureId: string): Observable<void> {
    return this.http.delete<void>(this.url + '/events/' + candidatureId);
  }

  updateEvent(internalEventId: string, newAgentId: string): Observable<any> {
    const updateAgent = {
      newAgentId
    };
    return this.http.patch(
      this.url + '/events/' + internalEventId,
      updateAgent
    );
  }

  getUsersByType(userType: TypeUserEnum): Observable<UserDto[]> {
    return this.http.get<UserDto[]>(
      this.url + '/users/by-type?userType=' + userType
    );
  }

  getIncofisaAdministrator(): Observable<UserDto[]> {
    return this.getUsersByType(TypeUserEnum.INCOFISA_ADMINISTRATIVE).pipe(
      // TODO: quitar este bloque cuando se quite funcionalidad revision
      map((users: UserDto[]) =>
        users.map((user: UserDto) => {
          if (user.email === environment.revisionEmail) {
            this.revisionUserId = user.id;
            return null;
          } else {
            return user;
          }
        })
      ),
      map((users: UserDto[]) => users.filter((user: UserDto) => !!user))
      // TODO: quitar este bloque cuando se quite funcionalidad revision
    );
  }

  updateBasicInfoHomeowner(
    userId: string,
    email: string,
    firstname: string,
    surname1: string,
    surname2: string,
    password: string,
    dni: string,
    occupation: string,
    businessName: string,
    cif: string,
    taxResidence: string,
    phone: string,
    birthDate: Date,
    birthPlace: string,
    userType: TypeUserEnum,
    foreignIncomes: boolean = false,
    retryDNI?: boolean
  ): Observable<User> {
    const obj: UpdateBasicInfoHomeownerDto = {
      email,
      firstname,
      surname1,
      surname2,
      password,
      dni,
      occupation,
      businessName,
      cif,
      taxResidence,
      phone,
      birthDate,
      birthPlace,
      retryDNI,
      userType,
      foreignIncomes
    }; // AQUI

    return this.http.put<User>(
      this.url + '/users/' + userId + '/basic-info',
      obj
    );
  }

  createMinimalUser(user: UserDto): Observable<User> {
    return this.http.post<User>(this.url + '/users/alternative-register', user);
  }

  getUserRegisterRequestById(
    userRegisterRequestId: string
  ): Observable<UserDto> {
    return this.http.get<UserDto>(
      this.url + '/register-requests/by-id/' + userRegisterRequestId
    );
  }

  getUserRegisterRequestByEmail(email: string): Observable<UserDto> {
    return this.http.get<UserDto>(
      this.url + '/register-requests/by-email/' + email
    );
  }

  getUserActivateAccountLink(email: string): Observable<string> {
    return this.http.get<string>(
      this.url + '/users/verification-link/' + email
    );
  }

  deleteUserRegisterRequest(email: string): Observable<void> {
    return this.http.delete<void>(this.url + '/register-requests/' + email);
  }

  checkExistingPhone(phoneNumber: string): Observable<VerifyExistingPhoneDto> {
    const requestBody = {
      phoneNumber
    };
    return this.http.post<VerifyExistingPhoneDto>(
      this.url + '/users/verify-phone',
      requestBody
    );
  }

  verifyCrr(tenantId: string, requestBody: VerifyCrrRequest): Observable<any> {
    return this.http.patch<VerifyCrrResponse>(
      `${this.url}/tenants/${tenantId}/verify-crr`,
      requestBody
    );
  }

  updateResearchValues(
    tenantEmail: string,
    requestBody: ResearchLevelsRequest
  ): Observable<User> {
    return this.http.patch<User>(
      `${this.url}/tenants/${tenantEmail}/researchValues`,
      requestBody
    );
  }

  getUserResearchLevel(userId: string): Observable<ResearchLevels> {
    return this.http
      .get<User>(`${this.url}/users/${userId}`)
      .pipe<ResearchLevels>(map((user: User) => user.researchLevels));
  }

  verifyUserEmail(email: string): Observable<ValidateEmailResponse> {
    return this.http.get<ValidateEmailResponse>(
      `${this.url}/tenants/${email}/verify-email`
    );
  }

  getUserReportHistory(dni: string): Observable<ReportHistory[]> {
    return this.http.get<ReportHistory[]>(`${this.url}/reportHistory/${dni}`);
  }

  saveAutoGuarantor(userId: string): Observable<any> {
    return this.http.patch(
      this.url + '/guarantor/self-update-guarantor/' + userId,
      userId
    );
  }

  createApi(api: ApiDto): Observable<ApiDto> {
    const endpoint = `${this.url}/apis`;
    return this.http.post<ApiDto>(endpoint, api);
  }

  getApis(
    apiAdminId?: string,
    portfolioOwnerId?: string,
    memberId?: string
  ): Observable<ApiDto[]> {
    const params = {};

    if (apiAdminId) {
      params['apiAdminId'] = apiAdminId;
    }
    if (portfolioOwnerId) {
      params['portfolioOwnerId'] = portfolioOwnerId;
    }
    if (memberId) {
      params['memberId'] = memberId;
    }

    return this.http.get<ApiDto[]>(`${this.url}/apis`, { params });
  }

  getApiById(apiId: string): Observable<ApiDto> {
    return this.http.get<ApiDto>(`${this.url}/apis/${apiId}`);
  }

  updateApi(api: ApiDto): Observable<ApiDto> {
    const endpoint = `${this.url}/apis/${api.id}`;
    return this.http.put<ApiDto>(endpoint, api);
  }

  getInvoiceGroups(): Observable<InvoiceGroup[]> {
    const endpoint = `${this.url}/invoice-groups`;
    return this.http.get<InvoiceGroup[]>(endpoint);
  }

  updateSession(
    userId: string,
    session: Session | StripeSession
  ): Observable<any> {
    return this.http.put(`${this.url}/users/update-session/${userId}`, session);
  }

  updateFeePockePaymentStatus(
    homeownerId: string,
    value: boolean
  ): Observable<any> {
    return this.http.patch<any>(
      this.url + `/homeowner/${homeownerId}/updatePendingFeePocketPayment`,
      { value }
    );
  }

  updatePolicyHolderData(
    homeownerId: string,
    data: PolicyHolderData
  ): Observable<any> {
    return this.http.patch<any>(
      this.url + `/homeowner/${homeownerId}/updatePolicyHolderData`,
      data
    );
  }

  updateUserType(userId: string, type: TypeUserEnum): Observable<void> {
    const req = {
      userType: type
    };
    return this.http.patch<void>(
      this.url + `/users/${userId}/update-type`,
      req
    );
  }

  getUserAuthInfo(request: RequestUserAuthInfo): Observable<UserAuthInfo> {
    return this.http.post<UserAuthInfo>(
      this.url + `/register-requests/auth-info`,
      request
    );
  }

  getConditions(): Observable<Condition[]> {
    return this.http.get<Condition[]>(`${this.url}/conditions`);
  }

  updateTenantGuarantorFlag(
    userId: string,
    guarantor: boolean
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.url}/guarantor/update-guarantor-flag-cc/${userId}/${guarantor}`,
      {}
    );
  }

  resendTenantPolicyRequest(
    notification: InsuranceTenantRequestDto
  ): Observable<void> {
    return this.http.post<void>(
      this.url + '/tenants/send-tenant-insurance-request',
      notification
    );
  }

  saveTenantVppSurvey(userId: string, survey: VppSurvey): Observable<any> {
    return this.http.patch(this.url + `/tenants/${userId}/vpp-survey`, survey);
  }

  getAppointments(
    fromDate: string,
    toDate: string
  ): Observable<CalendarAppointment[]> {
    const endpoint = `${this.url}/calendar/appointments`;

    const params = new HttpParams()
      .append('fromDate', fromDate)
      .append('toDate', toDate);

    return this.http.get<CalendarAppointment[]>(endpoint, { params });
  }

  createAppointment(
    data: AppointmentFormValue
  ): Observable<CalendarAppointment> {
    const endpoint = `${this.url}/calendar/appointments`;

    return this.http.post<CalendarAppointment>(endpoint, data);
  }

  editAppointment(
    appointment: CalendarAppointment
  ): Observable<CalendarAppointment> {
    const endpoint = `${this.url}/calendar/appointments/${appointment.id}`;

    return this.http.patch<CalendarAppointment>(endpoint, appointment);
  }

  createReport(candidatureId: string): Observable<Blob> {
    const endpoint = `${this.url}/tenants/candidature/${candidatureId}/report`;

    return this.http.get(endpoint, {
      responseType: 'blob'
    });
  }

  getExperianAnalysis(
    body: SubjectAnalysisRequest
  ): Observable<SubjectAnalysis> {
    const endpoint = `${this.url}/requests-analysis/experian`;

    return this.http.post<SubjectAnalysis>(endpoint, body);
  }
}
