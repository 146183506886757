/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { ContactsApiService } from '@core/api-services/contacts-api/contacts-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  ContactAssetDto,
  CreateContactUserDto,
  NotificationDto,
  NotificationTypeEnum,
  ShareProfileDto,
  TypeUserEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { environment } from '@environments/environment';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CountryCode } from 'libphonenumber-js/types.d';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MessageBean } from '../../utils/message-bean.utils';

@Component({
  selector: 'el-buen-inquilino-send-request-tenant',
  templateUrl: './send-request-tenant.component.html'
})
export class SendRequestTenantComponent implements OnInit {
  link: string;
  email: string;
  firstname: string;
  surname1: string;
  surname2: string;
  phone: any;
  createContactDto = new CreateContactUserDto();
  users$: Observable<User[]>;
  userNotFound = false;
  showInput = true;
  user: User;
  invitationSended = false;
  disableSendBtn = false;
  saving = false;
  forbiddenTenant = false;
  country: CountryCode = 'ES';

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthService,
    private navigationService: NavigationService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private contactService: ContactsApiService,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    private usersService: UsersApiService,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private notificationsService: NotificationsApiService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.createContactDto.assetData = new ContactAssetDto();
  }

  changeCountry(event: CountryCode): void {
    this.country = event;
  }

  generateBaseLink(): string {
    return (
      environment.domain +
      this.navigationService.pathLandingAnonymous() +
      '/' +
      this.authenticationService.user.id
    );
  }

  generateLink(data: string): void {
    this.link = this.generateBaseLink() + '?id=' + data;
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  clickGenerateLink(form: NgForm): void {
    if (form.invalid) {
      this.snackBarService.showAutoHide(
        MessageBean.buildMessages(form, this.translateService),
        2000
      );
    } else {
      this.generateContact().subscribe((data) => {
        this.generateLink(this.getIdContactFromResponse(data));
        this.email = '';
      });
    }
  }

  getIdContactFromResponse(response: HttpResponse<string>): string {
    const locationSnippets = response.headers.get('Location').split('/');
    return locationSnippets[locationSnippets.length - 1];
  }

  async presentToast(
    message: string,
    type: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    translate: boolean
  ): Promise<void> {
    const messageKey = message;
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      position: 'top',
      color: type,
      duration: 3000
    });
    toast.present();
  }

  generateContact(): Observable<HttpResponse<string>> {
    const sharedType = 'TENANT';
    return this.contactService.registerContact(
      this.createContactDto,
      sharedType
    );
  }

  shareProfile(): Observable<ShareProfileDto> {
    const obj = new ShareProfileDto();
    obj.email = this.email;
    obj.asset = this.createContactDto.assetData;
    return this.candidatureService.share(obj);
  }

  async searchUser(): Promise<void> {
    // Si es true, ya vamos directamente a enviar la invitación
    if (this.userNotFound) {
      this.addCotenant(true);
    } else {
      this.userNotFound = false;
      await this.loadingService.presentSecondLoader(null);
      this.users$ = this.usersService
        .getUserByEmail(this.email.toLowerCase())
        .pipe(
          tap(
            async (user) => {
              this.showInput = false;
              this.email = '';
              this.user = user[0];
              if (
                this.user.userType === TypeUserEnum.HOMEOWNER ||
                this.user.business
              ) {
                this.forbiddenTenant = true;
              }
              if (
                this.authenticationService.user.coTenants?.includes(user[0].id)
              ) {
                this.showInput = true;
                this.user = null;
                this.presentToast(
                  'cotenant.profile.duplicateCotenant',
                  'danger',
                  true
                );
              } else if (this.authenticationService.user.id === user[0].id) {
                this.showInput = true;
                this.user = null;
                this.presentToast(
                  'cotenant.profile.yourselfEmail',
                  'danger',
                  true
                );
              }
              await this.loadingService.dismissSecondLoader();
            },
            async (error) => {
              if (error.error.code === '102') {
                this.userNotFound = true;
                this.cdRef.detectChanges();
                this.showInput = false;

                await this.loadingService.dismissSecondLoader();
              }
            }
          )
        );
    }
  }

  async addCotenant(newUser?: boolean): Promise<void> {
    const notification: NotificationDto = new NotificationDto();
    notification.recipientEmail = newUser ? this.email : this.user.email;
    notification.type = NotificationTypeEnum.COTENANT;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    // Si es un usuario que no está en EBI le añadimos los datos del formulario de invitación
    if (newUser) {
      notification.recipientEmail = this.email;
      notification.recipientFirstname = this.firstname;
      notification.recipientSurname1 = this.surname1;
      notification.recipientSurname2 = this.surname2;
      notification.recipientPhone = this.utilsService.formatPhone(this.phone);
    }

    await this.loadingService.presentSecondLoader(null);
    this.notificationsService.newNotification(notification).subscribe(
      async () => {
        this.invitationSended = true;
        this.presentToast('pages.share-profile.email-sended', 'success', true);
        await this.loadingService.dismissSecondLoader();
      },
      async (_error) => {
        this.presentToast('cotenant.profile.duplicateRequest', 'danger', true);
        await this.loadingService.dismissSecondLoader();
      }
    );
  }
}
