import { Pipe, PipeTransform } from '@angular/core';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';

@Pipe({
  name: 'homeownerInsurancesEndDateHeader'
})
export class HomeownerInsurancesEndDateHeaderPipe implements PipeTransform {
  transform(type: ChildRoutesEnum): unknown {
    if (type === ChildRoutesEnum.ACTIVE) {
      return 'Fecha renovación';
    } else {
      return 'Fecha cancelación';
    }
  }
}
