import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';

import { ButtonsRole } from '../../models/button.model';
import { CancelPolicyAlertForm } from '../../models/cancel-policy-alert.model';

@Component({
  selector: 'el-buen-inquilino-cancel-policy-alert',
  templateUrl: './cancel-policy-alert.component.html'
})
export class CancelPolicyAlertComponent implements OnInit {
  @Input() policyNumber: string;

  form: FormGroup<CancelPolicyAlertForm>;
  max = new Date();

  constructor(
    private modalController: ModalController,
    private utilService: UtilsService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL, data?: Date): void {
    this.modalController.dismiss(data, role);
  }

  cancelPolicy(): void {
    if (this.form.invalid) {
      this.utilService.showFormErrors(this.form);
      return;
    }

    this.dismiss(ButtonsRole.ACCEPT, this.form.controls.cancelationDate.value);
  }

  private setForm(): void {
    this.form = new FormGroup<CancelPolicyAlertForm>({
      cancelationDate: new FormControl<Date>(new Date(), [Validators.required])
    });
  }
}
