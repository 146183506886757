import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DeleteAccountModalComponent } from '../delete-account/delete.account.modal.component';

@Component({
  selector: 'el-buen-inquilino-profile-unsubscribe',
  templateUrl: './profile-unsubscribe.component.html'
})
export class ProfileUnsubscribeComponent {
  constructor(private modalController: ModalController) {}

  async presentModalDeleteAccount(): Promise<void> {
    const modal = await this.modalController.create({
      component: DeleteAccountModalComponent,
      cssClass: 'auto-height'
    });
    return await modal.present();
  }
}
