import { Component, Input, OnInit } from '@angular/core';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import {
  AnalysisCatalogue,
  AssetDto,
  Candidature,
  CreateAnalysisDto,
  TenantCandidature,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import {
  calculateBusinessPaymentCapacity,
  calculateBusinesssRisk,
  calculateEffort,
  calculateJointEffort,
  calculatePreviousPeriodResultsValue,
  getAntiquity,
  getBusinessActivitySector,
  getContractType,
  getJudicialIncidents,
  getNonPayments,
  getPosition,
  getRole,
  getTenantDni,
  getTenantEmail,
  getTenantName,
  getTenantPhone,
  getTraceabilityValue
} from '../../utils/preview-report.utils';

@Component({
  selector: 'el-buen-inquilino-preview-report',
  templateUrl: './preview-report.component.html'
})
export class PreviewReportComponent implements OnInit {
  @Input() reports: CreateAnalysisDto[] = [];
  @Input() candidature: Candidature;
  @Input() catalogue: AnalysisCatalogue;
  user: User;
  mostRecentReport: CreateAnalysisDto;

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private assetService: AssetsApiService,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {
    this.user = this.authenticationService.user;
    this.mostRecentReport = this.reports.reduce(
      (mostRecent: CreateAnalysisDto, currentReport: CreateAnalysisDto) => {
        return new Date(currentReport.creationDate) >
          new Date(mostRecent.creationDate)
          ? currentReport
          : mostRecent;
      },
      this.reports[0]
    );

    if (this.mostRecentReport.rentalPrice === null) {
      this.mostRecentReport.rentalPrice = this.candidature.asset.rentalPrice;
    }

    if (!this.candidature?.asset?.rentalArea) {
      this.assetService
        .getAsset(this.candidature.asset.id)
        .pipe(first())
        .subscribe((asset: AssetDto) => (this.candidature.asset = asset));
    }
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  getTenantDni(userId: string): string {
    return getTenantDni(this.candidature, userId);
  }

  getTenantName(userId: string): string {
    return getTenantName(this.candidature, userId);
  }

  isGuarantor(userId: string): boolean {
    const tc = this.findUser(userId);
    return tc.user.guarantor;
  }

  getTenantEmail(userId: string): string {
    return getTenantEmail(this.candidature, userId);
  }

  getTenantPhone(userId: string): string {
    return getTenantPhone(this.candidature, userId);
  }

  getPosition(position: string): string {
    return getPosition(position, this.catalogue, this.translate.currentLang);
  }

  calculateEffort(report: CreateAnalysisDto): string {
    return calculateEffort(report, this.candidature);
  }

  calculateJointEffort(): string {
    return calculateJointEffort(this.candidature, this.reports);
  }

  getBusinessActivitySector(sector: string): string {
    return getBusinessActivitySector(
      sector,
      this.catalogue,
      this.translate.currentLang
    );
  }

  getAntiquity(antiquity: string, business: boolean = false): string {
    return getAntiquity(
      antiquity,
      this.catalogue,
      this.translate.currentLang,
      business
    );
  }

  getJudicialIncidents(
    judicialIncidents: string,
    business: boolean = false
  ): string {
    return getJudicialIncidents(
      judicialIncidents,
      this.catalogue,
      this.translate.currentLang,
      business
    );
  }

  getNonPayments(judicialIncidents: string, business: boolean = false): string {
    return getNonPayments(
      judicialIncidents,
      this.catalogue,
      this.translate.currentLang,
      business
    );
  }

  getTraceabilityValue(traceability: string): string {
    return this.translate.instant(getTraceabilityValue(traceability)) as string;
  }

  getRole(userId: string): string {
    return getRole(this.candidature, userId);
  }

  download(): void {
    this.modalCtrl.dismiss(null, 'download');
  }

  calculatePreviousPeriodResultsValue(
    previousPeriodResults: string
  ): string | null {
    return calculatePreviousPeriodResultsValue(Number(previousPeriodResults));
  }

  calculateBusinessPaymentCapacity(report: CreateAnalysisDto): string {
    return calculateBusinessPaymentCapacity(
      this.candidature,
      report,
      this.catalogue,
      this.translate.currentLang
    );
  }

  checkReportRiskForScoringWithOutScoring(
    reports: CreateAnalysisDto[]
  ): boolean {
    return reports.some((report: CreateAnalysisDto) => report.riskWarning);
  }

  calculateBusinesssRisk(report: CreateAnalysisDto): string {
    return calculateBusinesssRisk(
      report,
      this.catalogue,
      this.translate.currentLang
    );
  }

  getContractType(contractType: string): string {
    return getContractType(
      contractType,
      this.catalogue,
      this.translate.currentLang
    );
  }

  getCif(userId: string): string {
    return this.findUser(userId).user.cif || null;
  }

  private findUser(userId: string): TenantCandidature {
    return this.candidature.tenantCandidatureList.find(
      (tc: TenantCandidature) => tc.user.id === userId
    );
  }
}
