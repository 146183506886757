import { Pipe, PipeTransform } from '@angular/core';
import { Incidence } from '@core/models';

@Pipe({
  name: 'incidenceStatusClass'
})
export class IncidenceStatusClassPipe implements PipeTransform {
  transform(incidence: Incidence): string {
    return `status-${incidence.status.toLowerCase()}`;
  }
}
