import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Candidature,
  DocumentDTO,
  InsuranceCompany,
  PageDto,
  PolicyIssue
} from '../../models';

@Injectable()
export class InsurancesCompanyApiService {
  url: string;
  urlDocuments: string;
  userId: string;
  urlCloudFunctions: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.users;
    this.urlDocuments = environment.services.documents;
    this.urlCloudFunctions = environment.services.ebiCloudFunctions;
  }

  getUser(keyCode: string): Promise<InsuranceCompany> {
    return this.http
      .get<InsuranceCompany>(this.url + '/insurance-companies/' + keyCode)
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateAccountData(id: string, data: InsuranceCompany): Observable<any> {
    data.id = id;
    return this.http.put(this.url + '/insurance-companies/' + id, data);
  }

  getInsurancesByCompanyCode(
    code: string,
    from: string,
    to: string
  ): Observable<PolicyIssue[]> {
    return this.http.get<PolicyIssue[]>(
      `${this.urlCloudFunctions}/insurancePolicies/${code}/${from}/${to}`
    );
  }

  getOperationsByCompanyCode(
    code: string,
    from: string,
    to: string
  ): Observable<Candidature[]> {
    return this.http.get<Candidature[]>(
      `${this.urlCloudFunctions}/insuranceOperations/${code}/${from}/${to}`
    );
  }

  getDocuments(
    numElements: string = '20',
    requestUserId?: string,
    afterElementId?: string
  ): Observable<DocumentDTO[]> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    if (requestUserId) {
      httpParams = httpParams.append('requestUserId', requestUserId);
    }
    return this.http
      .get<PageDto<DocumentDTO>>(
        this.urlDocuments + '/insurance-companies-documents',
        {
          params: httpParams
        }
      )
      .pipe(map((res: PageDto<DocumentDTO>) => res.elements));
  }

  getFileByDocument(documentId: string): Observable<Blob> {
    return this.http.get(
      this.urlDocuments +
        '/insurance-companies-documents/' +
        `${documentId}/document`,
      {
        responseType: 'blob'
      }
    );
  }
}
