import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { FinaerAuthService } from '../../services/auth/finaer-auth.service';
import { NavigationService } from '../../services/navigation/navigation.service';

@Injectable()
export class FinaerGuard {
  constructor(
    private finaerAuthService: FinaerAuthService,
    private navigationService: NavigationService
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.finaerAuthService.isLoggedIn) {
      this.navigationService.goToLoginFinaerBackofficePage();
      return false;
    }
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.finaerAuthService.canActivate(route, state);
  }
}
