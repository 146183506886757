import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetColClass'
})
export class AssetColClassPipe implements PipeTransform {
  transform(screenWidht: number): string {
    return screenWidht > 767
      ? 'ion-justify-content-end'
      : 'ion-justify-content-start';
  }
}
