import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DocumentDTO } from '@core/models';

@Component({
  selector: 'el-buen-inquilino-backoffice-user-documents',
  templateUrl: './backoffice-user-documents.component.html'
})
export class BackofficeUserDocumentsComponent implements OnChanges {
  @Input() documents: DocumentDTO[] = [];

  thereIsDocuments = false;

  @Output() viewDocumentEmitter = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['documents']) {
      this.setThereIsDocuments();
    }
  }

  trackByDocument(_index: number, document: DocumentDTO): DocumentDTO {
    return document;
  }

  viewDocumentEmit(ev: boolean): void {
    this.viewDocumentEmitter.emit(ev);
  }

  private setThereIsDocuments(): void {
    if (this.documents?.length > 0) {
      this.thereIsDocuments = true;
    } else {
      this.thereIsDocuments = false;
    }
  }
}
