// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-grey {
  background-color: #f2f2f2;
}

.fields {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 50% !important;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/verifiy-dni-alert-modal/verify-dni-alert-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;EAEA,yBAAA;EACA,iBAAA;EACA,kBAAA;AAAF","sourcesContent":[".background-grey {\r\n  background-color: #f2f2f2;\r\n}\r\n\r\n.fields {\r\n  margin-top: 50px;\r\n  margin-bottom: 50px;\r\n\r\n  max-width: 50% !important;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
