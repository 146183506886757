import { Component, Input, OnInit } from '@angular/core';
import { AssociatedCandidature, CandidatureStatusEnum } from '@core/models';

@Component({
  selector: 'el-buen-inquilino-associated-candidatures-popover',
  templateUrl: './associated-candidatures-popover.component.html'
})
export class AssociatedCandidaturesPopoverComponent implements OnInit {
  @Input() associatedCandidatures: AssociatedCandidature[];

  openedCandidatures: AssociatedCandidature[] = [];
  rejectedCandidatures: AssociatedCandidature[] = [];
  preselectedCandidatures: AssociatedCandidature[] = [];

  ngOnInit(): void {
    if (this.associatedCandidatures) {
      this.fillCandidaturesStatusArray();
    }
  }

  fillCandidaturesStatusArray(): void {
    this.openedCandidatures = this.associatedCandidatures.filter(
      (candidature: AssociatedCandidature) =>
        candidature.status === CandidatureStatusEnum.PENDING
    );
    this.rejectedCandidatures = this.associatedCandidatures.filter(
      (candidature: AssociatedCandidature) =>
        candidature.status === CandidatureStatusEnum.REJECT
    );
    this.preselectedCandidatures = this.associatedCandidatures.filter(
      (candidature: AssociatedCandidature) =>
        candidature.status === CandidatureStatusEnum.PRE_SELECT
    );
  }
}
