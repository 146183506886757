import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-request-report-modal',
  templateUrl: './request-report-modal.component.html'
})
export class RequestReportModalComponent {
  constructor(private modalCtrl: ModalController) {}

  dismiss(action: boolean): void {
    this.modalCtrl.dismiss({ action });
  }
}
