import { AttachDocument } from './document.model';

export interface Catalog {
  icon: string;
  id: string;
  value: string;
  associatedImage?: AttachDocument;
}

export enum CatalogEnum {
  SPACE_TYPES = 'SPACE_TYPES',
  INCIDENCE_TYPES = 'INCIDENCE_TYPES',
  INCIDENCE_TYPOLOGY = 'INCIDENCE_TYPOLOGY'
}
