/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

import { CandidatureAssetAnalytics, User } from '../../models';

@Injectable()
export class ExportService {
  fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  async exportExcel(
    data: CandidatureAssetAnalytics[],
    fileName: string,
    homeOwnerData: User
  ): Promise<void> {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    const dataEBI = [
      ['FACTURA'],
      ['28/02/2022'],
      ['B-03838838 - El buen inquilino S.L.'],
      ['Av. Manoteras, 18 - Plt 5, Ed Elipse, 28050'],
      ['Madrid']
    ];

    const dataCustomer = [
      [homeOwnerData.businessName],
      [homeOwnerData.cif],
      [homeOwnerData.taxResidence]
    ];

    const dataHeader = [
      [
        'Cód. de Operación',
        'Dirección',
        'Precio',
        'Solicitud de informe',
        'Cierre del informe',
        'Inquilinos',
        'Estado',
        'Tiempo'
      ]
    ];

    this.addNewDataSet(sheet, dataEBI, 'A1');
    this.addNewDataSet(sheet, dataCustomer, 'D1');
    this.addNewDataSet(sheet, [''], undefined);
    this.addNewDataSet(sheet, dataHeader, undefined);
    this.addNewDataSet(
      sheet,
      data.map((item) => {
        return [
          item.assetId,
          item.address,
          item.price,
          item.reportStartDate,
          item.reportEndDate,
          item.tenants,
          item.status,
          item.time.toLocaleString('es-ES', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        ];
      }),
      undefined
    );

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  private addNewDataSet(
    sheet: ExcelJS.Worksheet,
    data: any[],
    coords?: string
  ): void {
    if (coords) {
      sheet.addRows(data, coords);
    } else {
      sheet.addRows(data);
    }
    sheet.columns = this.colWidths();
  }

  private colWidths(): any[] {
    return [
      { width: 40 },
      { width: 90 },
      { width: 10 },
      { width: 20 },
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 10 }
    ];
  }
}
