export interface SectionDTO {
  id?: string;
  name: string;
  weight: number;
  url?: string;
  checked?: boolean;
}

export interface UserSectionDTO {
  percentageProfile: number;
  sections: SectionDTO[];
}
