// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-start: 0px;
}

.grid-content {
  background-color: var(--ion-color-light-tint);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.fullscreen {
  width: 100%;
  height: 100%;
}

ion-card ion-card-header ion-card-title,
ion-card ion-card-header p {
  margin: 0;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-weight: bold;
}

ion-card-content ion-col {
  padding: 0;
}

.modify-column {
  display: flex;
  align-items: center;
}

.success-bar {
  width: 100%;
}

.row-user {
  margin-top: 1rem;
  cursor: pointer;
}
.row-user:hover {
  background-color: var(--ion-color-light) !important;
  color: white;
}
.row-user:hover ion-chip {
  color: white !important;
}

.badge-scoring {
  font-size: 14px;
  height: 30px;
  line-height: 24px;
}

ion-badge[color=default] {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/select-tenants-step/select-tenants-step.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,wBAAA;EACA,oBAAA;EACA,0BAAA;AACF;;AAEA;EACE,6CAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAGE;;EAEE,SAAA;AAAJ;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,UAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;AAGF;;AADA;EACE,WAAA;AAIF;;AADA;EACE,gBAAA;EACA,eAAA;AAIF;AAHE;EACE,mDAAA;EACA,YAAA;AAKJ;AAJI;EACE,uBAAA;AAMN;;AADA;EACE,eAAA;EACA,YAAA;EACA,iBAAA;AAIF;;AADA;EACE,mBAAA;AAIF","sourcesContent":["ion-item {\r\n  --padding-end: 0px;\r\n  --inner-padding-end: 0px;\r\n  --padding-start: 0px;\r\n  --inner-padding-start: 0px;\r\n}\r\n\r\n.grid-content {\r\n  background-color: var(--ion-color-light-tint);\r\n  height: 100%;\r\n  width: 100%;\r\n  overflow-y: scroll;\r\n}\r\n\r\n.fullscreen {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\nion-card ion-card-header {\r\n  ion-card-title,\r\n  p {\r\n    margin: 0;\r\n  }\r\n}\r\nh1 {\r\n  font-size: 18px;\r\n}\r\nh2 {\r\n  font-size: 16px;\r\n}\r\nh3 {\r\n  font-weight: bold;\r\n}\r\n\r\nion-card-content ion-col {\r\n  padding: 0;\r\n}\r\n.modify-column {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.success-bar {\r\n  width: 100%;\r\n}\r\n\r\n.row-user {\r\n  margin-top: 1rem;\r\n  cursor: pointer;\r\n  &:hover {\r\n    background-color: var(--ion-color-light) !important;\r\n    color: white;\r\n    ion-chip {\r\n      color: white !important;\r\n    }\r\n  }\r\n}\r\n\r\n.badge-scoring {\r\n  font-size: 14px;\r\n  height: 30px;\r\n  line-height: 24px;\r\n}\r\n\r\nion-badge[color='default'] {\r\n  font-weight: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
