/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { TypeUserEnum, User, UserRol } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { IncofisaAuthService } from '@core/services/auth/incofisa-auth.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SplitPaneMenuService } from '@core/services/split-pane-menu/split-pane-menu.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import { environment } from '@environments/environment';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { HeaderUserPopoverComponent } from '../header-user-popover/header-user-popover.component';

@Component({
  selector: 'el-buen-inquilino-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  onlyLogo = false;

  @Output()
  logout = new EventEmitter();

  @Input()
  login = true;

  @Input() source: string;

  @Input() params: string;

  @Input() showLogout = true;

  @Input() title: string;

  @Input() isLanding = false;

  //Provisionalmente quitamos el cambio de lenguaje en produccion.
  isDisabledMultilanguage: boolean = environment.production;

  width: number;

  user: User;
  userRol: string;
  showPortfolioBtn = false;

  widthChangeSub: Subscription;
  showMenuBtnSub: Subscription;

  constructor(
    private authenticateService: AuthService,
    private navigationService: NavigationService,
    private incofisaAuthService: IncofisaAuthService,
    private popoverController: PopoverController,
    private router: Router,
    private splitPaneMenuService: SplitPaneMenuService,
    private windowMeasuresService: WindowMeasuresService
  ) {}

  ngOnInit(): void {
    if (this.isUser()) {
      this.user = this.authenticateService.user;
      if (this.user?.portfolioId) {
        this.userRol = 'Administrador';
      }
      if (this.user?.apiRol === UserRol.API_ADMIN) {
        this.userRol = 'Gestor';
      }
      if (this.user?.apiRol === UserRol.API_MEMBER) {
        this.userRol = 'Colaborador';
      }
    } else if (this.isIncofisaUser()) {
      this.user = this.incofisaAuthService.user;
    }

    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => {
        this.width = width;
        this.showButtonSplitPane();
      });

    this.showMenuBtnSub = this.splitPaneMenuService
      .getShowMenuBtn()
      .subscribe((val: boolean) => {
        if (val !== null) {
          this.showPortfolioBtn = val;
        }
      });
  }

  showButtonSplitPane(): void {
    if (this.width < 992) {
      this.showPortfolioBtn = false;
      this.splitPaneMenuService.setShowSplitPane();
    } else {
      this.showPortfolioBtn = true;
    }
  }

  clickLogout(): void {
    let pageTo: string;
    if (localStorage.getItem('loginPage')) {
      pageTo = localStorage.getItem('loginPage');
      localStorage.removeItem('loginPage');
    }

    this.authenticateService.user = null;
    this.incofisaAuthService.clearUser();
    this.navigationService.logout(null, pageTo || this.source);
    this.logout.emit();
  }

  getScreenInfo(): string {
    return (
      this.authenticateService?.user?.firstname +
      ' ' +
      this.authenticateService?.user?.surname1 +
      ' ' +
      this.authenticateService?.user?.surname2
    );
  }

  isUser(): boolean {
    return this.authenticateService?.isUserLogged();
  }

  isIncofisaUser(): boolean {
    return this.incofisaAuthService?.isIncofisaUser();
  }

  goHome(): void {
    if (this.authenticateService.isUserLogged()) {
      this.navigationService.goHome(this.authenticateService.user);
    } else {
      this.navigationService.goLoginPage();
    }
  }

  goLogin(): void {
    this.navigationService.goLoginPage();
  }

  clickLogo(): void {
    if (
      this.isUser() &&
      this.authenticateService.user.userType === TypeUserEnum.HOMEOWNER
    ) {
      if (this.checkUserSessionPayment()) {
        this.goHome();
      } else {
        this.navigationService.goToSubscriptionPage(
          this.authenticateService.user.id
        );
      }
    } else if (this.isIncofisaUser()) {
      this.navigationService.goToLandingBackoffice();
    } else {
      this.navigationService.goLanding();
    }
  }

  async showUserPopover(ev: any): Promise<void> {
    const isUser = this.isUser();
    const popover = await this.popoverController.create({
      component: HeaderUserPopoverComponent,
      event: ev,
      componentProps: {
        isUser
      }
    });
    await popover.present();

    const { data } = await popover.onWillDismiss();

    if (data) {
      switch (data.option) {
        case 'PROFILE': {
          const currentUrl = this.router.url.split('/');
          currentUrl.shift();
          currentUrl.pop();

          this.router.navigate([...currentUrl, 'profile']);
          break;
        }
        case 'HELP': {
          break;
        }
        case 'LOGOUT': {
          this.clickLogout();
          break;
        }
      }
    }
  }

  hideSplitPane(): void {
    this.splitPaneMenuService.setShowSplitPane();
  }

  ngOnDestroy(): void {
    this.widthChangeSub?.unsubscribe();
    this.showMenuBtnSub?.unsubscribe();
  }

  private checkUserSessionPayment(): boolean {
    const expirationDateSubscription = new Date(
      this.authenticateService.user.session.expirationDate
    );
    return (
      this.authenticateService.user.session &&
      this.authenticateService.user.session.paymentStatus &&
      expirationDateSubscription.getTime() > new Date().getTime()
    );
  }
}
