/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import {
  Auth,
  UserCredential,
  signInWithEmailAndPassword
} from '@angular/fire/auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { MessageBean } from '@shared/utils/message-bean.utils';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

import { UsersApiService } from '../../api-services/users-api/users-api.service';
import { Credentials, DecodedToken, TypeUserEnum, User } from '../../models';
import { LoadingService } from '../loading/loading.service';
import { MenuService } from '../menu/menu.service';
import { NavigationService } from '../navigation/navigation.service';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { StoreService } from '../store/store.service';

@Injectable()
export class IncofisaAuthService {
  user: User;
  credentials: Credentials;
  lastUrl: string | null;

  get token(): string | undefined {
    return this.storeService.getToken();
  }

  get isLoggedIn(): boolean {
    return !!this.token ? true : false;
  }

  constructor(
    private storeService: StoreService,
    private navigationService: NavigationService,
    private toastController: ToastController,
    private menuService: MenuService,
    private userService: UsersApiService,
    private snackBarService: SnackBarService,
    private loadingService: LoadingService,
    private auth: Auth
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const credentials = (this.credentials = this.storeService.getCredentials());
    if (!this.user?.id) {
      this.user = null;
    }
    if (credentials?.token && this.isIncofisaUser()) {
      if (!this.user) {
        const tokenDecoded = jwt_decode(credentials.token) as DecodedToken;
        const expiredDate: Date = new Date(tokenDecoded.exp * 1000);

        // Si el token ha caducado
        if (expiredDate < new Date()) {
          this.lastUrl = state.url;
          this.logout();
          return false;
        }

        return this.userService.getUser(tokenDecoded.user_id).then(
          (userServiceData: User) => {
            this.user = userServiceData;
            return this.checkUserRole(route);
          },
          () => {
            this.lastUrl = state.url;
            this.logout();
            return false;
          }
        );
      } else {
        // Se comprueban los roles
        return this.checkUserRole(route);
      }
    } else {
      this.lastUrl = state.url;
      // Si no está logado se redirige al login de backoffice
      this.navigationService.goToLoginBackoffice();
    }
  }

  login(email: string, password: string, userType: TypeUserEnum): void {
    this.snackBarService.hide();
    this.loadingService.presentLoading(null);
    if (this.isUserLogged()) {
      this.processLogin(this.user);
    } else {
      this.processLoginFirebase(email, password, userType);
    }
  }

  clearUser(): void {
    this.user = null;
    this.storeService.removeCredentials();
  }

  isIncofisaUser(): boolean {
    return (
      this.storeService.getCredentials().typeUser ===
        TypeUserEnum.INCOFISA_ADMINISTRATIVE ||
      this.storeService.getCredentials().typeUser === TypeUserEnum.CUSTOMER_CARE
    );
  }

  private processLogin(user: User): void {
    this.user = user;
    const credentials = this.storeService.getCredentials();
    credentials.typeUser = user.userType;
    this.storeService.setCredentials(credentials);
    this.loadingService.dismissLoading();

    if (this.lastUrl) {
      this.navigationService.go(this.lastUrl);
      this.lastUrl = null;
    } else {
      this.navigationService.goToLandingBackoffice();
    }
  }

  private processLoginFirebase(
    email: string,
    password: string,
    userType: TypeUserEnum
  ): void {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(
        (dataFirebaseLogin: UserCredential) => {
          this.processToken(dataFirebaseLogin, userType);
        },
        (error) => {
          this.processError(error.code);
        }
      )
      .catch((error) => {
        this.processError(error);
      });
  }

  private processToken(
    dataFirebaseLogin: UserCredential,
    userType: TypeUserEnum
  ): void {
    if (dataFirebaseLogin && dataFirebaseLogin.user) {
      this.auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          this.storeService.setToken(idToken);
          this.processUser(dataFirebaseLogin, userType);
        })
        .catch((error) => {
          this.processError(error);
        });
    } else {
      this.processError('error');
    }
  }

  private processError(error: string): void {
    this.user = null;
    this.storeService.removeCredentials();
    this.loadingService.dismissLoading();
    this.snackBarService.show(MessageBean.buildMessage(error), 'danger');
  }

  private processUser(
    dataFirebaseLogin: UserCredential,
    userType: TypeUserEnum
  ): void {
    this.userService.getUser(dataFirebaseLogin.user.uid).then(
      (userServiceData) => {
        if (userServiceData.userType === userType) {
          this.storeService.setTokenRefresh(
            dataFirebaseLogin.user.refreshToken
          );
          this.processLogin(userServiceData);
        } else {
          this.processError('error');
        }
      },
      (error) => {
        this.processError(error);
      }
    );
  }

  private isUserLogged(): boolean {
    return (
      this.user !== null &&
      this.user !== undefined &&
      this.user.id !== null &&
      this.user?.id !== undefined &&
      this.storeService.getCredentials()?.token !== null
    );
  }

  private checkUserRole(route: ActivatedRouteSnapshot): boolean {
    if (route?.data?.role) {
      const hasRole = this.user.backofficeRoles?.find(
        (br: string) => br === route.data.role
      );
      if (!hasRole) {
        // Si no tiene los roles necesarios, se redirige al login de backoffice
        this.presentToast(
          // eslint-disable-next-line max-len
          'No tienes los roles necesarios. Por favor, habla con el administrador.',
          'danger',
          5000
        );
        this.navigationService.goToLandingBackoffice();
        return false;
      }
    }
    this.menuService.generateBackofficeMenu(this.user.backofficeRoles);
    return true;
  }

  private async presentToast(
    message: string,
    color: string,
    duration: number
  ): Promise<void> {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color,
      duration
    });

    toast.present();
  }

  private logout(): void {
    this.user = null;
    this.storeService.removeCredentials();
    this.navigationService.goToLoginBackoffice();
  }
}
