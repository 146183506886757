/* eslint-disable @typescript-eslint/no-explicit-any */

import { PolicyIssue } from './insurance.model';

export interface HomeOwnerInsuranceFeeDto {
  [x: string]: any;
  userId: string;
  email: string;
  name: string;
  date: Date;
  bigtenant?: boolean;
  portfolioId?: string;
  apiId?: string;
  apiRol?: string;
  contractedPolicies: PolicyIssue[];
  ccc: string;
  pendingFeePocketPayment?: boolean;
}

export interface Policy {
  candidatureId: string;
  policyNumber: string;
  premium: number;
  commission: number;
  percentage: number;
  creationDate: Date;
  asset: string;
  paid: boolean;
  lastUpdated: Date;
  netPremium: number;
  commissionFee: number;
}

export interface ChangePolicyPaymentStatusDto {
  paid: boolean;
}
