export enum NotificationTypeEnum {
  GUARANTOR = 'GUARANTOR',
  COTENANT = 'COTENANT',
  UPLOAD_DOC = 'UPLOAD_DOC',
  CANDIDATURE = 'CANDIDATURE',
  INSURANCE_RC = 'INSURANCE_RC'
}

export enum NotificationStatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export class NotificationDto {
  id: string;
  creationDate: Date;
  type: NotificationTypeEnum;
  status: NotificationStatusEnum;
  read: boolean;
  link: string;
  vpp: boolean;
  // Datos del receptor
  recipientId: string;
  recipientFirstname: string;
  recipientSurname1: string;
  recipientSurname2: string;
  recipientPhone: string;
  recipientEmail: string;
  // Datos del remitente
  senderId: string;
  senderFirstname: string;
  senderSurname1: string;
  senderSurname2: string;
  senderEmail: string;
  candidatureId: string;
  address: string;
}

export class InsuranceTenantRequestDto {
  email: string;
  firstname: string;
  address: string;
}

export class UpdateNotification {
  read?: boolean;
  newStatus?: NotificationStatusEnum;
  candidatureId?: string;
}

export class CandidatureNotification {
  notificationId: string;
  candidatureId: string;
  tenantId: string;
  address: string;
}
