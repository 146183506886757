/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CandidatureNotification,
  CustomerCareTenantList,
  NotificationDto,
  NotificationStatusEnum,
  NotificationTypeEnum,
  UpdateNotification
} from '@core/models';
import { environment } from '@environments/environment';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-tenant-actions-modal',
  templateUrl: './tenant-actions-modal.component.html'
})
export class TenantActionsModalComponent implements OnInit {
  @Input() currentTenant: CustomerCareTenantList;

  tenantRegister = false;

  tenantActivate = true;

  tenantAccept = true;

  showErrorPanel = false;
  errorDescription: string;

  showInfoPanel = false;
  link: string;

  showCandidaturesPanel = false;
  candidaturesWithoutChecking: CandidatureNotification[] = [];

  constructor(
    private modalController: ModalController,
    private userService: UsersApiService,
    private notificationsService: NotificationsApiService,
    private candidaturesService: CandidaturesApiService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUserRegisterRequestByEmail(this.currentTenant.email)
      .subscribe((response) => {
        if (response) {
          this.tenantRegister = true;
          this.tenantActivate = false;
          this.tenantAccept = false;
          this.link = `https://${environment.domain}/login?id=${response.id}`;
        }
      });
  }

  onShowLoginLink(): void {
    this.showErrorPanel = false;
    this.showInfoPanel = true;
  }

  onShowActivatingLink(): void {
    this.showErrorPanel = false;
    this.userService
      .getUserActivateAccountLink(this.currentTenant.email)
      .subscribe({
        next: (res: any) => {
          this.link = res.link;
          this.showInfoPanel = true;
        },
        error: (error: HttpErrorResponse) =>
          this.onError(error, 'Esta cuenta ya se encuentra activa.')
      });
  }

  onShowAcceptedCandidatures(): void {
    this.showErrorPanel = false;
    this.userService
      .getUserByEmail(this.currentTenant.email)
      .subscribe((tenant) => {
        this.notificationsService
          .getNotifications(50, this.currentTenant.email)
          .subscribe({
            next: (notifications) => {
              const notAcceptedCandidatures = notifications?.elements?.filter(
                (notification) => {
                  return (
                    notification.type === NotificationTypeEnum.CANDIDATURE &&
                    !notification.read
                  );
                }
              );
              if (notAcceptedCandidatures?.length > 0) {
                notAcceptedCandidatures.forEach(
                  (notification: NotificationDto) => {
                    this.candidaturesWithoutChecking.push({
                      notificationId: notification.id,
                      candidatureId: notification.candidatureId,
                      address: notification.address,
                      tenantId: tenant[0].id
                    });
                  }
                );
                this.showCandidaturesPanel = true;
              } else {
                this.showInfoPanel = false;
                this.showErrorPanel = true;
                this.errorDescription =
                  'Sin candidaturas pendientes de aceptación.';
              }
            },
            error: (error) =>
              this.onError(error, 'Sin candidaturas pendientes de aceptación.')
          });
      });
  }

  forceAccept(candidature: CandidatureNotification): void {
    this.candidaturesService
      .updateUserStatus(
        candidature.candidatureId,
        candidature.tenantId,
        'ACCEPT'
      )
      .subscribe(() => {
        const param: UpdateNotification = new UpdateNotification();
        param.newStatus = NotificationStatusEnum.ACCEPTED;
        param.candidatureId = candidature.candidatureId;
        this.notificationsService
          .updateNotification(candidature.notificationId, param)
          .subscribe(() => {
            this.dismiss();
          });
        this.showCandidaturesPanel = false;
      });
  }

  copyToClipboard(data: string): void {
    const listener = (e: ClipboardEvent): void => {
      e.clipboardData.setData('text/plain', data);
      e.preventDefault();
      document.removeEventListener('copy', listener);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
  }

  dismiss(saving?): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  onError(error: HttpErrorResponse, message: string): void {
    if (error.status === 500) {
      this.errorDescription = message;
      this.showErrorPanel = true;
      this.showInfoPanel = false;
      this.showCandidaturesPanel = false;
      this.link = null;
    }
  }
}
