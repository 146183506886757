import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HistoricalDebts, Sinister } from '@core/models';
import { ModalController } from '@ionic/angular';

import { DisplayedColumns } from '../../models/update-sinister-modal.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-historical-debt-modal',
  templateUrl: './historical-debt-modal.component.html'
})
export class HistoricalDebtModalComponent implements OnInit {
  @Input({ required: true }) sinister: Sinister;

  displayedColumnsEnum = DisplayedColumns;
  displayedColumns: string[] = [
    DisplayedColumns.DATE,
    DisplayedColumns.TOTAL_DEBT
  ];
  dataSource: MatTableDataSource<HistoricalDebts> =
    new MatTableDataSource<HistoricalDebts>([]);

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.setDataSource();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  private setDataSource(): void {
    this.dataSource = new MatTableDataSource<HistoricalDebts>(
      copyObject(this.sinister.historicalDebts.reverse()) as HistoricalDebts[]
    );
  }
}
