import {
  ComercialNote,
  SinisterThread,
  TypeUserEnum,
  User
} from '@core/models';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';

export function copyObject(object: unknown): unknown {
  return JSON.parse(JSON.stringify(object));
}

export const tableSize: number[] = [25, 50, 100];

export function getWriterName(
  users: User[],
  userId: string,
  message: ComercialNote | SinisterThread
): string {
  const user = getUser(users, message.userId);
  if (isIncofiso(user)) {
    return 'Soporte EBI';
  } else {
    const loggedUser = getUser(users, userId);
    if (loggedUser?.id === message.userId) {
      return 'Yo';
    } else if (isIncofiso(loggedUser)) {
      return 'Cliente';
    } else {
      return `${user.firstname} ${user.surname1}`;
    }
  }
}

export function getUser(users: User[], userId: string): User {
  return users?.find((u: User) => u.id === userId);
}

function isIncofiso(user: User): boolean {
  return user?.userType === TypeUserEnum.INCOFISA_ADMINISTRATIVE;
}

export function compare(
  a: number | Date | string,
  b: number | Date | string,
  isAsc: boolean
): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function formatDateToService(date: Date): string | undefined {
  if (!date) {
    return undefined;
  }

  return format(date, 'dd-MM-yyyy');
}

export function isBetween(
  initDate: Date,
  endDate: Date,
  compareDate: Date
): boolean {
  return (
    (isAfter(compareDate, initDate) || isSameDay(compareDate, initDate)) &&
    (isBefore(compareDate, endDate) || isSameDay(compareDate, endDate))
  );
}

export function fileSizeOk(file: File): boolean {
  if (!!file) {
    const maxSize = 10 * 1024 * 1024;
    return file?.size <= maxSize;
  } else {
    return true;
  }
}

export function userFullName(user: User): string {
  if (!user) {
    return '';
  }
  let fullname = `${user.firstname} ${user.surname1}`;
  if (user.surname2) {
    fullname += ` ${user.surname2}`;
  }
  return fullname;
}
