/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  HomeOwnerInsuranceFeeDto,
  HomeownerCommissionDto,
  PolicyIssue,
  TypeUserEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { IncofisaAuthService } from '@core/services/auth/incofisa-auth.service';
import { AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-fee-pocket-banner',
  templateUrl: './fee-pocket-banner.component.html'
})
export class FeePocketBannerComponent implements OnInit, OnChanges {
  homeownerCommission: HomeownerCommissionDto;
  user: User;
  @Input() contractedPolicies: PolicyIssue[];
  @Input() changePaid: number;
  @Input() selectedHomeowner: HomeOwnerInsuranceFeeDto;

  constructor(
    private authenticationService: AuthService,
    private authIncofisaService: IncofisaAuthService,
    private candidatureService: CandidaturesApiService,
    private alertController: AlertController,
    private usersService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.user = this.authenticationService.user;
    if (!this.user) {
      this.user = this.authIncofisaService.user;
    }
    if (!this.contractedPolicies) {
      this.getInsurances();
    } else {
      this.calculateTotals();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.contractedPolicies?.currentValue?.length > 0 ||
      changes.changePaid?.currentValue > 0
    ) {
      this.calculateTotals();
    }
    if (!!changes.selectedHomeowner?.currentValue) {
      this.selectedHomeowner = changes.selectedHomeowner.currentValue;
    }
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/explicit-function-return-type
  async getInsurances() {
    let call: Observable<any>;
    if (this.user.portfolioId) {
      call = this.candidatureService.getContractedPoliciesResume(
        this.user.portfolioId
      );
    } else if (this.user.apiRol && this.user.apiRol === 'API_ADMIN') {
      call = this.candidatureService.getContractedPoliciesResume(
        null,
        this.user.apiId
      );
    } else {
      call = this.candidatureService.getContractedPoliciesResume(null, null);
    }

    call.pipe(first()).subscribe((resp) => {
      this.contractedPolicies = resp;
      this.contractedPolicies = this.contractedPolicies.filter(
        (policy) => policy.commissionFee && policy.commissionFee > 0
      );
      this.calculateTotals();
    });
  }

  calculateTotalProfit(contractedPolicies: PolicyIssue[]): number {
    let totalProfit = 0;
    contractedPolicies.forEach((policy: PolicyIssue) => {
      totalProfit += this.calculateComission(
        policy.netPremium,
        policy.commissionFee
      );
    });

    return totalProfit;
  }

  calculateInsurancesMonth(): number {
    const currentDate = moment(new Date());
    return this.contractedPolicies.filter((policy: PolicyIssue) => {
      const contractedDate = policy.creationDate;
      return (
        moment(contractedDate).year() === currentDate.year() &&
        moment(contractedDate).month() === currentDate.month()
      );
    })?.length;
  }

  calculatePendingProfit(): number {
    const pendingPolicies = this.contractedPolicies.filter(
      (policy: PolicyIssue) => !policy.paid
    );
    return this.calculateTotalProfit(pendingPolicies);
  }

  calculatePaidProfit(): number {
    const paidPolicies = this.contractedPolicies.filter(
      (policy: PolicyIssue) => policy.paid === true
    );
    return this.calculateTotalProfit(paidPolicies);
  }

  calculateComission(netPremium, commission): number {
    if (!commission) {
      return 0;
    } else {
      return netPremium * (commission / 100);
    }
  }

  requestPendingPaid(): void {
    this.presentPaymentInfoAlert();
  }

  closePendingPayment(): void {
    this.selectedHomeowner.pendingFeePocketPayment = false;
    this.usersService
      .updateFeePockePaymentStatus(this.selectedHomeowner.userId, false)
      .subscribe();
  }

  isRequestPendingPaidButtonVisible(): boolean {
    return (
      this.user?.userType === TypeUserEnum.HOMEOWNER &&
      this.contractedPolicies.filter((policy: PolicyIssue) => !policy.paid)
        .length > 0 &&
      !this.user?.pendingFeePocketPayment
    );
  }

  isClosePendingPaymentButtonVisible(): boolean {
    return (
      this.user?.userType === TypeUserEnum.INCOFISA_ADMINISTRATIVE &&
      this.contractedPolicies.filter((policy: PolicyIssue) => !policy.paid)
        .length === 0 &&
      this.selectedHomeowner?.pendingFeePocketPayment
    );
  }

  private async presentPaymentInfoAlert(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: 'Liquidación de monedero',
      message:
        'Si continúas, se procederá a la liquidación de la cantidad acumulada en tu monedero. <br/><br/>Procederemos al pago al final de este mes y recibirás tu cuantía entre los días 5 y 10 del siguiente.',
      buttons: [
        {
          text: 'Proceder con la liquidación',
          cssClass: 'danger',
          handler: (): void => {
            this.user.pendingFeePocketPayment = true;
            this.usersService
              .updateFeePockePaymentStatus(this.user.id, true)
              .subscribe();
          }
        },
        {
          text: 'Continuar acumulando',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  private calculateTotals(): void {
    this.homeownerCommission = new HomeownerCommissionDto();
    if (!this.contractedPolicies || this.contractedPolicies?.length === 0) {
      this.homeownerCommission.insurancesGlobal = 0;
      this.homeownerCommission.insurancesMonth = 0;
      this.homeownerCommission.totalProfit = 0;
      this.homeownerCommission.pendingProfit = 0;
      this.homeownerCommission.paidProfit = 0;
    } else {
      this.homeownerCommission.insurancesGlobal =
        this.contractedPolicies.length;
      this.homeownerCommission.totalProfit = this.calculateTotalProfit(
        this.contractedPolicies
      );
      this.homeownerCommission.insurancesMonth =
        this.calculateInsurancesMonth();
      this.homeownerCommission.pendingProfit = this.calculatePendingProfit();
      this.homeownerCommission.paidProfit = this.calculatePaidProfit();
    }
  }
}
