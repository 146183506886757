/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Directive } from '@angular/core';
import {
  Validator,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS
} from '@angular/forms';

@Directive({
  selector: '[elBuenInquilinoDni]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DniDirective, multi: true }
  ]
})
export class DniDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    if (control.value) {
      const str = control.value.toString().toUpperCase();

      if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return { dni: true };
      }

      const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

      const letter = str.substr(-1);
      const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

      if (validChars.charAt(charIndex) === letter) {
        return null;
      } else {
        return { dni: true };
      }
    } else {
      return null;
    }
  }
}
