import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import {
  BankAccountValidation,
  BankAccountValidationStatus
} from '../../models/bbva-table.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-bbva-table',
  templateUrl: './bbva-table.component.html'
})
export class BbvaTableComponent implements OnChanges {
  @Input({ required: true }) data: BankAccountValidation[] = [];

  // TABLA
  displayedColumns = [
    'creationDate',
    'tenantName',
    'accountNumber',
    'response'
  ];
  dataSource: MatTableDataSource<BankAccountValidation> =
    new MatTableDataSource<BankAccountValidation>([]);
  // END TABLA
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.setDataSource();
    }
  }

  setDataSource(): void {
    this.dataSource = new MatTableDataSource<BankAccountValidation>(
      copyObject(this.data) as BankAccountValidation[]
    );
  }

  hasPendingValidations(): boolean {
    return this.dataSource.data.some(
      (item: BankAccountValidation) =>
        item.response === BankAccountValidationStatus.PENDING
    );
  }
}
