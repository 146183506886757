import { Pipe, PipeTransform } from '@angular/core';
import {
  Candidature,
  CandidatureStatusEnum,
  TenantCandidature
} from '@core/models';

@Pipe({
  name: 'candidatureShowActionsCol'
})
export class CandidatureShowActionsColPipe implements PipeTransform {
  transform(candidature: Candidature, tenant: TenantCandidature): unknown {
    return (
      candidature.candidatureStatusEnum !==
        CandidatureStatusEnum.WITHOUT_CHECKING &&
      candidature.candidatureStatusEnum !== CandidatureStatusEnum.REJECT &&
      this.checkTenant(tenant)
    );
  }

  checkTenant(tenant: TenantCandidature): boolean {
    if (tenant && tenant.user && tenant.user.id) {
      return true;
    } else {
      return false;
    }
  }
}
