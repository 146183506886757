import { BuildingDto } from './asset.model';

export interface KeyValuePair {
  key: string;
  value: number;
}

interface BuildingGeneralInfo {
  totalAssets: number;
  availableAssets: number;
  occupationPercentage: number;
  totalRents: number;
  activatedRents: number;
  totalInsuredRents: number;
}

interface Totalizer {
  total: number;
  values: KeyValuePair[];
}

export interface BuildingStatus {
  generalInfo: BuildingGeneralInfo;
  reports: Totalizer;
  insurances: Totalizer;
  occupation: Totalizer;
}

export interface ModalBuildingReportData {
  buildingStatus: BuildingStatus;
  building: BuildingDto;
}
