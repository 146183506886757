import { Pipe, PipeTransform } from '@angular/core';
import {
  Candidature,
  TenantCandidature,
  UserAnalysisStatusEnum
} from '@core/models';

@Pipe({
  name: 'checkRequestCandidature'
})
export class CheckRequestCandidaturePipe implements PipeTransform {
  transform(candidature: Candidature): boolean {
    // Si hay score no necesitamos mas comprobaciones y habilitamos botón
    if (candidature && candidature.score) return false;
    if (candidature && candidature.tenantCandidatureList.length > 0) {
      return !candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) =>
          tenant.status === UserAnalysisStatusEnum.REPORTED_ANALYSIS
      );
    } else {
      return true;
    }
  }
}
