import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { AssetDto, CustomModalButtonRole, User } from '@core/models';
import { FiltersService } from '@core/services/filters/filters.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import { tableSize } from '@shared/utils/global.utils';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-link-asset',
  templateUrl: './link-asset.component.html'
})
export class LinkAssetComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Input() selectedAsset: AssetDto;

  // TABLA
  columns: string[] = ['address', 'portal', 'floor', 'door', 'city'];
  dataSource = new MatTableDataSource<AssetDto>([]);
  @ViewChild('paginator') paginator: MatPaginator;
  tableSize = tableSize;
  // END TABLA
  originalAssets: AssetDto[] = [];

  $destroy = new Subject<void>();

  get disabledSelectButton(): boolean {
    if (!this.selectedAsset) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private modalController: ModalController,
    private assetService: AssetsApiService,
    private loadingService: LoadingService,
    private filtersService: FiltersService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.getAssets();
  }

  dismiss(role: CustomModalButtonRole, asset?: AssetDto): void {
    this.modalController.dismiss(asset, role);
  }

  close(): void {
    this.dismiss(CustomModalButtonRole.CANCEL);
  }

  selectAsset(element: AssetDto): void {
    if (this.selectedAsset?.id === element.id) {
      this.selectedAsset = null;
    } else {
      this.selectedAsset = element;
    }
  }

  select(): void {
    this.dismiss(CustomModalButtonRole.ACCEPT, this.selectedAsset);
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private async getAssets(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.assetService
      .getAssets(
        this.user.id,
        'OPEN',
        null,
        this.user.portfolioId,
        this.user.apiId
      )
      .pipe(first())
      .subscribe((resp: AssetDto[]) => this.onSuccessGetAssets(resp));
  }

  private async onSuccessGetAssets(resp: AssetDto[]): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.originalAssets = this.utilsService.orderAssets(resp);
    this.subscribeToFilters();
    this.filtersService.setOriginalItems(this.originalAssets);
  }

  private subscribeToFilters(): void {
    this.filtersService
      .getItems()
      .pipe(takeUntil(this.$destroy))
      .subscribe((resp: AssetDto[]) => {
        this.dataSource = new MatTableDataSource(resp);
        this.dataSource.paginator = this.paginator;
      });
  }
}
