import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'viveDocChipColor'
})
export class ViveDocChipColorPipe implements PipeTransform {
  transform(value: unknown): 'success' | 'danger' {
    return !!value ? 'success' : 'danger';
  }
}
