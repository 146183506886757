import { Pipe, PipeTransform } from '@angular/core';
import {
  Candidature,
  CandidatureStatusEnum,
  TenantCandidature
} from '@app/modules/core/models';

@Pipe({
  name: 'tenantCandidatureShowSelectOption'
})
export class TenantCandidatureShowSelectOptionPipe implements PipeTransform {
  transform(candidature: Candidature, status: CandidatureStatusEnum): boolean {
    switch (status) {
      case CandidatureStatusEnum.WITHOUT_CHECKING:
        return (
          (candidature.candidatureStatusEnum ===
            CandidatureStatusEnum.WITHOUT_CHECKING ||
            candidature.candidatureStatusEnum ===
              CandidatureStatusEnum.PENDING) &&
          this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );

      case CandidatureStatusEnum.PENDING:
        return (
          candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
          !this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );

      case CandidatureStatusEnum.PRE_SELECT:
        return (
          (candidature.candidatureStatusEnum ===
            CandidatureStatusEnum.PENDING ||
            candidature.candidatureStatusEnum ===
              CandidatureStatusEnum.PRE_SELECT) &&
          !this.areThereWithoutChekingTenants(candidature) &&
          candidature.tenantCandidatureList.length > 0
        );

      case CandidatureStatusEnum.SELECT:
        return (
          candidature.candidatureStatusEnum ===
            CandidatureStatusEnum.PRE_SELECT &&
          candidature.tenantCandidatureList.length > 0
        );
    }
  }

  private areThereWithoutChekingTenants(candidature: Candidature): boolean {
    if (
      this.areThereRejectTenants(candidature) ||
      candidature.tenantCandidatureList.some(
        (tenant: TenantCandidature) =>
          tenant.userCandidatureStatusDtoEnum ===
          CandidatureStatusEnum.WITHOUT_CHECKING
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  private areThereRejectTenants(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) =>
        tenant.userCandidatureStatusDtoEnum === CandidatureStatusEnum.REJECT
    );
  }
}
