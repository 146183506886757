import { Pipe, PipeTransform } from '@angular/core';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';

@Pipe({
  name: 'homeInsurancesStartDateHeader'
})
export class HomeInsurancesStartDateHeaderPipe implements PipeTransform {
  transform(type: ChildRoutesEnum): unknown {
    if (type === ChildRoutesEnum.ACTIVE) {
      return 'Fecha inicio';
    } else {
      return 'Fecha solicitud';
    }
  }
}
