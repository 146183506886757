/* eslint-disable @typescript-eslint/no-explicit-any */
export class DocumentDTO {
  id?: string;
  typeDocument:
    | TypeDocumentEnum
    | ProfileDocumentEnum
    | BillDocumentsEnum
    | TypeDocumentToCandidatureEnum;
  name: string;
  type?: TypeDocumentEnum;
  validated?: boolean;
  file: any;
  taxBase?: string;
  dateYear?: Date | string;
  netAmount?: string;
  date?: Date | string;
  expirationDate?: Date | string;
  birthDate?: Date | string;
  tenantId?: string;
  extension?: string;
  selected?: boolean;
  blob?: any;
  totalAmount?: string;
  model?: string;
  sourceId?: string;
  creationDate?: Date;
  year?: Date | string;
  requestAnalysisId?: string;
  deleted?: boolean;
  url?: string;
  inputStream?: string;
  metadata?: {
    internalReference?: string;
    description?: string;
    type?: TypeDocumentToCandidatureEnum;
  };
}

export class FileDocumentDTO {
  url: string;
}

export class DocumentIncidenceDTO {
  extension: string;
  inputStream: string;
  url: string;
}

export class DocumentValidationDTO {
  document: TypeDocumentEnum;
  optional: boolean;
  minCount: number;
  maxCount: number;
  neededCount: number;
  status: DocumentRequiredSnapshotEnum = DocumentRequiredSnapshotEnum.KO;
}

export class DocumentRequiredSnapshotDTO {
  document: TypeDocumentEnum;
  status: DocumentRequiredSnapshotEnum;
  minCount?: number;
}

export enum DocumentRequiredSnapshotEnum {
  OK = 'OK',
  KO = 'KO'
}

export enum TypeDocumentEnum {
  DNI = 'DNI',
  PAYSLIP = 'PAYSLIP',
  INCOME_TAX_DECLARATION = 'INCOME_TAX_DECLARATION',
  OTHER_DOCUMENT = 'OTHER_DOCUMENT',
  PENSIONER_RECOGNITION = 'PENSIONER_RECOGNITION',
  IS200_MODEL = 'IS200_MODEL',
  IVA_QUARTER_MODEL = 'IVA_QUARTER_MODEL',
  WORK_LIFE = 'WORK_LIFE',
  MODEL_130 = 'MODEL_130',
  SS_CERTIFICATE = 'SS_CERTIFICATE',
  AEAT_CERTIFICATE = 'AEAT_CERTIFICATE',
  PYG_QUARTER = 'PYG_QUARTER',
  RENTAL_CONTRACT = 'RENTAL_CONTRACT',
  WORK_CONTRACT = 'WORK_CONTRACT',
  BANK_STATEMENT = 'BANK_STATEMENT',
  REMOTE_JOB_CERTIFICATE = 'REMOTE_JOB_CERTIFICATE',
  INVOICE_PAYMENT_CONFIRMATION = 'INVOICE_PAYMENT_CONFIRMATION',
  INVOICE_RECORD_BOOK = 'INVOICE_RECORD_BOOK'
}

export enum TypeDocumentToCandidatureEnum {
  RENT_CONTRACT = 'RENT_CONTRACT',
  SECURITY_DEPOSIT = 'SECURITY_DEPOSIT',
  ACCOUNT_OWNERSHIP = 'ACCOUNT_OWNERSHIP',
  SEPA_MANDATE = 'SEPA_MANDATE',
  ADDITIONAL_GUARANTEES = 'ADDITIONAL_GUARANTEES',
  PAYMENT_RECEIPT = 'PAYMENT_RECEIPT',
  OTHERS = 'OTHERS'
}

export enum ProfileDocumentEnum {
  PROFILE_PHOTO = 'PROFILE_PHOTO'
}

export enum BillDocumentsEnum {
  INSURANCE = 'INSURANCE',
  SUPPLY = 'SUPPLY'
}

export class LastTwoPayslipMonths {
  ultimatePayslip: string;
  penultimatePayslip: string;
}

export class Month {
  value: string;
  text: string;
}
export class Quarter {
  value: string;
  text: string;
}

export interface AttachDocument {
  creationDate: string | Date;
  name: string;
  fileId: string;
}
