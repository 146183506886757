import { Pipe, PipeTransform } from '@angular/core';
import {
  ApiDto,
  AssetDto,
  AssetStatus,
  LabelDto,
  User,
  UserRol
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';

import {
  AssetItemFunction,
  AssetItemPipeArgs
} from '../models/asset-pipes.model';
import { getAssetColor } from '../utils/asset.utils';

@Pipe({
  name: 'assetItemFunctions'
})
export class AssetItemFunctionsPipe implements PipeTransform {
  private labels: LabelDto[] = [];
  private usersApi: ApiDto[] = [];
  private user: User;
  private openedClosedStatus: AssetStatus;
  private isBuildingPage = false;

  constructor(
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  transform(
    asset: AssetDto,
    {
      itemFunction,
      labels,
      usersApi,
      user,
      openedClosedStatus,
      isBuildingPage
    }: AssetItemPipeArgs
  ):
    | string
    | boolean
    | number
    | {
        [klass: string]: unknown;
      } {
    this.labels = labels || [];
    this.usersApi = usersApi || [];
    this.user = user || null;
    this.openedClosedStatus = openedClosedStatus || null;
    this.isBuildingPage = isBuildingPage || false;

    switch (itemFunction) {
      case AssetItemFunction.SHOW_LAST_UPDATED_BADGE:
        return this.showLastUpdatedBadge(asset);
      case AssetItemFunction.LAST_UPDATED_TEXT:
        return this.getLastUpdatedText(asset);
      case AssetItemFunction.LAST_UPDATED_TEXT_FORMATTED:
        return this.getLastUpdatedTextFormatted(asset);
      case AssetItemFunction.CLOSED_TEXT:
        return this.getClosedText(asset);
      case AssetItemFunction.CLOSED_TEXT_FORMATTED:
        return this.getClosedTextFormatted(asset);
      case AssetItemFunction.SHOW_RC_INSURANCE_CHIP:
        return this.showRcInsuranceChip(asset);
      case AssetItemFunction.ASSOCIATED_CANDIDATURES_LENGTH:
        return this.getAssociatedCandidaturesLength(asset);
      case AssetItemFunction.SHOW_DOWNLOABLE_REPORTS:
        return this.showDownloableReports(asset);
      case AssetItemFunction.DOWNLOABLE_REPORTS_ICON:
        return this.downloableReportsIcon(asset);
      case AssetItemFunction.SHOW_INCIDENCES:
        return this.showIncidences(asset);
      case AssetItemFunction.SHOW_RENTED_CHIP:
        return this.showRentedChip(asset);
      case AssetItemFunction.SHOW_NO_ASSET_LABEL_CHIP:
        return this.showNoAssetLabelChip(asset);
      case AssetItemFunction.SHOW_ASSET_LABEL_CHIP:
        return this.showAssetLabelChip(asset);
      case AssetItemFunction.ASSET_LABEL_CHIP_ICON_STYLE:
        return this.assetLabelChipIconStyle(asset);
      case AssetItemFunction.ASSET_LABEL_CHIP_NAME:
        return this.assetLabelChipName(asset);
      case AssetItemFunction.API_NAME:
        return this.getApiName(asset);
      case AssetItemFunction.EDITION_PERMISSION:
        return this.editionPermissions(asset);
    }
  }

  private showLastUpdatedBadge(asset: AssetDto): boolean {
    return !!asset && !!asset.lastUpdated;
  }

  private getLastUpdatedText(asset: AssetDto): string {
    const lastUpdated = this.getLastUpdatedTextFormatted(asset);
    return this.translate.instant(
      'pages.profile.home-owner.assets.last_update',
      {
        lastUpdated
      }
    ) as string;
  }

  private getLastUpdatedTextFormatted(asset: AssetDto): string {
    const date = asset.lastUpdated ? asset.lastUpdated : asset.creationDate;
    return this.formatDate(new Date(date));
  }

  private getClosedText(asset: AssetDto): string {
    const closed = format(new Date(asset.rentedDate), 'dd/MM/yy');
    return this.translate.instant(
      'pages.profile.home-owner.assets.closed_date',
      {
        closed
      }
    ) as string;
  }

  private getClosedTextFormatted(asset: AssetDto): string {
    return this.formatDate(new Date(asset.rentedDate));
  }

  private formatDate(date: Date, formatDate: string = 'dd/MM/yy'): string {
    return format(date, formatDate);
  }

  private showRcInsuranceChip(asset: AssetDto): boolean {
    return asset?.status === 'RENTED' && asset?.hasTenantPolicyNumber !== null;
  }

  private getAssociatedCandidaturesLength(asset: AssetDto): number {
    return asset?.associatedCandidatures?.length || 0;
  }

  private showDownloableReports(asset: AssetDto): boolean {
    return asset?.downloadableReports > 0;
  }

  private downloableReportsIcon(asset: AssetDto): string {
    if (asset?.downloadableReports > 1) {
      return 'documents-outline';
    } else {
      return 'document-outline';
    }
  }

  private showIncidences(asset: AssetDto): boolean {
    if (this.isBuildingPage && this.openedClosedStatus === AssetStatus.RENTED) {
      return false;
    }
    return asset?.incidences?.length > 0;
  }

  private showRentedChip(asset: AssetDto): boolean {
    return asset?.status === 'RENTED' && asset?.hasPolicyNumber !== null;
  }

  private showNoAssetLabelChip(asset: AssetDto): boolean {
    return !asset?.labelId || (asset?.labelId && asset?.labelId < 0);
  }

  private showAssetLabelChip(asset: AssetDto): boolean {
    return asset?.labelId && asset?.labelId >= 0;
  }

  private assetLabelChipIconStyle(asset: AssetDto): {
    [klass: string]: unknown;
  } {
    return {
      color: getAssetColor(asset, false, this.labels, this.utilsService)
    };
  }

  private assetLabelChipName(asset: AssetDto): string {
    const selectedLabel = this.labels.find(
      (label: LabelDto) => label.id === asset.labelId
    );
    return selectedLabel !== null && selectedLabel !== undefined
      ? selectedLabel.name
      : (this.translate.instant(
          'pages.profile.home-owner.assets.no_label'
        ) as string);
  }

  private getApiName(asset: AssetDto): string {
    const apiId = asset.apiId;
    let chipApi: string;
    if (apiId) {
      chipApi = this.filterApiById(apiId)?.name;
    }
    return chipApi ? chipApi : 'Propia';
  }

  private filterApiById(apiId: string): ApiDto {
    return this.usersApi.find((api: ApiDto) => api.id === apiId);
  }

  private editionPermissions(asset: AssetDto): boolean {
    if (
      this.user.apiRol === UserRol.API_ADMIN &&
      asset.userId === this.user.id
    ) {
      return true;
    }

    if (asset.buildingId && !this.user.portfolioId) {
      return false;
    }
    return true;
  }
}
