import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { PageDto } from '../../models';
import {
  AnnonRecommendation,
  CreateRecommendation,
  Recommendation,
  ReqRecommendation
} from '../../models/recommendation.model';

@Injectable()
export class RecommendationsApiService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.recommendations;
  }

  getRecommendations(userCode: string): Observable<PageDto<Recommendation>> {
    return this.http.get<PageDto<Recommendation>>(
      `${this.url}/recommendations`,
      {
        params: new HttpParams()
          .append('numElements', '5')
          .append('userTargetCode', userCode)
      }
    );
  }

  registerRecommendation(
    recommendation: CreateRecommendation
  ): Observable<unknown> {
    return this.http.post(`${this.url}/recommendations`, recommendation);
  }

  registerAnonRecommendation(
    recommendation: AnnonRecommendation
  ): Observable<unknown> {
    return this.http.post(
      `${this.url}/anonymous-recommendations`,
      recommendation
    );
  }

  createRequestRecommendation(email: string): Observable<ReqRecommendation> {
    return this.http.post<ReqRecommendation>(
      `${this.url}/requests-recommendations`,
      { email }
    );
  }

  getRequestRecommendation(reqId: string): Observable<ReqRecommendation> {
    return this.http.get<ReqRecommendation>(
      `${this.url}/requests-recommendations/${reqId}`
    );
  }
}
