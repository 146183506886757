import { Pipe, PipeTransform } from '@angular/core';
import { Sinister } from '@core/models';

@Pipe({
  name: 'sinisterType'
})
export class SinisterTypePipe implements PipeTransform {
  transform(sinisters: Sinister): string {
    if (sinisters.isCancellation) {
      return 'Cancelación';
    } else {
      return 'Siniestro';
    }
  }
}
