import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomModalButtonRole } from '@core/models';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { ModalController } from '@ionic/angular';
import {
  InputInputEventDetail,
  IonInputCustomEvent,
  OverlayEventDetail
} from '@ionic/core';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

import { PhoneFlagModalComponent } from '../phone-flag-modal/phone-flag-modal.component';

@Component({
  selector: 'el-buen-inquilino-phone-input-ngmodel',
  templateUrl: './phone-input-ngmodel.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputNgmodelComponent),
      multi: true
    }
  ]
})
export class PhoneInputNgmodelComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @Input() phoneControlClasses: string[] = [];
  @Input() showEndPhoneIcon = false;
  @Input() label: string;
  @HostBinding('class') className = 'w-100';
  @ViewChild('input', { read: ElementRef }) input: ElementRef;
  country: CountryCode = 'ES';
  options: MaskitoOptions;
  placeholder: string;
  validationErrorMessages = appValidators.validationErrorMessages;
  disabled = false;

  @Output() changeCountryEvent = new EventEmitter<CountryCode>();
  @Output() focusoutEvent = new EventEmitter<void>();

  get flag(): string {
    return this.country.toLowerCase();
  }

  constructor(
    private phoneMaskService: PhoneMaskService,
    private _changeDetectorRef: ChangeDetectorRef,
    private modalController: ModalController
  ) {}

  readonly predicate: MaskitoElementPredicate = (
    element: HTMLIonInputElement
  ) => element.getInputElement();
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    this.setPhoneMaskOptionsAndPlaceHolder();
  }

  ngAfterViewInit(): void {
    // this.phoneControl = this.controlDir.control as FormControl<string>;
    this._changeDetectorRef.detectChanges();
  }

  setPhoneMaskOptionsAndPlaceHolder(): void {
    this.options = this.phoneMaskService.getPhoneMaskOptions(this.country);
    this.placeholder = this.phoneMaskService.getPhonePlaceholder(this.country);
  }

  onInput(event: IonInputCustomEvent<InputInputEventDetail>): void {
    const value = event.detail.value;
    this.onTouched();
    this.onChange(value);
  }

  writeValue(value: string): void {
    if (!!this.input) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.input.nativeElement.value = value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocusout(): void {
    this.focusoutEvent.emit();
  }

  async showPhoneFlagModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: PhoneFlagModalComponent,
      showBackdrop: true,
      backdropDismiss: true
    });

    modal.onWillDismiss().then((resp: OverlayEventDetail<CountryCode>) => {
      if (resp.role === (CustomModalButtonRole.ACCEPT as string)) {
        this.changeLang(resp.data);
      }
    });
    modal.present();
  }

  changeLang(value: CountryCode): void {
    if (!!this.input) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.input.nativeElement.value = null;
    }
    this.onChange(null);
    this.country = value;
    this.changeCountryEvent.emit(this.country);
    this._changeDetectorRef.detectChanges();
    this.setPhoneMaskOptionsAndPlaceHolder();
  }
}
