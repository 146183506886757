import { User } from './users.model';

export enum StatusContactEnum {
  PENDING = 'PENDING',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  EXPIRE = 'EXPIRE'
}

export class ContactAssetDto {
  street: string;
  number: string;
  portal: string;
  floor: string;
  door: string;
  postalCode: string;
  town: string;
  province: string;
  link: string;
  id?: string;
  rentalPrice: number;
  status: string;
}

export class CreateContactUserDto {
  emailTarget: string;
  assetData: ContactAssetDto;
  linkBase: string;

  clean(): void {
    this.emailTarget = null;
    if (this.assetData) {
      this.assetData.street = null;
      this.assetData.number = null;
      this.assetData.portal = null;
      this.assetData.floor = null;
      this.assetData.door = null;
      this.assetData.postalCode = null;
      this.assetData.town = null;
      this.assetData.province = null;
      this.assetData.link = null;
      this.assetData.rentalPrice = null;
    }
    this.linkBase = null;
  }
}

export interface ContactDto {
  id: string;
  date: Date;
  userAccept: User;
  userRequest: User;
  emailTarget: string;
  statusContact: StatusContactEnum;
  assetData: ContactAssetDto;
}

export class UpdateStatusContactDto {
  statusContact: StatusContactEnum;
}
