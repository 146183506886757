import { Component, Input, OnInit } from '@angular/core';
import { CandidaturesApiService } from '@app/modules/core/api-services/candidatures-api/candidatures-api.service';
import { Candidature, PolicyIssue, User } from '@app/modules/core/models';
import { AuthService } from '@app/modules/core/services/auth/auth.service';
import { LoadingService } from '@app/modules/core/services/loading/loading.service';
import { finalize, first } from 'rxjs';

import { ChildRoutesEnum } from '../../models/insurance-page.model';

@Component({
  selector: 'el-buen-inquilino-insurances-candidature-list',
  templateUrl: './insurances-candidature-list.component.html'
})
export class InsurancesCandidatureListComponent implements OnInit {
  @Input() candidature: Candidature;

  type = ChildRoutesEnum.ACTIVE;
  insurances: PolicyIssue[] = [];
  user: User;

  constructor(
    private candidatureService: CandidaturesApiService,
    private authService: AuthService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.getInsurancesByCandidature();
    this.setUser();
  }

  private getInsurancesByCandidature(): void {
    this.loadingService.presentLoading('Cargando pólizas...');

    this.candidatureService
      .getContractedPolicyByCandidature(this.candidature.id)
      .pipe(
        first(),
        finalize(() => this.loadingService.dismissLoading())
      )
      .subscribe({
        next: (response: PolicyIssue[]) => (this.insurances = response),
        error: () => this.loadingService.dismissLoading()
      });
  }

  private setUser(): void {
    this.user = this.authService.user;
  }
}
