/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import {
  ApiDto,
  AssetDto,
  BuildingDto,
  CreateAssetFromBuildingDto,
  LabelDto,
  LocationDto,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { ContactNewApiComponent } from '../contact-new-api/contact-new-api.component';

@Component({
  selector: 'el-buen-inquilino-add-asset',
  templateUrl: './add-asset.component.html'
})
export class AddAssetComponent implements OnInit {
  @Input() asset: AssetDto = new AssetDto();
  @Input() editMode: boolean;
  @Input() labels: LabelDto[];
  @ViewChild('assetForm') ngForm: NgForm;

  modalTitle: string;
  // For Google Maps
  // eslint-disable-next-line @typescript-eslint/naming-convention
  GoogleGeoCode: any;

  // Campo dirección autocompletar
  autocomplete: { input: string };
  autocompleteItems: any[];
  formVisible = false;
  newLabelName = '';
  selectedLabel: LabelDto;

  // Mapa
  latitude: number;
  longitude: number;
  coords: { lat: number; lng: number } = { lat: null, lng: null };

  private initialValueHomeownerReference: string;

  // Building
  @Input() building: BuildingDto;
  @Input() buildingAddress: string;
  @Input() userApis: ApiDto[] = [];
  buildingForm: UntypedFormGroup;
  useAgent = true;
  apis: ApiDto[] = [];

  @Input() user: User;
  @Input() apiId: string;

  get disableHomeownerReferenceInput(): boolean {
    return !!this.initialValueHomeownerReference ? true : false || false;
  }

  get showHomeownerReferenceInputMsg(): boolean {
    return (
      !this.editMode || (this.editMode && !this.initialValueHomeownerReference)
    );
  }

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private assetService: AssetsApiService,
    private authenticationService: AuthService,
    private toastController: ToastController,
    private cdr: ChangeDetectorRef,
    public popoverController: PopoverController,
    private utilsService: UtilsService
  ) {
    this.GoogleGeoCode = new google.maps.Geocoder();
    this.autocomplete = { input: '' };
    this.autocompleteItems = [];
  }

  ngOnInit(): void {
    // Si viene por flujo de building
    if (this.asset.buildingId || this.building) {
      if (!this.building) {
        this.assetService
          .getBuildingById(this.asset.buildingId)
          .pipe(first())
          .subscribe((building) => {
            this.building = building;
            this.buildingAddress = `${this.building.street}, ${this.building.number}, ${this.building.postalCode}, ${this.building.town}`;
            this.fillBuildingApis();
          });
      } else {
        this.fillBuildingApis();
      }

      this.formVisible = true;
      if (this.editMode) {
        this.modalTitle = this.translateService.instant(
          'pages.profile.home-owner.assets.add-assets.edit_asset'
        );
      } else {
        this.modalTitle = this.translateService.instant(
          'pages.profile.home-owner.assets.add-assets.add_asset'
        );
      }
    } else {
      // Si viene por flujo de pantalla de asset normal
      if (this.editMode) {
        this.modalTitle = this.translateService.instant(
          'pages.profile.home-owner.assets.add-assets.edit_asset'
        );
        this.formVisible = true;
        this.coords = {
          lat: this.asset.location.latitude,
          lng: this.asset.location.longitude
        };
        this.initialValueHomeownerReference = this.asset.homeownerReference;
      } else {
        this.modalTitle = this.translateService.instant(
          'pages.profile.home-owner.assets.add-assets.add_asset'
        );
        this.formVisible = false;
      }
    }
  }

  fillBuildingApis(): void {
    this.buildingForm = new UntypedFormGroup({
      address: new UntypedFormControl({
        value: this.buildingAddress,
        disabled: true
      }),
      portal: new UntypedFormControl(this.asset?.portal || null, [
        Validators.required
      ]),
      stairs: new UntypedFormControl(this.asset?.stairs || null),
      floor: new UntypedFormControl(this.asset?.floor || null, [
        Validators.required
      ]),
      door: new UntypedFormControl(this.asset?.door || null),
      rentalPrice: new UntypedFormControl(this.asset?.rentalPrice || null, [
        Validators.required
      ]),
      link: new UntypedFormControl(this.asset?.link || null),
      selectedApiId: new UntypedFormControl(this.asset.apiId || null, [
        this.useAgent ? Validators.required : null
      ]),
      homeownerReference: new UntypedFormControl(
        {
          value: this.asset?.homeownerReference || null,
          disabled: !!this.asset?.homeownerReference
        },
        Validators.required
      )
    });

    if (this.editMode && !!this.asset?.homeownerReference) {
      this.initialValueHomeownerReference = this.asset?.homeownerReference;
    }

    this.building.apisId?.forEach((id) => {
      const api = this.userApis.find((userApi) => userApi.id === id);
      this.apis.push(api);
    });
    this.coords = {
      lat: this.building.location.latitude,
      lng: this.building.location.longitude
    };
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  updateAddress(event): void {
    if (event && event.address_components.length > 0) {
      if (event.geometry.location !== null) {
        // Se guardan en el objeto asset las coordenadas de la dirección
        this.latitude = event.geometry.location.lat();
        this.longitude = event.geometry.location.lng();
        this.asset.location = new LocationDto();
        this.asset.location.latitude = this.latitude;
        this.asset.location.longitude = this.longitude;
      }
      event.address_components.forEach((component) => {
        if (component.types.includes('route')) {
          let street: string;
          if (component.short_name.includes('/')) {
            street = component.short_name.replace('/', '.');
          } else if (component.short_name.includes('Carrer')) {
            street = component.short_name.replace('Carrer', 'C.');
          } else {
            street = component.short_name;
          }
          this.asset.street = street;
        }
        if (component.types.includes('street_number')) {
          this.asset.number = component.long_name;
        }
        if (component.types.includes('locality')) {
          this.asset.town = component.long_name;
        }
        if (component.types.includes('administrative_area_level_2')) {
          this.asset.province = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          this.asset.postalCode = component.long_name;
        }
      });
      this.formVisible = true;
      this.cdr.detectChanges();
    }
  }

  saveAsset(): void {
    // Crea assets para un edificio
    if (this.building) {
      if (this.buildingForm.invalid) {
        this.utilsService.showFormErrors(this.buildingForm);
        return;
      }

      let selectedApiId: string = null;
      let apiAdminId: string = null;
      if (this.useAgent) {
        selectedApiId = this.buildingForm.get('selectedApiId').value;
        apiAdminId = this.apis.find((api) => api.id === selectedApiId)?.adminId;
      }

      delete this.buildingForm.value.selectedApiId;
      const newAsset: CreateAssetFromBuildingDto = {
        ...this.buildingForm.value
      };

      if (this.asset && this.asset.id) {
        this.asset.door = newAsset.door;
        this.asset.floor = newAsset.floor;
        this.asset.stairs = newAsset.stairs || null;
        this.asset.portal = newAsset.portal;
        this.asset.rentalPrice = newAsset.rentalPrice;
        this.asset.link = newAsset.link;
        this.asset.homeownerReference = newAsset.homeownerReference;

        this.assetService
          .updateAsset(this.asset.id, this.asset)
          .pipe(first())
          .subscribe({
            next: () => {
              if (this.asset.apiId !== selectedApiId) {
                // Se llama a servicio para cambiar el api
                this.assetService
                  .reassignApi(
                    this.asset.id,
                    apiAdminId,
                    selectedApiId === 'null' ? null : selectedApiId
                  )
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      this.modalController.dismiss({ edit: true });
                      this.presentToast();
                    },
                    error: (err) => console.error('ERROR cambiar agent', err)
                  });
              } else if (this.asset.userId !== apiAdminId) {
                // Se llama a servicio para cambiar el member
                this.assetService
                  .reassignHomeowner(this.asset.id, apiAdminId)
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      this.modalController.dismiss({ edit: true });
                      this.presentToast();
                    },
                    error: (err) => console.error('ERROR cambiar agent', err)
                  });
              } else {
                this.modalController.dismiss({ edit: true });
                this.presentToast();
              }
            },
            error: () => {
              this.presentToast(
                'pages.profile.home-owner.assets.add-assets.edit_error'
              );
            }
          });
      } else {
        this.modalController.dismiss({
          newAsset,
          selectedApiId,
          apiAdminId
        });
      }
    } else {
      // Crea assets normales
      if (this.ngForm.invalid) {
        this.presentToast(
          'pages.profile.home-owner.assets.add-assets.fill_everything'
        );
      } else {
        if (this.asset && this.asset.id) {
          this.assetService
            .updateAsset(this.asset.id, this.asset)
            .pipe(first())
            .subscribe({
              next: () => {
                this.dismiss(true);
                this.presentToast(
                  'pages.profile.home-owner.assets.add-assets.updateOK'
                );
              },
              error: () => {
                this.presentToast(
                  'pages.profile.home-owner.assets.add-assets.edit_error',
                  'danger'
                );
              }
            });
        } else {
          const data = {
            ...this.ngForm.form.value,
            userId: this.authenticationService.user.id,
            location: this.asset.location
          };

          if (this.user?.portfolioId) {
            data['portfolioId'] = this.user?.portfolioId;
            data['portfolioOwnerId'] = this.user?.id;
          } else if (this.user?.apiRol === 'API_ADMIN') {
            data['apiId'] = this.apiId;
          } else if (
            this.user?.apiRol === 'API_MEMBER' &&
            this.user.isBottomUp
          ) {
            data['apiId'] = this.user?.apiId;
          }

          this.assetService
            .createAsset(data)
            .pipe(first())
            .subscribe({
              next: (asset) => {
                this.asset.id = asset.id;
                this.dismiss(true);
                this.presentToast();
              },
              error: (error) => {
                if (error.error.code === '106') {
                  this.presentToast(
                    'pages.profile.home-owner.assets.add-assets.duplicated_asset_error',
                    'danger'
                  );
                } else {
                  this.presentToast(
                    'pages.profile.home-owner.assets.add-assets.add_error',
                    'danger'
                  );
                }
              }
            });
        }
      }
    }
  }

  async presentToast(
    messages?: string,
    color: 'success' | 'danger' = 'success'
  ): Promise<void> {
    let messageKey = 'pages.profile.home-owner.assets.add-assets.savedOK';

    if (messages) {
      messageKey = messages;
    }

    messageKey = this.translateService.instant(messageKey);

    const toast = await this.toastController.create({
      message: messageKey,
      position: 'top',
      color,
      duration: 2000
    });
    toast.present();
  }
  getColor(label: LabelDto): string {
    return label !== null && label !== undefined
      ? label.value[0] === '#'
        ? label.value
        : '#' + label.value
      : '#fafafa';
  }

  toggleChange(event): void {
    const value = event.detail.checked;
    if (!value) {
      this.buildingForm.get('selectedApiId').setValue(null);
      this.buildingForm.get('selectedApiId').clearValidators();
      this.buildingForm.get('selectedApiId').updateValueAndValidity();
    } else {
      this.buildingForm
        .get('selectedApiId')
        .setValidators([Validators.required]);
      this.buildingForm.get('selectedApiId').updateValueAndValidity();
    }
    this.useAgent = value;
  }

  async contactNewApiModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ContactNewApiComponent,
      cssClass: 'auto-height modal-extend',
      id: 'contactNewApiComponent'
    });

    return await modal.present();
  }
}
