import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import { EnvelopeStatus } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { first, Subject, takeUntil } from 'rxjs';

import { copyObject, tableSize } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-signed-document-table',
  templateUrl: './signed-document-table.component.html'
})
export class SignedDocumentTableComponent implements OnInit, OnDestroy {
  @Input({ required: true }) candidatureId: string;

  // TABLA
  displayedColumns: string[] = [
    'documentType',
    'tenant',
    'status',
    'lastStatusChange',
    'method',
    'addressee',
    'actions'
  ];
  dataSource: MatTableDataSource<EnvelopeStatus> =
    new MatTableDataSource<EnvelopeStatus>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  tableSize = tableSize;
  getDocumentsSigned$ = new Subject<void>();

  constructor(
    private insuranceService: InsurancesApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.getDocuments();
    this.subscribeToGetDocumentsObservable();
  }

  download(_document: unknown): void {}

  ngOnDestroy(): void {
    this.getDocumentsSigned$.next();
    this.getDocumentsSigned$.complete();
  }

  private async getDocuments(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.insuranceService
      .getDocusSignedByCandidatureId(this.candidatureId)
      .pipe(first())
      .subscribe({
        next: (resp: EnvelopeStatus[]) => this.onSuccessGetDocuments(resp),
        error: (err: HttpErrorResponse) => this.onErrorGetDocuments(err)
      });
  }

  private async onSuccessGetDocuments(resp: EnvelopeStatus[]): Promise<void> {
    this.setDataSource(resp);
    await this.loadingService.dismissSecondLoader();
  }

  private setDataSource(resp: EnvelopeStatus[]): void {
    this.dataSource = new MatTableDataSource<EnvelopeStatus>(
      copyObject(resp) as EnvelopeStatus[]
    );
  }

  private async onErrorGetDocuments(_err: HttpErrorResponse): Promise<void> {
    await this.loadingService.dismissSecondLoader();
  }

  private subscribeToGetDocumentsObservable(): void {
    this.insuranceService
      .getSignedDocumentsObs()
      .pipe(takeUntil(this.getDocumentsSigned$))
      .subscribe(() => this.getDocuments());
  }
}
