/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsurancesApiService } from '@app/modules/core/api-services/insurances-api/insurances-api.service';
import { LoadingService } from '@app/modules/core/services/loading/loading.service';
import { templateStatusList } from '@app/modules/core/utils/templates-api.utils';
import {
    TemplatesDto,
    TemplateStatus,
} from '@core/models';
import {
  ModalController,
} from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-backoffice-template-status-modal',
  templateUrl: './backoffice-template-status-modal.component.html'
})
export class BackofficeTemplateStatusModalComponent implements OnInit {
  @Input() templateDoc: TemplatesDto;

  statusForm: FormGroup;
  statusList = templateStatusList;
  statusRequiresNote = false;
  errorRequest = false;

  get statusClass(): string {
    return `status-${ (this.statusForm.get('statusSelect').value as string).toLowerCase()}`;
  }

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private insuranceService: InsurancesApiService,
  ) {}

  ngOnInit(): void {
      this.initForm()
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  onSubmitUpdate(): void {
    if (this.statusForm.valid) {
      this.updateTemplate()
    } 
  }

  private initForm() : void {
    if(this.templateDoc.status === TemplateStatus.REJECTED) this.statusRequiresNote = true

    this.statusForm = this.formBuilder.group({
      statusSelect: [this.templateDoc.status], 
      note: [this.templateDoc.status === TemplateStatus.REJECTED 
        ? this.templateDoc.rejectReason : ''] 
    });

    this.statusForm.get('statusSelect')?.valueChanges.subscribe((status) => {
      const note = this.statusForm.get('note');

      if (status === TemplateStatus.REJECTED) {
        note?.setValidators([Validators.required, 
            Validators.minLength(10), Validators.maxLength(150)]); 
        this.statusRequiresNote = true;
      } else {
        this.statusRequiresNote = false;
        note?.clearValidators(); 
      }
      note?.updateValueAndValidity(); 
    });
  }

  private updateTemplate(): void {
    this.loadingService.presentLoading(null)
    const update = {
      newStatus: this.statusForm.get('statusSelect').value,
      reason: this.statusRequiresNote 
        ? this.statusForm.get('note').value : null
    };
    this.insuranceService.updateTemplateStatus(this.templateDoc.fileId, update).subscribe({
      next: () => {
        this.loadingService.dismissLoading()
        this.dismiss(true)
      },
      error: () => {
        this.loadingService.dismissLoading()
        this.errorRequest = true
      }
    })
  }

}