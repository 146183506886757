import { DocumentDTO } from '@core/models';

export enum ViveDocTypes {
  STATE_OF_INCOME = 'stateOfIncome',
  STATE_OF_INCOME_DOC_NAME = 'Declaración_Renta',
  PROPERTY_REGISTRATION = 'propertyRegistration',
  PROPERTY_REGISTRATION_DOC_NAME = 'REGISTRO_PROPIEDAD',
  MARITAL_STATUS = 'maritalStatus',
  MARITAL_STATUS_DOC_NAME = 'ESTADO_CIVIL',
  WORK_LIFE = 'workLife',
  WORK_LIFE_DOC_NAME = 'Vida_Laboral',
  CHARGES_CERTIFICATE = 'chargesCertificate',
  CHARGES_CERTIFICATE_DOC_NAME = 'Certificado_Imputaciones'
}

export enum DocVivePopoverOptions {
  SEE = 'SEE',
  REMOVE = 'REMOVE'
}

export interface DocVivePopoverResponse {
  option: DocVivePopoverOptions;
}

export interface ViveAddDocumentModalClosingData {
  document: DocumentDTO;
  file: File;
}
