export interface ExportBuildingAsset {
  homeownerReference: string;
  address: string;
  portal: string;
  stairs: string;
  floor: string;
  door: string;
  postalCode: string;
  town: string;
  rentalPrice: number;
  premium: number;
  policyNumber: string;
  inceptionDate: string;
  tenantsDNI: string[];
  status: string;
}
