import { Pipe, PipeTransform } from '@angular/core';
import { DocuSignStatusEnum, EnvelopeStatus } from '@core/models';

@Pipe({
  name: 'signedDocumentChipColor'
})
export class SignedDocumentChipColorPipe implements PipeTransform {
  transform(document: EnvelopeStatus): string {
    const { status } = document;

    if (status === DocuSignStatusEnum.COMPLETED) {
      return 'success';
    } else if (status === DocuSignStatusEnum.DECLINED) {
      return 'danger';
    } else if (status === DocuSignStatusEnum.DELIVERED) {
      return 'secondary';
    }

    return '';
  }
}
