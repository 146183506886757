import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

import { RecoveryPasswordForm } from '../../models/form-profile.model';
import { MessageBean } from '../../utils/message-bean.utils';

@Component({
  selector: 'el-buen-inquilino-profile-password-recovery-form',
  templateUrl: './profile-password-recovery-form.component.html'
})
export class ProfilePasswordRecoveryFormComponent implements OnInit, OnChanges {
  @Input({ required: true }) user: User;
  recoveryPasswordForm: FormGroup<RecoveryPasswordForm>;

  constructor(
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {
    this.setRecoveryPasswordForm();
  }

  ngOnChanges(): void {
    this.setRecoveryPasswordForm();
  }

  clickRecoveryPassword(): void {
    if (this.recoveryPasswordForm.invalid) {
      this.snackBarService.show(
        MessageBean.buildMessages(this.recoveryPasswordForm, this.translate),
        'danger'
      );
    } else {
      this.recoveryPassword(this.recoveryPasswordForm.value.email);
    }
  }

  private recoveryPassword(email: string): void {
    this.authenticationService.recoveryPassword(email);
  }

  private setRecoveryPasswordForm(): void {
    this.recoveryPasswordForm = new FormGroup<RecoveryPasswordForm>({
      email: new FormControl<string>(
        this.user?.email || null,
        Validators.required
      )
    });
  }
}
