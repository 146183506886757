import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  GeocodingResponse,
  StaticGoogleMapImage
} from '@shared/models/google-maps.model';
import { makeStaticMapImageUrl } from '@shared/utils/ebi-google-maps.utils';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { GoogleMapsLoaderService } from './google-maps-loader.service';
import { noTokenRequest } from '../../interceptors/interceptor';

@Injectable()
export class GoogleMapsService {
  constructor(
    private googleMapsLoader: GoogleMapsLoaderService,
    private http: HttpClient
  ) {}

  async getStaticMapImage(
    isCity: boolean = false,
    bLat: number,
    bLng: number
  ): Promise<string> {
    const resp = await this.getMapCenter(isCity, bLat, bLng);
    return makeStaticMapImageUrl(
      resp.centerLat,
      resp.centerLng,
      resp.lat,
      resp.lng,
      resp.zoom
    );
  }

  async getMapCenter(
    isCity: boolean = false,
    lat: number,
    lng: number
  ): Promise<StaticGoogleMapImage> {
    return isCity ? this.getCityData(lat, lng) : this.getCountryData(lat, lng);
  }

  private async getCityData(
    lat: number,
    lng: number
  ): Promise<StaticGoogleMapImage> {
    await this.googleMapsLoader.load(); // Aseguramos que Google Maps esté cargado

    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lng);

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const cityBounds = results[0].geometry.viewport;
          const center = cityBounds.getCenter();
          resolve({
            centerLat: center.lat(),
            centerLng: center.lng(),
            lat,
            lng,
            zoom: 10
          });
        } else {
          reject(`Error al obtener límites de la ciudad: ${status}`);
        }
      });
    });
  }

  private async getCountryData(
    lat: number,
    lng: number
  ): Promise<StaticGoogleMapImage> {
    const countryName = 'Spain';

    try {
      const resp = await this.getCountryCenter(countryName)
        .pipe(first())
        .toPromise();
      const location = resp.results[0].geometry.location;
      let centerLat = location.lat;
      let centerLng = location.lng;
      let zoom = 5;

      let isCanaryIslands: boolean;
      try {
        isCanaryIslands = await this.isCanaryIslands(lat, lng);
      } catch {
        isCanaryIslands = false;
      }

      if (isCanaryIslands) {
        centerLat -= 5;
        centerLng -= 3;
        zoom = 4;
      }

      return { lat, lng, centerLat, centerLng, zoom };
    } catch (error) {
      throw new Error('Error al obtener datos del país');
    }
  }

  private isCanaryIslands(lat: number, lng: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.googleMapsLoader.load().then(() => {
        // Aseguramos que Google Maps esté cargado
        const location = new google.maps.LatLng(lat, lng);
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ location }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            for (const result of results) {
              for (const component of result.address_components) {
                if (
                  component.long_name.includes('Canarias') ||
                  component.long_name.includes('Canary Islands')
                ) {
                  resolve(true);
                  return;
                }
              }
            }
            resolve(false);
          } else {
            console.error(
              'Error al realizar la geocodificación inversa:',
              status
            );
            reject();
          }
        });
      });
    });
  }

  private getCountryCenter(countryName: string): Observable<GeocodingResponse> {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${countryName}&key=${environment.firebaseConfig.apiKey}`;
    return this.http.get<GeocodingResponse>(geocodingUrl, {
      context: noTokenRequest()
    });
  }
}
