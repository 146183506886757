import { AssetDto } from './asset.model';
import { UserCreateCandidatureDto } from './candidature.model';
import { DocumentDTO } from './document.model';
import {
  AMLCheck,
  Conditioning,
  Incidence,
  RequestAnalysisDto
} from './users.model';

export class UserCandidatureDto {
  user: UserCreateCandidatureDto;
  documents?: DocumentDTO[];
  requestAnalysisId?: string;
  incidences?: Incidence[];
  conditioning?: Conditioning;
  hasDni = false;
  hasPayslip = false;
  hasRent = false;
  hasPensionerRecognition = false;
  hasOtherDocument = false;
  hasIs200Model = false;
  hasIvaQuarterModel = false;
  hasWorkLife = false;
  hasMinimumDocsByRol = false;
  requestAnalysisDate: Date;
  lastPayslipAmount: string;
  pensionerTotalAmount: string;
  dni?: DocumentDTO[];
  payslips?: DocumentDTO[];
  rent?: DocumentDTO[];
  otherDocument?: DocumentDTO[];
  is200Model: DocumentDTO[];
  ivaQuarterModel: DocumentDTO[];
  workLife?: DocumentDTO[];
  pensionerRecognition?: DocumentDTO[];
}

export class CandidatureDetailedDto {
  id: string;
  asset: AssetDto;
  homeowner: UserCandidatureDto;
  tenantCandidatureList: UserCandidatureDto[];
  status: string;
  selectDate: string;
  creationDate: string;
  score: number;
  originalScore?: number;
  warrantyId: string;
  certificationPaid?: boolean;
  paidDate?: Date;
  closingForced?: boolean;
  requestAnalyses?: RequestAnalysisDto[];
  archived?: boolean;
}

export class AmlAlertDto {
  activate: boolean;
  notifyEmail: string;
}

export class BuildingAmlAlertDto {
  userId: string;
  dni: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  buildingId: string;
  portfolioId: string;
  requestId: string;
  notifyEmail: string;
  warning: boolean;
  result: AMLCheck;
  street?: string;
  number?: string;
  town?: string;
  province?: string;
  vppModality?: string;
  internalCode?: string;
}

export enum CertificationStatusEnum {
  FORCED_CLOSE = 'FORCED_CLOSE',
  APPROVED_BY_DECREE = 'APPROVED_BY_DECREE',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  DOCUMENTATION_PENDING = 'DOCUMENTATION_PENDING',
  WITH_INCIDENCES = 'WITH_INCIDENCES',
  ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
  DOWNLOADABLE = 'DOWNLOADABLE',
  SELECT = 'SELECT',
  ERROR = 'ERROR'
}

export class CreateUserForCertificationsRegistry {
  email: string;
  password: string;
  confirmPassword: string;
  acceptedTerms: boolean;
  acceptedProfileStudy: boolean;
  acceptedAllowHomeOwnerUploadDocuments: boolean;
  candidatureId: string;
  noValidated: boolean;
}

export class UpdateConditionsUserDto {
  acceptedAllowHomeOwnerUploadDocuments: boolean;
  acceptedProfileStudy: boolean;
  acceptedTerms: boolean;
  candidatureId: string;
  id: string;
}
