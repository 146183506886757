import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LastTwoPayslipMonths, TypeDocumentEnum } from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

import {
  UploadTenantDocumentEvent,
  UploadTenantDocumentNameEnum
} from '../../models/upload-tenant-document-card.model';

@Component({
  selector: 'el-buen-inquilino-upload-tenant-document-card',
  templateUrl: './upload-tenant-document-card.component.html'
})
export class UploadTenantDocumentCardComponent {
  @Input({ required: true }) type: TypeDocumentEnum;
  @Input({ required: true }) isDisabledView = false;
  @Input() payslipNumber: number;
  @Input() quarterNumber: number;
  @Input() lastTwoPayslipMonths: LastTwoPayslipMonths;
  @Input() extraDoc = false;

  @Output() presentModalEvent = new EventEmitter<UploadTenantDocumentEvent>();

  get title(): string {
    switch (this.type) {
      case TypeDocumentEnum.DNI:
        return 'pages.tab_document.card_title_dni';
      case TypeDocumentEnum.PAYSLIP:
        return 'pages.tab_document.card_title_payroll';
      case TypeDocumentEnum.INCOME_TAX_DECLARATION:
        return 'pages.tab_document.card_title_statement_income';
      case TypeDocumentEnum.OTHER_DOCUMENT:
        return 'pages.tab_document.card_title_freelance_receipts';
      case TypeDocumentEnum.PENSIONER_RECOGNITION:
        return 'pages.tab_document.card_title_pension';
      case TypeDocumentEnum.IS200_MODEL:
        return 'pages.tab_document.card_title_corporate_taxes';
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        return 'pages.tab_document.card_title_quarterly_vat_dinamical';
      case TypeDocumentEnum.WORK_LIFE:
        return 'pages.tab_document.card_title_working_life';
      case TypeDocumentEnum.MODEL_130:
        return 'pages.tab_document.card_title_model_130';
      case TypeDocumentEnum.SS_CERTIFICATE:
        return 'pages.tab_document.card_title_ss_certificate';
      case TypeDocumentEnum.AEAT_CERTIFICATE:
        return 'pages.tab_document.card_title_aeat_certificate';
      case TypeDocumentEnum.PYG_QUARTER:
        return 'pages.tab_document.card_title_pyg_quarter';
      case TypeDocumentEnum.WORK_CONTRACT:
        return 'pages.tab_document.card_title_work_contract';
      case TypeDocumentEnum.BANK_STATEMENT:
        return 'pages.tab_document.card_title_bank_statement';
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE:
        return 'pages.tab_document.card_title_remote_job_certificate';
      case TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION:
        return 'pages.tab_document.card_title_invoice_payment_confirmation';
      case TypeDocumentEnum.INVOICE_RECORD_BOOK:
        return 'pages.tab_document.card_title_invoice_record_book';
    }
  }

  get subtitle(): string {
    switch (this.type) {
      case TypeDocumentEnum.DNI:
        return 'pages.tab_document.remember_upload_dni';
      case TypeDocumentEnum.INCOME_TAX_DECLARATION:
        return 'pages.tab_document.remember_upload_statement_income';
      case TypeDocumentEnum.PENSIONER_RECOGNITION:
        return 'pages.tab_document.remember_upload_pension';
      case TypeDocumentEnum.IS200_MODEL:
        return 'pages.tab_document.remember_upload_corporate_taxes';
      case TypeDocumentEnum.WORK_LIFE:
        return 'pages.tab_document.remember_upload_working_life';
      case TypeDocumentEnum.MODEL_130:
        return 'pages.tab_document.remember_upload_model_130';
      case TypeDocumentEnum.SS_CERTIFICATE:
        return 'pages.tab_document.remember_upload_ss_certificate';
      case TypeDocumentEnum.AEAT_CERTIFICATE:
        return 'pages.tab_document.remember_upload_aeat_certificate';
      case TypeDocumentEnum.PYG_QUARTER:
        return 'pages.tab_document.remember_upload_pyg_quarter';
      case TypeDocumentEnum.WORK_CONTRACT:
        return 'pages.tab_document.remember_upload_work_contract';
      case TypeDocumentEnum.BANK_STATEMENT:
        return 'pages.tab_document.remember_upload_bank_statement';
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE:
        return 'pages.tab_document.remember_upload_remote_job_certificate';
      case TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION:
        return 'pages.tab_document.remember_upload_invoice_payment_confirmation';
      case TypeDocumentEnum.INVOICE_RECORD_BOOK:
        return 'pages.tab_document.remember_upload_invoice_record_book';
    }
  }

  get uploadBtnText(): string {
    switch (this.type) {
      case TypeDocumentEnum.DNI:
        return 'pages.tab_document.upload_dni';
      case TypeDocumentEnum.PAYSLIP:
        return 'pages.tab_document.upload_payroll';
      case TypeDocumentEnum.INCOME_TAX_DECLARATION:
        return 'pages.tab_document.upload_statement_income';
      case TypeDocumentEnum.OTHER_DOCUMENT:
        return 'pages.tab_document.upload_freelance_receipts';
      case TypeDocumentEnum.PENSIONER_RECOGNITION:
        return 'pages.tab_document.upload_pension';
      case TypeDocumentEnum.IS200_MODEL:
        return 'pages.tab_document.upload_corporate_taxes';
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        return 'pages.tab_document.upload_quarterly_vat';
      case TypeDocumentEnum.WORK_LIFE:
        return 'pages.tab_document.upload_working_life';
      case TypeDocumentEnum.MODEL_130:
        return 'pages.tab_document.upload_model_130';
      case TypeDocumentEnum.SS_CERTIFICATE:
        return 'pages.tab_document.upload_ss_certificate';
      case TypeDocumentEnum.AEAT_CERTIFICATE:
        return 'pages.tab_document.upload_aeat_certificate';
      case TypeDocumentEnum.PYG_QUARTER:
        return 'pages.tab_document.upload_pyg_quarter';
      case TypeDocumentEnum.WORK_CONTRACT:
        return 'pages.tab_document.upload_work_contract';
      case TypeDocumentEnum.BANK_STATEMENT:
        return 'pages.tab_document.upload_bank_statement';
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE:
        return 'pages.tab_document.upload_remote_job_certificate';
      case TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION:
        return 'pages.tab_document.upload_invoice_payment_confirmation';
      case TypeDocumentEnum.INVOICE_RECORD_BOOK:
        return 'pages.tab_document.upload_invoice_record_book';
    }
  }

  get specialSubtitle(): string {
    switch (this.type) {
      case TypeDocumentEnum.PAYSLIP:
        return this.translate.instant(
          'pages.tab_document.remember_upload_payroll_optional_month',
          {
            month: this.titlecase.transform(
              this.getMonthString(this.payslipNumber)
            )
          }
        ) as string;
      case TypeDocumentEnum.OTHER_DOCUMENT:
        return this.translate.instant(
          'pages.tab_document.remember_upload_freelance_receipts',
          {
            penultimatePayslip: this.lastTwoPayslipMonths.penultimatePayslip,
            ultimatePayslip: this.lastTwoPayslipMonths.ultimatePayslip
          }
        ) as string;
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        return this.translate.instant(
          'pages.tab_document.remember_upload_iva_vats_one_quarter',
          {
            quarter: this.titlecase.transform(
              this.getQuarterString(this.quarterNumber)
            )
          }
        ) as string;
    }
  }

  get showPayslipMonth(): boolean {
    return this.type === TypeDocumentEnum.PAYSLIP;
  }

  get showIvaQuarterString(): boolean {
    return this.type === TypeDocumentEnum.IVA_QUARTER_MODEL;
  }

  get showSpecialSubtitle(): boolean {
    return [
      TypeDocumentEnum.PAYSLIP,
      TypeDocumentEnum.IVA_QUARTER_MODEL,
      TypeDocumentEnum.OTHER_DOCUMENT
    ].includes(this.type);
  }

  get showOptionalText(): boolean {
    return this.payslipNumber === 0 && !this.extraDoc;
  }

  get showSocialSecurityBtn(): boolean {
    return this.type === TypeDocumentEnum.WORK_LIFE;
  }

  get socialSecurityHref(): string {
    return environment.socialSecurityHref;
  }

  constructor(
    private utilsService: UtilsService,
    private translate: TranslateService,
    private titlecase: TitleCasePipe
  ) {}

  presentModal(): void {
    this.presentModalEvent.emit({
      type: this.type,
      name: this.getName()
    });
  }

  getName(): UploadTenantDocumentNameEnum {
    switch (this.type) {
      case TypeDocumentEnum.PAYSLIP: {
        if (this.payslipNumber === 0) {
          return UploadTenantDocumentNameEnum.HAS_NOT_OPTIONAL_PAYSLIP;
        } else if (this.payslipNumber === 1) {
          return UploadTenantDocumentNameEnum.HAS_ONE_MONTH_PAYSLIPS;
        } else if (this.payslipNumber === 2) {
          return UploadTenantDocumentNameEnum.HAS_TWO_MONTH_PAYSLIPS;
        }
      }
      case TypeDocumentEnum.IVA_QUARTER_MODEL: {
        if (this.quarterNumber === 1) {
          return UploadTenantDocumentNameEnum.HAS_ONE_QUARTER_MODEL;
        } else if (this.quarterNumber === 2) {
          return UploadTenantDocumentNameEnum.HAS_TWO_QUARTER_MODEL;
        }
      }
    }
  }

  getMonthString(number: number): string {
    return this.utilsService.getPayrollMonth(number);
  }

  getQuarterString(number: number): string {
    return this.utilsService.getQuarterString(number);
  }
}
