import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { ThirdpartyRoutingModule } from './thirdparty-routing.module';

const MODULES = [SharedModule, ThirdpartyRoutingModule];

@NgModule({
  declarations: [],
  imports: [...MODULES]
})
export class ThirdpartyModule {}
