import { Pipe, PipeTransform } from '@angular/core';
import { MinimalDocumentationCheck, TypeDocumentEnum } from '@core/models';

@Pipe({
  name: 'documentChipIcon'
})
export class DocumentChipIconPipe implements PipeTransform {
  transform(minDoc: MinimalDocumentationCheck, type: TypeDocumentEnum): string {
    let document: boolean = null;
    switch (type) {
      case TypeDocumentEnum.DNI:
        document = minDoc.hasDni;
        break;
      case TypeDocumentEnum.PAYSLIP:
        document = minDoc.hasPayslips;
        break;
      case TypeDocumentEnum.PENSIONER_RECOGNITION:
        document = minDoc.hasPensionerRecognition;
        break;
      case TypeDocumentEnum.INCOME_TAX_DECLARATION:
        document = minDoc.hasRent;
        break;
      case TypeDocumentEnum.MODEL_130:
        document = minDoc.hasModel130;
        break;
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        document = minDoc.hasIvaQuarterModel;
        break;
      case TypeDocumentEnum.IS200_MODEL:
        document = minDoc.hasIs200Model;
        break;
      case TypeDocumentEnum.SS_CERTIFICATE:
        document = minDoc.hasSSCertificate;
        break;
      case TypeDocumentEnum.AEAT_CERTIFICATE:
        document = minDoc.hasAEATCertificate;
        break;
      case TypeDocumentEnum.PYG_QUARTER:
        document = minDoc.hasPYGQuarter;
        break;
      case TypeDocumentEnum.WORK_CONTRACT:
        document = minDoc.hasWorkContract;
        break;
      case TypeDocumentEnum.BANK_STATEMENT:
        document = minDoc.hasBankStatement;
        break;
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE:
        document = minDoc.hasRemoteJobCertificate;
        break;
    }

    if (document) {
      return 'checkmark-outline';
    } else {
      return 'close-outline';
    }
  }
}
