import { Injectable } from '@angular/core';

import { Credentials, TypeUserEnum } from '../../models';

const PREFIX_APP = 'EL-BUEN-INQUILINO';
const TOKEN = 'TOKEN';
const TOKEN_REFRESH = 'TOKEN-REFRESH';
const ROLE = 'ROLE';
const PREFIX_SEPARATOR = '-';
const ROLE_TENANT = 'TENANT';
const ROLE_HOMEOWNER = 'HOMEOWNER';
const ROLE_ADMIN = 'INCOFISA_ADMINISTRATIVE';

const LAST_URL = 'LAST_URL';

@Injectable()
export class StoreService {
  setCredentials(credentials: Credentials): void {
    this.setToken(credentials.token);
    this.setRole(credentials.typeUser);
    this.setTokenRefresh(credentials.tokenRefresh);
  }

  getCredentials(): Credentials {
    return new Credentials(
      this.getToken(),
      this.getRole(),
      this.getTokenRefresh()
    );
  }

  verifyCredentials(): boolean {
    return (
      this.getCredentials().token.length > 0 &&
      this.getCredentials().tokenRefresh.length > 0 &&
      this.getCredentials().typeUser.length > 0
    );
  }

  removeCredentials(): void {
    this.removeRole();
    this.removeToken();
    this.removeRole();
    this.removeTokenRefresh();
  }

  setToken(token: string): void {
    localStorage.setItem(PREFIX_APP + PREFIX_SEPARATOR + TOKEN, token);
  }

  setTokenRefresh(token: string): void {
    localStorage.setItem(PREFIX_APP + PREFIX_SEPARATOR + TOKEN_REFRESH, token);
  }

  setRole(token: string): void {
    localStorage.setItem(PREFIX_APP + PREFIX_SEPARATOR + ROLE, token);
  }

  setLastUrl(url: string): void {
    localStorage.setItem(LAST_URL, url);
  }

  getLastUrl(): string | null {
    return localStorage.getItem(LAST_URL);
  }

  removeLastUrl(): void {
    localStorage.removeItem(LAST_URL);
  }

  getToken(): string {
    return localStorage.getItem(PREFIX_APP + PREFIX_SEPARATOR + TOKEN) || '';
  }

  private removeToken(): void {
    localStorage.removeItem(PREFIX_APP + PREFIX_SEPARATOR + TOKEN);
  }

  private getTokenRefresh(): string {
    return (
      localStorage.getItem(PREFIX_APP + PREFIX_SEPARATOR + TOKEN_REFRESH) || ''
    );
  }

  private removeTokenRefresh(): void {
    localStorage.removeItem(PREFIX_APP + PREFIX_SEPARATOR + TOKEN_REFRESH);
  }

  private getRole(): TypeUserEnum {
    const role =
      localStorage.getItem(PREFIX_APP + PREFIX_SEPARATOR + ROLE) || '';
    switch (role) {
      case ROLE_TENANT:
        return TypeUserEnum.TENANT;
      case ROLE_HOMEOWNER:
        return TypeUserEnum.HOMEOWNER;
      case ROLE_ADMIN:
        return TypeUserEnum.INCOFISA_ADMINISTRATIVE;
      default:
        return null;
    }
  }

  private removeRole(): void {
    localStorage.removeItem(PREFIX_APP + PREFIX_SEPARATOR + ROLE);
  }
}
