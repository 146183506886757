export interface RecipientData {
  recipients: {
    templateId: string;
    templateRoles: {
      email: string;
      name: string;
      roleName: string;
      tabs: {
        signHereTabs: {
          anchorString: string;
          anchorUnits: string;
          anchorXOffset: string;
          anchorYOffset: string;
          status: string;
        }[];
      };
    }[];
    status: string;
  };
}

export interface EmailData {
  email: string;
}

export interface ContractData {
  contractData: unknown;
  email: EmailData;
  recipients: RecipientData | undefined;
}

export interface Property {
  name: string; // Nombre derivado de la clave
  type: string; // Tipo del campo (e.g., 'number', 'string')
}

export interface BackendPayload {
  selectedTemplate: string;
  candidaturesId: string;
  properties: Property[]; // Lista de objetos con los atributos 'name', 'type', y 'title'
}
