/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { Component, OnInit, Input } from '@angular/core';
import { PreferencesApiService } from '@core/api-services/preferences-api/preferences-api.service';
import { PreferenceDTO, PreferenceValueDTO } from '@core/models';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-profile-preferences-modal',
  templateUrl: './profile-preferences-modal.component.html'
})
export class ProfilePreferencesModalComponent implements OnInit {
  allPreferences: PreferenceDTO[];
  subscriptions: Subscription[] = [];
  anyChange = false;
  @Input() userId: string;

  constructor(
    private modalController: ModalController,
    private preferencesService: PreferencesApiService
  ) {}

  ngOnInit(): void {
    this.getAllPreferences();
  }

  getAllPreferences(): void {
    const subs1 = this.preferencesService
      .getAllPreferences()
      .subscribe((resAll: PreferenceDTO[]) => {
        this.allPreferences = resAll;
        this.setUsersPreferences();
      });
    this.subscriptions.push(subs1);
  }

  setUsersPreferences(): void {
    const subs2 = this.preferencesService
      .getActivatedPreferences(this.userId)
      .subscribe((resActivated: PreferenceDTO[]) => {
        this.allPreferences.forEach((val: PreferenceDTO) => {
          val.elements.forEach((element: PreferenceValueDTO) => {
            let elementNotSelected = true;
            resActivated.forEach((resActVal: PreferenceDTO) => {
              resActVal.elements.forEach((elem: PreferenceValueDTO) => {
                if (elem.id === element.id) {
                  elementNotSelected = false;
                  element.checked = true;
                }
              });
            });
            if (elementNotSelected) {
              element.checked = false;
            }
          });
        });
      });
    this.subscriptions.push(subs2);
  }

  dismiss(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
    this.modalController.dismiss({
      anyChange: this.anyChange
    });
  }

  changedElementStatus(e): void {
    const elementChanged = e.feature.elements.find(
      (val) => val.id === e.element.id
    );

    const selectData = {
      select: elementChanged.checked ? !elementChanged.checked : true
    };
    this.preferencesService
      .changePreferenceStatus(
        this.userId,
        e.feature.id,
        e.element.id,
        selectData
      )
      .pipe(take(1))
      .subscribe(() => {
        this.setUsersPreferences();
        this.anyChange = true;
      });
  }
}
