import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import {
  ContactDto,
  CreateContactUserDto,
  PageDto,
  StatusContactEnum,
  UpdateStatusContactDto
} from '../../models';

const PATH_CREATE_CONTACT = '/contacts';
const PATH_GET_CONTACT = '/contacts/{contactId}';
const PATH_GET_CONTACTS = '/contacts';
const PATH_PATCH_CONTACT = '/contacts/{contactId}';

@Injectable()
export class ContactsApiService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.users;
  }

  getContacts(
    statusContact: StatusContactEnum
  ): Observable<PageDto<ContactDto>> {
    return this.http.get<PageDto<ContactDto>>(this.url + PATH_GET_CONTACTS, {
      params: new HttpParams()
        .append('numElements', '20')
        .append('statusContact', statusContact.toString())
    });
  }

  getContact(contactId: string): Observable<ContactDto> {
    return this.http.get<ContactDto>(
      this.url + PATH_GET_CONTACT.replace('{contactId}', contactId)
    );
  }

  registerContact(
    createContactUserDto: CreateContactUserDto,
    sharedType: string
  ): Observable<HttpResponse<string>> {
    return this.http.post<string>(
      this.url + PATH_CREATE_CONTACT,
      createContactUserDto,
      {
        observe: 'response',
        params: new HttpParams().append('sharedType', sharedType)
      }
    );
  }

  changeStatusContact(
    contactId: string,
    statusContact: StatusContactEnum
  ): Observable<unknown> {
    const updateStatusContactDto = new UpdateStatusContactDto();
    updateStatusContactDto.statusContact = statusContact;
    return this.http.patch(
      this.url + PATH_PATCH_CONTACT.replace('{contactId}', contactId),
      updateStatusContactDto
    );
  }
}
