import { Pipe, PipeTransform } from '@angular/core';
import { format, isSameDay } from 'date-fns';

@Pipe({
  name: 'sinisterMessageDate'
})
export class SinisterMessageDatePipe implements PipeTransform {
  transform(date: Date): string {
    let dateFormat = 'HH:mm';
    if (!this.isToday(date)) {
      dateFormat = 'dd/MM/yyyy HH:mm';
    }

    return format(new Date(date), dateFormat);
  }

  private isToday(date: Date): boolean {
    return isSameDay(new Date(date), new Date());
  }
}
