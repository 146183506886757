import { Pipe, PipeTransform } from '@angular/core';
import { filterIncidences } from '@app/modules/core/utils/incidences-api.utils';
import { Incidence } from '@core/models';

@Pipe({
  name: 'candidatureFilterIncidences'
})
export class CandidatureFilterIncidencesPipe implements PipeTransform {
  transform(incidences: Incidence[]): Incidence[] {
    return filterIncidences(incidences);
  }
}
