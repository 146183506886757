/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { PreferenceDTO } from '../../models';

@Injectable()
export class PreferencesApiService {
  urlAll: string;
  urlActivated: string;

  constructor(private http: HttpClient) {
    this.urlAll = environment.services.users + '/features';
    this.urlActivated = environment.services.users;
  }

  getActivatedPreferences(userId: string): Observable<PreferenceDTO[]> {
    return this.http.get<PreferenceDTO[]>(
      this.urlActivated + `/users/${userId}/features/`
    );
  }

  getAllPreferences(): Observable<PreferenceDTO[]> {
    return this.http.get<PreferenceDTO[]>(this.urlAll);
  }

  changePreferenceStatus(
    userId: string,
    featureId: string,
    elementId: string,
    preferenceData: any
  ): Observable<any> {
    return this.http.put(
      this.urlActivated +
        `/users/${userId}/features/${featureId}/elements/${elementId}`,
      preferenceData
    );
  }
}
