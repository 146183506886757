/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { HomeownerAnalyticsApiService } from '@core/api-services/homeowner-analytics-api/homeowner-analytics-api.service';
import { AllDataOwnerAnalytics } from '@core/models';
import { AlertController, ModalController } from '@ionic/angular';
import { Chart, ChartConfiguration } from 'chart.js';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { getBase64ImageFromURL } from '../../utils/pdfmake.utils';

@Component({
  selector: 'el-buen-inquilino-owner-analytics-pdf-modal',
  templateUrl: './owner-analytics-pdf-modal.component.html'
})
export class OwnerAnalyticsPdfModalComponent implements OnInit {
  allStadistics: AllDataOwnerAnalytics;
  // Actividad
  analysisMonthData: ChartConfiguration<'bar'>['data'];
  analysisMonthOptions: ChartConfiguration<'bar'>['options'];
  volumeData: ChartConfiguration<'bar'>['data'];
  volumeOptions: ChartConfiguration<'bar'>['options'];
  // Tiempos
  slaData: ChartConfiguration<'pie'>['data'];
  slaOptions: ChartConfiguration<'pie'>['options'];
  candidatureTenantsData: ChartConfiguration<'bar'>['data'];
  candidatureTenantsOptions: ChartConfiguration<'bar'>['options'];
  // Siniestro
  monthRatioData: ChartConfiguration<'bar'>['data'];
  monthRatioOptions: ChartConfiguration<'bar'>['options'];
  provinceRatioData: ChartConfiguration<'pie'>['data'];
  provinceRatioOptions: ChartConfiguration<'pie'>['options'];
  priceRatioData: ChartConfiguration<'pie'>['data'];
  priceRatioOptions: ChartConfiguration<'pie'>['options'];
  analysisMonthChart: any;
  volumeOcupationChart: any;
  slaChart: any;
  candidatureTenantChart: any;
  sinesterMonthChart: any;
  insuranceProvinceChart: any;
  insusancePriceChart: any;
  constructor(
    private modalController: ModalController,
    public alertController: AlertController,
    private homeownerAnalyticsService: HomeownerAnalyticsApiService
  ) {}

  ngOnInit(): void {
    this.allStadistics = this.homeownerAnalyticsService.allStadisticsData;
    this.createCharts();
  }

  async createPDF(onlypreview?: boolean): Promise<void> {
    const WIDTH = 595.28;
    const PAGE_MARGIN_H = 40;
    const PAGE_MARGIN_V = 100;
    const PAGE_WIDTH = WIDTH - 2 * PAGE_MARGIN_H;
    const LINE_HEIGHT = 1.5;
    const date = new Date();
    const dateNow = date.toJSON().slice(0, 10).replace(/-/g, '/');

    const cover = {
      stack: [
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 140,
              h: 140, // aumentar el valor de h
              r: 80,
              lineWidth: 0,
              color: '#ffffff'
            }
          ]
        },
        {
          columns: [
            {
              image: 'logo_ebi',
              fit: [80, 80]
            }
          ],
          relativePosition: {
            x: 32,
            y: -105
          }
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 200,
              y: -140,
              w: 500,
              h: 140, // aumentar el valor de h
              r: 80,
              lineWidth: 0,
              color: '#337ab7'
            }
          ]
        },
        {
          stack: [
            {
              text: 'Informe de actividad',
              style: ['h1', 'textLight']
            },
            {
              text: '10 de marzo de 2023',
              style: ['subheader', 'textLight'],
              margin: [0, 0, 0, 8]
            }
          ],
          relativePosition: {
            x: 240,
            y: -110
          }
        }
      ],
      pageBreak: 'after'
    };

    const tableOfContents = {
      stack: [
        {
          toc: {
            id: 'mainToc',
            title: { text: 'Indice', style: 'h2' }
          }
        },
        {
          text: '0. Introducción',
          style: 'subheader',
          tocItem: true
        },
        {
          text:
            '1. Control de actividad (' + date.getFullYear().toString() + ')',
          style: 'subheader',
          tocItem: true
        },
        {
          text: '2. Control de tiempo (' + date.getFullYear().toString() + ')',
          style: 'subheader',
          tocItem: true
        },
        {
          text:
            '3. Control de siniestros (' + date.getFullYear().toString() + ')',
          style: 'subheader',
          tocItem: true,
          pageBreak: 'after'
        }
      ]
    };

    const introduction = {
      stack: [
        {
          text: '0. Introducción',
          style: ['h2']
        },
        {
          text: 'El buen inquilino te facilita un informe que te ayudará a tomar mejores decisiones basadas en datos. Se basa en tres apartados: actividad, tiempo y siniestros. Revisa cada uno de los apartados y podrás ajustar tu cartera de manera estratégica, aumentando rentabilidad y minimizando riesgos.',
          style: ['normalText', 'safetyDistance']
        }
        // {
        //   text: 'Tu cartera',
        //   style: ['subheader'],
        // },
        // {
        //   text: 'Así se distribuyen tus activos en España. Esta es la muestra que se ha tomado para generar este informe.',
        //   style: ['normalText', 'safetyDistance'],
        // },
        // {
        //   table: {
        //     headerRows: 1,
        //     widths: ['*', '*'],
        //     body: [
        //       [
        //         { text: 'Ciudad', style: 'tableHeader' },
        //         { text: 'Número de activos', style: 'tableHeader' },
        //       ],
        //       ['Madrid', 65],
        //       ['Barcelona', 43],
        //       ['Sevilla', 32],
        //       ['Valencia', 22],
        //       ['Islas Baleares', 9],
        //     ],
        //   },
        //   margin: [0, 10, 0, 10],
        // },
      ],
      pageBreak: 'after'
    };

    const activity = {
      stack: [
        {
          text:
            '1. Control de actividad (' + date.getFullYear().toString() + ')',
          style: ['h2']
        },
        {
          text: 'El buen inquilino te facilita un informe sobre tu actividad. Con esto podrás tomar mejores decisiones basadas en datos. Revisa los datos y ajusta tu cartera de manera estratégica, aumentando la rentabilidad y minimizando los riesgos.',
          style: ['normalText', 'safetyDistance']
        },
        {
          text: '1.1. Volumen de informes',
          style: ['subheader']
        },
        {
          text: 'Los informes son una herramienta valiosa que te brinda información importante sobre el historial financiero y de alquiler de los posibles arrendatarios, lo que te ayuda a reducir el riesgo y garantizar que tus propiedades estén en buenas manos. Esto se traduce en una mayor estabilidad financiera y una experiencia más satisfactoria para los inquilinos y propietarios por igual.',
          style: ['normalText', 'safetyDistance']
        },
        {
          image: 'chartReports',
          fit: [PAGE_WIDTH, 300],
          pageBreak: 'after'
        },
        {
          text: '1.2. Ratio de ocupación',
          style: ['subheader']
        },
        {
          text: 'El ratio de ocupación por ciudad es un indicador clave que te permite evaluar la efectividad de tu cartera de inmuebles. Este ratio se calcula dividiendo el número de unidades ocupadas en una ciudad específica por el número total de unidades disponibles en esa misma ciudad. El resultado es un porcentaje que te indica el nivel de ocupación de tus propiedades en esa ubicación.',
          style: ['normalText', 'safetyDistance']
        },
        {
          image: 'chartVolumeOccupation',
          fit: [PAGE_WIDTH, 300]
        },
        {
          text: '1.3. Ocupación total',
          style: ['subheader']
        },
        {
          text: 'Tu cartera de activos asciende a 231 de los cuales actualmente hay en alquiler 131 y disponibles para alquiler 100.',
          style: ['normalText', 'safetyDistance']
        }
      ],
      pageBreak: 'after'
    };

    const timeReport = {
      stack: [
        {
          text: '2. Control de tiempo (' + date.getFullYear().toString() + ')',
          style: ['h2']
        },
        {
          text: 'El buen inquilino te facilita un informe sobre tu actividad. Con esto podrás tomar mejores decisiones basadas en datos. Revisa los datos y ajusta tu cartera de manera estratégica, aumentando la rentabilidad y minimizando los riesgos.',
          style: ['normalText', 'safetyDistance']
        },
        {
          text: '2.1. SLA',
          style: ['subheader']
        },
        {
          columns: [
            {
              text: 'Nuestros informes EBI son una herramienta valiosa que te brinda información importante sobre los posibles arrendatarios, lo que te ayuda a reducir el riesgo y garantizar que tus propiedades estén en buenas manos. Esto se traduce en una mayor estabilidad financiera y una experiencia más satisfactoria para los inquilinos y propietarios por igual',
              style: ['normalText', 'safetyDistance'],
              width: 240
            },
            {
              image: 'chartIncidents',
              fit: [200, 200]
            }
          ],
          // optional space between columns
          columnGap: 30,
          marginBottom: 20
        },
        {
          text: 'Las incidencias pueden tener un impacto positivo o negativo en el tiempo necesario para generar informes, dependiendo de cómo se manejen. Es importante contar con un equipo de soporte técnico eficiente y efectivo para manejar las incidencias de manera adecuada y garantizar que los informes se generen en un plazo razonable.',
          style: ['normalText', 'safetyDistance'],
          pageBreak: 'after'
        },
        {
          text: '2.2. Candidaturas',
          style: ['subheader']
        },
        {
          text: 'Cuanto más compleja sea una candidatura, es decir, si implica a múltiples inquilinos o avalistas, puede llevar más tiempo recopilar y verificar la información necesaria para generar el informe.',
          style: ['normalText', 'safetyDistance']
        },
        {
          image: 'chartCandidatureTenant',
          fit: [300, 200]
        }
      ],
      pageBreak: 'after'
    };

    const riskReport = {
      stack: [
        {
          text:
            '3. Control de siniestros (' + date.getFullYear().toString() + ')',
          style: ['h2']
        },
        {
          text: 'La gráfica de control de siniestralidad es una herramienta muy valiosa para monitorear el rendimiento de tus propiedades en términos de seguridad y protección. Esta gráfica muestra el número de incidencias que se han creado en cada alquiler, así como la frecuencia con la que se ha hecho uso del seguro para cubrir estas incidencias.',
          style: ['normalText', 'safetyDistance']
        },
        {
          text: '3.1. Volumen de siniestros',
          style: ['subheader']
        },
        {
          text: 'Al analizar esta información, puedes obtener una mejor comprensión de los riesgos asociados con tus propiedades, y puedes tomar medidas para reducir la probabilidad de que ocurran incidentes en el futuro.',
          style: ['normalText', 'safetyDistance']
        },
        {
          image: 'chartInsurance',
          fit: [PAGE_WIDTH, 300],
          pageBreak: 'after'
        },
        {
          text: '3.2. Volumen de siniestros por provincia',
          style: ['subheader']
        },
        {
          columns: [
            {
              text: 'Conocer los siniestros de impago por provincia puede ser muy útil para los propietarios y administradores de bienes raíces. Al analizar la frecuencia y el tipo de siniestros de impago en cada provincia, se puede obtener una mejor comprensión de los riesgos asociados con la gestión de propiedades en diferentes áreas geográficas.',
              style: ['normalText', 'safetyDistance'],
              width: 240
            },
            {
              image: 'chartInsuranceMonth',
              fit: [230, 200]
            }
          ],
          // optional space between columns
          columnGap: 30,
          marginBottom: 20
        },
        {
          text: '3.3. Volumen de siniestros por precio de alquiler',
          style: ['subheader', 'safetyDistance']
        },
        {
          columns: [
            {
              text: 'Al monitorear los siniestros de impago por precio de alquiler, se puede identificar si hay patrones o tendencias que indiquen una mayor probabilidad de incumplimiento de los pagos por parte de los inquilinos en ciertos niveles de precios de alquiler.',
              style: ['normalText', 'safetyDistance'],
              width: 240
            },
            {
              image: 'chartInsurancePrice',
              fit: [230, 200]
            }
          ],
          // optional space between columns
          columnGap: 30,
          marginBottom: 20
        }
      ],
      pageBreak: 'after'
    };

    const legalText = {
      stack: [
        {
          text: 'Aviso legal',
          style: ['h2']
        },
        {
          text: 'Este documento ha sido generado utilizando datos estadísticos proporcionados por EBI. Si bien hemos tomado todas las precauciones razonables para garantizar la precisión y fiabilidad de los datos y la información contenida en este documento, EBI no asume ninguna responsabilidad por errores, omisiones, inexactitudes o cambios en los datos proporcionados por terceros o por el uso que se haga de la información contenida en este documento.',
          style: ['normalText', 'safetyDistance']
        },
        {
          text: 'Los datos e información proporcionados en este documento son solo para fines informativos y no deben ser interpretados como asesoramiento legal, financiero, fiscal o de otro tipo. Antes de tomar cualquier decisión basada en la información proporcionada en este documento, se recomienda a los usuarios que realicen su propia investigación y consulten con profesionales adecuados.',
          style: ['normalText', 'safetyDistance']
        }
      ]
    };
    // Informes
    const canvasReports = document.getElementById(
      'MyAnalysisMonthChart'
    ) as HTMLCanvasElement;
    const chartReports = canvasReports.toDataURL('image/png');
    const canvasVolumeOccupation = document.getElementById(
      'MyVolumeOcupationChart'
    ) as HTMLCanvasElement;
    const chartVolumeOccupation = canvasVolumeOccupation.toDataURL('image/png');
    // Tiempos
    const canvasIncidents = document.getElementById(
      'MySlaChart'
    ) as HTMLCanvasElement;
    const chartIncidents = canvasIncidents.toDataURL('image/png');

    const canvasCandidatureTenant = document.getElementById(
      'MyCandidatureTenantChart'
    ) as HTMLCanvasElement;
    const chartCandidatureTenant =
      canvasCandidatureTenant.toDataURL('image/png');

    // siniestros
    const canvasInsurance = document.getElementById(
      'MySinesterMonthChart'
    ) as HTMLCanvasElement;
    const chartInsurance = canvasInsurance.toDataURL('image/png');

    const canvasInsuranceMonth = document.getElementById(
      'MyInsuranceProvinceChart'
    ) as HTMLCanvasElement;
    const chartInsuranceMonth = canvasInsuranceMonth.toDataURL('image/png');
    const canvasInsurancePrice = document.getElementById(
      'MyInsusancePriceChart'
    ) as HTMLCanvasElement;
    const chartInsurancePrice = canvasInsurancePrice.toDataURL('image/png');

    let coverImg: string;
    let demoImg: string;
    let ebiImg: string;
    let testaImg: string;
    try {
      coverImg = await getBase64ImageFromURL(
        'https://images.unsplash.com/photo-1565953522043-baea26b83b7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=595&q=90'
      );
    } catch (error) {
      coverImg = '';
    }
    try {
      demoImg = await getBase64ImageFromURL(
        'https://images.unsplash.com/photo-1560520031-3a4dc4e9de0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=' +
          PAGE_WIDTH +
          '&h=260&q=80'
      );
    } catch (error) {
      demoImg = '';
    }
    try {
      ebiImg = await getBase64ImageFromURL(
        'https://images.unsplash.com/photo-1565953522043-baea26b83b7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=595&q=90'
        // 'https://media.licdn.com/dms/image/C4E0BAQFO95a8cGx7WA/company-logo_200_200/0/1611739896122?e=1686182400&v=beta&t=XmqgTmPwrltpHw2Xor8bfuf2fJ1AM2aU96EtG7UTriA'
      );
    } catch (error) {
      ebiImg = '';
    }
    try {
      testaImg = await getBase64ImageFromURL(
        'https://pbs.twimg.com/media/Di4IrhKWwAEVaIq.jpg'
      );
    } catch (error) {
      testaImg = '';
    }

    const pdfDefinition: any = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [PAGE_MARGIN_H, PAGE_MARGIN_V, PAGE_MARGIN_H, PAGE_MARGIN_V],
      info: {
        title: 'Informe de actividad',
        author: 'EBI',
        subject: '10 de marzo de 2023'
      },
      background: function (currentPage) {
        if (currentPage == 1) {
          return {
            image: 'img_cover',
            fit: [595, 'auto'],
            absolutePosition: { x: 0, y: 0 },
            pageBreak: 'after'
          };
        }
      },
      header: function (currentPage) {
        const header_logos = [];

        if (currentPage != 1) {
          header_logos.push(
            {
              width: '*',
              stack: [
                {
                  image: 'logo_ebi',
                  fit: [60, 60],
                  margin: [
                    PAGE_MARGIN_H - 4,
                    PAGE_MARGIN_H,
                    PAGE_MARGIN_H,
                    PAGE_MARGIN_H
                  ]
                }
              ]
            }
            // {
            //   width: 100,
            //   stack: [
            //     {
            //       image: 'logo_testa',
            //       fit: [100, 100],
            //       margin: [0, 40, PAGE_MARGIN_H / 2, 0],
            //       alignment: 'right',
            //     },
            //   ],
            // }
          );
        }
        const header = {
          columns: header_logos
        };
        return header;
      },
      footer: function (currentPage) {
        if (currentPage != 1) {
          return {
            columnGap: 0,
            columns: [
              {
                stack: [
                  {
                    text: 'Informe EBI - ' + dateNow
                  }
                ]
              },
              {
                stack: [
                  {
                    text: ['· ', currentPage, ' ·'].join(''),
                    alignment: 'right'
                  }
                ]
              }
            ],
            style: 'smallText',
            margin: [40, 60, 40, 0]
          };
        }
      },
      content: [
        cover,
        tableOfContents,
        introduction,
        activity,
        timeReport,
        riskReport,
        legalText
      ],
      images: {
        img_cover: coverImg,
        img_demo: demoImg,
        logo_ebi: ebiImg,
        logo_testa: testaImg,
        chartReports: chartReports,
        chartVolumeOccupation: chartVolumeOccupation,
        chartIncidents: chartIncidents,
        chartCandidatureTenant: chartCandidatureTenant,
        chartInsurance: chartInsurance,
        chartInsuranceMonth: chartInsuranceMonth,
        chartInsurancePrice: chartInsurancePrice
      },
      styles: {
        h1: {
          fontSize: 24,
          bold: true,
          margin: [0, 16, 0, 0],
          color: '#021F37'
        },
        h2: {
          fontSize: 18,
          bold: true,
          margin: [0, 16, 0, 16],
          color: '#021F37'
        },
        subheader: {
          fontSize: 16,
          bold: false,
          margin: [0, 16, 0, 16],
          color: '#021F37'
        },
        normalText: {
          fontSize: 12,
          lineHeight: LINE_HEIGHT,
          margin: [0, 0, 0, 8],
          color: '#021F37'
        },
        smallText: {
          fontSize: 10,
          lineHeight: LINE_HEIGHT,
          margin: [0, 0, 0, 8],
          color: '#021F37'
        },
        safetyDistance: {
          margin: [0, 16, 0, 16]
        },
        table: {
          margin: [0, 16, 0, 16]
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black'
        },
        tableFooter: {
          bold: true,
          fontSize: 12,
          color: 'black'
        },
        textLight: {
          color: 'white'
        },
        blueBox: {
          fillColor: '#008BB5',
          margin: [40, 40, 40, 40]
        }
      }
    };

    if (onlypreview) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      pdfMake.createPdf(pdfDefinition, null, null, pdfFonts.pdfMake.vfs);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      pdfMake.createPdf(pdfDefinition, null, null, pdfFonts.pdfMake.vfs).open();
    }
  }

  createCharts() {
    // Charts
    // Informe-mes
    this.analysisMonthData =
      this.allStadistics?.activityCharts?.analysisByMonth?.data;
    this.analysisMonthOptions =
      this.allStadistics?.activityCharts?.analysisByMonth?.options;
    // Ocupación
    this.volumeData = this.allStadistics?.activityCharts?.ocupationRate?.data;
    this.volumeOptions =
      this.allStadistics?.activityCharts?.ocupationRate?.options;
    //Sla
    this.slaData = this.allStadistics?.timesCharts?.sla?.data;
    this.slaOptions = this.allStadistics?.timesCharts?.sla?.options;
    // Candidaturas por nº inquilinos
    this.candidatureTenantsData =
      this.allStadistics?.timesCharts?.tenantByCandidature?.data;
    this.candidatureTenantsOptions =
      this.allStadistics?.timesCharts?.tenantByCandidature?.options;
    // Siniestros mes
    this.monthRatioData =
      this.allStadistics.sinisterCharts?.sinestersByMonth?.data;
    this.monthRatioOptions =
      this.allStadistics.sinisterCharts?.sinestersByMonth?.options;
    // Ratio Provincia
    this.provinceRatioData =
      this.allStadistics?.sinisterCharts?.provinceRatio?.data;
    this.provinceRatioOptions =
      this.allStadistics?.sinisterCharts?.provinceRatio?.options;
    // Ratio Precio
    this.priceRatioData = this.allStadistics?.sinisterCharts?.priceRatio?.data;
    this.priceRatioOptions =
      this.allStadistics?.sinisterCharts?.priceRatio?.options;

    // Informes
    this.analysisMonthChart = new Chart('MyAnalysisMonthChart', {
      type: 'bar',

      data: this.analysisMonthData,
      options: this.analysisMonthOptions
    });
    this.volumeOcupationChart = new Chart('MyVolumeOcupationChart', {
      type: 'bar',

      data: this.volumeData,
      options: this.volumeOptions
    });
    // Tiempos
    this.slaChart = new Chart('MySlaChart', {
      type: 'pie',
      data: this.slaData,
      options: this.slaOptions
    });
    this.candidatureTenantChart = new Chart('MyCandidatureTenantChart', {
      type: 'bar',
      data: this.candidatureTenantsData,
      options: this.candidatureTenantsOptions
    });
    // Siniestros
    this.sinesterMonthChart = new Chart('MySinesterMonthChart', {
      type: 'bar',

      data: this.monthRatioData,
      options: this.monthRatioOptions
    });
    this.insuranceProvinceChart = new Chart('MyInsuranceProvinceChart', {
      type: 'pie',

      data: this.provinceRatioData,
      options: this.provinceRatioOptions
    });
    this.insusancePriceChart = new Chart('MyInsusancePriceChart', {
      type: 'pie',

      data: this.priceRatioData,
      options: this.priceRatioOptions
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
