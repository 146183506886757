import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[elBuenInquilinoOnlyNumberWithDecimals]'
})
export class OnlyNumberWithDecimalsDirective {
  // Expresión regular para aceptar solo comas como separadores decimales
  private regex = new RegExp(/^[0-9]+(,[0-9]*)?$/g);
  private allowedKeys: string[] = ['Backspace', 'ArrowLeft', 'ArrowRight'];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Permitimos solo las teclas especiales Backspace, flechas izquierda y derecha
    if (this.allowedKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = (event.target as HTMLInputElement).value;
    const next: string = current.concat(event.key);

    // Si el valor no coincide con la expresión regular, prevenimos la entrada
    if (!String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
