import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  ApiDto,
  ApiMemberDto,
  AssetDto,
  AssetStatus,
  AssociatedCandidature,
  BuildingDto,
  Candidature,
  CandidatureStatusEnum,
  ChangeApiDto,
  CustomModalButtonRole,
  LabelDto,
  User,
  UserRol
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { FiltersService } from '@core/services/filters/filters.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import {
  AlertController,
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { AddAssetData, AddAssetResponse } from '../../models/add-asset.model';
import { ShowLabelPopover } from '../../models/asset-filtes.model';
import { DisplayedColumns } from '../../models/asset-list.model';
import {
  AssetAddressReturnType,
  AssetItemFunction
} from '../../models/asset-pipes.model';
import {
  AssetUtilsPopoverOption,
  AssetUtilsPopoverResponse
} from '../../models/asset-utils-popover.model';
import { AppointmentFormValue } from '../../models/calendar.model';
import {
  ChangeAssetApiPopoverData,
  ChangeAssetApiPopoverResponse
} from '../../models/change-asset-api-popover.model';
import { tableSize } from '../../utils/global.utils';
import { presentToast } from '../../utils/toast.utils';
import { AddAssetComponent } from '../add-asset/add-asset.component';
import { AssetUtilsPopoverComponent } from '../asset-utils-popover/asset-utils-popover.component';
import { AssociatedCandidaturesPopoverComponent } from '../associated-candidatures-popover/associated-candidatures-popover.component';
import { ChangeAssetApiPopoverComponent } from '../change-asset-api-popover/change-asset-api-popover.component';
import { CreateNewAppointmentModalComponent } from '../create-new-appointment-modal/create-new-appointment-modal.component';

@Component({
  selector: 'el-buen-inquilino-asset-list',
  templateUrl: './asset-list.component.html'
})
export class AssetListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isBuildingPage = false;
  @Input() closedPage = false;
  @Input() candidaturesCurrentMonth = 0;
  @Input() user: User;
  @Input() currentBuilding: BuildingDto;
  @Input() labels: LabelDto[] = [];
  @Input() othersOpen = 0;
  @Input() othersRented = 0;
  @Input() listCancelSub = false;
  @Input() haveChangedDateFilter = false;
  @Input() openedClosedStatus: AssetStatus = AssetStatus.OPEN;

  userApis: ApiDto[] = [];
  screenWidth: number;
  items: AssetDto[] = [];

  widthChangeSub: Subscription;
  getItemsSub: Subscription;
  getApisSub: Subscription;

  @Output() getItems = new EventEmitter<boolean>(true);
  @Output() showLabelPopover = new EventEmitter<ShowLabelPopover>();
  @Output() changeApiEvent = new EventEmitter<ChangeApiDto>();
  @Output() deleteItemEvent = new EventEmitter<string>();

  // TABLA
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<AssetDto> = new MatTableDataSource<AssetDto>(
    []
  );
  selection = new SelectionModel<AssetDto>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  tableSize = tableSize;

  assetAddressReturnType = AssetAddressReturnType;
  assetItemFunction = AssetItemFunction;
  displayedColumnsEnum = DisplayedColumns;

  constructor(
    private windowMeasuresService: WindowMeasuresService,
    private filtersService: FiltersService,
    private authenticationService: AuthService,
    private popoverController: PopoverController,
    private assetService: AssetsApiService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private toastController: ToastController,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private modalController: ModalController,
    private alertController: AlertController,
    private userService: UsersApiService,
    private candidatureService: CandidaturesApiService
  ) {}

  ngOnInit(): void {
    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => (this.screenWidth = width));

    this.setColumnsToShow();

    // Subscripcion para cada vez que se obtengan los items
    // se muestren los cambios en la lista
    this.setGetItemsSub();

    if (this.user.portfolioId || this.user.apiRol === UserRol.API_ADMIN) {
      this.getApis();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.listCancelSub &&
      changes?.listCancelSub.previousValue !== undefined
    ) {
      if (!this.listCancelSub) {
        this.getItems.emit(this.haveChangedDateFilter);
        this.setGetItemsSub();
      } else {
        this.getItemsUnsubscribe();
      }
    }
  }

  trackByAsset(_index: number, asset: AssetDto): AssetDto {
    return asset;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.dataSource.data);
    }
    this.filtersService.setSelectedItems(this.selection.selected);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AssetDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  selectChange(ev: MatCheckboxChange, item: AssetDto): void {
    if (ev) {
      this.selection.toggle(item);
      this.filtersService.setSelectedItems(this.selection.selected);
    }
  }

  tableSort(event: Sort): void {
    if (!event.active || event.direction === '') {
      this.dataSource.data = this.items;
      return;
    }

    const isAsc = event.direction === 'asc';
    const data = JSON.parse(JSON.stringify(this.items)) as AssetDto[];

    const sortedData = data.sort((a: AssetDto, b: AssetDto) => {
      switch (event.active as DisplayedColumns) {
        case DisplayedColumns.ADDRESS:
          return this.compare(a.street, b.street, isAsc);
        case DisplayedColumns.LABEL:
          return this.compare(
            this.getLabelNameById(a.labelId)?.toLowerCase(),
            this.getLabelNameById(b.labelId)?.toLowerCase(),
            isAsc
          );
        case DisplayedColumns.API:
          return this.compare(
            this.user.portfolioId
              ? this.getApiName(a.apiId)?.toLowerCase()
              : this.getApiAssignedName(a, a.userId)?.toLowerCase(),
            this.user.portfolioId
              ? this.getApiName(b.apiId)?.toLowerCase()
              : this.getApiAssignedName(b, b.userId)?.toLowerCase(),
            isAsc
          );
        case DisplayedColumns.RENTAL_PRICE:
          return this.compare(a.rentalPrice, b.rentalPrice, isAsc);
        case DisplayedColumns.CREATION_DATE:
          return this.compare(a.creationDate, b.creationDate, isAsc);
      }
    });

    this.dataSource.data = sortedData;
  }

  setGetItemsSub(): void {
    // Subscripcion para cada vez que se obtengan los items se muestren los cambios en la lista
    this.getItemsSub = this.filtersService
      .getItems()
      .subscribe((resp: AssetDto[]) => {
        Object.assign(this.items, resp);
        this.dataSource = new MatTableDataSource<AssetDto>(
          Object.assign(resp, {})
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  getItemsUnsubscribe(): void {
    this.getItemsSub?.unsubscribe();
  }

  getApis(): void {
    this.getApisSub = this.authenticationService
      .getApisObservable()
      .subscribe((apis: ApiDto[]) => {
        if (!apis) {
          this.userApis = [];
        } else {
          this.userApis = apis;
        }
      });
  }

  buildNoAssetsMessage(): string {
    if (this.user.portfolioId) {
      return 'No existen inmuebles. Puedes introducirlos manualmente o importarlos.';
    }
    if (this.user.apiId) {
      // eslint-disable-next-line max-len
      return 'No existen inmuebles. El dueño de este portfolio no te ha asignado ninguno por el momento.';
    }
  }

  emitShowLabelPopover(ev: ShowLabelPopover): void {
    this.showLabelPopover.emit(ev);
  }

  emitChangeApiEvent(ev: ChangeApiDto): void {
    this.changeApiEvent.emit(ev);
  }

  emitDeleteItemEvent(ev: string): void {
    this.deleteItemEvent.emit(ev);
  }

  presentAssetLabelColorPopover(
    ev: Event,
    showOptions: boolean,
    asset?: AssetDto
  ): void {
    this.showLabelPopover.emit({
      ev,
      showOptions,
      asset
    });
  }

  async showChangeApiPopover(
    ev: Event,
    assetId: string,
    apiId: string,
    currentAgent: string
  ): Promise<void> {
    if (
      this.user.portfolioId &&
      (!this.currentBuilding?.apisId ||
        this.currentBuilding.apisId?.length === 0)
    ) {
      return;
    }

    const componentProps = {};
    let originalAgent = currentAgent;

    if (this.user.portfolioId) {
      componentProps['user'] = this.user;
      componentProps['isPortfolioOwner'] = true;
      componentProps['apis'] = this.getBuildingApis();
      componentProps['currentAgent'] = apiId;
      originalAgent = apiId;
    } else {
      componentProps['api'] = this.filterApiById(apiId);
      componentProps['currentAgent'] = currentAgent;
      originalAgent = currentAgent;
    }

    const popover = await this.popoverController.create({
      component: ChangeAssetApiPopoverComponent,
      event: ev,
      componentProps: componentProps as ChangeAssetApiPopoverData
    });
    await popover.present();

    const { data }: OverlayEventDetail<ChangeAssetApiPopoverResponse> =
      await popover.onWillDismiss();

    if (data) {
      if (data.apiId) {
        // Se llama a servicio para cambiar el api
        this.assetService
          .reassignApi(
            assetId,
            data.newUserId,
            data.apiId === 'null' ? null : data.apiId
          )
          .pipe(first())
          .subscribe({
            next: () => {
              this.changeApiEvent.emit({
                assetId: assetId,
                newApiId: data.apiId === 'null' ? null : data.apiId,
                userId: data.newUserId,
                changeType: 'api'
              });
            },
            error: (err: unknown) => console.error('ERROR cambiar agent', err)
          });
      } else if (data.newUserId !== originalAgent) {
        // Se llama a servicio para cambiar el member
        this.assetService
          .reassignHomeowner(assetId, data.newUserId)
          .pipe(first())
          .subscribe({
            next: () => {
              this.changeApiEvent.emit({
                assetId: assetId,
                newApiId: null,
                userId: data.newUserId,
                changeType: 'member'
              });
            },
            error: (err: unknown) => console.error('ERROR cambiar agent', err)
          });
      }
    }
  }

  getBuildingApis(): ApiDto[] {
    return this.userApis.filter((a: ApiDto) =>
      this.currentBuilding.apisId?.some((id: string) => id === a.id)
    );
  }

  getApiName(apiId: string): string {
    let chipApi: string;
    if (apiId) {
      chipApi = this.filterApiById(apiId)?.name;
    }
    return chipApi ? chipApi : 'Propia';
  }

  getApiAssignedName(asset: AssetDto, userId: string): string {
    const api = this.filterApiById(asset.apiId);

    if (api) {
      if (api.adminId === userId) {
        return 'Propia';
      } else {
        const member = api?.members?.find((m: ApiMemberDto) => m.id === userId);
        if (member) {
          return member.firstname;
        } else {
          return 'Propia';
        }
      }
    }
  }

  async presentUtilsOptionPopover(ev: Event, asset: AssetDto): Promise<void> {
    const selectedAsset: AssetDto = asset;
    if (this.editionPermissions(selectedAsset)) {
      const popover = await this.popoverController.create({
        component: AssetUtilsPopoverComponent,
        cssClass: 'my-custom-class',
        event: ev,
        translucent: true,
        componentProps: {
          closedPage: this.closedPage,
          userHasCalendar: this.user.hasCalendar ?? false
        } as Partial<AssetUtilsPopoverComponent>
      });
      await popover.present();

      const { data }: OverlayEventDetail<AssetUtilsPopoverResponse> =
        await popover.onWillDismiss();
      if (data && selectedAsset) {
        switch (data.options) {
          case AssetUtilsPopoverOption.VIEW_ASSET:
            this.goToCandidature(selectedAsset);
            break;
          case AssetUtilsPopoverOption.DOWNLOAD_ASSET:
            this.downloadAssetReport(selectedAsset);
            break;
          case AssetUtilsPopoverOption.EDIT_ASSET:
            this.presentModal(selectedAsset, true);
            break;
          case AssetUtilsPopoverOption.DELETE_ASSET:
            this.removeAsset(selectedAsset.id);
            break;
          case AssetUtilsPopoverOption.APPOINTMENT:
            this.addAppointment(selectedAsset.id);
            break;
        }
      }
    }
  }

  editionPermissions(asset: AssetDto): boolean {
    if (
      this.user.apiRol === UserRol.API_ADMIN &&
      asset.userId === this.user.id
    ) {
      return true;
    }

    if (asset.buildingId && !this.user.portfolioId) {
      return false;
    }
    return true;
  }

  async goToCandidature(asset: AssetDto): Promise<void> {
    localStorage.setItem(
      'candidaturesCurrentMonth',
      this.candidaturesCurrentMonth.toString()
    );
    this.assetService.selectedAsset = asset;
    if (!asset.visitedAsset) {
      this.assetService
        .updateVisitedStatus(asset.id, true)
        .pipe(first())
        .subscribe({
          next: () => null,
          error: (error: unknown) => console.error(error)
        });
    }

    if (this.closedPage || this.openedClosedStatus === AssetStatus.RENTED) {
      let call: Observable<Candidature[]>;
      if (!!this.user.portfolioId || this.user.apiRol === UserRol.API_ADMIN) {
        call = this.candidatureService.getClosedCandidaturesForBuildings(
          asset.id,
          asset.apiId,
          asset.portfolioId
        );
      } else {
        call = this.candidatureService.getCandidatures(
          '1',
          null,
          asset.id,
          CandidatureStatusEnum.SELECT
        );
      }

      const resp = await lastValueFrom(call.pipe(first()));

      if (!!resp) {
        const candidature = resp[0];

        this.route.navigate(
          [asset.id, 'selected-candidature', candidature.id],
          {
            relativeTo: this.activatedRoute,
            state: {
              candidature,
              asset
            }
          }
        );
      }
    } else {
      this.route.navigate([asset.id, 'candidatures'], {
        relativeTo: this.activatedRoute
      });
    }
  }

  downloadAssetReport(asset: AssetDto): void {
    this.loadingService.presentSecondLoader('Generando pdf...', true);
    this.assetService
      .getAssetReport(asset.id)
      .pipe(first())
      .subscribe({
        next: (blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              this.translate.instant(
                'pages.profile.home-owner.assets.disable_popup_blocker'
              ) as string
            );
          }
          this.loadingService.dismissSecondLoader();
        },
        error: () => {
          this.presentToast(
            this.translate.instant(
              'pages.profile.home-owner.assets.can_not_generate_report'
            ) as string,
            'danger'
          );
          this.loadingService.dismissSecondLoader();
        }
      });
  }

  async presentModal(data?: AssetDto, editMode?: boolean): Promise<void> {
    this.getItemsUnsubscribe();
    const componentProps = {
      editMode
    };

    if (this.isBuildingPage) {
      componentProps['building'] = this.currentBuilding;
      componentProps[
        'buildingAddress'
        // eslint-disable-next-line max-len
      ] =
        // eslint-disable-next-line max-len
        `${this.currentBuilding.street}, ${this.currentBuilding.number}, ${this.currentBuilding.postalCode}, ${this.currentBuilding.town}`;

      if (editMode) {
        componentProps['asset'] = data
          ? (JSON.parse(JSON.stringify(data)) as AssetDto)
          : new AssetDto();
      }
    } else {
      componentProps['asset'] = data
        ? (JSON.parse(JSON.stringify(data)) as AssetDto)
        : new AssetDto();
    }

    componentProps['user'] = this.user;
    componentProps['userApis'] = this.userApis;

    if (this.user.apiRol === UserRol.API_ADMIN) {
      componentProps['apiId'] = this.userApis[0].id;
    }

    const modal = await this.modalController.create({
      component: AddAssetComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: componentProps as AddAssetData,
      backdropDismiss: this.isBuildingPage ? false : true
    });
    modal.onDidDismiss().then((close: OverlayEventDetail<AddAssetResponse>) => {
      this.setGetItemsSub();
      if (this.isBuildingPage) {
        const { data } = close;
        if (data && data.dismissed) {
          return;
        }
        if (data) {
          if (data.edit) {
            this.getItems.emit(true);
          } else {
            // Si no se ha seleccionado Api, ponemos el id del usuario logado
            let apiAdminId: string = data.apiAdminId;
            if (!apiAdminId) {
              apiAdminId = this.user.id;
            }

            this.assetService
              .createAssetsFromForm(
                this.currentBuilding.id,
                data.selectedApiId,
                apiAdminId,
                data.newAsset
              )
              .pipe(first())
              .subscribe({
                next: () => {
                  this.filtersService.unshiftAsset();
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error: (err: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  if (err.error?.code === '101') {
                    this.presentToast(
                      // eslint-disable-next-line max-len
                      `No se ha podido crear el activo porque ya está dado de alta previamente en el edificio.`,
                      'danger',
                      5000
                    );
                  }
                }
              });
          }
        }
      } else {
        if (close && close.data && close.data.saving) {
          this.getItems.emit(true);
        }
      }
    });
    await modal.present();
  }

  async presentToast(
    msg: string,
    toastcolor: string,
    duration: number = 2000
  ): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  async removeAsset(assetId: string): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.profile.home-owner.assets.sure_to_delete'
      ) as string,
      message: this.translate.instant(
        'pages.profile.home-owner.assets.delete_message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.cancel'
          ) as string,
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.delete_message_button'
          ) as string,
          cssClass: 'danger',
          handler: (): void => {
            this.deleteAsset(assetId);
          }
        }
      ]
    });
    await alert.present();
  }

  deleteAsset(assetId: string): void {
    this.assetService.deleteAsset(assetId).subscribe(() => {
      this.presentToast(
        this.translate.instant(
          'pages.profile.home-owner.assets.deletedOK'
        ) as string,
        'success'
      );
      this.deleteItemEvent.emit(assetId);
    });
  }

  async addAppointment(assetId: string): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateNewAppointmentModalComponent,
      backdropDismiss: true,
      componentProps: {
        user: this.user,
        showLinkTemplate: false
      } as Partial<CreateNewAppointmentModalComponent>
    });
    await modal.present();

    const { data, role }: OverlayEventDetail<AppointmentFormValue> =
      await modal.onWillDismiss();

    if (role === (CustomModalButtonRole.CREATE as string)) {
      this.onNewAppointmentDismiss(data, assetId);
    }
  }

  async presentAssociatedCandidaturesPopover(
    ev: Event,
    associatedCandidatures: AssociatedCandidature[]
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: AssociatedCandidaturesPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: { associatedCandidatures }
    });
    await popover.present();
  }

  closedAssetRentalPriceTitle(asset: AssetDto): string {
    return this.closedPage
      ? (this.translate.instant(
          'pages.profile.home-owner.assets.requested_verified',
          {
            totalRequested: asset?.reports?.totalRequested,
            totalVerified: asset?.reports?.totalVerified
          }
        ) as string)
      : '';
  }

  async downloadPolicy(asset: AssetDto): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .getPolicyReport(asset.userId, asset.hasPolicyNumber)
      .pipe(first())
      .subscribe({
        next: async (blob: Blob) => {
          await this.loadingService.dismissSecondLoader();
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              this.translate.instant(
                'pages.profile.home-owner.assets.disable_popup_blocker'
              )
            );
          }
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
          this.presentToast(
            this.translate.instant(
              'components.selected-candidature-modal.can_not_download'
            ) as string,
            'danger'
          );
        }
      });
  }

  async downloadTenantPolicy(asset: AssetDto): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .downloadTenantPolicy(asset.hasTenantPolicyNumber)
      .pipe(first())
      .subscribe({
        next: async (blob: Blob) => {
          await this.loadingService.dismissSecondLoader();
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              this.translate.instant(
                'pages.profile.home-owner.assets.disable_popup_blocker'
              )
            );
          }
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
          this.presentToast(
            this.translate.instant(
              'components.selected-candidature-modal.can_not_download'
            ) as string,
            'danger'
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.widthChangeSub?.unsubscribe();
    this.getApisSub?.unsubscribe();
  }

  private async onNewAppointmentDismiss(
    data: AppointmentFormValue,
    assetId: string
  ): Promise<void> {
    data.assetId = assetId;
    await this.loadingService.presentLoading(
      this.translate.instant(
        'components.owner_calendar.create_loading'
      ) as string
    );
    this.userService
      .createAppointment(data)
      .pipe(first())
      .subscribe({
        next: async () => {
          presentToast(this.toastController, 'Cita creada correctamente');
          await this.loadingService.dismissLoading();
        },
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  private filterApiById(apiId: string): ApiDto {
    return this.userApis.find((api: ApiDto) => api.id === apiId);
  }

  private getLabelNameById(id: number): string {
    if (!id) {
      return this.translate.instant(
        'pages.profile.home-owner.assets.no_label'
      ) as string;
    }

    return this.labels.find((label: LabelDto) => label.id === id)?.name || null;
  }

  private compare(
    a: number | Date | string,
    b: number | Date | string,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private setColumnsToShow(): void {
    if (this.isBuildingPage) {
      this.setBuildingColumns();
    } else if (this.closedPage) {
      this.setClosedColumns();
    } else {
      this.setOpenColumns();
    }
  }

  private setBuildingColumns(): void {
    this.displayedColumns = [
      DisplayedColumns.ACTIONS,
      DisplayedColumns.SELECT,
      DisplayedColumns.ADDRESS,
      DisplayedColumns.INFO,
      DisplayedColumns.LABEL,
      DisplayedColumns.API,
      DisplayedColumns.RENTAL_PRICE,
      DisplayedColumns.CREATION_DATE
    ];
  }

  private setOpenColumns(): void {
    this.displayedColumns = [
      DisplayedColumns.ACTIONS,
      DisplayedColumns.ADDRESS,
      DisplayedColumns.INFO,
      DisplayedColumns.LABEL,
      DisplayedColumns.API,
      DisplayedColumns.RENTAL_PRICE,
      DisplayedColumns.CREATION_DATE
    ];
  }

  private setClosedColumns(): void {
    this.displayedColumns = [
      DisplayedColumns.ADDRESS,
      DisplayedColumns.INFO,
      DisplayedColumns.LABEL,
      DisplayedColumns.API,
      DisplayedColumns.RENTAL_PRICE
    ];
  }
}
