/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DOCUMENT } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { LabelDto } from '@core/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-manage-tags',
  templateUrl: './manage-tags.html'
})
export class ManageTagsComponent {
  @Input() labels: LabelDto[];

  modalTitle = 'Administar etiquetas';

  constructor(
    @Inject(DOCUMENT) document,
    private modalController: ModalController,
    private assetService: AssetsApiService
  ) {}

  dismiss(saving?): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  getColor(label: LabelDto): string {
    return label !== null && label !== undefined
      ? label.value[0] === '#'
        ? label.value
        : '#' + label.value
      : '#fafafa';
  }

  selectInput(item: any): void {
    const inputField: HTMLInputElement = document.querySelector(
      '#label' + item + '>input'
    );
    inputField.select();
  }

  saveLabels(): void {
    this.assetService.updateLabels(this.labels).subscribe(
      () => this.dismiss(true),
      (error) => console.log(error)
    );
  }
  canSaveLabels(): boolean {
    return this.labels.some(
      (label) =>
        label.name === null ||
        label.name === undefined ||
        label.name.length === 0 ||
        label.name.trim().length === 0
    );
  }

  onInputClicked(event: Event): void {
    const target = event.target as HTMLInputElement;
    target.select();
  }
}
