import { Pipe, PipeTransform } from '@angular/core';
import { differenceInYears } from 'date-fns';

@Pipe({
  name: 'yearsFromDate'
})
export class YearsFromDatePipe implements PipeTransform {
  transform(date: Date): number {
    return differenceInYears(new Date(), date);
  }
}
