import { ElementRef } from '@angular/core';
import { fileToBase64, loadImage } from '@core/services/utils/utils.service';
import html2canvas from 'html2canvas';

export async function elementRefToImage(
  element: ElementRef<HTMLDivElement>
): Promise<string> {
  const canvas = await html2canvas(element.nativeElement);
  return canvas.toDataURL('image/png');
}

export function getBase64ImageFromURL(url: string): Promise<string> {
  return new Promise(
    (resolve: (value: string) => void, reject: (value: string) => void) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = (): void => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error: string): void => {
        reject(error);
      };

      img.src = url;
    }
  );
}

export async function googleMapsStaticImgToBase64(
  elementRef: ElementRef<HTMLImageElement>
): Promise<string> {
  const canvas = await html2canvas(elementRef.nativeElement, {
    allowTaint: true,
    useCORS: true
  });
  return canvas.toDataURL('image/png');
}

export async function getImage(route: string): Promise<string> {
  const blob = await loadImage(route);
  return await fileToBase64(blob);
}
