import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { Catalog, CatalogEnum } from '../../models';

@Injectable()
export class CatalogsApiService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.spaces;
  }

  getCatalogs(catalogType: CatalogEnum): Observable<Catalog[]> {
    const endpoint = `/catalog/${catalogType}`;

    return this.http.get<Catalog[]>(`${this.url}${endpoint}`);
  }
}
