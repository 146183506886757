import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  AnalysisCatalogue,
  CustomerCareTenantList,
  SocialMediaInfo
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';

import { SocialMediaAnalysisForm } from '../../models/social-media-analysis.model';
import { TenantActionsModalComponent } from '../tenant-actions-modal/tenant-actions-modal.component';

@Component({
  selector: 'el-buen-inquilino-social-media-analysis',
  templateUrl: './social-media-analysis.component.html'
})
export class SocialMediaAnalysisComponent implements OnInit, OnChanges {
  form: FormGroup<SocialMediaAnalysisForm>;
  @Input() currentTenant: CustomerCareTenantList;
  @Input() catalogue: AnalysisCatalogue;
  isDisabledForm = false;
  @Output() savedSocialMediaForm = new EventEmitter<UntypedFormGroup>();
  @Output() turnSavingFalse = new EventEmitter();
  @Input() saving: boolean;
  @Input() modalMode = false;
  @Input() status: string;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.currentTenant) {
      this.initializeForm();
      this.loadForm();
    }
  }

  initializeForm(): void {
    this.form = new FormGroup({
      facebook: new FormControl<string>(null),
      twitter: new FormControl<string>(null),
      linkedin: new FormControl<string>(null),
      reliability: new FormControl<string>(null),
      notes: new FormControl<string>(null),
      //Se Eliminan version incofisa (Modal)
      position: new FormControl<string>(null),
      income: new FormControl<number>(null),
      businessName: new FormControl<string>(null),
      sector: new FormControl<string>(null),
      contractType: new FormControl<string>(null),
      antiquity: new FormControl<string>(null)
    });
  }

  ngOnChanges(): void {
    // caso inquilinos con nodo Research (No modal)
    if (this.currentTenant && !this.saving) {
      this.initializeForm();
      this.loadForm();
    }
    // Caso de guardar
    if (this.saving) {
      this.saveForm();
    }
  }

  loadForm(): void {
    let facebook: string, twitter: string, linkedin: string;

    if (this.currentTenant?.researchLevels?.socialMediaInfo?.length > 0) {
      this.currentTenant?.researchLevels.socialMediaInfo.forEach(
        (smi: SocialMediaInfo) => {
          if (smi.socialNetwork === 'facebook') {
            facebook = smi.url;
          } else if (smi.socialNetwork === 'twitter') {
            twitter = smi.url;
          } else {
            linkedin = smi.url;
          }
        }
      );
    }

    this.form.patchValue({
      facebook,
      twitter,
      linkedin,
      reliability:
        this.currentTenant.researchLevels?.socialMediaReliability || null,
      notes: this.currentTenant.researchLevels?.socialMediaNote || null,
      // Para incofisa (modal) se envia lo que haya en user por defecto
      income: this.currentTenant.researchLevels?.income || null,
      businessName: this.currentTenant.researchLevels?.businessName || null,
      sector: this.currentTenant.researchLevels?.sector || null,
      contractType: this.currentTenant.researchLevels?.contractType || null,
      antiquity: this.currentTenant.researchLevels?.antiquity || null,
      position: this.currentTenant.researchLevels?.position || null
    });
  }

  copyToClipboard(data: string): void {
    const listener = (e: ClipboardEvent): void => {
      e.clipboardData.setData('text/plain', data);
      e.preventDefault();
      document.removeEventListener('copy', listener);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
  }

  // save envia data props o hace output segun modo
  saveForm(): void {
    if (this.modalMode) {
      if (this.form.status === 'INVALID') {
        this.presentToast(
          'Es necesario rellenar datos de analysis redes sociales',
          'danger'
        );
        return;
      }
      this.dismiss(true);
    } else {
      this.savedSocialMediaForm.emit(this.form);
      this.turnSavingFalse.emit();
    }
  }

  // Version Modal
  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving,
      form: saving ? this.form : null,
      tenant: saving ? this.currentTenant : null
    });
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  showAnalysisBlock(): boolean {
    return (
      this.currentTenant &&
      !this.currentTenant.student &&
      !this.currentTenant.business &&
      !this.modalMode &&
      this.status !== 'NOT_REGISTERED' &&
      this.status !== 'NOT_ACCEPTED'
    );
  }

  async openActionsModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: TenantActionsModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        currentTenant: this.currentTenant
      }
    });
    modal.onDidDismiss().then(() => null);
    return await modal.present();
  }
}
