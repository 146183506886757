export enum PoliciesDisplayedColumnsEnum {
  ID = 'id',
  POLICY_NUMBER = 'policyNumber',
  TYPE = 'type',
  ADDRESS = 'address',
  ASSET_RENT = 'assetRent',
  PREMIUM = 'premium',
  INIT_DATE = 'initDate',
  END_DATE = 'endDate',
  UPDATE_DATE = 'updateDate',
  TENANTS = 'tenants',
  // Solo backoffice
  CLIENT = 'client',
  ACTIONS = 'actions'
}
