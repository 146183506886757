import { Pipe, PipeTransform } from '@angular/core';
import { SinisterThread, User } from '@core/models';

import { SinisterMessageWriterPipeArgs } from '../models/sinister-message-pipe.model';

@Pipe({
  name: 'internalNoteWriter'
})
export class InternalNoteWriterPipe implements PipeTransform {
  transform(
    message: SinisterThread,
    { user, users }: SinisterMessageWriterPipeArgs
  ): string {
    if (message.userId === user.id) {
      return 'Yo';
    }

    const foundUser = users?.find((u: User) => u.id === message.userId);

    return `${foundUser.firstname} ${foundUser.surname1}`;
  }
}
