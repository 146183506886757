import { ErrorResponse } from '@core/models';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { presentToast } from './toast.utils';

export function manageErrorUpdate(
  toastController: ToastController,
  translate: TranslateService,
  error: ErrorResponse
): void {
  if (error?.code === '100') {
    presentToast(
      toastController,
      translate.instant('pages.profile.tenant.invalidDni') as string,
      'warning',
      10000
    );
  } else if (error?.code === '209') {
    presentToast(
      toastController,
      translate.instant('pages.profile.tenant.requiredDni') as string,
      'danger',
      5000
    );
  } else if (error?.code === '218') {
    presentToast(
      toastController,
      translate.instant('pages.login.error.phone-exist') as string,
      'danger',
      5000
    );
  } else {
    presentToast(
      toastController,
      translate.instant('pages.profile.tenant.invalidForm') as string,
      'danger',
      5000
    );
  }
}
