import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { CountryCode } from 'libphonenumber-js/types.d';

@Directive({
  selector: '[elBuenInquilinoPhoneNgmodel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneNgmodelDirective, multi: true }
  ]
})
export class PhoneNgmodelDirective implements Validator {
  @Input() countryCode: CountryCode;

  constructor(private phoneMaskService: PhoneMaskService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null; // No hay valor, así que no hay error de validación
    }

    const isValid = this.phoneMaskService.isValidPhoneNumberWithIsoCode(
      control.value as string,
      this.countryCode
    );

    return isValid ? null : { phone: true }; // Devuelve { phone: true } si no es válido
  }
}
