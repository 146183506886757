import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { PolicyIssue, Sinister, SinisterThread, User } from '@core/models';
import { ModalController } from '@ionic/angular';
import { first, Observable } from 'rxjs';

import { CommentsForm } from '../../models/sinister-detail-modal.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-policy-conversation',
  templateUrl: './policy-conversation.component.html'
})
export class PolicyConversationComponent implements OnInit {
  @Input({ required: true }) messages: SinisterThread[] = [];
  @Input({ required: true }) user: User;
  @Input({ required: true }) users: User[];
  @Input({ required: true }) policy: PolicyIssue;
  @Input({ required: true }) sinister: Sinister;
  @Input() isIncofisa = false;
  @Input() isInternalNotes = false;

  form: FormGroup<CommentsForm>;
  messagesList: SinisterThread[] = [];

  get showForm(): boolean {
    return (!!this.policy && !this.policy.cancellationDate) || !!this.sinister;
  }

  get isAddCommentButtonDisabled(): boolean {
    return (
      this.messageControl.value === null ||
      this.messageControl.value?.length < 10
    );
  }

  get messageControl(): FormControl<string> {
    return this.form.controls.message;
  }

  get title(): string {
    if (!!this.policy) {
      return this.policy.policyNumber;
    } else if (!!this.sinister) {
      return this.sinister?.policyNumber
        ? this.sinister.policyNumber
        : this.sinister.id;
    }
  }

  get userId(): string {
    if (!!this.policy) {
      return this.policy.userId;
    } else if (!!this.sinister) {
      return this.sinister.userId;
    }
  }

  constructor(
    private modalController: ModalController,
    private candidatureService: CandidaturesApiService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.setList();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  setList(): void {
    this.messagesList = (
      copyObject(this.messages) as SinisterThread[]
    ).reverse();
  }

  sendNewMessage(): void {
    const data: SinisterThread = {
      date: new Date(),
      message: this.form.controls.message.value,
      userId: this.user.id,
      internalNote: this.isInternalNotes
    };
    let call: Observable<unknown>;
    if (!!this.policy) {
      call = this.candidatureService.addCommentToPolicyIssue(
        this.policy.id,
        data
      );
    } else if (!!this.sinister) {
      call = this.candidatureService.addCommentToCancelledPolicy(
        this.sinister.id,
        data
      );
    }

    call.pipe(first()).subscribe(() => {
      this.messages.unshift(data);
      this.setList();
      this.form.controls.message.reset();
    });
  }

  private setForm(): void {
    this.form = new FormGroup<CommentsForm>({
      message: new FormControl<string>(null)
    });
  }
}
