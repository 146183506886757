/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { TranslateModule } from '@ngx-translate/core';
import { StarRatingModule } from 'angular-star-rating';
import { NgChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';

import { HeaderComponent } from './components/header/header.component';
import { HeaderUserPopoverComponent } from './components/header-user-popover/header-user-popover.component';
import { ModalComponent } from './components/modal/modal.component';
import { PhoneFlagModalComponent } from './components/phone-flag-modal/phone-flag-modal.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { MaterialModule } from '../material/material.module';

// TODO: hay algunos que tendran que ser quitados de aqui y movidos a sus
// respectivos modulos
const COMPONENTS = [
  HeaderComponent,
  HeaderUserPopoverComponent,
  ModalComponent,
  TermsOfUseComponent,
  PhoneInputComponent,
  PhoneFlagModalComponent
];

const ANGULAR_PIPES = [DecimalPipe, TitleCasePipe];

const ANGULAR_MODULES = [
  CommonModule,
  IonicModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule
];

const MODULES = [
  ClipboardModule,
  GoogleMapsModule,
  MaterialModule,
  NgChartsModule,
  PdfViewerModule,
  MaskitoModule
];

const SCHEMAS = [CUSTOM_ELEMENTS_SCHEMA];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...ANGULAR_MODULES,
    ...MODULES,
    TranslateModule.forChild(),
    StarRatingModule.forRoot()
  ],
  exports: [
    // Modulos
    ...ANGULAR_MODULES,
    ...MODULES,
    TranslateModule,
    StarRatingModule,
    // Componentes
    ...COMPONENTS
  ],
  schemas: [...SCHEMAS],
  providers: [...ANGULAR_PIPES]
})
export class SharedModule {}
