import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { PolicyIssue } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { AlertController, ModalController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';
import { first } from 'rxjs';

import { ButtonsRole } from '../../models/button.model';
import { UpdateAssetRentPriceForm } from '../../models/update-asset-rent-price-modal.model';

@Component({
  selector: 'el-buen-inquilino-update-asset-rent-price-modal',
  templateUrl: './update-asset-rent-price-modal.component.html'
})
export class UpdateAssetRentPriceModalComponent implements OnInit {
  @Input() insurance: PolicyIssue;

  form: FormGroup<UpdateAssetRentPriceForm>;

  get assetRentControl(): FormControl<string> {
    return this.form.controls.assetRent;
  }

  get rentalPrice(): number {
    return this.insurance.assetRent;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private alertController: AlertController,
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL, data?: PolicyIssue): void {
    this.modalController.dismiss(data, role);
  }

  async confirm(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const alert = await this.alertController.create({
      // eslint-disable-next-line max-len
      message: `El precio del activo para esta póliza pasará a ser de ${this.assetRentControl.value}€`,
      buttons: [
        {
          text: 'Cancelar',
          role: ButtonsRole.CANCEL
        },
        {
          text: 'Aceptar',
          role: ButtonsRole.ACCEPT
        }
      ]
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === (ButtonsRole.ACCEPT as string)) {
      this.update();
    }
  }

  private async update(): Promise<void> {
    const newRent = Number(this.assetRentControl.value.replace(',', '.'));
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .updatePolicyPrice(this.insurance.policyNumber, newRent)
      .pipe(first())
      .subscribe({
        next: async () => {
          await this.loadingService.dismissSecondLoader();
          this.insurance.assetRent = newRent;
          this.insurance.renewedDate = new Date();
          this.dismiss(ButtonsRole.ACCEPT, this.insurance);
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
        }
      });
  }

  private setForm(): void {
    this.form = new FormGroup<UpdateAssetRentPriceForm>({
      assetRent: new FormControl<string>(null, [
        Validators.required,
        appValidators.onlyNumericWithSpanishDecimalsValidator
      ])
    });
  }
}
