import { Component, Input } from '@angular/core';
import { AssetDto, LabelDto } from '@core/models';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-asset-color-label-popover',
  templateUrl: './assets-color-label-popover.component.html'
})
export class AssetColorLabelPopoverComponent {
  @Input() labels: LabelDto[];
  @Input() asset: AssetDto;
  @Input() showOptions: boolean;

  constructor(private popoverController: PopoverController) {}

  onClickUtilOptions(options: string, label?: LabelDto): void {
    this.popoverController.dismiss({
      options: options,
      label,
      asset: this.asset
    });
  }

  getColor(label: LabelDto): string {
    return label !== null && label !== undefined
      ? label.value[0] === '#'
        ? label.value
        : '#' + label.value
      : '#fafafa';
  }
}
