import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { Candidature, ComercialNote, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { Observable, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import {
  CandidatureNotesForm,
  CandidatureNotesFunction
} from '../../models/candidature-notes.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-candidature-notes',
  templateUrl: './candidature-notes.component.html'
})
export class CandidatureNotesComponent implements OnInit, OnChanges {
  @Input() candidature: Candidature;
  @Input() user: User;
  @Input() users: User[] = [];

  candidatureNotesFunction = CandidatureNotesFunction;
  form: FormGroup<CandidatureNotesForm>;
  userId: string;

  @Output() newNote = new EventEmitter<boolean>();

  private showNoteWrapper = false;

  get messageControl(): FormControl<string> {
    return this.form.controls.message;
  }

  get thereIsNotes(): boolean {
    return (
      (this.candidature?.comercialNotes?.length > 0 && this.showNoteWrapper) ||
      false
    );
  }

  constructor(
    private utils: UtilsService,
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService,
    private userService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['candidature'] && !!changes['candidature'].currentValue) {
      this.setCandidature(changes['candidature'].currentValue as Candidature);
    }
    if (changes['user'] && !!changes['user'].currentValue) {
      const user = changes['user'].currentValue as User;
      this.setUserId(user);
      this.setUsers(user);
    }
  }

  trackByNotes(index: number, note: ComercialNote): ComercialNote {
    return note;
  }

  async createNote(): Promise<void> {
    if (this.form.invalid) {
      this.utils.showFormErrors(this.form);
      return;
    }

    const note: ComercialNote = {
      date: new Date(),
      userId: this.user.id,
      message: this.messageControl.value
    };

    await this.loadingService.presentLoading(null);

    this.candidatureService
      .createCommercialNotes(this.candidature.id, note)
      .pipe(first())
      .subscribe(
        async () => {
          this.onSuccessCreateNote(note);
          await this.loadingService.dismissLoading();
        },
        async () => await this.loadingService.dismissLoading()
      );
  }

  private setForm(): void {
    this.form = new FormGroup<CandidatureNotesForm>({
      message: new FormControl<string>(null, Validators.required)
    });
  }

  private setCandidature(candidature: Candidature): void {
    this.candidature = candidature;
  }

  private setUserId(user: User): void {
    this.user = user;
    this.userId = user.id;
  }

  private async setUsers(user: User): Promise<void> {
    this.showNoteWrapper = false;
    if (this.candidature?.comercialNotes?.length > 0) {
      this.users = [];
      let usersId = this.candidature.comercialNotes
        .filter((note: ComercialNote) => note.userId !== this.user.id)
        .map((note: ComercialNote) => note.userId);
      usersId = [...new Set(usersId)];
      const calls: Observable<User>[] = [];
      usersId.forEach((id: string) =>
        calls.push(this.userService.getUserObservable(id))
      );
      if (calls.length > 0) {
        const users: User[] = await forkJoin(calls).pipe(first()).toPromise();
        this.users.push(...users);
      }
    }

    if (!!this.user) {
      this.users.push(user);
    }

    this.candidature = copyObject(this.candidature) as Candidature;
    this.showNoteWrapper = true;
  }

  private onSuccessCreateNote(note: ComercialNote): void {
    this.newNote.emit(true);
    if (!this.candidature.comercialNotes) {
      this.candidature.comercialNotes = [];
    }

    this.candidature.comercialNotes.push(note);
    this.candidature = copyObject(this.candidature) as Candidature;

    this.form.reset();
  }
}
