import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from '@core/models';
import { MenuService } from '@core/services/menu/menu.service';
import { SplitPaneMenuService } from '@core/services/split-pane-menu/split-pane-menu.service';
import { MenuController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-vertical-menu',
  templateUrl: './vertical-menu.component.html'
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  pages: MenuItem[];

  path: string;

  typeUser: string;

  currentYear: number = new Date().getFullYear();

  @Input() disableSplitPane = false;
  disableSplitPaneSub: Subscription;
  pagesSub: Subscription;

  @Input() isIncofisa = false;

  constructor(
    private menuService: MenuService,
    private menu: MenuController,
    private modalController: ModalController,
    private splitPaneMenuService: SplitPaneMenuService
  ) {}

  ngOnInit(): void {
    this.pagesSub = this.menuService.pages$.subscribe((pages: MenuItem[]) => {
      this.pages = pages;
      this.typeUser = this.menuService.getMenuUserType();
    });

    if (!this.isIncofisa) {
      this.disableSplitPaneSub = this.splitPaneMenuService
        .getShowSplitPane()
        .subscribe((value: boolean) => (this.disableSplitPane = !value));
    }
  }

  closeMenu(): void {
    this.menu.close();
  }

  ngOnDestroy(): void {
    this.disableSplitPaneSub?.unsubscribe();
    this.pagesSub?.unsubscribe();
  }
}
