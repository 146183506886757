/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import {
  DocumentDTO,
  Month,
  Quarter,
  TypeDocumentEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { prepareFile } from '@core/services/utils/utils.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { format } from 'date-fns';
import * as moment from 'moment';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-add-document',
  templateUrl: './add-document.component.html'
})
export class AddDocumentComponent implements OnInit {
  subscriptions: Subscription[] = [];
  typeDocumentEnum = TypeDocumentEnum;
  calculatedTypeDocumentEnum: {
    [key: string]: string;
  };
  file: File;
  saving = false;
  @Input() document: DocumentDTO;
  @Input() user: User;
  @Input() typeDocumentInput?: TypeDocumentEnum;
  @Input() name: string;
  @Input() selectedMonth = 0;
  @Input() selectedQuarter = '';
  @Input() selectedQuarterMoment: moment.Moment;
  formatSelectedQuarter = '';
  authenticatedUser: User;
  @Input() userType = 'tenant';
  @Input() policyNumber: string;

  monthList: Month[] = [];
  quarterList: Quarter[] = [];
  validationErrorMessages = appValidators.validationErrorMessages;

  quarterPopoverConfig = {
    cssClass: 'quarter-popover'
  };

  years: string[] = [];

  constructor(
    private authenticationService: AuthService,
    private modalController: ModalController,
    private documentService: DocumentsApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private loadingService: LoadingService,
    public alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.prepareYears();

    // Segun el userType "tenant" o "homeOwner" se permitiran subir unos documento u otros.
    this.calculateDocumentEnumByUserType();
    this.writeMonthOptions();
    this.writeQuarterOptions();

    if (this.selectedQuarterMoment) {
      this.selectedQuarterMoment = this.getQuarterDate(
        this.selectedQuarterMoment
      );
      this.document.date = this.selectedQuarterMoment.format('DD/MM/YYYY');
    }

    this.authenticatedUser = this.authenticationService.user;
    if (this.typeDocumentInput) {
      this.document.typeDocument = this.typeDocumentInput;
    }
    if (this.typeDocumentInput === TypeDocumentEnum.PAYSLIP) {
      this.document.date = this.formatSelectDate();
    }
  }

  dismiss(saving?): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  changeListener(event): void {
    this.file = prepareFile(event.target.files[0] as File);
  }

  calculateDocumentName(typeDocument: TypeDocumentEnum, expDate: any): string {
    const fixText: string =
      this.calculateUserFullName() +
      '-' +
      (expDate ? this.replaceSlashOnDate(expDate) : '');

    switch (typeDocument) {
      case TypeDocumentEnum.DNI:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.DNI'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.PAYSLIP:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.PAYSLIP'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.INCOME_TAX_DECLARATION:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.INCOME_TAX_DECLARATION_document'
          ) +
          '-' +
          fixText
        );

      case TypeDocumentEnum.PENSIONER_RECOGNITION:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.PENSIONER_RECOGNITION_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.IS200_MODEL:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.IS200_MODEL_document'
          ) +
          '-' +
          this.document.sourceId +
          '-' +
          fixText
        );
      case TypeDocumentEnum.IVA_QUARTER_MODEL:
        if (!this.selectedQuarter) {
          this.selectedQuarter = moment(expDate, 'DD/MM/YYYY')
            .subtract()
            .quarter()
            .toString();
        }
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.IVA_QUARTER_MODEL_document'
          ) +
          '-Q' +
          this.selectedQuarter +
          '-' +
          fixText
        );
      case TypeDocumentEnum.WORK_LIFE:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.WORK_LIFE_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.MODEL_130:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.MODEL_130_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.SS_CERTIFICATE:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.SS_CERTIFICATE_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.AEAT_CERTIFICATE:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.AEAT_CERTIFICATE_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.PYG_QUARTER:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.PYG_QUARTER_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.WORK_CONTRACT:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.WORK_CONTRACT_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.BANK_STATEMENT:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.BANK_STATEMENT_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.REMOTE_JOB_CERTIFICATE_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.INVOICE_PAYMENT_CONFIRMATION_document'
          ) +
          '-' +
          fixText
        );

      case TypeDocumentEnum.INVOICE_RECORD_BOOK:
        return (
          this.translate.instant(
            'pages.documents.create-page.document-types.INVOICE_RECORD_BOOK_document'
          ) +
          '-' +
          fixText
        );
      case TypeDocumentEnum.OTHER_DOCUMENT:
        return (
          this.name +
          '-' +
          this.calculateUserFullName() +
          '-' +
          (expDate ? this.replaceSlashOnDate(expDate) : '')
        );
      case TypeDocumentEnum.RENTAL_CONTRACT:
        return this.name + '-' + this.user?.firstname;

      default:
        return typeDocument + fixText;
    }
  }

  replaceSlashOnDate(processDateString: string): string {
    let replacedString = '';
    if (processDateString) {
      replacedString = processDateString.replace(/\//g, '-');
    }
    return replacedString;
  }

  addShortedTimestamp(): string {
    const date = new Date();
    let timeStamp = date.getTime().toString();
    timeStamp = timeStamp.substr(-4);
    return timeStamp;
  }

  calculateUserFullName(): string {
    return this.user.firstname;
  }

  saveDocument(form: NgForm): void {
    this.saving = true;
    if (form.invalid) {
      Object.keys(form.controls).forEach((control) => {
        form.controls[control].markAsTouched();
        form.controls[control].markAsDirty();
      });

      const messageKey = 'pages.documents.create-page.savedKO';
      this.presentToast(messageKey, 'danger');
      this.saving = false;
    } else {
      // this.loadingService.presentSecondLoader(null);
      const documentForm: DocumentDTO = form.form.value;

      if (documentForm.expirationDate) {
        documentForm.expirationDate = format(
          documentForm.expirationDate as Date,
          'dd/MM/yyyy'
        );
      } else if (documentForm.date && documentForm.date instanceof Date) {
        documentForm.date = format(documentForm.date, 'dd/MM/yyyy');
      }

      // Le ponemos el nombre al documento excepto si es other document
      if (documentForm.typeDocument !== TypeDocumentEnum.OTHER_DOCUMENT) {
        documentForm.name = this.calculateDocumentName(
          documentForm.typeDocument as TypeDocumentEnum,
          documentForm.expirationDate
            ? documentForm.expirationDate
            : documentForm.date
              ? (documentForm.date as Date)
              : documentForm.dateYear
        );
        //Caso propietario -> Contrato de alquiler para incidencias con póliza
        if (
          documentForm.typeDocument === TypeDocumentEnum.RENTAL_CONTRACT &&
          (this.user?.policyNumber || !!this.policyNumber)
        ) {
          documentForm.sourceId = this.user.policyNumber ?? this.policyNumber;
        }
      }
      documentForm.tenantId = this.user.id;
      documentForm.name = documentForm.name + '-' + this.addShortedTimestamp();
      if (
        !documentForm.totalAmount &&
        (documentForm.typeDocument === TypeDocumentEnum.IS200_MODEL ||
          documentForm.typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL)
      ) {
        documentForm.totalAmount = '0';
      }

      this.documentService
        .createDocument(documentForm, this.file)
        .pipe(
          catchError((err) => {
            this.loadingService.dismissSecondLoader();
            if (err.status === 409) {
              const messageKey = 'pages.documents.create-page.duplicatedDoc';
              this.presentToast(messageKey, 'danger');
              this.saving = false;
            }
            return throwError(err);
          })
        )
        .subscribe(
          (_) => {
            this.loadingService.dismissSecondLoader();
            const messageKey = 'pages.documents.create-page.savedOK';
            this.presentToast(messageKey, 'success');
            this.saving = false;
            this.dismiss(true);
          },
          (error) => {
            this.loadingService.dismissSecondLoader();
            this.saving = false;
            if (error.status === 400) {
              this.presentToast(
                'pages.documents.create-page.sizeDocumentError',
                'danger'
              );
            } else {
              this.presentToast(
                'pages.documents.create-page.error_upload_document',
                'danger'
              );
            }
          }
        );
    }
  }

  async presentToast(messageKey: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translate.instant(messageKey),
      position: 'top',
      color,
      duration: 4000
    });
    toast.present();
  }

  async presentModelHelp(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'components.add-document-model-help.header'
      ),
      message: this.translate.instant(
        'components.add-document-model-help.message'
      ),
      buttons: [
        {
          text: this.translate.instant('pages.documents.create-page.ok'),
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  getLastMonths(n: number): any[] {
    const months = [];
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let i = 0;

    do {
      months.push('01/' + (month > 9 ? '' : '0') + month + '/' + year);
      if (month === 1) {
        month = 12;
        year--;
      } else {
        month--;
      }
      i++;
    } while (i < n);

    return months;
  }

  writeMonthOptions(): void {
    const optionValues = this.getLastMonths(4);
    optionValues.forEach((month) => {
      const monthObj: Month = {
        value: month,
        text: this.translateMonth(month)
      };
      this.monthList.push(monthObj);
    });
  }

  writeQuarterOptions(): void {
    let todayMoment = moment();

    todayMoment = this.getQuarterDate(todayMoment);

    const lastYearMoment = moment(todayMoment).subtract(1, 'year');
    const lastYearString = moment(todayMoment)
      .subtract(1, 'year')
      .format('YYYY');

    let quarters: Quarter[] = [];

    switch (todayMoment.quarter()) {
      case 1: {
        quarters = [
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter4',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(4).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter3',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(3).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter2',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(2).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter1',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(1).format('DD/MM/YYYY')
          }
        ];
        break;
      }
      case 2: {
        quarters = [
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.quarter1'
            ),
            value: todayMoment.quarter(1).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter4',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(4).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter3',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(3).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter2',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(2).format('DD/MM/YYYY')
          }
        ];
        break;
      }
      case 3: {
        quarters = [
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.quarter2'
            ),
            value: todayMoment.quarter(2).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.quarter1'
            ),
            value: todayMoment.quarter(1).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter4',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(4).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter3',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(3).format('DD/MM/YYYY')
          }
        ];
        break;
      }
      case 4: {
        quarters = [
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.quarter3'
            ),
            value: todayMoment.quarter(3).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.quarter2'
            ),
            value: todayMoment.quarter(2).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.quarter1'
            ),
            value: todayMoment.quarter(1).format('DD/MM/YYYY')
          },
          {
            text: this.translate.instant(
              'enums.quarters_add_document_select.year_ago_quarter4',
              { year: lastYearString }
            ),
            value: lastYearMoment.quarter(4).format('DD/MM/YYYY')
          }
        ];
        break;
      }
    }
    this.quarterList = quarters;
  }

  translateMonth(month): string {
    const monthNumber: string = month.slice(3, 5);
    let monthString = '';
    switch (monthNumber) {
      case '01':
        monthString =
          `${this.translate.instant('enums.months.jan')} ` + month.slice(8, 11);
        break;
      case '02':
        monthString =
          `${this.translate.instant('enums.months.feb')} ` + month.slice(8, 11);
        break;
      case '03':
        monthString =
          `${this.translate.instant('enums.months.mar')} ` + month.slice(8, 11);
        break;
      case '04':
        monthString =
          `${this.translate.instant('enums.months.apr')} ` + month.slice(8, 11);
        break;
      case '05':
        monthString =
          `${this.translate.instant('enums.months.may')} ` + month.slice(8, 11);
        break;
      case '06':
        monthString =
          `${this.translate.instant('enums.months.jun')} ` + month.slice(8, 11);
        break;
      case '07':
        monthString =
          `${this.translate.instant('enums.months.jul')} ` + month.slice(8, 11);
        break;
      case '08':
        monthString =
          `${this.translate.instant('enums.months.aug')} ` + month.slice(8, 11);
        break;
      case '09':
        monthString =
          `${this.translate.instant('enums.months.sep')} ` + month.slice(8, 11);
        break;
      case '10':
        monthString =
          `${this.translate.instant('enums.months.oct')} ` + month.slice(8, 11);
        break;
      case '11':
        monthString =
          this.translate.instant('enums.months.nov') + month.slice(8, 11);
        break;
      case '12':
        monthString =
          this.translate.instant('enums.months.dec') + month.slice(8, 11);
        break;
      default:
        break;
    }
    return monthString;
  }

  formatSelectDate(): string {
    if (this.selectedMonth > 0) {
      const today = new Date();
      let year = today.getFullYear();
      const month = today.getMonth() + 1;

      if (this.selectedMonth > month) {
        year--;
      }
      return (
        '01/' +
        (this.selectedMonth > 9 ? '' : '0') +
        this.selectedMonth +
        '/' +
        year
      );
    }
    return null;
  }

  compareWith(o1, o2): boolean {
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  calculateDocumentEnumByUserType(): void {
    const documentTypes: {
      [key: string]: string;
    } = {
      DNI: 'DNI',
      PAYSLIP: 'PAYSLIP',
      INCOME_TAX_DECLARATION: 'INCOME_TAX_DECLARATION',
      OTHER_DOCUMENT: 'OTHER_DOCUMENT',
      PENSIONER_RECOGNITION: 'PENSIONER_RECOGNITION',
      IS200_MODEL: 'IS200_MODEL',
      IVA_QUARTER_MODEL: 'IVA_QUARTER_MODEL',
      WORK_LIFE: 'WORK_LIFE',
      MODEL_130: 'MODEL_130',
      SS_CERTIFICATE: 'SS_CERTIFICATE',
      AEAT_CERTIFICATE: 'AEAT_CERTIFICATE',
      PYG_QUARTER: 'PYG_QUARTER',
      RENTAL_CONTRACT: 'RENTAL_CONTRACT',
      WORK_CONTRACT: 'WORK_CONTRACT',
      BANK_STATEMENT: 'BANK_STATEMENT',
      REMOTE_JOB_CERTIFICATE: 'REMOTE_JOB_CERTIFICATE',
      INVOICE_PAYMENT_CONFIRMATION: 'INVOICE_PAYMENT_CONFIRMATION',
      INVOICE_RECORD_BOOK: 'INVOICE_RECORD_BOOK'
    };

    if (this.userType === 'tenant') {
      delete documentTypes.RENTAL_CONTRACT;
    } else if (this.userType === 'homeOwner') {
      for (const key in documentTypes) {
        if (key !== 'RENTAL_CONTRACT') {
          delete documentTypes[key];
        }
      }
    }
    this.calculatedTypeDocumentEnum = documentTypes;
  }

  selectChange(event: Event): void {
    const type = Object.keys(this.typeDocumentEnum).find(
      (t: string) => t === ((event as CustomEvent).detail.value as string)
    );

    this.document.typeDocument = type as TypeDocumentEnum;

    if (this.document.typeDocument === this.typeDocumentEnum.OTHER_DOCUMENT) {
      this.name = null;
      this.document.name = null;
    }
  }

  private prepareYears(): void {
    this.years = ['2020'];

    const currentYear = new Date().getFullYear();

    for (let index = 2020; index < currentYear; index++) {
      this.years.push(index.toString());
    }
  }

  private getQuarterDate(date: moment.Moment): moment.Moment {
    if (date.quarter() === 1) {
      date.month(0).date(1);
    } else if (date.quarter() === 2) {
      date.month(3).date(1);
    } else if (date.quarter() === 3) {
      date.month(6).date(1);
    } else {
      date.month(9).date(1);
    }

    return date;
  }
}
