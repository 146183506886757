/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MaintenanceGuard } from './modules/core/guards/public/maintenance.guard';
import { ActionModule } from './modules/public/modules/action/action.module';
import { CookiesPolicyModule } from './modules/public/modules/cookies-policy/cookies-policy.module';
import { CustomLoginModule } from './modules/public/modules/custom-login/custom-login.module';
import { DeleteAccountEbiSpacesModule } from './modules/public/modules/delete-account-ebi-spaces/delete-account-ebi-spaces.module';
import { EnterpriseLoginModule } from './modules/public/modules/enterprise-login/enterprise-login.module';
import { LandingModule } from './modules/public/modules/landing/landing.module';
import { LegalConditionsModule } from './modules/public/modules/legal-conditions/legal-conditions.module';
import { LoginModule } from './modules/public/modules/login/login.module';
import { MaintenanceModule } from './modules/public/modules/maintenance/maintenance.module';
import { NotFoundModule } from './modules/public/modules/not-found/not-found.module';
import { PrivacyPolicyModule } from './modules/public/modules/privacy-policy/privacy-policy.module';
import { RegisterUserModule } from './modules/public/modules/register-user/register-user.module';
import { SuccessModule } from './modules/public/modules/success/success.module';
import { UserRegistryCertificationModule } from './modules/public/modules/user-registry-certification/user-registry-certification.module';
import { VerificationLandingModule } from './modules/public/modules/verification-landing/verification-landing.module';

const LANDING_ROUTES = [
  {
    path: 'landing',
    loadChildren: async (): Promise<typeof LandingModule> =>
      (await import('./modules/public/modules/landing/landing.module'))
        .LandingModule
  },
  {
    path: 'verification-landing',
    loadChildren: async (): Promise<typeof VerificationLandingModule> =>
      (
        await import(
          './modules/public/modules/verification-landing/verification-landing.module'
        )
      ).VerificationLandingModule
  }
];

const LOGIN_ROUTES = [
  {
    path: 'login',
    loadChildren: async (): Promise<typeof LoginModule> =>
      (await import('./modules/public/modules/login/login.module')).LoginModule
  },
  {
    path: 'register',
    loadChildren: async (): Promise<typeof RegisterUserModule> =>
      (
        await import(
          './modules/public/modules/register-user/register-user.module'
        )
      ).RegisterUserModule
  },
  {
    path: 'enterprise-login',
    loadChildren: async (): Promise<typeof EnterpriseLoginModule> =>
      (
        await import(
          './modules/public/modules/enterprise-login/enterprise-login.module'
        )
      ).EnterpriseLoginModule
  },
  {
    path: 'login/:name',
    loadChildren: async (): Promise<typeof CustomLoginModule> =>
      (
        await import(
          './modules/public/modules/custom-login/custom-login.module'
        )
      ).CustomLoginModule
  }
];

const CERTIFICATION_ROUTES = [
  {
    path: 'documentation-permissions',
    loadChildren: async (): Promise<typeof UserRegistryCertificationModule> =>
      (
        await import(
          './modules/public/modules/user-registry-certification/user-registry-certification.module'
        )
      ).UserRegistryCertificationModule
  }
];

const LEGAL_INFORMATION_ROUTES = [
  {
    path: 'cookies-policy',
    loadChildren: async (): Promise<typeof CookiesPolicyModule> =>
      (
        await import(
          './modules/public/modules/cookies-policy/cookies-policy.module'
        )
      ).CookiesPolicyModule
  },
  {
    path: 'legal-conditions',
    loadChildren: async (): Promise<typeof LegalConditionsModule> =>
      (
        await import(
          './modules/public/modules/legal-conditions/legal-conditions.module'
        )
      ).LegalConditionsModule
  },
  {
    path: 'privacy-policy',
    loadChildren: async (): Promise<typeof PrivacyPolicyModule> =>
      (
        await import(
          './modules/public/modules/privacy-policy/privacy-policy.module'
        )
      ).PrivacyPolicyModule
  },
  {
    path: 'ebi-spaces-delete',
    loadChildren: async (): Promise<typeof DeleteAccountEbiSpacesModule> =>
      (
        await import(
          './modules/public/modules/delete-account-ebi-spaces/delete-account-ebi-spaces.module'
        )
      ).DeleteAccountEbiSpacesModule
  }
];

const OTHER_ROUTES = [
  {
    path: 'success-page',
    loadChildren: async (): Promise<typeof SuccessModule> =>
      (await import('./modules/public/modules/success/success.module'))
        .SuccessModule
  },
  {
    path: 'not-found',
    loadChildren: async (): Promise<typeof NotFoundModule> =>
      (await import('./modules/public/modules/not-found/not-found.module'))
        .NotFoundModule
  },
  {
    path: 'action',
    loadChildren: async (): Promise<typeof ActionModule> =>
      (await import('./modules/public/modules/action/action.module'))
        .ActionModule
  },
  {
    path: 'maintenance',
    loadChildren: async (): Promise<typeof MaintenanceModule> =>
      (await import('./modules/public/modules/maintenance/maintenance.module'))
        .MaintenanceModule,
    canLoad: [MaintenanceGuard]
  }
];

const routes: Routes = [
  ...LANDING_ROUTES,
  ...LOGIN_ROUTES,
  ...CERTIFICATION_ROUTES,
  ...LEGAL_INFORMATION_ROUTES,
  ...OTHER_ROUTES,
  { path: '', redirectTo: '/landing', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
