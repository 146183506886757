import { Injectable } from '@angular/core';

import { HomeOwnerInsuranceFeeDto } from '../../models';

@Injectable()
export class InsuranceFeeService {
  url: string;
  homeOwnerInsuranceFeeRawData: HomeOwnerInsuranceFeeDto[];
  selectedHomeOwnerFeeUser: HomeOwnerInsuranceFeeDto;
}
