/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import {
  NotificationDto,
  NotificationStatusEnum,
  NotificationTypeEnum,
  PageDto,
  UpdateNotification
} from '../../models';

@Injectable()
export class NotificationsApiService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.notifications;
  }

  newNotification(notification: NotificationDto): Observable<any> {
    return this.http.post<any>(this.url + '/notifications', notification);
  }

  updateNotification(
    notificationId: string,
    updateNotification: UpdateNotification
  ): Observable<any> {
    return this.http.patch(
      this.url + '/notifications/' + notificationId,
      updateNotification
    );
  }

  getNotifications(
    numElements: number = 20,
    userTargetEmail: string,
    afterElementId?: string
  ): Observable<PageDto<NotificationDto>> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    if (userTargetEmail) {
      userTargetEmail = userTargetEmail.replace('+', '%2B');
    }
    httpParams = httpParams.append('userTargetEmail', userTargetEmail);

    return this.http.get<PageDto<NotificationDto>>(
      this.url + '/notifications',
      { params: httpParams }
    );
  }

  deleteNotification(
    type: NotificationTypeEnum,
    candidatureId: string,
    userTargetEmail?: string,
    userTargetId?: string
  ): Observable<any> {
    let httpParams = new HttpParams().append('type', type);
    if (candidatureId) {
      httpParams = httpParams.append('candidatureId', candidatureId);
    }
    if (userTargetEmail) {
      userTargetEmail = userTargetEmail.replace('+', '%2B');
      httpParams = httpParams.append('userTargetEmail', userTargetEmail);
    }

    if (userTargetId) {
      httpParams = httpParams.append('userTargetId', userTargetId);
    }

    return this.http.delete(this.url + '/notifications', {
      params: httpParams
    });
  }

  getNotificationsByFilter(
    userTargetCode: string,
    type?: NotificationTypeEnum,
    status?: NotificationStatusEnum
  ): Observable<NotificationDto[]> {
    let httpParams = new HttpParams().append('userTargetCode', userTargetCode);
    if (type) {
      httpParams = httpParams.append('type', type);
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }

    return this.http.get<NotificationDto[]>(
      this.url + '/notifications/by-filter',
      { params: httpParams }
    );
  }
}
