import { Component, Input } from '@angular/core';
import { ApiDto, User } from '@core/models';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-change-asset-api-popover',
  templateUrl: './change-asset-api-popover.component.html'
})
export class ChangeAssetApiPopoverComponent {
  @Input() apis: ApiDto[];
  @Input() api: ApiDto;
  @Input() currentAgent: string;
  @Input() user: User;
  @Input() isPortfolioOwner = false;

  constructor(private popoverCtrl: PopoverController) {}

  setNewApi(newUserId: string, apiId?: string): void {
    this.popoverCtrl.dismiss({
      newUserId,
      apiId
    });
  }
}
