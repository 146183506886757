/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { User } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';
import { Observable, Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-documents-page',
  templateUrl: './documents-page.component.html'
})
export class DocumentsPageComponent implements OnInit, AfterViewInit {
  subscriptions: Subscription[] = [];
  cotenantsList: User[] = [];
  activeTab: string;
  screenWidth: number;

  carouselInner: HTMLElement;
  totalSlides: number;
  currentIndex = 0;
  activeSlide: number;

  @ViewChild('carouselContainer') set carouselContainer(value) {
    if (!!value) {
      this.carouselInner =
        document.querySelector<HTMLElement>('.carousel-inner');
      if (!!this.carouselInner) {
        setTimeout(() => {
          this.updateCarousel(this.currentIndex);
        }, 50);
      }
    }
  }

  constructor(
    private authenticationService: AuthService,
    private userService: UsersApiService,
    public modalController: ModalController,
    private utilsService: UtilsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    this.getRequestCotenants();
    if (this.router.url.endsWith('documents')) {
      this.router.navigate([
        this.router.url + '/tab-document/' + this.authenticationService.user.id
      ]);
    }
  }

  ngAfterViewInit(): void {
    this.carouselInner = document.querySelector<HTMLElement>('.carousel-inner');
  }

  checkActiveTab(cotenantId: string): boolean {
    this.activeTab =
      this.router.url.split('/')[this.router.url.split('/').length - 1];

    return cotenantId === this.activeTab;
  }

  getRequestCotenants(): void {
    const loggedTenant = this.authenticationService.user;
    this.cotenantsList.push(loggedTenant);
    this.userService
      .getCotenantList(this.authenticationService.user.id)
      .subscribe((usersIds) => {
        const callsArray: Observable<User>[] = [];
        usersIds.forEach((e) => {
          callsArray.push(this.userService.getUserObservable(e));
        });

        forkJoin(callsArray).subscribe({
          next: (response: User[]) => {
            response.forEach((user) => {
              user.profilePhoto$ = this.utilsService.getImage(
                user.profilePhotoId
              );
            });

            this.cotenantsList.push(...response);

            // Si son tabs slides
            if (this.screenWidth < 992 || this.cotenantsList.length > 2) {
              this.totalSlides = this.cotenantsList.length;

              const routeId =
                this.router.url.split('/')[
                  this.router.url.split('/').length - 1
                ];

              const index = this.cotenantsList.findIndex(
                (t) => t.id === routeId
              );

              this.activeSlide = index;
              this.currentIndex = index;
            }
            this.userService.cotenantsList = this.cotenantsList;
          }
        });
      });
  }

  open(id: string): void {
    const url = this.router.url.split('/');
    url.pop();

    this.activeSlide = this.cotenantsList.findIndex((t) => t.id === id);

    this.router.navigate([`${url.join('/')}/${id}`]);
  }

  moveLeft(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateCarousel();
    }
  }

  moveRight(): void {
    if (this.currentIndex < this.totalSlides - 1) {
      this.currentIndex++;
      this.updateCarousel();
    }
  }

  updateCarousel(index?: number): void {
    const slideWidth = this.carouselInner.clientWidth;
    let newPosition: number;
    if (!!index) {
      newPosition = -index * slideWidth;
    } else {
      newPosition = -this.currentIndex * slideWidth;
    }
    this.carouselInner.style.transform = `translateX(${newPosition}px)`;
  }

  checkIsUserRegister(userId: string): boolean {
    return !appValidators.emailDetector(userId);
  }
}
