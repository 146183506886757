import { SelectOption } from "@app/modules/shared/models/select-option.model";

import { TemplateStatus } from "../models";

export const templateStatusList: SelectOption[] = [
  {
    label: 'Rechazada',
    value: TemplateStatus.REJECTED
  },
  {
    label: 'Pendiente',
    value: TemplateStatus.IN_PROGRESS
  },
  {
    label: 'Configurada',
    value: TemplateStatus.READY
  }
];