import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CheckMinimumDoc,
  DocumentDTO,
  FileDocumentDTO,
  GeneralModalResponse,
  Incidence,
  LastTwoPayslipMonths,
  MinimalDocumentationCheck,
  ProfDataDto,
  TypeDocumentEnum,
  UpdateUser,
  User,
  UserAnalysis
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { environment } from '@environments/environment';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { first, Subject, Subscription, takeUntil } from 'rxjs';

import { AddDocumentComponent } from '../../components/add-document/add-document.component';
import { DocumentModalComponent } from '../../components/document-modal/document.modal.component';
import { IncidencesModalComponent } from '../../components/incidences-modal/incidences-modal.component';
import { MoveDocumentModalComponent } from '../../components/move-document-modal/move-document-modal.component';
import { ViveDocModalComponent } from '../../components/vive-doc-modal/vive-doc-modal.component';
import { ButtonsRole } from '../../models/button.model';
import { LaunchIncidenceEvent } from '../../models/tenant-document-card.model';
import { UploadTenantDocumentEvent } from '../../models/upload-tenant-document-card.model';

@Component({
  selector: 'el-buen-inquilino-tab-document-page',
  templateUrl: './tab-document-page.component.html'
})
export class TabDocumentPageComponent implements OnInit, OnDestroy {
  documents: DocumentDTO[] = [];
  subscriptions: Subscription[] = [];
  student: boolean;
  retired: boolean;
  business: boolean;
  guarantor = false;
  tenantId: string;
  user: User;
  authenticatedUser: User;
  isDisabledView: boolean;
  freelance: boolean;
  minimalDocumentationCheck: MinimalDocumentationCheck =
    new MinimalDocumentationCheck();
  typeDocumentEnum = TypeDocumentEnum;
  lastTwoPayslipMonths: LastTwoPayslipMonths;
  notRegistryOrNotPermissionUser: boolean;
  documentationOkMessage: string;
  cotenantsList: User[] = [];

  private $destroy = new Subject<void>();

  get isVpp(): boolean {
    return !!this.user.vppSurvey;
  }

  get socialSecurityHref(): string {
    return environment.socialSecurityHref;
  }

  get extraDoc(): boolean {
    return (
      !!this.user?.extraDocumentation && this.user?.extraDocumentation !== ''
    );
  }

  get checkMinimumDocForTenantRol(): boolean {
    const minDoc: CheckMinimumDoc = {
      student: this.student,
      freelance: this.freelance,
      retired: this.retired,
      business: this.business,
      hasDni: this.minimalDocumentationCheck.hasDni,
      hasRent: this.minimalDocumentationCheck.hasRent,
      hasModel130: this.minimalDocumentationCheck.hasModel130,
      hasIvaQuarterModel: this.minimalDocumentationCheck.hasIvaQuarterModel,
      hasPensionerRecognition:
        this.minimalDocumentationCheck.hasPensionerRecognition,
      hasIs200Model: this.minimalDocumentationCheck.hasIs200Model,
      hasPayslips: this.minimalDocumentationCheck.hasPayslips,
      hasSSCertificate: this.minimalDocumentationCheck.hasSSCertificate,
      hasAEATCertificate: this.minimalDocumentationCheck.hasAEATCertificate,
      hasPYGQuarter: this.minimalDocumentationCheck.hasPYGQuarter,
      hasWorkContract: this.minimalDocumentationCheck.hasWorkContract,
      hasBankStatement: this.minimalDocumentationCheck.hasBankStatement,
      hasRemoteJobCertificate:
        this.minimalDocumentationCheck.hasRemoteJobCertificate,
      hasWorkLife: this.minimalDocumentationCheck?.hasWorkLife,
      hasInvoiceRecordBook:
        this.minimalDocumentationCheck?.hasInvoiceRecordBook,
      hasInvoicePaymentConfirmation:
        this.minimalDocumentationCheck?.hasInvoicePaymentConfirmation
    };
    return this.utilsService.checkMinimumDocForTenantRol(minDoc, this.user);
  }

  get showWorkLifeCard(): boolean {
    return !this.minimalDocumentationCheck.hasWorkLife;
  }

  get showDniCard(): boolean {
    return !this.minimalDocumentationCheck.hasDni;
  }

  get isWorker(): boolean {
    return !this.freelance && !this.student && !this.retired && !this.business;
  }

  get showFirstPayslipCard(): boolean {
    return (
      this.minimalDocumentationCheck.hasNotOptionalPayslip &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  get showSecondPayslipCard(): boolean {
    return (
      !this.minimalDocumentationCheck.hasOneMonthPayslips &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  get showThirdPayslipCard(): boolean {
    return (
      (!this.minimalDocumentationCheck.hasTwoMonthPayslips || this.extraDoc) &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  get showFirstQuarterCard(): boolean {
    return (
      !this.minimalDocumentationCheck.hasOneQuarterModel &&
      ((this.freelance &&
        !this.extraDoc &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          this.minimalDocumentationCheck.hasIvaQuarterModel,
          this.minimalDocumentationCheck.hasModel130
        )) ||
        this.business)
    );
  }

  get showSecondQuarterCard(): boolean {
    return (
      !this.minimalDocumentationCheck.hasTwoQuarterModel &&
      ((this.freelance &&
        !this.extraDoc &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          this.minimalDocumentationCheck.hasIvaQuarterModel,
          this.minimalDocumentationCheck.hasModel130
        )) ||
        this.business)
    );
  }

  get showPensionerRecognitionCard(): boolean {
    return (
      !this.minimalDocumentationCheck.hasPensionerRecognition && this.retired
    );
  }

  get showIs200ModelCard(): boolean {
    return !this.minimalDocumentationCheck.hasIs200Model && this.business;
  }

  get showIncomeTaxDeclarationCard(): boolean {
    return (
      !this.minimalDocumentationCheck.hasRent &&
      this.freelance &&
      !this.student &&
      !this.extraDoc
    );
  }

  get showModel130Card(): boolean {
    return (
      !this.minimalDocumentationCheck.hasModel130 &&
      !this.student &&
      this.freelance &&
      !this.extraDoc &&
      this.utilsService.checkModel130ForFreelance(
        this.minimalDocumentationCheck.hasIvaQuarterModel,
        this.minimalDocumentationCheck.hasModel130
      )
    );
  }

  get showSsCertificateCard(): boolean {
    return !this.minimalDocumentationCheck.hasSSCertificate && this.business;
  }

  get showAeatCertificateCard(): boolean {
    return !this.minimalDocumentationCheck.hasAEATCertificate && this.business;
  }

  get showPygQuarterCard(): boolean {
    return !this.minimalDocumentationCheck.hasPYGQuarter && this.business;
  }

  get showFreelanceOptionalDocCard(): boolean {
    return this.freelance && !this.student;
  }

  get showModel130Chip(): boolean {
    return (
      this.freelance &&
      !this.extraDoc &&
      this.utilsService.checkModel130ForFreelance(
        this.minimalDocumentationCheck.hasIvaQuarterModel,
        this.minimalDocumentationCheck.hasModel130
      )
    );
  }

  get showIvaQuarterModelChip(): boolean {
    return (
      this.business ||
      (this.freelance &&
        !this.extraDoc &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          this.minimalDocumentationCheck.hasIvaQuarterModel,
          this.minimalDocumentationCheck.hasModel130
        ))
    );
  }

  get showPayslipChip(): boolean {
    return !this.student && !this.freelance && !this.retired && !this.business;
  }

  get showForeignerIncomeChips(): boolean {
    return this.user.foreignIncomes;
  }

  get showWorkContractCard(): boolean {
    return (
      (this.user.foreignIncomes || this.extraDoc) &&
      !this.minimalDocumentationCheck.hasWorkContract &&
      this.freelance
    );
  }

  get showBankStatementCard(): boolean {
    return (
      (this.user.foreignIncomes || (this.extraDoc && this.retired)) &&
      !this.minimalDocumentationCheck.hasBankStatement
    );
  }

  get showRemoteJobCertificateCard(): boolean {
    return (
      this.user.foreignIncomes &&
      !this.minimalDocumentationCheck.hasRemoteJobCertificate
    );
  }

  get showInvoiceRecordBookCard(): boolean {
    return (
      this.freelance &&
      this.extraDoc &&
      !this.minimalDocumentationCheck.hasInvoiceRecordBook
    );
  }

  get showInvoicePaymentConfirmationCard(): boolean {
    return (
      this.freelance &&
      this.extraDoc &&
      !this.minimalDocumentationCheck.hasInvoicePaymentConfirmation
    );
  }

  constructor(
    private authenticationService: AuthService,
    private userService: UsersApiService,
    private loadingService: LoadingService,
    public modalController: ModalController,
    private docService: DocumentsApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    public utilsService: UtilsService,
    private utils: UtilsService,
    private router: Router,
    public alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.setDocumentationOkMessage();
    this.setTenantId();
  }

  trackByDocument(_index: number, document: DocumentDTO): string {
    return document.id;
  }

  getProfessionalData(tenantId: string): void {
    this.userService
      .getProfessionalData(tenantId)
      .subscribe((professionalData: ProfDataDto) => {
        if (professionalData?.freelance === true) {
          this.freelance = professionalData.freelance;
        } else {
          this.freelance = false;
        }
      });
  }

  getRequestAnalysisForIncidences(
    requestAnalysisId: string,
    documentId: string
  ): void {
    this.userService
      .getAnalysis(requestAnalysisId)
      .subscribe((requestAnalysis: UserAnalysis) => {
        if (requestAnalysis) {
          const filteredIncidenceByDocument = requestAnalysis.incidences.filter(
            (incidence: Incidence) => incidence?.document?.id === documentId
          );
          this.showIncidences(filteredIncidenceByDocument, requestAnalysisId);
        }
      });
  }

  getCotenantUser(tenantId: string): void {
    this.userService.getUser(tenantId).then((user: User) => {
      this.user = user;
      this.student = user.student;
      this.retired = user.retired;
      this.business = user.business;
      this.guarantor = user.guarantor ? true : false;
    });
  }

  getDocuments(requestUserId?: string): void {
    this.documents = [];
    this.docService
      .getDocuments('50', requestUserId, null)
      .pipe(first())
      .subscribe({
        next: (documents: DocumentDTO[]) => {
          this.documents = documents || [];
          this.minimalDocumentationCheck =
            this.utilsService.checkMinimalDocumentation(this.documents);
        },
        error: () => {
          this.minimalDocumentationCheck = new MinimalDocumentationCheck();
        }
      });
  }

  launchIncidencesEmitted({
    requestAnalysisId,
    documentId
  }: LaunchIncidenceEvent): void {
    this.launchIncidences(requestAnalysisId, documentId);
  }

  launchIncidences(requestAnalysisId: string, documentId: string): void {
    this.getRequestAnalysisForIncidences(requestAnalysisId, documentId);
  }

  async showIncidences(
    incidences: Incidence[],
    requestAnalysisId: string
  ): Promise<void> {
    const incidenceModal = await this.modalController.create({
      component: IncidencesModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        incidenceList: incidences,
        user: this.user,
        analysisId: requestAnalysisId
      }
    });
    incidenceModal
      .onDidDismiss()
      .then((close: OverlayEventDetail<GeneralModalResponse>) => {
        if (close.data.saving) this.ngOnInit();
      });
    await incidenceModal.present();
  }

  updateUser(updateUser: UpdateUser): void {
    if (this.tenantId === this.authenticationService.user.id) {
      this.authenticationService.user.student = updateUser.student;
    }
    this.student = updateUser.student;
  }

  presentModalEmitted(event: UploadTenantDocumentEvent): void {
    this.presentModal(null, event.type, event.name);
  }

  async presentModal(
    data?: DocumentDTO,
    typeDocument?: TypeDocumentEnum,
    name?: string
  ): Promise<void> {
    if (this.documents?.length >= 20) {
      this.presentMaximumNumberDocumentsReachedModal();
      return;
    }
    let month = 0;
    if (
      typeDocument === TypeDocumentEnum.PAYSLIP &&
      name === 'hasNotOptionalPayslip'
    ) {
      month = this.getMonthNumber(0);
    }

    if (
      typeDocument === TypeDocumentEnum.PAYSLIP &&
      name === 'hasOneMonthPayslips'
    ) {
      month = this.getMonthNumber(1);
    }

    if (
      typeDocument === TypeDocumentEnum.PAYSLIP &&
      name === 'hasTwoMonthPayslips'
    ) {
      month = this.getMonthNumber(2);
    }
    let quarter = '';
    let quarterMoment: moment.Moment;
    if (
      typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL &&
      name === 'hasOneQuarterModel'
    ) {
      quarter = this.getQuarterString(1);
      quarterMoment = this.getQuarterMoment(1);
    }

    if (
      typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL &&
      name === 'hasTwoQuarterModel'
    ) {
      quarter = this.getQuarterString(2);
      quarterMoment = this.getQuarterMoment(2);
    }

    const modal = await this.modalController.create({
      component: AddDocumentComponent,
      cssClass: 'auto-height',
      componentProps: {
        document: data ?? new DocumentDTO(),
        typeDocumentInput: typeDocument,
        user: this.user,
        name: name ?? '',
        selectedMonth: typeDocument === TypeDocumentEnum.PAYSLIP ? month : null,
        selectedQuarter:
          typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL ? quarter : null,
        selectedQuarterMoment:
          typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL
            ? quarterMoment
            : null
      } as Partial<AddDocumentComponent>
    });
    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<GeneralModalResponse>) => {
        if (close?.data?.saving) {
          this.getDocuments(this.tenantId);
          if (
            typeDocument === TypeDocumentEnum.PAYSLIP ||
            typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL
          ) {
            this[name] = true;
          }
        }
      });
    return await modal.present();
  }

  async presentModalVpp(): Promise<void> {
    const modal = await this.modalController.create({
      component: ViveDocModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        vppSurvey: this.user.vppSurvey,
        documents: this.documents,
        user: this.user
      } as Partial<ViveDocModalComponent>
    });

    modal
      .onDidDismiss()
      .then((resp: OverlayEventDetail) => this.onSuccessPresentModalVpp(resp));

    modal.present();
  }

  onSuccessPresentModalVpp(resp: OverlayEventDetail<unknown>): void {
    const { role } = resp;

    if (role === 'search') {
      if (this.tenantId === this.authenticationService.user.id) {
        this.getDocuments();
      } else {
        this.getDocuments(this.tenantId);
      }
    }
  }

  checkDeleteDocument(document: DocumentDTO): void {
    if (document.validated) {
      this.presentDeniedDeleteDocumentModal(document.id);
    } else {
      this.delete(document.id);
    }
  }

  delete(docId: string): void {
    if (this.tenantId === this.authenticationService.user.id) {
      this.docService
        .deleteDocument(docId)
        .pipe(first())
        .subscribe(() => {
          const index = this.documents.findIndex(
            (doc: DocumentDTO) => doc.id === docId
          );
          this.documents.splice(index, 1);
          this.minimalDocumentationCheck =
            this.utilsService.checkMinimalDocumentation(this.documents);
          this.presentToast();
        });
    } else {
      this.docService
        .deleteDocumentCotenant(this.user.id, docId)
        .pipe(first())
        .subscribe(() => {
          const index = this.documents.findIndex(
            (doc: DocumentDTO) => doc.id === docId
          );
          this.documents = this.documents.splice(index, 1);
          this.minimalDocumentationCheck =
            this.utilsService.checkMinimalDocumentation(this.documents);
          this.presentToast();
        });
    }
  }

  showDocument(document: DocumentDTO): void {
    if (document && document.id) {
      if (this.tenantId !== this.authenticatedUser.id) {
        const fileSubs = this.docService
          .getFileByDocumentAndCotenant(document.id, this.tenantId)
          .pipe(first())
          .subscribe(async (res: FileDocumentDTO) => {
            const blob = await this.utils.UrlToBlob(res.url);
            this.utils.downloadPDF(
              document.name + '.' + document.extension,
              blob
            );
          });
        this.subscriptions.push(fileSubs);
      } else {
        const fileSubs = this.docService
          .getFileByDocument(document.id)
          .pipe(first())
          .subscribe(async (res: FileDocumentDTO) => {
            const blob = await this.utils.UrlToBlob(res.url);
            this.utils.downloadPDF(
              document.name + '.' + document.extension,
              blob
            );
          });
        this.subscriptions.push(fileSubs);
      }
    }
  }

  async presentToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translate.instant(
        'pages.documents.create-page.deletedOK'
      ) as string,
      duration: 2000,
      position: 'top',
      color: 'success'
    });
    toast.present();
  }

  checkGuarantor(user: User): boolean {
    if (user.guarantor) {
      return true;
    }
    return false;
  }

  async viewDocument(document: DocumentDTO): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.isDisabledView = true;

    if (this.tenantId !== this.authenticatedUser?.id) {
      this.docService
        .getFileByDocumentAndCotenant(document.id, this.tenantId)
        .pipe(first())
        .subscribe({
          next: async (res: FileDocumentDTO) => {
            const blob = await this.utils.UrlToBlob(res.url);
            this.loadViewDocumentModal(blob, document);
            await this.loadingService.dismissSecondLoader();
          },
          error: async () => {
            await this.loadingService.dismissSecondLoader();
          }
        });
    } else {
      this.docService
        .getFileByDocument(document.id)
        .pipe(first())
        .subscribe({
          next: async (res: FileDocumentDTO) => {
            const blob = await this.utils.UrlToBlob(res.url);
            this.loadViewDocumentModal(blob, document);
            await this.loadingService.dismissSecondLoader();
          },
          error: async () => {
            await this.loadingService.dismissSecondLoader();
          }
        });
    }
  }

  async loadViewDocumentModal(
    responseBlob: Blob,
    document: DocumentDTO
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: DocumentModalComponent,
      cssClass: 'custom-modal-xl',
      componentProps: {
        pdfSrc: responseBlob,
        documentData: document
      }
    });
    modal.onDidDismiss().then(() => {
      this.isDisabledView = false;
    });
    return await modal.present();
  }

  async presentDeniedDeleteDocumentModal(id: string): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.documents.create-page.denied_delete_document_modal_header'
      ) as string,
      message: this.translate.instant(
        'pages.documents.create-page.denied_delete_document_modal_message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant('common.cancel') as string,
          cssClass: 'secondary',
          role: ButtonsRole.CANCEL
        },
        {
          text: this.translate.instant('common.accept') as string,
          cssClass: 'primary',
          role: ButtonsRole.ACCEPT
        }
      ]
    });

    alert.onDidDismiss().then((resp: OverlayEventDetail<unknown>) => {
      if (resp.role === (ButtonsRole.ACCEPT as string)) {
        this.delete(id);
      }
    });
    await alert.present();
  }

  async presentMaximumNumberDocumentsReachedModal(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_header'
      ) as string,
      message: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant(
            'pages.documents.create-page.ok'
          ) as string,
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  checkIsUserRegister(userId: string): boolean {
    return !appValidators.emailDetector(userId);
  }

  ionViewWillLeave(): void {
    this.subscriptions?.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  async showPopupMoveDocument(document: DocumentDTO): Promise<void> {
    const moveDocumentModal = await this.modalController.create({
      component: MoveDocumentModalComponent,
      cssClass: 'auto-height modal-extend',
      id: 'moveDocumentModal',
      componentProps: {
        cotenantsList: this.cotenantsList,
        document: document,
        originTenantId: this.tenantId
      }
    });
    moveDocumentModal
      .onDidDismiss()
      .then((close: OverlayEventDetail<GeneralModalResponse>) => {
        if (close?.data?.saving) {
          this.getDocuments();
        }
      });
    await moveDocumentModal.present();
  }

  async presentDeniedMoveDocumentModal(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.documents.create-page.denied_delete_document_modal_header'
      ) as string,
      message: this.translate.instant(
        'pages.documents.create-page.denied_move_document_modal_message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant(
            'pages.documents.create-page.ok'
          ) as string,
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private setTenantId(): void {
    this.tenantId =
      this.router.url.split('/')[this.router.url.split('/').length - 1];

    this.cotenantsList = this.userService.cotenantsList.filter(
      (tenant: User) => {
        return tenant.id !== this.tenantId;
      }
    );

    if (!this.checkIsUserRegister(this.tenantId)) {
      this.notRegistryOrNotPermissionUser = true;
    }

    if (this.authenticationService.user?.coTenants?.length > 0) {
      this.documentationOkMessage = this.translate.instant(
        'pages.tab_document.documentationOk_with_cotenants'
      ) as string;
    }

    this.getProfessionalData(this.tenantId);

    this.setUser();

    this.authenticatedUser = this.authenticationService.user;
    this.lastTwoPayslipMonths =
      this.utilsService.calculatedUltimateTwoMonthForPayslips();
  }

  private setUser(): void {
    this.authenticationService
      .getUser()
      .pipe(takeUntil(this.$destroy))
      .subscribe((user: User) => {
        this.user = user;
        if (this.tenantId === this.user.id) {
          this.getDocuments();
          this.student = this.user.student;
          this.retired = this.user.retired;
          this.business = this.user.business;
          this.guarantor = this.user.guarantor ? true : false;
        } else {
          this.getDocuments(this.tenantId);
          this.getCotenantUser(this.tenantId);
        }
      });
  }

  private setDocumentationOkMessage(): void {
    this.documentationOkMessage = this.translate.instant(
      'pages.tab_document.documentationOk'
    ) as string;
  }

  private getMonthString(number: number): string {
    return this.utilsService.getPayrollMonth(number);
  }

  private getMonthNumber(number: number): number {
    return this.utilsService.getMonthNumber(number);
  }

  private getQuarterString(number: number): string {
    return this.utilsService.getQuarterString(number);
  }

  private getQuarterMoment(number: number): moment.Moment {
    return this.utilsService.getQuarterMoment(number);
  }
}
