import { Pipe, PipeTransform } from '@angular/core';
import { DocumentDTO } from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';

import { BackofficeDocumentPipeFunction } from '../models/backoffice.model';

@Pipe({
  name: 'backofficeDocuments'
})
export class BackofficeDocumentsPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(
    document: DocumentDTO,
    pipeFunction: BackofficeDocumentPipeFunction
  ): string | boolean {
    switch (pipeFunction) {
      case BackofficeDocumentPipeFunction.TYPE:
        return this.utilsService.translateDocumentType(document?.typeDocument);
      case BackofficeDocumentPipeFunction.VALIDATED:
        return document.validated ?? false;
    }
  }
}
