import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AMLCheck, BuildingAmlAlertDto, BuildingDto } from '@app/modules/core/models';
import { ModalController } from '@ionic/angular';

import { copyObject } from '../../utils/global.utils';
import { ModalViewMoneyLaunderingChecksComponent } from '../modal-view-money-laundering-checks/modal-view-money-laundering-checks.component';


@Component({
  selector: 'el-buen-inquilino-money-laundering-list',
  templateUrl: './money-laundering-list.component.html'
})
export class MoneyLaunderingListComponent implements OnChanges {
  @Input() data: BuildingAmlAlertDto[] = [];
  @Input() buildings: BuildingDto[] = [];

  combinedData: BuildingAmlAlertDto[] = []

  displayedColumns = ['building' , 'dni' , 'name' , 'actions'];
  dataSource: MatTableDataSource<BuildingAmlAlertDto> =
    new MatTableDataSource<BuildingAmlAlertDto>([]);
  constructor(
     private modalController: ModalController,
     private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data' ]) {
      this.combineDataAddres()
      this.setDataSource();
    }
  }

  setDataSource(): void {
    this.dataSource = new MatTableDataSource<BuildingAmlAlertDto>(
      copyObject(this.combinedData) as BuildingAmlAlertDto[]
    );
  }

  async openAlertMoneyLaundering(amlCheck: AMLCheck): Promise<void> {
    const modal = await this.modalController.create({
    component: ModalViewMoneyLaunderingChecksComponent,
    cssClass: 'auto-height modal-extend',
    backdropDismiss: true,
    componentProps: {
        amlData: amlCheck
    }
    });
    await modal.present();
  }

  seeBuilding(buildingId: string): void {
    const filteredBuilding = this.buildings.filter((item) => item.id === buildingId);
    this.router.navigate(
      [`/home-owner/${filteredBuilding[0].portfolioOwnerId}/portfolio/building/${buildingId}`], {
      state: { filteredBuilding }
    });
  }

  private combineDataAddres(): void {
    this.combinedData = this.data.map((data) => {
      const building = this.buildings.find((b) => b.id === data.buildingId);
      return {
        ...data,
        street: building?.street ,
        number: building?.number ,
        town: building?.town,
        province: building?.province ,
        vppModality: building?.vppModality,
        internalCode: building?.internalCode,
      };
    });
  }
}

