// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-search-field {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  top: 8px !important;
  left: 8px !important;
  font-size: 16px !important;
  font-family: "Helvetica Neue", "Roboto", sans-serif;
  min-width: 340px;
  max-height: 40px;
  padding: 1rem !important;
  z-index: 9999999 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/asset-map/asset.map.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,0BAAA;EACA,mDAAA;EACA,gBAAA;EACA,gBAAA;EACA,wBAAA;EACA,2BAAA;AACF","sourcesContent":[".asset-search-field {\r\n  background-color: white;\r\n  border: 1px solid #ddd;\r\n  border-radius: 4px;\r\n  top: 8px !important;\r\n  left: 8px !important;\r\n  font-size: 16px !important;\r\n  font-family: 'Helvetica Neue', 'Roboto', sans-serif;\r\n  min-width: 340px;\r\n  max-height: 40px;\r\n  padding: 1rem !important;\r\n  z-index: 9999999 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
