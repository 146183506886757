import { AssetDto, ChangeApiDto, LabelDto } from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';

export function reassignAsset(
  originalAssets: AssetDto[],
  event: ChangeApiDto
): AssetDto[] {
  const asset = findAsset(originalAssets, event.assetId);
  asset.userId = event.userId;

  if (event.newApiId) {
    asset.apiId = event.newApiId;
  } else if (event.changeType === 'api') {
    asset.apiId = null;
  }

  const index = findAssetIndex(originalAssets, event.assetId);
  originalAssets[index] = asset;

  return originalAssets;
}

export function findAsset(
  originalAssets: AssetDto[],
  assetId: string
): AssetDto {
  return originalAssets.find((asset: AssetDto) => asset.id === assetId);
}

export function findAssetIndex(
  originalAssets: AssetDto[],
  assetId: string
): number {
  return originalAssets.findIndex((asset: AssetDto) => asset.id === assetId);
}

export function getAssetColor(
  asset: AssetDto,
  bg: boolean,
  labels: LabelDto[],
  utilsService: UtilsService
): string {
  let color = '#fafafa';
  if (asset.labelId !== null && asset.labelId !== undefined)
    color = bg
      ? utilsService.hexToRGB(getColorById(asset.labelId, labels), 0.16)
      : getColorById(asset.labelId, labels);
  return color;
}

function getColorById(labelId: number, labels: LabelDto[]): string {
  const selectedLabel = labels.find((label: LabelDto) => label.id === labelId);
  return getColor(selectedLabel);
}

function getColor(label: LabelDto): string {
  return label !== null &&
    label !== undefined &&
    label.value !== null &&
    label.value !== undefined
    ? label.value[0] === '#'
      ? label.value
      : '#' + label.value
    : '#fafafa';
}
