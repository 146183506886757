/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeDetectorRef, Component } from '@angular/core';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CreateContactUserDto,
  NotificationDto,
  NotificationTypeEnum,
  TypeUserEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CountryCode } from 'libphonenumber-js/types.d';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-send-request-guarantor',
  templateUrl: './send-request-guarantor.component.html'
})
export class SendRequestGuarantorComponent {
  link: string;
  email: string;
  firstname: string;
  surname1: string;
  surname2: string;
  phone: any;
  createContactDto = new CreateContactUserDto();
  users$: Observable<User[]>;
  userNotFound: boolean;
  showInput = true;
  user: User;
  invitationSended = false;
  disableSendBtn = false;
  saving = false;
  forbiddenTenant = false;
  country: CountryCode = 'ES';

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private userService: UsersApiService,
    private authenticationService: AuthService,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private notificationsService: NotificationsApiService,
    private utilsService: UtilsService
  ) {}

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  changeCountry(event: CountryCode): void {
    this.country = event;
  }

  async presentToast(message: string, type: string): Promise<void> {
    const messageKey = message;
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      position: 'top',
      color: type,
      duration: 3000
    });
    toast.present();
  }

  async searchUser(): Promise<void> {
    // Si es true, ya vamos directamente a enviar la invitación
    if (this.userNotFound) {
      this.addGuarantor(true);
    } else {
      this.userNotFound = false;
      await this.loadingService.presentSecondLoader(null);
      this.users$ = this.userService
        .getUserByEmail(this.email.toLowerCase())
        .pipe(
          tap(
            async (user) => {
              this.showInput = false;
              this.email = '';
              this.user = user[0];
              if (
                this.user.userType === TypeUserEnum.HOMEOWNER ||
                this.user.business
              ) {
                this.forbiddenTenant = true;
              }
              if (
                this.authenticationService.user.coTenants?.includes(user[0].id)
              ) {
                this.showInput = true;
                this.user = null;
                this.presentToast(
                  'cotenant.profile.duplicateCotenant',
                  'danger'
                );
              } else if (this.authenticationService.user.id === user[0].id) {
                this.showInput = true;
                this.user = null;
                this.presentToast('cotenant.profile.yourselfEmail', 'danger');
              }
              await this.loadingService.dismissSecondLoader();
            },
            async (error) => {
              if (error.error.code === '102') {
                this.userNotFound = true;
                this.cdRef.detectChanges();
                this.showInput = false;

                await this.loadingService.dismissSecondLoader();
              }
            }
          )
        );
    }
  }

  async addGuarantor(newUser?: boolean): Promise<void> {
    const notification: NotificationDto = new NotificationDto();
    notification.recipientEmail = newUser ? this.email : this.user.email;
    notification.type = NotificationTypeEnum.GUARANTOR;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    // Si es un usuario que no está en EBI le añadimos los datos del formulario de invitación
    if (newUser) {
      notification.recipientEmail = this.email;
      notification.recipientFirstname = this.firstname;
      notification.recipientSurname1 = this.surname1;
      notification.recipientSurname2 = this.surname2;
      notification.recipientPhone = this.utilsService.formatPhone(this.phone);
    }

    await this.loadingService.presentSecondLoader(null);
    this.notificationsService.newNotification(notification).subscribe({
      next: async () => {
        this.invitationSended = true;
        this.presentToast('pages.share-profile.email-sended', 'success');
        await this.loadingService.dismissSecondLoader();
      },
      error: async (error) => {
        if (error.error.code === '201') {
          this.presentToast('cotenant.profile.duplicateRequest', 'danger');
        }
        if (error.error.code === '207') {
          this.presentToast('guarantor.profile.alreadyGuarantor', 'danger');
        } else {
          this.presentToast(
            'Ha ocurrido un error. Inténtelo más tarde',
            'danger'
          );
        }
        await this.loadingService.dismissSecondLoader();
      }
    });
  }
}
