import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ofuscateBankAccountNumber'
})
export class OfuscateBankAccountNumberPipe implements PipeTransform {
  transform(ofuscate: boolean, number: string): string {
    if (ofuscate) {
      return this.maskNumber(number);
    }
    return number;
  }

  private maskNumber(iban: string): string {
    // Separar solo los caracteres alfanuméricos y guiones
    const ibanWithoutSpaces = iban.replace(/-/g, '');
    // Enmascarar excepto últimos 4
    const maskedPart = ibanWithoutSpaces.slice(0, -4).replace(/./g, '*');
    // Últimos 4 dígitos sin enmascarar
    const lastFour = ibanWithoutSpaces.slice(-4);

    // Reinsertar los guiones en su posición original
    let maskedIban = maskedPart + lastFour;
    const positions = [4, 9, 14, 19, 24]; // Posiciones de los guiones en IBAN español

    positions.forEach((pos: number) => {
      maskedIban = maskedIban.slice(0, pos) + '-' + maskedIban.slice(pos);
    });

    return maskedIban;
  }
}
