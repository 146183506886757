// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-information {
  border-radius: 0.7rem;
  margin: 0.5rem 0;
  padding: 0.5rem 0rem 0.5rem 0.25rem;
  border-left: 8px solid var(--ion-color-danger);
}
.container-information .span-title {
  margin-bottom: 1rem;
}

.icon-medium {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/pages/selected-candidature-page/components/selected-candidature-incidences/selected-candidature-incidences.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,mCAAA;EACA,8CAAA;AACJ;AACI;EACI,mBAAA;AACR;;AAGA;EACI,iBAAA;EACA,oBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".container-information {\r\n    border-radius: 0.7rem;\r\n    margin: 0.5rem 0;\r\n    padding: 0.5rem 0rem 0.5rem 0.25rem;\r\n    border-left: 8px solid var(--ion-color-danger);\r\n\r\n    .span-title {\r\n        margin-bottom: 1rem;\r\n    }\r\n}\r\n\r\n.icon-medium {\r\n    font-size: 1.2rem;\r\n    margin-right: 0.5rem;\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
