/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Candidature,
  TenantCandidature,
  User,
  RecipientData,
  UserCreateCandidatureDto,
  BackendPayload
} from '@app/modules/core/models';
import { AuthService } from '@app/modules/core/services/auth/auth.service';
import { ModalController } from '@ionic/angular';
import { createRetoolEmbed } from '@tryretool/retool-embed';
import * as moment from 'moment';

import { InsurancesApiService } from '../../../../../../../core/api-services/insurances-api/insurances-api.service';

@Component({
  selector: 'el-buen-inquilino-sign-retool-modal',
  templateUrl: './sign-retool-modal.component.html'
})
export class SignRetoolModalComponent implements OnInit, OnDestroy {
  @Input() status: string;

  @Input() candidature: Candidature;

  user: User;

  selectedTemplate: string;

  receivedData = null;
  retoolUrl =
    'https://elbueninquilino.retool.com/embedded/public/4be7f151-537b-479a-8f92-19b6f6e2e2de?_embed=true';

  // Data to be sent to Retool app
  recipientData: RecipientData;

  data = {
    contractData: {}
  };

  contractData = {};

  combinedData = {};

  url = this.retoolUrl;

  embeddedRetool: any;

  constructor(
    private modalController: ModalController,
    private elementRef: ElementRef,
    private auth: AuthService,
    private insuranceService: InsurancesApiService
  ) {
    this.setUser();
  }

  ngOnInit(): void {
    this.fillFirstRequestToRetool();
    this.createRetoolEmbed();
    window.addEventListener('message', this.handleMessage.bind(this), false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleMessage.bind(this));
  }

  createRetoolEmbed(): void {
    // Clear the container first
    this.elementRef.nativeElement.innerHTML = '';

    const container = document.createElement('div');
    this.elementRef.nativeElement.appendChild(container);

    // Create Retool Embed
    this.embeddedRetool = createRetoolEmbed({
      src: this.retoolUrl,
      data: this.combinedData,
      style: 'width: 100vw; height: 100vh; border: 1px solid blue;',
      onData: (data: any) => {
        this.handleRetoolData(data);

        // Handle file download data
        if (data && data.fileUrl) {
          this.downloadFile(data.fileUrl); // Trigger file download
        }
      }
    });

    container.appendChild(this.embeddedRetool);
  }

  handleRetoolData(data: any): void {
    if (data.type === 'object') {
      this.receivedData = data;
    }
    if (!!data.envelopeData) {
      this.insuranceService
        .addEnvelopeToCandidature(
          this.candidature.id,
          data.envelopeData.envelopeId,
          data.templateValue,
          data.contractName
        )
        .subscribe();
    }
  }

  handleMessage(event: MessageEvent): void {
    if (event.origin === 'https://elbueninquilino.retool.com') {
      this.receivedData = event.data;

      if (event.data.changeTemplate) {
        // Nos quedamos con el id del template seleccionado
        this.selectedTemplate = event.data.selectedId;
        const backendPayload: BackendPayload = {
          selectedTemplate: this.selectedTemplate,
          candidaturesId: this.candidature.id,
          properties: []
        };
        this.initFormParametersFromBackend(backendPayload);
      }

      // Check if we need to change the iframe URL based on received data
      if (event.data.changePage) {
        const newUrl =
          'https://elbueninquilino.retool.com/embedded/public/691d4602-84db-4d82-995b-cd223071f092';
        this.retoolUrl = newUrl;
        const emailData = { email: 'joliva@elbueninquilino.es' }; // ???
        window.parent.postMessage(emailData, '*');
        this.createRetoolEmbed(); // Recreate the Retool embed with the new URL
      }

      const message = event.data;
      if (message.type === 'pdfDownload') {
        this.downloadPDF(message.data);
      }
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  private getTenantDataList(): string {
    const tenants = this.candidature.tenantCandidatureList.map(
      (tc: TenantCandidature) => tc.user
    );
    if (!!tenants) {
      let tenantInfoStr = '';
      tenants.map((user: User) => {
        tenantInfoStr += ` ${user.firstname} ${user.surname1} (DNI - ${user.dni}),`;
      });
      // Elimina la última coma
      tenantInfoStr = tenantInfoStr.substring(0, tenantInfoStr.length - 1);
      return tenantInfoStr;
    }
    return '';
  }

  private fillFirstRequestToRetool(): void {
    const tenants = this.candidature.tenantCandidatureList.map(
      (tc: TenantCandidature) => tc.user
    );
    if (!!tenants) {
      this.recipientData = {
        recipients: {
          templateId: null,
          templateRoles: tenants.map((user: User, index: number) => ({
            email: user.email,
            name: `${user.firstname} ${user.surname1}`,
            roleName: 'signer',
            tabs: {
              signHereTabs: [
                {
                  anchorString: `/signature_${index + 1}/`,
                  anchorUnits: 'pixels',
                  anchorXOffset: '0',
                  anchorYOffset: '0',
                  status: 'active'
                }
              ]
            }
          })),
          status: 'created'
        }
      };

      this.combinedData = {
        ...this.data,
        ...this.recipientData,
        email: {
          email: 'joliva@elbueninquilino.es' // TODO - What email send?
        }
      };
    }
  }

  downloadFile(url: string): void {
    // Create an anchor element
    const a = document.createElement('a');

    // Set the file URL and necessary attributes
    a.href = url;
    a.target = '_blank'; // Open in a new tab (if needed)
    a.download = url.split('/').pop() || 'downloaded-file'; // Filename fallback

    // Append anchor to the DOM
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor after the click
    document.body.removeChild(a);
  }

  downloadPDF(base64Data: string): void {
    const binaryString = window.atob(base64Data); // Decode base64 to binary string
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    // Convert binary string to array of bytes
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create an anchor element for downloading the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'document.pdf'; // Set the file name

    // Append the anchor to the DOM and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after download
  }

  /*sampleData = {
    contractData: {
      full_loan_value: 25000.0, // TODO - De dónde lo sacamos
      installments_value: 450.0, // TODO - cómo lo calculamos
      loan_value_numbers: 25000.0, // TODO - cómo lo calculamos
      debtor_account_iban: 'ES1234567890123456789012', // TODO - De dónde lo sacamos
      interest_full_value: 1500.0, // TODO de donde lo sacamos
      debtor_account_Swift_BIC: 'BICCODE123', // TODO - Es necesario ésto?
      number_of_installments_2: 60, // TODO - Siempre será 60 meses?
      installments_account_iban: 'ES1234567890123456789012', // TODO - Esto coincide con el debtor_account?
      non_requestor_full_name_and_gov_id: 'Non-Requestor Name (GOVID0033)', // TODO ?? - Es necesario. Podría haber varios coinquilinos
    }
  };*/

  private initFormParametersFromBackend(payload: BackendPayload): void {
    this.insuranceService.getModelData(payload).subscribe((data: any) => {
      this.contractData = data.fieldsMap;
      this.data = {
        contractData: this.contractData
      };

      this.recipientData.recipients.templateId = payload.selectedTemplate;

      this.combinedData = {
        ...this.data,
        ...this.recipientData,
        email: {
          email: 'joliva@elbueninquilino.es' // TODO - What email send?
        }
      };

      this.embeddedRetool.data = this.combinedData;
    });
  }

  private initFormParametersFromCandidature(): void {
    this.contractData = {
      TAE: 3.75,
      TAE_2: 4.2,
      months: 60,
      opening_fee: 'SÍ. VEINTITRÉS CON CINCUENTA EUROS (23,50€)',
      interest_rate: 5.5,
      opening_fee_value: 23.5,
      pledge_true_false: 'Sí',
      installments_value: 450.0,
      opening_fee_financed: 'NO',
      number_of_installments: 60,
      pledge_full_text_normalized_contract:
        'Este contrato de Préstamo estará garantizado por un derecho real de prenda sobre los derechos de crédito que el Prestatario ostenta frente al Arrendador dimanantes de la devolución de la fianza arrendaticia...',
      interest_full_value: 1500.0,
      debtor_account_Swift_BIC: 'BICCODE123',
      installments_account_iban: 'ES1234567890123456789012'
    };

    this.contractData['mandate_number'] = 'TODO'; // TODO
    this.contractData['contract_number'] = 'TODO'; // TODO
    this.contractData['full_loan_value'] = this.candidature.asset.rentalPrice;
    this.contractData['requestor_email'] = this.user.email;
    this.contractData['requestor_phone'] = this.formatPhoneNumber(
      this.user.phone
    );
    this.contractData['ebi_candidacy_id'] = this.candidature.id;
    this.contractData['months'] = 60;
    this.contractData['end_of_loan_date'] = moment()
      .add(60, 'months')
      .format('DD/MM/YYYY');
    this.contractData['loan_all_debtors'] = this.getTenantDataList();
    this.contractData['origination_date'] = moment().format('DD/MM/YYYY');

    // Datos del usuario que está en EBI logueado
    this.contractData['signature_1_full_name'] =
      this.user.firstname + ' ' + this.user.surname1;
    this.contractData['signature_1_gov_ID'] = this.user.dni;

    const tenants = this.candidature.tenantCandidatureList
      .map((tc: TenantCandidature) => tc.user)
      .filter(
        (user: User) =>
          user.email?.toLowerCase() !== this.user.email.toLowerCase()
      );

    // Recorrer los coTenants y asignar sus nombres a los campos de firma dinámicamente
    let nonRequestorData = '';
    tenants?.forEach((user: User, index: number) => {
      const fullName = `${user.firstname} ${user.surname1}`;
      const fullNameKey = `signature_${index + 2}_full_name`;
      const dniKey = `signature_${index + 2}_gov_ID`; // Generar la clave de forma dinámica
      this.contractData[fullNameKey] = fullName;
      this.contractData[dniKey] = user.dni;
      nonRequestorData += `${user.firstname} ${user.surname1} (${user.dni}), `;
    });
    this.contractData['non_requestor_full_name_and_gov_id'] = nonRequestorData;
    this.contractData['loan_value_numbers'] =
      this.candidature.asset.rentalPrice;
    this.contractData['loan_value_letters'] = this.numeroALetras(
      this.candidature.asset.rentalPrice
    );
    this.contractData['rental_asset_address'] = this.getFullAddress();

    // Datos propietario
    this.contractData['landlord_full_name_and_gov_id'] = this.getUserData(
      this.candidature.homeowner.user
    );
    // Datos firmante
    this.contractData['requestor_full_name_and_gov_id'] = this.getUserData(
      this.user
    );

    // Datos sobre activo
    this.contractData['rental_asset_address_only_street'] =
      `${this.candidature.asset.street} ${parseInt(this.candidature.asset.number).toString()}`;
    this.contractData['rental_asset_address_postal_code_and_city'] =
      `${this.candidature.asset.postalCode}, ${this.candidature.asset.town}`;

    this.data = {
      contractData: this.contractData
    };

    this.combinedData = {
      ...this.data,
      ...this.recipientData,
      email: {
        email: 'joliva@elbueninquilino.es' // TODO - What email send?
      }
    };

    this.embeddedRetool.data = this.combinedData;
  }

  private setUser(): void {
    this.user = this.auth.user;
  }

  private formatPhoneNumber(phone: string): string | null {
    if (!phone) {
      return null; // Si el teléfono es null o undefined
    }

    // Eliminar cualquier carácter que no sea un dígito o un signo de + al inicio
    let cleanedPhone = phone.replace(/[^\d+]/g, '');

    // Asegurarnos de que solo hay un '+' al inicio, si es internacional
    if (cleanedPhone.startsWith('+')) {
      cleanedPhone = '+' + cleanedPhone.slice(1).replace(/\+/g, ''); // Quitar cualquier '+' adicional en el resto
    }

    // Si el resultado es solo un + sin números, lo invalidamos
    if (cleanedPhone === '+') {
      return null;
    }

    // Si no tiene un '+' al inicio, lo retornamos como string de solo dígitos
    return cleanedPhone;
  }

  private numeroALetras(num: number): string {
    const unidades = [
      '',
      'UNO',
      'DOS',
      'TRES',
      'CUATRO',
      'CINCO',
      'SEIS',
      'SIETE',
      'OCHO',
      'NUEVE'
    ];
    const decenas = [
      '',
      '',
      'VEINTE',
      'TREINTA',
      'CUARENTA',
      'CINCUENTA',
      'SESENTA',
      'SETENTA',
      'OCHENTA',
      'NOVENTA'
    ];
    const centenas = [
      '',
      'CIEN',
      'DOSCIENTOS',
      'TRESCIENTOS',
      'CUATROCIENTOS',
      'QUINIENTOS',
      'SEISCIENTOS',
      'SETECIENTOS',
      'OCHOCCIENTOS',
      'NOVECIENTOS'
    ];

    const entero = Math.floor(num);
    const decimal = Math.round((num - entero) * 100); // Obtener los centavos

    const convertirEntero = (num: number): string => {
      if (num < 10) {
        return unidades[num];
      } else if (num >= 10 && num < 20) {
        const especiales = [
          '',
          'DIEZ',
          'ONCE',
          'DOCE',
          'TRECE',
          'CATORCE',
          'QUINCE',
          'DIECISEIS',
          'DIECISETE',
          'DIECIOCHO',
          'DIECINUEVE'
        ];
        return especiales[num - 10];
      } else if (num >= 20 && num < 100) {
        return (
          decenas[Math.floor(num / 10)] +
          (num % 10 > 0 ? ' Y ' + unidades[num % 10] : '')
        );
      } else if (num >= 100 && num < 1000) {
        return (
          centenas[Math.floor(num / 100)] +
          (num % 100 > 0 ? ' ' + convertirEntero(num % 100) : '')
        );
      } else if (num >= 1000 && num < 10000) {
        const mil =
          Math.floor(num / 1000) > 1 ? unidades[Math.floor(num / 1000)] : '';
        return (
          (mil ? mil + ' MIL' : 'MIL') +
          (num % 1000 > 0 ? ' ' + convertirEntero(num % 1000) : '')
        );
      } else {
        return 'NÚMERO DEMASIADO GRANDE';
      }
    };

    const enteroEnLetras = convertirEntero(entero);
    let resultado = enteroEnLetras.trim();

    // Agregar parte decimal si existe
    if (decimal > 0) {
      resultado += ` CON ${decimal} CÉNTIMOS`;
    }

    return resultado;
  }

  private getFullAddress(): string {
    return `${this.candidature.asset.street} ${parseInt(this.candidature.asset.number).toString()}, Portal ${this.candidature.asset.portal} - ${this.candidature.asset.floor}º${this.candidature.asset.door}`;
  }

  private getUserData(user: User | UserCreateCandidatureDto): string {
    return `${user.firstname} ${user.surname1} ${user.surname2} (${user.dni})`;
  }
}
