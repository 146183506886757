import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExportBuildingAsset } from '../../models';

@Injectable()
export class BuildingAssetApiService {
  constructor(private http: HttpClient) {}

  getAll(id: string): Observable<ExportBuildingAsset[]> {
    const url = `${environment.services.assets}/building/${id}/occupation-excel`;

    return this.http
      .get<{ assets: ExportBuildingAsset[] }>(url)
      .pipe(map(buildResponse));
  }
}

function buildResponse(data: {
  assets: ExportBuildingAsset[];
}): ExportBuildingAsset[] {
  return data.assets;
}
