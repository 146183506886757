/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { Candidature, TenantCandidature } from '@core/models';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-trial-document-page',
  templateUrl: './trial-document-page.component.html'
})
export class TrialDocumentPageComponent implements OnInit {
  candidature: Candidature;
  cotenantsList: TenantCandidature[] = [];
  subscriptions: Subscription[] = [];
  activeTab: string;
  loadError = false;

  //error component
  errorTitle: string;
  paragraphOne: string;
  paragraphTwo: string;
  errorType: string;

  isBackoffice = false;

  constructor(
    public modalController: ModalController,
    private router: Router,
    private toastController: ToastController,
    private candidatureService: CandidaturesApiService,
    private translateSevice: TranslateService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setTexts();
    this.candidature = this.candidatureService.selectedCandidature;
    this.getCandidatureData();
  }

  setTexts(): void {
    this.errorTitle = this.translateSevice.instant(
      'pages.certifications.documents_certifications.oops'
    );
    this.paragraphOne = this.translateSevice.instant(
      'pages.home_owner_tab_document.p1'
    );
    this.paragraphTwo = this.translateSevice.instant(
      'pages.certifications.documents_certifications.p2'
    );
    this.errorType = this.translateSevice.instant(
      'pages.certifications.documents_certifications.error'
    );
  }

  getCandidatureData(): void {
    const initialTab =
      this.router.url.split('/')[this.router.url.split('/').length - 2];
    if (this.candidature) {
      this.setCotenantList(initialTab, this.candidature?.tenantCandidatureList);
    } else if (this.router.url.includes('backoffice')) {
      this.isBackoffice = true;
      if (history?.state?.from && history?.state?.to) {
        localStorage.setItem('dateFrom', history?.state?.from);
        localStorage.setItem('dateTo', history?.state?.to);
      }
      // Si se viene desde pantalla de customer care
      const candidatureId = this.route.snapshot.paramMap.get('candidatureId');
      this.candidatureService
        .getCandidature(candidatureId)
        .subscribe((candidature) => {
          this.candidatureService.selectedCandidature = candidature;
          this.candidature = candidature;
          this.setCotenantList(initialTab, candidature.tenantCandidatureList);
        });
    } else {
      this.loadError = true;
    }
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  goBack(): void {
    if (this.isBackoffice) {
      this.navigationService.goToCustomerCareRequestAnalysisMinimal(
        this.candidatureService.selectedCandidature.id,
        localStorage.getItem('dateFrom'),
        localStorage.getItem('dateTo')
      );

      localStorage.removeItem('dateFrom');
      localStorage.removeItem('dateTo');
    } else {
      const routeArray = this.router.url.split('/documents');
      this.router.navigate([routeArray[0]], {
        state: { return: true }
      });
    }
  }

  checkActiveTab(cotenantId: string): boolean {
    this.activeTab =
      this.router.url.split('/')[this.router.url.split('/').length - 1];
    if (cotenantId === this.activeTab) {
      return true;
    } else {
      return false;
    }
  }

  private setCotenantList(initialTab: string, list: TenantCandidature[]): void {
    this.cotenantsList = list;
    if (initialTab === 'documents' && this.cotenantsList?.[0]?.user) {
      this.router.navigate([
        this.router.url + '/tab-document/' + this.cotenantsList[0].user.id
      ]);
    }
  }
}
