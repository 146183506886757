import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CotenantsArag,
  DocumentDTO,
  MyCustomEvent,
  PolicyIssue,
  Sinister,
  User
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { AlertController, ModalController } from '@ionic/angular';
import { first } from 'rxjs';

import { CreateSinisterForm } from '../../models/create-sinister-modal.model';
import { SinisterDocumentsComponent } from '../sinister-documents/sinister-documents.component';

@Component({
  selector: 'el-buen-inquilino-create-sinester-modal',
  templateUrl: './create-sinester-modal.component.html'
})
export class CreateSinesterModalComponent implements OnInit {
  @Input() user: User;
  @Input() insurance: PolicyIssue;
  @Input() isIncofisa = false;
  @Input() unpaid = false;
  @ViewChild('sinisterDocuments')
  sinisterDocumentsComponent: SinisterDocumentsComponent;

  isTenantCommunicationChecked = false;
  form: FormGroup<CreateSinisterForm>;
  today: Date = new Date();
  userPolicyHolder: User;
  documents: DocumentDTO[] = [];
  showUploadRentContractBtn = false;

  get dateControl(): FormControl<Date> {
    return this.form.controls.date;
  }

  get contenats(): CotenantsArag[] {
    return this.insurance?.insuranceAsset?.cotenants || [];
  }

  get notifyEmailControl(): FormControl<string> {
    return this.form.controls.notifyEmail;
  }

  get commentsControl(): FormControl<string> {
    return this.form.controls.comments;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    public alertController: AlertController,
    private userService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.initPrincipalForm();
    this.getHomeownerUser();
  }

  getHomeownerUser(): void {
    if (!!this.user) {
      this.userPolicyHolder = this.user;
    } else {
      this.userService
        .getUserObservable(this.insurance.userId)
        .pipe(first())
        .subscribe((user: User) => (this.userPolicyHolder = user));
    }
  }

  dismiss(): void {
    if (this.isIncofisa) {
      this.modalController.dismiss({ insurance: this.insurance });
    } else {
      this.modalController.dismiss();
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const sinister: Sinister = {
      id: null,
      creationDate: new Date(),
      startDate: this.form.getRawValue().date,
      tenantCommunication: this.form.getRawValue().tenantCommunication,
      comments: this.form.getRawValue().comments,
      emailsToNotify: [this.notifyEmailControl.value],
      totalDebt: this.form.value.totalDebt
    };

    this.modalController.dismiss({
      sinister
    });
  }

  toggleChange(event: Event): void {
    this.isTenantCommunicationChecked = (
      event as CustomEvent<MyCustomEvent>
    ).detail.checked;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.form.get('date').setValue(event.value);
  }

  saveDocument(): void {
    this.sinisterDocumentsComponent.saveDocument(this.insurance.policyNumber);
  }

  setShowUploadBtn(show: boolean): void {
    this.showUploadRentContractBtn = show;
  }

  private initPrincipalForm(): void {
    this.form = new FormGroup<CreateSinisterForm>({
      address: new FormControl<string>({
        value: this.insurance.insuranceAsset.address,
        disabled: true
      }),
      city: new FormControl<string>({
        value: this.insurance.insuranceAsset.city,
        disabled: true
      }),
      policyHolder: new FormControl<string>({
        value: this.insurance.emailPolicyHolder,
        disabled: true
      }),
      tenantCommunication: new FormControl<boolean>(false),
      date: new FormControl<Date>(null, Validators.required),
      comments: new FormControl<string>(null, Validators.required),
      notifyEmail: new FormControl<string>(null, Validators.required),
      totalDebt: new FormControl<number>(null, Validators.required)
    });
  }
}
