import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  MyCustomEvent,
  OccupationEnum,
  TypeUserEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  ModalController,
  SelectCustomEvent,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

import { UpdateBasicInfoForm } from '../../models/update-basic-info.model';

@Component({
  selector: 'el-buen-inquilino-update-basic-info',
  templateUrl: './update-basic-info.component.html'
})
export class UpdateBasicInfoComponent implements OnInit, AfterContentChecked {
  @Input() user: User;
  isTenant = false;
  basicInfoForm: FormGroup<UpdateBasicInfoForm>;
  occupationEnum = OccupationEnum;
  typeUserEnum = TypeUserEnum;
  retryDNI = false;
  validationErrorMessages = appValidators.validationErrorMessages;
  documentOption = '1';
  dniPlaceholder: string;
  country: CountryCode = 'ES';

  maxBirthdate: Date;

  get occupationControl(): FormControl<string> {
    return this.basicInfoForm.controls.occupation;
  }
  get firstnameControl(): FormControl<string> {
    return this.basicInfoForm.controls.firstname;
  }
  get surname1Control(): FormControl<string> {
    return this.basicInfoForm.controls.surname1;
  }
  get surname2Control(): FormControl<string> {
    return this.basicInfoForm.controls.surname2;
  }
  get dniControl(): FormControl<string> {
    return this.basicInfoForm.controls.dni;
  }
  get emailControl(): FormControl<string> {
    return this.basicInfoForm.controls.email;
  }
  get businessNameControl(): FormControl<string> {
    return this.basicInfoForm.controls.businessName;
  }
  get cifControl(): FormControl<string> {
    return this.basicInfoForm.controls.cif;
  }
  get taxResidenceControl(): FormControl<string> {
    return this.basicInfoForm.controls.taxResidence;
  }
  get passwordControl(): FormControl<string> {
    return this.basicInfoForm.controls.password;
  }
  get confirmPasswordControl(): FormControl<string> {
    return this.basicInfoForm.controls.confirmPassword;
  }
  get phoneControl(): FormControl<string> {
    return this.basicInfoForm.controls.phone;
  }

  constructor(
    private modalController: ModalController,
    private usersService: UsersApiService,
    private authenticationService: AuthService,
    private toastController: ToastController,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.maxBirthdate = this.utilsService.calculate18YearsAgoStringDate();
    if (this.user.userType === this.typeUserEnum.TENANT) {
      this.isTenant = true;
    }
    this.createBasicInfoForm();
    this.documentChange(null);
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  // Previene error de cambios de referencia del formulario
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  documentChange(event: SelectCustomEvent): void {
    const value = (event?.detail?.value as string) || this.documentOption;
    this.documentOption = value;
    if (Number(value) === 1) {
      this.dniPlaceholder = this.translateService.instant(
        'pages.login.data.signin.dni-placeholder'
      ) as string;
      this.dniControl.removeValidators(appValidators.passportValidator);
      this.dniControl.addValidators(appValidators.dniAndNieValidator);
    } else {
      this.dniPlaceholder = this.translateService.instant(
        'pages.login.data.signin.email_signin_component.passport'
      ) as string;
      this.dniControl.removeValidators(appValidators.dniAndNieValidator);
      this.dniControl.addValidators(appValidators.passportValidator);
    }
    this.dniControl.updateValueAndValidity();
  }

  selectChanged(event: SelectCustomEvent): void {
    if (event.detail.value === this.occupationEnum.BUSINESS) {
      this.validationsForBusinessRol(true);
    } else {
      this.validationsForBusinessRol(false);
    }
  }

  validationsForBusinessRol(businessIsActive: boolean): void {
    this.basicInfoForm.controls.businessName.setValidators(
      businessIsActive
        ? Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
            appValidators.complexNameValidator
          ])
        : null
    );
    this.basicInfoForm.controls.businessName.updateValueAndValidity();
    this.basicInfoForm.controls.cif.setValidators(
      businessIsActive
        ? Validators.compose([Validators.required, appValidators.cifValidator])
        : null
    );
    this.basicInfoForm.controls.cif.updateValueAndValidity();
    this.basicInfoForm.controls.taxResidence.setValidators(
      businessIsActive
        ? Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
            appValidators.addressValidator
          ])
        : null
    );
    this.basicInfoForm.controls.taxResidence.updateValueAndValidity();

    if (!businessIsActive) {
      this.basicInfoForm.patchValue({
        businessName: '',
        cif: '',
        taxResidence: ''
      });
    }
  }

  submit(): void {
    if (this.basicInfoForm.valid) {
      let password = this.basicInfoForm.value.password;
      // Si ha introducido una pass, la encriptamos
      if (password) {
        password = this.authenticationService.encryptPassword(
          this.basicInfoForm.value.password
        );
      }
      // Para que no falle backend, si es un homeowner,
      // le tenemos que meter por defecto una ocupación
      // Después en backend no hacemos nada con ella, pero así nos evitamos el error de BadRequest
      if (this.user.userType === this.typeUserEnum.HOMEOWNER) {
        this.basicInfoForm.value.occupation = OccupationEnum.WORKER;
      }

      let phone = null;
      if (this.basicInfoForm.value.phone) {
        phone = this.utilsService.formatPhone(this.basicInfoForm.value.phone);
      }

      // Si se selecciona pasaporte
      if (Number(this.documentOption) === 2) {
        this.retryDNI = true;
      }

      this.loadingService.presentSecondLoader(
        this.translate.instant(
          'components.update_basic_info.updating_user'
        ) as string
      );
      this.usersService
        .updateBasicInfoHomeowner(
          this.user.id,
          this.basicInfoForm.getRawValue().email,
          this.basicInfoForm.value.firstname,
          this.basicInfoForm.value.surname1,
          this.basicInfoForm.value.surname2,
          password,
          this.basicInfoForm.value.dni,
          this.basicInfoForm.value.occupation,
          this.basicInfoForm.value.businessName,
          this.basicInfoForm.value.cif,
          this.basicInfoForm.value.taxResidence,
          phone as string,
          null,
          null,
          this.user.userType,
          this.retryDNI
        )
        .subscribe({
          next: (userUpdated: User) => {
            this.loadingService.dismissSecondLoader();
            this.usersService
              .deleteUserRegisterRequest(this.user.email)
              .subscribe(() => {
                if (this.retryDNI) {
                  this.retryDNI = false;
                }

                this.getNewValidToken(
                  this.user.email,
                  this.basicInfoForm.value.password
                )
                  .then(() => {
                    this.user = userUpdated;
                    this.dismiss();
                  })
                  .catch(() => {
                    this.user = userUpdated;
                    this.dismiss();
                  });
              });
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error: any) => {
            this.loadingService.dismissSecondLoader();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.error?.code === '100') {
              this.presentToast(
                false,
                'pages.tenant.home-owner.invalidDniWarn',
                true
              );
              this.retryDNI = true;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            } else if (error.error?.code === '108') {
              this.presentToast(false, 'pages.login.error.email-exist');
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            } else if (error.error?.code === '218') {
              this.presentToast(false, 'pages.login.error.phone-exist');
            } else {
              this.presentToast(false);
            }
          }
        });
    } else {
      Object.values(this.basicInfoForm.controls).forEach(
        (control: UntypedFormControl) => {
          control.markAsDirty();
          control.markAsTouched();
        }
      );

      this.presentToast(false, 'pages.profile.home-owner.invalidForm');
    }
  }

  async presentToast(
    ok: boolean,
    message?: string,
    warning?: boolean
  ): Promise<void> {
    let messageKey = ok
      ? 'pages.profile.tenant.savedOK'
      : 'pages.profile.tenant.invalidForm';
    let colorKey = ok ? 'success' : 'danger';
    if (warning) {
      colorKey = 'warning';
    }
    if (message) {
      messageKey = message;
    }
    const toast = await this.toastController.create({
      message: this.translate.instant(messageKey) as string,
      position: 'top',
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  dismiss(): void {
    this.modalController.dismiss({
      user: this.user
    });
  }

  /**
   * Dependiendo del valor de password, pone confirmPassword como required o no required
   * @param event
   */
  passwordChange(event: Event): void {
    const value = (event as CustomEvent<MyCustomEvent>).detail.value as string;

    if (value !== '') {
      this.confirmPasswordControl.setValidators([
        Validators.required,
        Validators.maxLength(55)
      ]);
    } else {
      // Cuando el campo se oculta, se resetea su estado
      this.confirmPasswordControl.clearValidators();
      this.confirmPasswordControl.setErrors(null);
      this.confirmPasswordControl.markAsUntouched();
    }
  }

  private createBasicInfoForm(): void {
    this.basicInfoForm = new FormGroup<UpdateBasicInfoForm>(
      {
        email: new FormControl<string>(
          { value: this.user?.email || '', disabled: !!this.user.email },
          [Validators.required, appValidators.emailValidator]
        ),
        firstname: new FormControl<string>(this.user?.firstname || '', [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(40),
          appValidators.namesValidator
        ]),
        surname1: new FormControl<string>(this.user?.surname1 || '', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          appValidators.namesValidator
        ]),
        surname2: new FormControl<string>(this.user?.surname2 || ''),
        phone: new FormControl<string>(
          this.user?.phone
            ? this.phoneMaskService.formatPhone(this.user.phone, this.country)
            : null,
          this.getPhoneControlValidators()
        ),
        dni: new FormControl<string>('', [
          Validators.required,
          appValidators.dniAndNieAndPassportValidator
        ]),
        occupation: new FormControl<string>(
          this.user?.userType === this.typeUserEnum.HOMEOWNER
            ? OccupationEnum.WORKER
            : ''
        ),
        businessName: new FormControl<string>(''),
        cif: new FormControl<string>(''),
        taxResidence: new FormControl<string>(''),
        password: new FormControl<string>('', [
          Validators.required,
          Validators.maxLength(55)
        ]),
        confirmPassword: new FormControl<string>('')
      },
      { validators: appValidators.passwordMatchingValidator }
    );
  }

  private getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  private getNewValidToken(email: string, password: string): Promise<null> {
    // eslint-disable-next-line @typescript-eslint/typedef
    return new Promise((resolve, reject) => {
      this.authenticationService
        .getNewValidToken(email, password)
        .then(() => resolve(null))
        .catch(() => reject(null));
    });
  }
}
