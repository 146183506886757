/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  EmitterUserEmailInvitation,
  TypeUserEnum,
  User,
  ValidateEmailResponse
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { StepsService } from '@core/services/steps/steps.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { AlertController, IonInput, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-step-two-multiple',
  templateUrl: './step-two-multiple.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class StepTwoMultipleComponent implements OnInit, AfterViewInit {
  users$: Observable<User[]>;
  email = '';
  @Output() selectedUser = new EventEmitter<User>();
  userNotFound: boolean;
  showInput = true;
  shouldRemoveUser = false;
  invitationSended = false;
  user: User;
  emailIsValid = true;
  errorTenantWithCotenants = false;
  duplicatedUser = false;
  @ViewChild('emailInput', { read: ElementRef }) myInput: ElementRef<IonInput>;

  validateUserEmail: ValidateEmailResponse;

  constructor(
    private usersService: UsersApiService,
    public stepsService: StepsService,
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.stepsService.invalidTenant = false;
  }

  ngAfterViewInit(): void {
    this.setEmailInputFocus();
  }

  setEmailInputFocus(): void {
    setTimeout(() => {
      this.myInput.nativeElement.setFocus();
    }, 300);
  }

  searchUser(): void {
    this.userNotFound = false;
    this.stepsService.invalidTenant = false;
    this.stepsService.invalidGuarantor = false;
    this.stepsService.invalidOwner = false;
    this.errorTenantWithCotenants = false;
    this.duplicatedUser = false;
    this.email = this.email.toLowerCase();
    this.loadingService.presentSecondLoader(null, false);
    this.users$ = this.usersService.getUserByEmail(this.email, true).pipe(
      tap(
        (user) => {
          if (
            user[0].guarantor &&
            (!this.stepsService.userRequestCreateCandidatureDto?.users ||
              this.stepsService.userRequestCreateCandidatureDto.users.length ===
                0)
          ) {
            this.stepsService.invalidGuarantor = true;
          } else if (user[0].userType === TypeUserEnum.HOMEOWNER) {
            this.stepsService.invalidOwner = true;
          } else if (this.checkBusinessUser(user[0])) {
            this.stepsService.businessError = true;
          } else {
            const index =
              this.stepsService.userRequestCreateCandidatureDto.users?.findIndex(
                (userCandidature) => {
                  return userCandidature.id === user[0].id;
                }
              );
            if (index > -1) {
              this.duplicatedUser = true;
              this.loadingService.dismissSecondLoader();
              return;
            }
            if (user[0].coTenants) {
              user[0].coTenants.forEach((cotenantId) => {
                this.usersService
                  .getUser(cotenantId, false)
                  .then((cotenant) => {
                    this.selectedUser.emit(cotenant);
                    this.showInput = false;
                  });
              });
            }
            this.user = user[0];
            this.showInput = false;
            this.selectedUser.emit(user[0]);
          }
          this.loadingService.dismissSecondLoader();
        },
        (error) => {
          if (error.error.code === '102') {
            // Si el usuario no existe, comprobamos que sea un email valido
            this.usersService
              .verifyUserEmail(this.email)
              .pipe(first())
              .subscribe({
                next: (response: ValidateEmailResponse) => {
                  this.validateUserEmail = response;

                  this.userNotFound = true;
                  this.showInput = false;
                  this.shouldRemoveUser = true;

                  this.loadingService.dismissSecondLoader();
                },
                error: (err) => {
                  console.error(err);
                  this.loadingService.dismissSecondLoader();
                }
              });
          }
        }
      )
    );
  }

  checkEmail(e: EmitterUserEmailInvitation): void {
    this.user = e.user;
    this.emailIsValid = e.emailIsValid;
  }

  checkUser(): boolean {
    //return this.user?.coTenants?.length > 0;
    return false;
  }

  removeUserFromParent(ids: string[]): void {
    if (ids.indexOf(this.user?.id) > -1) {
      this.user = null;
      this.shouldRemoveUser = true;
      this.showInput = true;
      this.userNotFound = false;
      this.stepsService.invalidTenant = false;
      this.stepsService.invalidGuarantor = false;
      this.stepsService.invalidOwner = false;
      this.email = '';
      this.user = null;
      this.emailIsValid = true;
    }
  }

  removeUser(): void {
    // Si tiene cotenants los borramos del listado también
    if (this.user?.coTenants) {
      this.user.coTenants.forEach((cotenantId) => {
        const index =
          this.stepsService.userRequestCreateCandidatureDto.users.findIndex(
            (user) => {
              return user.id === cotenantId;
            }
          );
        this.stepsService.userRequestCreateCandidatureDto.users.splice(
          index,
          1
        );
      });
    }

    if (this.user?.id) {
      // Ahora borramos del listado al propio usuario
      const index =
        this.stepsService.userRequestCreateCandidatureDto.users.findIndex(
          (userCandidature) => {
            return userCandidature.id === this.user.id;
          }
        );
      this.stepsService.userRequestCreateCandidatureDto.users.splice(index, 1);
    }

    this.shouldRemoveUser = true;
    this.showInput = true;
    this.userNotFound = false;
    this.stepsService.invalidTenant = false;
    this.stepsService.invalidGuarantor = false;
    this.stepsService.invalidOwner = false;
    this.email = '';
    this.user = null;
    this.selectedUser.emit(null);
  }

  async preAddAnotherUser(guarantor: boolean = false): Promise<void> {
    if (!!this.user) {
      if (!this.emailIsValid) {
        const alert = await this.alertController.create({
          message: this.translateService.instant(
            'pages.add-tenant.multiple_candidature.step_two.verify_tenant_email'
          ),
          buttons: [
            {
              text: this.translateService.instant(
                'pages.add-tenant.multiple_candidature.step_two.cancel'
              ),
              role: 'cancel',
              cssClass: 'secondary',
              handler: (): void => {
                // Limpiamos los campos
                this.removeUser();
              }
            },
            {
              text: this.translateService.instant(
                'pages.add-tenant.multiple_candidature.step_two.continue'
              ),
              handler: (): void => {
                this.emailIsValid = true;
                this.addAnotherUser(guarantor);
              }
            }
          ]
        });

        await alert.present();
      } else {
        this.addAnotherUser(guarantor);
      }
    } else {
      this.presentToast(
        this.translateService.instant(
          'pages.add-tenant.multiple_candidature.step_two.required_fields_msg'
        ) as string,
        true
      );
    }
  }

  async presentToast(messages: string, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'danger' : 'success',
      duration: 2000
    });
    toast.present();
  }

  addAnotherUser(guarantor: boolean = false): void {
    if (this.user && guarantor) {
      this.user.guarantor = guarantor;
    }
    if (this.user?.phone) {
      this.user.phone = this.utilsService.formatPhone(this.user.phone);
    }
    this.selectedUser.emit(this.user);
    this.showInput = true;
    this.userNotFound = false;
    this.stepsService.invalidTenant = false;
    this.email = '';
    this.user = null;
    this.setEmailInputFocus();
  }

  checkTenantHasCandidaturesYet(user: User): void {
    this.candidatureService
      .getCandidaturesByTenantId(user.id)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: any) => {
        if (data && data.length > 0) {
          this.stepsService.invalidTenant = true;
        } else {
          this.showInput = false;
          this.selectedUser.emit(user);
        }
      });
  }

  mask(name): string {
    const regex1 = /\b[^\W]{2}([^\W]{1,2})\b/g;
    const regex2 = /\b[^\W]{2}([^\W]{2,})[^\W]\b/g;

    if (!!name) {
      return name.replace(regex1, '**$1').replace(regex2, '**$1*');
    } else {
      return '';
    }
  }

  private checkBusinessUser(userToAdd: User): boolean {
    if (this.stepsService.userRequestCreateCandidatureDto.users?.length > 0) {
      return (
        userToAdd.business ||
        this.stepsService.userRequestCreateCandidatureDto.users.some(
          (tenant) => tenant.business
        )
      );
    } else {
      return false;
    }
  }
}
