/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { PolicyHolderData } from '@core/models';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

@Component({
  selector: 'el-buen-inquilino-financial-data-building-modal',
  templateUrl: './financial-data-building-modal.component.html'
})
export class FinancialDataBuildingModalComponent implements OnInit {
  @Input() financialData: PolicyHolderData;
  form: UntypedFormGroup;
  validationErrorMessages = appValidators.validationErrorMessages;
  country: CountryCode = 'ES';

  get phoneControl(): FormControl<string> {
    return this.form.controls.phone as FormControl<string>;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  save(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const newFinancialData: PolicyHolderData = {
      typeDocument: '1',
      document: this.form.controls.document.value,
      surname1: this.form.controls.surname1.value,
      street: this.form.controls.street.value,
      number: this.form.controls.number.value,
      city: this.form.controls.city.value,
      postalCode: this.form.controls.postalCode.value,
      province: this.form.controls.province.value,
      phone: this.formatPhone(this.form.controls.phone.value),
      email: this.form.controls.email.value,
      ccc: this.form.controls.ccc.value.replace(/\s/g, '')
    };
    this.modalController.dismiss({ newFinancialData });
  }

  /**
   * Se intruduce un espacio cada 4 numeros al valor del input ccc para facilitar lectura
   * @param event valor para ccc del fomulario
   */
  cccMaskChanges(event: Event): void {
    const value = (event.target as HTMLInputElement).value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.form.get('ccc').setValue(value);
  }

  private initData(): void {
    this.form = new UntypedFormGroup({
      document: new UntypedFormControl(
        this.financialData?.document,
        Validators.required
      ),
      surname1: new UntypedFormControl(
        this.financialData?.surname1,
        Validators.required
      ),
      street: new UntypedFormControl(
        this.financialData?.street,
        Validators.required
      ),
      number: new UntypedFormControl(
        this.financialData?.number,
        Validators.required
      ),
      city: new UntypedFormControl(
        this.financialData?.city,
        Validators.required
      ),
      postalCode: new UntypedFormControl(
        this.financialData?.postalCode,
        Validators.required
      ),
      province: new UntypedFormControl(
        this.financialData?.province,
        Validators.required
      ),
      phone: new UntypedFormControl(
        this.financialData?.phone
          ? this.phoneMaskService.formatPhone(
              this.financialData.phone,
              this.country
            )
          : null,
        this.getPhoneControlValidators()
      ),
      email: new UntypedFormControl(
        this.financialData?.email,
        Validators.required
      ),
      ccc: new UntypedFormControl(this.financialData?.ccc, [
        Validators.required,
        appValidators.bankAccountValidator
      ])
    });
  }

  private getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  private formatPhone(phone: any): string {
    return this.utilsService.formatPhone(phone);
  }
}
