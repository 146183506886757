import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import locales from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from '@environments/environment';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { MaterialModule } from '@material/material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { PrivateModule } from './modules/private/private.module';

registerLocaleData(locales);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + Date.now()
  );
}

const ANGULAR_MODULES = [
  BrowserAnimationsModule,
  BrowserModule,
  MaterialModule,
  CoreModule,
  PrivateModule,
  IonicModule.forRoot({
    rippleEffect: false,
    mode: 'ios'
  })
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    ...ANGULAR_MODULES,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideFirebaseApp(() => initializeApp({ ...environment.firebaseConfig })),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
