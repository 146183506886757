import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { PolicyIssue, SinisterThread, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { first } from 'rxjs';

import { CommentsForm } from '../../models/sinister-detail-modal.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-policy-cancelation-conversation',
  templateUrl: './policy-cancelation-conversation.component.html'
})
export class PolicyCancelationConversationComponent
  implements OnInit, OnChanges
{
  @Input({ required: true }) messages: SinisterThread[] = [];
  @Input({ required: true }) user: User;
  @Input({ required: true }) users: User[];
  @Input({ required: true }) policy: PolicyIssue;
  @Input() isIncofisa = false;

  form: FormGroup<CommentsForm>;
  messagesList: SinisterThread[] = [];

  get showForm(): boolean {
    return !this.policy.cancellationDate;
  }

  get isAddCommentButtonDisabled(): boolean {
    return (
      this.messageControl.value === null ||
      this.messageControl.value?.length < 10
    );
  }

  get messageControl(): FormControl<string> {
    return this.form.controls.message;
  }
  constructor(
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messages']) {
      this.setList();
    }
  }

  setList(): void {
    this.messagesList = (
      copyObject(this.messages) as SinisterThread[]
    ).reverse();
  }

  sendNewMessage(policyId: string): void {
    const data: SinisterThread = {
      date: new Date(),
      message: this.form.controls.message.value,
      userId: this.user.id
    };
    this.candidatureService
      .addCommentToCancellation(policyId, data)
      .pipe(first())
      .subscribe(() => {
        this.messages.unshift(data);
        this.setList();
        this.form.controls.message.reset();
      });
  }

  private setForm(): void {
    this.form = new FormGroup<CommentsForm>({
      message: new FormControl<string>(null)
    });
  }
}
