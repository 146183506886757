import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProfileForm } from '@shared-private/models/form-profile.model';
import { presentToast } from '@shared/utils/toast.utils';
import { manageErrorUpdate } from '@shared/utils/update-user-personal-data.utils';

import { UtilsService } from './utils.service';
import { UsersApiService } from '../../api-services/users-api/users-api.service';
import { ErrorResponse, UpdateCompleteUser, User } from '../../models';
import { LoadingService } from '../loading/loading.service';

@Injectable()
export class UserUtilsService {
  constructor(
    private utilsService: UtilsService,
    private loadingService: LoadingService,
    private userService: UsersApiService,
    private toastController: ToastController,
    private translate: TranslateService
  ) {}

  async updateTenant(user: User, form: FormGroup<ProfileForm>): Promise<void> {
    return new Promise<void>(
      async (resolve: () => void, reject: () => void) => {
        if (form.invalid) {
          this.utilsService.showFormErrors(form.controls.personalData);
          this.utilsService.showFormErrors(form.controls.currentAddress);
          reject();
          return;
        }

        const data: UpdateCompleteUser = {
          firstname: form.controls.personalData.controls.firstname.value,
          surname1: form.controls.personalData.controls.surname1.value,
          postalCode: form.controls.currentAddress.controls.postalCode.value,
          ...form.value.personalData,
          ...form.value.currentAddress,
          phone: this.utilsService.formatPhone(
            form.controls.personalData.controls.phone.value
          )
        };

        await this.loadingService.presentLoading(null);
        this.userService.updateUser2(user.id, data).subscribe({
          next: () => {
            this.onSuccessUpdatePersonalData();
            resolve();
          },
          error: (error: HttpErrorResponse) => {
            this.onErrorUpdatePersonalData(error.error as ErrorResponse);
            reject();
          }
        });
      }
    );
  }

  async onSuccessUpdatePersonalData(): Promise<void> {
    presentToast(
      this.toastController,
      this.translate.instant('pages.profile.tenant.savedOK') as string,
      'success'
    );
    await this.loadingService.dismissLoading();
  }

  async onErrorUpdatePersonalData(error: ErrorResponse): Promise<void> {
    await this.loadingService.dismissLoading();
    manageErrorUpdate(this.toastController, this.translate, error);
  }
}
