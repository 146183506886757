import { Component, Input, ViewChild } from '@angular/core';
import { CustomModalButtonRole, User } from '@core/models';
import { ModalController } from '@ionic/angular';
import { AppointmentFormValue } from '@shared/models/calendar.model';

import { AppointmentFormComponent } from '../appointment-form/appointment-form.component';

@Component({
  selector: 'el-buen-inquilino-create-new-appointment-modal',
  templateUrl: './create-new-appointment-modal.component.html'
})
export class CreateNewAppointmentModalComponent {
  @Input({ required: true }) user: User;
  @Input() showLinkTemplate = true;
  @ViewChild(AppointmentFormComponent)
  private formComponent: AppointmentFormComponent;

  constructor(private modalController: ModalController) {}

  dismiss(role: CustomModalButtonRole, data?: AppointmentFormValue): void {
    this.modalController.dismiss(data, role);
  }

  create(): void {
    this.formComponent.emitFormValue();
  }

  formEmit(event: AppointmentFormValue): void {
    this.dismiss(CustomModalButtonRole.CREATE, event);
  }

  close(): void {
    this.dismiss(CustomModalButtonRole.CANCEL);
  }
}
