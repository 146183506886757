/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartConfiguration } from 'chart.js';

import { AssetStatistic } from './asset.model';
import { Candidature } from './candidature.model';
import { PolicyIssue } from './insurance.model';
import {
  RequestAnalysisByCandidature,
  RequestAnalysisDto
} from './users.model';

export class GeneralAnalytics {
  assetsDisabled: number;
  assetsOpen: number;
  assetsRented: number;
  candidaturesCreated: number;
  candidaturesReject: number;
  candidaturesSelect: number;
  date?: Date;
  homeownersActivated: number;
  homeownersCreated: number;
  id?: string;
  insurancesContracted: number;
  tenantsCreated: number;
}
export class Session {
  sessionId: string;
  paymentStatus: string;
  planCode: string;
  maxReportsMonth: number;
  maxCandidaturesMonth: number;
  initialPaymentDate: Date;
  expirationDate: Date;
  subscriptionId: string;
  maxReportsByAssetMonth: number;
  maxCandidaturesByAssetMonth: number;
  id?: string;
}
export class UserAnalytics {
  id?: string;
  date: Date;
  userId: string;
  userEmail: string;
  userName: string;
  userPhone?: string;
  bigTenant?: boolean;
  assetsOpen: number;
  assetsRented: number;
  assetsDisabled: number;
  candidaturesCreated: number;
  candidaturesSelect: number;
  candidaturesReject: number;
  requestedReports: number;
  insurancesContracted: number;
  registeredAmount: number;
  session?: Session;
}

export class AssetAnalytics {
  id: string;
  userId: string;
  street: string;
  number: string;
  portal: string;
  floor: string;
  door: string;
  postalCode: string;
  town: string;
  province: string;
  link: string;
  rentalPrice: number;
  assetStatus: string;
  creationDate: Date;
  rentedDate: Date;
  disabledDate: Date;
  visitedAsset: boolean;
  lastUpdated: Date;
  cadastralReference: string;
  rentalArea: number;
  labelId: string;
  candidatures: CandidatureAnalytics[];
}

export interface CandidatureAnalytics extends Candidature {
  requestAnalyses: RequestAnalysisDto[];
}

export class CandidatureAssetAnalytics {
  id: string;
  assetId: string;
  address: string;
  assetCreationDate: Date;
  price: number;
  status: string;
  invoiced: boolean;
  incidences: number;
  tenants: number;
  reportId: string;
  reportStartDate: Date;
  reportEndDate: Date;
  time: number;
  score: number;
}

export class AllDataOwnerAnalytics {
  insurancesData: PolicyIssue[];
  assetStatistic: AssetStatistic[];
  requestByCandidatureData: RequestAnalysisByCandidature[];
  activityCharts: {
    analysisByMonth: {
      counts: number[];
      data: ChartConfiguration<'bar'>['data'];
      options: ChartConfiguration<'bar'>['options'];
      showChart: boolean;
    };
    ocupationRate: {
      labels: string[];
      dataOpen: any[];
      dataRented: any[];
      data: ChartConfiguration<'bar'>['data'];
      options: ChartConfiguration<'bar'>['options'];
      showChart: boolean;
    };
  };
  timesCharts: {
    sla: {
      less12: number;
      between12and24: number;
      more24: number;
      data: ChartConfiguration<'pie'>['data'];
      options: ChartConfiguration<'pie'>['options'];
      showChart: boolean;
    };
    incidentAnalysis: {
      incidencePercentage: number;
      data: ChartConfiguration<'pie'>['data'];
      options: ChartConfiguration<'pie'>['options'];
      showChart: boolean;
    };
    tenantByCandidature: {
      candidaturesByTenantNumber: number[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
      data: ChartConfiguration<'bar'>['data'];
      options: ChartConfiguration<'bar'>['options'];
      showChart: boolean;
    };
    candidatureWithGuarantor: {
      candidaturesWithGuarantorData: number[];
      data: ChartConfiguration<'doughnut'>['data'];
      options: ChartConfiguration<'doughnut'>['options'];
      showChart: boolean;
    };
  };
  sinisterCharts: {
    sinestersByMonth: {
      totalPoliciesWithSinister: any[];
      totalPolicies: any[];
      data: ChartConfiguration<'bar'>['data'];
      options: ChartConfiguration<'bar'>['options'];
      showChart: boolean;
    };
    provinceRatio: {
      provinceRatio: any[];
      labels: string[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
      data: ChartConfiguration<'pie'>['data'];
      options: ChartConfiguration<'pie'>['options'];
      showChart: boolean;
    };
    priceRatio: {
      priceRatio: number[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
      data: ChartConfiguration<'pie'>['data'];
      options: ChartConfiguration<'pie'>['options'];
      showChart: boolean;
    };
  };
}
