/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/typedef */
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';

import {
  MAINTENANCE_PAGE,
  NavigationService
} from './modules/core/services/navigation/navigation.service';
import { TranslationService } from './modules/core/services/translation/translation.service';
import { WindowMeasuresService } from './modules/core/services/window-measures/window-measures.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

register();

@Component({
  selector: 'el-buen-inquilino-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private router: Router,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private windowMeasuresService: WindowMeasuresService,
    private navigationService: NavigationService
  ) {
    this.initializeApp();
  }

  @HostListener('window:resize', ['$event'])
  windowChangeListener(): void {
    this.windowMeasuresService.setInnerWidth(window.innerWidth);
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      this.translateService.addLangs(['en', 'es']);
      this.translateService.setDefaultLang('es');

      this.translationService.setAppLanguage(
        localStorage.getItem('language') || 'es'
      );
    });
  }

  ngOnInit(): void {
    this.checkOnMaintenance();
    this.windowChangeListener();
    this.configGoogleAnalytics();
    this.configZendesk();
    this.configOsano();
    this.configGoogleMaps();
  }

  private checkOnMaintenance(): void {
    if (environment.maintenance && this.router.url !== MAINTENANCE_PAGE) {
      this.navigationService.goMaintenancePage();
    }
  }

  private configGoogleAnalytics(): void {
    if (environment.googleAnalytics.active) {
      const sub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalytics.key);
          sub.unsubscribe();
        }
      });
    }
  }

  private configZendesk(): void {
    if (environment.zendesk.active) {
      const sub = this.router.events.subscribe((event) => {
        //if (event instanceof NavigationEnd) {
        const node = document.createElement('script');
        node.id = environment.zendesk.id;
        node.src = environment.zendesk.url + environment.zendesk.key;
        node.type = 'text/javascript';
        document.getElementsByTagName('body')[0].appendChild(node);
        sub.unsubscribe();
        //}
      });
    }
  }

  private configOsano(): void {
    if (environment.osano.active) {
      const sub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (!event.url.includes('certifications')) {
            const node = document.createElement('script');
            node.src =
              environment.osano.url +
              environment.osano.key +
              environment.osano.file;
            node.type = 'text/javascript';
            node.async = true;
            document.getElementsByTagName('head')[0].appendChild(node);
            sub.unsubscribe();
          }
        }
      });
    }
  }

  private configGoogleMaps(): void {
    const node = document.createElement('script');
    node.src = environment.google_maps.url + environment.firebaseConfig.apiKey;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
