/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Directive({
  selector: '[elBuenInquilinoPhoneValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneValidatorDirective,
      multi: true
    }
  ]
})
export class PhoneValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const phoneReg = /^(\+34)?([0-9]){9}$/;

    if (!control.value) return null;
    return phoneReg.test(control.value) ? null : { phoneValidatorError: true };
  }
}
