import { Component, Input } from '@angular/core';
import { CustomModalButtonRole } from '@core/models';
import { ModalController } from '@ionic/angular';
import {
  IonSearchbarCustomEvent,
  SearchbarChangeEventDetail
} from '@ionic/core';
import { CountryCode } from 'libphonenumber-js/types.d';

import { Country } from '../../models/country.model';
import { countries } from '../../utils/country.utils';

@Component({
  selector: 'el-buen-inquilino-phone-flag-modal',
  templateUrl: './phone-flag-modal.component.html'
})
export class PhoneFlagModalComponent {
  @Input() country: CountryCode;
  countries: Country[] = countries;
  selectedCountry: Country;

  constructor(private modalController: ModalController) {}

  dismiss(role: CustomModalButtonRole, data?: CountryCode): void {
    this.modalController.dismiss(data, role);
  }

  close(): void {
    this.dismiss(CustomModalButtonRole.CANCEL);
  }

  accept(): void {
    this.dismiss(
      CustomModalButtonRole.ACCEPT,
      this.selectedCountry.countryIsoCode.toUpperCase() as CountryCode
    );
  }

  searchbarChange(
    event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>
  ): void {
    const value = event.detail.value;
    if (value.trim() === '') {
      this.countries = countries;
      return;
    }

    this.countries = countries.filter(
      (country: Country) =>
        country.countryIsoCode.toLowerCase().includes(value) ||
        country.name.toLowerCase().includes(value) ||
        country.phoneCode.toLowerCase().includes(value)
    );
  }

  selectCountry(event: Country): void {
    if (this.selectedCountry?.countryIsoCode === event.countryIsoCode) {
      this.selectedCountry = null;
    } else {
      this.selectedCountry = event;
    }
  }
}
