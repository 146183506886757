/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { BackofficeRole } from '@shared/models/backoffice.model';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import {
  CALENDAR_MENU_ITEM_HOME_OWNER,
  FEE_POCKET_MENU_ITEM_HOME_OWNER,
  INCIDENCES_MENU_ITEM_HOME_OWNER,
  INSURANCE_MENU_ITEM_HOME_OWNER,
  MENU_GUARANTOR,
  MENU_HOME_OWNER,
  MENU_HOME_OWNER_API_ADMIN,
  MENU_HOME_OWNER_BIG_TENANT,
  MENU_HOME_OWNER_BIG_TENANT_ASSOCIATED_USER,
  MENU_HOME_OWNER_EXTERNAL_LAWYER,
  MENU_HOME_OWNER_PORTFOLIO,
  MENU_HOME_OWNER_PORTFOLIO_BIG_TENANT_ASSOCIATED_USER,
  MENU_HOME_OWNER_UNPAID,
  PROFILE_MENU_ITEM_HOME_OWNER,
  MENU_TENANT,
  MENU_TRIAL_VERSION,
  MenuItem,
  SINISTER_MENU_ITEM_HOME_OWNER,
  TEMPLATES_MENU_ITEM_HOME_OWNER,
  TypeUserEnum,
  User
} from '../../models';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class MenuService {
  pages: MenuItem[];

  private pagesSubject = new BehaviorSubject<MenuItem[]>(new Array<MenuItem>());
  pages$ = this.pagesSubject.asObservable();

  private id: string;

  private userTypeMenu: string;

  showMenuSplitPane = true;
  menuSplitPane$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private authenticationService: AuthService) {
    this.authenticationService.getUser().subscribe((user: User) => {
      if (user && user.id) {
        this.id = user.id;
        this.loadMenuItems(user, user.session?.paymentStatus === 'paid');
        this.userTypeMenu = user.userType;
      }
    });
  }

  getMenuUserType(): string {
    return this.userTypeMenu;
  }

  loadMenuItems(user: User, paid?: boolean): void {
    this.pages = [];

    if (user.userType === TypeUserEnum.TENANT && user.guarantor) {
      this.pages = this.generateMenu(MENU_GUARANTOR);
    } else if (user.userType === TypeUserEnum.TENANT) {
      this.pages = this.generateMenu(MENU_TENANT);
    } else if (user.userType === TypeUserEnum.HOMEOWNER) {
      if (paid) {
        if (user.backofficeRoles?.includes(BackofficeRole.EXTERNAL_LAWYER)) {
          this.pages = this.generateMenu(MENU_HOME_OWNER_EXTERNAL_LAWYER);
        } else {
          if (user.portfolioId || user.apiRolId) {
            if (user.bigTenant && user.associatedUsers) {
              this.pages = this.generateMenu(
                MENU_HOME_OWNER_PORTFOLIO_BIG_TENANT_ASSOCIATED_USER
              );

              if (!user.blockCreatePolicy) {
                this.addShowPolicy(4);
                this.addShowSinister(5);
              }
            } else {
              this.pages = this.generateMenu(MENU_HOME_OWNER_PORTFOLIO);

              if (!user.blockCreatePolicy) {
                this.addShowPolicy(4);
                this.addShowSinister(5);
              }
            }
          } else if (user.apiRol) {
            this.pages = this.generateMenu(MENU_HOME_OWNER_API_ADMIN);

            if (!user.blockCreatePolicy) {
              this.addShowPolicy(4);
              this.addShowSinister(5);
            }
          } else if (user.bigTenant) {
            this.checkAssociatedUserForThirdPartyAsset(user);

            if (!user.blockCreatePolicy) {
              this.addShowPolicy(4);
              this.addShowSinister(5);
            }
          } else if (user.trialVersion) {
            this.pages = this.generateMenu(MENU_TRIAL_VERSION);

            if (user.hasFeePocket) {
              this.addFeePocket();
            }

            if (!user.blockCreatePolicy) {
              this.addShowPolicy();
              this.addShowSinister();
            }
          } else {
            this.pages = this.generateMenu(MENU_HOME_OWNER);

            if (user.hasFeePocket) {
              this.addFeePocket(4);
            }

            if (!user.blockCreatePolicy) {
              this.addShowPolicy(4);
              this.addShowSinister(5);
            }
          }
        }

        if (user.hasCalendar) {
          this.addCalendar();
        }

        if (user.incidencesActivated) {
          this.addIncidencesMenuItem(7);
        }

        if (user.documentsGeneration) {
          this.addTemplatesMenuItem(8);
        }

        if (!user.portfolioId && !user.apiId) {
          this.addProfileItem(9);
        }
      } else {
        this.pages = this.generateMenu(MENU_HOME_OWNER_UNPAID);
      }
    }

    this.pagesSubject.next(this.pages);
  }

  addCalendar(): void {
    const itemMenu = this.generateMenu(CALENDAR_MENU_ITEM_HOME_OWNER);

    const position = this.pages.length - 2;
    this.pages.splice(position, 0, ...itemMenu);
  }

  addFeePocket(pos?: number): void {
    const itemMenu = this.generateMenu(FEE_POCKET_MENU_ITEM_HOME_OWNER);

    if (pos) {
      this.pages.splice(pos, 0, ...itemMenu);
    } else {
      this.pages.push(...itemMenu);
    }
  }

  addIncidencesMenuItem(pos?: number): void {
    const itemMenu = this.generateMenu(INCIDENCES_MENU_ITEM_HOME_OWNER);

    if (pos) {
      this.pages.splice(pos, 0, ...itemMenu);
    } else {
      this.pages.push(...itemMenu);
    }
  }

  addTemplatesMenuItem(pos?: number): void {
    const itemMenu = this.generateMenu(TEMPLATES_MENU_ITEM_HOME_OWNER);

    if (pos) {
      this.pages.splice(pos, 0, ...itemMenu);
    } else {
      this.pages.push(...itemMenu);
    }
  }

  addProfileItem(pos?: number): void {
    const itemMenu = this.generateMenu(PROFILE_MENU_ITEM_HOME_OWNER);

    if (pos) {
      this.pages.splice(pos, 0, ...itemMenu);
    } else {
      this.pages.push(...itemMenu);
    }
  }

  addShowPolicy(pos?: number): void {
    const itemMenu = this.generateMenu(INSURANCE_MENU_ITEM_HOME_OWNER);

    if (pos) {
      this.pages.splice(pos, 0, ...itemMenu);
    } else {
      this.pages.push(...itemMenu);
    }
  }

  addShowSinister(pos?: number): void {
    const itemMenu = this.generateMenu(SINISTER_MENU_ITEM_HOME_OWNER);

    if (pos) {
      this.pages.splice(pos, 0, ...itemMenu);
    } else {
      this.pages.push(...itemMenu);
    }
  }

  checkAssociatedUserForThirdPartyAsset(user: User): void {
    this.pages = user.associatedUsers
      ? this.generateMenu(MENU_HOME_OWNER_BIG_TENANT_ASSOCIATED_USER)
      : this.generateMenu(MENU_HOME_OWNER_BIG_TENANT);
  }

  setMenuSplitPane(): void {
    this.showMenuSplitPane = !this.showMenuSplitPane;
    this.menuSplitPane$.next(this.showMenuSplitPane);
  }

  getMenuSplitPane(): Observable<boolean> {
    return this.menuSplitPane$.asObservable();
  }

  generateBackofficeMenu(roles: string[]): void {
    this.pages = [];

    if (roles?.length > 0) {
      roles.forEach((rol) => {
        if (rol === (BackofficeRole.ANALYSIS as string)) {
          this.pages.push({
            label: 'Análisis de candidaturas',
            icon: 'document-text-outline',
            path: '/backoffice/analysis'
          });
          this.pages.push({
            label: 'Gestión de portfolios',
            icon: 'business-outline',
            path: '/backoffice/cadastral-reference'
          });
        }
        if (rol === (BackofficeRole.ANALYTICS as string)) {
          this.pages.push({
            label: 'Seguimiento de actividad',
            icon: 'bar-chart-outline',
            path: '/backoffice/analytics'
          });
        }
        if (rol === (BackofficeRole.CUSTOMER_CARE as string)) {
          this.pages.push({
            label: 'Atención al cliente',
            icon: 'chatbubbles-outline',
            path: '/backoffice/customer-care'
          });
          this.pages.push({
            label: 'Consulta de usuario',
            icon: 'search-outline',
            path: '/backoffice/user-search'
          });
          this.pages.push({
            label: 'Gestion de plantillas automaticas',
            icon: 'document-outline',
            path: '/backoffice/templates-management'
          });
        }
        if (rol === (BackofficeRole.INSURANCE_COMISSIONS as string)) {
          this.pages.push({
            label: 'Gestión de cobros',
            icon: 'wallet-outline',
            path: '/backoffice/insurance-fees'
          });
        }
        if (rol === (BackofficeRole.HOMEOWNER_MANAGER as string)) {
          this.pages.push({
            label: 'Gestión de clientes',
            icon: 'people-outline',
            path: '/backoffice/homeowner-management'
          });
        }
        if (rol === (BackofficeRole.POLICIES as string)) {
          this.pages.push({
            label: 'Gestión de pólizas',
            icon: 'receipt-outline',
            path: '/backoffice/insurances-management'
          });
          this.pages.push({
            label: 'Gestión de siniestros',
            icon: 'warning-outline',
            path: '/backoffice/sinisters'
          });
        }
      });
    }

    this.pagesSubject.next(this.pages);
  }

  private generateMenu(pagesSource: MenuItem[]): MenuItem[] {
    const pages: MenuItem[] = [];
    for (const page of pagesSource) {
      pages.push(
        new MenuItem(
          page.label,
          page.icon,
          page.path.replace('{userId}', this.id)
        )
      );
    }
    return pages;
  }
}
