/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoadingService {
  loading: any;
  messageDefault: string;
  count = 0;

  constructor(
    private loadingController: LoadingController,
    private translateService: TranslateService
  ) {
    this.translateService.get('application.wait').subscribe((data: string) => {
      this.messageDefault = data;
    });
  }

  async presentLoading(messageToShow: string): Promise<void> {
    this.loading = await this.loadingController.create({
      message: messageToShow !== null ? messageToShow : this.messageDefault,
      backdropDismiss: true,
      spinner: 'bubbles',
      translucent: false,
      keyboardClose: false
    });
    
    await this.loading.present();
  }

  async dismissLoading(): Promise<void> {
    await this.loading?.dismiss();
  }

  async presentSecondLoader(
    messageToShow: string,
    backDropDismiss?: boolean
  ): Promise<void> {
    this.loading = await this.loadingController.create({
      message: messageToShow !== null ? messageToShow : this.messageDefault,
      backdropDismiss: backDropDismiss ? true : false,
      spinner: 'bubbles',
      translucent: false,
      keyboardClose: false
    });

    await this.loading.present();
  }

  async dismissSecondLoader(): Promise<void> {
    await this.loading?.dismiss();
  }
}
