/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  AragInsuranceDto,
  BrokerDto,
  Candidature,
  CotenantsArag,
  InsuranceAsset,
  MyCustomEvent,
  NotificationTypeEnum,
  PricingFiatcDto,
  TenantCandidature,
  TypeDocumentAragEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import {
  UtilsService,
  calculatedAdultDate
} from '@core/services/utils/utils.service';
import {
  IonRadioGroup,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { addMonths } from 'date-fns';
import { CountryCode } from 'libphonenumber-js/types.d';

@Component({
  selector: 'el-buen-inquilino-contract-rc-insurance',
  templateUrl: './contract-rc-insurance.component.html'
})
export class ContractRcInsuranceComponent implements OnInit {
  @ViewChild('loadingIcon', { read: ElementRef }) loadingIcon: ElementRef;
  @ViewChild('priceBox', { read: ElementRef }) priceBox: ElementRef;
  @ViewChild('radioGroup') radioGroup: IonRadioGroup;

  @Input() candidatureId: string;
  @Input() homeownerId: string;
  @Input() totalAnnualReceipt: number;

  candidature: Candidature;

  showForm = false;
  aragForm: UntypedFormGroup;
  insuranceAssetForm: UntypedFormGroup;
  aragInsurance: AragInsuranceDto = new AragInsuranceDto();
  insuranceAsset: InsuranceAsset = new InsuranceAsset();

  user: User;
  homeowner: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cotenants: any[];
  max = calculatedAdultDate();
  isDisabledPolicyButton = false;
  showInsuredForm = false;
  typeDocumentEnum = TypeDocumentAragEnum;
  selectedDocumentType: string = this.typeDocumentEnum.DNI as string;

  validationErrorMessages = appValidators.validationErrorMessages;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadingAnimation: any;
  textoBotonRecalcular: string;

  //Input para el teléfono
  country: CountryCode = 'ES';
  showOkImage = false;
  save = false;
  today = new Date();
  maxInceptionDate: Date;

  // Si es FIATC
  pricingFIATC: PricingFiatcDto;
  showTotalAnnualReceipt = false;

  get phoneControl(): FormControl<string> {
    return this.aragForm.controls.phone as FormControl<string>;
  }

  get numberControl(): FormControl<string> {
    return this.aragForm.controls.number as FormControl<string>;
  }

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private formBuilder: UntypedFormBuilder,
    private candidatureService: CandidaturesApiService,
    private userService: UsersApiService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private authenticationService: AuthService,
    private notificationsService: NotificationsApiService,
    private utilsService: UtilsService,
    private phoneMaskService: PhoneMaskService
  ) {
    this.aragInsurance.insuranceAsset = this.insuranceAsset;
    this.user = this.authenticationService.user;
  }

  ngOnInit(): void {
    this.maxInceptionDate = addMonths(new Date(), 2);
    this.getCandidature();
    this.setTextoBotonRecalcular();
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  setTextoBotonRecalcular(): void {
    this.textoBotonRecalcular = this.translateService.instant(
      'components.add-arag-insurance.recalculate'
    ) as string;
  }

  getCandidature(): void {
    this.candidatureService
      .getCandidature(this.candidatureId)
      .subscribe((candidature: Candidature) => {
        this.candidature = candidature;
        this.getHomeOwnerUser();
        this.prepareCotenantsFromCandidature();
      });
  }

  getHomeOwnerUser(): void {
    this.userService.getUser(this.homeownerId).then((user: User) => {
      this.homeowner = user;
      // Obtener broker con el homeownerId por si es necesario tarificar
      // Esto ocurre si el homeowner tiene acuerdo con FiatC
      this.candidatureService
        .getBrokerCodeByUserId(user.id)
        .subscribe((broker: BrokerDto) => {
          if (
            !!broker.tenantInsuranceCode &&
            broker.tenantInsuranceCode === 'FIATC'
          ) {
            this.candidatureService
              .getPricingFiatC(this.candidatureId)
              .subscribe({
                next: (pricing: PricingFiatcDto) => {
                  this.totalAnnualReceipt = pricing.price;
                  this.pricingFIATC = pricing;
                  this.showTotalAnnualReceipt = true;
                },
                error: () => (this.showTotalAnnualReceipt = true)
              });
          } else {
            this.totalAnnualReceipt = 140;
            this.showTotalAnnualReceipt = true;
          }
        });
      this.loadForm();
    });
  }

  loadForm(): void {
    this.aragForm = this.formBuilder.group({
      score: [this.candidature.score],
      assetRent: [
        this.candidature.asset.rentalPrice,
        [Validators.required, Validators.max(5000000)]
      ],
      document:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [
              this.user.dni,
              [Validators.required, appValidators.dniAndNieAndPassportValidator]
            ]
          : [null, [Validators.required, appValidators.cifValidator]],
      firstName:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [
              this.user.firstname,
              [Validators.required, appValidators.namesValidator]
            ]
          : [null],
      surname: [
        this.user.surname1,
        [Validators.required, appValidators.complexNameValidator]
      ],
      surname2:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [this.user.surname2, [appValidators.namesValidator]]
          : [null],
      phone: [
        !!this.user.phone
          ? this.phoneMaskService.formatPhone(this.user.phone, this.country)
          : null,
        Validators.compose(this.getPhoneControlValidators())
      ],
      postalCode: [
        this.user.postalCode,
        [Validators.required, appValidators.postalCodeValidator]
      ],
      ccc: [null, [Validators.required, appValidators.bankAccountValidator]],
      birthdatePolicyHolder:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [this.user.birthDate, [Validators.required]]
          : [this.user.birthDate],
      emailPolicyHolder: [
        this.user.email,
        [Validators.required, appValidators.emailValidator]
      ],
      documentType: [this.selectedDocumentType, [Validators.required]],
      street: [
        this.candidature.asset.street,
        [Validators.required, appValidators.maxLengthValidator(240)]
      ],
      number: [
        this.candidature.asset.number,
        [
          Validators.required,
          appValidators.onlyNumericValidator,
          Validators.max(2000)
        ]
      ],
      portal: [
        this.candidature.asset.portal,
        appValidators.onlylettersAndNumericValidator
      ],
      floor: [
        this.candidature.asset.floor,
        appValidators.onlylettersAndNumericValidator
      ],
      door: [
        this.candidature.asset.door,
        appValidators.onlylettersAndNumericValidator
      ],
      town: [
        this.candidature.asset.town,
        [
          Validators.required,
          appValidators.maxLengthValidator(50),
          appValidators.namesValidator
        ]
      ],
      province: [
        this.candidature.asset.province,
        [
          Validators.required,
          appValidators.maxLengthValidator(50),
          appValidators.namesValidator
        ]
      ],
      postalCodeInsured: [
        this.candidature.asset.postalCode,
        [Validators.required, appValidators.postalCodeValidator]
      ],
      documentInsured: !this.showInsuredForm
        ? [this.user.dni]
        : [
            this.user.dni,
            [Validators.required, appValidators.dniAndNieValidator]
          ],
      nameInsured: [
        this.user.firstname,
        [Validators.required, appValidators.namesValidator]
      ],
      surnameInsured: [
        this.user.surname1,
        [Validators.required, appValidators.namesValidator]
      ],
      surname2Insured: [this.user.surname2, [appValidators.namesValidator]],
      inceptionDate: [new Date(), [Validators.required]]
    });
    this.showForm = true;
  }

  getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  /**
   * Se hace trim al valor del input
   * @param input Form control del fomulario
   */
  deleteSpacesFormControl(input: string): void {
    appValidators.deleteSpacesFormControl(this.aragForm, input);
  }

  /**
   * Se intruduce un espacio cada 4 numeros al valor del input ccc para
   * facilitar lectura
   * @param event valor para ccc del fomulario
   */
  cccMaskChanges(event: Event): void {
    // const value = (event.target as HTMLInputElement).value
    //   .replace(/[^\dA-Z]/g, '')
    //   .replace(/(.{4})/g, '$1 ')
    //   .trim();
    // this.aragForm.get('ccc').setValue(value);
    this.utilsService.cccMaskChanges(event, this.aragForm, 'ccc');
  }

  calculateAddress(): string {
    const emptySpace = ' ';
    return (
      (this.candidature.asset.street
        ? this.candidature.asset.street + emptySpace
        : '') +
      (this.candidature.asset.number
        ? this.candidature.asset.number + emptySpace
        : '') +
      (this.candidature.asset.portal
        ? this.candidature.asset.portal + emptySpace
        : '') +
      (this.candidature.asset.floor
        ? this.candidature.asset.floor + emptySpace
        : '') +
      (this.candidature.asset.door
        ? this.candidature.asset.door + emptySpace
        : '') +
      (this.candidature.asset.town
        ? '- ' + this.candidature.asset.town + emptySpace
        : '') +
      (this.candidature.asset.province
        ? '(' + this.candidature.asset.province + ')'
        : '')
    );
  }

  convertDateToArag(date: Date): string {
    if (!date) return null;
    const x = new Date(date);
    const y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    d.length === 1 && (d = '0' + d);
    m.length === 1 && (m = '0' + m);
    const yyyymmdd = y + m + d;
    return yyyymmdd;
  }

  createArag(): void {
    this.isDisabledPolicyButton = true;
    const emptyValueForArag = '';

    this.aragInsurance.score = this.aragForm.controls.score.value;
    this.aragInsurance.assetRent = this.aragForm.controls.assetRent.value;
    this.aragInsurance.document = this.aragForm.controls.document.value;
    this.aragInsurance.firstName = this.aragForm.controls.firstName.value;
    this.aragInsurance.surname = this.aragForm.controls.surname.value;
    this.aragInsurance.surname2 = this.aragForm.controls.surname2.value;
    if (this.aragForm.controls.phone.value) {
      this.aragInsurance.phone = this.formatPhone(
        this.aragForm.controls.phone.value
      );
    }
    this.aragInsurance.address = this.calculateAddress();
    this.aragInsurance.postalCode =
      this.aragForm.controls.postalCodeInsured.value;
    this.aragInsurance.city = this.aragForm.controls.town.value;
    this.aragInsurance.province = this.aragForm.controls.province.value;

    //eliminamos espacios introducidos por la mascara para ccc
    this.aragInsurance.ccc = this.aragForm.controls.ccc.value.replace(
      /\s/g,
      ''
    );
    this.aragInsurance.birthdatePolicyHolder = this.convertDateToArag(
      this.aragForm.controls.birthdatePolicyHolder.value as Date
    );
    this.aragInsurance.mobilePhonePolicyHolder =
      this.aragForm.controls.phone.value;
    this.aragInsurance.emailPolicyHolder =
      this.aragForm.controls.emailPolicyHolder.value;
    this.aragInsurance.documentType = this.aragForm.controls.documentType.value;

    this.aragInsurance.insuranceAsset.cotenants = this.cotenants;

    this.aragInsurance.insuranceAsset.address = this.calculateAddress();
    this.aragInsurance.insuranceAsset.postalCode =
      this.aragForm.get('postalCodeInsured').value;
    this.aragInsurance.insuranceAsset.city = this.aragForm.get('town').value;
    this.aragInsurance.insuranceAsset.province =
      this.aragForm.get('province').value;

    this.aragInsurance.insuranceAsset.street =
      this.aragForm.get('street').value;
    this.aragInsurance.insuranceAsset.number =
      this.aragForm.get('number').value;
    this.aragInsurance.insuranceAsset.portal =
      this.aragForm.get('portal').value;
    this.aragInsurance.insuranceAsset.floor = this.aragForm.get('floor').value;
    this.aragInsurance.insuranceAsset.door = this.aragForm.get('door').value;

    this.aragInsurance.insuranceAsset.document = !this.showInsuredForm
      ? this.aragForm.controls.document.value
      : this.aragForm.controls.documentInsured.value;
    // Flujo (Tomador diferente del asegurado deshabilitado, se mandara
    // name, surname, surname2 cif, del propietario como tomador por defecto)
    // pero si se elige cif solo se enviara cif y surname en el el
    // form deshabilitado de abajo (asegurado)
    this.aragInsurance.insuranceAsset.name =
      this.selectedDocumentType === (this.typeDocumentEnum.CIF as string)
        ? emptyValueForArag
        : !this.showInsuredForm
          ? this.aragForm.controls.firstName.value
          : this.aragForm.controls.nameInsured.value;
    this.aragInsurance.insuranceAsset.surname = !this.showInsuredForm
      ? this.aragForm.controls.surname.value
      : this.aragForm.controls.surnameInsured.value;
    this.aragInsurance.insuranceAsset.surname2 =
      this.selectedDocumentType === (this.typeDocumentEnum.CIF as string)
        ? emptyValueForArag
        : !this.showInsuredForm
          ? this.aragForm.controls.surname2.value
          : this.aragForm.controls.surname2Insured.value;
    this.aragInsurance.insuranceAsset.insuranceTypeDocument =
      this.selectedDocumentType === (this.typeDocumentEnum.CIF as string)
        ? (this.typeDocumentEnum.CIF as string)
        : (this.typeDocumentEnum.DNI as string);
    // Fecha de inicio de la póliza
    this.aragInsurance.inceptionDate = this.formatDate(
      this.aragForm.controls.inceptionDate.value as Date
    );

    // Si es un cif, ponemos nombre y segundo apellidos a null
    if (this.aragInsurance.documentType === '1') {
      this.aragInsurance.firstName = null;
      this.aragInsurance.surname2 = null;
    }

    this.aragInsurance.candidatureStatus =
      this.candidature.candidatureStatusEnum;
    // Se añade también, si las tuviese, las coordenadas del asset
    this.aragInsurance.assetLocation = this.candidature.asset.location;
    // Se añaden campos para gestión de edificios
    this.aragInsurance.assetId = this.candidature.asset.id;
    this.aragInsurance.buildingId = this.candidature.asset.buildingId;
    // Se añaden los datos del homeowner
    this.aragInsurance.userId = this.homeownerId;
    if (this.homeowner.portfolioId) {
      this.aragInsurance.portfolioId = this.homeowner.portfolioId;
      this.aragInsurance.portfolioOwnerId = this.homeowner.id;
    }
    if (!!this.homeowner.apiRol) {
      this.aragInsurance.apiId = this.homeowner.apiId;
      this.aragInsurance.portfolioId = this.candidature.asset.portfolioId;
    }

    //Se guarda en el objeto de póliza la comisión del usuario
    // si tuviera el monedero activo
    // TODO - hay que poner la comisión de este tipo de seguro
    if (this.homeowner.hasFeePocket) {
      this.aragInsurance.commissionFee = this.homeowner.commissionFeeTenantRC;
    }

    //Se guarda el array de cotenants si los tuviera
    // en el atributo insuredTenants
    this.aragInsurance.insuredTenants = [this.user.id];
    if (this.user?.coTenants) {
      this.user.coTenants.forEach((cotenantId: string) =>
        this.aragInsurance.insuredTenants.push(cotenantId)
      );
    }

    // FIATC - Si se trata de un seguro para FIATC, enviamos esto
    if (!!this.pricingFIATC) {
      this.aragInsurance.idPoblacion = this.pricingFIATC.idPoblacion;
      this.aragInsurance.offerId = this.pricingFIATC.offerId;
      this.aragInsurance.ref = this.pricingFIATC.ref;
    }

    this.loadingService.presentSecondLoader(
      this.translateService.instant(
        'components.add-arag-insurance.creating_policy'
      ) as string
    );

    this.candidatureService
      .createTenantInsurance(this.candidature.id, this.aragInsurance)
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (response: any) => {
          this.showForm = false;
          this.showOkImage = true;
          this.save = true;
          this.isDisabledPolicyButton = false;
          this.loadingService.dismissSecondLoader();
          this.deleteNotifications();
          this.candidatureService
            .generateTenantPolicyPDF(response.policyNumber as string)
            .subscribe();
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          this.loadingService.dismissSecondLoader();
          this.isDisabledPolicyButton = false;
          if (error?.error?.code === '104') {
            this.presentToast(
              this.translateService.instant(
                'components.add-arag-insurance.dni_does_not_correspond'
              ) as string,
              false
            );
          } else {
            this.presentToast(
              this.translateService.instant(
                'components.add-arag-insurance.creating_policy_fail'
              ) as string,
              false
            );
          }
        }
      );
  }

  checkCreateArag(): boolean {
    const validForm: boolean = this.aragForm?.valid;
    return validForm;
  }

  prepareCotenantsFromCandidature(): void {
    this.cotenants = this.candidature.tenantCandidatureList.map(
      (item: TenantCandidature) => {
        const userCotenantArag: CotenantsArag = {
          type: this.calculateCotenantsTypeArag(item.user.guarantor),
          document: item.user.dni,
          name: item.user.firstname,
          surname: item.user.surname1,
          surname2: item.user.surname2,
          phone: item.user.phone,
          freelance: item.user.profData ? item.user.profData.freelance : false,
          guarantor: item.user.guarantor
        };
        return userCotenantArag;
      }
    );
  }

  calculateCotenantsTypeArag(guarantor: boolean): string {
    return guarantor
      ? (this.translateService.instant(
          'components.add-arag-insurance.guarantor'
        ) as string)
      : (this.translateService.instant(
          'components.add-arag-insurance.tenant'
        ) as string);
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
      saving: this.save
    });
  }

  async presentToast(messages: string, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'success' : 'danger',
      duration: 2000
    });
    toast.present();
  }

  radioGroupChange(event: Event): void {
    this.selectedDocumentType = (event as CustomEvent<MyCustomEvent>).detail
      .value as string;
    //this.aragForm.reset();
    this.loadForm();
  }

  switchHolderAndOwnerForm(): void {
    this.showInsuredForm = !this.showInsuredForm;
    //this.aragForm.reset();
    this.loadForm();
  }

  onChangeDocumentType(event: Event): void {
    const documentType = (event as CustomEvent<MyCustomEvent>).detail
      .value as string;
    this.selectedDocumentType = documentType;
    this.aragForm.get('documentType').setValue(documentType);

    if (documentType === (this.typeDocumentEnum.DNI as string)) {
      this.aragForm.controls['document'].setValidators([
        Validators.required,
        appValidators.dniAndNieValidator
      ]);
      this.aragForm.controls['document'].setValue(this.user.dni);
      this.aragForm.controls['document'].markAsUntouched();
      this.aragForm.controls['document'].markAsPristine();

      this.aragForm.controls['birthdatePolicyHolder'].setValue(null);
      this.aragForm.controls['birthdatePolicyHolder'].setValidators([
        Validators.required
      ]);
      this.aragForm.controls['birthdatePolicyHolder'].setErrors(null);
      this.aragForm.controls['birthdatePolicyHolder'].markAsUntouched();
      this.aragForm.controls['birthdatePolicyHolder'].markAsPristine();

      this.aragForm.controls['firstName'].setValue(this.user.firstname);
      this.aragForm.controls['firstName'].setValidators([
        Validators.required,
        appValidators.namesValidator
      ]);
      this.aragForm.controls['firstName'].setErrors(null);
      this.aragForm.controls['firstName'].markAsUntouched();
      this.aragForm.controls['firstName'].markAsPristine();

      this.aragForm.controls['surname'].setValue(this.user.surname1);
      this.aragForm.controls['surname'].markAsUntouched();
      this.aragForm.controls['surname'].markAsPristine();

      this.aragForm.controls['surname2'].setValue(this.user.surname2);
      this.aragForm.controls['surname2'].setValidators([
        Validators.required,
        appValidators.namesValidator
      ]);
      this.aragForm.controls['surname2'].setErrors(null);
      this.aragForm.controls['surname2'].markAsUntouched();
      this.aragForm.controls['surname2'].markAsPristine();
    } else {
      this.aragForm.controls['document'].setValidators([
        Validators.required,
        appValidators.cifValidator
      ]);
      this.aragForm.controls['document'].setValue(null);
      this.aragForm.controls['document'].markAsUntouched();
      this.aragForm.controls['document'].markAsPristine();

      this.aragForm.controls['birthdatePolicyHolder'].clearValidators();
      this.aragForm.controls['birthdatePolicyHolder'].setErrors(null);
      this.aragForm.controls['birthdatePolicyHolder'].markAsUntouched();
      this.aragForm.controls['birthdatePolicyHolder'].markAsPristine();

      this.aragForm.controls['firstName'].clearValidators();
      this.aragForm.controls['firstName'].setErrors(null);
      this.aragForm.controls['firstName'].markAsUntouched();
      this.aragForm.controls['firstName'].markAsPristine();

      this.aragForm.controls['surname'].setValue(null);
      this.aragForm.controls['surname'].markAsUntouched();
      this.aragForm.controls['surname'].markAsPristine();

      this.aragForm.controls['surname2'].clearValidators();
      this.aragForm.controls['surname2'].setErrors(null);
      this.aragForm.controls['surname2'].markAsUntouched();
      this.aragForm.controls['surname2'].markAsPristine();
    }
  }

  showIPID(): void {
    this.seePDF('../../../assets/pdfs/arag/IPID.pdf');
  }

  showGeneralConditions(): void {
    this.seePDF('../../../assets/pdfs/arag/Condiciones_Generales.pdf');
  }

  seePDF(url: string): void {
    window.open(url, '_blank');
  }

  private formatDate(date: Date): string {
    const dateFormat = new Date(date);
    const day = dateFormat.getDate();
    const month = dateFormat.getMonth() + 1;
    const year = dateFormat.getFullYear();
    let dayString = day.toString();
    let monthString = month.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }
    if (month < 10) {
      monthString = '0' + monthString;
    }
    return dayString + '/' + monthString + '/' + year;
  }

  private deleteNotifications(): void {
    //Borramos la notificación para este usuario que contrata
    this.notificationsService
      .deleteNotification(
        NotificationTypeEnum.INSURANCE_RC,
        this.candidature.id
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatPhone(phone: any): string {
    return this.utilsService.formatPhone(phone);
  }
}
