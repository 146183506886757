import { Component, Input } from '@angular/core';
import { Candidature } from '@app/modules/core/models';

@Component({
  selector: 'el-buen-inquilino-transactions-candidature-information',
  templateUrl: './transactions-candidature-information.component.html'
})
export class TransactionsCandidatureInformationComponent {
  @Input() candidature: Candidature;

  constructor() {}
}
