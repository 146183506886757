import { Country } from '../models/country.model';

export const countries: Country[] = [
  { name: 'Afghanistan', countryIsoCode: 'af', phoneCode: '+93' },
  { name: 'Åland Islands', countryIsoCode: 'ax', phoneCode: '+358' },
  { name: 'Albania', countryIsoCode: 'al', phoneCode: '+355' },
  { name: 'Algeria', countryIsoCode: 'dz', phoneCode: '+213' },
  { name: 'American Samoa', countryIsoCode: 'as', phoneCode: '+1-684' },
  { name: 'Andorra', countryIsoCode: 'ad', phoneCode: '+376' },
  { name: 'Angola', countryIsoCode: 'ao', phoneCode: '+244' },
  { name: 'Anguilla', countryIsoCode: 'ai', phoneCode: '+1-264' },
  { name: 'Antigua and Barbuda', countryIsoCode: 'ag', phoneCode: '+1-268' },
  { name: 'Argentina', countryIsoCode: 'ar', phoneCode: '+54' },
  { name: 'Armenia', countryIsoCode: 'am', phoneCode: '+374' },
  { name: 'Aruba', countryIsoCode: 'aw', phoneCode: '+297' },
  { name: 'Australia', countryIsoCode: 'au', phoneCode: '+61' },
  { name: 'Austria', countryIsoCode: 'at', phoneCode: '+43' },
  { name: 'Azerbaijan', countryIsoCode: 'az', phoneCode: '+994' },
  { name: 'Bahamas', countryIsoCode: 'bs', phoneCode: '+1-242' },
  { name: 'Bahrain', countryIsoCode: 'bh', phoneCode: '+973' },
  { name: 'Bangladesh', countryIsoCode: 'bd', phoneCode: '+880' },
  { name: 'Barbados', countryIsoCode: 'bb', phoneCode: '+1-246' },
  { name: 'Belarus', countryIsoCode: 'by', phoneCode: '+375' },
  { name: 'Belgium', countryIsoCode: 'be', phoneCode: '+32' },
  { name: 'Belize', countryIsoCode: 'bz', phoneCode: '+501' },
  { name: 'Benin', countryIsoCode: 'bj', phoneCode: '+229' },
  { name: 'Bermuda', countryIsoCode: 'bm', phoneCode: '+1-441' },
  { name: 'Bhutan', countryIsoCode: 'bt', phoneCode: '+975' },
  { name: 'Bolivia', countryIsoCode: 'bo', phoneCode: '+591' },
  { name: 'Bosnia and Herzegovina', countryIsoCode: 'ba', phoneCode: '+387' },
  { name: 'Botswana', countryIsoCode: 'bw', phoneCode: '+267' },
  { name: 'Brazil', countryIsoCode: 'br', phoneCode: '+55' },
  {
    name: 'British Indian Ocean Territory',
    countryIsoCode: 'io',
    phoneCode: '+246'
  },
  { name: 'Brunei Darussalam', countryIsoCode: 'bn', phoneCode: '+673' },
  { name: 'Bulgaria', countryIsoCode: 'bg', phoneCode: '+359' },
  { name: 'Burkina Faso', countryIsoCode: 'bf', phoneCode: '+226' },
  { name: 'Burundi', countryIsoCode: 'bi', phoneCode: '+257' },
  { name: 'Cambodia', countryIsoCode: 'kh', phoneCode: '+855' },
  { name: 'Cameroon', countryIsoCode: 'cm', phoneCode: '+237' },
  { name: 'Canada', countryIsoCode: 'ca', phoneCode: '+1' },
  { name: 'Cape Verde', countryIsoCode: 'cv', phoneCode: '+238' },
  { name: 'Cayman Islands', countryIsoCode: 'ky', phoneCode: '+1-345' },
  { name: 'Central African Republic', countryIsoCode: 'cf', phoneCode: '+236' },
  { name: 'Chad', countryIsoCode: 'td', phoneCode: '+235' },
  { name: 'Chile', countryIsoCode: 'cl', phoneCode: '+56' },
  { name: 'China', countryIsoCode: 'cn', phoneCode: '+86' },
  { name: 'Christmas Island', countryIsoCode: 'cx', phoneCode: '+61' },
  { name: 'Cocos (Keeling) Islands', countryIsoCode: 'cc', phoneCode: '+61' },
  { name: 'Colombia', countryIsoCode: 'co', phoneCode: '+57' },
  { name: 'Comoros', countryIsoCode: 'km', phoneCode: '+269' },
  { name: 'Congo', countryIsoCode: 'cg', phoneCode: '+242' },
  {
    name: 'Democratic Republic of the Congo',
    countryIsoCode: 'cd',
    phoneCode: '+243'
  },
  { name: 'Cook Islands', countryIsoCode: 'ck', phoneCode: '+682' },
  { name: 'Costa Rica', countryIsoCode: 'cr', phoneCode: '+506' },
  { name: 'Croatia', countryIsoCode: 'hr', phoneCode: '+385' },
  { name: 'Cuba', countryIsoCode: 'cu', phoneCode: '+53' },
  { name: 'Curaçao', countryIsoCode: 'cw', phoneCode: '+599' },
  { name: 'Cyprus', countryIsoCode: 'cy', phoneCode: '+357' },
  { name: 'Czech Republic', countryIsoCode: 'cz', phoneCode: '+420' },
  { name: 'Denmark', countryIsoCode: 'dk', phoneCode: '+45' },
  { name: 'Djibouti', countryIsoCode: 'dj', phoneCode: '+253' },
  { name: 'Dominica', countryIsoCode: 'dm', phoneCode: '+1-767' },
  { name: 'Dominican Republic', countryIsoCode: 'do', phoneCode: '+1-809' },
  { name: 'Ecuador', countryIsoCode: 'ec', phoneCode: '+593' },
  { name: 'Egypt', countryIsoCode: 'eg', phoneCode: '+20' },
  { name: 'El Salvador', countryIsoCode: 'sv', phoneCode: '+503' },
  { name: 'Equatorial Guinea', countryIsoCode: 'gq', phoneCode: '+240' },
  { name: 'Eritrea', countryIsoCode: 'er', phoneCode: '+291' },
  { name: 'Estonia', countryIsoCode: 'ee', phoneCode: '+372' },
  { name: 'Ethiopia', countryIsoCode: 'et', phoneCode: '+251' },
  {
    name: 'Falkland Islands (Malvinas)',
    countryIsoCode: 'fk',
    phoneCode: '+500'
  },
  { name: 'Faroe Islands', countryIsoCode: 'fo', phoneCode: '+298' },
  { name: 'Fiji', countryIsoCode: 'fj', phoneCode: '+679' },
  { name: 'Finland', countryIsoCode: 'fi', phoneCode: '+358' },
  { name: 'France', countryIsoCode: 'fr', phoneCode: '+33' },
  { name: 'French Guiana', countryIsoCode: 'gf', phoneCode: '+594' },
  { name: 'French Polynesia', countryIsoCode: 'pf', phoneCode: '+689' },
  { name: 'Gabon', countryIsoCode: 'ga', phoneCode: '+241' },
  { name: 'Gambia', countryIsoCode: 'gm', phoneCode: '+220' },
  { name: 'Georgia', countryIsoCode: 'ge', phoneCode: '+995' },
  { name: 'Germany', countryIsoCode: 'de', phoneCode: '+49' },
  { name: 'Ghana', countryIsoCode: 'gh', phoneCode: '+233' },
  { name: 'Gibraltar', countryIsoCode: 'gi', phoneCode: '+350' },
  { name: 'Greece', countryIsoCode: 'gr', phoneCode: '+30' },
  { name: 'Greenland', countryIsoCode: 'gl', phoneCode: '+299' },
  { name: 'Grenada', countryIsoCode: 'gd', phoneCode: '+1-473' },
  { name: 'Guadeloupe', countryIsoCode: 'gp', phoneCode: '+590' },
  { name: 'Guam', countryIsoCode: 'gu', phoneCode: '+1-671' },
  { name: 'Guatemala', countryIsoCode: 'gt', phoneCode: '+502' },
  { name: 'Guernsey', countryIsoCode: 'gg', phoneCode: '+44' },
  { name: 'Guinea', countryIsoCode: 'gn', phoneCode: '+224' },
  { name: 'Guinea-Bissau', countryIsoCode: 'gw', phoneCode: '+245' },
  { name: 'Guyana', countryIsoCode: 'gy', phoneCode: '+592' },
  { name: 'Haiti', countryIsoCode: 'ht', phoneCode: '+509' },
  { name: 'Vatican City', countryIsoCode: 'va', phoneCode: '+379' },
  { name: 'Honduras', countryIsoCode: 'hn', phoneCode: '+504' },
  { name: 'Hong Kong', countryIsoCode: 'hk', phoneCode: '+852' },
  { name: 'Hungary', countryIsoCode: 'hu', phoneCode: '+36' },
  { name: 'Iceland', countryIsoCode: 'is', phoneCode: '+354' },
  { name: 'India', countryIsoCode: 'in', phoneCode: '+91' },
  { name: 'Indonesia', countryIsoCode: 'id', phoneCode: '+62' },
  { name: 'Iran', countryIsoCode: 'ir', phoneCode: '+98' },
  { name: 'Iraq', countryIsoCode: 'iq', phoneCode: '+964' },
  { name: 'Ireland', countryIsoCode: 'ie', phoneCode: '+353' },
  { name: 'Isle of Man', countryIsoCode: 'im', phoneCode: '+44' },
  { name: 'Israel', countryIsoCode: 'il', phoneCode: '+972' },
  { name: 'Italy', countryIsoCode: 'it', phoneCode: '+39' },
  { name: 'Ivory Coast', countryIsoCode: 'ci', phoneCode: '+225' },
  { name: 'Jamaica', countryIsoCode: 'jm', phoneCode: '+1-876' },
  { name: 'Japan', countryIsoCode: 'jp', phoneCode: '+81' },
  { name: 'Jersey', countryIsoCode: 'je', phoneCode: '+44' },
  { name: 'Jordan', countryIsoCode: 'jo', phoneCode: '+962' },
  { name: 'Kazakhstan', countryIsoCode: 'kz', phoneCode: '+7' },
  { name: 'Kenya', countryIsoCode: 'ke', phoneCode: '+254' },
  { name: 'Kiribati', countryIsoCode: 'ki', phoneCode: '+686' },
  { name: 'North Korea', countryIsoCode: 'kp', phoneCode: '+850' },
  { name: 'South Korea', countryIsoCode: 'kr', phoneCode: '+82' },
  { name: 'Kuwait', countryIsoCode: 'kw', phoneCode: '+965' },
  { name: 'Kyrgyzstan', countryIsoCode: 'kg', phoneCode: '+996' },
  { name: 'Laos', countryIsoCode: 'la', phoneCode: '+856' },
  { name: 'Latvia', countryIsoCode: 'lv', phoneCode: '+371' },
  { name: 'Lebanon', countryIsoCode: 'lb', phoneCode: '+961' },
  { name: 'Lesotho', countryIsoCode: 'ls', phoneCode: '+266' },
  { name: 'Liberia', countryIsoCode: 'lr', phoneCode: '+231' },
  { name: 'Libya', countryIsoCode: 'ly', phoneCode: '+218' },
  { name: 'Liechtenstein', countryIsoCode: 'li', phoneCode: '+423' },
  { name: 'Lithuania', countryIsoCode: 'lt', phoneCode: '+370' },
  { name: 'Luxembourg', countryIsoCode: 'lu', phoneCode: '+352' },
  { name: 'Macau', countryIsoCode: 'mo', phoneCode: '+853' },
  { name: 'North Macedonia', countryIsoCode: 'mk', phoneCode: '+389' },
  { name: 'Madagascar', countryIsoCode: 'mg', phoneCode: '+261' },
  { name: 'Malawi', countryIsoCode: 'mw', phoneCode: '+265' },
  { name: 'Malaysia', countryIsoCode: 'my', phoneCode: '+60' },
  { name: 'Maldives', countryIsoCode: 'mv', phoneCode: '+960' },
  { name: 'Mali', countryIsoCode: 'ml', phoneCode: '+223' },
  { name: 'Malta', countryIsoCode: 'mt', phoneCode: '+356' },
  { name: 'Marshall Islands', countryIsoCode: 'mh', phoneCode: '+692' },
  { name: 'Martinique', countryIsoCode: 'mq', phoneCode: '+596' },
  { name: 'Mauritania', countryIsoCode: 'mr', phoneCode: '+222' },
  { name: 'Mauritius', countryIsoCode: 'mu', phoneCode: '+230' },
  { name: 'Mayotte', countryIsoCode: 'yt', phoneCode: '+262' },
  { name: 'Mexico', countryIsoCode: 'mx', phoneCode: '+52' },
  { name: 'Micronesia', countryIsoCode: 'fm', phoneCode: '+691' },
  { name: 'Moldova', countryIsoCode: 'md', phoneCode: '+373' },
  { name: 'Monaco', countryIsoCode: 'mc', phoneCode: '+377' },
  { name: 'Mongolia', countryIsoCode: 'mn', phoneCode: '+976' },
  { name: 'Montenegro', countryIsoCode: 'me', phoneCode: '+382' },
  { name: 'Montserrat', countryIsoCode: 'ms', phoneCode: '+1-664' },
  { name: 'Morocco', countryIsoCode: 'ma', phoneCode: '+212' },
  { name: 'Mozambique', countryIsoCode: 'mz', phoneCode: '+258' },
  { name: 'Myanmar', countryIsoCode: 'mm', phoneCode: '+95' },
  { name: 'Namibia', countryIsoCode: 'na', phoneCode: '+264' },
  { name: 'Nauru', countryIsoCode: 'nr', phoneCode: '+674' },
  { name: 'Nepal', countryIsoCode: 'np', phoneCode: '+977' },
  { name: 'Netherlands', countryIsoCode: 'nl', phoneCode: '+31' },
  { name: 'New Caledonia', countryIsoCode: 'nc', phoneCode: '+687' },
  { name: 'New Zealand', countryIsoCode: 'nz', phoneCode: '+64' },
  { name: 'Nicaragua', countryIsoCode: 'ni', phoneCode: '+505' },
  { name: 'Niger', countryIsoCode: 'ne', phoneCode: '+227' },
  { name: 'Nigeria', countryIsoCode: 'ng', phoneCode: '+234' },
  { name: 'Niue', countryIsoCode: 'nu', phoneCode: '+683' },
  { name: 'Norfolk Island', countryIsoCode: 'nf', phoneCode: '+672' },
  {
    name: 'Northern Mariana Islands',
    countryIsoCode: 'mp',
    phoneCode: '+1-670'
  },
  { name: 'Norway', countryIsoCode: 'no', phoneCode: '+47' },
  { name: 'Oman', countryIsoCode: 'om', phoneCode: '+968' },
  { name: 'Pakistan', countryIsoCode: 'pk', phoneCode: '+92' },
  { name: 'Palau', countryIsoCode: 'pw', phoneCode: '+680' },
  { name: 'Palestine', countryIsoCode: 'ps', phoneCode: '+970' },
  { name: 'Panama', countryIsoCode: 'pa', phoneCode: '+507' },
  { name: 'Papua New Guinea', countryIsoCode: 'pg', phoneCode: '+675' },
  { name: 'Paraguay', countryIsoCode: 'py', phoneCode: '+595' },
  { name: 'Peru', countryIsoCode: 'pe', phoneCode: '+51' },
  { name: 'Philippines', countryIsoCode: 'ph', phoneCode: '+63' },
  { name: 'Pitcairn', countryIsoCode: 'pn', phoneCode: '+870' },
  { name: 'Poland', countryIsoCode: 'pl', phoneCode: '+48' },
  { name: 'Portugal', countryIsoCode: 'pt', phoneCode: '+351' },
  { name: 'Puerto Rico', countryIsoCode: 'pr', phoneCode: '+1-787' },
  { name: 'Qatar', countryIsoCode: 'qa', phoneCode: '+974' },
  { name: 'Réunion', countryIsoCode: 're', phoneCode: '+262' },
  { name: 'Romania', countryIsoCode: 'ro', phoneCode: '+40' },
  { name: 'Russia', countryIsoCode: 'ru', phoneCode: '+7' },
  { name: 'Rwanda', countryIsoCode: 'rw', phoneCode: '+250' },
  { name: 'Saint Barthélemy', countryIsoCode: 'bl', phoneCode: '+590' },
  { name: 'Saint Helena', countryIsoCode: 'sh', phoneCode: '+290' },
  { name: 'Saint Kitts and Nevis', countryIsoCode: 'kn', phoneCode: '+1-869' },
  { name: 'Saint Lucia', countryIsoCode: 'lc', phoneCode: '+1-758' },
  {
    name: 'Saint Martin (French part)',
    countryIsoCode: 'mf',
    phoneCode: '+590'
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryIsoCode: 'pm',
    phoneCode: '+508'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryIsoCode: 'vc',
    phoneCode: '+1-784'
  },
  { name: 'Samoa', countryIsoCode: 'ws', phoneCode: '+685' },
  { name: 'San Marino', countryIsoCode: 'sm', phoneCode: '+378' },
  { name: 'Sao Tome and Principe', countryIsoCode: 'st', phoneCode: '+239' },
  { name: 'Saudi Arabia', countryIsoCode: 'sa', phoneCode: '+966' },
  { name: 'Senegal', countryIsoCode: 'sn', phoneCode: '+221' },
  { name: 'Serbia', countryIsoCode: 'rs', phoneCode: '+381' },
  { name: 'Seychelles', countryIsoCode: 'sc', phoneCode: '+248' },
  { name: 'Sierra Leone', countryIsoCode: 'sl', phoneCode: '+232' },
  { name: 'Singapore', countryIsoCode: 'sg', phoneCode: '+65' },
  {
    name: 'Sint Maarten (Dutch part)',
    countryIsoCode: 'sx',
    phoneCode: '+1-721'
  },
  { name: 'Slovakia', countryIsoCode: 'sk', phoneCode: '+421' },
  { name: 'Slovenia', countryIsoCode: 'si', phoneCode: '+386' },
  { name: 'Solomon Islands', countryIsoCode: 'sb', phoneCode: '+677' },
  { name: 'Somalia', countryIsoCode: 'so', phoneCode: '+252' },
  { name: 'South Africa', countryIsoCode: 'za', phoneCode: '+27' },
  { name: 'South Sudan', countryIsoCode: 'ss', phoneCode: '+211' },
  { name: 'Spain', countryIsoCode: 'es', phoneCode: '+34' },
  { name: 'Sri Lanka', countryIsoCode: 'lk', phoneCode: '+94' },
  { name: 'Sudan', countryIsoCode: 'sd', phoneCode: '+249' },
  { name: 'Suriname', countryIsoCode: 'sr', phoneCode: '+597' },
  { name: 'Svalbard and Jan Mayen', countryIsoCode: 'sj', phoneCode: '+47' },
  { name: 'Swaziland', countryIsoCode: 'sz', phoneCode: '+268' },
  { name: 'Sweden', countryIsoCode: 'se', phoneCode: '+46' },
  { name: 'Switzerland', countryIsoCode: 'ch', phoneCode: '+41' },
  { name: 'Syria', countryIsoCode: 'sy', phoneCode: '+963' },
  { name: 'Taiwan', countryIsoCode: 'tw', phoneCode: '+886' },
  { name: 'Tajikistan', countryIsoCode: 'tj', phoneCode: '+992' },
  { name: 'Tanzania', countryIsoCode: 'tz', phoneCode: '+255' },
  { name: 'Thailand', countryIsoCode: 'th', phoneCode: '+66' },
  { name: 'Timor-Leste', countryIsoCode: 'tl', phoneCode: '+670' },
  { name: 'Togo', countryIsoCode: 'tg', phoneCode: '+228' },
  { name: 'Tokelau', countryIsoCode: 'tk', phoneCode: '+690' },
  { name: 'Tonga', countryIsoCode: 'to', phoneCode: '+676' },
  { name: 'Trinidad and Tobago', countryIsoCode: 'tt', phoneCode: '+1-868' },
  { name: 'Tunisia', countryIsoCode: 'tn', phoneCode: '+216' },
  { name: 'Turkey', countryIsoCode: 'tr', phoneCode: '+90' },
  { name: 'Turkmenistan', countryIsoCode: 'tm', phoneCode: '+993' },
  {
    name: 'Turks and Caicos Islands',
    countryIsoCode: 'tc',
    phoneCode: '+1-649'
  },
  { name: 'Tuvalu', countryIsoCode: 'tv', phoneCode: '+688' },
  { name: 'Uganda', countryIsoCode: 'ug', phoneCode: '+256' },
  { name: 'Ukraine', countryIsoCode: 'ua', phoneCode: '+380' },
  { name: 'United Arab Emirates', countryIsoCode: 'ae', phoneCode: '+971' },
  { name: 'United Kingdom', countryIsoCode: 'gb', phoneCode: '+44' },
  { name: 'United States', countryIsoCode: 'us', phoneCode: '+1' },
  { name: 'Uruguay', countryIsoCode: 'uy', phoneCode: '+598' },
  { name: 'Uzbekistan', countryIsoCode: 'uz', phoneCode: '+998' },
  { name: 'Vanuatu', countryIsoCode: 'vu', phoneCode: '+678' },
  { name: 'Venezuela', countryIsoCode: 've', phoneCode: '+58' },
  { name: 'Vietnam', countryIsoCode: 'vn', phoneCode: '+84' },
  { name: 'British Virgin Islands', countryIsoCode: 'vg', phoneCode: '+1-284' },
  { name: 'US Virgin Islands', countryIsoCode: 'vi', phoneCode: '+1-340' },
  { name: 'Wallis and Futuna', countryIsoCode: 'wf', phoneCode: '+681' },
  { name: 'Western Sahara', countryIsoCode: 'eh', phoneCode: '+212' },
  { name: 'Yemen', countryIsoCode: 'ye', phoneCode: '+967' },
  { name: 'Zambia', countryIsoCode: 'zm', phoneCode: '+260' },
  { name: 'Zimbabwe', countryIsoCode: 'zw', phoneCode: '+263' }
];
