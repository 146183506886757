/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  ApiDto,
  AragInsuranceDto,
  BuildingDto,
  CalculatedPremiumAragDto,
  Candidature,
  CleaningCoverageEnum,
  CotenantsArag,
  InsuranceAsset,
  InsuranceRetentionEnum,
  MyCustomEvent,
  PolicyHolderData,
  TenantCandidature,
  TypeDocumentAragEnum,
  User,
  VandalismCoverageEnum
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import {
  UtilsService,
  calculatedAdultDate
} from '@core/services/utils/utils.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import {
  IonRadioGroup,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { startOfDay, subDays } from 'date-fns';
import { CountryCode } from 'libphonenumber-js/types.d';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-add-arag-insurance',
  templateUrl: './add-arag-insurance.component.html'
})
export class AddAragInsuranceComponent implements OnInit, OnDestroy {
  @ViewChild('loadingIcon', { read: ElementRef }) loadingIcon: ElementRef;
  @ViewChild('priceBox', { read: ElementRef }) priceBox: ElementRef;
  @ViewChild('radioGroup') radioGroup: IonRadioGroup;
  @Input() candidature: Candidature;
  @Input() totalAnnualReceipt: number;
  @Input() initialPrice: number;
  @Input() insuranceCompanyCode = 'ARAG';
  @Input() insuranceBrokerPaid = false;

  aragForm: UntypedFormGroup;
  insuranceAssetForm: UntypedFormGroup;
  aragInsurance: AragInsuranceDto = new AragInsuranceDto();
  insuranceAsset: InsuranceAsset = new InsuranceAsset();
  user: User;
  cotenants: any[];
  min: Date;
  max = calculatedAdultDate();
  isDisabledPolicyButton = false;
  showInsuredForm = false;
  typeDocumentEnum = TypeDocumentAragEnum;
  typeVandalismCoverageEnum = VandalismCoverageEnum;
  typeInsuranceRetentionEnum = InsuranceRetentionEnum;
  typeCleaningCoverage = CleaningCoverageEnum;
  selectedDocumentType: string = this.typeDocumentEnum.DNI as string;

  validationErrorMessages = appValidators.validationErrorMessages;
  loadingAnimation: any;
  textoBotonRecalcular: string;

  // Atributos para APIS
  authUser: User;
  apiId: string;

  @Input() client: User;
  @Input() originalPolicyNumber: string;

  //Input para el teléfono
  country: CountryCode = 'ES';

  width: number;
  widthChangeSub: Subscription;

  showRecalculatePolicyBlock = true;

  get phoneControl(): FormControl<string> {
    return this.aragForm.controls.phone as FormControl<string>;
  }

  get numberControl(): FormControl {
    return this.aragForm.controls.number as FormControl;
  }

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private formBuilder: UntypedFormBuilder,
    private candidatureService: CandidaturesApiService,
    private assetsService: AssetsApiService,
    private userService: UsersApiService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private authenticationService: AuthService,
    private windowMeasuresService: WindowMeasuresService,
    private utilsService: UtilsService,
    private phoneMaskService: PhoneMaskService
  ) {
    this.aragInsurance.insuranceAsset = this.insuranceAsset;
    this.authUser = this.authenticationService.user;
  }

  ngOnInit(): void {
    this.setTextButtonText();
    if (!this.authUser && this.client) {
      this.authUser = this.client;
    }

    this.min = startOfDay(subDays(new Date(), 29));

    this.getHomeOwnerUser();
    this.prepareCotenantsFromCandidature();

    if (
      this.authUser.apiRol === 'API_ADMIN' ||
      this.authUser.apiRol === 'API_MEMBER'
    ) {
      this.getUserApi();
    }

    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => {
        this.width = width;
      });
  }
  setTextButtonText(): void {
    this.textoBotonRecalcular = this.translateService.instant(
      'components.add-arag-insurance.recalculate'
    ) as string;
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  async getUserApi(): Promise<void> {
    let userApi: ApiDto[];
    if (this.authUser.apiRol === 'API_ADMIN') {
      userApi = await this.userService
        .getApis(this.authUser.id)
        .pipe(first())
        .toPromise();
    } else {
      userApi = await this.userService
        .getApis(null, null, this.authUser.id)
        .pipe(first())
        .toPromise();
    }
    this.apiId = userApi[0].id;
  }

  getHomeOwnerUser(): void {
    // Vemos con qué broker contrata este hombre porque si es con Caser no debe poder recalcular póliza ni meter coberturas extra
    if (this.insuranceCompanyCode === 'CASER') {
      this.showRecalculatePolicyBlock = false;
    }

    if (this.candidature.asset?.buildingId) {
      this.checkPolicyHolderDataFromBuildingId();
    } else {
      this.checkPolicyHolderDataFromUser();
    }
  }

  loadForm(): void {
    this.aragForm = this.formBuilder.group({
      score: [this.candidature.score, [Validators.required]],
      assetRent: [
        this.candidature.asset.rentalPrice,
        [Validators.required, Validators.max(5000000)]
      ],
      document:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [this.user.dni, [Validators.required, appValidators.dniValidator]]
          : [null, [Validators.required, appValidators.cifValidator]],
      firstName:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [
              this.user.firstname,
              [Validators.required, appValidators.namesValidator]
            ]
          : [null],
      surname: [
        this.user.surname1,
        [Validators.required, appValidators.complexNameValidator]
      ],
      surname2:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [this.user.surname2, [appValidators.namesValidator]]
          : [null],
      phone: [
        this.user?.phone
          ? this.phoneMaskService.formatPhone(this.user.phone, this.country)
          : null,
        Validators.compose(this.getPhoneControlValidators())
      ],
      street: [
        null,
        [Validators.required, appValidators.maxLengthValidator(40)]
      ],
      number: [
        null,
        [
          Validators.required,
          appValidators.onlyNumericValidator,
          Validators.max(2000)
        ]
      ],
      postalCode: [
        this.user.postalCode,
        [Validators.required, appValidators.postalCodeValidator]
      ],
      city: [null, [Validators.required, appValidators.namesValidator]],
      province: [null, [Validators.required, appValidators.namesValidator]],
      birthdatePolicyHolder:
        this.selectedDocumentType === (this.typeDocumentEnum.DNI as string)
          ? [null, [Validators.required]]
          : [null],
      emailPolicyHolder: [
        this.user.email,
        [Validators.required, appValidators.emailForAragValidator]
      ],
      documentType: [this.selectedDocumentType, [Validators.required]],

      addressInsured: [
        this.calculateAdress(),
        [Validators.required, appValidators.maxLengthValidator(40)]
      ],
      postalCodeInsured: [
        this.candidature.asset.postalCode,
        [Validators.required, appValidators.postalCodeValidator]
      ],
      cityInsured: [
        this.candidature.asset.town,
        [
          Validators.required,
          appValidators.maxLengthValidator(50),
          appValidators.namesValidator
        ]
      ],
      documentInsured: !this.showInsuredForm
        ? [this.user.dni]
        : [this.user.dni, [Validators.required, appValidators.dniValidator]],
      nameInsured: [
        this.user.firstname,
        [Validators.required, appValidators.namesValidator]
      ],
      surnameInsured: [
        this.user.surname1,
        [Validators.required, appValidators.namesValidator]
      ],
      surname2Insured: [this.user.surname2, [appValidators.namesValidator]],
      inceptionDate: [new Date(), [Validators.required]],
      vandalismCoverage: [this.typeVandalismCoverageEnum.SIN],
      insuranceRetention: [this.typeInsuranceRetentionEnum.CON],
      cleaningCoverage: [this.typeCleaningCoverage.NO],
      savePolicyHolderData: [this.user.savePolicyHolderData],
      ccc: [null]
    });

    if (!this.insuranceBrokerPaid) {
      const cccControl = this.aragForm.get('ccc');
      // Si no está configurada la opción de pagos al mediador, tenemos que añadir el validador de cuentas
      cccControl.setValidators([
        Validators.required,
        appValidators.bankAccountValidator
      ]);
    }
  }

  getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  loadFormWithPolicyHolderData(): void {
    this.aragForm = this.formBuilder.group({
      score: [this.candidature.score, [Validators.required]],
      assetRent: [
        this.candidature.asset.rentalPrice,
        [Validators.required, Validators.max(5000000)]
      ],
      typeDocument: [this.user.policyHolderData.typeDocument],
      document:
        this.user.policyHolderData.typeDocument ===
        (this.typeDocumentEnum.DNI as string)
          ? [
              this.user.policyHolderData.document,
              [Validators.required, appValidators.dniValidator]
            ]
          : [
              this.user.policyHolderData.document,
              [Validators.required, appValidators.cifValidator]
            ],
      firstName:
        this.user.policyHolderData.typeDocument ===
        (this.typeDocumentEnum.DNI as string)
          ? [
              this.user.policyHolderData.firstname,
              [Validators.required, appValidators.namesValidator]
            ]
          : [null],
      surname: [
        this.user.policyHolderData.surname1,
        [Validators.required, appValidators.complexNameValidator]
      ],
      surname2:
        this.user.policyHolderData.typeDocument ===
        (this.typeDocumentEnum.DNI as string)
          ? [
              this.user.policyHolderData.surname2,
              [appValidators.namesValidator]
            ]
          : [null],
      phone: [
        this.user?.policyHolderData?.phone
          ? this.user.policyHolderData.phone
          : null,
        Validators.compose([
          Validators.required
          // TODO: IonIntlTelInputValidators.phone
        ])
      ],
      street: [
        this.user.policyHolderData?.street,
        [Validators.required, appValidators.maxLengthValidator(35)]
      ],
      number: [this.user.policyHolderData.number, [Validators.required]],
      postalCode: [
        this.user.policyHolderData?.postalCode,
        [Validators.required, appValidators.postalCodeValidator]
      ],
      city: [
        this.user.policyHolderData?.city,
        [Validators.required, appValidators.namesValidator]
      ],
      province: [
        this.user?.policyHolderData?.province,
        [Validators.required, appValidators.namesValidator]
      ],
      birthdatePolicyHolder:
        this.user.policyHolderData.typeDocument ===
        (this.typeDocumentEnum.DNI as string)
          ? [this.user.policyHolderData.dateOfBirth, [Validators.required]]
          : [null],
      emailPolicyHolder: [
        this.user.policyHolderData.email,
        [Validators.required, appValidators.emailForAragValidator]
      ],
      documentType: [
        this.user.policyHolderData.typeDocument,
        [Validators.required]
      ],

      addressInsured: [
        this.calculateAdress(),
        [Validators.required, appValidators.maxLengthValidator(40)]
      ],
      postalCodeInsured: [
        this.candidature.asset.postalCode,
        [Validators.required, appValidators.postalCodeValidator]
      ],
      cityInsured: [
        this.candidature.asset.town,
        [
          Validators.required,
          appValidators.maxLengthValidator(50),
          appValidators.namesValidator
        ]
      ],
      documentInsured: !this.showInsuredForm
        ? [this.user.dni]
        : [this.user.dni, [Validators.required, appValidators.dniValidator]],
      nameInsured: [
        this.user.firstname,
        [Validators.required, appValidators.namesValidator]
      ],
      surnameInsured: [
        this.user.surname1,
        [Validators.required, appValidators.namesValidator]
      ],
      surname2Insured: [this.user.surname2, [appValidators.namesValidator]],
      inceptionDate: [new Date(), [Validators.required]],
      vandalismCoverage: [this.typeVandalismCoverageEnum.SIN],
      insuranceRetention: [this.typeInsuranceRetentionEnum.CON],
      cleaningCoverage: [this.typeCleaningCoverage.NO],
      savePolicyHolderData: [this.user.savePolicyHolderData],
      ccc: [null]
    });

    if (!this.insuranceBrokerPaid) {
      const cccControl = this.aragForm.get('ccc');
      // Si no está configurada la opción de pagos al mediador, tenemos que añadir el validador de cuentas
      cccControl.setValue(this.user.policyHolderData.ccc);
      cccControl.setValidators([
        Validators.required,
        appValidators.bankAccountValidator
      ]);
    }
  }

  /**
   * Se hace trim al valor del input
   * @param input Form control del fomulario
   */
  deleteSpacesFormControl(input: string): void {
    appValidators.deleteSpacesFormControl(this.aragForm, input);
  }

  /**
   * Se intruduce un espacio cada 4 numeros al valor del input ccc para facilitar lectura
   * @param event valor para ccc del fomulario
   */
  cccMaskChanges(event: Event): void {
    const value = (event.target as HTMLInputElement).value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.aragForm.get('ccc').setValue(value);
  }

  calculateAdress(): string {
    const emptySpace = ' ';
    const address =
      (this.candidature.asset.street
        ? this.candidature.asset.street + emptySpace
        : '') +
      (this.candidature.asset.number
        ? this.candidature.asset.number + emptySpace
        : '') +
      (this.candidature.asset.portal
        ? this.candidature.asset.portal + emptySpace
        : '') +
      (this.candidature.asset.floor
        ? this.candidature.asset.floor + emptySpace
        : '') +
      (this.candidature.asset.door ? this.candidature.asset.door : '');
    if (address.length > 40) {
      return address.substring(0, 39);
    }
    return address;
  }

  convertDateToArag(date: Date): string {
    if (!date) return null;
    const x = new Date(date);
    const y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    d.length === 1 && (d = '0' + d);
    m.length === 1 && (m = '0' + m);
    const yyyymmdd = y + m + d;
    return yyyymmdd;
  }

  createArag(): void {
    this.isDisabledPolicyButton = true;
    const emptyValueForArag = '';

    this.aragInsurance.score = this.aragForm.controls.score.value;
    this.aragInsurance.assetRent = this.aragForm.controls.assetRent.value;
    this.aragInsurance.document = this.aragForm.controls.document.value;
    this.aragInsurance.firstName = this.aragForm.controls.firstName.value;
    this.aragInsurance.surname = this.aragForm.controls.surname.value;
    this.aragInsurance.surname2 = this.aragForm.controls.surname2.value;
    if (this.aragForm.controls.phone.value) {
      this.aragInsurance.phone = this.formatPhone(
        this.aragForm.controls.phone.value
      );
    }
    this.aragInsurance.address = `${this.aragForm.controls.street.value} ${this.aragForm.controls.number.value}`;
    this.aragInsurance.street = this.aragForm.controls.street.value;
    this.aragInsurance.number = this.aragForm.controls.number.value;
    this.aragInsurance.province = this.aragForm.controls.province.value;

    this.aragInsurance.postalCode = this.aragForm.controls.postalCode.value;
    this.aragInsurance.city = this.aragForm.controls.city.value;
    //Eliminamos espacios introducidos por la mascara para ccc (sólo si está informado)
    if (this.aragForm.controls.ccc.value !== null) {
      this.aragInsurance.ccc = this.aragForm.controls.ccc.value.replace(
        /\s/g,
        ''
      );
    }
    this.aragInsurance.birthdatePolicyHolder = this.convertDateToArag(
      this.aragForm.controls.birthdatePolicyHolder.value as Date
    );
    this.aragInsurance.mobilePhonePolicyHolder = this.aragInsurance.phone;
    this.aragInsurance.emailPolicyHolder =
      this.aragForm.controls.emailPolicyHolder.value;
    this.aragInsurance.documentType = this.aragForm.controls.documentType.value;

    this.aragInsurance.insuranceAsset.cotenants = this.cotenants;

    this.aragInsurance.insuranceAsset.address =
      this.aragForm.controls.addressInsured.value;
    this.aragInsurance.insuranceAsset.street = this.candidature.asset.street;
    this.aragInsurance.insuranceAsset.number = this.candidature.asset.number;
    this.aragInsurance.insuranceAsset.portal = this.candidature.asset.portal;
    this.aragInsurance.insuranceAsset.floor = this.candidature.asset.floor;
    this.aragInsurance.insuranceAsset.door = this.candidature.asset.door;
    this.aragInsurance.insuranceAsset.postalCode =
      this.candidature.asset.postalCode;
    this.aragInsurance.insuranceAsset.city = this.candidature.asset.town;
    this.aragInsurance.insuranceAsset.province =
      this.candidature.asset.province;

    this.aragInsurance.insuranceAsset.document = !this.showInsuredForm
      ? this.aragForm.controls.document.value
      : this.aragForm.controls.documentInsured.value;
    //flujo (Tomador diferente del asegurado deshabilitado, se mandara por defecto name, surname, surname2 cif, del propietario como tomador por defecto)
    //pero si se elige cif solo se enviara cif y surname en el el form deshabilitado de abajo (asegurado)
    this.aragInsurance.insuranceAsset.name =
      this.selectedDocumentType === (this.typeDocumentEnum.CIF as string)
        ? emptyValueForArag
        : !this.showInsuredForm
          ? this.aragForm.controls.firstName.value
          : this.aragForm.controls.nameInsured.value;
    this.aragInsurance.insuranceAsset.surname = !this.showInsuredForm
      ? this.aragForm.controls.surname.value
      : this.aragForm.controls.surnameInsured.value;
    this.aragInsurance.insuranceAsset.surname2 =
      this.selectedDocumentType === (this.typeDocumentEnum.CIF as string)
        ? emptyValueForArag
        : !this.showInsuredForm
          ? this.aragForm.controls.surname2.value
          : this.aragForm.controls.surname2Insured.value;
    //Crear checkbox que recoja este valor cuando liberemos la segunda parte del formulario "asegurado"
    this.aragInsurance.insuranceAsset.insuranceTypeDocument =
      this.selectedDocumentType === (this.typeDocumentEnum.CIF as string)
        ? (this.typeDocumentEnum.CIF as string)
        : (this.typeDocumentEnum.DNI as string);
    // Fecha de inicio de la póliza
    this.aragInsurance.inceptionDate = this.formatDate(
      this.aragForm.controls.inceptionDate.value as Date
    );

    // Si es un cif, ponemos nombre y segundo apellidos a null
    if (this.aragInsurance.documentType === '1') {
      this.aragInsurance.firstName = null;
      this.aragInsurance.surname2 = null;
    }
    this.aragInsurance.vandalismCoverage =
      this.aragForm.controls.vandalismCoverage.value;
    this.aragInsurance.insuranceRetention =
      this.aragForm.controls.insuranceRetention.value;
    this.aragInsurance.cleaningCoverage =
      this.aragForm.controls.cleaningCoverage.value;
    this.aragInsurance.candidatureStatus =
      this.candidature.candidatureStatusEnum;
    // Se añade también, si las tuviese, las coordenadas del asset
    this.aragInsurance.assetLocation = this.candidature.asset.location;
    // Se añaden campos para gestión de edificios
    this.aragInsurance.assetId = this.candidature.asset.id;
    if (this.authUser.portfolioId) {
      this.aragInsurance.portfolioId = this.authUser.portfolioId;
      this.aragInsurance.portfolioOwnerId = this.authUser.id;
    }
    if (
      this.authUser.apiRol === 'API_ADMIN' ||
      this.authUser.apiRol === 'API_MEMBER'
    ) {
      this.aragInsurance.apiId = this.apiId;
      this.aragInsurance.portfolioId = this.candidature.asset.portfolioId;
    }

    //Se guarda en el objeto de póliza la comisión del usuario si tuviera el monedero activo
    if (this.authUser.hasFeePocket) {
      this.aragInsurance.commissionFee = this.authUser.commissionFee;
    }

    if (this.originalPolicyNumber) {
      this.aragInsurance.originalPolicyNumber = this.originalPolicyNumber;
    }

    this.loadingService.presentSecondLoader(
      this.translateService.instant(
        'components.add-arag-insurance.creating_policy'
      ) as string
    );

    this.aragInsurance.calculatedPrice = this.totalAnnualReceipt;
    this.aragInsurance.initialPrice = this.initialPrice;
    if (this.client) {
      this.aragInsurance.clientId = this.client.id;
    }

    this.candidatureService
      .createInsurancePolicyArag(this.candidature.id, this.aragInsurance)
      .subscribe({
        next: (blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              this.translateService.instant(
                'pages.profile.home-owner.assets.disable_popup_blocker'
              )
            );
          }
          this.isDisabledPolicyButton = false;
          this.presentToast(
            this.translateService.instant(
              'components.add-arag-insurance.creating_policy_ok'
            ),
            true
          );
          // Si se ha marcado la opción de guardar los datos del tomador para futura ocasiones, entonces actualizamos el nodo en el usuario
          if (this.aragForm.controls.savePolicyHolderData.value === true) {
            this.savePolicyHolderData();
          }
          this.loadingService.dismissSecondLoader();
          this.dismiss(true);
        },
        // eslint-disable-next-line @typescript-eslint/typedef
        error: async (error) => {
          const errorCode = JSON.parse(
            (await error.error.text()) as string
          ).code;
          this.loadingService.dismissSecondLoader();
          this.isDisabledPolicyButton = false;
          if (errorCode === '104') {
            this.presentToast(
              this.translateService.instant(
                'components.add-arag-insurance.dni_does_not_correspond'
              ),
              false
            );
          } else {
            this.presentToast(
              this.translateService.instant(
                'components.add-arag-insurance.creating_policy_fail'
              ),
              false
            );
          }
        }
      });
  }

  checkCreateArag(): boolean {
    const validForm: boolean = this.aragForm?.valid;
    return validForm;
  }

  prepareCotenantsFromCandidature(): void {
    this.cotenants = this.candidature.tenantCandidatureList.map(
      (item: TenantCandidature) => {
        const userCotenantArag: CotenantsArag = {
          type: this.calculateCotenantsTypeArag(item.user.guarantor),
          document: item.user.dni,
          name: item.user.firstname,
          surname: item.user.surname1,
          surname2: item.user.surname2,
          phone: item.user.phone,
          guarantor: item.user.guarantor,
          freelance: item.user.profData ? item.user.profData.freelance : false,
          student: item.user.student,
          postalCode: item.user.postalCode,
          birthDate: item.user.birthDate,
          street: item.user.street,
          number: item.user.number,
          city: item.user.city
        };
        return userCotenantArag;
      }
    );
  }

  calculateCotenantsTypeArag(guarantor: boolean): string {
    return guarantor
      ? (this.translateService.instant(
          'components.add-arag-insurance.guarantor'
        ) as string)
      : (this.translateService.instant(
          'components.add-arag-insurance.tenant'
        ) as string);
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  async presentToast(messages: any, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'success' : 'danger',
      duration: 2000
    });
    toast.present();
  }

  // eslint-disable-next-line @typescript-eslint/typedef
  radioGroupChange(event): void {
    this.selectedDocumentType = event.detail.value;
    //this.aragForm.reset();
    this.loadForm();
  }

  switchHolderAndOwnerForm(): void {
    this.showInsuredForm = !this.showInsuredForm;
    //this.aragForm.reset();
    this.loadForm();
  }

  onChangeDocumentType(event: Event): void {
    this.changeDocumentType(
      (event as CustomEvent<MyCustomEvent>).detail.value as string
    );
  }

  changeDocumentType(documentType: string): void {
    this.selectedDocumentType = documentType;
    this.aragForm.get('documentType').setValue(documentType);

    if (documentType === (this.typeDocumentEnum.DNI as string)) {
      this.aragForm.controls['document'].setValidators([
        Validators.required,
        appValidators.dniValidator
      ]);
      this.aragForm.controls['document'].setValue(this.user.dni);
      this.aragForm.controls['document'].markAsUntouched();
      this.aragForm.controls['document'].markAsPristine();

      this.aragForm.controls['birthdatePolicyHolder'].setValue(null);
      this.aragForm.controls['birthdatePolicyHolder'].setValidators([
        Validators.required
      ]);
      this.aragForm.controls['birthdatePolicyHolder'].setErrors(null);
      this.aragForm.controls['birthdatePolicyHolder'].markAsUntouched();
      this.aragForm.controls['birthdatePolicyHolder'].markAsPristine();

      this.aragForm.controls['firstName'].setValue(this.user.firstname);
      this.aragForm.controls['firstName'].setValidators([
        Validators.required,
        appValidators.namesValidator
      ]);
      this.aragForm.controls['firstName'].setErrors(null);
      this.aragForm.controls['firstName'].markAsUntouched();
      this.aragForm.controls['firstName'].markAsPristine();

      this.aragForm.controls['surname'].setValue(this.user.surname1);
      this.aragForm.controls['surname'].markAsUntouched();
      this.aragForm.controls['surname'].markAsPristine();

      this.aragForm.controls['surname2'].setValue(this.user.surname2);
      this.aragForm.controls['surname2'].setValidators([
        appValidators.namesValidator
      ]);
      this.aragForm.controls['surname2'].setErrors(null);
      this.aragForm.controls['surname2'].markAsUntouched();
      this.aragForm.controls['surname2'].markAsPristine();
    } else {
      this.aragForm.controls['document'].setValidators([
        Validators.required,
        appValidators.cifValidator
      ]);
      this.aragForm.controls['document'].setValue(null);
      this.aragForm.controls['document'].markAsUntouched();
      this.aragForm.controls['document'].markAsPristine();

      this.aragForm.controls['birthdatePolicyHolder'].clearValidators();
      this.aragForm.controls['birthdatePolicyHolder'].setErrors(null);
      this.aragForm.controls['birthdatePolicyHolder'].markAsUntouched();
      this.aragForm.controls['birthdatePolicyHolder'].markAsPristine();

      this.aragForm.controls['firstName'].clearValidators();
      this.aragForm.controls['firstName'].setErrors(null);
      this.aragForm.controls['firstName'].markAsUntouched();
      this.aragForm.controls['firstName'].markAsPristine();

      this.aragForm.controls['surname'].setValue(null);
      this.aragForm.controls['surname'].markAsUntouched();
      this.aragForm.controls['surname'].markAsPristine();

      this.aragForm.controls['surname2'].clearValidators();
      this.aragForm.controls['surname2'].setErrors(null);
      this.aragForm.controls['surname2'].markAsUntouched();
      this.aragForm.controls['surname2'].markAsPristine();
    }
  }

  recalculateInsurancePremium(): void {
    const lastTotalAnnualReceiptValue = this.totalAnnualReceipt;
    this.candidatureService
      .recalculateInsurancePremium(
        this.candidature.id,
        this.candidature.score,
        this.candidature.asset.rentalPrice,
        this.aragForm.controls.vandalismCoverage.value as string,
        this.aragForm.controls.insuranceRetention.value as string,
        this.aragForm.controls.cleaningCoverage.value as string
      )
      .pipe(first())
      .subscribe(
        (recalculateResponse: CalculatedPremiumAragDto) => {
          this.totalAnnualReceipt = recalculateResponse.totalAnnualReceipt;
          this.textoBotonRecalcular = this.translateService.instant(
            'components.add-arag-insurance.recalculate'
          );
        },
        () => {
          this.totalAnnualReceipt = lastTotalAnnualReceiptValue;
        }
      );
  }

  startLoad(): void {
    this.textoBotonRecalcular = this.translateService.instant(
      'components.add-arag-insurance.recalculating'
    );
  }

  showIPID(): void {
    this.seePDF('../../../assets/pdfs/arag/IPID.pdf');
  }

  showGeneralConditions(): void {
    this.seePDF('../../../assets/pdfs/arag/Condiciones_Generales.pdf');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  seePDF(url: string): void {
    // window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.widthChangeSub?.unsubscribe();
  }

  private savePolicyHolderData(): void {
    const policyHolderData: PolicyHolderData = {
      typeDocument: this.aragInsurance.documentType,
      document: this.aragInsurance.document,
      dateOfBirth: this.aragForm.controls.birthdatePolicyHolder.value,
      firstname: this.aragInsurance.firstName,
      surname1: this.aragInsurance.surname,
      surname2: this.aragInsurance.surname2,
      street: this.aragInsurance.street,
      number: this.aragInsurance.number,
      postalCode: this.aragInsurance.postalCode,
      city: this.aragInsurance.city,
      province: this.aragInsurance.province,
      phone: this.aragInsurance.phone,
      email: this.aragInsurance.emailPolicyHolder,
      ccc: this.aragInsurance.ccc
    };
    this.userService
      .updatePolicyHolderData(this.user.id, policyHolderData)
      .subscribe();
  }

  private formatDate(date: Date): string {
    const dateFormat = new Date(date);
    const day = dateFormat.getDate();
    const month = dateFormat.getMonth() + 1;
    const year = dateFormat.getFullYear();
    let dayString = day.toString();
    let monthString = month.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }
    if (month < 10) {
      monthString = '0' + monthString;
    }
    return dayString + '/' + monthString + '/' + year;
  }

  private formatPhone(phone: any): string {
    return this.utilsService.formatPhone(phone);
  }

  private checkPolicyHolderDataFromBuildingId(): void {
    this.assetsService
      .getBuildingById(this.candidature.asset.buildingId)
      .subscribe((building: BuildingDto) => {
        if (building.policyHolderData) {
          if (!!this.client) {
            this.user = this.client;
          } else {
            this.user = this.authUser;
          }

          this.user.policyHolderData = building.policyHolderData;
          this.loadFormWithPolicyHolderData();
          if (this.user?.policyHolderData?.typeDocument === '1') {
            this.changeDocumentType(this.user.policyHolderData.typeDocument);
            this.aragForm.controls['document'].setValue(
              this.user?.policyHolderData?.document
            );
            this.aragForm.controls['surname'].setValue(
              this.user?.policyHolderData?.surname1
            );
          }
        } else {
          this.checkPolicyHolderDataFromUser();
        }
      });
  }

  private checkPolicyHolderDataFromUser(): void {
    if (!!this.client) {
      this.user = this.client;
    } else {
      this.user = this.authUser;
    }
    if (this.user.savePolicyHolderData) {
      this.loadFormWithPolicyHolderData();
      if (this.user?.policyHolderData?.typeDocument === '1') {
        this.changeDocumentType(this.user.policyHolderData.typeDocument);
        this.aragForm.controls['document'].setValue(
          this.user?.policyHolderData?.document
        );
        this.aragForm.controls['surname'].setValue(
          this.user?.policyHolderData?.surname1
        );
      }
    } else {
      this.loadForm();
    }
  }
}
