import { Pipe, PipeTransform } from '@angular/core';
import { Catalog, Incidence } from '@core/models';

@Pipe({
  name: 'incidenceTypeValue'
})
export class IncidenceTypeValuePipe implements PipeTransform {
  transform(incidence: Incidence, incidenceTypes: Catalog[]): string {
    if (incidenceTypes?.length === 0) {
      return '';
    }
    return (
      incidenceTypes.find((type: Catalog) => type.id === incidence.type)
        ?.value || ''
    );
  }
}
