import { CommonModule, DecimalPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { TranslateModule } from '@ngx-translate/core';
import { StarRatingModule } from 'angular-star-rating';
import { NgChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';

import { AddAragInsuranceComponent } from './components/add-arag-insurance/add-arag-insurance.component';
import { AddAssetComponent } from './components/add-asset/add-asset.component';
import { AddBuildingModalComponent } from './components/add-building-modal/add-building-modal.component';
import { AddDocumentComponent } from './components/add-document/add-document.component';
import { AddDocumentToCandidatureComponent } from './components/add-document-to-candidature/add-document-to-candidature.component';
import { AddDocumentToIncidenceModalComponent } from './components/add-document-to-incidence-modal/add-document-to-incidence-modal.component';
import { AddMultipleCandidatureComponent } from './components/add-multiple-candidature/add-multiple-candidature.component';
import { AddSinisterDocumentComponent } from './components/add-sinister-document/add-sinister-document.component';
import { AddTenantInsuranceComponent } from './components/add-tenant-insurance/add-tenant-insurance.component';
import { AddTrialCandidatureComponent } from './components/add-trial-candidature/add-trial-candidature.component';
import { AmlTableComponent } from './components/aml-table/aml-table.component';
import { AppointmentFormComponent } from './components/appointment-form/appointment-form.component';
import { AssetFiltersComponent } from './components/asset-filters/asset-filters.component';
import { AssetListComponent } from './components/asset-list/asset-list.component';
import { AssetMapComponent } from './components/asset-map/asset.map';
import { AssetUtilsPopoverComponent } from './components/asset-utils-popover/asset-utils-popover.component';
import { AssetsApiFilterPopoverComponent } from './components/assets-api-filter-popover/assets-api-filter-popover.component';
import { AssetColorLabelPopoverComponent } from './components/assets-color-label-popover/assets-color-label-popover.component';
import { AssociatedCandidaturesPopoverComponent } from './components/associated-candidatures-popover/associated-candidatures-popover.component';
import { BackofficeUserDocumentItemComponent } from './components/backoffice-user-document-item/backoffice-user-document-item.component';
import { BackofficeUserDocumentsComponent } from './components/backoffice-user-documents/backoffice-user-documents.component';
import { BiometricPopoverComponent } from './components/biometric-popover/biometric-popover.component';
import { BuildingUtilsPopoverComponent } from './components/building-utils-popover/building-utils-popover.component';
import { BusinessRolDataFormModalComponent } from './components/business-rol-data-form-modal/business-rol-data-form-modal.component';
import { CancelPolicyAlertComponent } from './components/cancel-policy-alert/cancel-policy-alert.component';
import { CancelPolicyModalComponent } from './components/cancel-policy-modal/cancel-policy-modal.component';
import { CancelPolicyRequestModalComponent } from './components/cancel-policy-request-modal/cancel-policy-request-modal.component';
import { CandidatureInformationComponent } from './components/candidature-information/candidature-information.component';
import { CandidatureNotesComponent } from './components/candidature-notes/candidature-notes.component';
import { CandidatureNotesModalComponent } from './components/candidature-notes-modal/candidature-notes-modal.component';
import {
  CardProfileComponent,
  PopoverComponent
} from './components/card-profile/card-profile.component';
import { ChangeAssetApiPopoverComponent } from './components/change-asset-api-popover/change-asset-api-popover.component';
import { ChangeTenantRoleComponent } from './components/change-tenant-role/change.tenant.role.component';
import { ConfirmCandidatureStepComponent } from './components/confirm-candidature-step/confirm-candidature-step.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ConfirmationSinisterModalComponent } from './components/confirmation-sinister-modal/confirmation-sinister-modal.component';
import { ContactNewApiComponent } from './components/contact-new-api/contact-new-api.component';
import { ContractRcInsuranceComponent } from './components/contract-rc-insurance/contract-rc-insurance.component';
import { CotenantModalPage } from './components/cotenant-modal/cotenant-modal.page';
import { CreateNewAppointmentModalComponent } from './components/create-new-appointment-modal/create-new-appointment-modal.component';
import { CreateSinesterModalComponent } from './components/create-sinester-modal/create-sinester-modal.component';
import { CustomDataLoginComponent } from './components/custom-data-login/custom-data-login.component';
import { DataLoginComponent } from './components/data-login/data-login.component';
import { DataSigninComponent } from './components/data-signin/data-signin.component';
import { DeleteAccountModalComponent } from './components/delete-account/delete.account.modal.component';
import { DocumentModalComponent } from './components/document-modal/document.modal.component';
import { DocumentationTenantsModalComponent } from './components/documentation-tenants-modal/documentation-tenants-modal.component';
import { EmailRegisterComponent } from './components/email-register/email-register.component';
import { ErrorOnComponentComponent } from './components/error-on-component/error-on-component.component';
import { FeePocketBannerComponent } from './components/fee-pocket-banner/fee-pocket-banner.component';
import { FinancialDataBuildingModalComponent } from './components/financial-data-building-modal/financial-data-building-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderUserPopoverComponent } from './components/header-user-popover/header-user-popover.component';
import { HistoricalDebtModalComponent } from './components/historical-debt-modal/historical-debt-modal.component';
import { IncidenceDetailModalComponent } from './components/incidence-detail-modal/incidence-detail-modal.component';
import { IncidencesListComponent } from './components/incidences-list/incidences-list.component';
import { IncidencesModalComponent } from './components/incidences-modal/incidences-modal.component';
import { InsuranceOptionsPopoverComponent } from './components/insurance-options-popover/insurance-options-popover.component';
import { InsurancesCandidatureListComponent } from './components/insurances-candidature-list/insurances-candidature-list.component';
import { InsurancesListComponent } from './components/insurances-list/insurances-list.component';
import { InsurancesManagementFiltersComponent } from './components/insurances-management-filters/insurances-management-filters.component';
import { InternalEventsComponent } from './components/internal-events/internal-events.component';
import { LinkAssetComponent } from './components/link-asset/link-asset.component';
import { LinkTenantsComponent } from './components/link-tenants/link-tenants.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPhoneFormComponent } from './components/login-phone-form/login-phone-form.component';
import { ManageTagsComponent } from './components/manage-tags/manage-tags.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalBuildingReportComponent } from './components/modal-building-report/modal-building-report.component';
import { MoreFiltersPopoverComponent } from './components/more-filters-popover/more-filters-popover.component';
import { MoreRegisterInfoModalComponent } from './components/more-register-info-modal/more-register-info-modal.component';
import { MoveDocumentModalComponent } from './components/move-document-modal/move-document-modal.component';
import { MultipleApiAssignComponent } from './components/multiple-api-assign/multiple-api-assign.component';
import { MultipleDocumentModalComponent } from './components/multiple-document-modal/multiple.document.modal.component';
import { MultiplePriceAssignComponent } from './components/multiple-price-assign/multiple-price-assign.component';
import { NameAvatarGeneratorComponent } from './components/name-avatar-generator/name-avatar-generator.component';
import { NoItemsMessageComponent } from './components/no-items-message/no-items-message.component';
import { OwnerAnalyticsPdfModalComponent } from './components/owner-analytics-pdf-modal/owner-analytics-pdf-modal.component';
import { PayCertificationModalPage } from './components/pay-certification-modal/pay-certification-modal.page';
import { PhoneFlagModalComponent } from './components/phone-flag-modal/phone-flag-modal.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { PhoneInputNgmodelComponent } from './components/phone-input-ngmodel/phone-input-ngmodel.component';
import { PhoneRegisterComponent } from './components/phone-register/phone-register.component';
import { PolicyCancelationConversationComponent } from './components/policy-cancelation-conversation/policy-cancelation-conversation.component';
import { PolicyConversationComponent } from './components/policy-conversation/policy-conversation.component';
import { PolicyDetailModalComponent } from './components/policy-detail-modal/policy-detail-modal.component';
import { PreviewReportComponent } from './components/preview-report/preview-report.component';
import { ProfilePasswordRecoveryFormComponent } from './components/profile-password-recovery-form/profile-password-recovery-form.component';
import { ProfilePreferencesComponent } from './components/profile-preferences/profile-preferences.component';
import { ProfilePreferencesContentComponent } from './components/profile-preferences-content/profile-preferences-content.component';
import { ProfilePreferencesModalComponent } from './components/profile-preferences-modal/profile-preferences-modal.component';
import { ProfileUnsubscribeComponent } from './components/profile-unsubscribe/profile-unsubscribe.component';
import { ProfitabilityCandidatureInformationComponent } from './components/profitability-candidature-information/profitability-candidature-information.component';
import { RecommendationFormComponent } from './components/recommendation-form/recommendation-form.component';
import { RecommendationListComponent } from './components/recommendation-list/recommendation-list.component';
import { RequestInvitationComponent } from './components/request-invitation/request-invitation.component';
import { RequestRecomendationComponent } from './components/request-recomendation/request-recomendation.component';
import { SelectTenantsStepComponent } from './components/select-tenants-step/select-tenants-step.component';
import { SelectedCandidatureModalComponent } from './components/selected-candidature-modal/selected-candidature-modal.component';
import { SelectedCandidatureSinistersComponent } from './components/selected-candidature-sinisters/selected-candidature-sinisters.component';
import { SendRequestGuarantorComponent } from './components/send-request-guarantor/send-request-guarantor.component';
import { SendRequestHomeownerComponent } from './components/send-request-homeowner/send-request-homeowner.component';
import { SendRequestTenantComponent } from './components/send-request-tenant/send-request-tenant.component';
import { SharedPoliciesComponent } from './components/shared-policies/shared-policies.component';
import { SharedSinistersComponent } from './components/shared-sinisters/shared-sinisters.component';
import { SignedDocumentTableComponent } from './components/signed-document-table/signed-document-table.component';
import { SinisterChronologyModalComponent } from './components/sinister-chronology-modal/sinister-chronology-modal.component';
import { SinisterConversationComponent } from './components/sinister-conversation/sinister-conversation.component';
import { SinisterDetailModalComponent } from './components/sinister-detail-modal/sinister-detail-modal.component';
import { SinisterDocumentsComponent } from './components/sinister-documents/sinister-documents.component';
import { SocialMediaAnalysisComponent } from './components/social-media-analysis/social-media-analysis.component';
import { StepOneComponent } from './components/step-one/step-one.component';
import { StepThreeMultipleComponent } from './components/step-three-multiple/step-three-multiple.component';
import { StepTwoMultipleComponent } from './components/step-two-multiple/step-two-multiple.component';
import { StepTwoNewTenantPage } from './components/step-two-new-tenant/step-two-new-tenant.page';
import { TabsMenuComponent } from './components/tabs-menu/tabs-menu.component';
import { TenantActionsModalComponent } from './components/tenant-actions-modal/tenant-actions-modal.component';
import { TenantListComponent } from './components/tenant-list/tenant-list.component';
import { TenantListPopoverComponent } from './components/tenant-list-popover/tenant-list-popover.component';
import { TenantProfileFormComponent } from './components/tenant-profile-form/tenant-profile-form.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { TimePopoverComponent } from './components/time-popover/time-popover.component';
import { TransactionsCandidatureInformationComponent } from './components/transactions-candidature-information/transactions-candidature-information.component';
import { TrialTabDocumentComponent } from './components/trial-tab-document/trial-tab-document.component';
import { UpdateAssetRentPriceModalComponent } from './components/update-asset-rent-price-modal/update-asset-rent-price-modal.component';
import { UpdateBasicInfoComponent } from './components/update-basic-info/update-basic-info.component';
import { UpdateDebtSinisterModalComponent } from './components/update-debt-sinister-modal/update-debt-sinister-modal.component';
import { UpdateSinisterModalComponent } from './components/update-sinister-modal/update-sinister-modal.component';
import { UploadAssetsExcelModalComponent } from './components/upload-assets-excel-modal/upload-assets-excel-modal.component';
import { VerifyDniAlertModalComponent } from './components/verifiy-dni-alert-modal/verify-dni-alert-modal.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { ViewSinisterDocumentModalComponent } from './components/view-sinister-document-modal/view-sinister-document-modal.component';
import { ViveAddDocModalComponent } from './components/vive-add-doc-modal/vive-add-doc-modal.component';
import { ViveDocModalComponent } from './components/vive-doc-modal/vive-doc-modal.component';
import { DniDirective } from './directives/dni.directive';
import { EmailDirective } from './directives/email.directive';
import { OnlyNumberWithDecimalsDirective } from './directives/only-number-with-decimals.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { PhoneNgmodelDirective } from './directives/phone-ngmodel.directive';
import { PhoneValidatorDirective } from './directives/phone-validator.directive';
import { CandidaturesPageComponent } from './pages/candidatures-page/candidatures-page.component';
import { DocumentsPageComponent } from './pages/documents-page/documents-page.component';
import { SelectedCandidatureIncidencesComponent } from './pages/selected-candidature-page/components/selected-candidature-incidences/selected-candidature-incidences.component';
import { SelectedCandidaturePageComponent } from './pages/selected-candidature-page/selected-candidature-page.component';
import { TabDocumentPageComponent } from './pages/tab-document-page/tab-document-page.component';
import { TrialDocumentPageComponent } from './pages/trial-document-page/trial-document-page.component';
import { AreThereItemsPipe } from './pipes/are-there-items.pipe';
import { AssetAddressPipe } from './pipes/asset-address.pipe';
import { AssetColClassPipe } from './pipes/asset-col-class.pipe';
import { AssetItemFunctionsPipe } from './pipes/asset-item-functions.pipe';
import { AssetLabelChipStylePipe } from './pipes/asset-label-chip-style.pipe';
import { BackofficeDocumentsPipe } from './pipes/backoffice-documents.pipe';
import { CandidatureNotesPipe } from './pipes/candidature-notes.pipe';
import { DocChipIconPipe } from './pipes/doc-chip-icon.pipe';
import { DocusignStatusTextPipe } from './pipes/docusign-status-text.pipe';
import { EnumToArrayPipe } from './pipes/enumToArray.pipe';
import { HomeInsurancesInitDateDataPipe } from './pipes/home-insurances-init-date-data.pipe';
import { HomeInsurancesStartDateHeaderPipe } from './pipes/home-insurances-start-date-header.pipe';
import { HomeownerInsurancesEndDateHeaderPipe } from './pipes/homeowner-insurances-end-date-header.pipe';
import { HomeownerInsurancesRenovationDatePipe } from './pipes/homeowner-insurances-renovation-date.pipe';
import { HomeownerStatisticsTextColorPipe } from './pipes/homeowner-statistics-text-color.pipe';
import { IncidenceStatusClassPipe } from './pipes/incidence-status-class.pipe';
import { IncidenceStatusPipe } from './pipes/incidence-status.pipe';
import { IncidenceTenantPipe } from './pipes/incidence-tenant.pipe';
import { IncidenceTypeValuePipe } from './pipes/incidence-type-value.pipe';
import { InternalNoteWriterPipe } from './pipes/internal-note-writer.pipe';
import { MultipleApiAssignPipe } from './pipes/multiple-api-assign.pipe';
import { MultipleApiTooltipPipe } from './pipes/multiple-api-tooltip.pipe';
import { MultiplePriceAssignPipe } from './pipes/multiple-price-assign.pipe';
import { PluralSingularPipe } from './pipes/plural.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ShowDocumentInfoRowPipe } from './pipes/show-document-info-row.pipe';
import { SinisterMessageClassPipe } from './pipes/sinister-message-class.pipe';
import { SinisterMessageDatePipe } from './pipes/sinister-message-date.pipe';
import { SinisterMessageWriterPipe } from './pipes/sinister-message-writer.pipe';
import { SinisterPhaseStatusPipe } from './pipes/sinister-phase-status.pipe';
import { SinisterStatusChipClassPipe } from './pipes/sinister-status-chip-class.pipe';
import { SinisterTypePipe } from './pipes/sinister-type.pipe';
import { StringToDatePipe } from './pipes/string-to-date.pipe';
import { TenantRolePipe } from './pipes/tenant-role.pipe';
import { ViveDocChipColorPipe } from './pipes/vive-doc-chip-color.pipe';
import { YearsFromDatePipe } from './pipes/years-from-date.pipe';
import { MaterialModule } from '../material/material.module';

// TODO: hay algunos que tendran que ser quitados de aqui y movidos a sus
// respectivos modulos
const COMPONENTS = [
  AddAragInsuranceComponent,
  AddAssetComponent,
  AddBuildingModalComponent,
  AddDocumentComponent,
  AddMultipleCandidatureComponent,
  AddTenantInsuranceComponent,
  AddTrialCandidatureComponent,
  AssetColorLabelPopoverComponent,
  AssetFiltersComponent,
  AssetListComponent,
  AssetMapComponent,
  AssetUtilsPopoverComponent,
  AssetsApiFilterPopoverComponent,
  AssociatedCandidaturesPopoverComponent,
  BiometricPopoverComponent,
  BuildingUtilsPopoverComponent,
  BusinessRolDataFormModalComponent,
  CancelPolicyModalComponent,
  CardProfileComponent,
  ChangeAssetApiPopoverComponent,
  ChangeTenantRoleComponent,
  ContactNewApiComponent,
  ContractRcInsuranceComponent,
  CotenantModalPage,
  CreateSinesterModalComponent,
  DataLoginComponent,
  DataSigninComponent,
  DeleteAccountModalComponent,
  DocumentModalComponent,
  DocumentationTenantsModalComponent,
  ErrorOnComponentComponent,
  FeePocketBannerComponent,
  FinancialDataBuildingModalComponent,
  FooterComponent,
  GoogleMapsComponent,
  HeaderComponent,
  HeaderUserPopoverComponent,
  IncidencesModalComponent,
  InsuranceOptionsPopoverComponent,
  InsurancesListComponent,
  InsurancesManagementFiltersComponent,
  LoginFormComponent,
  ManageTagsComponent,
  ModalBuildingReportComponent,
  ModalComponent,
  MoreFiltersPopoverComponent,
  MultipleDocumentModalComponent,
  NameAvatarGeneratorComponent,
  OwnerAnalyticsPdfModalComponent,
  PayCertificationModalPage,
  PhoneRegisterComponent,
  PopoverComponent,
  PreviewReportComponent,
  ProfilePreferencesComponent,
  ProfilePreferencesContentComponent,
  ProfilePreferencesModalComponent,
  RecommendationFormComponent,
  RecommendationListComponent,
  RequestInvitationComponent,
  RequestRecomendationComponent,
  SelectedCandidatureModalComponent,
  SendRequestGuarantorComponent,
  SendRequestHomeownerComponent,
  SendRequestTenantComponent,
  SharedSinistersComponent,
  SocialMediaAnalysisComponent,
  TenantActionsModalComponent,
  TermsOfUseComponent,
  UpdateBasicInfoComponent,
  UploadAssetsExcelModalComponent,
  VerticalMenuComponent,
  StepTwoMultipleComponent,
  StepOneComponent,
  StepThreeMultipleComponent,
  MoveDocumentModalComponent,
  CustomDataLoginComponent,
  ViveAddDocModalComponent,
  ViveDocModalComponent,
  StepTwoNewTenantPage,
  EmailRegisterComponent,
  SelectTenantsStepComponent,
  VerifyDniAlertModalComponent,
  ConfirmCandidatureStepComponent,
  TrialTabDocumentComponent,
  InternalEventsComponent,
  MultipleApiAssignComponent,
  MultiplePriceAssignComponent,
  BackofficeUserDocumentsComponent,
  BackofficeUserDocumentItemComponent,
  CandidatureNotesComponent,
  CandidatureNotesModalComponent,
  NoItemsMessageComponent,
  ConfirmationModalComponent,
  AmlTableComponent,
  TenantProfileFormComponent,
  ProfilePasswordRecoveryFormComponent,
  ProfileUnsubscribeComponent,
  CancelPolicyRequestModalComponent,
  TenantListPopoverComponent,
  LoginPhoneFormComponent,
  MoreRegisterInfoModalComponent,
  PolicyDetailModalComponent,
  SinisterDetailModalComponent,
  UpdateSinisterModalComponent,
  SinisterConversationComponent,
  SinisterDocumentsComponent,
  ConfirmationSinisterModalComponent,
  HistoricalDebtModalComponent,
  SinisterChronologyModalComponent,
  AddSinisterDocumentComponent,
  SharedPoliciesComponent,
  PhoneInputComponent,
  PhoneInputNgmodelComponent,
  CreateNewAppointmentModalComponent,
  AppointmentFormComponent,
  TimePopoverComponent,
  LinkAssetComponent,
  LinkTenantsComponent,
  PolicyCancelationConversationComponent,
  PhoneFlagModalComponent,
  PolicyConversationComponent,
  ViewSinisterDocumentModalComponent,
  UpdateAssetRentPriceModalComponent,
  CancelPolicyAlertComponent,
  UpdateDebtSinisterModalComponent,
  TabsMenuComponent,
  TenantListComponent,
  IncidencesListComponent,
  CandidatureInformationComponent,
  AddDocumentToCandidatureComponent,
  SelectedCandidatureSinistersComponent,
  InsurancesCandidatureListComponent,
  TransactionsCandidatureInformationComponent,
  ProfitabilityCandidatureInformationComponent,
  SignedDocumentTableComponent,
  AddDocumentToIncidenceModalComponent,
  IncidenceDetailModalComponent,
  AddDocumentToIncidenceModalComponent,
  IncidenceDetailModalComponent,
  SelectedCandidatureIncidencesComponent
];

const PAGES = [
  CandidaturesPageComponent,
  DocumentsPageComponent,
  TabDocumentPageComponent,
  TrialDocumentPageComponent,
  SelectedCandidaturePageComponent
];

const DIRECTIVES = [
  DniDirective,
  EmailDirective,
  OnlyNumberDirective,
  OnlyNumberWithDecimalsDirective,
  PhoneValidatorDirective,
  PhoneNgmodelDirective
];

const PIPES = [
  EnumToArrayPipe,
  PluralSingularPipe,
  ViveDocChipColorPipe,
  DocChipIconPipe,
  AreThereItemsPipe,
  ShowDocumentInfoRowPipe,
  AssetAddressPipe,
  AssetItemFunctionsPipe,
  AssetColClassPipe,
  MultipleApiAssignPipe,
  MultiplePriceAssignPipe,
  BackofficeDocumentsPipe,
  CandidatureNotesPipe,
  AssetLabelChipStylePipe,
  MultipleApiTooltipPipe,
  SinisterTypePipe,
  SinisterMessageClassPipe,
  SinisterMessageWriterPipe,
  SinisterMessageDatePipe,
  SinisterPhaseStatusPipe,
  SinisterStatusChipClassPipe,
  HomeownerInsurancesRenovationDatePipe,
  HomeownerStatisticsTextColorPipe,
  HomeownerInsurancesEndDateHeaderPipe,
  HomeInsurancesStartDateHeaderPipe,
  HomeInsurancesInitDateDataPipe,
  TenantRolePipe,
  SafeUrlPipe,
  InternalNoteWriterPipe,
  YearsFromDatePipe,
  IncidenceTypeValuePipe,
  IncidenceTenantPipe,
  IncidenceStatusPipe,
  IncidenceStatusClassPipe,
  SafeUrlPipe,
  DocusignStatusTextPipe,
  StringToDatePipe
];

const ANGULAR_PIPES = [DecimalPipe];

const ANGULAR_MODULES = [
  CommonModule,
  IonicModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule
];

const MODULES = [
  ClipboardModule,
  GoogleMapsModule,
  MaterialModule,
  NgChartsModule,
  PdfViewerModule,
  MaskitoModule
];

const SCHEMAS = [CUSTOM_ELEMENTS_SCHEMA];

@NgModule({
  declarations: [...PAGES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [
    ...ANGULAR_MODULES,
    ...MODULES,
    TranslateModule.forChild(),
    StarRatingModule.forRoot()
  ],
  exports: [
    // Modulos
    ...ANGULAR_MODULES,
    ...MODULES,
    TranslateModule,
    StarRatingModule,
    // Componentes
    ...PAGES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  schemas: [...SCHEMAS],
  providers: [
    ...ANGULAR_PIPES,
    SinisterPhaseStatusPipe,
    HomeownerInsurancesRenovationDatePipe
  ]
})
export class SharedModule {}
