/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Recommendation, TypeUserEnum } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { ModalController } from '@ionic/angular';

import { RecommendationFormComponent } from '../recommendation-form/recommendation-form.component';
import { RequestRecomendationComponent } from '../request-recomendation/request-recomendation.component';

@Component({
  selector: 'el-buen-inquilino-recommendation-list',
  templateUrl: './recommendation-list.component.html'
})
export class RecommendationListComponent implements OnInit {
  @Input()
  list: Recommendation[];

  @Output()
  recommend = new EventEmitter();

  addRecomendationAllowed: boolean;

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.addRecomendationAllowed = this.canAddRecommendation();
  }

  async presentModalAddRecommendation(): Promise<void> {
    const modal = await this.modalController.create({
      component: RecommendationFormComponent,
      cssClass: 'auto-height',
      componentProps: {
        userId:
          this.router.url.split('/')[this.router.url.split('/').length - 2]
      }
    });
    modal.onDidDismiss().then((d: any) => {
      if (d.data) {
        this.recommend.emit(d.data.sendValue);
      }
    });
    return await modal.present();
  }

  async requestRecomendation(): Promise<void> {
    const modal = await this.modalController.create({
      component: RequestRecomendationComponent,
      cssClass: 'auto-height'
    });
    return await modal.present();
  }

  getProfileImage(): string {
    return './assets/images/userdefault.png';
  }

  private canAddRecommendation(): boolean {
    let result = false;
    if (this.authenticationService.user) {
      if (
        this.authenticationService.user.userType === TypeUserEnum.HOMEOWNER &&
        this.authenticationService.user?.id !==
          this.activatedRoute.snapshot.params.id
      ) {
        result = true;
      }
    }
    return result;
  }
}
