import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { IncidencesModalComponent } from '@shared/components/incidences-modal/incidences-modal.component';
import { MultipleDocumentModalComponent } from '@shared/components/multiple-document-modal/multiple.document.modal.component';
import { PreviewReportComponent } from '@shared/components/preview-report/preview-report.component';
import { SelectedCandidatureModalComponent } from '@shared/components/selected-candidature-modal/selected-candidature-modal.component';
import { isCandidatureClosed } from '@shared/utils/sinister.utils';
import { presentToast } from '@shared/utils/toast.utils';
import { loadStripe } from '@stripe/stripe-js';
import { BehaviorSubject, first, forkJoin, Observable, Subject } from 'rxjs';

import { CandidaturesApiService } from '../../api-services/candidatures-api/candidatures-api.service';
import { DocumentsApiService } from '../../api-services/documenst-api/documents-api.service';
import { UsersApiService } from '../../api-services/users-api/users-api.service';
import {
  AnalysisCatalogue,
  AssetDto,
  Candidature,
  CandidatureStatusEnum,
  CandidatureStatusModalEnum,
  CheckMinimumDoc,
  CreateAnalysisDto,
  DocumentDTO,
  EBIStripeError,
  GeneralModalResponse,
  Incidence,
  StripeSession,
  TenantCandidature,
  User,
  UserAnalysisStatusEnum
} from '../../models';
import { filterIncidences } from '../../utils/incidences-api.utils';
import { AuthService } from '../auth/auth.service';
import { LoadingService } from '../loading/loading.service';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class CandidatureUtilsService {
  savingAnalysis$ = new BehaviorSubject<boolean>(false);
  getCandidatures$ = new Subject<void>();

  constructor(
    private utilsService: UtilsService,
    private translate: TranslateService,
    private modalController: ModalController,
    private authenticationService: AuthService,
    private router: Router,
    private candidatureService: CandidaturesApiService,
    private alertController: AlertController,
    private numberPipe: DecimalPipe,
    private loadingService: LoadingService,
    private docService: DocumentsApiService,
    private toastController: ToastController,
    private userService: UsersApiService
  ) {}

  setSavingAnalysisObs(value: boolean): void {
    this.savingAnalysis$.next(value);
  }

  getSavingAnalysisObs(): Observable<boolean> {
    return this.savingAnalysis$.asObservable();
  }

  setGetCandidaturesObs(): void {
    this.getCandidatures$.next();
  }

  getGetCandidaturesObs(): Observable<void> {
    return this.getCandidatures$.asObservable();
  }

  getCandidatureColorStatus(candidature: Candidature): string {
    let status = this.getCandidatureStatus(candidature);
    if (
      status === 'pre-selected' ||
      status === 'revision-owner' ||
      status === 'revision-kamino'
    ) {
      status = 'revision';
    }
    if (status === 'pending') {
      status = 'unfilled';
    }
    return `status-${status}`;
  }

  getCandidatureTextStatus(candidature: Candidature): string {
    const status = this.getCandidatureStatus(candidature);
    let msg: string;
    switch (status) {
      case 'pending':
        // eslint-disable-next-line max-len
        msg = `enums.candidatureStatusTenant.NO_REGISTERED`;
        break;
      case 'unfilled':
        // eslint-disable-next-line max-len
        msg = `enums.candidatureStatusTenant.${CandidatureStatusEnum.WITHOUT_CHECKING}`;
        break;
      case 'incidence':
        msg = `enums.candidatureStatusTenant.INCIDENCE`;
        break;
      case 'rejected-tenant':
        msg = `enums.candidatureStatusTenant.REJECTED_TENANT`;
        break;
      case 'pre-selected':
        msg = `enums.candidatureStatusTenant.${CandidatureStatusEnum.PENDING}`;
        break;
      case 'expired':
        msg = `enums.candidatureStatusTenant.${CandidatureStatusEnum.EXPIRED}`;
        break;
      case 'revision-ebi':
        msg = 'enums.candidatureStatusTenant.REVISION-EBI';
        break;
      case 'revision-owner':
        msg = 'enums.candidatureStatusTenant.REVISION-OWNER';
        break;
      case 'revision-kamino':
        msg = 'enums.candidatureStatusTenant.REVISION-KAMINO';
        break;
      default:
        // eslint-disable-next-line max-len
        msg = `enums.candidatureStatusTenant.${candidature.candidatureStatusEnum}`;
    }

    return this.translate.instant(msg) as string;
  }

  isRejectedByTenant(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) =>
        tenant.userCandidatureStatusDtoEnum === 'REJECT'
    );
  }

  getTenantStatus(tenant: TenantCandidature): string {
    let cause: string;

    // Comprobamos si no ha completado el registro
    if (!(tenant && tenant.user && tenant.user.id)) {
      cause = 'unregistered';
    } else {
      // Comprobamos si tiene la documentacióm completa
      const doc = this.checkTenantsDocumentation(tenant);
      if (!doc) {
        cause = 'doc';
      } else if (
        tenant.incidences?.length > 0 &&
        this.thereIsIncidence(tenant.incidences)
      ) {
        cause = 'incidence';
      }
    }

    return cause;
  }

  checkLiveCandidatureStatus(candidature: Candidature): boolean {
    switch (candidature?.candidatureStatusEnum) {
      case CandidatureStatusEnum.WITHOUT_CHECKING:
        return true;

      case CandidatureStatusEnum.PENDING:
        return true;

      case CandidatureStatusEnum.PRE_SELECT:
        return true;

      default:
        return false;
    }
  }

  getScoreColor(progress: number): string {
    if (progress < 35) {
      return 'danger';
    } else if (progress >= 80) {
      return 'success';
    } else {
      return 'warning';
    }
  }

  thereIsIncidence(incidences: Incidence[]): boolean {
    return incidences.some((i: Incidence) => !i.endDate);
  }

  getCandidatureStatus(candidature: Candidature): string {
    if (candidature.candidatureStatusEnum === CandidatureStatusEnum.REJECT) {
      return 'rejected';
    }
    if (this.isRejectedByTenant(candidature)) {
      return 'rejected-tenant';
    }

    // Si alguno de los tenants no está registrado
    if (this.checkUnregisteredTenants(candidature)) {
      return 'pending';
    }

    let noDocuments = false;
    for (
      let index = 0;
      index < candidature.tenantCandidatureList.length;
      index++
    ) {
      const tenant = candidature.tenantCandidatureList[index];
      const isCompleted = this.checkTenantsDocumentation(tenant);
      if (!isCompleted) {
        noDocuments = true;
        break;
      }
    }

    // Estado Caducado
    if (new Date(candidature.expirationDate) < new Date()) {
      candidature.candidatureStatusEnum = CandidatureStatusEnum.EXPIRED;
      return 'expired';
    }

    // Estado incompleto
    if (
      noDocuments &&
      (candidature.score === null || candidature.score === undefined) &&
      this.checkLiveCandidatureStatus(candidature)
    ) {
      return 'unfilled';
    }

    let incidence = false;
    for (
      let index = 0;
      index < candidature.tenantCandidatureList.length;
      index++
    ) {
      const incidenceList = candidature.tenantCandidatureList[index].incidences;
      if (incidenceList?.length > 0) {
        const isIncidence = this.thereIsIncidence(incidenceList);
        if (isIncidence) {
          incidence = true;
          break;
        }
      }
    }

    // Estado incidencia
    if (incidence) {
      return 'incidence';
    } else if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      !!candidature.tenantCandidatureList[0].analysisId &&
      !candidature.score
    ) {
      return 'revision-ebi';
    } else if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      candidature.selectDate !== null
    ) {
      return 'revision';
    } else if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      candidature.tenantCandidatureList[0].analysisId !== null &&
      candidature.score
    ) {
      return 'revision-owner';
    } else if (!!candidature?.warrantyId && candidature?.warrantyId !== '') {
      return 'revision-kamino';
    } else {
      switch (candidature.candidatureStatusEnum) {
        case CandidatureStatusEnum.PENDING:
          return 'revision';
        case CandidatureStatusEnum.PRE_SELECT:
          return 'pre-selected';
        case CandidatureStatusEnum.SELECT:
          return 'selected';
        case CandidatureStatusEnum.WITHOUT_CHECKING:
          return this.isRejectedByTenant(candidature)
            ? 'rejected-tenant'
            : 'unfilled';
        case CandidatureStatusEnum.EXPIRED:
          return 'expired';
      }
    }
  }
  checkUnregisteredTenants(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) => !tenant.user.id
    );
  }

  /**
   * Obtiene el rol del tenant
   * @param tenant
   * @returns Rol del tenant
   */
  getTenantRole(tenant: TenantCandidature): string {
    let role: string;

    if (tenant.user.guarantor) {
      role = 'guarantor';
    } else if (tenant.user.student) {
      role = 'student';
    } else if (tenant.user.business) {
      role = 'business';
    } else if (tenant.user.retired) {
      role = 'retired';
    } else if (tenant.user.freelance) {
      role = 'freelance';
    } else {
      role = 'worker';
    }

    return role;
  }

  getCandidatureStatusForCandidatureStatusModal(
    candidature: Candidature
  ): string {
    let status = this.getCandidatureStatus(candidature);
    if (status) {
      if (status === 'unfilled') {
        status = 'documents';
      }
      if (
        status === 'pre-selected' ||
        status === 'revision-owner' ||
        status === 'revision-kamino'
      ) {
        status = 'revision';
      }
    }
    return status;
  }

  showStatusHelpIcon(candidature: Candidature): boolean {
    const status =
      this.getCandidatureStatusForCandidatureStatusModal(candidature);

    return Object.keys(CandidatureStatusModalEnum).some(
      (key: string) => CandidatureStatusModalEnum[key] === status
    );
  }

  async presentSelectedCandidatureModal(
    candidature: Candidature
  ): Promise<void> {
    const closedOperation: boolean = isCandidatureClosed(candidature);
    const modal = await this.modalController.create({
      component: SelectedCandidatureModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature,
        closedOperation
      },
      id: 'selected-candidature'
    });
    await modal.present();
  }

  checkCandidatureTenants(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.every(
      (tenant: TenantCandidature) => {
        return this.checkTenant(tenant);
      }
    );
  }

  checkTenant(tenant: TenantCandidature): boolean {
    if (tenant && tenant.user && tenant.user.id) {
      return true;
    } else {
      return false;
    }
  }

  pendingToDownloadClass(candidature: Candidature): string {
    if (
      this.checkPendingToDownload(candidature) &&
      this.validationToShowDownloadAnalysis(candidature)
    ) {
      return 'pendingToDownload';
    }
    return '';
  }

  checkPendingToDownload(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) => tenant.pendingToDownload
    );
  }

  validationToShowDownloadAnalysis(candidature: Candidature): boolean {
    return (
      this.checkTenantDoc(candidature) &&
      this.checkTenantsStatus(
        candidature,
        UserAnalysisStatusEnum.REPORTED_ANALYSIS
      )
    );
  }

  checkTenantDoc(candidature: Candidature): boolean {
    if (
      this.checkTenantHasGuarantor(candidature) &&
      this.checkStudentTenant(candidature)
    ) {
      const tenantCandidatureListWithoutStudent =
        candidature.tenantCandidatureList.filter(
          (tenant: TenantCandidature) => !tenant.user.student
        );

      return this.calculateTenantIsStudentHasGuarantor(
        candidature,
        tenantCandidatureListWithoutStudent
      );
    } else {
      return this.checkAllTenantDocumentAreReady(
        candidature.tenantCandidatureList,
        candidature?.score
      );
    }
  }

  /**
   * Check use case when tenant is unemployed or student but need have a valid
   * guarantor to allow request analysis, now in masked guarantor feature we
   * need accept only valid Dni and payslips
   */
  checkTenantHasGuarantor(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) =>
          tenant.hasDni &&
          (tenant.hasPayslip ||
            tenant.hasRent ||
            tenant.hasPensionerRecognition)
      ).length > 0
    );
  }

  checkStudentTenant(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) => tenant.user.student
      ).length > 0
    );
  }

  checkTenantsStatus(
    candidature: Candidature,
    status: UserAnalysisStatusEnum,
    inProcess?: UserAnalysisStatusEnum
  ): boolean {
    if (!this.checkAllUserHaveAnalysis(candidature, inProcess)) {
      return candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) =>
          tenant.status === status || tenant.status === inProcess
      );
    } else {
      return candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) => tenant.status === status
      );
    }
  }

  checkWithOutChecking(candidature: Candidature): boolean {
    if (
      candidature.candidatureStatusEnum ===
      CandidatureStatusEnum.WITHOUT_CHECKING
    ) {
      return false;
    } else {
      return true;
    }
  }

  navigateToTenantFromOwnerView(tenant: User, candidature: Candidature): void {
    if (this.authenticationService.user?.trialVersion) {
      this.candidatureService.selectedCandidature = candidature;
      this.router.navigate([this.router.url + '/documents/' + tenant.id]);
    } else {
      window.location.href =
        'tenant/null/home?idContact=' + tenant.id + '&fromHomeowner=true';
    }
  }

  showSeeDocumentationBtn(candidature: Candidature, user: User): boolean {
    return (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PRE_SELECT &&
      !user.trialVersion
    );
  }

  checkTenantRejected(tenant: TenantCandidature): boolean {
    if (
      tenant &&
      tenant.user &&
      tenant.user.id &&
      tenant.userCandidatureStatusDtoEnum === 'REJECT'
    ) {
      return true;
    } else {
      return false;
    }
  }

  showRelaunchRequestanalysis(candidature: Candidature, user: User): boolean {
    return candidature?.score !== null && !user.blockShowAnalysisReport;
  }

  hiddenRelaunchRequestAnalysis(candidature: Candidature): boolean {
    return (
      !(candidature.tenantCandidatureList.length > 0) ||
      this.checkAllUserAreStudent(candidature) ||
      this.checkBusinessTenantNotAlone(candidature) ||
      this.isInAnalysis(candidature)
    );
  }

  isInAnalysis(candidature: Candidature): boolean {
    return this.checkTenantsStatus(
      candidature,
      UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
      UserAnalysisStatusEnum.REPORTED_ANALYSIS
    );
  }

  checkAllUserAreStudent(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.every(
      (tenant: TenantCandidature) => tenant.user.student === true
    );
  }

  checkBusinessTenantNotAlone(candidature: Candidature): boolean {
    return (
      candidature.tenantCandidatureList?.length > 1 &&
      candidature.tenantCandidatureList.some(
        (tenant: TenantCandidature) => tenant.user.business
      )
    );
  }

  requestAnalysis(
    candidature: Candidature,
    user: User,
    asset: AssetDto,
    reLaunched?: boolean
  ): void {
    this.setSavingAnalysisObs(true);
    if (user.bigTenant) {
      // For Bigtenants
      if (asset.reports.requested >= user.session.maxReportsByAssetMonth) {
        this.presentModalMaxReportByAssetReached(user);
      } else {
        this.requestMutipleAnalysis(candidature, reLaunched);
      }
    } else {
      // For APIs
      if (user.reports.requested >= user.session.maxReportsMonth) {
        this.presentStripeModal(candidature, user);
      } else {
        this.requestMutipleAnalysis(candidature, reLaunched);
      }
    }
  }

  async candidatureRejectedAlert(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.rejected_by_tenant'
      ) as string,
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  async launchViewDocuments(tenant: TenantCandidature): Promise<void> {
    return new Promise(
      (resolve: (value: void) => void, reject: (value: void) => void) => {
        this.docService
          .getDocuments('20', tenant?.user?.id)
          .pipe(first())
          .subscribe({
            next: (documents: DocumentDTO[]) => {
              this.viewDocumentsFull(documents);
              resolve();
            },
            error: () => {
              presentToast(
                this.toastController,
                // eslint-disable-next-line max-len
                'Ha ocurrido un error, inténtelo de nuevo. Si el error persiste, contacte con el administrador.',
                'danger'
              );
              reject();
            }
          });
      }
    );
  }

  async presentInfoNoValidated(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_no_validated.message'
      ) as string,
      buttons: [
        this.translate.instant(
          'pages.profile.home-owner.assets.candidature.info_rating.button'
        ) as string
      ]
    });
    await alert.present();
  }

  async presentInfoRating(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_rating.header'
      ) as string,
      subHeader: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_rating.subheader'
      ) as string,
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.info_rating.message'
      ) as string,
      buttons: [
        this.translate.instant(
          'pages.profile.home-owner.assets.candidature.info_rating.button'
        ) as string
      ]
    });
    await alert.present();
  }

  async seeLastPayslip(ev: string): Promise<void> {
    const amount = Number(ev);
    const stringAmount = this.numberPipe.transform(amount, '1.0-0');
    const msg: string = this.translate.instant(
      'pages.profile.home-owner.assets.candidature.last_payroll'
    ) as string;
    const msg2: string = this.translate.instant(
      'pages.profile.home-owner.assets.candidature.money_month',
      { amount: stringAmount }
    ) as string;

    const alert = await this.alertController.create({
      message: `<b>${msg}</b><br /><span>${msg2}</span>`,
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  async viewDocuments(
    documents: DocumentDTO[],
    exists: boolean
  ): Promise<void> {
    if (exists) {
      this.loadingService.presentSecondLoader(null, true);
      const modal = await this.modalController.create({
        component: MultipleDocumentModalComponent,
        cssClass: 'custom-modal-xl modal-extend',
        componentProps: {
          documentsData: documents
        }
      });
      return await modal.present();
    }
  }

  async showIncidences(tenant: TenantCandidature): Promise<boolean> {
    return new Promise(async (resolve: (value: boolean) => void) => {
      const incidenceModal = await this.modalController.create({
        component: IncidencesModalComponent,
        cssClass: 'auto-height modal-extend',
        componentProps: {
          incidenceList: filterIncidences(tenant.incidences),
          user: this.authenticationService.user,
          analysisId: tenant.requestAnalysisId
        }
      });
      incidenceModal
        .onDidDismiss()
        .then((close: OverlayEventDetail<GeneralModalResponse>) => {
          if (close.data.saving) {
            resolve(true);
          }
          resolve(false);
        });
      await incidenceModal.present();
    });
  }

  async previewReport(candidature: Candidature): Promise<void> {
    await this.loadingService.presentSecondLoader(null, true);

    // Preparamos la llamada para obtener los informes
    const calls: Observable<CreateAnalysisDto | AnalysisCatalogue>[] = [];

    candidature.tenantCandidatureList.forEach((tc: TenantCandidature) => {
      calls.push(
        this.userService.getCompletedAnalysis(candidature.id, tc.user.id)
      );
    });

    // Añadimos la llamada para obtener el catálogo del análisis
    calls.push(this.userService.getCatalogue());

    forkJoin(calls)
      .pipe(first())
      .subscribe({
        next: async (resp: unknown[]) => {
          // Recuperamos la información del catálogo y la eliminamos del array
          const catalogue: AnalysisCatalogue = resp.pop() as AnalysisCatalogue;

          await this.loadingService.dismissSecondLoader();
          const modal = await this.modalController.create({
            component: PreviewReportComponent,
            cssClass: 'preview-report',
            componentProps: {
              reports: resp as CreateAnalysisDto[],
              candidature,
              catalogue
            }
          });
          await modal.present();

          const dismissEvent = await modal.onWillDismiss();
          if (dismissEvent.role === 'download') {
            // this.incofisaReportPdfService.getReport(
            //   candidature,
            //   resp as CreateAnalysisDto[],
            //   catalogue,
            //   this.canvasElement
            // );
            await this.loadingService.presentSecondLoader(null);
            this.userService
              .createReport(candidature.id)
              .pipe(first())
              .subscribe({
                next: async (blob: Blob) => {
                  await this.loadingService.dismissSecondLoader();
                  const url = window.URL.createObjectURL(blob);
                  const pwa = window.open(url);
                  if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
                    alert(
                      'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
                    );
                  }
                }
              });
          }
        },
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  prepareMinDocForTenant(
    tenant: TenantCandidature,
    score: number
  ): CheckMinimumDoc {
    if (score && score > 0) {
      tenant.hasPayslip = true;
    }
    return {
      student: tenant?.user?.student || false,
      freelance: tenant?.user?.freelance || false,
      retired: tenant?.user?.retired || false,
      business: tenant?.user?.business || false,
      hasDni: tenant?.hasDni || false,
      hasRent: tenant?.hasRent || false,
      hasModel130: tenant?.hasModel130 || false,
      hasIvaQuarterModel: tenant?.hasIvaQuarterModel || false,
      hasPensionerRecognition: tenant?.hasPensionerRecognition || false,
      hasIs200Model: tenant?.hasIs200Model || false,
      hasPayslips: tenant?.hasPayslip || false,
      hasSSCertificate: tenant?.hasSSCertificate || false,
      hasAEATCertificate: tenant?.hasAEATCertificate || false,
      hasPYGQuarter: tenant?.hasPYGQuarter || false,
      hasWorkContract: tenant?.hasWorkContract || false,
      hasBankStatement: tenant?.hasBankStatement || false,
      hasRemoteJobCertificate: tenant?.hasRemoteJobCertificate || false,
      hasWorkLife: tenant?.hasWorkLife || false,
      hasInvoiceRecordBook: tenant?.hasInvoiceRecordBook || false,
      hasInvoicePaymentConfirmation:
        tenant?.hasInvoicePaymentConfirmation || false
    };
  }

  private async viewDocumentsFull(documents: DocumentDTO[]): Promise<void> {
    this.loadingService.presentSecondLoader(null, true);
    const modal = await this.modalController.create({
      component: MultipleDocumentModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        documentsData: documents,
        source: 'selectedCandidature'
      }
    });
    return await modal.present();
  }

  /**
   * Comprueba si el tenant tiene toda la documentación mínima
   * necesaria por su rol
   * @param tenant
   * @returns boolean
   */
  private checkTenantsDocumentation(tenant: TenantCandidature): boolean {
    const minDoc: CheckMinimumDoc = {
      student: tenant?.user?.student || false,
      freelance: tenant?.user?.freelance || false,
      retired: tenant?.user?.retired || false,
      business: tenant?.user?.business || false,
      hasDni: tenant?.hasDni || false,
      hasRent: tenant?.hasRent || false,
      hasModel130: tenant?.hasModel130 || false,
      hasIvaQuarterModel: tenant?.hasIvaQuarterModel || false,
      hasPensionerRecognition: tenant?.hasPensionerRecognition || false,
      hasIs200Model: tenant?.hasIs200Model || false,
      hasPayslips: tenant?.hasPayslip || false,
      hasSSCertificate: tenant?.hasSSCertificate || false,
      hasAEATCertificate: tenant?.hasAEATCertificate || false,
      hasPYGQuarter: tenant?.hasPYGQuarter || false,
      hasWorkContract: tenant?.hasWorkContract || false,
      hasBankStatement: tenant?.hasBankStatement || false,
      hasRemoteJobCertificate: tenant?.hasRemoteJobCertificate || false,
      hasWorkLife: tenant?.hasWorkLife || false,
      hasInvoiceRecordBook: tenant?.hasInvoiceRecordBook || false,
      hasInvoicePaymentConfirmation:
        tenant?.hasInvoicePaymentConfirmation || false
    };
    return this.utilsService.checkMinimumDocForTenantRol(minDoc, tenant.user);
  }

  private checkAllUserHaveAnalysis(
    candidature: Candidature,
    inProcess: UserAnalysisStatusEnum
  ): boolean {
    return candidature.tenantCandidatureList.every(
      (tenant: TenantCandidature) => tenant.status === inProcess
    );
  }

  private calculateTenantIsStudentHasGuarantor(
    candidature: Candidature,
    tenantCandidatureListWithoutStudent: TenantCandidature[]
  ): boolean {
    return (
      candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) => tenant.hasDni
      ) &&
      tenantCandidatureListWithoutStudent.length > 0 &&
      this.checkAllTenantDocumentAreReady(
        tenantCandidatureListWithoutStudent,
        candidature?.score
      )
    );
  }

  private checkAllTenantDocumentAreReady(
    tenantCandidatureList: TenantCandidature[],
    score: number
  ): boolean {
    return tenantCandidatureList.every((tenant: TenantCandidature) => {
      const minDoc = this.prepareMinDocForTenant(tenant, score);
      return this.utilsService.checkMinimumDocForTenantRol(minDoc, tenant.user);
    });
  }

  private async presentModalMaxReportByAssetReached(user: User): Promise<void> {
    this.setSavingAnalysisObs(false);
    if (user?.bigTenant) {
      // Un bigtenant tiene un máximo de reports por asset
      const alert = await this.alertController.create({
        cssClass: 'custom-alert',
        header: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.max_reports_reached.header'
        ) as string,
        message: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.max_reports_reached.message'
        ) as string,
        buttons: [
          {
            text: this.translate.instant(
              'pages.profile.home-owner.assets.candidature.max_reports_reached.accept'
            ) as string,
            role: 'cancel',
            cssClass: 'secondary'
          }
        ]
      });
      await alert.present();
    }
  }

  private requestMutipleAnalysis(
    candidature: Candidature,
    reLaunched?: boolean
  ): void {
    this.candidatureService
      .requestMutipleAnalysis(candidature, reLaunched)
      .pipe(first())
      .subscribe(() => {
        this.setSavingAnalysisObs(false);
        this.setGetCandidaturesObs();
      });
  }

  private async presentStripeModal(
    candidature: Candidature,
    user: User
  ): Promise<void> {
    this.setSavingAnalysisObs(false);
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.stripe_modal.header'
      ) as string,
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.stripe_modal.message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.stripe_modal.cancel'
          ) as string,
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.stripe_modal.request'
          ) as string,
          cssClass: 'danger',
          handler: (): void => {
            this.candidatureService
              .requestMutipleAnalysis(candidature) //aki?
              .pipe(first())
              .subscribe(() => {
                this.createStripeExtraSession(user, candidature);
              });
          }
        }
      ]
    });
    await alert.present();
  }

  private createStripeExtraSession(user: User, candidature: Candidature): void {
    this.userService
      .createStripeExtraSession(
        user.id,
        user.email,
        user.session.id,
        this.userService.getPriceExtra(user.session.planCode),
        this.userService.createRequestAnalysisSuccessUrl(user.id, candidature)
      )
      .pipe(first())
      .subscribe(async (data: StripeSession) => {
        const stripe = await loadStripe(environment.stripe_key);
        // Call Stripe.js method to redirect to the new Checkout page
        stripe
          .redirectToCheckout({
            sessionId: data.id
          })
          .then((result: EBIStripeError) => this.handleResult(result));
      });
  }

  private handleResult(result: EBIStripeError): void {
    if (result.error) {
      // showErrorMessage(result.error.message);
    }
  }
}
