/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';
import {
  MessageClasses,
  SinisterThread,
  TypeUserEnum,
  User
} from '@core/models';

import { SinisterMessageClassPipeArgs } from '../models/sinister-message-pipe.model';

@Pipe({
  name: 'sinisterMessageClass'
})
export class SinisterMessageClassPipe implements PipeTransform {
  private user: User;
  private users: User[];
  private sinisterUserId: string;

  transform(
    message: SinisterThread,
    { user, users, sinisterUserId }: SinisterMessageClassPipeArgs
  ): MessageClasses {
    this.user = user;
    this.users = users;
    this.sinisterUserId = sinisterUserId;

    return this.getMessageClasses(message);
  }

  private getMessageClasses(message: SinisterThread): MessageClasses {
    const isOwnMessage = this.isOwnMessage(message);
    let isEbiComment = false;
    let isClientComment = false;
    const messageUser = this.getMessageUser(message);

    if (messageUser.userType === TypeUserEnum.INCOFISA_ADMINISTRATIVE) {
      isEbiComment = true;
    } else {
      isClientComment = true;
    }

    return {
      'right-comment': isOwnMessage,
      'left-comment': !isOwnMessage,
      'sinister-ebi-comment': isEbiComment,
      'sinister-client-comment': isClientComment
    };
  }

  private getMessageUser(message: SinisterThread): User {
    return this.users.find((user: User) => user.id === message.userId);
  }

  private isOwnMessage(thread: SinisterThread): boolean {
    if (this.user.id === thread.userId) {
      return true;
    } else {
      if (this.user.userType === TypeUserEnum.HOMEOWNER) {
        return false;
      } else if (this.user.userType === TypeUserEnum.INCOFISA_ADMINISTRATIVE) {
        if (this.threadUserIsIncofisaAdministrative(thread.userId)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  private threadUserIsIncofisaAdministrative(userId: string): boolean {
    return (
      this.users.find((user: User) => user.id === userId)?.userType ===
      TypeUserEnum.INCOFISA_ADMINISTRATIVE
    );
  }
}
