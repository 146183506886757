export class PreferenceDTO {
  id: string;
  name: string;
  elements: PreferenceValueDTO[];
}

export class PreferenceValueDTO {
  id: string;
  name: string;
  checked = false;
}
