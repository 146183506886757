import { TypeUserEnum, User } from './users.model';

export class CreateRecommendation {
  userTargetCode: string;
  date: Date;
  text: string;
  address: string;
  score: number;
}

export class Recommendation {
  userSource: User;
  userTarget: User;
  date: Date;
  text: string;
  address: string;
  score: number;
  name: string;
  type: string;

  isNeighbour(): boolean {
    return this.userSource.userType !== TypeUserEnum.HOMEOWNER;
  }

  isHomeOwner(): boolean {
    return this.userSource.userType === TypeUserEnum.HOMEOWNER;
  }
}

export enum RecommendationTypeEnum {
  TENANT = 'TENANT',
  HOMEOWNER = 'HOMEOWNER',
  OTHER = 'OTHER'
}

export interface BaseRecommendation {
  address: string;
  date: Date; // Año de recomendación
  score: number;
  text: string;
  userTargetCode: string;
}

export interface AnnonRecommendation extends BaseRecommendation {
  firstName?: string;
  surname1?: string;
  surname2?: string;
  userType: RecommendationTypeEnum;
}

export interface ReqRecommendation {
  email: string;
  emailSent: boolean;
  id: string;
  userId: string;
  name: string;
  surname1: string;
  surname2?: string;
}
