import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { NavigationService } from '../../services/navigation/navigation.service';

@Injectable()
export class MaintenanceGuard {
  constructor(private navigationService: NavigationService) {}

  canLoad(): boolean {
    if (!environment.maintenance) {
      this.navigationService.goLanding();
      return false;
    }
    return true;
  }
}
