/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { ChartOptions } from 'chart.js';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const ACTIVE_RENTS_OPTIONS: ChartOptions<'doughnut'> = {
  responsive: true,
  animation: false,
  aspectRatio: 3,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  }
};

export const CANDIDATURES_WITH_GUARANTOR_OPTIONS: ChartOptions<'doughnut'> = {
  responsive: true,
  animation: false,
  aspectRatio: 1.5,
  plugins: {
    legend: {
      display: true
    },
    title: {
      display: false
    }
  }
};

export const ACTIVE_RENTS_DATA = {
  labels: ['Rentas activadas', 'Rentas no activadas'],
  datasets: {
    backgroundColor: ['#EFA549', '#DDDDDD'],
    hoverBackgroundColor: ['#EFA549', '#DDDDDD'],
    hoverBorderColor: ['#EFA549', '#DDDDDD']
  }
};

export const CANDIDATURES_WITH_GUARANTOR_DATA = {
  labels: ['Con avalista', 'Sin avalista'],
  datasets: {
    backgroundColor: ['#5dc988', '#DDDDDD'],
    hoverBackgroundColor: ['#5dc988', '#DDDDDD'],
    hoverBorderColor: ['#5dc988', '#DDDDDD']
  }
};

export const TENANTS_CANDIDATURE_OPTIONS: ChartOptions<'bar'> = {
  responsive: true,
  animation: false,
  aspectRatio: 1.5,
  elements: {
    bar: {
      borderRadius: 30
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      display: false
    }
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title: function (value): string {
          const [info] = value;
          const { data } = info.dataset;
          const { raw } = info;
          const sum = data.reduce((acc: number, curr: number | number[]) => {
            if (Array.isArray(curr)) {
              // Si el elemento actual es un array, sumamos sus elementos
              return (
                acc + curr.reduce((subAcc, subCurr) => subAcc + subCurr, 0)
              );
            } else {
              // Si no es un array, lo sumamos directamente
              return acc + curr;
            }
          }, 0);

          const result = ((Number(raw) / Number(sum)) * 100).toFixed(1);
          return `${result} %`;
        },
        label: function (): null {
          return null;
        }
      }
    }
  }
};

export const TENANTS_CANDIDATURE_DATA = {
  datasets: {
    backgroundColor: ['#dddddd', '#dddddd', '#dddddd', '#dddddd'],
    hoverBackgroundColor: ['#dddddd', '#dddddd', '#dddddd', '#dddddd']
  }
};

export const SCORING_OPTIONS: ChartOptions<'pie'> = {
  responsive: true,
  animation: false,
  aspectRatio: 1.5,
  plugins: {
    legend: {
      display: true
    }
  }
};

export const SCORING_DATA = {
  datasets: {
    backgroundColor: [
      '#005CAB',
      '#316DB0',
      '#418BE0',
      '#599DF4',
      '#ADE7FF',
      '#ECECEC'
    ],
    hoverBackgroundColor: [
      '#005CAB',
      '#316DB0',
      '#418BE0',
      '#599DF4',
      '#ADE7FF',
      '#ECECEC'
    ],
    hoverBorderColor: [
      '#005CAB',
      '#316DB0',
      '#418BE0',
      '#599DF4',
      '#ADE7FF',
      '#ECECEC'
    ]
  }
};
