/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit } from '@angular/core';
import { IncidencesApiService } from '@app/modules/core/api-services/incidences-api/incidences-api.service';
import { Candidature, Incidence, User } from '@app/modules/core/models';
import { first } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-selected-candidature-incidences',
  templateUrl: './selected-candidature-incidences.component.html',
  styleUrls: ['./selected-candidature-incidences.component.scss']
})
export class SelectedCandidatureIncidencesComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() user: User;

  incidences: Incidence[] = [];
  loading = true;

  constructor(private incidenceService: IncidencesApiService) {}

  ngOnInit(): void {
    this.getIncidencesByCandidature();
  }

  forceRefreshIncidences($event: any): void {
    this.loading = true;
    this.getIncidencesByCandidature();
  }

  private getIncidencesByCandidature(): void {
    this.incidenceService
      .getIncidencesByCandidature(this.candidature.id)
      .pipe(first())
      .subscribe({
        next: (response: Incidence[]) => {
          this.loading = false;
          this.incidences = response;
        },
        error: () => {}
      });
  }
}
