/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import {
  AssetDto,
  BuildingDto,
  ContactAssetDto,
  User,
  UserRol
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { FiltersService } from '@core/services/filters/filters.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { copyObject, tableSize } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-step-one',
  templateUrl: './step-one.component.html'
})
export class StepOneComponent implements OnInit, OnDestroy {
  @Input() building: BuildingDto;
  @Input() user: User;
  @Input() isMovingCandidature = false;
  @Input() assetId: string;

  assetSelected: ContactAssetDto = new ContactAssetDto();
  assets: AssetDto[] = [];
  originalAssets: AssetDto[] = [];
  screenWidth: number;
  // TABLA
  columns: string[] = ['address', 'portal', 'floor', 'door', 'city'];
  dataSource = new MatTableDataSource<AssetDto>([]);
  @ViewChild('paginator') paginator: MatPaginator;
  tableSize = tableSize;
  // END TABLA

  getItemsSub: Subscription;

  @Output() selectedAsset = new EventEmitter();

  constructor(
    private authenticationService: AuthService,
    private assetService: AssetsApiService,
    private filtersService: FiltersService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    if (this.building) {
      this.assetService
        .getAssetsByBuildingId(this.building.id)
        .pipe(first())
        .subscribe({
          next: (assets: AssetDto[]) => {
            let filteredAssets: AssetDto[];
            if (this.user.apiId) {
              filteredAssets = assets.filter(
                (a) =>
                  a.status === 'OPEN' &&
                  a.userId !== 'null' &&
                  a.apiId === this.user.apiId
              );
            } else {
              filteredAssets = assets.filter(
                (a) => a.status === 'OPEN' && a.userId !== 'null'
              );
            }

            // Si es reubicación eliminamos el asset origen
            if (this.isMovingCandidature) {
              filteredAssets = filteredAssets.filter(
                (a) => a.id !== this.assetId
              );
            }
            this.originalAssets = filteredAssets;

            //Se ordenan los assets por portal, planta y letra
            this.originalAssets = this.utilsService.orderAssets(
              this.originalAssets
            );
            this.filtersService.setOriginalItems(this.originalAssets);
          },
          error: (err) => console.error(err)
        });
    } else {
      let portfolioId = null;
      let apiId = null;
      if (this.user.portfolioId) {
        portfolioId = this.user.portfolioId;
      }
      if (this.user.apiRol && this.user.apiRol === UserRol.API_ADMIN) {
        apiId = this.user.apiId;
      }
      this.assetService
        .getAssets(
          this.authenticationService.user.id,
          'OPEN',
          null,
          portfolioId,
          apiId
        )
        .pipe(first())
        .subscribe({
          next: (assets: AssetDto[]) => {
            let filteredAssets = assets.filter((a) => a.street && a.town);
            // Si es reubicación eliminamos el asset origen
            if (this.isMovingCandidature) {
              filteredAssets = filteredAssets.filter(
                (a) => a.id !== this.assetId
              );
            }
            //Se ordenan los assets por calle, portal, planta y letra
            this.originalAssets = filteredAssets;
            this.originalAssets = this.utilsService.orderAssets(
              this.originalAssets
            );
            this.filtersService.setOriginalItems(this.originalAssets);
          }
        });
    }

    this.getItemsSub = this.filtersService
      .getItems()
      .subscribe((resp: AssetDto[]) => {
        this.assets = resp;
        this.setDataSource();
      });
  }
  setDataSource(): void {
    this.dataSource = new MatTableDataSource(
      copyObject(this.assets) as AssetDto[]
    );
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.getItemsSub?.unsubscribe();
  }

  selectItem(selectedAsset): void {
    this.assetSelected = selectedAsset;
    this.selectedAsset.emit(selectedAsset);
  }
}
