/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  Auth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential
} from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UserDto } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { WindowService } from '@core/services/window/window.service';
// TODO: import { IonIntlTelInputValidators } from '@crodriguezdominguez/ion-intl-tel-input';
import { IonItem, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';

import { CodeForm, PhoneForm } from '../../models/phone-form.model';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';

@Component({
  selector: 'el-buen-inquilino-phone-register',
  templateUrl: './phone-register.component.html'
})
export class PhoneRegisterComponent implements OnInit, AfterViewInit {
  @Input() modalMode = false;
  @Input() user: User;
  @Output() gobackLoginType = new EventEmitter();
  phoneForm: FormGroup<PhoneForm>;
  codeForm: FormGroup<CodeForm>;
  windowRef: any;
  validationErrorMessages = appValidators.validationErrorMessages;
  phone: string;
  blockCodeNoShow = true;
  acceptProfileStudy = false;
  acceptConditionsChecked = false;

  @ViewChild('phoneItem') phoneItem: IonItem;
  @ViewChild('phoneInput') phoneInput: any;

  get phoneControl(): FormControl<string> {
    return this.phoneForm.controls.phone;
  }

  get codeControl(): FormControl<string> {
    return this.codeForm.controls.code;
  }

  constructor(
    private authService: AuthService,
    private win: WindowService,
    private modalController: ModalController,
    private toastController: ToastController,
    private translateService: TranslateService,
    private auth: Auth,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'sign-in-button',
      {
        size: 'invisible'
      }
    );
    if (this.user && this.user.phone) {
      this.phone = this.utilsService.formatPhone(this.user.phone);
    }
    this.createPhoneForm();
    this.createCodeForm();
  }

  ngAfterViewInit(): void {
    if (this.phoneItem && this.phoneInput) {
      this.phoneForm.get('phone').markAsPristine();
      this.phoneInput['el'].nativeElement.classList.remove(
        'ion-invalid',
        'ion-dirty',
        'ng-dirty',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.remove('ion-invalid', 'ion-dirty');
    }
  }

  goBackPhoneNumber(): void {
    this.blockCodeNoShow = true;
  }

  checkPhoneInputStatus(codeSelect: boolean = false): void {
    const input = this.phoneForm.get('phone');

    if (input.invalid && input.errors && (input.dirty || codeSelect)) {
      this.phoneInput['el'].nativeElement.classList.add(
        'ion-invalid',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.add('ion-invalid');
    } else {
      this.phoneInput['el'].nativeElement.classList.remove(
        'ion-invalid',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.remove('ion-invalid');
    }
  }

  logIn(): void {
    if (this.phoneForm.status === 'INVALID') return;
    const appVerifier = this.windowRef.recaptchaVerifier;
    const formatedPhoneNumber = this.utilsService.formatPhone(
      this.phoneForm.value.phone
    );
    this.blockCodeNoShow = false;
    this.authService
      .phoneLogin(formatedPhoneNumber, appVerifier)
      .then((res) => {
        this.windowRef.confirmationResult = res;
        this.presentToast(
          this.translateService.instant('pages.login.data.signin.code_sent'),
          true
        );
      })
      .catch((err) => {
        this.blockCodeNoShow = true;
        if (err?.code === 'auth/too-many-requests') {
          this.presentToast(
            this.translateService.instant(
              'pages.login.data.signin.too-many-attemps-try-later'
            ),
            false
          );
        } else {
          this.presentToast(
            this.translateService.instant(
              'pages.login.data.signin.phone_not_valid'
            ),
            false
          );
        }
      });
  }

  verifyCode(): void {
    const code: string = this.codeForm.value.code;
    const credentials = PhoneAuthProvider.credential(
      this.windowRef.confirmationResult.verificationId,
      code
    );

    signInWithCredential(this.auth, credentials)
      .then((p) => {
        if (this.modalMode) {
          this.dismiss();
        }
        this.authService.processAlternativeTokenLogin(
          p,
          'phone',
          this.user as unknown as UserDto
        );
      })
      .catch(() => {
        this.presentToast(
          this.translateService.instant('pages.login.data.signin.code_expired'),
          false
        );
        this.dismiss();
      });
  }

  async presentToast(messages: any, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'success' : 'danger',
      duration: 5000
    });
    toast.present();
  }

  isAcceptConditions(): boolean {
    if (this.modalMode) {
      return false;
    }
    return !(this.acceptConditionsChecked && this.acceptProfileStudy);
  }

  async presentModalConditions(type: number): Promise<void> {
    let privacy = false;
    let profilePrivacy = false;

    if (type === 1) {
      privacy = true;
    } else {
      profilePrivacy = true;
    }

    const modal = await this.modalController.create({
      component: TermsOfUseComponent,
      cssClass: 'terms-class',
      componentProps: {
        privacy,
        profilePrivacy
      }
    });
    modal.onDidDismiss().then((data) => {
      // this.acceptConditionsChecked = data?.data?.accept;
      if (data?.data) {
        if (data?.data.accept) {
          if (type === 1) {
            this.acceptConditionsChecked = true;
          } else {
            this.acceptProfileStudy = true;
          }
        } else {
          if (type === 1) {
            this.acceptConditionsChecked = false;
          } else {
            this.acceptProfileStudy = false;
          }
        }
      }
    });
    return await modal.present();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  goBack(): void {
    this.gobackLoginType.emit();
  }

  private createPhoneForm(): void {
    this.phoneForm = new FormGroup({
      phone: new FormControl<string>(
        this.phone ? this.utilsService.formatPhone(this.user.phone) : null,
        [
          Validators.required // TODO: IonIntlTelInputValidators.phone
        ]
      )
    });
  }

  private createCodeForm(): void {
    this.codeForm = new FormGroup({
      code: new FormControl<string>(null)
    });
  }
}
