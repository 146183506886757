import { Component, Input } from '@angular/core';
import {
    TemplatesDto,
} from '@core/models';
import {
  ModalController,
} from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-modal-reason-template-component',
  templateUrl: './modal-reason-template.component.html'
})
export class ModalReasonTemplateComponent {
  @Input() templateDoc: TemplatesDto;


  constructor(
    private modalController: ModalController,
  ) {}

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

}