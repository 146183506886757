import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataSignIn, OccupationEnum, TypeUserEnum, User } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { UtilsService } from '@core/services/utils/utils.service';
// TODO: import { IonIntlTelInputValidators } from '@crodriguezdominguez/ion-intl-tel-input';
import {
  IonItem,
  ModalController,
  SelectCustomEvent,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';

import { EmailRegisterForm } from '../../models/email-register.model';
import { TermsOfUseResponseData } from '../../models/terms-of-use.model';
import { presentToast } from '../../utils/toast.utils';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';

@Component({
  selector: 'el-buen-inquilino-email-register',
  templateUrl: './email-register.component.html'
})
export class EmailRegisterComponent implements OnInit, AfterViewInit {
  @Output() register = new EventEmitter<DataSignIn>();
  @Output() clickLogin = new EventEmitter();
  @Output() gobackLoginType = new EventEmitter();
  @Input() typeUser: TypeUserEnum;
  @Input() typeUserRadio: string;
  @Input() user: User;
  mailRegisterForm: FormGroup<EmailRegisterForm>;
  occupationEnum = OccupationEnum;
  validationErrorMessages = appValidators.validationErrorMessages;
  screenWidth = 0;
  slideOpts = {
    slidesPerView: 2.5,
    spaceBetween: 15,
    initialSlide: 0,
    speed: 400
  };
  activeOption: number;
  documentOption = '1';
  dniPlaceholder: string;

  @ViewChild('phoneItem') phoneItem: IonItem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('phoneInput') phoneInput: any;

  maxBirthdate: Date;
  birthdateError = false;

  get typeUserRadioControl(): FormControl<string> {
    return this.mailRegisterForm.controls.typeUserRadio;
  }
  get occupationControl(): FormControl<string> {
    return this.mailRegisterForm.controls.occupation;
  }
  get firstnameControl(): FormControl<string> {
    return this.mailRegisterForm.controls.firstname;
  }
  get surname1Control(): FormControl<string> {
    return this.mailRegisterForm.controls.surname1;
  }
  get surname2Control(): FormControl<string> {
    return this.mailRegisterForm.controls.surname2;
  }
  get dniControl(): FormControl<string> {
    return this.mailRegisterForm.controls.dni;
  }
  get emailControl(): FormControl<string> {
    return this.mailRegisterForm.controls.email;
  }
  get birthDateControl(): FormControl<Date> {
    return this.mailRegisterForm.controls.birthDate;
  }
  get phoneControl(): FormControl<string> {
    return this.mailRegisterForm.controls.phone;
  }
  get streetControl(): FormControl<string> {
    return this.mailRegisterForm.controls.street;
  }
  get numberControl(): FormControl<string> {
    return this.mailRegisterForm.controls.number;
  }
  get postalCodeControl(): FormControl<string> {
    return this.mailRegisterForm.controls.postalCode;
  }
  get cityControl(): FormControl<string> {
    return this.mailRegisterForm.controls.city;
  }
  get businessNameControl(): FormControl<string> {
    return this.mailRegisterForm.controls.businessName;
  }
  get cifControl(): FormControl<string> {
    return this.mailRegisterForm.controls.cif;
  }
  get taxResidenceControl(): FormControl<string> {
    return this.mailRegisterForm.controls.taxResidence;
  }
  get passwordControl(): FormControl<string> {
    return this.mailRegisterForm.controls.password;
  }
  get confirmPasswordControl(): FormControl<string> {
    return this.mailRegisterForm.controls.confirmPassword;
  }
  get acceptProfileStudyControl(): FormControl<boolean> {
    return this.mailRegisterForm.controls.acceptProfileStudy;
  }
  get acceptConditionsCheckedControl(): FormControl<boolean> {
    return this.mailRegisterForm.controls.acceptConditionsChecked;
  }

  constructor(
    private toastController: ToastController,
    private modalController: ModalController,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {
    this.maxBirthdate = this.utilsService.calculate18YearsAgoStringDate();
    this.screenWidth = window.innerWidth;
    this.createMailRegisterForm();
    this.documentChange(null);
  }

  ngAfterViewInit(): void {
    if (this.phoneItem && this.phoneInput) {
      this.mailRegisterForm.get('phone').markAsPristine();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.phoneInput['el'].nativeElement.classList.remove(
        'ion-invalid',
        'ion-dirty',
        'ng-dirty',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.remove('ion-invalid', 'ion-dirty');
    }
  }

  unknowTypeUser(): boolean {
    return this.typeUser === TypeUserEnum.ANONYMOUS;
  }

  documentChange(event: SelectCustomEvent): void {
    const value = (event?.detail?.value as string) || this.documentOption;
    this.documentOption = value;
    if (Number(value) === 1) {
      this.dniPlaceholder = this.translateService.instant(
        'pages.login.data.signin.dni-placeholder'
      ) as string;
      this.dniControl.removeValidators(appValidators.passportValidator);
      this.dniControl.addValidators(appValidators.dniAndNieValidator);
    } else {
      this.dniPlaceholder = this.translateService.instant(
        'pages.login.data.signin.email_signin_component.passport'
      ) as string;
      this.dniControl.removeValidators(appValidators.dniAndNieValidator);
      this.dniControl.addValidators(appValidators.passportValidator);
    }
    this.dniControl.updateValueAndValidity();
  }

  checkPhoneInputStatus(codeSelect: boolean = false): void {
    const input = this.mailRegisterForm.get('phone');

    if (input.invalid && input.errors && (input.dirty || codeSelect)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.phoneInput['el'].nativeElement.classList.add(
        'ion-invalid',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.add('ion-invalid');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.phoneInput['el'].nativeElement.classList.remove(
        'ion-invalid',
        'ng-invalid'
      );
      this.phoneItem['el'].classList.remove('ion-invalid');
    }
  }

  occupationChanged(event: SelectCustomEvent): void {
    if (
      (event.detail.value as string) === (OccupationEnum.BUSINESS as string)
    ) {
      this.validationsForBusinessRol(true);
    } else {
      this.validationsForBusinessRol(false);
    }
  }

  validationsForBusinessRol(businessIsActive: boolean): void {
    this.businessNameControl.setValidators(
      businessIsActive
        ? Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
            appValidators.complexNameValidator
          ])
        : null
    );
    this.businessNameControl.updateValueAndValidity();
    this.cifControl.setValidators(
      businessIsActive
        ? Validators.compose([Validators.required, appValidators.cifValidator])
        : null
    );
    this.cifControl.updateValueAndValidity();
    this.taxResidenceControl.setValidators(
      businessIsActive
        ? Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
            appValidators.addressValidator
          ])
        : null
    );
    this.taxResidenceControl.updateValueAndValidity();

    if (!businessIsActive) {
      this.mailRegisterForm.patchValue({
        businessName: '',
        cif: '',
        taxResidence: ''
      });
    }
  }

  clickRegister(): void {
    if (this.mailRegisterForm.get('birthDate').errors) {
      this.birthdateError = true;
    }

    if (!this.mailRegisterForm.value.acceptConditionsChecked) {
      presentToast(
        this.toastController,
        this.translateService.instant(
          'pages.login.data.signin.not-accept-conditions'
        ) as string,
        'danger'
      );
    } else if (!this.mailRegisterForm.value.acceptProfileStudy) {
      presentToast(
        this.toastController,
        this.translateService.instant(
          'pages.login.data.signin.not-accept-profile-study'
        ) as string,
        'danger'
      );
    } else if (this.mailRegisterForm.invalid) {
      // Marcamos los campos como dirty para que muestre los errores no visibles
      this.utilsService.showFormErrors(this.mailRegisterForm);

      this.checkPhoneInputStatus(true);
      presentToast(
        this.toastController,
        this.translateService.instant(
          'pages.login.data.signin.empty_field'
        ) as string,
        'danger'
      );

      return;
    } else if (this.mailRegisterForm.controls.occupation.errors) {
      presentToast(
        this.toastController,
        this.translateService.instant(
          'pages.login.data.signin.select_occupation'
        ) as string,
        'danger'
      );
    } else if (this.mailRegisterForm.errors?.notmatched) {
      presentToast(
        this.toastController,
        this.translateService.instant(
          'pages.login.data.signin.passwords-not-match'
        ) as string,
        'danger'
      );
      return;
    } else if (this.mailRegisterForm.status === 'INVALID') {
      presentToast(
        this.toastController,
        this.translateService.instant(
          'pages.login.data.signin.empty_field'
        ) as string,
        'danger'
      );
      return;
    } else {
      const {
        firstname,
        surname1,
        surname2,
        dni,
        email,
        birthDate,
        phone,
        street,
        number,
        postalCode,
        city,
        occupation,
        businessName,
        cif,
        taxResidence,
        password
      } = this.mailRegisterForm.value;
      this.user.guarantor = this.user.guarantor;
      this.user.firstname = firstname;
      this.user.surname1 = surname1;
      this.user.surname2 = surname2;
      this.user.dni = dni;
      this.user.email = email;
      this.user.birthDate = birthDate;
      this.user.phone = phone;
      this.user.street = street;
      this.user.number = Number(number);
      this.user.postalCode = postalCode;
      this.user.city = city;
      this.user.occupation = occupation;
      if (occupation === (OccupationEnum.BUSINESS as string)) {
        this.user.businessName = businessName;
        this.user.cif = cif;
        this.user.taxResidence = taxResidence;
      }
      this.user.password = password;

      let typeUserLogin: TypeUserEnum;
      if (this.typeUser !== TypeUserEnum.ANONYMOUS) {
        typeUserLogin = this.typeUser;
      } else if (this.typeUserRadio === 'TENANT') {
        typeUserLogin = TypeUserEnum.TENANT;
      } else if (this.typeUserRadio === 'HOMEOWNER') {
        typeUserLogin = TypeUserEnum.HOMEOWNER;
      }
      this.user.userType = typeUserLogin;
      this.user.email = this.user.email.toLowerCase();
      if (this.user.phone) {
        this.user.phone = this.utilsService.formatPhone(this.user.phone);
      }
      // Si se ha introducido un pasaporte
      if (Number(this.documentOption) === 2) {
        this.authenticationService.retryDNI = true;
      }
      this.register.emit(
        new DataSignIn(this.user, this.mailRegisterForm.value.password)
      );
    }
  }

  async presentModal(type: number): Promise<void> {
    let privacy = false;
    let profilePrivacy = false;

    if (type === 1) {
      privacy = true;
    } else {
      profilePrivacy = true;
    }

    const modal = await this.modalController.create({
      component: TermsOfUseComponent,
      cssClass: 'terms-class',
      componentProps: {
        privacy,
        profilePrivacy
      }
    });

    await modal.present();

    const { data }: OverlayEventDetail<TermsOfUseResponseData> =
      await modal.onWillDismiss();
    if (data) {
      if (data.accept) {
        if (type === 1) {
          this.acceptConditionsCheckedControl.setValue(true);
        } else {
          this.acceptProfileStudyControl.setValue(true);
        }
      } else {
        if (type === 1) {
          this.acceptConditionsCheckedControl.setValue(false);
        } else {
          this.acceptProfileStudyControl.setValue(false);
        }
      }
    }
  }

  goBack(): void {
    this.gobackLoginType.emit();
  }

  clickLoginEmitter(): void {
    this.clickLogin.emit();
  }

  clickSlide(value: OccupationEnum, index: number): void {
    this.activeOption = index;

    this.occupationControl.setValue(value);

    if (value === this.occupationEnum.BUSINESS) {
      this.validationsForBusinessRol(true);
    } else {
      this.validationsForBusinessRol(false);
    }
  }

  private createMailRegisterForm(): void {
    this.mailRegisterForm = new FormGroup<EmailRegisterForm>(
      {
        typeUserRadio: this.getTypeUserRadioControl(),
        occupation: this.getOccupationControl(),
        firstname: this.getFirstnameControl(),
        surname1: this.getSurname1Control(),
        surname2: this.getSurname2Control(),
        dni: this.getDniControl(),
        email: this.getEmailControl(),
        birthDate: this.getBirthDateControl(),
        phone: this.getPhoneControl(),
        street: this.getStreetControl(),
        number: this.getNumberControl(),
        postalCode: this.getPostalCodeControl(),
        city: this.getCityControl(),
        businessName: this.getBusinessNameControl(),
        cif: this.getCifControl(),
        taxResidence: this.getTaxResidenceControl(),
        password: this.getPasswordControl(),
        confirmPassword: this.getConfirmPasswordControl(),
        acceptProfileStudy: this.getAcceptProfileStudyControl(),
        acceptConditionsChecked: this.getAcceptConditionsCheckedControl()
      },
      { validators: appValidators.passwordMatchingValidator }
    );
  }

  private getTypeUserRadioControl(): FormControl<string> {
    return new FormControl<string>(this.typeUserRadio);
  }

  private getOccupationControl(): FormControl<string> {
    return new FormControl<string>(null, [Validators.required]);
  }

  private getFirstnameControl(): FormControl<string> {
    return new FormControl<string>(
      this.user?.firstname || '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(40),
        appValidators.namesValidator
      ])
    );
  }

  private getSurname1Control(): FormControl<string> {
    return new FormControl<string>(
      this.user?.surname1 || '',
      Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        appValidators.namesValidator
      ])
    );
  }

  private getSurname2Control(): FormControl<string> {
    return new FormControl<string>(this.user?.surname2 || '');
  }

  private getDniControl(): FormControl<string> {
    return new FormControl<string>('', [
      Validators.required,
      appValidators.dniAndNieValidator
    ]);
  }

  private getEmailControl(): FormControl<string> {
    return new FormControl<string>(
      this.user?.email || '',
      Validators.compose([Validators.required, appValidators.emailValidator])
    );
  }

  private getBirthDateControl(): FormControl<Date> {
    return new FormControl<Date>(
      this.user?.birthDate || null,
      Validators.required
    );
  }

  private getPhoneControl(): FormControl<string> {
    return new FormControl<string>(
      this.user?.phone || null,
      Validators.compose([
        Validators.required // TODO: IonIntlTelInputValidators.phone
      ])
    );
  }

  private getStreetControl(): FormControl<string> {
    return new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100)
    ]);
  }

  private getNumberControl(): FormControl<string> {
    return new FormControl<string>(null, [
      Validators.required,
      appValidators.onlyNumericValidator
    ]);
  }

  private getPostalCodeControl(): FormControl<string> {
    return new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        appValidators.postalCodeValidator,
        Validators.minLength(4),
        Validators.maxLength(5)
      ])
    );
  }

  private getCityControl(): FormControl<string> {
    return new FormControl<string>(null, [Validators.required]);
  }

  private getBusinessNameControl(): FormControl<string> {
    return new FormControl<string>(null);
  }

  private getCifControl(): FormControl<string> {
    return new FormControl<string>(null);
  }

  private getTaxResidenceControl(): FormControl<string> {
    return new FormControl<string>(null);
  }

  private getPasswordControl(): FormControl<string> {
    return new FormControl<string>(
      null,
      Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(55)
      ])
    );
  }

  private getConfirmPasswordControl(): FormControl<string> {
    return new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(55)
      ])
    );
  }

  private getAcceptProfileStudyControl(): FormControl<boolean> {
    return new FormControl<boolean>(false, Validators.requiredTrue);
  }

  private getAcceptConditionsCheckedControl(): FormControl<boolean> {
    return new FormControl<boolean>(false, Validators.requiredTrue);
  }
}
