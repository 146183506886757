import { Component, Input } from '@angular/core';
import { CotenantsArag, TenantCandidature } from '@core/models';

@Component({
  selector: 'el-buen-inquilino-tenant-list-popover',
  templateUrl: './tenant-list-popover.component.html'
})
export class TenantListPopoverComponent {
  @Input() list: CotenantsArag[];
  @Input() tenantsList: TenantCandidature[];
  @Input() isTenantsList = false;

  constructor() {}
}
