/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  private static loadingPromise: Promise<void> | null = null;

  load(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (GoogleMapsLoaderService.loadingPromise) {
      return GoogleMapsLoaderService.loadingPromise;
    }

    GoogleMapsLoaderService.loadingPromise = new Promise<void>(
      (resolve, reject) => {
        if ((window as any).google && (window as any).google.maps) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = `${environment.google_maps.url}${environment.firebaseConfig.apiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = (error) => reject(error);

        document.head.appendChild(script);
      }
    );

    return GoogleMapsLoaderService.loadingPromise;
  }
}
