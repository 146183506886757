/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-len */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  EmitterUserEmailInvitation,
  TypeUserEnum,
  User,
  ValidateEmailResponse
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { StepsService } from '@core/services/steps/steps.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-step-two',
  templateUrl: './step-two.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class StepTwoComponent {
  users$: Observable<User[]>;
  email = '';
  @Output() selectedUser = new EventEmitter<EmitterUserEmailInvitation>();
  userNotFound: boolean;
  showInput = true;
  shouldRemoveUser = false;
  invitationSended = false;
  cotenants: User[] = [];
  validateUserEmail: ValidateEmailResponse;

  @Output() resetCancelAlert = new EventEmitter<void>();
  @Input() set cancelAlertClicked(value: boolean) {
    // Si se ha pulsado en el boton cancelar del alert de correo electronico invalido
    if (value) {
      // Limpiamos los datos
      this.removeUser();

      // Se añade set time out para que no salte error de before checked
      setTimeout(() => {
        // Reestablecemos el valor
        this.resetCancelAlert.emit();
      });
    }
  }

  constructor(
    private usersService: UsersApiService,
    public stepsService: StepsService,
    private loadingService: LoadingService
  ) {}

  searchUser(): void {
    this.stepsService.invalidGuarantor = false;
    this.stepsService.invalidTenant = false;
    this.stepsService.invalidOwner = false;
    this.userNotFound = false;
    this.email = this.email.toLowerCase();
    this.loadingService.presentSecondLoader(null, false);
    this.users$ = this.usersService.getUserByEmail(this.email).pipe(
      tap(
        (user) => {
          if (user[0].guarantor) {
            this.stepsService.invalidGuarantor = true;
          } else if (user[0].userType === TypeUserEnum.HOMEOWNER) {
            this.stepsService.invalidOwner = true;
          } else {
            if (user[0].coTenants) {
              user[0].coTenants.forEach((cotenantId) => {
                this.usersService.getUser(cotenantId, true).then((cotenant) => {
                  this.cotenants.push(cotenant);
                });
              });
            }
            this.showInput = false;
            this.selectedUser.emit({ user: user[0], emailIsValid: true });
          }
          this.loadingService.dismissSecondLoader();
        },
        (error) => {
          if (error.error.code === '102') {
            // Si el usuario no existe, comprobamos que sea un email valido
            this.usersService
              .verifyUserEmail(this.email)
              .pipe(first())
              .subscribe({
                next: (response: ValidateEmailResponse) => {
                  this.validateUserEmail = response;

                  this.userNotFound = true;
                  this.showInput = false;
                  this.shouldRemoveUser = true;

                  this.loadingService.dismissSecondLoader();
                },
                error: (err) => {
                  console.error(err);
                  this.loadingService.dismissSecondLoader();
                }
              });
          }
        }
      )
    );
  }

  checkEmail(event: EmitterUserEmailInvitation): void {
    this.selectedUser.emit(event);
  }

  validateEmail(): boolean {
    if (
      this.email.length > 5 &&
      this.email.includes('@') &&
      this.email.includes('.')
    ) {
      return false;
    } else {
      return true;
    }
  }

  removeUser(): void {
    this.stepsService.invalidGuarantor = false;
    this.stepsService.invalidTenant = false;
    this.stepsService.invalidOwner = false;
    this.shouldRemoveUser = true;
    this.showInput = true;
    this.userNotFound = false;
    this.cotenants = [];
    this.selectedUser.emit(null);
  }
}
