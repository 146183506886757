import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import { DocuSignStatusEnum, EnvelopeStatus } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { ToastController } from '@ionic/angular';
import { first, Subject, takeUntil } from 'rxjs';

import { copyObject, tableSize } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-signed-document-table',
  templateUrl: './signed-document-table.component.html'
})
export class SignedDocumentTableComponent implements OnInit, OnDestroy {
  @Input({ required: true }) candidatureId: string;
  @Input() showTenant = false

  // TABLA
  displayedColumns: string[] = [
    'id',
    'documentType',
    'contractName',
    'tenant',
    'status',
    'lastStatusChange',
    'actions'
  ];
  dataSource: MatTableDataSource<EnvelopeStatus> =
    new MatTableDataSource<EnvelopeStatus>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  tableSize = tableSize;
  getDocumentsSigned$ = new Subject<void>();

  constructor(
    private insuranceService: InsurancesApiService,
    private loadingService: LoadingService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    if(!this.showTenant){
      this.getDocuments();
      this.subscribeToGetDocumentsObservable();
    }else{
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'tenant');
      this.getTenantDocuments()
    }
  }

  download(envelopeId: string): void {
    this.loadingService.presentSecondLoader(null);
    this.insuranceService.downloadDocusignDocument(envelopeId).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (blob: any) => {
        this.loadingService.dismissSecondLoader();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
          alert(
            'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
          );
        }
      },
      () => {
        this.loadingService.dismissSecondLoader();
        this.presentToast('No se ha podido descargar la poliza.', 'danger');
      }
    );
  }

  ngOnDestroy(): void {
    this.getDocumentsSigned$.next();
    this.getDocumentsSigned$.complete();
  }

  private async getDocuments(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.insuranceService
      .getDocusSignedByCandidatureId(this.candidatureId)
      .pipe(first())
      .subscribe({
        next: (resp: EnvelopeStatus[]) => this.onSuccessGetDocuments(resp),
        error: () => this.onErrorGetDocuments()
      });
  }

  private async getTenantDocuments(): Promise<void> {
    await this.loadingService.presentLoading(null);
    this.insuranceService
      .getDocusSignedByTenant()
      .pipe(first())
      .subscribe({
        next: (resp: EnvelopeStatus[]) => this.onSuccessGetDocuments(resp),
        error: () => this.onErrorGetDocuments()
      });
  }

  private async onSuccessGetDocuments(resp: EnvelopeStatus[]): Promise<void> {
    this.orderDocumentsByStatus(resp);
    // this.setDataSource(resp);
    await this.loadingService.dismissSecondLoader();
  }

  private orderDocumentsByStatus(resp: EnvelopeStatus[]): void {
    const list: EnvelopeStatus[] = [];

    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.CREATED));
    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.SENT));
    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.DELIVERED));
    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.SIGNED));
    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.COMPLETED));
    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.DECLINED));
    list.push(...this.getDocumentsByStatus(resp, DocuSignStatusEnum.VOIDED));

    this.setDataSource(list);
  }

  private getDocumentsByStatus(
    resp: EnvelopeStatus[],
    status: DocuSignStatusEnum
  ): EnvelopeStatus[] {
    let list = resp.filter((doc: EnvelopeStatus) => status === doc.status);

    list = list.sort(
      (a: EnvelopeStatus, b: EnvelopeStatus) =>
        new Date(a.lastStatusChange).getTime() -
        new Date(b.lastStatusChange).getTime()
    );

    return list;
  }

  private setDataSource(resp: EnvelopeStatus[]): void {
    this.dataSource = new MatTableDataSource<EnvelopeStatus>(
      copyObject(resp) as EnvelopeStatus[]
    );
  }

  private async onErrorGetDocuments(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
  }

  private subscribeToGetDocumentsObservable(): void {
    this.insuranceService
      .getSignedDocumentsObs()
      .pipe(takeUntil(this.getDocumentsSigned$))
      .subscribe(() => this.getDocuments());
  }

  private async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }
}
