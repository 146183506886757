import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { InsuranceCompanyAuthService } from '../../services/auth/insurance-company-auth.service';
import { NavigationService } from '../../services/navigation/navigation.service';

@Injectable()
export class InsuranceCompanyGuard {
  constructor(
    private insuranceCompanyAuthService: InsuranceCompanyAuthService,
    private navigationService: NavigationService
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.insuranceCompanyAuthService.isLoggedIn) {
      this.navigationService.goToLoginInsuranceCompanyPage();
      return false;
    }
    return true;
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.insuranceCompanyAuthService.canActivate();
  }
}
