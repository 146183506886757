/* eslint-disable max-len */
import { MenuItem } from './menu.model';

export const MENU_TENANT = [
  new MenuItem(
    'pages.profile.tenant.menu.home',
    'home-outline',
    '/tenant/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.candidatures',
    'newspaper-outline',
    '/tenant/{userId}/candidatures'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.insurances',
    'document-text-outline',
    '/tenant/{userId}/insurances'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.incidences',
    'settings-outline',
    '/tenant/{userId}/incidences'
  ),
  // new MenuItem('pages.profile.tenant.menu.supplies', 'bulb-outline', '/tenant/{userId}/supplies'),
  new MenuItem(
    'pages.profile.tenant.menu.newspaper',
    'folder-outline',
    '/tenant/{userId}/documents'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.profile',
    'person-outline',
    '/tenant/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.professional_data',
    'briefcase-outline',
    '/tenant/{userId}/professional-data'
  )
];

export const MENU_GUARANTOR = [
  new MenuItem(
    'pages.profile.tenant.menu.home',
    'home-outline',
    '/guarantor/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.candidatures-guarantor',
    'newspaper-outline',
    '/tenant/{userId}/candidatures'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.insurances',
    'document-text-outline',
    '/tenant/{userId}/insurances'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.newspaper',
    'folder-outline',
    '/guarantor/{userId}/documents'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.profile',
    'person-outline',
    '/guarantor/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.tenant.menu.professional_data',
    'briefcase-outline',
    '/tenant/{userId}/professional-data'
  )
];
