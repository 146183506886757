import { Pipe, PipeTransform } from '@angular/core';
import { SinisterThread } from '@core/models';

import { SinisterMessageWriterPipeArgs } from '../models/sinister-message-pipe.model';
import { getWriterName } from '../utils/global.utils';

@Pipe({
  name: 'sinisterMessageWriter'
})
export class SinisterMessageWriterPipe implements PipeTransform {
  transform(
    message: SinisterThread,
    { user, users }: SinisterMessageWriterPipeArgs
  ): string {
    return getWriterName(users, user.id, message);
  }
}
