/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ChangeUserRoleDto, TenantCandidature } from '@core/models';
import { ModalController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';

@Component({
  selector: 'el-buen-inquilino-business-rol-data-form-modal',
  templateUrl: './business-rol-data-form-modal.component.html'
})
export class BusinessRolDataFormModalComponent implements OnInit {
  @Input() changeUserRoleDto: ChangeUserRoleDto;
  @Input() tenant: TenantCandidature;

  businessForm: UntypedFormGroup;

  validationErrorMessages = appValidators.validationErrorMessages;

  constructor(
    private modalController: ModalController,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.createBusinessForm();
  }

  createBusinessForm(): void {
    this.businessForm = this.fb.group({
      businessName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          appValidators.complexNameValidator
        ])
      ),
      cif: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, appValidators.cifValidator])
      ),
      taxResidence: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          appValidators.addressValidator
        ])
      )
    });
  }

  submit(form: UntypedFormGroup): void {
    if (form.status === 'INVALID') return;
    this.changeUserRoleDto = { ...this.changeUserRoleDto, ...form.value };
    this.dismiss(true);
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving,
      data: saving ? this.changeUserRoleDto : null
    });
  }
}
