/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { presentToast } from '@shared/utils/toast.utils';
import { CancelPolicyRequestModalComponent } from '@shared-private/components/cancel-policy-request-modal/cancel-policy-request-modal.component';
import { CancelPolicyRequestModalResp } from '@shared-private/models/cancel-policy-request-modal.model';
import { fileSizeOk } from '@shared-private/utils/global.utils';
import { Observable, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  first,
  map
} from 'rxjs/operators';

import {
  AragInsuranceDto,
  AssetDto,
  BrokerDto,
  CalculatedPremiumAragDto,
  Candidature,
  CandidatureDto,
  CandidatureStatusEnum,
  ChangePolicyPaymentStatusDto,
  ChangeTenantEmailObj,
  ChangeUserRoleDto,
  ComercialNote,
  CustomModalButtonRole,
  DocumentDTO,
  DocumentRequiredSnapshotDTO,
  MoveCandidatureRequest,
  PageDto,
  PolicyIssue,
  PricingFiatcDto,
  ProfDataDto,
  ReSendEmailDto,
  ScoreResponseDto,
  ShareProfileDto,
  Sinister,
  SinisterDocumentType,
  SinisterThread,
  TenantCandidature,
  TrialCandidatureRequestDto,
  TypeDocumentToCandidatureEnum,
  UpdateSinister,
  UpdateUserStatusResponseDto,
  User,
  UserAnalysisStatusEnum,
  UserRequestCreateCandidatureDto,
  UserRequestCreateMultipleCandidatureDto,
  ValidateUserDto
} from '../../models';
import { LoadingService } from '../../services/loading/loading.service';
import { DocumentsApiService } from '../documenst-api/documents-api.service';
import { UsersApiService } from '../users-api/users-api.service';

@Injectable()
export class CandidaturesApiService {
  url: string;
  shouldBeRefreshCandidature = false;
  selectedCandidature: Candidature;
  userFillEventEmitter: EventEmitter<TenantCandidature> =
    new EventEmitter<TenantCandidature>();

  constructor(
    private http: HttpClient,
    private docService: DocumentsApiService,
    private usersService: UsersApiService,
    private modalController: ModalController,
    private toastController: ToastController,
    private loadingService: LoadingService
  ) {
    this.url = environment.services.candidatures;
  }

  homeOwnerRegisterCandidatureForAllSelected(
    assetId: string,
    user: UserRequestCreateCandidatureDto
  ): Observable<unknown> {
    return this.http.post<unknown>(
      this.url + '/assets/' + assetId + '/candidatures',
      user
    );
  }

  homeOwnerRegisterCandidatureMultipleForAllSelected(
    assetId: string,
    userList: UserRequestCreateMultipleCandidatureDto
  ): Observable<Candidature> {
    return this.http.post<Candidature>(
      this.url + '/assets/' + assetId + '/multiple-candidature',
      userList
    );
  }

  share(shareProfile: ShareProfileDto): Observable<ShareProfileDto> {
    return this.http.post<ShareProfileDto>(
      this.url + '/share-profile',
      shareProfile
    );
  }

  getCandidatures(
    numElements = '50',
    afterElementId?: string,
    assetId?: string,
    status?: CandidatureStatusEnum,
    email?: string
  ): Observable<Candidature[]> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    if (assetId) {
      httpParams = httpParams.append('assetId', assetId);
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (email) {
      httpParams = httpParams.append('email', email);
    }
    return this.http
      .get<PageDto<Candidature>>(this.url + '/candidatures', {
        params: httpParams
      })
      .pipe(map((res: PageDto<Candidature>) => res.elements));
  }

  getClosedCandidaturesForBuildings(
    assetId?: string,
    apiId?: string,
    portfolioId?: string
  ): Observable<Candidature[]> {
    const httpParams = new HttpParams()
      .append('numElements', 50)
      .append('apiId', apiId)
      .append('portfolioId', portfolioId)
      .append('assetId', assetId)
      .append('status', CandidatureStatusEnum.SELECT);
    return this.http
      .get<PageDto<Candidature>>(this.url + '/candidatures/for-buildings', {
        params: httpParams
      })
      .pipe(map((res: PageDto<Candidature>) => res.elements));
  }

  getCandidaturesForBuildings(
    assetId?: string,
    apiId?: string,
    portfolioId?: string,
    status?: CandidatureStatusEnum,
    trialVersion?: boolean
  ): Observable<Candidature[]> {
    return this.fetchCandidaturesForBuildings(
      assetId,
      apiId,
      portfolioId,
      status
    ).pipe(
      concatMap((candidatures) =>
        candidatures.length > 0
          ? forkJoin(
              candidatures.map((c) => this.enrichCandidature(c, trialVersion))
            )
          : of([])
      ),
      map(this.filterCandidatures)
    );
  }

  getTenantsData(idUsers: string[]): void {
    from(idUsers)
      .pipe(concatMap((idUser) => this.fetchTenantData(idUser)))
      .subscribe({
        next: (tenantData) => this.userFillEventEmitter.emit(tenantData),
        error: (err) => console.error('Error global en getTenantsData:', err)
      });
  }

  getCandidaturesByEmail(
    email: string,
    status?: any
  ): Observable<Candidature[]> {
    const emailReplaced = email.replace('+', '%2B');
    return this.getCandidatures('50', '0', null, status, emailReplaced);
  }

  getCandidaturesById(id: string): Observable<Candidature> {
    return this.http.get<Candidature>(`${this.url}/candidatures/${id}`);
  }

  getCandidaturesByTenantEmail(email: string): Observable<Candidature[]> {
    return this.http.get<Candidature[]>(
      `${this.url}/candidatures/by-tenant-email/${email}`
    );
  }

  getCandidaturesByTenantId(tenantId: string): Observable<Candidature[]> {
    return this.http.get<Candidature[]>(
      this.url + '/candidatures/tenant/' + tenantId
    );
  }

  getCandidaturesByAsset(
    assetId: string,
    trialVersion: boolean
  ): Observable<Candidature[]> {
    return this.getCandidatures('50', '0', assetId).pipe(
      concatMap((candidatures) =>
        this.enrichCandidaturesList(candidatures, trialVersion)
      ),
      map((candidatures) => this.filterRejectedWithoutTenants(candidatures))
    );
  }

  getCandidaturesByUser(id: string): Observable<Candidature[]> {
    return this.getCandidatures(
      '50',
      '0',
      null,
      CandidatureStatusEnum.WITHOUT_CHECKING
    ).pipe(
      map((candidatures) => {
        const result: Candidature[] = [];
        if (candidatures && candidatures.length > 0) {
          for (const candidature of candidatures) {
            for (const tenant of candidature?.tenantCandidatureList) {
              if (
                tenant.user.id === id &&
                tenant.userCandidatureStatusDtoEnum ===
                  CandidatureStatusEnum.WITHOUT_CHECKING
              ) {
                result.push(candidature);
              }
            }
          }
          return result;
        } else {
          return candidatures;
        }
      })
    );
  }

  getCandidature(candidatureId: string): Observable<Candidature> {
    return this.http.get<Candidature>(
      this.url + '/candidatures/' + candidatureId
    );
  }

  updateCandidatureStatus(
    candidature: Candidature,
    status: string,
    notify: boolean
  ): Observable<unknown> {
    return this.http.patch(this.url + '/candidatures/' + candidature.id, {
      status,
      notify
    });
  }

  updateUserStatus(
    candidatureId: string,
    userId: string,
    status: string
  ): Observable<UpdateUserStatusResponseDto> {
    return this.http.patch<UpdateUserStatusResponseDto>(
      this.url + '/candidatures/' + candidatureId + '/user-status/' + userId,
      { status }
    );
  }

  updateAsset(candidatureId: string, assetId: string) {
    return this.http.patch(this.url + '/candidatures/' + candidatureId, {
      assetId
    });
  }

  createInsurancePolicyArag(
    candidatureId: string,
    aragInsuranceDto: AragInsuranceDto
  ): Observable<unknown> {
    return this.http.post(
      `${this.url}/policies/candidatures/${candidatureId}/create-insurance`,
      aragInsuranceDto,
      {
        responseType: 'blob'
      }
    );
  }

  createTenantInsurance(
    candidatureId: string,
    aragInsuranceDto: AragInsuranceDto
  ): Observable<unknown> {
    return this.http.post(
      `${this.url}/policies/candidatures/${candidatureId}/create-tenant-insurance/`,
      aragInsuranceDto
    );
  }

  generateTenantPolicyPDF(policyNumber: string): Observable<void> {
    return this.http.get<void>(
      this.url + '/candidatures/generate-pdf/' + policyNumber
    );
  }

  getCalculatedPremiumArag(
    candidatureId: string,
    userId?: string
  ): Observable<CalculatedPremiumAragDto> {
    let httpParams = new HttpParams();
    if (userId) {
      httpParams = httpParams.append('userId', userId);
    }
    return this.http.get<CalculatedPremiumAragDto>(
      `${this.url}/policies/candidatures/${candidatureId}/calculate-insurance-premium`,
      { params: httpParams }
    );
  }

  getPricingFiatC(candidatureId: string): Observable<PricingFiatcDto> {
    return this.http.get<PricingFiatcDto>(
      `${this.url}/candidatures/${candidatureId}/pricing-fiatc`
    );
  }

  recalculateInsurancePremium(
    candidatureId: string,
    score: number,
    rentalPrice: number,
    vandalismCoverage: string,
    insuranceRetention: string,
    cleaningCoverage: string
  ): Observable<CalculatedPremiumAragDto> {
    const request = {
      score,
      rentalPrice,
      vandalismCoverage,
      insuranceRetention,
      cleaningCoverage
    };
    return this.http.post<CalculatedPremiumAragDto>(
      this.url +
        '/candidatures/' +
        candidatureId +
        '/recalculateInsurancePremium',
      request
    );
  }

  getPolicyReport(userId: string, policyId: string): Observable<unknown> {
    return this.http.get(`${this.url}/policies/${policyId}/users/${userId}`, {
      responseType: 'blob'
    });
  }

  requestMutipleAnalysis(
    candidature: Candidature,
    reLaunched?: boolean,
    rentalPrice?: number
  ): Observable<unknown> {
    return this.usersService.requestAnalysis(
      candidature.id,
      reLaunched,
      rentalPrice,
      null
    );
  }

  requestAnalysis(
    candidatureId: string,
    homeownerId?: string
  ): Observable<unknown> {
    return this.usersService.requestAnalysis(
      candidatureId,
      false,
      null,
      homeownerId
    );
  }

  updateCandidaturesCotenants(
    invitingTenantId: string,
    invitedTenantId: string
  ): Observable<unknown> {
    return this.http.patch(this.url + '/candidatures/', {
      invitingTenantId,
      invitedTenantId
    });
  }

  deletePreviousCandidatures(invitedTenant: string): Observable<unknown> {
    return this.http.delete(
      this.url + '/candidatures/' + invitedTenant + '/co-tenants'
    );
  }

  reSendEmailToTenant(reSendEmailDto: ReSendEmailDto): Observable<unknown> {
    return this.http.patch(
      this.url + '/candidatures/resend-invitation-tenant',
      reSendEmailDto
    );
  }

  changeTenantEmailForNewTenantsAndSendEmail(
    changeTenantEmailObj: ChangeTenantEmailObj
  ) {
    return this.http.patch(
      this.url + '/candidatures/edit-and-resend-tenant-mail',
      changeTenantEmailObj
    );
  }

  /************  Auxiliar functions ****************/

  updateColor(progress: number): string {
    if (progress < 35) {
      return 'danger';
    } else if (progress >= 80) {
      return 'success';
    } else {
      return 'warning';
    }
  }

  getBuildingPolicyNumberAndThirdParty(
    assetId: string,
    apiId?: string,
    portfolioId?: string
  ): Observable<unknown> {
    return this.getClosedCandidaturesForBuildings(
      assetId,
      apiId,
      portfolioId
    ).pipe(
      map((data: Candidature[]) => {
        return {
          id: assetId,
          policyNumber: data?.some(
            (candidature) => candidature.policyNumber !== null
          ),
          isThirdParty: data?.every(
            (candidature) => candidature.thirdParty === true
          ),
          tenantPolicyNumber: data?.some(
            (candidature) =>
              candidature.tenantPolicyNumber !== null &&
              candidature.tenantPolicyNumber !== 'DRAFT'
          )
        };
      })
    );
  }

  getAssetPolicyNumberAndThirdParty(assetId: string): Observable<unknown> {
    return this.getCandidatures(
      '50',
      '0',
      assetId,
      CandidatureStatusEnum.SELECT
    ).pipe(
      map((data: Candidature[]) => {
        return {
          id: assetId,
          policyNumber: data?.some(
            (candidature) => candidature.policyNumber !== null
          ),
          isThirdParty: data?.every(
            (candidature) => candidature.thirdParty === true
          ),
          tenantPolicyNumber: data?.some(
            (candidature) =>
              candidature.tenantPolicyNumber !== null &&
              candidature.tenantPolicyNumber !== 'DRAFT'
          )
        };
      })
    );
  }

  changePolicyBlockedStatus(
    candidatureId: string,
    status: boolean
  ): Observable<unknown> {
    return this.http.patch(
      this.url + `/candidatures/${candidatureId}/block-policy`,
      {
        blockPolicy: status
      }
    );
  }

  verifyTenantTrialFlow(
    userData: ValidateUserDto
  ): Observable<ScoreResponseDto> {
    return this.http.post<ScoreResponseDto>(
      this.url + '/trial/verify-tenant',
      userData
    );
  }

  createCandidatureTrialFlow(
    candidature: TrialCandidatureRequestDto,
    clientId?: string
  ): Observable<CandidatureDto> {
    return this.http.post<CandidatureDto>(
      this.url + '/trial/' + candidature.asset.id + '/candidatures',
      {
        warrantyId: 'trialVersion',
        users: candidature.users,
        portfolioId: candidature.portfolioId,
        clientId
      }
    );
  }

  changeUserRole(changeUserRoleDto: ChangeUserRoleDto): Observable<unknown> {
    const url =
      this.url +
      '/trial/' +
      changeUserRoleDto.candidatureId +
      '/tenants/' +
      changeUserRoleDto.tenantId +
      '/change-roles';
    const obj: ChangeUserRoleDto = { ...changeUserRoleDto };
    return this.http.put(url, obj);
  }

  thirdPartyCandidatures(date?: string) {
    const params = {};
    if (date !== null) {
      params['selectedDate'] = date;
    }
    return this.http.get<Candidature[]>(this.url + '/candidatures/third-party');
  }

  archiveCandidatures(archived: boolean, candidaturesIds: string[]) {
    return this.http.patch(`${this.url}/candidatures/archive`, {
      archived,
      candidaturesIds
    });
  }

  deleteTenant(candidatureId, tenantEmail): Observable<unknown> {
    return this.http.delete(
      this.url +
        '/candidatures/' +
        `${candidatureId}` +
        '/tenant/' +
        `${tenantEmail}`
    );
  }

  getContractedPoliciesResume(
    portfolioId?: string,
    apiId?: string,
    date?: string,
    homeownerId?: string
  ): Observable<PolicyIssue[]> {
    const params = {};

    if (portfolioId) {
      params['portfolioId'] = portfolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (date !== null) {
      params['selectedDate'] = date;
    }

    if (homeownerId) {
      params['requestedHomeownerId'] = homeownerId;
    }

    return this.http.get<PolicyIssue[]>(`${this.url}/policies/by-user-resume`, {
      params
    });
  }

  getSinisterPolicies(
    portfolioId?: string,
    apiId?: string,
    homeownerId?: string
  ): Observable<Sinister[]> {
    const params = {};

    if (portfolioId) {
      params['portfolioId'] = portfolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (homeownerId) {
      params['requestedHomeownerId'] = homeownerId;
    }

    return this.http.get<Sinister[]>(`${this.url}/sinisters`, { params });
  }

  getContractedPolicyByPolicyNumber(
    policyNumber: string
  ): Observable<PolicyIssue> {
    return this.http.get<PolicyIssue>(`${this.url}/policies/${policyNumber}`);
  }

  getContractedPolicyByCandidature(
    candidatureId: string
  ): Observable<PolicyIssue[]> {
    return this.http.get<PolicyIssue[]>(
      `${this.url}/policies/by-candidature/${candidatureId}`
    );
  }

  getContractedPoliciesByTenantId(): Observable<PolicyIssue[]> {
    return this.http.get<PolicyIssue[]>(`${this.url}/policies/by-tenant`);
  }

  updatePolicyPaymentStatus(
    policyNumber: string,
    changePolicyPaymentStatusDto: ChangePolicyPaymentStatusDto
  ): Observable<unknown> {
    return this.http.patch(
      `${this.url}/policies/${policyNumber}/update-payment-status`,
      changePolicyPaymentStatusDto
    );
  }

  updateDraftFlagCandidature(candidatureId: string): Observable<unknown> {
    return this.http.patch(
      `${this.url}/policies/candidatures/${candidatureId}/update-tenant-policy-number`,
      {}
    );
  }

  cancelPolicy(policyNumber: string, cancellationDate): Observable<unknown> {
    return this.http.patch(`${this.url}/policies/${policyNumber}/cancel`, {
      cancellationDate
    });
  }

  async openCancelPolicyRequestModal(
    insuranceType: string,
    insurancePolicyNumber: string,
    reopen = false,
    candidature?: Candidature
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: CancelPolicyRequestModalComponent,
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: {
        reopen: reopen,
        insuranceType: insuranceType
      } as Partial<CancelPolicyRequestModalComponent>
    });

    modal
      .onWillDismiss()
      .then((resp: OverlayEventDetail<CancelPolicyRequestModalResp>) => {
        if (resp.role === (CustomModalButtonRole.ACCEPT as string)) {
          this.callCancelPolicy(resp.data, insurancePolicyNumber, candidature);
        }
      });

    await modal.present();
  }

  async callCancelPolicy(
    data: CancelPolicyRequestModalResp,
    insurancePolicyNumber: string,
    candidature?: Candidature
  ): Promise<void> {
    if (!fileSizeOk(data.file)) {
      presentToast(
        this.toastController,
        'Tamaño máximo del fichero superado. Debe ser 10mb como máximo.',
        'danger',
        5000
      );
      return;
    }
    await this.loadingService.presentLoading(null);

    this.cancelPolicyRequest(
      insurancePolicyNumber,
      data.file,
      data.comments,
      data.futureDocumentDate
    )
      .pipe(first())
      .subscribe({
        next: () => {
          this.loadingService.dismissLoading();

          presentToast(
            this.toastController,
            'Vamos a tramitar tu solicitud de cancelación de póliza',
            'success',
            5000
          );

          if (candidature) {
            this.changeCandidatureStatus(candidature);
          }
        },
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  cancelPolicyRequest(
    policyNumber: string,
    file?: File,
    comments?: string,
    futureDocumentDate?: string
  ): Observable<void> {
    const formData = new FormData();
    if (!!file) {
      formData.append('file', file);
      formData.append('name', file.name);
    }
    if (!!comments) {
      formData.append('comments', comments);
    }
    if (
      !!futureDocumentDate &&
      futureDocumentDate !== undefined &&
      futureDocumentDate !== null
    ) {
      formData.append('futureDocumentDate', futureDocumentDate);
    }
    return this.http.post<void>(
      `${this.url}/policies/${policyNumber}/cancel-request`,
      formData
    );
  }

  changeCandidatureStatus(candidature: Candidature): void {
    this.updateCandidatureStatus(
      candidature,
      CandidatureStatusEnum.PENDING,
      false
    ).subscribe({
      next: () => {
        presentToast(
          this.toastController,
          'Éxito en la reapertura del activo',
          'success'
        );
      },
      error: () =>
        presentToast(
          this.toastController,
          'Error al deseleccionar la candidatura',
          'danger'
        )
    });
  }

  createSinister(policyId: string, sinister: Sinister) {
    return this.http.post(
      `${this.url}/policies/${policyId}/sinister`,
      sinister
    );
  }

  closeSinister(sinister: Sinister): Observable<void> {
    return this.http.post<void>(
      `${this.url}/sinisters/${sinister.id}/close`,
      sinister
    );
  }

  addCommentToPolicySinister(
    policyId: string,
    sinisterId: string,
    message: string
  ) {
    return this.http.patch(
      `${this.url}/candidatures/contracted-policies/${policyId}/sinister/${sinisterId}`,
      {
        message
      }
    );
  }

  addCommentToSinister(sinisterId: string, data: SinisterThread) {
    return this.http.patch(`${this.url}/sinisters/${sinisterId}/comment`, data);
  }

  addCommentToCancellation(policyId: string, data: SinisterThread) {
    return this.http.patch(
      `${this.url}/sinisters/cancellations/${policyId}/comment`,
      data
    );
  }

  editCandidature(assetId: string, candidatureId: string, tenantList: User[]) {
    return this.http.patch(
      this.url + '/assets/' + assetId + '/candidatures/' + candidatureId,
      {
        users: tenantList
      }
    );
  }

  relocateCandidature(
    relocatedCandidature: MoveCandidatureRequest
  ): Observable<unknown> {
    return this.http.post<unknown>(
      this.url + '/assets/relocate-candidature',
      relocatedCandidature
    );
  }

  reinforceCandidature(
    assetId: string,
    candidatureId: string,
    tenantList: User[]
  ): Observable<CandidatureDto> {
    return this.http.post<CandidatureDto>(
      this.url + `/assets/${assetId}/candidatures/${candidatureId}/reinforce`,
      {
        users: tenantList
      }
    );
  }

  downloadTenantPolicy(policyNumber: string): Observable<Blob> {
    return this.http.get(
      this.url + '/candidatures/downloadTenantPolicy/' + policyNumber,
      {
        responseType: 'blob'
      }
    );
  }

  getBrokerCodeByUserId(userId?: string): Observable<BrokerDto> {
    let httpParams = new HttpParams();
    if (userId) {
      httpParams = httpParams.append('userId', userId);
    }
    return this.http.get<BrokerDto>(`${this.url}/policies/broker-code`, {
      params: httpParams
    });
  }

  updateScore(candidatureId: string, score: number) {
    return this.http.patch<null>(
      `${this.url}/candidatures/${candidatureId}/updateScore`,
      {
        score
      }
    );
  }

  getContractedPoliciesByPolicyNumber(policyNumber: string) {
    return this.http
      .get<PolicyIssue>(`${this.url}/policies/${policyNumber}`)
      .pipe(map((resp) => [resp]));
  }

  getSinisterExcel(
    portfolioId?: string,
    apiId?: string,
    homeownerId?: string
  ): Observable<Blob> {
    const params = {};

    if (portfolioId) {
      params['portfolioId'] = portfolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (homeownerId) {
      params['requestedHomeownerId'] = homeownerId;
    }

    return this.http.get(`${this.url}/sinisters/excel`, {
      responseType: 'blob',
      params
    });
  }

  updateSinistersByExcel(file: File): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file);
    const endpoint = `${this.url}/sinisters/update-by-excel`;
    return this.http.post(endpoint, formData);
  }

  getAssetsByTenantEmail(
    email: string,
    buildingId: string
  ): Observable<AssetDto[]> {
    const params = new HttpParams().append('buildingId', buildingId);

    const tenantEmail = email.replace('+', '%2B');
    const endpoint = `${this.url}/assets/tenants/${tenantEmail}`;
    return this.http.get<AssetDto[]>(endpoint, { params });
  }

  getAssetsByTenantDni(
    dni: string,
    buildingId: string
  ): Observable<AssetDto[]> {
    const params = new HttpParams().append('buildingId', buildingId);

    const endpoint = `${this.url}/assets/tenant-dni/${dni}`;
    return this.http.get<AssetDto[]>(endpoint, { params });
  }

  createCommercialNotes(
    candidatureId: string,
    body: ComercialNote
  ): Observable<null> {
    return this.http.patch<null>(
      `${this.url}/candidatures/${candidatureId}/comercial-notes`,
      body
    );
  }

  getSinisterDocument(
    policyNumber: string,
    docType: SinisterDocumentType
  ): Observable<DocumentDTO> {
    const endpoint = `${this.url}/sinisters/${policyNumber}/document/${docType}`;

    return this.http.get<DocumentDTO>(endpoint);
  }

  updateSinister(data: UpdateSinister): Observable<Sinister> {
    const endpoint = `${this.url}/sinisters/${data.id}`;
    return this.http.patch<Sinister>(endpoint, data);
  }

  addSinisterDocument(
    policyNumber: string,
    file: File,
    type: SinisterDocumentType
  ): Observable<void> {
    const endpoint = `${this.url}/sinisters/${policyNumber}/add-document`;

    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    formData.append('type', type.toString());

    return this.http.post<void>(endpoint, formData);
  }

  deleteSinisterDocument(
    policyNumber: string,
    type: SinisterDocumentType
  ): Observable<void> {
    const endpoint = `${this.url}/sinisters/${policyNumber}/document/${type}`;

    return this.http.delete<void>(endpoint);
  }

  getSinisterCancelation(
    portfolioId: string,
    apiId: string,
    requestedHomeownerId: string
  ): Observable<Sinister[]> {
    const endpoint = `${this.url}/sinisters/cancellations`;

    let params = new HttpParams();

    if (!!portfolioId) {
      params = params.append('portfolioId', portfolioId);
    }

    if (!!apiId) {
      params = params.append('apiId', apiId);
    }

    if (!!requestedHomeownerId) {
      params = params.append('requestedHomeownerId', requestedHomeownerId);
    }

    return this.http.get<Sinister[]>(endpoint, { params });
  }

  checkSinistersByDni(dni: string) {
    const endpoint = `${this.url}/sinisters/${dni}/by-tenant-dni`;
    return this.http.get<Sinister[]>(endpoint);
  }

  addCommentToPolicyIssue(
    id: string,
    data: SinisterThread
  ): Observable<PolicyIssue> {
    const endpoint = `${this.url}/policies/issues/${id}/add-comment`;
    return this.http.post<PolicyIssue>(endpoint, data);
  }

  addCommentToCancelledPolicy(
    id: string,
    data: SinisterThread
  ): Observable<PolicyIssue> {
    const endpoint = `${this.url}/sinisters/cancellations/${id}/add-comment`;
    return this.http.post<PolicyIssue>(endpoint, data);
  }

  updatePolicyPrice(
    policyNumber: string,
    updatedPrice: number
  ): Observable<void> {
    const endpoint = `${this.url}/policies/${policyNumber}/update-price`;
    return this.http.patch<void>(endpoint, {
      updatedPrice
    });
  }

  getSinistersByCandidatureId(candidatureId: string): Observable<Sinister[]> {
    const endpoint = `${this.url}/sinisters/${candidatureId}`;
    return this.http.get<Sinister[]>(endpoint);
  }

  createDocumentOnCandidature(
    params: {
      type: string;
      internalReference: string;
      description: string;
      file: any;
    },
    idCandidature: string
  ): Observable<void> {
    const formData = new FormData();

    formData.append(`file`, params.file);
    formData.append(`type`, params.type);
    formData.append(`internalReference`, params.internalReference);
    formData.append(`description`, params.description);

    return this.http.post<void>(
      `${this.url}/candidatures/${idCandidature}/documents`,
      formData
    );
  }

  getDocumentsByCandidature(idCandidature: string): Observable<DocumentDTO[]> {
    const endpoint = `${this.url}/candidatures/${idCandidature}/documents`;

    const endpoints = Object.keys(TypeDocumentToCandidatureEnum).map(
      (type: string) =>
        this.http
          .get<DocumentDTO>(`${endpoint}/${type}`)
          .pipe(catchError(() => of(null)))
    );

    return forkJoin(endpoints).pipe(
      map((response: DocumentDTO[]) =>
        response.filter((response: DocumentDTO) => !!response)
      )
    );
  }

  deleteDocument(
    idCandidature: string,
    documentType: string
  ): Observable<unknown> {
    const endpoint = `${this.url}/candidatures/${idCandidature}/documents/${documentType}`;

    return this.http.delete(endpoint);
  }

  getSelectedDocuments(
    candidatureId: string,
    requestUserId?: string
  ): Observable<DocumentDTO[]> {
    let httpParams = new HttpParams();

    // Agrega el parámetro solo si requestUserId tiene un valor
    if (!!requestUserId) {
      httpParams = httpParams.set('requestUserId', requestUserId);
    }
    return this.http.get<DocumentDTO[]>(
      this.url + `/candidatures/${candidatureId}/documents`,
      {
        params: httpParams
      }
    );
  }

  updateVisitedSinister(sinisterId: string): Observable<unknown> {
    return this.http.patch(
      this.url + '/sinisters/' + sinisterId + '/visited',
      {}
    );
  }

  private checkAnalysisDate(latestUpdate: Date, analysisDates: Date) {
    if (analysisDates) {
      return latestUpdate >= analysisDates;
    }
    return false;
  }

  private fetchCandidaturesForBuildings(
    assetId?: string,
    apiId?: string,
    portfolioId?: string,
    status?: CandidatureStatusEnum
  ): Observable<Candidature[]> {
    let params = new HttpParams()
      .set('numElements', '50')
      .set('apiId', apiId)
      .set('portfolioId', portfolioId)
      .set('assetId', assetId);

    if (status) {
      params = params.set('status', status);
    }

    return this.http
      .get<
        PageDto<Candidature>
      >(`${this.url}/candidatures/for-buildings`, { params })
      .pipe(map((res) => res.elements ?? []));
  }

  private enrichCandidature(
    candidature: Candidature,
    trialVersion: boolean
  ): Observable<Candidature> {
    if (!candidature.tenantCandidatureList?.length) {
      return of(candidature);
    }

    return forkJoin(
      candidature.tenantCandidatureList.map((tenant) =>
        this.getExtendedTenant(tenant, candidature, trialVersion)
      )
    ).pipe(
      map((extendedTenants) => ({
        ...candidature,
        tenantCandidatureList: extendedTenants
      }))
    );
  }

  private filterCandidatures(candidatures: Candidature[]): Candidature[] {
    return candidatures.filter(
      (c) =>
        !(
          c.tenantCandidatureList?.length === 0 &&
          c.candidatureStatusEnum === CandidatureStatusEnum.REJECT
        )
    );
  }

  private getExtendedTenant(
    tenant: TenantCandidature,
    candidature: Candidature,
    trialVersion: boolean
  ): Observable<TenantCandidature> {
    if (trialVersion) {
      if (tenant.user.professionalData?.freelance !== undefined) {
        tenant.user.freelance = tenant.user.professionalData.freelance;
      }
      return forkJoin({
        analysis: this.loadTenantAnalysis(tenant.user.id, candidature).pipe(
          catchError(() => of(this.emptyAnalysisData([], null)))
        ),
        user: of(tenant.user)
      }).pipe(
        map((data) => ({
          ...tenant,
          ...this.mapTenantAnalysis(data.analysis),
          user: data.user
        })),
        catchError(() => of(tenant))
      );
    } else {
      // Para no trialVersion, se llama al servicio de users para obtener userDB
      return forkJoin({
        analysis: this.loadTenantAnalysis(tenant.user.id, candidature).pipe(
          catchError(() => of(this.emptyAnalysisData([], null)))
        ),
        user: this.usersService
          .getUserObservable(tenant.user.id)
          .pipe(catchError(() => of(tenant.user)))
      }).pipe(
        map((data) => {
          if (data.user.professionalData?.freelance !== undefined) {
            data.user.freelance = tenant.user.professionalData?.freelance;
          }
          return {
            ...tenant,
            ...this.mapTenantAnalysis(data.analysis),
            user: data.user
          };
        }),
        catchError(() => of(tenant))
      );
    }
  }

  private loadTenantAnalysis(userId: string, candidature: Candidature) {
    return this.getRelevantAnalysisDataForTenant(
      userId,
      candidature.id,
      candidature.latestUpdate
    );
  }

  private getRelevantAnalysisDataForTenant(
    tenantId: string,
    candidatureId: string,
    latestCandidatureUpdate: Date
  ) {
    return this.usersService
      .getRequestAnalysisByUser(tenantId, candidatureId)
      .pipe(
        map((analysis) => {
          const incidences = analysis.incidences || [];
          const pendingToDownload = analysis.pendingToDownload;

          if (
            this.checkAnalysisDate(
              latestCandidatureUpdate,
              analysis.creationDate
            ) ||
            this.checkAnalysisDate(latestCandidatureUpdate, analysis.endDate)
          ) {
            return this.emptyAnalysisData(incidences, pendingToDownload);
          } else if (analysis.endDate) {
            return this.reportedAnalysisData(
              analysis,
              incidences,
              pendingToDownload
            );
          } else {
            return this.inProcessAnalysisData(
              analysis,
              incidences,
              pendingToDownload
            );
          }
        }),
        catchError(() => of(this.emptyAnalysisData([], null)))
      );
  }

  private emptyAnalysisData(incidences: any[], pendingToDownload: any) {
    return {
      status: UserAnalysisStatusEnum.WITHOUT_ANALYSIS,
      incidences,
      pendingToDownload,
      requestAnalysisDate: null,
      requestAnalysisId: null
    };
  }

  private reportedAnalysisData(
    analysis: any,
    incidences: any[],
    pendingToDownload: any
  ) {
    return {
      status: UserAnalysisStatusEnum.REPORTED_ANALYSIS,
      requestAnalysisDate: analysis.endDate,
      incidences,
      pendingToDownload,
      requestAnalysisId: analysis.id
    };
  }

  private inProcessAnalysisData(
    analysis: any,
    incidences: any[],
    pendingToDownload: any
  ) {
    return {
      status: UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
      requestAnalysisDate: analysis.creationDate,
      incidences,
      pendingToDownload,
      requestAnalysisId: analysis.id
    };
  }

  private mapTenantAnalysis(analysis: any): Partial<TenantCandidature> {
    return {
      status: analysis.status,
      incidences: analysis.incidences,
      requestAnalysisDate: analysis.requestAnalysisDate,
      requestAnalysisId: analysis.requestAnalysisId,
      pendingToDownload: analysis.pendingToDownload
    };
  }

  /**
   * Carga los datos completos de un tenant
   */
  private fetchTenantData(idUser: string): Observable<TenantCandidature> {
    return forkJoin({
      requestDocumentation: this.safeLoad(
        `getRequestDocumentation (${idUser})`,
        () => this.usersService.getRequestDocumentation(idUser)
      ),
      user: this.safeLoad(`getUserObservable (${idUser})`, () =>
        this.usersService.getUserObservable(idUser)
      ),
      professionalData: this.safeLoad(`getProfessionalData (${idUser})`, () =>
        this.usersService.getProfessionalData(idUser)
      ),
      snapshot: this.safeLoad(`getCheckDocuments(${idUser})`, () =>
        this.docService.getCheckDocuments(idUser).pipe(catchError(() => of([])))
      )
    }).pipe(map((responses) => this.buildTenantCandidature(idUser, responses)));
  }

  /**
   * Wrapper seguro para llamadas a servicios, que loguea errores.
   */
  private safeLoad<T>(
    sourceName: string,
    loader: () => Observable<T>
  ): Observable<T | null> {
    return loader().pipe(
      catchError((err) => {
        if (!environment.production) {
          console.error(`Error en ${sourceName}:`, err);
        }
        return of(null);
      })
    );
  }

  /**
   * Construye el TenantCandidature a partir de los datos obtenidos
   */
  private buildTenantCandidature(
    idUser: string,
    responses: {
      //documents: UserDocuments | null;
      requestDocumentation: any;
      user: User | null;
      professionalData: ProfDataDto | null;
      snapshot: DocumentRequiredSnapshotDTO[];
    }
  ): TenantCandidature {
    const userData: TenantCandidature = {
      requestDocumentation: responses.requestDocumentation,
      snapshot: responses.snapshot
    };

    if (responses.user) {
      userData.user = responses.user;

      // Sincronizar freelance (si lo trae professionalData)
      userData.user.freelance = responses.professionalData?.freelance ?? false;
    } else {
      console.warn(`⚠️ Usuario con id ${idUser} no encontrado.`);
    }

    return userData;
  }

  /**
   * Enriquecer cada candidatura con la información extendida de cada tenant.
   */
  private enrichCandidaturesList(
    candidatures: Candidature[],
    trialVersion: boolean
  ): Observable<Candidature[]> {
    if (!candidatures || candidatures.length === 0) {
      return of([]);
    }

    const enrichedCandidatures$ = candidatures.map((candidature) =>
      this.enrichCandidatureTenants(candidature, trialVersion)
    );

    return forkJoin(enrichedCandidatures$).pipe(defaultIfEmpty([]));
  }

  /**
   * Enriquecer una candidatura con información extendida de cada tenant.
   */
  private enrichCandidatureTenants(
    candidature: Candidature,
    trialVersion: boolean
  ): Observable<Candidature> {
    if (!candidature.tenantCandidatureList?.length) {
      return of(candidature);
    }

    const enrichedTenants$ = candidature.tenantCandidatureList.map((tenant) =>
      this.getExtendedTenant(tenant, candidature, trialVersion)
    );

    return forkJoin(enrichedTenants$).pipe(
      map((extendedTenants) => ({
        ...candidature,
        tenantCandidatureList: extendedTenants
      }))
    );
  }

  /**
   * Filtra candidaturas rechazadas que no tienen tenants asociados.
   */
  private filterRejectedWithoutTenants(
    candidatures: Candidature[]
  ): Candidature[] {
    return candidatures.filter(
      (candidature) =>
        !(
          candidature.tenantCandidatureList?.length === 0 &&
          candidature.candidatureStatusEnum === CandidatureStatusEnum.REJECT
        )
    );
  }
}
