import { Component, Input } from '@angular/core';
import { Candidature, User } from '@core/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-candidature-notes-modal',
  templateUrl: './candidature-notes-modal.component.html'
})
export class CandidatureNotesModalComponent {
  @Input() candidature: Candidature;
  @Input() user: User;

  private thereIsNewNote = false;

  constructor(private modalController: ModalController) {}

  dismiss(): void {
    this.modalController.dismiss(this.thereIsNewNote);
  }

  newNote(event: boolean): void {
    this.thereIsNewNote = event;
  }
}
