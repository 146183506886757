/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CheckMinimumDoc,
  DocumentDTO,
  Incidence,
  LastTwoPayslipMonths,
  MinimalDocumentationCheck,
  TenantCandidature,
  TypeDocumentEnum,
  User
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AddDocumentComponent } from '@shared/components/add-document/add-document.component';
import { DocumentModalComponent } from '@shared/components/document-modal/document.modal.component';
import { IncidencesModalComponent } from '@shared/components/incidences-modal/incidences-modal.component';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-trial-tab-document',
  templateUrl: './trial-tab-document.component.html'
})
export class TrialTabDocumentComponent implements OnInit {
  documents: DocumentDTO[] = [];
  subscriptions: Subscription[] = [];
  student: boolean;
  retired: boolean;
  business: boolean;
  guarantor = false;
  tenantId: string;
  user: User;
  isDisabledView: boolean;
  freelance: boolean;
  lastTwoPayslipMonths: LastTwoPayslipMonths;
  documentationOkMessage: string;
  minimalDocumentationCheck: MinimalDocumentationCheck =
    new MinimalDocumentationCheck();
  typeDocumentEnum = TypeDocumentEnum;

  isBackoffice = false;

  constructor(
    private loadingService: LoadingService,
    public modalController: ModalController,
    private docService: DocumentsApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    public utilsService: UtilsService,
    private utils: UtilsService,
    private router: Router,
    public alertController: AlertController,
    private candidatureService: CandidaturesApiService,
    private usersService: UsersApiService
  ) {}

  ngOnInit() {
    this.setText();
    this.user = {} as User;
    this.tenantId =
      this.router.url.split('/')[this.router.url.split('/').length - 1];
    this.getTenantInfo();

    if (this.router.url.includes('backoffice')) {
      this.reloadDocuments(this.tenantId);
    }
    this.lastTwoPayslipMonths =
      this.utilsService.calculatedUltimateTwoMonthForPayslips();
  }

  setText(): void {
    this.documentationOkMessage = this.translate.instant(
      'pages.tab_document.documentationOk'
    );
  }

  get showForeignerIncomeChips(): boolean {
    return this.user.foreignIncomes;
  }

  get extraDoc(): boolean {
    return (
      !!this.user?.extraDocumentation && this.user?.extraDocumentation !== ''
    );
  }

  getTenantInfo() {
    const tenantCandidature =
      this.candidatureService.selectedCandidature?.tenantCandidatureList?.find(
        (tenant) => tenant.user.id === this.tenantId
      );
    this.user = tenantCandidature?.user;
    this.student = this.user?.student;
    this.retired = this.user?.retired;
    this.guarantor = this.user?.guarantor ? true : false;
    this.freelance =
      this.user?.freelance || this.user?.professionalData?.freelance;
    this.business = this.user?.business;
    this.getDocuments(tenantCandidature);
  }

  getDocuments(tenantCandidature: TenantCandidature) {
    if (!!tenantCandidature) {
      if (tenantCandidature.payslips?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.payslips);
      }

      if (tenantCandidature.dni?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.dni);
      }

      if (tenantCandidature.rent?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.rent);
      }

      if (tenantCandidature.otherDocument?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.otherDocument);
      }

      if (tenantCandidature.pensionerRecognition?.length > 0) {
        this.documents = this.documents.concat(
          tenantCandidature.pensionerRecognition
        );
      }

      if (tenantCandidature.workLife?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.workLife);
      }

      if (tenantCandidature.is200Model?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.is200Model);
      }

      if (tenantCandidature.ivaQuarterModel?.length > 0) {
        this.documents = this.documents.concat(
          tenantCandidature.ivaQuarterModel
        );
      }

      if (tenantCandidature.model130?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.model130);
      }

      if (tenantCandidature.ssCertificate?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.ssCertificate);
      }

      if (tenantCandidature.aeatCertificate?.length > 0) {
        this.documents = this.documents.concat(
          tenantCandidature.aeatCertificate
        );
      }

      if (tenantCandidature.pygQuarter?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.pygQuarter);
      }

      if (tenantCandidature.bankStatement?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.bankStatement);
      }

      if (tenantCandidature.workContract?.length > 0) {
        this.documents = this.documents.concat(tenantCandidature.workContract);
      }

      if (tenantCandidature.remoteJobCertificate?.length > 0) {
        this.documents = this.documents.concat(
          tenantCandidature.remoteJobCertificate
        );
      }

      if (tenantCandidature.invoicePaymentConfirmation?.length > 0) {
        this.documents = this.documents.concat(
          tenantCandidature.invoicePaymentConfirmation
        );
      }

      if (tenantCandidature.invoiceRecordBook?.length > 0) {
        this.documents = this.documents.concat(
          tenantCandidature.invoiceRecordBook
        );
      }

      this.minimalDocumentationCheck =
        this.utilsService.checkMinimalDocumentation(
          this.documents,
          this.extraDoc
        );
    }
  }

  getRequestAnalysisForIncidences(
    requestAnalysisId: string,
    documentId: string
  ) {
    this.usersService
      .getAnalysis(requestAnalysisId)
      .subscribe((requestAnalysis) => {
        if (requestAnalysis) {
          const filteredIncidenceByDocument = requestAnalysis.incidences.filter(
            (incidence) => incidence?.document?.id === documentId
          );
          this.showIncidences(filteredIncidenceByDocument, requestAnalysisId);
        }
      });
  }

  reloadDocuments(requestUserId?: string) {
    this.documents = [];
    this.docService
      .getDocuments('50', requestUserId, null)
      .subscribe((documents) => {
        const payslips = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.PAYSLIP
        );
        if (payslips?.length > 0) {
          this.documents = this.documents.concat(payslips);
        }

        const dni = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.DNI
        );
        if (dni?.length > 0) {
          this.documents = this.documents.concat(dni);
        }

        const rent = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.INCOME_TAX_DECLARATION
        );
        if (rent?.length > 0) {
          this.documents = this.documents.concat(rent);
        }

        const otherDocument = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.OTHER_DOCUMENT
        );
        if (otherDocument?.length > 0) {
          this.documents = this.documents.concat(otherDocument);
        }

        const pensionerRecognition = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.PENSIONER_RECOGNITION
        );
        if (pensionerRecognition?.length > 0) {
          this.documents = this.documents.concat(pensionerRecognition);
        }

        const workLife = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.WORK_LIFE
        );
        if (workLife?.length > 0) {
          this.documents = this.documents.concat(workLife);
        }

        const is200Model = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.IS200_MODEL
        );
        if (is200Model?.length > 0) {
          this.documents = this.documents.concat(is200Model);
        }

        const ivaQuarterModel = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL
        );
        if (ivaQuarterModel?.length > 0) {
          this.documents = this.documents.concat(ivaQuarterModel);
        }

        const model130 = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.MODEL_130
        );
        if (model130?.length > 0) {
          this.documents = this.documents.concat(model130);
        }

        const ssCertificate = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.SS_CERTIFICATE
        );
        if (ssCertificate?.length > 0) {
          this.documents = this.documents.concat(ssCertificate);
        }

        const aeatCertificate = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.AEAT_CERTIFICATE
        );

        if (aeatCertificate?.length > 0) {
          this.documents = this.documents.concat(aeatCertificate);
        }

        const pygQuarter = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.PYG_QUARTER
        );
        if (pygQuarter?.length > 0) {
          this.documents = this.documents.concat(pygQuarter);
        }

        const workContract = documents.filter(
          (document) => document.typeDocument === TypeDocumentEnum.WORK_CONTRACT
        );
        if (workContract?.length > 0) {
          this.documents = this.documents.concat(workContract);
        }

        const bankStatement = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.BANK_STATEMENT
        );
        if (bankStatement?.length > 0) {
          this.documents = this.documents.concat(bankStatement);
        }

        const remoteJobCertificate = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.REMOTE_JOB_CERTIFICATE
        );
        if (remoteJobCertificate?.length > 0) {
          this.documents = this.documents.concat(remoteJobCertificate);
        }

        const invoicePaymentConfirmation = documents.filter(
          (document) =>
            document.typeDocument ===
            TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION
        );
        if (invoicePaymentConfirmation?.length > 0) {
          this.documents = this.documents.concat(invoicePaymentConfirmation);
        }

        const invoiceRecordBook = documents.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.INVOICE_RECORD_BOOK
        );
        if (invoiceRecordBook?.length > 0) {
          this.documents = this.documents.concat(invoiceRecordBook);
        }

        this.minimalDocumentationCheck =
          this.utilsService.checkMinimalDocumentation(documents, this.extraDoc);
      }),
      catchError(() => {
        this.minimalDocumentationCheck = new MinimalDocumentationCheck();
        return [];
      });
  }

  launchIncidences(requestAnalysisId: string, documentId: string) {
    this.getRequestAnalysisForIncidences(requestAnalysisId, documentId);
  }

  async showIncidences(incidences: Incidence[], requestAnalysisId: string) {
    const incidenceModal = await this.modalController.create({
      component: IncidencesModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        incidenceList: incidences,
        user: this.user,
        analysisId: requestAnalysisId
      }
    });
    incidenceModal.onDidDismiss().then((close) => {
      if (close.data.saving) this.reloadDocuments(this.tenantId);
    });
    await incidenceModal.present();
  }

  async presentModal(
    data?: DocumentDTO,
    typeDocument?: TypeDocumentEnum,
    name?: string
  ) {
    if (this.documents?.length >= 20) {
      this.presentMaximumNumberDocumentsReachedModal();
      return;
    }
    let month = 0;
    if (
      typeDocument === TypeDocumentEnum.PAYSLIP &&
      name === 'hasNotOptionalPayslip'
    ) {
      month = this.getMonthNumber(0);
    }

    if (
      typeDocument === TypeDocumentEnum.PAYSLIP &&
      name === 'hasOneMonthPayslips'
    ) {
      month = this.getMonthNumber(1);
    }

    if (
      typeDocument === TypeDocumentEnum.PAYSLIP &&
      name === 'hasTwoMonthPayslips'
    ) {
      month = this.getMonthNumber(2);
    }

    let quarter = '';
    let quarterMoment: moment.Moment;
    if (
      typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL &&
      name === 'hasOneQuarterModel'
    ) {
      quarter = this.getQuarterString(1);
      quarterMoment = this.getQuarterMoment(1);
    }

    if (
      typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL &&
      name === 'hasTwoQuarterModel'
    ) {
      quarter = this.getQuarterString(2);
      quarterMoment = this.getQuarterMoment(2);
    }
    const modal = await this.modalController.create({
      component: AddDocumentComponent,
      cssClass: 'auto-height',
      componentProps: {
        document: data ?? new DocumentDTO(),
        typeDocumentInput: typeDocument,
        user: this.user,
        name: name ?? '',
        selectedMonth: typeDocument === TypeDocumentEnum.PAYSLIP ? month : null,
        selectedQuarter:
          typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL ? quarter : null,
        selectedQuarterMoment:
          typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL
            ? quarterMoment
            : null
      } as Partial<AddDocumentComponent>
    });
    modal.onDidDismiss().then((close) => {
      if (close?.data?.saving) {
        this.reloadDocuments(this.tenantId);
      }
    });
    return await modal.present();
  }

  checkDeleteDocument(document: DocumentDTO) {
    document.validated
      ? this.presentDeniedDeleteDocumentModal()
      : this.delete(document.id);
  }

  delete(docId) {
    const subsDelete = this.docService
      .deleteDocumentCotenant(this.user.id, docId)
      .subscribe(() => {
        this.presentToast();
        this.reloadDocuments(this.tenantId);
      });
    this.subscriptions.push(subsDelete);
  }

  showDocument(document: DocumentDTO) {
    if (document && document.id) {
      const fileSubs = this.docService
        .getFileByDocument(document.id)
        .subscribe(async (res) => {
          const blob = await this.utils.UrlToBlob(res.url);
          this.utils.downloadPDF(document.name + '.' + document.extension, blob);
        });
      this.subscriptions.push(fileSubs);
    }
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: this.translate.instant('pages.documents.create-page.deletedOK'),
      duration: 2000,
      position: 'top',
      color: 'success'
    });
    toast.present();
  }

  checkGuarantor(user: User) {
    if (user.guarantor) {
      return true;
    }
    return false;
  }

  async viewDocument(document: DocumentDTO) {
    this.loadingService.presentSecondLoader(null);
    this.isDisabledView = true;
    const fileSubs = this.docService
      .getFileByDocument(document.id)
      .subscribe(async (res) => {
        this.loadingService.dismissSecondLoader();
        this.loadViewDocumentModal(res, document);
      });
    this.subscriptions.push(fileSubs);
  }

  async loadViewDocumentModal(responseBlob: any, document: DocumentDTO) {
    const modal = await this.modalController.create({
      component: DocumentModalComponent,
      cssClass: 'custom-modal-xl',
      componentProps: {
        pdfSrc: responseBlob,
        documentData: document
      }
    });
    modal.onDidDismiss().then(() => {
      this.isDisabledView = false;
    });
    return await modal.present();
  }

  async presentDeniedDeleteDocumentModal() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.home_owner_tab_document.modal_header'
      ),
      message: this.translate.instant(
        'pages.home_owner_tab_document.modal_msg'
      ),
      buttons: [
        {
          text: 'Ok',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  async presentMaximumNumberDocumentsReachedModal() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_header'
      ),
      message: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_message'
      ),
      buttons: [
        {
          text: this.translate.instant('pages.documents.create-page.ok'),
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  getMonthString(number: number) {
    return this.utilsService.getPayrollMonth(number);
  }

  getMonthNumber(number: number): number {
    return this.utilsService.getMonthNumber(number);
  }

  getQuarterString(number: number) {
    return this.utilsService.getQuarterString(number);
  }

  getQuarterMoment(number: number) {
    return this.utilsService.getQuarterMoment(number);
  }

  checkMinimumDocForTenantRol(): boolean {
    const minDoc: CheckMinimumDoc = {
      student: this.student,
      freelance: this.freelance,
      retired: this.retired,
      business: this.business,
      hasDni: this.minimalDocumentationCheck.hasDni,
      hasRent: this.minimalDocumentationCheck.hasRent,
      hasModel130: this.minimalDocumentationCheck.hasModel130,
      hasIvaQuarterModel: this.minimalDocumentationCheck.hasIvaQuarterModel,
      hasPensionerRecognition:
        this.minimalDocumentationCheck.hasPensionerRecognition,
      hasIs200Model: this.minimalDocumentationCheck.hasIs200Model,
      hasPayslips: this.minimalDocumentationCheck.hasPayslips,
      hasSSCertificate: this.minimalDocumentationCheck.hasSSCertificate,
      hasAEATCertificate: this.minimalDocumentationCheck.hasAEATCertificate,
      hasPYGQuarter: this.minimalDocumentationCheck.hasPYGQuarter,
      hasWorkContract: this.minimalDocumentationCheck.hasWorkContract,
      hasBankStatement: this.minimalDocumentationCheck.hasBankStatement,
      hasRemoteJobCertificate:
        this.minimalDocumentationCheck.hasRemoteJobCertificate,
      hasWorkLife: this.minimalDocumentationCheck?.hasWorkLife,
      hasInvoiceRecordBook:
        this.minimalDocumentationCheck?.hasInvoiceRecordBook,
      hasInvoicePaymentConfirmation:
        this.minimalDocumentationCheck?.hasInvoicePaymentConfirmation
    };
    return this.utilsService.checkMinimumDocForTenantRol(minDoc, this.user);
  }

  showFirstPayslip(): boolean {
    return (
      this.minimalDocumentationCheck.hasNotOptionalPayslip &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  showSecondPayslip(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasOneMonthPayslips &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  showThirdPayslip(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasTwoMonthPayslips &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  showWorkLife(): boolean {
    return (
      this.extraDoc &&
      !this.freelance &&
      !this.student &&
      !this.retired &&
      !this.business
    );
  }

  showBankStatement(): boolean {
    return (
      (!this.minimalDocumentationCheck?.hasBankStatement &&
        this.showForeignerIncomeChips) ||
      (this.extraDoc && !this.freelance && !this.student && !this.business)
    );
  }

  showRentalDeclaration(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasRent &&
      this.freelance &&
      !this.student &&
      !this.extraDoc
    );
  }

  showIvaQuarterModel(): boolean {
    return (
      this.business ||
      (this.freelance &&
        !this.extraDoc &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          this.minimalDocumentationCheck?.hasIvaQuarterModel,
          this.minimalDocumentationCheck?.hasModel130
        ))
    );
  }

  showIvaCard(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasOneQuarterModel &&
      ((this.freelance &&
        !this.extraDoc &&
        this.utilsService.checkTwoIvaQuarterForFreelance(
          this.minimalDocumentationCheck?.hasIvaQuarterModel,
          this.minimalDocumentationCheck?.hasModel130
        )) ||
        this.business)
    );
  }

  showModel130Card(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasModel130 &&
      this.freelance &&
      !this.extraDoc &&
      this.utilsService.checkModel130ForFreelance(
        this.minimalDocumentationCheck?.hasIvaQuarterModel,
        this.minimalDocumentationCheck?.hasModel130
      ) &&
      !this.student
    );
  }

  showWorkContractCard(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasWorkContract &&
      (this.showForeignerIncomeChips || (this.freelance && this.extraDoc))
    );
  }

  showInvoicePaymentConfirmationCard(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasInvoicePaymentConfirmation &&
      this.extraDoc &&
      this.freelance
    );
  }

  showInvoiceRecordBookCard(): boolean {
    return (
      !this.minimalDocumentationCheck?.hasInvoicePaymentConfirmation &&
      this.extraDoc &&
      this.freelance
    );
  }

  ionViewWillLeave() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }
}
