/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
import { Component, Input, OnInit } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { Candidature } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-recalculate-score',
  templateUrl: './recalculate-score.component.html'
})
export class RecalculateScoreComponent implements OnInit {
  rentalPrice: number;
  @Input() candidature: Candidature;
  @Input() haveRequestOrAnalysis: boolean;

  constructor(
    private modalController: ModalController,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.rentalPrice = this.candidature?.asset?.rentalPrice || null;
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  async presentToast(messages: string, success: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: success ? 'success' : 'danger',
      duration: 2000
    });
    toast.present();
  }

  reCalculatedScore(): void {
    this.loadingService.presentSecondLoader(null, true);

    this.candidatureService
      .requestMutipleAnalysis(this.candidature, false, this.rentalPrice)
      .subscribe(
        () => {
          this.loadingService.dismissSecondLoader();
          this.presentToast(
            this.translate.instant(
              'components.delete_account.success'
            ) as string,
            true
          );
          if (!this.haveRequestOrAnalysis) {
            //tenemos solicitudes Analisis acabadas
            //disparamos el recalculo automatico
            //con el banner informativo

            this.presentScoreCalculated();
          }
          this.modalController.dismiss({ saving: true });
        },
        (err) => {
          this.loadingService.dismissSecondLoader();
          console.log(err);
          this.presentToast(
            this.translate.instant('components.delete_account.error') as string,
            false
          );
        }
      );
  }

  private async presentScoreCalculated(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: 'Cambio de precio',
      message:
        'Se ha recalculado con éxito el score con el nuevo precio del activo para esta candidatura. <br/><br/>Ya tienes el informe actualizado para descargar.',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }
}
