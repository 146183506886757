import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsurancesApiService } from '@app/modules/core/api-services/insurances-api/insurances-api.service';
import { Candidature, User } from '@app/modules/core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';

import {
  BankAccountValidation,
  BankAccountValidationStatus,
  ValidateBankAccountModalForm
} from '../../models/bbva-table.model';
import { ButtonsRole } from '../../models/button.model';
import { presentToast } from '../../utils/toast.utils';

@Component({
  selector: 'el-buen-inquilino-guarantee-contract-request-modal',
  templateUrl: './guarantee-contract-request-modal.component.html'
})
export class GuaranteeContractRequestModalComponent implements OnInit {
  form: FormGroup<ValidateBankAccountModalForm>;
  validationErrorMessages = appValidators.validationErrorMessages;

  candidature: Candidature;
  user: User;
  homeownerAccounts: { account: string }[] = [];
  tenantOptions: { email: string; fullName: string }[] = [];

  get accountOwnerControl(): FormControl<string> {
    return this.form.controls.accountOwner;
  }

  get cccControl(): FormControl<string> {
    return this.form.controls.ccc;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private loadingService: LoadingService,
    private insuranceService: InsurancesApiService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.setupHomeownerAccountsOptions();
    this.setupTenantOptions();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL, data: unknown = null): void {
    this.modalController.dismiss(data, role);
  }

  async validate(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    await this.loadingService.presentSecondLoader(null);
    this.insuranceService
      .requestGuaranteeContract(
        this.candidature.id,
        this.form.controls.ccc.value
      )
      .subscribe(
        () => {
          this.loadingService.dismissSecondLoader();
          this.dismiss(ButtonsRole.ACCEPT);
          presentToast(
            this.toastController,
            'La solicitud de pago automático se ha iniciado correctamente.',
            'success'
          );
        },
        () => {
          presentToast(
            this.toastController,
            'Error en la conexión con el servidor. Inténtalo más tarde.',
            'danger'
          );
          this.dismiss();
        }
      );
  }

  cccMaskChanges(event: Event): void {
    this.utilsService.cccMaskChanges(event, this.form, 'ccc');
  }

  private setForm(): void {
    this.form = new FormGroup({
      accountOwner: new FormControl<string>(null, Validators.required),
      ccc: new FormControl<string>(null, [Validators.required])
    });
  }

  private setupHomeownerAccountsOptions(): void {
    // TODO - Veremos cómo mapear más cuentas de empresa
    if (this.user?.ccc) {
      this.homeownerAccounts = [{ account: this.user.ccc }];
    }
  }

  private setupTenantOptions(): void {
    // Mapear las opciones para el ion-select
    this.insuranceService
      .getBankAccountValidations(this.candidature.id)
      .subscribe((accounts: BankAccountValidation[]) => {
        const validatedAccounts = accounts.filter(
          (ac: BankAccountValidation) =>
            ac.response === BankAccountValidationStatus.CONFIRMED
        );
        this.tenantOptions =
          validatedAccounts?.map((tenantAccount: BankAccountValidation) => ({
            email: tenantAccount.email,
            fullName: `${tenantAccount.name} - ${tenantAccount.accountNumber}`
          })) || [];
      });
  }
}
