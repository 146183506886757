import { Pipe, PipeTransform } from '@angular/core';
import { templateStatusList } from '@app/modules/core/utils/templates-api.utils';
import { TemplatesDto,  } from '@core/models';

import { SelectOption } from '../models/select-option.model';


@Pipe({
  name: 'templateStatus'
})
export class TemplateStatusPipe implements PipeTransform {
  templateStatusList: SelectOption[] = templateStatusList;
  transform(template: TemplatesDto): string {
    if (!template.status) {
      return '';
    }
    return (
      this.templateStatusList.find(
        (option: SelectOption) => option.value === template.status
      )?.label || ''
    );
  }
}