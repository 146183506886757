/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { TenantRequestDto } from '@core/models';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-request-invitation',
  templateUrl: './request-invitation.component.html'
})
export class RequestInvitationComponent {
  tenantRequest: TenantRequestDto = new TenantRequestDto();
  success = false;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private userService: UsersApiService,
    private translate: TranslateService
  ) {}

  clickTenantRequest(tenantRequestForm: NgForm): void {
    if (tenantRequestForm.invalid) {
      this.presentToast(
        this.translate.instant('components.request_invitation.remember_fill'),
        'danger'
      );
    } else if (this.validateEmail(tenantRequestForm.value.email)) {
      this.presentToast(
        this.translate.instant('components.request_invitation.email_not_valid'),
        'danger'
      );
    } else {
      this.userService.createTenantRequest(tenantRequestForm.value).subscribe(
        () => {
          this.presentToast(
            this.translate.instant(
              'components.request_invitation.request_sent'
            ),
            'success'
          );
          this.success = true;
        },
        () => {
          this.presentToast(
            this.translate.instant(
              'components.request_invitation.request_sent_fail'
            ),
            'danger'
          );
        }
      );
    }
  }

  validateEmail(email: string): boolean {
    if (email.length > 5 && email.includes('@') && email.includes('.')) {
      return false;
    } else {
      return true;
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
    this.success = false;
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message:
        '<ion-icon name="alert-circle-outline" slot="start"></ion-icon> ' + msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }
}
