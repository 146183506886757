import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, first, Observable } from 'rxjs';

import { AssetDto } from '../../models';

@Injectable()
export class FiltersService {
  originalItems$: BehaviorSubject<AssetDto[]> = new BehaviorSubject<AssetDto[]>(
    []
  );
  items$: BehaviorSubject<AssetDto[]> = new BehaviorSubject<AssetDto[]>([]);
  selectedItems$: BehaviorSubject<AssetDto[]> = new BehaviorSubject<AssetDto[]>(
    []
  );
  datesFilter$: BehaviorSubject<{ month?: string; year: number }> =
    new BehaviorSubject<{ month?: string; year: number }>({
      month: null,
      year: null
    });
  tenantEmailFilter$ = new BehaviorSubject<string>(null);
  tenantDniFilter$ = new BehaviorSubject<string>(null);
  cleanFilters: EventEmitter<boolean> = new EventEmitter<boolean>();

  setOriginalItems(items: AssetDto[]): void {
    this.originalItems$.next(items);
  }

  getOriginalItems(): Observable<AssetDto[]> {
    return this.originalItems$.asObservable();
  }

  setItems(items: AssetDto[]): void {
    this.items$.next(items);
  }

  getItems(): Observable<AssetDto[]> {
    return this.items$.asObservable();
  }

  setSelectedItems(items: AssetDto[]): void {
    this.selectedItems$.next(items);
  }

  getSelectedItems(): Observable<AssetDto[]> {
    return this.selectedItems$.asObservable();
  }

  unshiftAsset(): void {
    this.getOriginalItems()
      .pipe(first())
      .subscribe((items) => {
        // TODO: mirar error al descomentar
        // items.unshift(item);
        this.setOriginalItems(items);
      });
  }

  setDatesFilter(items: { month?: string; year: number }): void {
    this.datesFilter$.next(items);
  }

  getDatesFilter(): Observable<{ month?: string; year: number }> {
    return this.datesFilter$.asObservable();
  }

  setTenantEmail(email: string): void {
    this.tenantEmailFilter$.next(email);
  }

  getTenantEmail(): Observable<string> {
    return this.tenantEmailFilter$.asObservable();
  }

  setTenantDni(dni: string): void {
    this.tenantDniFilter$.next(dni);
  }

  getTenantDni(): Observable<string> {
    return this.tenantDniFilter$.asObservable();
  }
}
