/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Candidature,
  TenantCandidature,
  User,
  RecipientData,
  UserCreateCandidatureDto,
  BackendPayload,
  EnvelopeRequest
} from '@app/modules/core/models';
import { AuthService } from '@app/modules/core/services/auth/auth.service';
import { presentToast } from '@app/modules/shared/utils/toast.utils';
import { ModalController, ToastController } from '@ionic/angular';
import { createRetoolEmbed } from '@tryretool/retool-embed';

import { InsurancesApiService } from '../../../../../../../core/api-services/insurances-api/insurances-api.service';

@Component({
  selector: 'el-buen-inquilino-sign-retool-modal',
  templateUrl: './sign-retool-modal.component.html'
})
export class SignRetoolModalComponent implements OnInit, OnDestroy {
  @Input() status: string;

  @Input() candidature: Candidature;

  user: User;

  selectedTemplate: string;

  envelopeToSend: EnvelopeRequest = undefined;

  receivedData = null;
  retoolUrl =
    'https://elbueninquilino.retool.com/embedded/public/4be7f151-537b-479a-8f92-19b6f6e2e2de?_embed=true';

  // Data to be sent to Retool app
  recipientData: RecipientData;

  data = {
    contractData: {}
  };

  contractData = {};

  combinedData = {};

  url = this.retoolUrl;

  embeddedRetool: any;

  constructor(
    private modalController: ModalController,
    private elementRef: ElementRef,
    private auth: AuthService,
    private insuranceService: InsurancesApiService,
    private toastController: ToastController
  ) {
    this.setUser();
  }

  ngOnInit(): void {
    this.fillFirstRequestToRetool();
    this.createRetoolEmbed();
    window.addEventListener('message', this.handleMessage.bind(this), false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleMessage.bind(this));
  }

  createRetoolEmbed(): void {
    // Clear the container first
    this.elementRef.nativeElement.innerHTML = '';

    const container = document.createElement('div');
    this.elementRef.nativeElement.appendChild(container);

    // Create Retool Embed
    this.embeddedRetool = createRetoolEmbed({
      src: this.retoolUrl,
      data: this.combinedData,
      style: 'width: 100vw; height: 100vh; border: 1px solid blue;',
      onData: (data: any) => {
        this.handleRetoolData(data);

        // Handle file download data
        if (data && data.fileUrl) {
          this.downloadFile(data.fileUrl); // Trigger file download
        }
      }
    });

    container.appendChild(this.embeddedRetool);
  }

  handleRetoolData(data: any): void {
    if (data.type === 'object') {
      this.receivedData = data;
    }
    if (!!data.envelopeData) {
      this.envelopeToSend = {
        envelopeId: data.envelopeData.envelopeId,
        modelName: data.templateValue,
        contractName: data.contractName,
        tenantEmails: this.candidature.tenantCandidatureList.map(
          (it) => it.user.email
        )
      };
    }
  }

  handleMessage(event: MessageEvent): void {
    if (event.origin === 'https://elbueninquilino.retool.com') {
      this.receivedData = event.data;
      if (this.receivedData === 'sent' && !!this.envelopeToSend) {
        this.envelopeToSend.tenantEmails =
          this.candidature.tenantCandidatureList.map((it) => it.user.email);
        this.insuranceService
          .addEnvelopeToCandidature(this.candidature.id, this.envelopeToSend)
          .subscribe(() => {
            presentToast(
              this.toastController,
              'Documento enviado a firma con éxito.',
              'success',
              5000
            );
            this.dismiss();
          });
      }

      if (this.receivedData === 'discarded') {
        presentToast(
          this.toastController,
          'Has rechazado enviar un documento a firma.',
          'warning',
          5000
        );
        this.dismiss();
      }

      if (event.data.changeTemplate) {
        // Nos quedamos con el id del template seleccionado
        this.selectedTemplate = event.data.selectedId;
        const backendPayload: BackendPayload = {
          selectedTemplate: this.selectedTemplate,
          candidaturesId: this.candidature.id,
          properties: []
        };
        this.initFormParametersFromBackend(backendPayload);
      }

      // Check if we need to change the iframe URL based on received data
      if (event.data.changePage) {
        const newUrl =
          'https://elbueninquilino.retool.com/embedded/public/691d4602-84db-4d82-995b-cd223071f092';
        this.retoolUrl = newUrl;
        const emailData = { email: this.user.retoolAccount }; // ???
        window.parent.postMessage(emailData, '*');
        this.createRetoolEmbed(); // Recreate the Retool embed with the new URL
      }

      const message = event.data;
      if (message.type === 'pdfDownload') {
        this.downloadPDF(message.data);
      }
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  private getTenantDataList(): string {
    const tenants = this.candidature.tenantCandidatureList.map(
      (tc: TenantCandidature) => tc.user
    );
    if (!!tenants) {
      let tenantInfoStr = '';
      tenants.map((user: User) => {
        tenantInfoStr += ` ${user.firstname} ${user.surname1} (DNI - ${user.dni}),`;
      });
      // Elimina la última coma
      tenantInfoStr = tenantInfoStr.substring(0, tenantInfoStr.length - 1);
      return tenantInfoStr;
    }
    return '';
  }

  private fillFirstRequestToRetool(): void {
    const tenants = this.candidature.tenantCandidatureList.map(
      (tc: TenantCandidature) => tc.user
    );
    if (!!tenants) {
      this.recipientData = {
        recipients: {
          templateId: null,
          templateRoles: tenants.map((user: User, index: number) => ({
            email: user.email,
            name: `${user.firstname} ${user.surname1}`,
            roleName: 'signer',
            tabs: {
              signHereTabs: [
                {
                  anchorString: `/signature_${index + 1}/`,
                  anchorUnits: 'pixels',
                  anchorXOffset: '0',
                  anchorYOffset: '0',
                  status: 'active'
                }
              ]
            }
          })),
          status: 'created'
        }
      };

      this.combinedData = {
        ...this.data,
        ...this.recipientData,
        email: {
          email: this.user.retoolAccount
        }
      };
    }
  }

  downloadFile(url: string): void {
    // Create an anchor element
    const a = document.createElement('a');

    // Set the file URL and necessary attributes
    a.href = url;
    a.target = '_blank'; // Open in a new tab (if needed)
    a.download = url.split('/').pop() || 'downloaded-file'; // Filename fallback

    // Append anchor to the DOM
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor after the click
    document.body.removeChild(a);
  }

  downloadPDF(base64Data: string): void {
    const binaryString = window.atob(base64Data); // Decode base64 to binary string
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    // Convert binary string to array of bytes
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create an anchor element for downloading the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'document.pdf'; // Set the file name

    // Append the anchor to the DOM and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after download
  }

  private initFormParametersFromBackend(payload: BackendPayload): void {
    this.insuranceService.getModelData(payload).subscribe((data: any) => {
      this.contractData = data.fieldsMap;
      this.data = {
        contractData: this.contractData
      };

      this.recipientData.recipients.templateId = payload.selectedTemplate;

      this.combinedData = {
        ...this.data,
        ...this.recipientData,
        email: {
          email: this.user.retoolAccount
        }
      };

      this.embeddedRetool.data = this.combinedData;
    });
  }

  private setUser(): void {
    this.user = this.auth.user;
  }

  private formatPhoneNumber(phone: string): string | null {
    if (!phone) {
      return null; // Si el teléfono es null o undefined
    }

    // Eliminar cualquier carácter que no sea un dígito o un signo de + al inicio
    let cleanedPhone = phone.replace(/[^\d+]/g, '');

    // Asegurarnos de que solo hay un '+' al inicio, si es internacional
    if (cleanedPhone.startsWith('+')) {
      cleanedPhone = '+' + cleanedPhone.slice(1).replace(/\+/g, ''); // Quitar cualquier '+' adicional en el resto
    }

    // Si el resultado es solo un + sin números, lo invalidamos
    if (cleanedPhone === '+') {
      return null;
    }

    // Si no tiene un '+' al inicio, lo retornamos como string de solo dígitos
    return cleanedPhone;
  }

  private numeroALetras(num: number): string {
    const unidades = [
      '',
      'UNO',
      'DOS',
      'TRES',
      'CUATRO',
      'CINCO',
      'SEIS',
      'SIETE',
      'OCHO',
      'NUEVE'
    ];
    const decenas = [
      '',
      '',
      'VEINTE',
      'TREINTA',
      'CUARENTA',
      'CINCUENTA',
      'SESENTA',
      'SETENTA',
      'OCHENTA',
      'NOVENTA'
    ];
    const centenas = [
      '',
      'CIEN',
      'DOSCIENTOS',
      'TRESCIENTOS',
      'CUATROCIENTOS',
      'QUINIENTOS',
      'SEISCIENTOS',
      'SETECIENTOS',
      'OCHOCCIENTOS',
      'NOVECIENTOS'
    ];

    const entero = Math.floor(num);
    const decimal = Math.round((num - entero) * 100); // Obtener los centavos

    const convertirEntero = (num: number): string => {
      if (num < 10) {
        return unidades[num];
      } else if (num >= 10 && num < 20) {
        const especiales = [
          '',
          'DIEZ',
          'ONCE',
          'DOCE',
          'TRECE',
          'CATORCE',
          'QUINCE',
          'DIECISEIS',
          'DIECISETE',
          'DIECIOCHO',
          'DIECINUEVE'
        ];
        return especiales[num - 10];
      } else if (num >= 20 && num < 100) {
        return (
          decenas[Math.floor(num / 10)] +
          (num % 10 > 0 ? ' Y ' + unidades[num % 10] : '')
        );
      } else if (num >= 100 && num < 1000) {
        return (
          centenas[Math.floor(num / 100)] +
          (num % 100 > 0 ? ' ' + convertirEntero(num % 100) : '')
        );
      } else if (num >= 1000 && num < 10000) {
        const mil =
          Math.floor(num / 1000) > 1 ? unidades[Math.floor(num / 1000)] : '';
        return (
          (mil ? mil + ' MIL' : 'MIL') +
          (num % 1000 > 0 ? ' ' + convertirEntero(num % 1000) : '')
        );
      } else {
        return 'NÚMERO DEMASIADO GRANDE';
      }
    };

    const enteroEnLetras = convertirEntero(entero);
    let resultado = enteroEnLetras.trim();

    // Agregar parte decimal si existe
    if (decimal > 0) {
      resultado += ` CON ${decimal} CÉNTIMOS`;
    }

    return resultado;
  }

  private getFullAddress(): string {
    return `${this.candidature.asset.street} ${parseInt(this.candidature.asset.number).toString()}, Portal ${this.candidature.asset.portal} - ${this.candidature.asset.floor}º${this.candidature.asset.door}`;
  }

  private getUserData(user: User | UserCreateCandidatureDto): string {
    return `${user.firstname} ${user.surname1} ${user.surname2} (${user.dni})`;
  }
}
