/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { Component, Input } from '@angular/core';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import { Incidence, User } from '@core/models';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-incidences-modal',
  templateUrl: './incidences-modal.component.html'
})
export class IncidencesModalComponent {
  @Input() incidenceList: Incidence[];
  @Input() user: User;
  @Input() analysisId: string;
  @Input() finaer = false;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private usersService: UsersApiService,
    private translateService: TranslateService
  ) {}

  dismiss(saving?): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  checkIncidence(incidence: Incidence): boolean {
    return (
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_dni'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_payroll'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_statement_of_income'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_recognition_pension'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_corporation_tax'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_quarterly_vat'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_model_130'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_ss_certificate'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_aeat_certificate'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_pyg_quarter'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_incongruity'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_price'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_address'
        ) ||
      incidence.type ===
        this.translateService.instant(
          'components.incidences_modal.incidence_other'
        )
    );
  }

  resolveIncidence(incidence: Incidence): void {
    this.usersService
      .closeRequestAnalysisIncidence(this.analysisId, incidence)
      .subscribe(() => {
        this.dismiss(true);
        this.presentToast(
          this.translateService.instant(
            'components.incidences_modal.check_as_resolved_ok'
          ),
          'success'
        );
      });
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }
}
