/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Candidature, ChangeTenantEmailObj } from '@core/models';
import { ModalController } from '@ionic/angular';
import * as appValidators from '@shared/utils/app-validators.utils';

@Component({
  selector: 'el-buen-inquilino-edit-tenant-email-modal',
  templateUrl: './edit-tenant-email-modal.component.html'
})
export class EditTenantEmailModalComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() tenantEmail: string;
  newTenantMail: string;
  changeEmailForm: UntypedFormGroup;
  changeTenantEmailObj: ChangeTenantEmailObj = new ChangeTenantEmailObj();
  isDisabledButton = false;

  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm(): void {
    this.changeEmailForm = this.formBuilder.group({
      email: [this.tenantEmail, [Validators.required]],
      newTenantEmail: [
        null,
        [Validators.required, appValidators.emailValidator]
      ],
      candidatureId: [this.candidature.id, [Validators.required]]
    });
  }

  dismiss(changeTenantEmailObj?: ChangeTenantEmailObj): void {
    this.modalController.dismiss({
      dismissed: true,
      changeTenantEmailObj
    });
  }

  checksaveAndSendNewEmail(): boolean {
    return (
      this.changeEmailForm.valid &&
      !(
        this.changeEmailForm.controls.newTenantEmail.value ===
        this.changeEmailForm.controls.email.value
      )
    );
  }

  saveAndSendNewEmail(): void {
    this.changeTenantEmailObj.email = this.changeEmailForm.controls.email.value;
    this.changeTenantEmailObj.newTenantEmail =
      this.changeEmailForm.controls.newTenantEmail.value;
    this.changeTenantEmailObj.candidatureId =
      this.changeEmailForm.controls.candidatureId.value;

    this.dismiss(this.changeTenantEmailObj);
  }
}
