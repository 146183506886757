import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-building-utils-popover',
  templateUrl: './building-utils-popover.component.html'
})
export class BuildingUtilsPopoverComponent {
  @Input() showInBackoffice = false;

  constructor(private popoverController: PopoverController) {}

  onClickUtilOptions(options: string): void {
    this.popoverController.dismiss({
      options: options
    });
  }
}
