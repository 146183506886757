/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  BrokerDto,
  CalculatedPremiumAragDto,
  Candidature,
  DocumentDTO,
  InsuranceTenantRequestDto,
  PolicyIssue,
  TenantCandidature,
  TypeDocumentToCandidatureEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { SignRetoolModalComponent } from '@private/modules/tenant/modules/candidature/components/sign-retool-modal/sign-retool-modal.component';
import { finalize, first, tap } from 'rxjs';

import { BankAccountValidation } from '../../models/bbva-table.model';
import { ButtonsRole } from '../../models/button.model';
import { SelectedCandidatureHistoryState } from '../../models/selected-candidature.model';
import { copyObject } from '../../utils/global.utils';
import { base64ToBlob } from '../../utils/sinister.utils';
import { presentToast } from '../../utils/toast.utils';
import { AddAragInsuranceComponent } from '../add-arag-insurance/add-arag-insurance.component';
import { AddDocumentToCandidatureComponent } from '../add-document-to-candidature/add-document-to-candidature.component';
import { AddTenantInsuranceComponent } from '../add-tenant-insurance/add-tenant-insurance.component';
import { DocumentModalComponent } from '../document-modal/document.modal.component';
import { ValidateBankAccountModalComponent } from '../validate-bank-account-modal/validate-bank-account-modal.component';

@Component({
  selector: 'el-buen-inquilino-candidature-information',
  templateUrl: './candidature-information.component.html'
})
export class CandidatureInformationComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() partialAddress: string;
  @Input() secondAddress: string;

  totalYearToNonPaymentPolicy: {
    estimated: number;
    contracted: number;
  } = {
    estimated: 0,
    contracted: 0
  };

  totalYearToTenantHomePolicy: {
    estimated: number;
    contracted: number;
  } = {
    estimated: 140,
    contracted: 0
  };

  initialPrice: number;
  user: User;
  documentsCandidature: DocumentDTO[] = [];

  insuranceCompanyCode = 'ARAG';
  canContractTenantInsurances = false;
  insuranceBrokerPaid = false;
  isBackoffice = false;
  bankAccountData: BankAccountValidation[] = [];

  get nonPaymentPolicy(): string {
    return this.candidature.policyNumber;
  }

  get tenantHomePolicy(): string {
    return this.candidature.tenantPolicyNumber;
  }

  get colorScore(): string {
    return this.candidatureService.updateColor(this.candidature.score);
  }

  #client: User;
  #typeDocumentEnumEnabled = copyObject(TypeDocumentToCandidatureEnum);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private candidatureService: CandidaturesApiService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private toastController: ToastController,
    private modalController: ModalController,
    private usersService: UsersApiService,
    private utilsService: UtilsService,
    private insuranceService: InsurancesApiService
  ) {}

  ngOnInit(): void {
    this.setClient();
    this.setUser();
    this.checkTotalYearToPolicies();
    this.getInsuranceCompanyCode();
    this.getDocumentsByCandidature();
    this.getValidatedBankAccountData();
  }

  showOffersWrapper(): boolean {
    return (
      (this.canContractTenantInsurances || this.candidature.score >= 80) &&
      !this.user?.blockCreatePolicy &&
      !this.isBackoffice
    );
  }

  metadataDocument(document: DocumentDTO, attribute: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return document.metadata[attribute] === 'null'
      ? ''
      : document.metadata[attribute];
  }

  generatePayment(): void {
    this.showToast(
      'No tienes contratado este servicio. Póngase en contacto con EBI Soporte para su activación.',
      'warning'
    );
  }

  openRequestForHiringHomeOwnerPolicy(): void {
    this.showToast(
      'Para solicitar esta modalidad de póliza, ponte en contacto con EBI Soporte.',
      'warning'
    );
  }

  /* MODAL UPLOAD DOCUMENT */
  async presentUploadDocumentsToCandidatureModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: AddDocumentToCandidatureComponent,
      cssClass: 'auto-height',
      componentProps: {
        candidature: this.candidature,
        typeDocumentEnum: this
          .#typeDocumentEnumEnabled as TypeDocumentToCandidatureEnum
      } as unknown as Partial<AddDocumentToCandidatureComponent>
    });

    modal.onDidDismiss().then((data: OverlayEventDetail) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (data.data?.dismissed) {
        this.getDocumentsByCandidature();
      }
    });

    modal.present();
  }

  /* DOCUMENTS */
  async loadViewDocumentModal(document: DocumentDTO): Promise<void> {
    const modal = await this.modalController.create({
      component: DocumentModalComponent,
      cssClass: 'custom-modal-xl',
      componentProps: {
        pdfSrc: base64ToBlob(document.inputStream, document.extension),
        documentData: document,
        downloadable: true,
        extension: document.extension
      } as Partial<DocumentModalComponent>
    });

    await modal.present();
  }

  downloadDocument(document: DocumentDTO): void {
    this.utilsService.downloadPDF(
      `${document.metadata?.type}.${document.extension}`,
      base64ToBlob(document.inputStream, document.extension)
    );
  }

  deleteDocument(document: DocumentDTO): void {
    this.candidatureService
      .deleteDocument(this.candidature.id, document.metadata?.type)
      .subscribe({
        next: () => {
          this.getDocumentsByCandidature();
          this.showToast('Documento eliminado con éxito', 'success');
        },
        error: () => this.showToast('Error al eliminar el documento', 'danger')
      });
  }

  /* POLICY NON PAYMENT */
  downloadPolicy(numberPolicy: string): void {
    this.loadingService.presentSecondLoader(null);

    this.candidatureService
      .getPolicyReport(this.candidature.homeowner?.user?.id, numberPolicy)
      .pipe(
        first(),
        finalize(() => this.loadingService.dismissSecondLoader())
      )
      .subscribe({
        next: (blob: Blob) => this.createAndDownloadBlob(blob),
        error: () => this.presentToastCanNotDownload()
      });
  }

  async openRequestForHiringNonPaymentPolicy(): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';

    const modal = await this.modalController.create({
      component: AddAragInsuranceComponent,
      id: 'addAragInsurance',
      cssClass: `${isAutoHeight} custom-over-modal modal-extend`,
      componentProps: {
        candidature: this.candidature,
        totalAnnualReceipt: this.totalYearToNonPaymentPolicy.estimated,
        initialPrice: this.initialPrice,
        insuranceCompanyCode: this.insuranceCompanyCode,
        originalPolicyNumber: this.nonPaymentPolicy,
        client: this.#client,
        insuranceBrokerPaid: this.insuranceBrokerPaid
      }
    });

    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<{ saving?: boolean }>) => {
        if (close?.data?.saving) {
          this.return();
        }
      });

    return await modal.present();
  }

  /* POLICY TENANT HOME */
  downloadTenantHomePolicy(): void {
    this.loadingService.presentSecondLoader(null);

    this.candidatureService
      .downloadTenantPolicy(this.tenantHomePolicy)
      .pipe(
        first(),
        finalize(() => this.loadingService.dismissSecondLoader())
      )
      .subscribe({
        next: (blob: Blob) => this.createAndDownloadBlob(blob),
        error: () => this.presentToastCanNotDownload()
      });
  }

  async openRequestForHiringTenantHomePolicy(): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';

    const modal = await this.modalController.create({
      component: AddTenantInsuranceComponent,
      cssClass: `${isAutoHeight} modal-extend modal-backdrop`,
      componentProps: {
        candidature: this.candidature,
        totalAnnualReceipt: this.totalYearToNonPaymentPolicy.estimated
      },
      showBackdrop: true
    });

    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<{ saving?: boolean }>) => {
        if (close?.data?.saving) {
          this.candidature.tenantPolicyNumber = 'DRAFT';
          this.showToast(
            'Se ha enviado una solicitud de contratación de póliza a los miembros',
            'success'
          );
        }
      });

    return await modal.present();
  }

  openRequestForResendTenantHomePolicy(): void {
    const notification = new InsuranceTenantRequestDto();

    // eslint-disable-next-line max-len
    notification.address = `${this.candidature.asset.street} ${this.candidature.asset.number}, ${this.candidature.asset.town} (${this.candidature.asset.province})`;

    this.candidature.tenantCandidatureList?.forEach(
      (userCandidature: TenantCandidature) => {
        notification.email = userCandidature.user.email;
        notification.firstname = userCandidature.user.firstname;
        this.usersService.resendTenantPolicyRequest(notification).subscribe();
      }
    );

    this.showToast('Invitaciones reenviadas con éxito', 'success');
  }

  /* CANCEL POLICY */
  openRequestPolicyCancelation(policyNumber: string): void {
    this.candidatureService.openCancelPolicyRequestModal(
      'DEFAULT',
      policyNumber,
      false,
      this.candidature
    );
  }

  async signNewDocument(): Promise<void> {
    const modal = await this.modalController.create({
      component: SignRetoolModalComponent,
      cssClass: 'full-screen-modal',
      componentProps: {
        status: this.candidature.candidatureStatusEnum,
        candidature: this.candidature
      } as Partial<SignRetoolModalComponent>
    });

    modal
      .onDidDismiss()
      .then(() => this.insuranceService.nextSignedDocumentsObs());
  }

  async showValidateBankAccountModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ValidateBankAccountModalComponent,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        candidature: this.candidature
      }
    });

    modal.onDidDismiss().then(({ role }: OverlayEventDetail<unknown>) => {
      if (role === (ButtonsRole.ACCEPT as string)) {
        this.getValidatedBankAccountData();
      }
      this.loadingService.dismissSecondLoader();
    });
    modal.present();
  }

  private createAndDownloadBlob(blob: Blob): void {
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);

    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert(
        this.translate.instant(
          'pages.profile.home-owner.assets.disable_popup_blocker'
        )
      );
    }
  }

  private presentToastCanNotDownload(): void {
    this.showToast(
      this.translate.instant(
        'components.selected-candidature-modal.can_not_download'
      ) as string,
      'danger'
    );
  }

  /* SET DATA */
  private setUser(): void {
    this.user = this.authService.user;
  }

  private setClient(): void {
    if (!!(history.state as SelectedCandidatureHistoryState).client) {
      this.#client = (history.state as SelectedCandidatureHistoryState).client;
    }
  }

  /* POLICIES */
  private checkTotalYearToPolicies(): void {
    if (!this.nonPaymentPolicy) {
      this.callTotalYearEstimatedToNonPaymentPolicy();
    }

    if (this.nonPaymentPolicy) {
      this.calculateTotalYearContractedToNonPaymentPolicy();
    }

    if (this.tenantHomePolicy) {
      this.calculateTotalYearContractedToToTenantHomePolicy();
    }
  }

  private callTotalYearEstimatedToNonPaymentPolicy(): void {
    if (this.calculateConditionsToOfferPolicy()) {
      this.candidatureService
        .getCalculatedPremiumArag(this.candidature.id, this.#client?.id)
        .pipe(first())
        .subscribe((resp: CalculatedPremiumAragDto) => {
          if (resp && resp?.totalAnnualReceipt) {
            this.totalYearToNonPaymentPolicy.estimated =
              resp.totalAnnualReceipt;

            if (resp?.initialPrice) {
              this.initialPrice = resp.initialPrice;

              if (resp.initialPrice < resp.totalAnnualReceipt) {
                this.totalYearToNonPaymentPolicy.estimated = resp.initialPrice;
              }
            }
          }
        });
    }
  }

  private calculateTotalYearContractedToNonPaymentPolicy(): void {
    this.candidatureService
      .getContractedPolicyByPolicyNumber(this.nonPaymentPolicy)
      .pipe(first())
      .subscribe(
        (response: PolicyIssue) =>
          (this.totalYearToNonPaymentPolicy.contracted =
            response?.totalAnnualReceipt)
      );
  }

  private calculateTotalYearContractedToToTenantHomePolicy(): void {
    this.candidatureService
      .getContractedPolicyByPolicyNumber(this.tenantHomePolicy)
      .pipe(first())
      .subscribe((response: PolicyIssue) => {
        this.totalYearToTenantHomePolicy.contracted = response?.netPremium ?? 0;
      });
  }

  private calculateConditionsToOfferPolicy(): boolean {
    return (
      !this.nonPaymentPolicy &&
      this.candidature.score &&
      this.candidature.score >= 80 &&
      !this.candidature.blockPolicy
    );
  }

  private getInsuranceCompanyCode(): void {
    let userId = this.user?.id;

    if (!!this.#client) {
      userId = this.#client.id;
    }

    this.candidatureService
      .getBrokerCodeByUserId(userId)
      .subscribe((broker: BrokerDto) => {
        this.insuranceCompanyCode = broker?.insuranceCode;
        this.canContractTenantInsurances = broker?.canContractTenantInsurances;
        // TODO - Retomar cuando Caser conteste
        //this.insuranceBrokerPaid = broker?.insuranceBrokerPaid;
      });
  }

  private getDocumentsByCandidature(): void {
    this.candidatureService
      .getDocumentsByCandidature(this.candidature.id)
      .pipe(
        tap((response: DocumentDTO[]) => {
          const typeDocumentAux = copyObject(TypeDocumentToCandidatureEnum);

          response.forEach((document: DocumentDTO) => {
            delete typeDocumentAux[document.metadata.type];
          });

          this.#typeDocumentEnumEnabled = typeDocumentAux;
        })
      )
      .subscribe(
        (response: DocumentDTO[]) => (this.documentsCandidature = response)
      );
  }

  private return(): void {
    this.router.navigate(['../../..'], {
      relativeTo: this.route
    });
  }

  private showToast(
    message: string,
    color: 'success' | 'danger' | 'warning'
  ): void {
    presentToast(this.toastController, message, color);
  }

  private getValidatedBankAccountData(): void {
    this.insuranceService
      .getBankAccountValidations(this.candidature.id)
      .subscribe((validations: BankAccountValidation[]) => {
        this.bankAccountData = validations;
        this.bankAccountData = copyObject(
          this.bankAccountData
        ) as BankAccountValidation[];
      });
  }
}
