/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @angular-eslint/component-selector */
import { DecimalPipe } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgForm,
  Validators,
  FormControl
} from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { ScoreResponseDto, ValidateUserDto } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { TrialFlowService } from '@core/services/trial-flow/trial-flow.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';

import { VerifyDniAlertModalComponent } from '../verifiy-dni-alert-modal/verify-dni-alert-modal.component';

@Component({
  providers: [DecimalPipe],
  selector: 'select-tenants-step',
  templateUrl: './select-tenants-step.html',
  styleUrls: ['./select-tenants-step.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SelectTenantsStepComponent implements OnInit {
  @Output() selectedUser = new EventEmitter();
  @Output() deletedUser = new EventEmitter();
  tenantForm: UntypedFormGroup;
  verifyError = false;
  verifySuccess = false;
  verifySuccessText = '';
  notUniqueUserError = false;
  verificationButtonText: string;
  titleForm: string;
  users: ValidateUserDto[] = [];
  showInput = true;
  user: ValidateUserDto;
  addingGuarantor = false;
  isDisabledButton = false;
  verifyDniCounter = 0;

  validationErrorMessages = appValidators.validationErrorMessages;

  get dniControl(): FormControl {
    return this.tenantForm.get('dni') as FormControl;
  }

  constructor(
    public trialService: TrialFlowService,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    private changeDetection: ChangeDetectorRef,
    private candidatureService: CandidaturesApiService,
    private decimalPipe: DecimalPipe,
    private modalController: ModalController,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.setTexts();
    this.initForm();
  }

  setTexts(): void {
    this.verificationButtonText = this.translateService.instant(
      'components.select_tenants_step.verify_tenant'
    );
    this.titleForm = this.translateService.instant(
      'components.select_tenants_step.tenant_data'
    );
  }

  initForm(): void {
    this.tenantForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      dni: ['', [Validators.required, appValidators.dniAndNieValidator]],
      surname1: ['', [Validators.required]],
      surname2: ['']
    });
  }

  validateUser(): void {
    if (this.tenantForm.valid) {
      this.loadingService.presentSecondLoader(
        this.translateService.instant(
          'components.select_tenants_step.verifying_tenant'
        ),
        true
      );
      this.isDisabledButton = true;
      this.notUniqueUserError = false;
      this.trialService.invalidTenant = false;
      this.trialService.invalidGuarantor = false;
      this.trialService.invalidOwner = false;
      const validateUser: ValidateUserDto = {
        dni: this.tenantForm.value.dni,
        firstname: this.tenantForm.value.firstname,
        surname1: this.tenantForm.value.surname1,
        surname2: this.tenantForm.value.surname2,
        guarantor: this.addingGuarantor
      };
      this.trialService.verifyTenantTrialFlow(validateUser).then((res) => {
        if (res.error) {
          this.verifyError = true;
          this.verifySuccess = false;
          this.isDisabledButton = false;
          this.loadingService.dismissSecondLoader();
          this.verifyDniCounter++;
          if (this.verifyDniCounter > 1) {
            this.showPopupDniAlert(validateUser);
          }
        } else if (!this.isUniqueValidatedUser(validateUser)) {
          this.notUniqueUserError = true;
          this.verifySuccess = false;
          this.verifyError = false;
          this.isDisabledButton = false;
          this.initForm();
          this.loadingService.dismissSecondLoader();
        } else {
          this.verifyDniCounter = 0;
          this.verifySuccess = true;
          this.verifyError = false;
          this.verifySuccessText = this.translateService.instant(
            'components.select_tenants_step.verifying_success',
            {
              firstname: validateUser.firstname,
              surname1: validateUser.surname1,
              surname2: validateUser.surname2 ?? '',
              value: this.decimalPipe.transform(res.value, '1.0-1')
            }
          );
          this.showInput = false;
          validateUser.score = {} as ScoreResponseDto;
          validateUser.score.value = res.value;
          this.users.push(validateUser);
          this.changeDetection.detectChanges();
          this.user = validateUser;
          this.selectedUser.emit(this.user);
          this.isDisabledButton = false;
          this.loadingService.dismissSecondLoader();
        }
      });
    } else {
      this.presentToast(
        this.translateService.instant(
          'components.select_tenants_step.fill_fields'
        ),
        true
      );
    }
  }

  removeUser(index: number): void {
    this.tenantForm.reset();
    this.notUniqueUserError = false;
    this.verifyError = false;
    this.verifySuccess = false;
    this.users.splice(index, 1);
    if (this.users.length === 0) {
      this.showInput = true;
    }
    this.deletedUser.emit(index);
    this.user = null;
  }

  addAnotherUser(guarantor: boolean = false): void {
    this.tenantForm.reset();
    this.showInput = true;
    this.trialService.invalidTenant = false;
    this.notUniqueUserError = false;
    this.verifyError = false;
    this.verifySuccess = false;
    this.user = null;
    if (guarantor) {
      this.addingGuarantor = true;
      this.titleForm = this.translateService.instant(
        'components.select_tenants_step.guarantor_data'
      );
      this.verificationButtonText = this.translateService.instant(
        'components.select_tenants_step.guarantor_button'
      );
    } else {
      this.addingGuarantor = false;
      this.titleForm = this.translateService.instant(
        'components.select_tenants_step.tenant_data'
      );
      this.verificationButtonText = this.translateService.instant(
        'components.select_tenants_step.tenant_button'
      );
    }
  }

  isUniqueValidatedUser(user: ValidateUserDto): boolean {
    if (user) {
      return !(this.users.filter((it) => it.dni === user.dni).length > 0);
    } else {
      return false;
    }
  }

  updateColor(progress): string {
    return this.candidatureService.updateColor(progress);
  }

  async showPopupDniAlert(validateUser: ValidateUserDto): Promise<void> {
    const recoveryAnalysisModal = await this.modalController.create({
      component: VerifyDniAlertModalComponent,
      cssClass: 'auto-height modal-extend custom-over-modal',
      id: 'recoveryAnalysis'
    });
    recoveryAnalysisModal.onDidDismiss().then((close) => {
      if (close?.data?.saving) {
        const continueFlag: boolean = close.data.data.continue;
        if (continueFlag) {
          this.verifyDniCounter = 0;
          this.verifySuccess = true;
          this.verifyError = false;
          this.showInput = false;
          validateUser.noValidated = true;
          this.verifySuccessText = 'Inquilino añadido con su DNI sin verificar';
          this.users.push(validateUser);
          this.changeDetection.detectChanges();
          this.user = validateUser;
          this.selectedUser.emit(this.user);
          this.isDisabledButton = false;
          this.loadingService.dismissSecondLoader();
        }
      }
    });
    await recoveryAnalysisModal.present();
  }

  async presentToast(messages: any, error: boolean): Promise<void> {
    const toast = await this.toastController.create({
      message: messages,
      position: 'top',
      color: error ? 'danger' : 'success',
      duration: 2000
    });
    toast.present();
  }

  /**
   * Se hace trim al valor del input
   * @param input Form control del fomulario
   */
  deleteSpacesFormControl(input: string): void {
    appValidators.deleteSpacesFormControl(this.tenantForm, input);
  }
}
