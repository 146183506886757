import { FormControl } from '@angular/forms';
import { Candidature, User } from '@core/models';

export interface CandidatureNotesPipeArgs {
  pipeFunction: CandidatureNotesFunction;
  user: User;
  userId?: string;
  users?: User[];
}

export enum CandidatureNotesFunction {
  OWN,
  WRITER_NAME,
  MSG_DATE
}

export interface CandidatureNotesForm {
  message: FormControl<string>;
}

export interface CandidatureNotesModalData {
  candidature: Candidature;
  user: User;
}
