import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { AssetAnalytics, GeneralAnalytics, UserAnalytics } from '../../models';

@Injectable()
export class AnalyticsApiService {
  url: string;
  homeownersRawData: Map<string, UserAnalytics[]> = new Map();
  selectedUserAnalytics: UserAnalytics;
  dateFiltered: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.analytics;
  }

  getGeneralAnalyticsByDefault(
    requestDate?: string
  ): Observable<GeneralAnalytics[]> {
    if (requestDate) {
      return this.http.get<GeneralAnalytics[]>(
        this.url + '/generalAnalytics/byRangeDate?fromDate=' + requestDate
      );
    } else {
      return this.http.get<GeneralAnalytics[]>(
        this.url + '/generalAnalytics/byRangeDate'
      );
    }
  }

  getAllUsersResume(requestDate?: string): Observable<UserAnalytics[]> {
    if (requestDate) {
      return this.http.get<UserAnalytics[]>(
        this.url + '/userAnalytics/byRangeDate?fromDate=' + requestDate
      );
    } else {
      return this.http.get<UserAnalytics[]>(
        this.url + '/userAnalytics/byRangeDate'
      );
    }
  }

  getHomeownerSales(
    homeownerId: string,
    requestDate: string
  ): Observable<AssetAnalytics[]> {
    return this.http.get<AssetAnalytics[]>(
      this.url +
        '/userAnalytics/byHomeowner?fromDate=' +
        requestDate +
        '&homeownerId=' +
        homeownerId
    );
  }

  updateInvoiceStatus(invoicedList: unknown[]): Observable<unknown> {
    return this.http.patch(
      environment.services.users + '/requests-analysis/invoiced',
      invoicedList
    );
  }
}
