import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { Candidature, CustomModalButtonRole } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { TenantListPopoverComponent } from '@shared/components/tenant-list-popover/tenant-list-popover.component';
import * as appValidators from '@shared/utils/app-validators.utils';
import { tableSize } from '@shared/utils/global.utils';
import { first } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-link-tenants',
  templateUrl: './link-tenants.component.html'
})
export class LinkTenantsComponent implements OnInit {
  @Input() selectedCandidature: Candidature;

  form: FormGroup<{ email: FormControl<string> }>;
  // TABLA
  columns: string[] = ['address', 'portal', 'floor', 'door', 'city', 'tenants'];
  dataSource = new MatTableDataSource<Candidature>([]);
  @ViewChild('paginator') paginator: MatPaginator;
  tableSize = tableSize;
  // END TABLA

  get disabledSelectButton(): boolean {
    if (!this.selectedCandidature) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService,
    private popoverController: PopoverController
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(role: CustomModalButtonRole, data?: Candidature): void {
    this.modalController.dismiss(data, role);
  }

  close(): void {
    this.dismiss(CustomModalButtonRole.CANCEL);
  }

  select(): void {
    this.dismiss(CustomModalButtonRole.ACCEPT, this.selectedCandidature);
  }

  async searchByEmail(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .getCandidaturesByTenantEmail(this.form.value.email)
      .pipe(first())
      .subscribe({
        next: (resp: Candidature[]) =>
          this.onSuccessGetCandidaturesByEmail(resp),
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  selectCandidature(candidature: Candidature): void {
    this.selectedCandidature = candidature;
  }

  async showTenantListPopover(
    event: Event,
    candidature: Candidature
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: TenantListPopoverComponent,
      componentProps: {
        tenantsList: candidature.tenantCandidatureList,
        isTenantsList: true
      } as Partial<TenantListPopoverComponent>,
      event
    });
    popover.present();
  }

  private setForm(): void {
    this.form = new FormGroup<{ email: FormControl<string> }>({
      email: new FormControl<string>(null, [
        Validators.required,
        appValidators.emailValidator
      ])
    });
  }

  private async onSuccessGetCandidaturesByEmail(
    resp: Candidature[]
  ): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.dataSource = new MatTableDataSource(resp);
    this.dataSource.paginator = this.paginator;
  }
}
