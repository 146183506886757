import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AMLCheck } from '@core/models';

import { AmlForm } from '../../models/aml-table.model';

@Component({
  selector: 'el-buen-inquilino-aml-table',
  templateUrl: './aml-table.component.html'
})
export class AmlTableComponent implements OnInit {
  @Input({ required: true }) amlCheck: AMLCheck;
  @Input({ required: true }) readonly: boolean;
  form: FormGroup<AmlForm>;

  get isPublicPersonControl(): FormControl<boolean> {
    return this.form.controls.isPublicPerson;
  }

  get wasPublicPersonControl(): FormControl<boolean> {
    return this.form.controls.wasPublicPerson;
  }

  get isSanctionatedControl(): FormControl<boolean> {
    return this.form.controls.isSanctionated;
  }

  get wasSanctionatedControl(): FormControl<boolean> {
    return this.form.controls.wasSanctionated;
  }

  get isTerroristControl(): FormControl<boolean> {
    return this.form.controls.isTerrorist;
  }

  get hasMediaControl(): FormControl<boolean> {
    return this.form.controls.hasMedia;
  }

  get hasAdverseInfoControl(): FormControl<boolean> {
    return this.form.controls.hasAdverseInfo;
  }

  get isHighRiskControl(): FormControl<boolean> {
    return this.form.controls.isHighRisk;
  }

  ngOnInit(): void {
    this.setForm();
  }

  private setForm(): void {
    this.form = new FormGroup({
      isPublicPerson: this.getIsPublicPersonControl(),
      wasPublicPerson: this.getWasPublicPersonControl(),
      isSanctionated: this.getIsSanctionatedControl(),
      wasSanctionated: this.getWasSanctionatedControl(),
      isTerrorist: this.getIsTerroristControl(),
      hasMedia: this.getHasMediaControl(),
      hasAdverseInfo: this.getHasAdverseInfoControl(),
      isHighRisk: this.getIsHighRiskControl()
    });
  }

  private getIsPublicPersonControl(): FormControl<boolean> {
    let value = this.amlCheck?.isPublicPerson ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getWasPublicPersonControl(): FormControl<boolean> {
    let value = this.amlCheck?.wasPublicPerson ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getIsSanctionatedControl(): FormControl<boolean> {
    let value = this.amlCheck?.isSanctionated ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getWasSanctionatedControl(): FormControl<boolean> {
    let value = this.amlCheck?.wasSanctionated ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getIsTerroristControl(): FormControl<boolean> {
    let value = this.amlCheck?.isTerrorist ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getHasMediaControl(): FormControl<boolean> {
    let value = this.amlCheck?.hasMedia ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getHasAdverseInfoControl(): FormControl<boolean> {
    let value = this.amlCheck?.hasAdverseInfo ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }

  private getIsHighRiskControl(): FormControl<boolean> {
    let value = this.amlCheck?.isHighRisk ?? false;
    if (value === null) {
      value = false;
    }
    return new FormControl(
      { value, disabled: this.readonly },
      Validators.required
    );
  }
}
