import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { Sinister, SinisterThread, User } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { first, lastValueFrom } from 'rxjs';

import { CommentsForm } from '../../models/sinister-detail-modal.model';
import { copyObject } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-sinister-conversation',
  templateUrl: './sinister-conversation.component.html'
})
export class SinisterConversationComponent implements OnInit, OnChanges {
  @Input({ required: true }) messages: SinisterThread[] = [];
  @Input({ required: true }) user: User;
  @Input({ required: true }) users: User[];
  @Input({ required: true }) sinister: Sinister;
  @Input() showCommentsForm = true;
  @Input() isIncofisa = false;
  @Input() seeDetails = false;

  form: FormGroup<CommentsForm>;
  messagesList: SinisterThread[] = [];
  thereIsNewMessage = false;

  @Output() thereIsNewMessageEvent = new EventEmitter();
  @Output() dismissModalEvent = new EventEmitter();

  get showForm(): boolean {
    return !this.sinister.endDate && this.showCommentsForm;
  }

  get isAddCommentButtonDisabled(): boolean {
    return (
      this.messageControl.value === null ||
      this.messageControl.value?.length < 10
    );
  }

  get messageControl(): FormControl<string> {
    return this.form.controls.message;
  }

  constructor(
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.setList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sinister']) {
      this.messages = this.sinister.thread;
      this.setList();
    }
  }

  setList(): void {
    this.messagesList = (
      copyObject(this.messages) as SinisterThread[]
    ).reverse();
  }

  sendNewMessage(sinisterId: string): void {
    const data: SinisterThread = {
      date: new Date(),
      message: this.form.controls.message.value,
      userId: this.user.id
    };
    this.candidatureService
      .addCommentToSinister(sinisterId, data)
      .pipe(first())
      .subscribe(() => {
        this.messages.unshift(data);
        this.setList();
        this.form.controls.message.reset();
        if (!this.thereIsNewMessage) {
          this.thereIsNewMessage = true;
          this.thereIsNewMessageEvent.emit();
        }
      });
  }

  async replySinister(sinister: Sinister): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    const data: SinisterThread = {
      date: new Date(),
      message: this.form.controls.message.value,
      userId: this.user.id
    };

    try {
      await lastValueFrom(
        this.candidatureService
          .addCommentToSinister(sinister.id, data)
          .pipe(first())
      );
    } catch (error) {
      await this.loadingService.dismissSecondLoader();
      return;
    }

    this.sinister.thread.push(data);

    this.candidatureService
      .closeSinister(this.sinister)
      .pipe(first())
      .subscribe({
        next: () => {
          this.sinister.endDate = new Date();
          this.dismissModalEvent.emit();
        },
        error: () => {
          this.dismissModalEvent.emit();
        }
      });
  }

  private setForm(): void {
    this.form = new FormGroup<CommentsForm>({
      message: new FormControl<string>(null)
    });
  }
}
