/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NotificationsApiService } from '@app/modules/core/api-services/notifications-api/notifications-api.service';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  AnalysisCatalogue,
  AssetDto,
  BrokerDto,
  CalculatedPremiumAragDto,
  Candidature,
  DocumentDTO,
  InsuranceTenantRequestDto,
  NotificationDto,
  NotificationTypeEnum,
  TenantCandidature,
  User,
  UserAnalysisStatusEnum,
  UserCandidatureDto
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { AddAragInsuranceComponent } from '../add-arag-insurance/add-arag-insurance.component';
import { AddTenantInsuranceComponent } from '../add-tenant-insurance/add-tenant-insurance.component';
import { MultipleDocumentModalComponent } from '../multiple-document-modal/multiple.document.modal.component';
import { PreviewReportComponent } from '../preview-report/preview-report.component';

@Component({
  selector: 'el-buen-inquilino-selected-candidature-modal',
  templateUrl: './selected-candidature-modal.component.html'
})
export class SelectedCandidatureModalComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() closedOperation: boolean;
  @Input() isBackoffice = false;
  @Input() client: User;
  @Input() originalPolicyNumber: string;

  insuranceCompanyCode = 'ARAG';
  canContractTenantInsurances = false;
  insuranceBrokerPaid = false;
  hideAragOfferButton = true;
  user: User;
  totalAnnualReceipt: number;
  initialPrice: number;

  textTenantInsuranceButton: string;

  @ViewChild('scoringBusiness', { static: true })
  canvasElement: ElementRef<HTMLCanvasElement>;

  constructor(
    private authenticationService: AuthService,
    private modalController: ModalController,
    private candidatureService: CandidaturesApiService,
    private usersService: UsersApiService,
    private toastController: ToastController,
    private assetService: AssetsApiService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private notificationsService: NotificationsApiService
  ) {}

  ngOnInit(): void {
    this.user = this.authenticationService.user;
    this.textTenantInsuranceButton = this.translate.instant(
      'pages.profile.home-owner.assets.candidature.download_policy'
    ) as string;
    this.getInsuranceCompanyCode();
    this.addDocumentsToTenant();
    if (!this.candidature?.policyNumber) {
      this.callCalculatedPremiun();
    }
    if (this.candidature?.tenantPolicyNumber === 'DRAFT') {
      this.textTenantInsuranceButton = 'Pendiente de firma';
    }
  }

  callCalculatedPremiun(): void {
    if (this.calculateConditionsToOfferPolicy()) {
      this.candidatureService
        .getCalculatedPremiumArag(this.candidature.id, this.client?.id)
        .pipe(first())
        .subscribe((resp: CalculatedPremiumAragDto) => {
          if (resp && resp?.totalAnnualReceipt) {
            this.totalAnnualReceipt = resp.totalAnnualReceipt;
            if (resp?.initialPrice) {
              this.initialPrice = resp.initialPrice;

              if (resp.initialPrice < resp.totalAnnualReceipt) {
                this.totalAnnualReceipt = resp.initialPrice;
              }
            }
          }
        });
    }
  }

  calculateConditionsToOfferPolicy(): boolean {
    return (
      !this.candidature.policyNumber &&
      this.candidature.score &&
      this.candidature.score >= 80 &&
      !this.candidature.blockPolicy
    );
  }

  getInsuranceCompanyCode(): void {
    let userId = this.user?.id;
    if (!!this.client) {
      userId = this.client.id;
    }
    this.candidatureService
      .getBrokerCodeByUserId(userId)
      .subscribe((broker: BrokerDto) => {
        this.insuranceCompanyCode = broker?.insuranceCode;
        this.canContractTenantInsurances = broker?.canContractTenantInsurances;
        // TODO - Retomar cuando Caser conteste
        //this.insuranceBrokerPaid = broker?.insuranceBrokerPaid;
      });
  }

  async previewReport(): Promise<void> {
    const candidature = this.candidature;
    await this.loadingService.presentSecondLoader(null, true);

    // Preparamos la llamada para obtener los informes
    const calls: unknown[] = [];

    candidature.tenantCandidatureList.forEach((tc: TenantCandidature) => {
      calls.push(
        this.usersService.getCompletedAnalysis(candidature.id, tc.user.id)
      );
    });

    // Añadimos la llamada para obtener el catálogo del análisis
    calls.push(this.usersService.getCatalogue());

    forkJoin(calls)
      .pipe(first())
      .subscribe({
        next: async (resp: unknown[]) => {
          // Recuperamos la información del catálogo y la eliminamos del array
          const catalogue: AnalysisCatalogue = resp.pop() as AnalysisCatalogue;

          const modal = await this.modalController.create({
            component: PreviewReportComponent,
            cssClass: 'preview-report',
            componentProps: {
              reports: resp,
              candidature,
              catalogue
            }
          });
          await modal.present();

          const dismissEvent = await modal.onWillDismiss();
          if (dismissEvent.role === 'download') {
            //   this.incofisaReportPdfService.getReport(
            //     candidature,
            //     resp as CreateAnalysisDto[],
            //     catalogue,
            //     this.canvasElement
            //   );
            await this.loadingService.presentSecondLoader(null);
            this.usersService
              .createReport(candidature.id)
              .pipe(first())
              .subscribe({
                next: async (blob: Blob) => {
                  await this.loadingService.dismissSecondLoader();
                  const url = window.URL.createObjectURL(blob);
                  const pwa = window.open(url);
                  if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
                    alert(
                      'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
                    );
                  }
                  await this.loadingService.dismissSecondLoader();
                },
                error: async () =>
                  await this.loadingService.dismissSecondLoader()
              });
          }
          await this.loadingService.dismissSecondLoader();
        },
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  downloadAssetReport(asset: AssetDto): void {
    this.loadingService.presentSecondLoader(
      this.translate.instant(
        'components.selected-candidature-modal.generating_pdf'
      ) as string,
      true
    );
    this.assetService.getAssetReport(asset.id).subscribe(
      (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === undefined) {
          alert(
            this.translate.instant(
              'pages.profile.home-owner.assets.disable_popup_blocker'
            )
          );
        }
        this.loadingService.dismissSecondLoader();
      },
      () => {
        this.presentToast(
          this.translate.instant(
            'components.selected-candidature-modal.can_not_generate_report_without_candidatures'
          ) as string,
          'danger'
        );
        this.loadingService.dismissSecondLoader();
      }
    );
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss(
      {
        dismissed: true,
        saving
      },
      '',
      'selected-candidature'
    );
  }

  async presentAragInsuranceFormModal(
    data?: Candidature,
    totalAnnualReceipt?: number
  ): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';
    const modal = await this.modalController.create({
      component: AddAragInsuranceComponent,
      cssClass: `${isAutoHeight} custom-over-modal modal-extend`,
      componentProps: {
        candidature: data,
        totalAnnualReceipt,
        initialPrice: this.initialPrice,
        insuranceCompanyCode: this.insuranceCompanyCode,
        originalPolicyNumber: this.originalPolicyNumber,
        client: this.client,
        insuranceBrokerPaid: this.insuranceBrokerPaid
      },
      id: 'addAragInsurance'
    });
    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<{ saving?: boolean }>) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (close?.data?.saving) this.dismiss();
      });
    return await modal.present();
  }

  async presentTenantInsuranceFormModal(
    data?: Candidature,
    totalAnnualReceipt?: number
  ): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';
    const modal = await this.modalController.create({
      component: AddTenantInsuranceComponent,
      cssClass: `${isAutoHeight} modal-extend modal-backdrop`,
      componentProps: {
        candidature: data,
        totalAnnualReceipt
      },
      showBackdrop: true
    });
    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<{ saving?: boolean }>) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (close?.data?.saving) {
          this.candidature.tenantPolicyNumber = 'DRAFT';
          this.textTenantInsuranceButton = 'Pendiente de firma';
          this.presentToast(
            'Se ha enviado una solicitud de contratación de póliza a los miembros de la candidatura',
            'success'
          );
        }
      });
    return await modal.present();
  }

  downloadPolicyReport(candidature: Candidature): void {
    if (candidature.policyNumber) {
      this.loadingService.presentSecondLoader(null);
      this.candidatureService
        .getPolicyReport(
          candidature.homeowner?.user?.id,
          candidature.policyNumber
        )
        .subscribe(
          (blob: Blob) => {
            this.loadingService.dismissSecondLoader();
            const url = window.URL.createObjectURL(blob);
            const pwa = window.open(url);
            if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
              alert(
                this.translate.instant(
                  'pages.profile.home-owner.assets.disable_popup_blocker'
                )
              );
            }
          },
          () => {
            this.loadingService.dismissSecondLoader();
            this.presentToast(
              this.translate.instant(
                'components.selected-candidature-modal.can_not_download'
              ) as string,
              'danger'
            );
          }
        );
    }
  }

  isDisabledTenantPolicyReport(candidature: Candidature): boolean {
    return candidature.tenantPolicyNumber === 'DRAFT';
  }

  downloadTenantPolicyReport(candidature: Candidature): void {
    if (candidature.tenantPolicyNumber !== 'DRAFT') {
      this.loadingService.presentSecondLoader(null);
      this.candidatureService
        .downloadTenantPolicy(candidature.tenantPolicyNumber)
        .subscribe(
          (blob: Blob) => {
            this.loadingService.dismissSecondLoader();
            const url = window.URL.createObjectURL(blob);
            const pwa = window.open(url);
            if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
              alert(
                this.translate.instant(
                  'pages.profile.home-owner.assets.disable_popup_blocker'
                )
              );
            }
          },
          () => {
            this.loadingService.dismissSecondLoader();
            this.presentToast(
              this.translate.instant(
                'components.selected-candidature-modal.can_not_download'
              ) as string,
              'danger'
            );
          }
        );
    }
  }

  resendTenantPolicyRequest(): void {
    // eslint-disable-next-line max-len
    const address = `${this.candidature.asset.street} ${this.candidature.asset.number}, ${this.candidature.asset.town} (${this.candidature.asset.province})`;

    // Se crea la notificación en la tabla
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.INSURANCE_RC;
    notification.candidatureId = this.candidature.id;
    notification.address = address;
    notification.senderEmail = this.user.email;
    notification.senderFirstname = this.user.firstname;
    notification.senderSurname1 = this.user.surname1;
    notification.senderSurname2 = this.user.surname2;
    this.candidature.tenantCandidatureList?.forEach(
      (userCandidature: UserCandidatureDto) => {
        notification.recipientEmail = userCandidature.user.email;
        notification.recipientFirstname = userCandidature.user.firstname;
        notification.recipientSurname1 = userCandidature.user.surname1;
        notification.recipientSurname2 = userCandidature.user.surname2;
        this.notificationsService
          .newNotification(notification)
          .subscribe(() => null);
      }
    );

    // Se envían los emails
    const notificationEmail = new InsuranceTenantRequestDto();
    // eslint-disable-next-line max-len
    notificationEmail.address = address;

    this.candidature.tenantCandidatureList?.forEach(
      (userCandidature: TenantCandidature) => {
        notificationEmail.email = userCandidature.user.email;
        notificationEmail.firstname = userCandidature.user.firstname;
        this.usersService
          .resendTenantPolicyRequest(notificationEmail)
          .subscribe();
      }
    );

    this.presentToast('Invitaciones reenviadas con éxito', 'success');
  }

  addDocumentsToTenant(): void {
    this.candidature.tenantCandidatureList.map(
      (user: TenantCandidature, index: number) => {
        this.tenantDocuments(user.user.id, index);
      }
    );
  }

  tenantDocuments(idUser: string, index: number): void {
    this.candidatureService
      .getSelectedDocuments(this.candidature.id, idUser)
      .subscribe((documents: DocumentDTO[]) => {
        this.candidature.tenantCandidatureList[index].user.documents =
          this.verifyDocuments(documents);
      });
  }

  verifyDocuments(documents: DocumentDTO[]): DocumentDTO[] {
    const validatedDocument = documents.filter(
      (document: DocumentDTO) => document
    );
    return validatedDocument;
  }

  launchViewDocuments(userDoc: User): void {
    if (userDoc.documents) {
      this.viewDocumentsFull(userDoc.documents);
    } else {
      this.presentToast(
        this.translate.instant(
          'components.selected-candidature-modal.documentation_not_exist'
        ) as string,
        'danger'
      );
    }
  }

  async viewDocumentsFull(documents: DocumentDTO[]): Promise<void> {
    this.loadingService.presentSecondLoader(null, true);
    const modal = await this.modalController.create({
      component: MultipleDocumentModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        documentsData: documents,
        source: 'selectedCandidature'
      }
    });
    return await modal.present();
  }

  checkUserDocuments(documents: DocumentDTO[]): boolean {
    let hasUserDocuments = true;
    if (documents) {
      return documents.every((document: DocumentDTO) => {
        hasUserDocuments = this.checkDocument(document);
      });
    }
    return hasUserDocuments;
  }

  checkDocument(document: DocumentDTO): boolean {
    return document && document.id ? true : false;
  }

  checkRequestCandidature(candidature: Candidature): boolean {
    if (candidature && candidature.tenantCandidatureList.length > 0) {
      return !candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) =>
          tenant.status === UserAnalysisStatusEnum.REPORTED_ANALYSIS
      );
    } else {
      return true;
    }
  }

  getTenantRole(tenant: TenantCandidature): string {
    if (tenant.user.guarantor) {
      return 'components.change_tenant_role.guarantor';
    } else if (tenant.user.student) {
      return 'components.change_tenant_role.student';
    } else if (tenant.user.business) {
      return 'components.change_tenant_role.business';
    } else if (tenant.user.retired) {
      return 'components.change_tenant_role.retired';
    } else if (tenant.user.freelance) {
      return 'components.change_tenant_role.freelance';
    } else {
      return 'components.change_tenant_role.worker';
    }
  }

  isDisabledTenantPolicyButton(candidature: Candidature): boolean {
    if (candidature.tenantPolicyNumber === 'DRAFT') {
      return true;
    }
    return false;
  }

  revertSelectedCandidature(): void {
    if (this.candidature.policyNumber) {
      this.candidatureService.openCancelPolicyRequestModal(
        'DEFAULT',
        this.candidature.policyNumber,
        true,
        this.candidature
      );
    } else {
      this.candidatureService.changeCandidatureStatus(this.candidature);
    }
  }
}
