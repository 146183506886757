/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/modules/core/services/auth/auth.service';
import { CatalogsApiService } from '@core/api-services/catalogs-api/catalogs-api.service';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import {
  Catalog,
  CatalogEnum,
  Incidence,
  IncidenceStatusEnum,
  User
} from '@core/models';
import { ModalController } from '@ionic/angular';
import {
  IonSelectCustomEvent,
  OverlayEventDetail,
  SelectChangeEventDetail
} from '@ionic/core';
import { endOfDay, startOfDay } from 'date-fns';
import { first } from 'rxjs';

import { IncidencesListDisplayedColumnsEnum } from '../../models/incidences-list.model';
import { copyObject, tableSize } from '../../utils/global.utils';
import { IncidenceDetailModalComponent } from '../incidence-detail-modal/incidence-detail-modal.component';

@Component({
  selector: 'el-buen-inquilino-incidences-list',
  templateUrl: './incidences-list.component.html'
})
export class IncidencesListComponent implements OnInit {
  @Input() incidences: Incidence[];
  @Input() canChangeStatusOnDetail = false;

  @Output() forceRefresh: EventEmitter<void> = new EventEmitter();

  originalIncidencesList: Incidence[] = [];
  // TABLA
  displayedColumns = [
    IncidencesListDisplayedColumnsEnum.INCIDENCE_TYPE,
    IncidencesListDisplayedColumnsEnum.TENANT,
    IncidencesListDisplayedColumnsEnum.REQUEST_DATE,
    IncidencesListDisplayedColumnsEnum.STATUS
  ];
  incidencesDataSource: MatTableDataSource<Incidence> =
    new MatTableDataSource<Incidence>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableSize = tableSize;
  displayedColumnsEnum = IncidencesListDisplayedColumnsEnum;
  // END TABLA

  user: User;
  incidenceTypes: Catalog[];
  incidenceTypesFilter: Catalog | null = null;

  dateFilter: Date | null = null;
  max = new Date();

  showCancelDateFilter = false;

  pendingStatusFilter = false;
  inProgressStatusFilter = false;
  closedStatusFilter = false;

  get numberNew(): number {
    return (
      this.getNumberByStatus(IncidenceStatusEnum.NEW) +
      this.getNumberByStatus(IncidenceStatusEnum.ESCALATED)
    );
  }

  get numberEjecucion(): number {
    return this.getNumberByStatus(IncidenceStatusEnum.EJECUCION);
  }

  get numberClosed(): number {
    return this.getNumberByStatus(IncidenceStatusEnum.CLOSED);
  }

  constructor(
    private catalogService: CatalogsApiService,
    private insuranceService: InsurancesApiService,
    private modalController: ModalController,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setUser();
    this.getIncidenceTypes();
  }

  trackByIncidence(_index: number, incidence: Incidence): Incidence {
    return incidence;
  }

  incidenceTypesFilterChange(
    event: IonSelectCustomEvent<SelectChangeEventDetail<Catalog>>
  ): void {
    this.incidenceTypesFilter = event.detail.value;

    this.filter();
  }

  dateFilterChange(event: MatDatepickerInputEvent<Date, Date>): void {
    this.dateFilter = event.value;

    if (!!this.dateFilter) {
      this.showCancelDateFilter = true;
    }

    this.filter();
  }

  resetDateFilter(): void {
    this.dateFilter = null;
    this.showCancelDateFilter = false;

    this.filter();
  }

  pendingStatusFilterChange(): void {
    this.pendingStatusFilter = !this.pendingStatusFilter;

    if (this.pendingStatusFilter) {
      this.inProgressStatusFilter = false;
      this.closedStatusFilter = false;
      this.filter();
    } else {
      this.clearFilters();
    }
  }

  inProgressStatusFilterChange(): void {
    this.inProgressStatusFilter = !this.inProgressStatusFilter;

    if (this.inProgressStatusFilter) {
      this.pendingStatusFilter = false;
      this.closedStatusFilter = false;
      this.filter();
    } else {
      this.clearFilters();
    }
  }

  closedStatusFilterChange(): void {
    this.closedStatusFilter = !this.closedStatusFilter;

    if (this.closedStatusFilter) {
      this.pendingStatusFilter = false;
      this.inProgressStatusFilter = false;
      this.filter();
    } else {
      this.clearFilters();
    }
  }

  clearFilters(): void {
    this.incidenceTypesFilter = null;
    this.dateFilter = null;

    this.showCancelDateFilter = false;
    this.pendingStatusFilter = false;
    this.inProgressStatusFilter = false;
    this.closedStatusFilter = false;

    this.filter();
  }

  async openDetail(incidence: Incidence): Promise<void> {
    const modal = await this.modalController.create({
      component: IncidenceDetailModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        incidence: incidence,
        canChangeStatusOnDetail: this.canChangeStatusOnDetail
      } as Partial<IncidenceDetailModalComponent>
    });

    modal
      .onWillDismiss()
      .then((hasBeenUpdated: OverlayEventDetail<boolean>) => {
        if (hasBeenUpdated.data) {
          this.forceRefresh.emit();
        }
      });

    await modal.present();
  }

  private getIncidenceTypes(): void {
    this.catalogService
      .getCatalogs(CatalogEnum.INCIDENCE_TYPES)
      .pipe(first())
      .subscribe((resp: Catalog[]) => {
        this.incidenceTypes = resp;
        this.onSuccessGetIncidences();
      });
  }

  private onSuccessGetIncidences(): void {
    this.originalIncidencesList = copyObject(this.incidences) as Incidence[];
    this.incidencesDataSource = new MatTableDataSource<Incidence>(
      copyObject(this.incidences) as Incidence[]
    );
    this.incidencesDataSource.paginator = null;
    this.incidencesDataSource.paginator = this.paginator;

    this.incidencesDataSource = new MatTableDataSource<Incidence>(
      copyObject(this.originalIncidencesList) as Incidence[]
    );
  }

  private filter(): void {
    let incidences = copyObject(this.originalIncidencesList) as Incidence[];

    if (!!this.incidenceTypesFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) =>
          incidence.type === this.incidenceTypesFilter.id
      );
    }

    if (!!this.dateFilter) {
      const initDate = startOfDay(this.dateFilter);
      const endDate = endOfDay(this.dateFilter);

      incidences = incidences.filter((incidence: Incidence) =>
        this.insuranceService.isBetween(
          initDate,
          endDate,
          incidence.creationDate
        )
      );
    }

    if (this.pendingStatusFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) => incidence.status === IncidenceStatusEnum.NEW
      );
    }

    if (this.inProgressStatusFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) =>
          incidence.status === IncidenceStatusEnum.EJECUCION
      );
    }

    if (this.closedStatusFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) =>
          incidence.status === IncidenceStatusEnum.CLOSED
      );
    }

    this.incidencesDataSource.data = incidences;
  }

  private getNumberByStatus(status: IncidenceStatusEnum): number {
    return (
      this.originalIncidencesList.filter(
        (incidence: Incidence) =>
          incidence.status === status ||
          (status === IncidenceStatusEnum.CLOSED &&
            incidence.status === IncidenceStatusEnum.CANCELLED)
      )?.length || 0
    );
  }

  private setUser(): void {
    this.user = this.authService.user;
  }
}
