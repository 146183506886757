/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncidencesApiService } from '@app/modules/core/api-services/incidences-api/incidences-api.service';
import { AttachDocument, Incidence } from '@app/modules/core/models';
import { LoadingService } from '@app/modules/core/services/loading/loading.service';
import { prepareFile } from '@app/modules/core/services/utils/utils.service';
import { presentToast } from '@app/modules/shared/utils/toast.utils';
import { ModalController, ToastController } from '@ionic/angular';
import { catchError, finalize, throwError } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-add-document-to-incidence-modal',
  templateUrl: './add-document-to-incidence-modal.component.html'
})
export class AddDocumentToIncidenceModalComponent implements OnInit {
  @Input() incidence: Incidence;

  form: FormGroup;

  #file: File;

  constructor(
    private modalController: ModalController,
    private incidencesService: IncidencesApiService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  dismiss(attachDocument?: AttachDocument): void {
    this.modalController.dismiss({ attachDocument: attachDocument });
  }

  changeListenerFile(event: any): void {
    this.#file = prepareFile((event.target as HTMLInputElement).files[0]);
  }

  uploadDocument(): void {
    this.loadingService.presentLoading('Subiendo documento...');

    const params = {
      name: this.form.get('name').value,
      file: this.#file
    };

    this.incidencesService
      .attachDocument(params, this.incidence.id)
      .pipe(
        catchError((err: any) => {
          this.showToast(
            'pages.documents.create-page.error_upload_document',
            'danger'
          );

          return throwError(err);
        }),
        finalize(() => this.loadingService.dismissSecondLoader())
      )
      .subscribe({
        next: (attachDocument: AttachDocument) => {
          this.showToast('pages.documents.create-page.savedOK', 'success');
          this.dismiss(attachDocument);
        },
        error: (error: any) => {
          if (error.status === 400) {
            this.showToast(
              'pages.documents.create-page.sizeDocumentError',
              'danger'
            );
          } else {
            this.showToast(
              'pages.documents.create-page.error_upload_document',
              'danger'
            );
          }
        }
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      file: ['', [Validators.required]]
    });
  }

  private showToast(
    message: string,
    color: 'success' | 'danger' | 'warning'
  ): void {
    presentToast(this.toastController, message, color);
  }
}
