/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { BuildingDto, Candidature, User } from '@core/models';
import { ModalController, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { CandidatureNotesModalComponent } from '@shared/components/candidature-notes-modal/candidature-notes-modal.component';
import { CandidatureNotesModalData } from '@shared/models/candidature-notes.model';
import {
  MoreCandidatureOptionEnum,
  MoreCandidatureOptionsPopoverResponse
} from '@shared/models/more-candidature-options-popover.model';
import { copyObject } from '@shared/utils/global.utils';

import { EditCandidatureComponent } from '../edit-candidature/edit-candidature.component';
import { MoveCandidatureModalComponent } from '../move-candidature-modal/move-candidature-modal.component';
import { RecalculateScoreComponent } from '../recalculate-score/recalculate-score.component';

@Component({
  selector: 'el-buen-inquilino-more-candidature-options-popover',
  templateUrl: './more-candidature-options-popover.component.html'
})
export class MoreCandidatureOptionsPopoverComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() disableEdition = false;
  @Input() building: BuildingDto;
  @Input() user: User;
  @Input() disableReinforce = false;
  @Input() disableRelocate = false;
  @Input() disabledReCalculate = false;
  @Input() userHasCalendar = false;
  showCalendarItem = false;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.setShowCalendarItem();
  }

  setShowCalendarItem(): void {
    this.showCalendarItem =
      !this.candidature.selectDate &&
      !this.candidature.rejectDate &&
      new Date(this.candidature.expirationDate) > new Date() &&
      this.userHasCalendar;
  }

  async editCandidature(reinforce: boolean): Promise<void> {
    const modal = await this.modalController.create({
      component: EditCandidatureComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        updatedCandidature: copyObject(this.candidature) as Candidature,
        reinforce
      }
    });
    modal.onWillDismiss().then((close) => {
      if (close?.data?.saving) {
        this.dismiss({
          option: MoreCandidatureOptionEnum.EDIT_CANDIDATURE
        });
      } else {
        this.dismiss();
      }
    });
    return await modal.present();
  }

  async moveCandidature(): Promise<void> {
    const modal = await this.modalController.create({
      component: MoveCandidatureModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        candidature: copyObject(this.candidature) as Candidature,
        building: this.building,
        user: this.user
      }
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data?.newAsset) {
      this.dismiss({
        option: MoreCandidatureOptionEnum.NOTE_CANDIDATURE,
        newAsset: data?.newAsset
      });
    } else {
      this.dismiss();
    }
  }

  async reCalculateCandidatureWithNewAssetPrice(): Promise<void> {
    const modal = await this.modalController.create({
      component: RecalculateScoreComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature: copyObject(this.candidature) as Candidature,
        haveRequestOrAnalysis: this.disableReinforce
      }
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data?.saving) {
      this.dismiss({ option: MoreCandidatureOptionEnum.RECALCULATE_SCORE });
    } else {
      this.dismiss();
    }
  }

  async seeCandidatureNotes(): Promise<void> {
    const modal = await this.modalController.create({
      component: CandidatureNotesModalComponent,
      componentProps: {
        candidature: copyObject(this.candidature) as Candidature,
        user: this.user
      } as CandidatureNotesModalData
    });

    await modal.present();

    const { data }: OverlayEventDetail<boolean> = await modal.onWillDismiss();

    if (data) {
      this.dismiss({
        option: MoreCandidatureOptionEnum.NOTE_CANDIDATURE
      });
    } else {
      this.dismiss();
    }
  }

  addAppointment(): void {
    this.dismiss({
      option: MoreCandidatureOptionEnum.APPOINTMENT
    });
  }

  dismiss(data?: MoreCandidatureOptionsPopoverResponse): void {
    this.popoverController.dismiss(data);
  }
}
