/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinaerGuard } from '@core/guards/private/finaer.guard';

import { CertificationsLandingModule } from './modules/certifications-landing/certifications-landing.module';
import { DocumentsCertificationsModule } from './modules/documents-certifications/documents-certifications.module';

const routes: Routes = [
  {
    path: 'certifications-login',
    loadChildren: async (): Promise<typeof CertificationsLandingModule> =>
      (
        await import(
          './modules/certifications-landing/certifications-landing.module'
        )
      ).CertificationsLandingModule
  },
  {
    path: 'certifications/:id',
    loadChildren: async (): Promise<typeof DocumentsCertificationsModule> =>
      (
        await import(
          './modules/documents-certifications/documents-certifications.module'
        )
      ).DocumentsCertificationsModule,
    canLoad: [FinaerGuard],
    canActivate: [FinaerGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CertificationsRoutingModule {}
