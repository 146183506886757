import { Pipe, PipeTransform } from '@angular/core';
import { Candidature } from '@core/models';
import { CandidatureUtilsService } from '@core/services/candidature-utils/candidature-utils.service';

@Pipe({
  name: 'checkCandidatureTenants'
})
export class CheckCandidatureTenantsPipe implements PipeTransform {
  constructor(private candidatureUtils: CandidatureUtilsService) {}

  transform(candidature: Candidature): boolean {
    return this.candidatureUtils.checkCandidatureTenants(candidature);
  }
}
