import { Pipe, PipeTransform } from '@angular/core';
import { AssetDto } from '@core/models';

import {
  MultipleApiAssign,
  MultipleApiAssignFunction
} from '../models/multiple-api-assign.model';

@Pipe({
  name: 'multipleApiAssign'
})
export class MultipleApiAssignPipe implements PipeTransform {
  transform(
    items: AssetDto[],
    { pipeFunction, isBuildingPage }: MultipleApiAssign
  ): boolean {
    switch (pipeFunction) {
      case MultipleApiAssignFunction.SHOW_BUTTON:
        return isBuildingPage;
      case MultipleApiAssignFunction.DISABLE_BUTTON:
        return this.getDisabledButton(items);
    }
  }

  private getDisabledButton(items: AssetDto[]): boolean {
    if (!items || items?.length < 2) {
      return true;
    } else {
      return false;
    }
  }
}
