import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ApiDto,
  ApiMemberDto,
  CustomPopoverButtonRole,
  MyCustomEvent,
  User,
  UserRol
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { PopoverController } from '@ionic/angular';

import {
  MultipleApiAssignModalForm,
  MultipleApiAssignModalResponse
} from '../../models/multiple-api-assign.model';

@Component({
  selector: 'el-buen-inquilino-multiple-api-assign',
  templateUrl: './multiple-api-assign.component.html'
})
export class MultipleApiAssignComponent implements OnInit {
  @Input() apis: ApiDto[];
  @Input() members: ApiMemberDto[];
  @Input() user: User;

  form: FormGroup<MultipleApiAssignModalForm>;
  showMemberControl = false;
  customPopoverButtonRole = CustomPopoverButtonRole;
  isApiAdmin = false;

  get apiControl(): FormControl<ApiDto> {
    return this.form.get('api') as FormControl<ApiDto>;
  }

  get memberControl(): FormControl<ApiMemberDto> {
    return this.form.get('member') as FormControl<ApiMemberDto>;
  }

  constructor(
    private popoverController: PopoverController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.prepareDependingOnUserRole();
  }

  dismiss(
    role: CustomPopoverButtonRole,
    data?: MultipleApiAssignModalResponse
  ): void {
    this.popoverController.dismiss(data, role);
  }

  apiSelectChange(): void {
    if (this.showMemberControl) {
      this.memberControl.reset();
      this.setMembersList(this.getMemberFromApi());
    }
  }

  selectAgentCheckboxChange(event: Event): void {
    const show = (event as CustomEvent<MyCustomEvent>).detail.checked;
    if (show) {
      this.setMembersList(this.getMemberFromApi());
    } else {
      this.setMembersList([]);
    }
    this.showMemberControl = show;
  }

  assign(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    this.dismiss(
      CustomPopoverButtonRole.ACCEPT,
      this.form.getRawValue() as MultipleApiAssignModalResponse
    );
  }

  private setForm(): void {
    this.form = new FormGroup<MultipleApiAssignModalForm>({
      api: this.getApiControl(),
      member: this.getMemberControl()
    });
  }

  private getApiControl(): FormControl<ApiDto> {
    if (this.apis.length > 1) {
      return new FormControl<ApiDto>(null, Validators.required);
    } else {
      return new FormControl<ApiDto>({ value: this.apis[0], disabled: true });
    }
  }

  private getMemberControl(): FormControl<ApiMemberDto> {
    if (this.isApiAdmin) {
      return new FormControl<ApiMemberDto>(null, Validators.required);
    } else {
      return new FormControl<ApiMemberDto>(null);
    }
  }

  private getMemberFromApi(): ApiMemberDto[] {
    return this.apis.find((api: ApiDto) => api.id === this.apiControl.value.id)
      ?.members;
  }

  private prepareDependingOnUserRole(): void {
    if (this.user.apiRol === UserRol.API_ADMIN) {
      this.isApiAdmin = true;
      this.setForm();
      this.setMembersList(this.getMemberFromApi());
      this.showMemberControl = true;
    }
  }

  private setMembersList(list: ApiMemberDto[]): void {
    this.members = list;
  }
}
