/* eslint-disable max-len */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'el-buen-inquilino-drag-and-drop-file',
  templateUrl: './drag-and-drop-file.component.html'
})
export class DragAndDropFileComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  file: File | null = null;

  @Input() acceptedFileTypes = '';
  @Input() uploadMessage = 'Sube un documento.';

  @Output() fileEvent = new EventEmitter<File>();

  errorMessage = '';

  // Método que se ejecuta cuando se arrastra un archivo sobre la zona
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.add('drag-over');
  }

  // Método que se ejecuta cuando el archivo deja de estar sobre la zona de arrastre
  onDragLeave(event: DragEvent): void {
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('drag-over');
  }

  // Método que se ejecuta cuando se suelta el archivo en la zona de arrastre
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('drag-over');

    const files = event.dataTransfer?.files;
    if (files?.length) {
      const file = files[0];
      if (this.isValidFileType(file)) {
        this.file = file;
        this.fileEvent.emit(this.file);
        this.errorMessage = ''; // Limpiar mensaje de error
      } else {
        this.errorMessage = 'Tipo de archivo no permitido.';
      }
    }
  }

  // Método que se ejecuta cuando se selecciona un archivo desde el input
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file && this.isValidFileType(file)) {
      this.file = file;
      this.errorMessage = '';
      this.fileEvent.emit(this.file);
    } else {
      this.errorMessage = 'Tipo de archivo no permitido.';
    }
  }

  // Método para eliminar el archivo seleccionado
  removeFile(): void {
    this.file = null;
    this.fileEvent.emit(null);
  }

  private isValidFileType(file: File): boolean {
    if (!this.acceptedFileTypes) {
      return true; // Si no se especifican tipos, permitir cualquier archivo
    }
    const acceptedTypes = this.acceptedFileTypes
      .split(',')
      .map((type: string) => type.trim());
    return acceptedTypes.includes(file.type);
  }
}
