/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetsApiService } from '@app/modules/core/api-services/assets-api/assets-api.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { prepareFile, UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { first } from 'rxjs';

import { ButtonsRole } from '../../models/button.model';
import { UpdateDebtSinisterModalForm } from '../../models/update-debt-sinister-modal.model';

@Component({
  selector: 'el-buen-inquilino-update-asset-prices-modal',
  templateUrl: './update-asset-prices-modal.component.html'
})
export class UpdateAssetPricesModalComponent implements OnInit {
  buildingId: string;
  form: FormGroup<UpdateDebtSinisterModalForm>;
  file: File;

  get fileControl(): FormControl {
    return this.form.controls.file;
  }

  get disableImportBtn(): boolean {
    return this.form.invalid;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private assetService: AssetsApiService,
    private loadingService: LoadingService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL): void {
    this.modalController.dismiss(null, role);
  }

  async downloadTemplate(): Promise<void> {
    await this.loadingService.presentSecondLoader(
      'Generando plantilla...',
      false
    );
    this.assetService
      .getPricesExcelTemplate(this.buildingId)
      .pipe(first())
      .subscribe({
        next: async (data: Blob) => {
          const blobUrl = window.URL.createObjectURL(data);

          // Crear un enlace oculto
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'assets_ebi.xlsx';

          // Simular clic en el enlace para iniciar la descarga
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Limpiar y eliminar el enlace
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);

          await this.loadingService.dismissSecondLoader();
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
          const toast = await this.toastController.create({
            message: 'No se puede generar el Excel de precios.',
            duration: 2000,
            position: 'top',
            color: 'danger'
          });
          toast.present();
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/typedef
  changeListener(event): void {
    this.file = prepareFile(event.target.files[0] as File);
  }

  async import(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    await this.loadingService.presentSecondLoader(null);

    this.assetService
      .updateMassivePrices(this.buildingId, this.file)
      .pipe(first())
      .subscribe({
        next: async () => {
          this.dismiss(ButtonsRole.ACCEPT);
          await this.loadingService.dismissSecondLoader();
        },
        error: async (_err: unknown) => {
          await this.loadingService.dismissSecondLoader();
        }
      });
  }

  private setForm(): void {
    this.form = new FormGroup<UpdateDebtSinisterModalForm>({
      file: new FormControl<File>(null, Validators.required)
    });
  }
}
