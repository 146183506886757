import { Pipe, PipeTransform } from '@angular/core';
import { DocuSignStatusEnum } from '@core/models';

@Pipe({
  name: 'docusignStatusText'
})
export class DocusignStatusTextPipe implements PipeTransform {
  transform(value: DocuSignStatusEnum): string {
    switch (value) {
      case DocuSignStatusEnum.COMPLETED:
        return 'Completado';
      case DocuSignStatusEnum.CREATED:
        return 'Creado';
      case DocuSignStatusEnum.DECLINED:
        return 'Rechazado';
      case DocuSignStatusEnum.DELIVERED:
        return 'Leído';
      case DocuSignStatusEnum.SENT:
        return 'Enviado';
      case DocuSignStatusEnum.SIGNED:
        return 'Firmado';
      case DocuSignStatusEnum.VOIDED:
        return 'Anulado';
    }
  }
}
