import { Pipe, PipeTransform } from '@angular/core';
import { BuildingDto } from '@core/models';

@Pipe({
  name: 'buildingAddressOption'
})
export class BuildingAddressOptionPipe implements PipeTransform {
  transform(building: BuildingDto): string {
    let address = `${building.street} ${building.number}, ${building.town}`;

    if (!!building.internalCode) {
      address = `${address} (${building.internalCode})`;
    }

    return address;
  }
}
