import { Pipe, PipeTransform } from '@angular/core';
import { SinisterStatusEnum } from '@core/models';

@Pipe({
  name: 'sinisterPhaseStatus'
})
export class SinisterPhaseStatusPipe implements PipeTransform {
  transform(status: SinisterStatusEnum): string {
    switch (status) {
      case SinisterStatusEnum.NOTIFIED_TO_EBI:
        return 'Revisión EBI';
      case SinisterStatusEnum.INSURANCE_COMPANY_INITIAL_STATUS:
        return 'Aseguradora';
      case SinisterStatusEnum.INSURANCE_COMPANY_BUROFAX_SENT:
        return 'Envío Burofax';
      case SinisterStatusEnum.INSURANCE_COMPANY_COMPLAINT_FILLED:
        return 'Presentación Demanda';
      case SinisterStatusEnum.PENDING_TRIAL:
        return 'Pendiente de Juicio';
      case SinisterStatusEnum.SCHEDULED_EVICTION:
        return 'Lanzamiento Previsto';
    }
  }
}
