import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentDTO, User } from '@core/models';

import { LaunchIncidenceEvent } from '../../models/tenant-document-card.model';

@Component({
  selector: 'el-buen-inquilino-tenant-document-card',
  templateUrl: './tenant-document-card.component.html'
})
export class TenantDocumentCardComponent {
  @Input({ required: true }) document: DocumentDTO;
  @Input({ required: true }) isDisabledView = false;
  @Input({ required: true }) cotenantsList: User[] = [];

  @Output() checkDeleteDocumentEvent = new EventEmitter<DocumentDTO>();
  @Output() showDocumentEvent = new EventEmitter<DocumentDTO>();
  @Output() viewDocumentEvent = new EventEmitter<DocumentDTO>();
  @Output() showPopupMoveDocumentEvent = new EventEmitter<DocumentDTO>();
  @Output() launchIncidencesEvent = new EventEmitter<LaunchIncidenceEvent>();

  get title(): string {
    return `pages.documents.create-page.document-types.${this.document.typeDocument}`;
  }

  get showBirthdate(): boolean {
    return !!this.document.birthDate;
  }

  constructor() {}

  checkDeleteDocument(): void {
    this.checkDeleteDocumentEvent.emit(this.document);
  }

  showDocument(): void {
    this.showDocumentEvent.emit(this.document);
  }

  viewDocument(): void {
    this.viewDocumentEvent.emit(this.document);
  }

  showPopupMoveDocument(): void {
    this.showPopupMoveDocumentEvent.emit(this.document);
  }

  launchIncidences(): void {
    this.launchIncidencesEvent.emit({
      requestAnalysisId: this.document.requestAnalysisId,
      documentId: this.document.id
    });
  }
}
