/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RecommendationsApiService } from '@core/api-services/recommendations-api/recommendations-api.service';
import { AuthService } from '@core/services/auth/auth.service';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { ModalController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { MessageBean } from '../../utils/message-bean.utils';

@Component({
  selector: 'el-buen-inquilino-request-recomendation',
  templateUrl: './request-recomendation.component.html'
})
export class RequestRecomendationComponent {
  email: string;
  showSameEmailError = false;

  constructor(
    private modalController: ModalController,
    private snackBarService: SnackBarService,
    private recommendationService: RecommendationsApiService,
    private authService: AuthService
  ) {}

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  submitForm(form: NgForm): void {
    if (this.email === this.authService.user.email) {
      this.showSameEmailError = true;
    } else if (form.valid) {
      if (this.showSameEmailError) {
        this.showSameEmailError = false;
      }
      this.recommendationService
        .createRequestRecommendation(this.email)
        .pipe(
          tap(() => this.snackBarService.hide()),
          catchError((error) => {
            this.snackBarService.show(
              MessageBean.buildMessage(error.error.description),
              'danger'
            );
            return throwError(error);
          }),
          finalize(() => this.dismiss())
        )
        .subscribe();
    }
  }
}
