/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-asset-map',
  templateUrl: './asset.map.html',
  styleUrls: ['./asset.map.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetMapComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('mapSearchField') searchField: ElementRef;
  @ViewChild(GoogleMap) map: GoogleMap;
  @Output() send = new EventEmitter<google.maps.places.PlaceResult>();
  @Input() editMode: boolean;
  @Input() coords: { lat: number; lng: number };
  @Input() building = false;
  @Input() address: string;

  mapZoom = 6;
  mapCoordinates = {
    lat: 40.416667,
    lng: -3.703889
  };

  mapStyle: google.maps.MapTypeStyle[] = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'poi.school',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#c2e3e5'
        },
        {
          saturation: 20
        },
        {
          lightness: -20
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ];

  mapConfigurations = {
    disableDefaultUI: true,
    fullscreenControl: true,
    zoonControl: true,
    zoomControl: false,
    scrollwheel: false,
    styles: this.mapStyle,
    componentRestrictions: { country: 'ES' }
  };
  markers = [];
  options = {
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['address'],
    language: 'es'
  };
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if ((this.editMode && this.coords.lat) || this.building) {
      this.mapCoordinates = this.coords;
      this.createMarker(this.coords);
      this.mapZoom = 16;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coords && changes.coords.currentValue?.lat) {
      this.mapCoordinates = changes.coords.currentValue;
      this.createMarker(changes.coords.currentValue);
      this.mapZoom = 16;
    }
    if (changes.address) {
      this.address = changes.address.currentValue;
      if (this.address && this.searchField) {
        this.searchField.nativeElement.value = this.address;
        this.searchField.nativeElement.disabled = true;
      }

      this.map?.controls[google.maps.ControlPosition.TOP_CENTER].push(
        this.searchField.nativeElement
      );
    }
  }

  ngAfterViewInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchField.nativeElement,
      this.options
    );
    autocomplete.setComponentRestrictions({
      country: ['es']
    });

    if (this.address) {
      this.searchField.nativeElement.value = this.address;
      this.searchField.nativeElement.disabled = true;
    }

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField.nativeElement
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const bounds = new google.maps.LatLngBounds();
      if (!place.geometry || !place.geometry.location) {
        return;
      }
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      this.createMarker(place.geometry.location);
      this.map.fitBounds(bounds);
      this.send.emit(place);
    });

    if (this.editMode) {
      this.searchField.nativeElement.placeholder =
        this.translateService.instant(
          'pages.profile.home-owner.assets.asset_map.edit_asset_new_address'
        );
    }
  }

  createMarker(data): void {
    this.deleteMarkers();
    this.markers.push({
      position: data,
      zoom: 22,
      options: {
        animation: google.maps.Animation.DROP
      },
      map: this.map
    });
  }

  // Deletes all markers in the array by removing references to them.
  deleteMarkers(): void {
    this.markers = [];
  }
}
