import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UsersApiService } from '@app/modules/core/api-services/users-api/users-api.service';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import {
  AssetDto,
  Candidature,
  CandidaturesPageState,
  CandidatureStatusEnum,
  GeneralModalResponse,
  User,
  UserAnalysisStatusEnum,
  UserRol
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { WindowMeasuresService } from '@core/services/window-measures/window-measures.service';
import {
  AlertController,
  ModalController,
  NavController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { AddTrialCandidatureComponent } from '@shared/components/add-trial-candidature/add-trial-candidature.component';
import { SelectedCandidatureModalComponent } from '@shared/components/selected-candidature-modal/selected-candidature-modal.component';
import { first, Subscription } from 'rxjs';

import { AddCandidatureModalComponent } from '../../components/add-candidature-modal/add.candidature.modal';
import { AddMultipleCandidatureModalComponent } from '../../components/add-multiple-candidature-modal/add-multiple-candidature-modal.component';

@Component({
  selector: 'el-buen-inquilino-candidatures-page',
  templateUrl: './candidatures-page.component.html'
})
export class CandidaturesPageComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  asset: AssetDto;

  user: User;

  candidatures: Candidature[] = [];
  rejectedAndExpiredCandidatures: Candidature[] = [];
  candidatureStatusEnum = CandidatureStatusEnum;
  notify: boolean;
  userAnalysisStatusEnum = UserAnalysisStatusEnum;
  savingAnalysis = false;
  today = new Date();

  subscriptions: Subscription[] = [];

  screenWidth: number;
  showBackground = true;

  candidatureChangeValue = false;
  showRejected = false;
  buttonName = 'Mostrar descartadas / caducadas';

  widthChangeSub: Subscription;

  fromClosedPage = false;

  @ViewChild('scoringBusiness', { static: false })
  canvasElement: ElementRef<HTMLCanvasElement>;

  get showVppModality(): boolean {
    return !!this.asset?.vppModality;
  }

  get vppModality(): string {
    return this.asset?.vppModality;
  }

  get showNoCandidatureText(): boolean {
    return this.candidatures.length === 0;
  }

  get showRejectedAndExpiredCandidatures(): boolean {
    return this.rejectedAndExpiredCandidatures.length > 0;
  }

  get showReportAsset(): boolean {
    return this.candidatures?.length > 0;
  }

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthService,
    private candidatureService: CandidaturesApiService,
    private assetService: AssetsApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private alertController: AlertController,
    private windowMeasuresService: WindowMeasuresService,
    private userService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.setUser();
    this.setAsset();
    this.setNotify();
    this.candidatureService.shouldBeRefreshCandidature = false;
    this.initCandidatures();

    this.widthChangeSub = this.windowMeasuresService
      .getInnerWidth()
      .subscribe((width: number) => {
        this.screenWidth = width;
      });
  }

  ionViewWillEnter(): void {
    // Cuando se vuelve desde la pagina del usuario
    // Se vuelve a buscar los datos para actualizar la información
    if ((history.state as CandidaturesPageState)?.return) {
      this.initCandidatures();
    }

    // Si se viene de la redirección al cambiar la candidatura de asset
    if ((history.state as CandidaturesPageState)?.moveCandidature) {
      this.asset = null;
      this.initCandidatures();
    }
  }

  ngAfterViewChecked(): void {
    if (this.candidatureService.shouldBeRefreshCandidature) {
      this.initCandidatures();
    }
  }

  initCandidatures(): void {
    const isAssetClosed =
      this.router.url.split('/')[this.router.url.split('/').length - 3];

    if (isAssetClosed === 'closed') {
      this.fromClosedPage = true;
      this.callAssetByIdServiceWithParams();
    } else {
      if (!!this.asset) {
        this.getCandidatures(this.asset);
      } else {
        this.callAssetByIdServiceWithParams();
      }
    }

    this.candidatureService.shouldBeRefreshCandidature = false;
  }

  callAssetByIdServiceWithParams(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.assetService
        .getAsset((params as { id: string }).id)
        .subscribe((asset: AssetDto) => {
          this.asset = asset;
          this.getCandidatures(asset);
        });
    });
  }

  getCandidatures(asset: AssetDto): void {
    if (this.user.portfolioId || this.user.apiRol === UserRol.API_ADMIN) {
      this.candidatureService
        .getCandidaturesForBuildings(
          asset.id,
          this.authenticationService.user.apiId,
          this.authenticationService.user.portfolioId,
          this.fromClosedPage ? CandidatureStatusEnum.SELECT : null
        )
        .pipe(first())
        .subscribe((data: Candidature[]) => {
          this.prepareGetCandidaturesResponse(asset, data);
        });
    } else {
      this.candidatureService
        .getCandidaturesByAsset(asset.id)
        .pipe(first())
        .subscribe({
          next: (data: Candidature[]) => {
            this.prepareGetCandidaturesResponse(asset, data);
          },
          error: (error: HttpErrorResponse) => {
            console.error(error);
          }
        });
    }
  }

  prepareGetCandidaturesResponse(asset: AssetDto, data: Candidature[]): void {
    this.candidatures = data.map((e: Candidature) => {
      e.asset.street = asset.street;
      e.asset.number = asset.number;
      e.asset.portal = asset.portal;
      e.asset.floor = asset.floor;
      e.asset.door = asset.door;
      e.asset.postalCode = asset.postalCode;
      e.asset.province = asset.province;
      e.asset.town = asset.town;
      e.asset.rentalPrice = asset.rentalPrice;
      e.asset.reports = asset.reports;
      if (new Date(e.expirationDate) < new Date()) {
        if (e.candidatureStatusEnum === CandidatureStatusEnum.SELECT) {
          e.isCandidatureSelectAndExpired = true;
        }
        e.candidatureStatusEnum = this.candidatureStatusEnum.EXPIRED;
      }
      return e;
    });

    // Separo los reject
    this.rejectedAndExpiredCandidatures = this.candidatures.filter(
      (item: Candidature) =>
        item.candidatureStatusEnum === CandidatureStatusEnum.REJECT ||
        item.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED
    );
    this.candidatures = this.candidatures.filter(
      (item: Candidature) =>
        item.candidatureStatusEnum !== CandidatureStatusEnum.REJECT &&
        item.candidatureStatusEnum !== CandidatureStatusEnum.EXPIRED
    );

    if (this.candidatures && this.candidatures.length > 0) {
      this.orderCandidatures();
      this.checkCandidatureStatuses();
    }
  }

  async presentToast(
    msg: string,
    toastcolor: string,
    duration: number = 2000
  ): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  goBackToAssets(): void {
    const currentUrl = this.router.url.split('/');

    currentUrl.shift();
    currentUrl.pop();
    currentUrl.pop();

    this.navCtrl.navigateBack([...currentUrl], {
      state: { return: true }
    });
  }

  goBackToAssetsWithRefresh(): void {
    this.router.navigate(['home-owner', this.user.id, 'assets'], {
      state: { return: true }
    });
  }

  downloadAssetReport(asset: AssetDto): void {
    this.loadingService.presentSecondLoader(
      this.translate.instant(
        'pages.profile.home-owner.assets.candidature.generating_pdf'
      ) as string,
      true
    );
    this.assetService
      .getAssetReport(asset.id)
      .pipe(first())
      .subscribe(
        (blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            alert(
              this.translate.instant(
                'pages.profile.home-owner.assets.disable_popup_blocker'
              )
            );
          }
          this.loadingService.dismissSecondLoader();
        },
        () => {
          this.presentToast(
            this.translate.instant(
              'pages.profile.home-owner.assets.can_not_generate_report'
            ) as string,
            'danger'
          );
          this.loadingService.dismissSecondLoader();
        }
      );
  }

  async presentSelectedCandidatureModal(
    selectedCandidature: Candidature,
    closed?: boolean
  ): Promise<void> {
    const closedOperation: boolean = closed || false;
    const modal = await this.modalController.create({
      component: SelectedCandidatureModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature: selectedCandidature,
        closedOperation
      },
      id: 'selected-candidature'
    });
    modal.onDidDismiss().then(() => {
      this.goBackToAssets();
    });
    return await modal.present();
  }

  async addCandidature(isMultiple: boolean = false): Promise<void> {
    let addCandidatureModal: HTMLIonModalElement;
    if (
      this.user?.bigTenant &&
      this.user.session.maxCandidaturesByAssetMonth === this.candidatures.length
    ) {
      // Un bigtenant tiene un máximo de candidaturas por asset
      const alert = await this.alertController.create({
        cssClass: 'custom-alert',
        header: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.add_candidature.header'
        ) as string,
        message: this.translate.instant(
          'pages.profile.home-owner.assets.candidature.add_candidature.message'
        ) as string,
        buttons: [
          {
            text: this.translate.instant(
              'pages.profile.home-owner.assets.candidature.add_candidature.accept'
            ) as string,
            role: 'cancel',
            cssClass: 'secondary'
          }
        ]
      });
      await alert.present();
    } else if (this.user.trialVersion) {
      addCandidatureModal = await this.modalController.create({
        component: AddTrialCandidatureComponent,
        cssClass: 'auto-height modal-extend',
        componentProps: {
          user: this.authenticationService.user,
          asset: this.asset
        }
      });
      addCandidatureModal
        .onDidDismiss()
        .then((close: OverlayEventDetail<GeneralModalResponse>) => {
          if (close && close.data && close.data.saving) {
            this.getCandidatures(this.asset);
          }
        });
      await addCandidatureModal.present();
    } else {
      const candidaturesCurrentMonth =
        Number(localStorage.getItem('candidaturesCurrentMonth')) || 0;
      if (this.user.session.maxCandidaturesMonth <= candidaturesCurrentMonth) {
        const alert = await this.alertController.create({
          cssClass: 'custom-alert',
          header: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.add_candidature.header'
          ) as string,
          message: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.add_candidature.message2'
          ) as string,
          buttons: [
            {
              text: this.translate.instant(
                'pages.profile.home-owner.assets.candidature.add_candidature.cancel'
              ) as string,
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: this.translate.instant(
                'pages.profile.home-owner.assets.candidature.add_candidature.upgrade'
              ) as string,
              handler: (): void => {
                this.router.navigateByUrl(
                  `/home-owner/${this.user.id}/profile`
                );
              }
            }
          ]
        });
        await alert.present();
      } else {
        // let addCandidatureModal;
        if (isMultiple) {
          addCandidatureModal = await this.modalController.create({
            component: AddMultipleCandidatureModalComponent,
            cssClass: 'auto-height modal-extend',
            componentProps: {
              user: this.authenticationService.user,
              asset: this.asset
            }
          });
        } else {
          addCandidatureModal = await this.modalController.create({
            component: AddCandidatureModalComponent,
            cssClass: 'auto-height modal-extend',
            componentProps: {
              user: this.authenticationService.user,
              asset: this.asset
            }
          });
        }
      }

      if (addCandidatureModal) {
        addCandidatureModal
          .onDidDismiss()
          .then((close: OverlayEventDetail<GeneralModalResponse>) => {
            if (close && close.data && close.data.saving) {
              let candidaturesCurrentMonth =
                Number(localStorage.getItem('candidaturesCurrentMonth')) || 0;
              candidaturesCurrentMonth++;
              localStorage.setItem(
                'candidaturesCurrentMonth',
                candidaturesCurrentMonth.toString()
              );
              this.getCandidatures(this.asset);
            }
          });

        await addCandidatureModal.present();
      }
    }
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
    this.widthChangeSub?.unsubscribe();
  }

  setEmptyListMessage(): string {
    if (this.user.apiId) {
      return this.translate.instant(
        'pages.profile.home-owner.assets.candidature.unavailable_candidatures_portfolio'
      ) as string;
    } else {
      return this.translate.instant(
        'pages.profile.home-owner.assets.candidature.unavailable_candidatures'
      ) as string;
    }
  }

  toggleRejected(): void {
    this.showRejected = !this.showRejected;

    // Change the name of the button.
    if (this.showRejected) this.buttonName = 'Ocultar descartadas / caducadas';
    else this.buttonName = 'Mostrar descartadas / caducadas';
  }

  updateDefaultNotifications(): void {
    this.userService.updateDefaultNotifications(this.notify).subscribe({
      next: () => null,
      error: () => {
        this.user.defaultNotifications = !this.notify;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.every((subs: Subscription) => subs.unsubscribe());
    this.widthChangeSub?.unsubscribe();
  }

  private setUser(): void {
    this.user = this.authenticationService.user;
  }

  private setAsset(): void {
    this.asset = this.assetService.selectedAsset;
  }

  private setNotify(): void {
    this.notify = this.user.defaultNotifications;
  }

  private checkCandidatureStatuses(): void {
    this.candidatures.forEach((candidature: Candidature) => {
      if (candidature.candidatureStatusEnum === CandidatureStatusEnum.SELECT) {
        this.showBackground = false;
        this.presentSelectedCandidatureModal(candidature, true);
      } else if (
        candidature.isCandidatureSelectAndExpired &&
        candidature.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED
      ) {
        this.showBackground = false;
        this.presentSelectedCandidatureModal(candidature, true);
      }
    });
  }

  private orderCandidatures(): void {
    this.candidatures.sort((a: Candidature, b: Candidature) => {
      const mapCandidatures = {};
      mapCandidatures[CandidatureStatusEnum.SELECT] = 0;
      mapCandidatures[CandidatureStatusEnum.PRE_SELECT] = 1;
      mapCandidatures[CandidatureStatusEnum.PENDING] = 2;
      mapCandidatures[CandidatureStatusEnum.WITHOUT_CHECKING] = 3;
      mapCandidatures[CandidatureStatusEnum.REJECT] = 4;
      if (
        mapCandidatures[a.candidatureStatusEnum] <
        mapCandidatures[b.candidatureStatusEnum]
      ) {
        return -1;
      }
      if (
        mapCandidatures[a.candidatureStatusEnum] >
        mapCandidatures[b.candidatureStatusEnum]
      ) {
        return 1;
      }
      return 0;
    });
  }
}
