import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentDTO, TypeDocumentEnum, User } from '@core/models';
import { UtilsService, prepareFile } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';

import { ViveAddDocumentModalClosingData } from '../../models/vive-modal.model';

@Component({
  selector: 'el-buen-inquilino-vive-add-doc-modal',
  templateUrl: './vive-add-doc-modal.component.html'
})
export class ViveAddDocModalComponent implements OnInit {
  @Input() typeDocumentInput: TypeDocumentEnum;
  @Input() name: string;
  @Input() user: User;

  form: FormGroup;
  file: File;
  saving = false;
  validationErrorMessages = appValidators.validationErrorMessages;

  get fileControl(): FormControl {
    return this.form.get('file') as FormControl;
  }

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  changeListener(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.file = prepareFile(file);
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  saveDocument(): void {
    if (!this.file) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const document: DocumentDTO = {
      file: null,
      name: this.name,
      typeDocument: this.typeDocumentInput,
      tenantId: this.user.id,
      extension: this.file.type
    };

    this.modalController.dismiss({
      document,
      file: this.file
    } as ViveAddDocumentModalClosingData);
  }

  async presentToast(messageKey: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translate.instant(messageKey) as string,
      position: 'top',
      color,
      duration: 4000
    });
    toast.present();
  }

  private initForm(): void {
    this.form = new FormGroup({
      name: new FormControl<string>({ value: this.name, disabled: true }),
      file: new FormControl(null, Validators.required)
    });
  }
}
