import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { ContactDto } from '../../models';

const PATH_REQUEST_LOAN = '/loans/{candidatureId}/request';
const PATH_GET_CONTACT = '/contacts/{contactId}';

@Injectable()
export class LoansApiService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.insurances;
  }

  getContact(contactId: string): Observable<ContactDto> {
    return this.http.get<ContactDto>(
      this.url + PATH_GET_CONTACT.replace('{contactId}', contactId)
    );
  }

  requestLoan(candidatureId: string): Observable<string> {
    return this.http.post<string>(
      this.url + PATH_REQUEST_LOAN.replace('{candidatureId}', candidatureId),
      {}
    );
  }
}
