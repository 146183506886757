/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormGroup
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastController } from '@ionic/angular';
import { sub } from 'date-fns';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DocumentsApiService } from '../../api-services/documenst-api/documents-api.service';
import {
  AssetDto,
  BillDocumentsEnum,
  CheckMinimumDoc,
  DocumentDTO,
  InOutEnum,
  LabelDto,
  LastTwoPayslipMonths,
  MinimalDocumentationCheck,
  ProfileDocumentEnum,
  SocialNetworkStatusEnum,
  TypeDocumentEnum,
  User
} from '../../models';

@Injectable()
export class UtilsService {
  DEFAULT_LOGO_URL = './assets/images/userdefault.png';

  constructor(
    private docService: DocumentsApiService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private toastController: ToastController
  ) {}

  downloadPDF(filename: string, response: Blob): void {
    const element = document.createElement('a');
    element.setAttribute('href', window.URL.createObjectURL(response));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getSelectedElements(
    allElements$: Observable<any>,
    selectedElements$: Observable<any>
  ): Observable<any> {
    return allElements$.pipe(
      switchMap((items: any[]) =>
        selectedElements$.pipe(
          map((favItems: any[]) => this.markItems(items, favItems))
        )
      )
    );
  }

  hexToRGB(hex, alpha = 1): string {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  async UrlToBlob(url: string): Promise<Blob> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw error;
    }
  }

  getImage(profilePhotoId: string): Observable<any> {
    if (profilePhotoId) {
      return this.docService
        .getFileByDocument(profilePhotoId)
        .pipe(
          map(async (r) =>{
            const url = await this.UrlToBlob(r.url)
            this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(url))
          }
          )
        );
    } else {
      return of(this.sanitizer.bypassSecurityTrustUrl(this.DEFAULT_LOGO_URL));
    }
  }

  getSectionUrl(section: any): string {
    switch (section.id) {
      case 'FEATURES': {
        section.url = 'profile';
        break;
      }
      case 'DNI': {
        section.url = 'profile';
        break;
      }
      case 'DOCUMENTATION': {
        section.url = 'documents';
        break;
      }
      case 'PROFESSIONAL_DATA': {
        section.url = 'professional-data';
        break;
      }
      case 'OFFERS': {
        section.url = '#';
        break;
      }
      default: {
        break;
      }
    }
    return section;
  }

  calculatedUltimateTwoMonthForPayslips(): LastTwoPayslipMonths {
    const lastTwoPayslipMonths: LastTwoPayslipMonths =
      {} as LastTwoPayslipMonths;

    const today = new Date();
    const day10month = new Date().setDate(10);

    if (this.compareDates(today, new Date(day10month))) {
      lastTwoPayslipMonths.ultimatePayslip = this.calculateMonthIndex(
        today.getMonth() - 2
      );
      lastTwoPayslipMonths.penultimatePayslip = this.calculateMonthIndex(
        today.getMonth() - 3
      );
    } else {
      lastTwoPayslipMonths.ultimatePayslip = this.calculateMonthIndex(
        today.getMonth() - 1
      );
      lastTwoPayslipMonths.penultimatePayslip = this.calculateMonthIndex(
        today.getMonth() - 2
      );
    }
    return lastTwoPayslipMonths;
  }

  calculateMonthIndex(index: number): string {
    const today = new Date();
    today.setMonth(index);
    return this.transtaleMonthToString(today.getMonth());
  }

  transtaleMonthToString(indexToMonthString: number): string {
    const index: number = indexToMonthString;
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];

    return monthNames[index] || '';
  }

  compareDates = (a: string | Date, b: string | Date): boolean => {
    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();

    return dateB > dateA;
  };

  parseInOutEnumTypeToStatus(typeString: string): InOutEnum {
    const type = InOutEnum[typeString];
    if (type === undefined) {
      return InOutEnum.UNKNOWN;
    }

    return type;
  }

  parseSocialNetworkStatusEnumTypeToStatus(
    typeString: string
  ): SocialNetworkStatusEnum {
    const type = SocialNetworkStatusEnum[typeString];
    if (type === undefined) {
      return SocialNetworkStatusEnum.UNKNOWN;
    }

    return type;
  }

  getColorInOutByStatus(status: string): string {
    let statusArr = 'primary';
    switch (this.parseInOutEnumTypeToStatus(status)) {
      case InOutEnum.NOT_REGISTERED:
      case InOutEnum.NOT_ACCEPTED:
      case InOutEnum.NO_DOCUMENTATION:
      case InOutEnum.INCOMPLETE_DOCUMENTATION:
      case InOutEnum.OWNER:
        statusArr = 'success';
        break;
      case InOutEnum.INCIDENT:
      case InOutEnum.NO_ANALYSIS:
        statusArr = 'danger';
        break;
      case InOutEnum.FINISHED:
      case InOutEnum.UNKNOWN:
        statusArr = 'warning';
        break;
    }
    return statusArr;
  }

  getColorSocialNetworkStatusByStatus(statusSocialNetwork: string): string {
    let statusArr = 'primary';
    switch (
      this.parseSocialNetworkStatusEnumTypeToStatus(statusSocialNetwork)
    ) {
      case SocialNetworkStatusEnum.FILLED:
        statusArr = 'success';
        break;
      case SocialNetworkStatusEnum.EMPTY:
        statusArr = 'danger';
        break;
      case SocialNetworkStatusEnum.UNKNOWN:
        statusArr = 'warning';
        break;
    }
    return statusArr;
  }

  getIconSocialNetworkStatusByStatus(statusSocialNetwork: string): string {
    let statusIcon = 'prmary';
    switch (
      this.parseSocialNetworkStatusEnumTypeToStatus(statusSocialNetwork)
    ) {
      case SocialNetworkStatusEnum.FILLED:
        statusIcon = 'checkmark-circle';
        break;
      case SocialNetworkStatusEnum.EMPTY:
        statusIcon = 'close-circle';
        break;
      case SocialNetworkStatusEnum.UNKNOWN:
        statusIcon = 'alert-circle-outline';
        break;
    }
    return statusIcon;
  }

  formatPhone(phone: any): string {
    return phone?.split(/\s+/).join('');
  }

  getPayrollMonth(number: number): string {
    const dateMoment = this.getMomentMonth(number);
    return this.datePipe.transform(
      dateMoment.toDate(),
      'MMMM',
      undefined,
      localStorage.getItem('language') || 'es'
    );
  }

  getMonthNumber(number: number): number {
    const date = this.getMomentMonth(number).get('M');
    return date + 1;
  }

  getMomentMonth(number: number): moment.Moment {
    const today = moment().startOf('day');
    const day10 = moment().date(10).startOf('day');

    if (today.isSameOrAfter(day10)) {
      return moment().subtract(number, 'months').startOf('day');
    } else {
      if (number === 0) {
        return moment().subtract(0, 'months').startOf('day');
      }
      if (number === 1) {
        return moment().subtract(1, 'months').startOf('day');
      } else {
        return moment().subtract(2, 'months').startOf('day');
      }
    }
  }

  isValidDateForPayslipsAnalysis(paySlipDate): boolean {
    const dateInput = moment(paySlipDate);
    const today = moment();
    const dayofMonth = today.date();

    let initRange;
    let endRange;

    if (dayofMonth >= 10) {
      initRange = today.clone().subtract(2, 'months').startOf('month');
      endRange = today.clone().subtract(1, 'months').endOf('month');
    } else {
      initRange = today.clone().subtract(3, 'months').startOf('month');
      endRange = today.clone().subtract(2, 'months').endOf('month');
    }

    return dateInput
      ? dateInput.isBetween(initRange, endRange, 'day', '[]')
      : false;
  }

  getQuarterString(number: number): string {
    let quarter: number;
    if (number === 1) {
      quarter = moment().subtract(3, 'months').quarter();
    } else {
      quarter = moment().subtract(6, 'months').quarter();
    }
    return quarter?.toString();
  }

  getQuarterMoment(number: number): moment.Moment {
    let date: moment.Moment;
    if (number === 1) {
      date = moment().subtract(3, 'months');
    } else {
      date = moment().subtract(6, 'months');
    }

    return moment(date).quarter(date.quarter());
  }

  hasExtraDoc(user: User): boolean {
    return !!user?.extraDocumentation && user?.extraDocumentation !== '';
  }

  checkMinimumDocForTenantRol(minDoc: CheckMinimumDoc, tenant: User): boolean {
    let minimunDocs = false;
    if (minDoc.student) {
      minimunDocs = minDoc.hasDni;
    } else if (minDoc.freelance) {
      if (this.hasExtraDoc(tenant)) {
        minimunDocs =
          minDoc.hasDni &&
          minDoc.hasWorkContract &&
          minDoc.hasInvoicePaymentConfirmation &&
          minDoc.hasInvoiceRecordBook;
      } else {
        minimunDocs =
          minDoc.hasDni &&
          minDoc.hasRent &&
          (minDoc.hasModel130 || minDoc.hasIvaQuarterModel);
      }
    } else if (minDoc.retired) {
      if (this.hasExtraDoc(tenant)) {
        minimunDocs =
          minDoc.hasDni &&
          minDoc.hasPensionerRecognition &&
          minDoc.hasBankStatement;
      } else {
        minimunDocs = minDoc.hasDni && minDoc.hasPensionerRecognition;
      }
    } else if (minDoc.business) {
      minimunDocs =
        minDoc.hasDni &&
        minDoc.hasIs200Model &&
        minDoc.hasIvaQuarterModel &&
        minDoc.hasSSCertificate &&
        minDoc.hasAEATCertificate &&
        minDoc.hasPYGQuarter;
    } else {
      if (this.hasExtraDoc(tenant)) {
        minimunDocs = minDoc.hasDni && minDoc.hasPayslips && minDoc.hasWorkLife;
      } else {
        minimunDocs = minDoc.hasDni && minDoc.hasPayslips;
      }
    }
    if (tenant.foreignIncomes) {
      minimunDocs =
        minimunDocs && minDoc.hasWorkContract && minDoc.hasBankStatement;
    }

    return minimunDocs;
  }

  translateDocumentType(documentType: string): string {
    switch (documentType) {
      case TypeDocumentEnum.DNI as string:
        return 'DNI';
      case TypeDocumentEnum.PAYSLIP as string:
        return 'Nómina';
      case TypeDocumentEnum.INCOME_TAX_DECLARATION as string:
        return 'Declaración de la Renta';
      case TypeDocumentEnum.PENSIONER_RECOGNITION as string:
        return 'Reconocimiento de pensión';
      case TypeDocumentEnum.IS200_MODEL as string:
        return 'Impuesto sociedades';
      case TypeDocumentEnum.IVA_QUARTER_MODEL as string:
        return 'IVA trimestrales';
      case TypeDocumentEnum.WORK_LIFE as string:
        return 'Vida laboral';
      case TypeDocumentEnum.MODEL_130 as string:
        return 'Modelo 130';
      case TypeDocumentEnum.SS_CERTIFICATE as string:
        return 'Certificado SS';
      case TypeDocumentEnum.AEAT_CERTIFICATE as string:
        return 'Certificado AEAT';
      case TypeDocumentEnum.PYG_QUARTER as string:
        return 'Trimestre PYG';
      case TypeDocumentEnum.WORK_CONTRACT as string:
        return 'Contrato trabajo';
      case TypeDocumentEnum.BANK_STATEMENT as string:
        return 'Justificante bancario';
      case TypeDocumentEnum.REMOTE_JOB_CERTIFICATE as string:
        return 'Trabajo remoto';
      case TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION as string:
        return 'Acreditación cobro';
      case TypeDocumentEnum.INVOICE_RECORD_BOOK as string:
        return 'Libro facturas';
      case TypeDocumentEnum.OTHER_DOCUMENT as string:
        return 'Otros documentos';
      default:
        return '';
    }
  }

  checkMinimalDocumentation(
    documents: DocumentDTO[],
    extraDoc: boolean = false
  ): MinimalDocumentationCheck {
    const result: MinimalDocumentationCheck = new MinimalDocumentationCheck();
    const currentDate = new Date();

    const payslips = documents.filter(
      (doc) => doc.typeDocument === TypeDocumentEnum.PAYSLIP
    );
    const ivaQuarterModel = documents.filter(
      (doc) => doc.typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL
    );

    // Verificaciones de nóminas
    const payslipCheck = this.hasPayslipsUpdated(
      payslips,
      currentDate,
      extraDoc
    );
    result.hasOneMonthPayslips = payslipCheck.hasOneMonth;
    result.hasTwoMonthPayslips = payslipCheck.hasTwoMonths;
    result.hasPayslips = payslipCheck.hasPayslips;

    // Si extraDoc es true, la tercera nómina debe ser obligatoria
    if (extraDoc) {
      result.hasNotOptionalPayslip = !payslipCheck.hasOptionalPayslip;
      result.hasPayslips = result.hasPayslips && !result.hasNotOptionalPayslip;
    } else {
      result.hasNotOptionalPayslip = this.hasOptionalPayslipMissing(
        payslips,
        currentDate
      );
    }

    // Verificaciones de modelos trimestrales
    const quarterCheck = this.checkQuarterModels(
      ivaQuarterModel,
      TypeDocumentEnum.IVA_QUARTER_MODEL,
      currentDate
    );

    result.hasOneQuarterModel = quarterCheck.hasOneQuarter;
    result.hasTwoQuarterModel = quarterCheck.hasTwoQuarters;

    result.hasIvaQuarterModel =
      quarterCheck.hasOneQuarter || quarterCheck.hasTwoQuarters;

    // Asignación de otros flags
    result.hasDni = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.DNI
    );
    result.hasRent = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.INCOME_TAX_DECLARATION
    );
    result.hasOtherDocuments = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.OTHER_DOCUMENT
    );

    // Otros flags que no dependen de lógica auxiliar
    result.hasSSCertificate = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.SS_CERTIFICATE
    );

    result.hasAEATCertificate = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.AEAT_CERTIFICATE
    );

    result.hasWorkContract = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.WORK_CONTRACT
    );
    result.hasWorkLife = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.WORK_LIFE
    );

    result.hasBankStatement = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.BANK_STATEMENT
    );

    result.hasInvoicePaymentConfirmation = documents.some(
      (doc) =>
        doc.typeDocument === TypeDocumentEnum.INVOICE_PAYMENT_CONFIRMATION
    );

    result.hasInvoiceRecordBook = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.INVOICE_RECORD_BOOK
    );

    result.hasPYGQuarter = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.PYG_QUARTER
    );

    result.hasIs200Model = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.IS200_MODEL
    );

    result.hasModel130 = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.MODEL_130
    );

    result.hasPensionerRecognition = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.PENSIONER_RECOGNITION
    );

    result.hasRemoteJobCertificate = documents.some(
      (doc) => doc.typeDocument === TypeDocumentEnum.REMOTE_JOB_CERTIFICATE
    );

    return result;
  }

  monthsDiff(date1: Date, date2: Date): number {
    return moment([date1.getFullYear(), date1.getMonth()]).diff(
      moment([date2.getFullYear(), date2.getMonth()]),
      'months'
    );
  }

  hasPayslipsUpdated(
    payslips: DocumentDTO[],
    currentDate: Date,
    extraDoc: boolean
  ): {
    hasOneMonth: boolean;
    hasTwoMonths: boolean;
    hasPayslips: boolean;
    hasOptionalPayslip: boolean;
  } {
    const oneMonthAgo = moment(currentDate)
      .subtract(1, 'month')
      .startOf('month');
    const twoMonthsAgo = moment(currentDate)
      .subtract(2, 'month')
      .startOf('month');
    const threeMonthsAgo = moment(currentDate)
      .subtract(3, 'month')
      .startOf('month');

    const hasOneMonth = payslips.some((p) =>
      moment(p.date).startOf('month').isSame(oneMonthAgo, 'month')
    );
    const hasTwoMonths = payslips.some((p) =>
      moment(p.date).startOf('month').isSame(twoMonthsAgo, 'month')
    );
    const hasOptionalPayslip = payslips.some((p) =>
      moment(p.date).startOf('month').isSame(threeMonthsAgo, 'month')
    );

    let hasPayslips = hasOneMonth && hasTwoMonths;

    // Si extraDoc es true, la tercera nómina debe estar presente
    if (extraDoc) {
      hasPayslips = hasPayslips && hasOptionalPayslip;
    }

    return {
      hasOneMonth,
      hasTwoMonths,
      hasPayslips,
      hasOptionalPayslip
    };
  }

  hasOptionalPayslipMissing(
    payslips: DocumentDTO[],
    currentDate: Date
  ): boolean {
    if (moment(currentDate).date() >= 10) return false; // No aplica si el día >= 10
    const lastMonth = moment(currentDate).subtract(1, 'month');
    return !payslips.some(
      (p) =>
        moment(p.date).month() === lastMonth.month() &&
        moment(p.date).year() === lastMonth.year()
    );
  }

  checkQuarterModels(
    documents: DocumentDTO[],
    type: TypeDocumentEnum,
    currentDate: Date
  ): { hasOneQuarter: boolean; hasTwoQuarters: boolean } {
    const quarterDocs = documents.filter((doc) => doc.typeDocument === type);
    let hasOneQuarter = false;
    let hasTwoQuarters = false;

    quarterDocs.forEach((doc) => {
      const diff = moment([
        currentDate.getFullYear(),
        currentDate.getMonth()
      ]).diff(
        moment([
          new Date(doc.date).getFullYear(),
          new Date(doc.date).getMonth()
        ]),
        'quarters'
      );

      if (diff === 1) hasOneQuarter = true;
      if (diff === 2) hasTwoQuarters = true;
    });

    return { hasOneQuarter, hasTwoQuarters };
  }

  /**
   * Funcion que controla si el freelance tiene modelo 130
   * pero si tiene trimestres de IVA el model130 no será obligatorio,
   * si no tiene modelo 130 no se mostrará el componente,
   * ya que no sera obligatorio.
   *
   * @param hasTwoQuarterModel : boolean
   * @param hasModel130 : boolean
   */
  checkModel130ForFreelance(
    hasTwoQuarterModel: boolean,
    hasModel130: boolean
  ): boolean {
    return !hasModel130 && hasTwoQuarterModel ? false : true;
  }

  /**
   * Funcion que controla si el freelance tiene trimestres de IVA
   * pero si tiene modelo 130 el trimestres de IVA no será obligatorio,
   * si no tiene trimestres de IVA no se mostrará el componente,
   * ya que no sera obligatorio.
   *
   * @param hasTwoQuarterModel : boolean
   * @param hasModel130 : boolean
   */
  checkTwoIvaQuarterForFreelance(
    hasTwoQuarterModel: boolean,
    hasModel130: boolean
  ): boolean {
    return hasModel130 && !hasTwoQuarterModel ? false : true;
  }

  showFormErrors(form: UntypedFormGroup): void {
    form.markAllAsTouched();
    Object.keys(form.controls).forEach((controlName) => {
      const control = form.get(controlName);
      if (control instanceof FormArray) {
        control.markAllAsTouched();
        control.controls.forEach((arrayControl: FormControl) =>
          this.markControlAsDirty(arrayControl)
        );
      } else {
        this.markControlAsDirty(control as FormControl);
      }
    });
  }

  markControlAsDirty(control: FormControl): void {
    control.markAsDirty();
  }

  async presentToast(
    message: string,
    color: string,
    duration: number
  ): Promise<void> {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color,
      duration
    });

    toast.present();
  }

  orderAssets(assets: AssetDto[]): AssetDto[] {
    //Se ordenan los assets por calle, portal, planta y letra
    assets = assets
      .sort((a, b) =>
        a?.street?.toLowerCase().localeCompare(b?.street?.toLowerCase())
      )
      .sort(
        (a, b) =>
          a?.street?.toLowerCase() === b?.street?.toLowerCase() &&
          a?.portal?.toLowerCase().localeCompare(b?.portal?.toLowerCase())
      )
      .sort(
        (a, b) =>
          a?.street?.toLowerCase() === b?.street?.toLowerCase() &&
          a?.portal?.toLowerCase() === b?.portal?.toLowerCase() &&
          a?.floor?.toLowerCase().localeCompare(b?.floor?.toLowerCase())
      )
      .sort(
        (a, b) =>
          a?.street?.toLowerCase() === b?.street?.toLowerCase() &&
          a?.portal?.toLowerCase() === b?.portal?.toLowerCase() &&
          a?.floor?.toLowerCase() === b?.floor?.toLowerCase() &&
          a?.door?.localeCompare(b?.door)
      );

    return assets;
  }

  getBase64FromFile(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!(file instanceof File) && !(file instanceof Blob)) {
        reject(new Error('Invalid input. Expected a File instance.'));
        return;
      }

      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          resolve(reader.result as string);
        };
        reader.onerror = (error): void => reject(error);
      } catch (error) {
        console.error('Error in reader.onload:', error);
      }
    });
  }

  mask(name): string {
    const regex1 = /\b[^\W]{2}([^\W]{1,2})\b/g;
    const regex2 = /\b[^\W]{2}([^\W]{2,})[^\W]\b/g;
    if (name) {
      return name.replace(regex1, '**$1').replace(regex2, '**$1*');
    }
    return '';
  }

  filterLabels(defaultLabels: LabelDto[], labels: LabelDto[]): LabelDto[] {
    defaultLabels?.forEach((dl: LabelDto) => {
      const index = labels.findIndex(
        (l) => l.id === dl.id && l.name === dl.name
      );
      if (index > -1) {
        labels.splice(index, 1);
      }
    });
    return labels;
  }

  /**
   * Calcula la fecha de hace 18 años
   * @returns
   */
  calculate18YearsAgoStringDate(): Date {
    return sub(new Date(), { years: 18 });
  }

  getCurrentRouteUrlArray(currentUrl: string): string[] {
    const urlArray = currentUrl.split('/');
    urlArray.shift();

    return urlArray;
  }

  /**
   * Se intruduce un espacio cada 4 numeros al valor del input ccc para
   * facilitar lectura
   * @param event valor para ccc del fomulario
   */
  cccMaskChanges(event: Event, form: FormGroup, control: string): void {
    const value = (event.target as HTMLInputElement).value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    form.get(control).setValue(value);
  }

  private markItems(allItems: any[], favoriteItems: any[]): any[] {
    let favoriteIds;
    if (favoriteItems) {
      favoriteIds = favoriteItems.map((r) => r.id);
    }
    return allItems.map((x) => {
      if (favoriteIds) {
        x.checked = favoriteIds.includes(x.id);
      } else {
        x.checked = false;
      }
      this.getSectionUrl(x);
      return x;
    });
  }
}

export function calculatedAdultDate(): Date {
  const adultDate = new Date();
  adultDate.setFullYear(adultDate.getFullYear() - 18);
  return adultDate;
}

export function filterIncidenceTypes(
  incidencesTypes: string[],
  type?: TypeDocumentEnum | ProfileDocumentEnum | BillDocumentsEnum | null
): string[] {
  switch (type ? type : null) {
    case TypeDocumentEnum.DNI:
      return incidencesTypes.filter((incidence) =>
        incidence.includes('Dni mal escaneado o con información incompleta')
      );
    case TypeDocumentEnum.PAYSLIP:
      return incidencesTypes.filter((incidence) =>
        incidence.includes('Nómina mal escaneada o con información incompleta')
      );
    case TypeDocumentEnum.INCOME_TAX_DECLARATION:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          'Declaración de la renta mal escaneada o con información incompleta'
        )
      );
    // case TypeDocumentEnum.OTHER_DOCUMENT:
    //   return incidencesTypes.filter(
    //     (incidence) => incidence.includes('El tipo "otros documentos"
    // esta mal escaneado o con información incompleta')
    //   );
    case TypeDocumentEnum.PENSIONER_RECOGNITION:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          // eslint-disable-next-line max-len
          'Reconocimiento de la pensión mal escaneado o con información incompleta'
        )
      );
    case TypeDocumentEnum.IS200_MODEL:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          'Impuesto de Sociedades mal escaneado o con información incompleta'
        )
      );
    case TypeDocumentEnum.IVA_QUARTER_MODEL:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          'IVA trimestral mal escaneado o con información incompleta'
        )
      );
    case TypeDocumentEnum.MODEL_130:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          'Modelo 130 mal escaneado o con información incompleta'
        )
      );
    // case TypeDocumentEnum.WORK_LIFE:
    //   return incidencesTypes.filter(
    //     (incidence) => incidence.includes('Vida laboral mal
    // escaneado o con información incompleta')
    //   );
    case TypeDocumentEnum.SS_CERTIFICATE:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          // eslint-disable-next-line max-len
          'Certificado Seguridad Social mal escaneado o con información incompleta'
        )
      );
    case TypeDocumentEnum.AEAT_CERTIFICATE:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          'Certificado AEAT mal escaneado o con información incompleta'
        )
      );
    case TypeDocumentEnum.PYG_QUARTER:
      return incidencesTypes.filter((incidence) =>
        incidence.includes(
          // eslint-disable-next-line max-len
          'Perdidas y ganacias del trimestre mal escaneado o con información incompleta'
        )
      );
    default:
      return [
        'Incongruencia manifiesta entre los datos de la empresa y el titular',
        'Precio del alquiler muy por debajo del mercado',
        'Dirección no localizada o incompleta',
        'Otro tipo de incidencia'
      ];
  }
}

export function prepareFile(file: File): File {
  let name = file.name.toLowerCase();
  name = name.replace(/[^a-zA-Z0-9\s.]/g, '');
  return new File([file], name, { type: file.type });
}

export function loadImage(route: string): Promise<Blob> {
  return new Promise((resolve: (value: Blob) => void) => {
    fetch(route)
      .then((response) => response.blob())
      .then((blob) => {
        resolve(blob);
      });
  });
}

export function fileToBase64(file: File | Blob): Promise<string> {
  return new Promise<string>((resolve: (value: string) => void) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>): void => {
      const base64String = e.target.result as string;
      resolve(base64String);
    };

    reader.readAsDataURL(file);
  });
}
