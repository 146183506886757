import { Component, Input } from '@angular/core';

@Component({
  selector: 'el-buen-inquilino-ofuscate-bank-account-number',
  templateUrl: './ofuscate-bank-account-number.component.html'
})
export class OfuscateBankAccountNumberComponent {
  @Input({ required: true }) bankAccountNumber: string;
  ofuscate = true;

  get eyeIcon(): string {
    if (this.ofuscate) {
      return 'eye-outline';
    } else {
      return 'eye-off-outline';
    }
  }

  constructor() {}

  changeOfuscate(): void {
    this.ofuscate = !this.ofuscate;
  }
}
