/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
import { Component, Input, ViewChild } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import {
  AssetDto,
  NotificationDto,
  NotificationTypeEnum,
  UserCreateCandidatureDto,
  UserRequestCreateMultipleCandidatureDto
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { StepsService } from '@core/services/steps/steps.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StepTwoMultipleComponent } from '@shared/components/step-two-multiple/step-two-multiple.component';

@Component({
  selector: 'el-buen-inquilino-add-multiple-candidature-modal',
  templateUrl: './add-multiple-candidature-modal.component.html'
})
export class AddMultipleCandidatureModalComponent {
  @Input() asset: AssetDto;
  saving = false;
  maximunTenantsReached = false;
  @ViewChild('stepTwo') stepTwo: StepTwoMultipleComponent;

  constructor(
    public stepsService: StepsService,
    private modalController: ModalController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private candidatureService: CandidaturesApiService,
    private notificationsService: NotificationsApiService,
    private authenticationService: AuthService
  ) {
    this.stepsService.userRequestCreateCandidatureDto.users = [];
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
  }

  addCandidature(): void {
    this.saving = true;
    this.candidatureService
      .homeOwnerRegisterCandidatureMultipleForAllSelected(
        this.asset.id,
        this.stepsService.userRequestCreateCandidatureDto
      )
      .subscribe(
        (data: any) => {
          this.saving = false;
          // Se crean las notificaciones de candidatura
          this.createNotifications(
            data.id,
            this.asset,
            this.stepsService.userRequestCreateCandidatureDto
          );
          this.dismiss(true);
          this.presentToast(true);
        },
        (err) => {
          if (err.status === 409) {
            let msg = this.translateService.instant(
              'pages.add-tenant.multiple_candidature.error_tenant_other_candidature'
            );
            if (
              this.stepsService.userRequestCreateCandidatureDto.users.length > 1
            ) {
              msg = this.translateService.instant(
                'pages.add-tenant.multiple_candidature.error_multiple_tenants_other_candidature'
              );
            }
            this.presentToast(false, msg);
          } else {
            this.presentToast(false);
          }
          this.saving = false;
        }
      );
  }

  selectedUser(e): void {
    if (this.isUniqueUser(e)) {
      this.stepsService.userRequestCreateCandidatureDto.users.push(e);
    }
    this.checkCandidature();
    this.checkMaximunTenantForCandidature();
  }

  isUniqueUser(e): boolean {
    if (e) {
      return (
        this.stepsService.userRequestCreateCandidatureDto.users
          .map((it) => it.email)
          .indexOf(e.email) === -1
      );
    } else {
      return false;
    }
  }

  checkCandidature(): boolean {
    if (this.stepsService.userRequestCreateCandidatureDto.users === undefined) {
      this.stepsService.userRequestCreateCandidatureDto.users = [];
    }
    if (this.stepsService.userRequestCreateCandidatureDto.users?.length > 0) {
      this.stepsService.userRequestCreateCandidatureDto.users.forEach((it) => {
        if (it.email.length > 5 && it.email.includes('@')) {
          return false;
        }
      });
    } else {
      return true;
    }
  }

  async presentToast(ok: boolean, message?: string): Promise<void> {
    let messageKey = ok
      ? 'components.add_multiple_candidature_modal.create_ok'
      : 'components.add_multiple_candidature_modal.create_fail';
    const colorKey = ok ? 'success' : 'danger';
    if (message) {
      messageKey = message;
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  checkMaximunTenantForCandidature(): void {
    if (
      this.stepsService.userRequestCreateCandidatureDto.users &&
      this.stepsService.userRequestCreateCandidatureDto.users.length >=
        this.stepsService.MAXIMUN_TENANT_ON_CANDIDATURE
    ) {
      this.maximunTenantsReached = true;
      this.presentToast(
        false,
        'components.add_multiple_candidature_modal.max_tenants_msg2'
      );
    } else {
      this.maximunTenantsReached = false;
    }
  }

  removeUser(userEmail: any): void {
    const user: UserCreateCandidatureDto =
      this.stepsService.userRequestCreateCandidatureDto.users.find((tenant) => {
        return tenant.email === userEmail;
      });
    const idList = [user.id];
    if (user.coTenants) {
      user.coTenants.forEach((cotenantId) => {
        idList.push(cotenantId);
        const index =
          this.stepsService.userRequestCreateCandidatureDto.users.findIndex(
            (user) => {
              return user.id === cotenantId;
            }
          );
        this.stepsService.userRequestCreateCandidatureDto.users.splice(
          index,
          1
        );
      });
    }

    // Ahora borramos del listado al propio usuario
    const index =
      this.stepsService.userRequestCreateCandidatureDto.users.findIndex(
        (userCandidature) => {
          return userCandidature.email === user.email;
        }
      );
    this.stepsService.userRequestCreateCandidatureDto.users.splice(index, 1);

    this.stepTwo.removeUserFromParent(idList);
    this.checkMaximunTenantForCandidature();
  }

  mask(name): string {
    const regex1 = /\b[^\W]{2}([^\W]{1,2})\b/g;
    const regex2 = /\b[^\W]{2}([^\W]{2,})[^\W]\b/g;
    return name.replace(regex1, '**$1').replace(regex2, '**$1*');
  }

  private createNotifications(
    candidatureId: string,
    asset: AssetDto,
    userRequestCreateCandidatureDto: UserRequestCreateMultipleCandidatureDto
  ): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.CANDIDATURE;
    notification.candidatureId = candidatureId;
    notification.address = `${asset.street} ${asset.number}, ${asset.town} (${asset.province})`;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    if (!!asset.vppModality) {
      notification.vpp = true;
    }
    userRequestCreateCandidatureDto.users?.forEach((user) => {
      notification.recipientEmail = user.email;
      this.notificationsService.newNotification(notification).subscribe();
    });
  }
}
