import { Pipe, PipeTransform } from '@angular/core';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';

@Pipe({
  name: 'homeownerStatisticsTextColor'
})
export class HomeownerStatisticsTextColorPipe implements PipeTransform {
  transform(type: ChildRoutesEnum): string {
    if (type === ChildRoutesEnum.CANCELLED) {
      return 'danger';
    } else {
      return 'secondary';
    }
  }
}
