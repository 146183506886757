/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class WindowMeasuresService {
  innerWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(
    window.innerWidth
  );

  setInnerWidth(width: number): void {
    this.innerWidth$.next(width);
  }

  getInnerWidth(): Observable<number> {
    // TODO: crear tarea en Jira: Quitar codigo repetido de hostlistener y tirar del observable
    return this.innerWidth$.asObservable();
  }
}
