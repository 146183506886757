/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Input, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import {
  AssetDto,
  BuildingDto,
  Candidature,
  MoveCandidatureRequest,
  User
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-move-candidature-modal',
  templateUrl: './move-candidature-modal.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class MoveCandidatureModalComponent {
  @Input() candidature: Candidature;
  @Input() building: BuildingDto;
  @Input() user: User;
  selectedIndex = 0;
  newAsset: AssetDto;
  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private modalController: ModalController,
    private candidatureService: CandidaturesApiService,
    private utilsService: UtilsService
  ) {}
  selectedAsset(e): void {
    this.newAsset = e;
  }
  nextIndex(): void {
    this.selectedIndex++;
    this.stepper?.next();
  }
  previousIndex(): void {
    this.selectedIndex--;
    this.stepper?.previous();
  }
  confirm(): void {
    const moveCandidatureDto: MoveCandidatureRequest = {
      originCandidatureId: this.candidature.id,
      destinationAssetId: this.newAsset.id,
      tenantList: this.candidature.tenantCandidatureList
    };
    this.candidatureService
      .relocateCandidature(moveCandidatureDto)
      .subscribe(() => {
        this.modalController.dismiss({
          newAsset: this.newAsset
        });
      });
  }
  mask(name): string {
    return this.utilsService.mask(name);
  }
  dismiss(): void {
    this.modalController.dismiss();
  }
}
