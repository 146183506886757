import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalButtonRole } from '@core/models';
import { UtilsService, prepareFile } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';
import { IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import { add, formatDate } from 'date-fns';

import {
  CancelPolicyRequestModalForm,
  CancelPolicyRequestModalResp
} from '../../models/cancel-policy-request-modal.model';

@Component({
  selector: 'el-buen-inquilino-cancel-policy-request-modal',
  templateUrl: './cancel-policy-request-modal.component.html'
})
export class CancelPolicyRequestModalComponent implements OnInit {
  @Input() insuranceType = '';

  // Se utiliza el flag para referirse a la reapertura de un activo
  @Input() reopen = false;

  file: File = null;
  form: FormGroup<CancelPolicyRequestModalForm>;
  min: Date;
  showClearDatepicker = false;

  get fileControl(): FormControl {
    return this.form.controls.file;
  }

  get isDefaultInsurance(): boolean {
    return this.insuranceType === 'DEFAULT';
  }

  get futureDateControl(): FormControl {
    return this.form.controls?.futureDate;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.setMinDate();
  }

  dismiss(
    role: CustomModalButtonRole = CustomModalButtonRole.CANCEL,
    file?: File,
    futureDocumentDate?: string
  ): void {
    const data: CancelPolicyRequestModalResp = {
      file,
      comments: this.form.value.comments,
      futureDocumentDate
    };
    this.modalController.dismiss(data, role);
  }

  hasDocumentToggleChange(
    event: IonToggleCustomEvent<ToggleChangeEventDetail<boolean>>
  ): void {
    this.changeFileControlValidators(event.detail.checked);
  }

  changeListener(event: Event): void {
    this.file = prepareFile((event.target as HTMLInputElement).files[0]);
  }

  accept(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    let stringDate: string = null;
    if (!!this.futureDateControl) {
      stringDate = formatDate(this.futureDateControl.value, 'dd/MM/yyyy');
    }

    this.dismiss(CustomModalButtonRole.ACCEPT, this.file, stringDate);
  }

  deleteFile(): void {
    this.file = null;

    // Limpiar el valor del FormControl 'file'
    const fileControl = this.form.get('file');
    if (fileControl) {
      fileControl.setValue(null);
      fileControl.markAsDirty();
      fileControl.updateValueAndValidity();
    }

    const fileInput = document.getElementById('file') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  datepickerChange(): void {
    this.showClearDatepicker = true;
  }

  resetDatePicker(): void {
    this.showClearDatepicker = false;
    this.futureDateControl.setValue(null);
  }

  private changeFileControlValidators(checked: boolean): void {
    if (checked) {
      this.fileControl.setValidators(null);
      this.addFutureDateControl();
    } else {
      this.fileControl.setValidators(Validators.required);
      this.form.removeControl('futureDate');
    }
    this.fileControl.updateValueAndValidity();
  }

  private setForm(): void {
    this.form = new FormGroup<CancelPolicyRequestModalForm>({
      comments: new FormControl<string>(null),
      file: new FormControl<File>(
        null,
        this.isDefaultInsurance ? Validators.required : []
      )
    });
  }

  private addFutureDateControl(): void {
    const fileControl = this.form.get('file');
    if (fileControl) {
      fileControl.clearValidators();
      fileControl.updateValueAndValidity();
    }

    this.form.addControl(
      'futureDate',
      new FormControl<Date>(null, Validators.required)
    );
  }

  private setMinDate(): void {
    this.min = add(new Date(), {
      days: 1
    });
  }
}
