import { SelectOption } from '@shared/models/select-option.model';

import { IncidenceStatusEnum } from '../models';

export const incidenceStatusList: SelectOption[] = [
  {
    label: 'Cancelada',
    value: IncidenceStatusEnum.CANCELLED
  },
  {
    label: 'Pendiente',
    value: IncidenceStatusEnum.NEW
  },
  {
    label: 'Asignado',
    value: IncidenceStatusEnum.ESCALATED
  },
  {
    label: 'En progreso',
    value: IncidenceStatusEnum.EJECUCION
  },
  {
    label: 'Cerrada',
    value: IncidenceStatusEnum.CLOSED
  }
];
