/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

import { TypeUserEnum } from '../../models';
import { NavigationService } from '../../services/navigation/navigation.service';
import { StoreService } from '../../services/store/store.service';

@Injectable()
export class LandingGuard {
  constructor(
    private navigationService: NavigationService,
    private storeService: StoreService
  ) {}

  canActivate(): boolean | Observable<boolean> {
    const credentials = this.storeService.getCredentials();

    if (credentials) {
      if (credentials.typeUser === TypeUserEnum.INCOFISA_ADMINISTRATIVE) {
        this.navigationService.goToLandingBackoffice();
      } else if (
        credentials.typeUser === TypeUserEnum.HOMEOWNER ||
        credentials.typeUser === TypeUserEnum.HOMEOWNER_UNPAID
      ) {
        const tokenDecoded = jwt_decode(credentials.token);
        this.navigationService.goTenantsOfHomeOwner(tokenDecoded.user_id);
      } else if (credentials.typeUser === TypeUserEnum.TENANT) {
        const tokenDecoded = jwt_decode(credentials.token);
        this.navigationService.goTenantsHome(tokenDecoded.user_id);
      } else {
        return true;
      }
    }

    return true;
  }
}
