import { Pipe, PipeTransform } from '@angular/core';
import { TenantCandidature } from '@core/models';
import { CandidatureUtilsService } from '@core/services/candidature-utils/candidature-utils.service';

@Pipe({
  name: 'checkCandidatureTenant'
})
export class CheckCandidatureTenantPipe implements PipeTransform {
  constructor(private candidatureUtils: CandidatureUtilsService) {}

  transform(tenant: TenantCandidature): boolean {
    return this.candidatureUtils.checkTenant(tenant);
  }
}
