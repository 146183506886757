/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Directive } from '@angular/core';
import {
  Validator,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS
} from '@angular/forms';

@Directive({
  selector: '[elBuenInquilinoEmail]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailDirective, multi: true }
  ]
})
export class EmailDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (!control.value) return null;
    return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/.test(control.value)
      ? null
      : { emailValidatorError: true };
  }
}
