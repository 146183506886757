/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { DocumentDTO, User } from '@core/models';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-move-document-modal',
  templateUrl: './move-document-modal.component.html'
})
export class MoveDocumentModalComponent implements OnInit {
  @Input() cotenantsList: User[];
  @Input() document: DocumentDTO;
  @Input() originTenantId: string;
  targetTenantId: string;
  showError = false;
  textButton: string;
  disabledButton = false;

  constructor(
    private modalController: ModalController,
    private documentsService: DocumentsApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.setTextButton();
  }

  setTextButton(): void {
    this.textButton = this.translateService.instant(
      'pages.documents.create-page.reasign-button-text'
    );
  }

  dismiss(saving?): void {
    if (saving && !this.targetTenantId) {
      this.showError = true;
      return;
    } else if (saving) {
      this.textButton = this.translateService.instant(
        'pages.documents.create-page.reasign-button-text-process'
      );
      this.disabledButton = true;
      this.documentsService
        .moveDocument(
          this.originTenantId,
          this.document.typeDocument.toString(),
          this.document.name,
          this.targetTenantId
        )
        .subscribe(() => {
          this.modalController.dismiss({
            dismissed: true,
            saving
          });
        });
    } else {
      this.modalController.dismiss({
        dismissed: true,
        saving
      });
    }
  }

  changeValue(event): void {
    this.targetTenantId = event.detail.value;
  }
}
