import { Pipe, PipeTransform } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { TenantCandidature } from '@core/models';

@Pipe({
  name: 'updateColor'
})
export class UpdateColorPipe implements PipeTransform {
  constructor(private candidatureService: CandidaturesApiService) {}

  transform(tenant: TenantCandidature): string {
    return this.candidatureService.updateColor(tenant.user?.score?.value);
  }
}
