/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AnnonRecommendation,
  RecommendationTypeEnum,
  ReqRecommendation
} from '@core/models';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { MessageBean } from '../../utils/message-bean.utils';
import { calculateYearDefaultValue } from '../../utils/month-year.utils';

@Component({
  selector: 'el-buen-inquilino-recommendation-form',
  templateUrl: './recommendation-form.component.html'
})
export class RecommendationFormComponent implements OnInit {
  @Input() isAnon: boolean;
  @Input() request: ReqRecommendation;
  @Input() userId: string;

  @Output() send = new EventEmitter<any>();

  recommendation: AnnonRecommendation;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RecommendationTypeEnum = RecommendationTypeEnum;

  years: number[] = [];
  defaultYear: number = calculateYearDefaultValue();

  year: number;

  constructor(
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.generateArrayOfYears();

    if (this.userId) {
      this.isAnon = false;
    }
    this.recommendation = {} as AnnonRecommendation;
    this.recommendation.score = 4;
  }

  submitForm(form: NgForm): void {
    if (form.valid) {
      form.value.date = moment(
        `01-01-${form.value.date}`,
        'DD-MM-YYYY'
      ).toDate();
      form.value.score = this.recommendation.score;
      const sendValue = Object.assign(form.value, {});

      sendValue.userTargetCode = this.userId || this.request.userId;
      if (this.userId) {
        this.modalController.dismiss({ sendValue });
      } else {
        this.send.emit(sendValue);
      }
    } else {
      this.snackBarService.showAutoHide(
        MessageBean.buildMessages(form, this.translateService),
        5000
      );
    }
  }

  dismiss(): void {
    this.snackBarService.hide();
    this.modalController.dismiss();
  }

  onRatingChange(event: { rating: number }): void {
    this.recommendation.score = event.rating;
  }

  private generateArrayOfYears(): void {
    const max = new Date().getFullYear();
    const min = max - 4;
    for (let i = max; i >= min; i--) {
      this.years.push(i);
    }
  }
}
