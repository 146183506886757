/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MessageBean } from '@shared/utils/message-bean.utils';
import { Subject } from 'rxjs';

@Injectable()
export class SnackBarService {
  private snackbarSubject = new Subject<any>();
  snackbarState = this.snackbarSubject.asObservable();

  show(message: MessageBean, type?: string): void {
    this.snackbarSubject.next({ show: true, message, type });
  }

  showAutoHide(message: MessageBean, duration: number, type?: string): void {
    this.snackbarSubject.next({ show: true, message, type, duration });
  }

  hide(): void {
    this.snackbarSubject.next({ show: false });
  }
}
