import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/private/auth.guard';

import { BackofficeModule } from './modules/backoffice/backoffice.module';
import { GuarantorModule } from './modules/guarantor/guarantor.module';
import { HomeOwnerModule } from './modules/home-owner/home-owner.module';
import { RecommendationModule } from './modules/recommendation/recommendation.module';
import { TenantModule } from './modules/tenant/tenant.module';

const routes: Routes = [
  {
    path: 'backoffice',
    loadChildren: async (): Promise<typeof BackofficeModule> =>
      (await import('./modules/backoffice/backoffice.module')).BackofficeModule
  },
  {
    path: 'tenant',
    loadChildren: async (): Promise<typeof TenantModule> =>
      (await import('./modules/tenant/tenant.module')).TenantModule,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      roles: ['TENANT', 'HOMEOWNER']
    }
  },
  {
    path: 'home-owner',
    loadChildren: async (): Promise<typeof HomeOwnerModule> =>
      (await import('./modules/home-owner/home-owner.module')).HomeOwnerModule,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      roles: ['HOMEOWNER', 'TENANT']
    }
  },
  {
    path: 'guarantor',
    loadChildren: async (): Promise<typeof GuarantorModule> =>
      (await import('./modules/guarantor/guarantor.module')).GuarantorModule,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      roles: ['HOMEOWNER', 'TENANT']
    }
  },
  {
    path: 'recommendation-form/:id',
    loadChildren: async (): Promise<typeof RecommendationModule> =>
      (await import('./modules/recommendation/recommendation.module'))
        .RecommendationModule,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      roles: ['HOMEOWNER', 'TENANT']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule {}
