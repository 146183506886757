/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export class MessageBean {
  messages: string[];

  static buildMessages(
    ngForm: NgForm | UntypedFormGroup,
    translateService: TranslateService
  ): MessageBean {
    const message = new MessageBean();
    message.messages = [];
    Object.keys(ngForm.controls).forEach((key) => {
      if (ngForm.controls[key].errors) {
        Object.keys(ngForm.controls[key].errors).forEach((error) => {
          MessageBean.buildTranslate(
            message.messages,
            message.messages.length,
            'application.validations.fields.' + key,
            'application.validations.errors.' + error,
            translateService
          );
        });
      }
    });
    return message;
  }

  static buildMessagesFildset(
    ngForm: NgForm,
    translateService: TranslateService
  ): MessageBean {
    const message = new MessageBean();
    message.messages = [];
    Object.values(ngForm.controls).forEach((value: UntypedFormGroup) => {
      Object.keys(value.controls).forEach((key) => {
        if (value.controls[key].errors) {
          Object.keys(value.controls[key].errors).forEach((error) => {
            MessageBean.buildTranslate(
              message.messages,
              message.messages.length,
              'application.validations.fields.' + key,
              'application.validations.errors.' + error,
              translateService
            );
          });
        }
      });
    });
    return message;
  }

  private static buildTranslate(
    messages: string[],
    position: number,
    key: string,
    error: string,
    translateService: TranslateService
  ): void {
    translateService.get(key).subscribe((keyTranslate) => {
      translateService.get(error, { field: keyTranslate }).subscribe((data) => {
        messages[position] = data;
      });
    });
  }

  static buildMessage(messageString: string): MessageBean {
    const message = new MessageBean();
    message.messages = [messageString];
    return message;
  }

  static buildMessageWithTranslation(
    key: string,
    translateService: TranslateService
  ): MessageBean {
    const message = new MessageBean();
    message.messages = [];
    translateService.get(key).subscribe((keyTranslate) => {
      message.messages[0] = keyTranslate;
    });
    return message;
  }
}
