// import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  GeocodingResponse,
  StaticGoogleMapImage
} from '@shared/models/google-maps.model';
import { makeStaticMapImageUrl } from '@shared/utils/ebi-google-maps.utils';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { noTokenRequest } from '../../interceptors/interceptor';

@Injectable()
export class GoogleMapsService {
  // eslint-disable-next-line max-len
  // constructor(private mapsAPILoader: MapsAPILoader, private http: HttpClient) {}
  constructor(private http: HttpClient) {}
  getStaticMapImage(
    isCity: boolean = false,
    bLat: number,
    bLng: number
  ): Promise<string> {
    return new Promise(
      async (
        resolve: (value: string) => void,
        _reject: (reason?: unknown) => void
      ) => {
        const resp = await this.getMapCenter(isCity, bLat, bLng);

        const { centerLat, centerLng, lat, lng, zoom } = resp;

        resolve(makeStaticMapImageUrl(centerLat, centerLng, lat, lng, zoom));
      }
    );
  }

  async getMapCenter(
    isCity: boolean = false,
    lat: number,
    lng: number
  ): Promise<StaticGoogleMapImage> {
    if (isCity) {
      return await this.getCityData(lat, lng);
    } else {
      return await this.getCountryData(lat, lng);
    }
  }

  private getCityData(
    _lat: number,
    _lng: number
  ): Promise<StaticGoogleMapImage> {
    return new Promise(
      (
        _resolve: (value: StaticGoogleMapImage) => void,
        _reject: (reason?: unknown) => void
      ) => {
        /*this.mapsAPILoader.load().then(() => {
          const geocoder = new google.maps.Geocoder();
          const latlng = new google.maps.LatLng(lat, lng);
          geocoder.geocode(
            { location: latlng },
            (
              results: google.maps.GeocoderResult[],
              status: google.maps.GeocoderStatus
            ) => {
              if (status === 'OK') {
                if (results[0]) {
                  const cityBounds = results[0].geometry.viewport;
                  const center = cityBounds.getCenter();
                  const staticImg: StaticGoogleMapImage = {
                    centerLat: center.lat(),
                    centerLng: center.lng(),
                    lat,
                    lng,
                    zoom: 10
                  };
                  resolve(staticImg);
                }
              } else {
                reject(`Error al obtener límites de la ciudad: ${status}`);
              }
            }
          );
        });*/
      }
    );
  }

  private async getCountryData(
    lat: number,
    lng: number
  ): Promise<StaticGoogleMapImage> {
    const countryName = 'Spain';

    try {
      const resp = await this.getCountryCenter(countryName)
        .pipe(first())
        .toPromise();
      const location = resp.results[0].geometry.location;
      let centerLat = location.lat;
      let centerLng = location.lng;
      let zoom = 5;

      let isCanaryIslands: boolean;
      try {
        isCanaryIslands = await this.isCanaryIslands(lat, lng);
      } catch (error) {
        isCanaryIslands = false;
      }

      if (isCanaryIslands) {
        centerLat = centerLat - 5;
        centerLng = centerLng - 3;
        zoom = 4;
      }

      return {
        lat,
        lng,
        centerLat,
        centerLng,
        zoom
      } as StaticGoogleMapImage;
    } catch (error) {}
  }

  private isCanaryIslands(lat: number, lng: number): Promise<boolean> {
    return new Promise(
      (
        resolve: (value: boolean) => void,
        reject: (reason?: unknown) => void
      ) => {
        const location = new google.maps.LatLng(lat, lng);
        const geocoder = new google.maps.Geocoder();

        // Realizar una consulta inversa de geocodificación
        geocoder.geocode(
          { location },
          function (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
          ) {
            if (status === google.maps.GeocoderStatus.OK) {
              // Examinar la respuesta para verificar si la ubicación
              // está dentro de las Islas Canarias
              for (const result of results) {
                for (const component of result.address_components) {
                  if (
                    component.long_name.includes('Canarias') ||
                    component.long_name.includes('Canary Islands')
                  ) {
                    resolve(true);
                    return;
                  }
                }
              }
              resolve(false);
            } else {
              console.error(
                'Error al realizar la geocodificación inversa:',
                status
              );
              reject();
            }
          }
        );
      }
    );
  }

  private getCountryCenter(countryName: string): Observable<GeocodingResponse> {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${countryName}&key=${environment.firebaseConfig.apiKey}`;

    return this.http.get<GeocodingResponse>(geocodingUrl, {
      context: noTokenRequest()
    });
  }
}
