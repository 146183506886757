import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  AssetDto,
  Candidature,
  CandidatureStatusEnum,
  ChangeTenantEmailObj,
  CustomModalButtonRole,
  DocumentDTO,
  GeneralModalResponse,
  MyCustomEvent,
  NotificationDto,
  NotificationTypeEnum,
  TenantCandidature,
  TypeDocumentEnum,
  User,
  UserAnalysisStatusEnum
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { CandidatureUtilsService } from '@core/services/candidature-utils/candidature-utils.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  AlertController,
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { EditTenantEmailModalComponent } from '@shared/components/edit-tenant-email-modal/edit-tenant-email-modal.component';
import { MoreCandidatureOptionsPopoverComponent } from '@shared/components/more-candidature-options-popover/more-candidature-options-popover.component';
import { RequestInsurancePolicyModalComponent } from '@shared/components/request-insurance-policy-modal/request-insurance-policy-modal.component';
import { RequestReportModalComponent } from '@shared/components/request-report-modal/request-report-modal.component';
import { first, Subject, takeUntil } from 'rxjs';

import { AppointmentFormValue } from '../../models/calendar.model';
import {
  MoreCandidatureOptionEnum,
  MoreCandidatureOptionsPopoverResponse
} from '../../models/more-candidature-options-popover.model';
import { presentToast } from '../../utils/toast.utils';
import { CreateNewAppointmentModalComponent } from '../create-new-appointment-modal/create-new-appointment-modal.component';

@Component({
  selector: 'el-buen-inquilino-candidature-card-item',
  templateUrl: './candidature-card-item.component.html'
})
export class CandidatureCardItemComponent implements OnInit, OnDestroy {
  @Input({ required: true }) candidature: Candidature;
  @Input({ required: true }) user: User;
  @Input({ required: true }) asset: AssetDto;

  userAnalysisStatusEnum = UserAnalysisStatusEnum;
  candidatureStatusEnum = CandidatureStatusEnum;
  typeDocumentEnum = TypeDocumentEnum;
  candidatureChangeValue = false;
  notify = true;
  savingAnalysis = false;
  today = new Date();

  savingAnalysis$ = new Subject<void>();
  getCandidatures$ = new Subject<void>();

  @Output() goBackToAssetsEvent = new EventEmitter();
  @Output() goBackToAssetsWithRefreshEvent = new EventEmitter();
  @Output() getCandidaturesEvent = new EventEmitter<AssetDto>();

  get tenantCandidatureList(): TenantCandidature[] {
    return this.candidature?.tenantCandidatureList || [];
  }

  get checkPendingToDownload(): boolean {
    return this.candidatureUtils.checkPendingToDownload(this.candidature);
  }

  get validationToShowDownloadAnalysis(): boolean {
    return this.candidatureUtils.validationToShowDownloadAnalysis(
      this.candidature
    );
  }

  get showSeeDocumentationBtn(): boolean {
    return this.candidatureUtils.showSeeDocumentationBtn(
      this.candidature,
      this.user
    );
  }

  get pendingToDownloadClass(): string {
    return this.candidatureUtils.pendingToDownloadClass(this.candidature);
  }

  get candidatureStatus(): string {
    switch (this.candidature.candidatureStatusEnum) {
      case this.candidatureStatusEnum.WITHOUT_CHECKING:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.without_checking'
        ) as string;
      case this.candidatureStatusEnum.PENDING:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.pending'
        ) as string;
      case this.candidatureStatusEnum.PRE_SELECT:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.preselect'
        ) as string;
      case this.candidatureStatusEnum.REJECT:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.reject'
        ) as string;
      case this.candidatureStatusEnum.SELECT:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.select'
        ) as string;
      case this.candidatureStatusEnum.EXPIRED:
        return this.translate.instant(
          'pages.profile.home-owner.assets.candidature.expired'
        ) as string;
      default:
        return '';
    }
  }

  get disableCandidatureStatusSelect(): boolean {
    return (
      this.candidature.candidatureStatusEnum === CandidatureStatusEnum.REJECT ||
      this.isInAnalysis
    );
  }

  get candidatureStatusSelectValue(): CandidatureStatusEnum {
    if (
      this.candidature.candidatureStatusEnum ===
      CandidatureStatusEnum.WITHOUT_CHECKING
    ) {
      return CandidatureStatusEnum.WITHOUT_CHECKING;
    }

    if (
      this.candidature.candidatureStatusEnum ===
        CandidatureStatusEnum.PENDING &&
      !this.candidatureUtils.checkCandidatureTenants(this.candidature)
    ) {
      return CandidatureStatusEnum.WITHOUT_CHECKING;
    }

    return this.candidature.candidatureStatusEnum;
  }

  get checkWithOutChecking(): boolean {
    return this.candidatureUtils.checkWithOutChecking(this.candidature);
  }

  get showRequestInsurancePolicyBtn(): boolean {
    return (
      this.validationToShowDownloadAnalysis &&
      this.candidature.score >= 80 &&
      !this.candidature.blockPolicy &&
      !this.candidature.policyNumber &&
      this.user.trialVersion &&
      !this.user.blockCreatePolicy
    );
  }

  get hiddenRelaunchRequestAnalysis(): boolean {
    return (
      !(this.tenantCandidatureList.length > 0) ||
      this.checkAllUserAreStudent ||
      this.checkBusinessTenantNotAlone ||
      this.isInAnalysis
    );
  }

  get showRelaunchRequestanalysis(): boolean {
    return this.candidatureUtils.showRelaunchRequestanalysis(
      this.candidature,
      this.user
    );
  }

  get hiddenRequestAnalysisBtn(): boolean {
    return this.candidatureUtils.hiddenRelaunchRequestAnalysis(
      this.candidature
    );
  }

  get checkTenantDoc(): boolean {
    return this.candidatureUtils.checkTenantDoc(this.candidature);
  }

  /**
   * Check use case when tenant is unemployed or student but need have a valid
   * guarantor to allow request analysis, now in masked guarantor feature we
   * need accept only valid Dni and payslips
   */
  get checkTenantHasGuarantor(): boolean {
    return (
      this.candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) =>
          tenant.hasDni &&
          (tenant.hasPayslip ||
            tenant.hasRent ||
            tenant.hasPensionerRecognition)
      ).length > 0
    );
  }

  /**
   * Check if tenant checked student toggle on his profile, also need have
   * a garantor to complete use case
   */
  get checkStudentTenant(): boolean {
    return (
      this.candidature.tenantCandidatureList.filter(
        (tenant: TenantCandidature) => tenant.user.student
      ).length > 0
    );
  }

  get showCandidatureStatusSelect(): boolean {
    return (
      this.candidature.candidatureStatusEnum !==
        this.candidatureStatusEnum.EXPIRED && !this.user?.blockCandidatureStatus
    );
  }

  get checkAllUserAreStudent(): boolean {
    return this.candidature.tenantCandidatureList.every(
      (tenant: TenantCandidature) => tenant.user.student === true
    );
  }

  get isInAnalysis(): boolean {
    return this.candidatureUtils.checkTenantsStatus(
      this.candidature,
      UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
      UserAnalysisStatusEnum.REPORTED_ANALYSIS
    );
  }

  get checkBusinessTenantNotAlone(): boolean {
    return (
      this.tenantCandidatureList?.length > 1 &&
      this.tenantCandidatureList.some(
        (tenant: TenantCandidature) => tenant.user.business
      )
    );
  }

  get checkRequestAnalysis(): boolean {
    if (this.user.bigTenant) {
      return (
        this.checkTenantDoc &&
        this.candidatureUtils.checkTenantsStatus(
          this.candidature,
          this.userAnalysisStatusEnum.WITHOUT_ANALYSIS
        ) &&
        this.checkCandidatureStatus()
      );
    } else {
      return (
        this.checkTenantDoc &&
        this.candidatureUtils.checkTenantsStatus(
          this.candidature,
          this.userAnalysisStatusEnum.WITHOUT_ANALYSIS
        ) &&
        this.checkCandidatureStatus()
      );
    }
  }

  get showPreviewReportBtn(): boolean {
    return (
      this.candidature?.score !== null && !this.user.blockShowAnalysisReport
    );
  }

  get showReportInProcessBtn(): boolean {
    return !this.showPreviewReportBtn && this.isInAnalysis;
  }

  constructor(
    private utilsService: UtilsService,
    private translate: TranslateService,
    private modalController: ModalController,
    private toastController: ToastController,
    private loadingService: LoadingService,
    private candidatureService: CandidaturesApiService,
    private alertController: AlertController,
    private userService: UsersApiService,
    private popoverController: PopoverController,
    private router: Router,
    private authenticationService: AuthService,
    private notificationsService: NotificationsApiService,
    private candidatureUtils: CandidatureUtilsService
  ) {}

  ngOnInit(): void {
    this.subscribeToGetCandidaturesObs();
    this.subscribeToSavingAnalysisObs();
  }

  triggerGetCandidatures(): void {
    this.getCandidaturesEvent.emit(this.asset);
  }

  checkTenantsStatus(): boolean {
    return this.candidatureUtils.checkTenantsStatus(
      this.candidature,
      UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
      UserAnalysisStatusEnum.REPORTED_ANALYSIS
    );
  }

  requestAnalysis(reLaunched?: boolean): void {
    this.candidatureUtils.requestAnalysis(
      this.candidature,
      this.user,
      this.asset,
      reLaunched
    );
  }

  navigateToTenantFromOwnerView(tenant: User, status: string): void {
    if (status === 'REJECT') {
      return;
    }
    this.candidatureUtils.navigateToTenantFromOwnerView(
      tenant,
      this.candidature
    );
  }

  presentInfoNoValidated(): void {
    this.candidatureUtils.presentInfoNoValidated();
  }

  presentInfoRating(): void {
    this.candidatureUtils.presentInfoRating();
  }

  seeLastPayslip(ev: string): void {
    this.candidatureUtils.seeLastPayslip(ev);
  }

  viewDocuments(documents: DocumentDTO[], exists: boolean): void {
    console.log(documents);
    this.candidatureUtils.viewDocuments(documents, exists);
  }

  showIncidences(tenant: TenantCandidature): void {
    this.candidatureUtils.showIncidences(tenant).then((value: boolean) => {
      if (value) {
        this.triggerGetCandidatures();
      }
    });
  }

  launchViewDocuments(tenant: TenantCandidature): void {
    this.candidatureUtils.launchViewDocuments(tenant);
  }

  showRequestDocumentsBtn(tenant: TenantCandidature): boolean {
    if (this.user.trialVersion) {
      return false;
    } else if (this.showPreviewReportBtn || this.showReportInProcessBtn) {
      return false;
    }

    if (
      this.checkTenantDocumentAreReady(tenant, this.candidature.score) ||
      !!this.candidature.score
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkTenantRejected(tenant: TenantCandidature): boolean {
    return this.candidatureUtils.checkTenantRejected(tenant);
  }

  candidatureRejectedAlert(): void {
    this.candidatureUtils.candidatureRejectedAlert();
  }

  showResendInvitation(tenant: TenantCandidature): boolean {
    return (
      new Date(this.candidature.expirationDate).getTime() >
        new Date(this.today).getTime() &&
      tenant.userCandidatureStatusDtoEnum !== 'ACCEPT'
    );
  }

  reSendEmailToTenant(userTenant: User): void {
    const reSendEmailDto = { asset: this.asset, userTenant: userTenant };
    this.candidatureService
      .reSendEmailToTenant(reSendEmailDto)
      .pipe(first())
      .subscribe({
        next: () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.resend_email.re-invite'
            ) as string,
            'success'
          );
          this.triggerGetCandidatures();
        },
        error: () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.resend_email.can_not_send'
            ) as string,
            'danger'
          );
        }
      });
  }

  relaunchRequestAnalysis(): void {
    this.candidatureUtils.requestAnalysis(
      this.candidature,
      this.user,
      this.asset,
      true
    );
  }

  previewReport(): void {
    this.candidatureUtils.previewReport(this.candidature);
  }

  async changeCandidatureStatus(ev: Event): Promise<void> {
    this.candidature.candidatureStatusEnum = (ev as CustomEvent<MyCustomEvent>)
      .detail.value as CandidatureStatusEnum;

    if (this.candidatureChangeValue) {
      // Aquí entra cuando se ha seleccionado una candidatura,
      // pero se vuelve a estado preseleccionado por pedir informe
      this.candidatureChangeValue = false;
      return;
    }

    if (
      this.candidature.candidatureStatusEnum ===
      this.candidatureStatusEnum.SELECT
    ) {
      // Si se elige seleccionado, se mira si tiene análisis
      if (!this.isInAnalysis && !this.candidature?.score) {
        // Si no tiene análisis, sacamos modal para ofrecerlo
        const modal = await this.modalController.create({
          component: RequestReportModalComponent,
          backdropDismiss: false,
          cssClass: 'request-report-modal'
        });
        await modal.present();

        const { data } = await modal.onWillDismiss<GeneralModalResponse>();

        // Si pide el informe
        if (data.action) {
          // Comprobamos que tienen todos los documentos necesarios
          if (!this.checkRequestAnalysis) {
            presentToast(
              this.toastController,
              this.translate.instant(
                'components.request_report_modal.doc_msg'
              ) as string,
              'danger',
              5000
            );
          } else {
            // Si tiene toda la documentación, pedimos el informe
            this.requestAnalysis();
          }
          this.candidature.candidatureStatusEnum =
            this.candidatureStatusEnum.PRE_SELECT;
          this.candidatureChangeValue = true;
          return;
        }
      }
    }

    this.loadingService.presentSecondLoader(null);

    if (this.user.trialVersion) {
      this.notify = false;
    }

    this.candidatureService
      .updateCandidatureStatus(
        this.candidature,
        this.candidature.candidatureStatusEnum,
        this.notify
      )
      .subscribe({
        next: () => {
          if (
            this.candidature.candidatureStatusEnum ===
            this.candidatureStatusEnum.REJECT
          ) {
            presentToast(
              this.toastController,
              this.translate.instant(
                'pages.profile.home-owner.assets.candidature.has_ruled_out_candidature'
              ) as string,
              'success'
            );
          } else if (
            this.candidature.candidatureStatusEnum ===
            this.candidatureStatusEnum.PRE_SELECT
          ) {
            presentToast(
              this.toastController,
              this.translate.instant(
                'pages.profile.home-owner.assets.candidature.preselect_candidature'
              ) as string,
              'success'
            );
          } else if (
            this.candidature.candidatureStatusEnum ===
            this.candidatureStatusEnum.SELECT
          ) {
            presentToast(
              this.toastController,
              this.translate.instant(
                'pages.profile.home-owner.assets.candidature.candidature_has_been_selected'
              ) as string,
              'success'
            );
            this.candidatureService
              .getCandidaturesById(this.candidature.id)
              .subscribe((updatedCandidature: Candidature) => {
                this.candidature = updatedCandidature;
              });
          }
          this.triggerGetCandidatures();
          this.loadingService.dismissSecondLoader();
        },
        error: () => {
          const status = this.candidatureStatus;
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.transition_not_allowed',
              { status: status }
            ) as string,
            'danger'
          );
          this.loadingService.dismissSecondLoader();
        }
      });
  }

  async showCandidatureMoreOptionsPopover(ev: Event): Promise<void> {
    if (
      this.candidature.candidatureStatusEnum === CandidatureStatusEnum.EXPIRED
    ) {
      return;
    }
    let building: { id: string } = null;
    if (this.asset.buildingId) {
      building = { id: this.asset.buildingId };
    }

    const popover = await this.popoverController.create({
      component: MoreCandidatureOptionsPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        candidature: this.candidature,
        disableEdition: this.hideEditCandidature(),
        building,
        user: this.user,
        disabledReCalculate: this.hideReCalculateScore(),
        disableReinforce: this.candidature.score === null,
        disableRelocate: this.isInAnalysis,
        userHasCalendar: this.user.hasCalendar
      } as Partial<MoreCandidatureOptionsPopoverComponent>
    });
    await popover.present();

    const { data }: OverlayEventDetail<MoreCandidatureOptionsPopoverResponse> =
      await popover.onWillDismiss();

    if (data) {
      if (data?.option) {
        switch (data?.option) {
          case MoreCandidatureOptionEnum.EDIT_CANDIDATURE: {
            this.getCandidaturesEvent.emit(this.asset);
            break;
          }
          case MoreCandidatureOptionEnum.MOVE_CANDIDATURE: {
            const id = data?.newAsset?.id;

            const urlArray = this.router.url.split('/');
            urlArray.shift();
            urlArray.pop();
            urlArray.pop();

            this.router.navigate([...urlArray, id, 'candidatures'], {
              state: { moveCandidature: true }
            });
            break;
          }
          case MoreCandidatureOptionEnum.RECALCULATE_SCORE: {
            this.getCandidaturesEvent.emit(this.asset);
            break;
          }
          case MoreCandidatureOptionEnum.NOTE_CANDIDATURE: {
            this.getCandidaturesEvent.emit(this.asset);
            break;
          }
          case MoreCandidatureOptionEnum.APPOINTMENT: {
            this.addAppointment();
            break;
          }
        }
      }
    }
  }

  async presentRequestDocumentation(tenantId: string): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.request_documentation.header'
      ) as string,
      message: this.translate.instant(
        'pages.profile.home-owner.assets.candidature.request_documentation.message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.request_documentation.cancel'
          ) as string,
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant(
            'pages.profile.home-owner.assets.candidature.request_documentation.send'
          ) as string,
          cssClass: 'danger',
          handler: (): void => {
            this.requestDocumentation(tenantId);
          }
        }
      ]
    });
    await alert.present();
  }

  async editEmailModal(tenantEmail: string): Promise<void> {
    const modal = await this.modalController.create({
      component: EditTenantEmailModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        tenantEmail: tenantEmail,
        candidature: this.candidature
      } as Partial<EditTenantEmailModalComponent>
    });
    modal.onDidDismiss().then(
      (
        close: OverlayEventDetail<{
          dismissed: boolean;
          changeTenantEmailObj: ChangeTenantEmailObj;
        }>
      ) => {
        if (close && close.data && close.data.changeTenantEmailObj) {
          this.changeTenantEmailForNewTenant(close.data.changeTenantEmailObj);
        }
      }
    );
    return await modal.present();
  }

  async requestInsurancePolicy(closed?: boolean): Promise<void> {
    const closedOperation: boolean = closed || false;
    const modal = await this.modalController.create({
      component: RequestInsurancePolicyModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature: this.candidature,
        closedOperation
      },
      id: 'request-insurance-policy'
    });
    modal.onDidDismiss().then(() => {
      this.goBackToAssetsWithRefreshEvent.emit();
    });
    return await modal.present();
  }

  ngOnDestroy(): void {
    this.getCandidatures$.next();
    this.getCandidatures$.complete();
    this.savingAnalysis$.next();
    this.savingAnalysis$.complete();
  }

  private createNotification(
    candidatureId: string,
    asset: AssetDto,
    email: string
  ): void {
    const notification = new NotificationDto();
    notification.type = NotificationTypeEnum.CANDIDATURE;
    notification.candidatureId = candidatureId;
    notification.address = `${asset.street} ${asset.number}, ${asset.town} (${asset.province})`;
    notification.senderEmail = this.authenticationService.user.email;
    notification.senderFirstname = this.authenticationService.user.firstname;
    notification.senderSurname1 = this.authenticationService.user.surname1;
    notification.senderSurname2 = this.authenticationService.user.surname2;
    notification.recipientEmail = email;
    if (!!asset.vppModality) {
      notification.vpp = true;
    }
    this.notificationsService.newNotification(notification).subscribe();
  }

  private checkTenantDocumentAreReady(
    tenant: TenantCandidature,
    score: number
  ): boolean {
    const minDoc = this.candidatureUtils.prepareMinDocForTenant(tenant, score);
    return this.utilsService.checkMinimumDocForTenantRol(minDoc, tenant.user);
  }

  private async onNewAppointmentDismiss(
    data: AppointmentFormValue,
    candidatureId: string
  ): Promise<void> {
    data.candidatureId = candidatureId;
    await this.loadingService.presentLoading(
      this.translate.instant(
        'components.owner_calendar.create_loading'
      ) as string
    );
    this.userService
      .createAppointment(data)
      .pipe(first())
      .subscribe({
        next: async () => {
          presentToast(this.toastController, 'Cita creada correctamente');
          await this.loadingService.dismissLoading();
        },
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  private checkCandidatureStatus(): boolean {
    if (
      this.candidature.candidatureStatusEnum ===
      this.candidatureStatusEnum.WITHOUT_CHECKING
    ) {
      return false;
    } else if (
      this.candidature.candidatureStatusEnum ===
      this.candidatureStatusEnum.REJECT
    ) {
      return false;
    } else {
      return true;
    }
  }

  private hideEditCandidature(): boolean {
    return (
      !(this.candidature.tenantCandidatureList.length > 0) ||
      this.isInAnalysis ||
      // this.user.trialVersion ||
      this.candidature.candidatureStatusEnum ===
        CandidatureStatusEnum.EXPIRED ||
      !!this.candidature?.score
    );
  }

  private hideReCalculateScore(): boolean {
    return (
      // !this.checkRequestAnalysis(candidature) ||
      this.candidature.tenantCandidatureList.every(
        (tenant: TenantCandidature) =>
          tenant.status === UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS
      ) || !this.checkCandidatureStatus()
    );
  }

  private async addAppointment(): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateNewAppointmentModalComponent,
      backdropDismiss: true,
      componentProps: {
        user: this.user,
        showLinkTemplate: false
      } as Partial<CreateNewAppointmentModalComponent>
    });
    await modal.present();

    const { data, role }: OverlayEventDetail<AppointmentFormValue> =
      await modal.onWillDismiss();

    if (role === (CustomModalButtonRole.CREATE as string)) {
      this.onNewAppointmentDismiss(data, this.candidature.id);
    }
  }

  private requestDocumentation(tenantId: string): void {
    this.userService
      .requestDocumentation(this.candidature.id, tenantId)
      .pipe(first())
      .subscribe({
        next: () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.requestDocumentOk'
            ) as string,
            'success'
          );
          this.triggerGetCandidatures();
        },
        error: () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.requestDocumentError'
            ) as string,
            'danger'
          );
        }
      });
  }

  private async changeTenantEmailForNewTenant(
    changeTenantEmailObj: ChangeTenantEmailObj
  ): Promise<void> {
    await this.loadingService.presentSecondLoader(null, true);
    this.candidatureService
      .changeTenantEmailForNewTenantsAndSendEmail(changeTenantEmailObj)
      .pipe(first())
      .subscribe({
        next: () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.change_email.change'
            ) as string,
            'success'
          );
          // Borramos la vieja notificación al email original y
          // en el callback creamos una nueva notificación al email correcto
          this.notificationsService
            .deleteNotification(
              NotificationTypeEnum.CANDIDATURE,
              changeTenantEmailObj.candidatureId,
              changeTenantEmailObj.email
            )
            .pipe(first())
            .subscribe(() => {
              this.createNotification(
                changeTenantEmailObj.candidatureId,
                this.asset,
                changeTenantEmailObj.newTenantEmail
              );
            });
          this.triggerGetCandidatures();
          this.loadingService.dismissSecondLoader();
        },
        error: () => {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.profile.home-owner.assets.candidature.change_email.can_not_change'
            ) as string,
            'danger'
          );
          this.loadingService.dismissSecondLoader();
        }
      });
  }

  private subscribeToSavingAnalysisObs(): void {
    this.candidatureUtils
      .getSavingAnalysisObs()
      .pipe(takeUntil(this.savingAnalysis$))
      .subscribe((value: boolean) => (this.savingAnalysis = value));
  }

  private subscribeToGetCandidaturesObs(): void {
    this.candidatureUtils
      .getGetCandidaturesObs()
      .pipe(takeUntil(this.getCandidatures$))
      .subscribe(() => this.triggerGetCandidatures());
  }
}
