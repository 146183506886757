import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'el-buen-inquilino-no-validated-user-info',
  templateUrl: './no-validated-user-info.component.html'
})
export class NoValidatedUserInfoComponent {
  @Input() informative = false;
  notShowAgain = false;

  constructor(private modalController: ModalController) {}

  dismiss(): void {
    this.modalController.dismiss({
      notShowAgain: this.notShowAgain
    });
  }
}
