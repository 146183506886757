export enum DisplayedColumns {
  SELECT = 'select',
  ADDRESS = 'address',
  LABEL = 'label',
  API = 'api',
  ACTIONS = 'actions',
  INFO = 'info',
  RENTAL_PRICE = 'rentalPrice',
  CREATION_DATE = 'creationDate'
}
