import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  Candidature,
  PolicyIssue,
  Sinister,
  SinisterDocumentType,
  SinisterThread,
  User
} from '@core/models';
import { isBefore, isSameDay } from 'date-fns';
import { lastValueFrom } from 'rxjs';

export async function getUsers(
  userService: UsersApiService,
  user: User,
  sinister: Sinister,
  policy?: PolicyIssue,
  modification: boolean = false
): Promise<User[]> {
  const usersId: string[] = [];
  let users: User[] = [];

  let thread: SinisterThread[] = [];
  if (!!sinister && !modification) {
    thread = sinister.thread;
  } else if (!!sinister && modification) {
    thread = sinister.chat;
  } else if (!!policy && !modification) {
    thread = policy.thread;
  } else if (!!policy && modification) {
    thread = policy.chat;
  }

  thread.forEach((thread: SinisterThread) => {
    if (!usersId.includes(thread.userId)) {
      usersId.push(thread.userId);
    }
  });

  if (usersId.includes(user.id)) {
    const index = usersId.findIndex((id: string) => id === user.id);
    usersId.splice(index, 1);
  }

  const calls: Promise<User>[] = [];
  usersId.forEach((id: string) =>
    calls.push(lastValueFrom(userService.getUserSubscribe(id)))
  );

  try {
    users = await Promise.all(calls);
    users.push(user);
    return users;
  } catch (error) {
    return [];
  }
}

export function getSinisterBlobName(
  policyNumber: string,
  type: SinisterDocumentType
): string {
  switch (type) {
    case SinisterDocumentType.RENTAL_CONTRACT:
      return `${policyNumber}_CONTRATO_ALQUILER`;
    case SinisterDocumentType.BUROFAX_SENT:
      return `${policyNumber}_JUSTIFICANTE_ENVIO_BUROFAX`;
    case SinisterDocumentType.BUROFAX_RECEPTION:
      return `${policyNumber}_JUSTIFICANTE_RECEPCION_FAX`;
    case SinisterDocumentType.COMPLAINT_DOC:
      return `${policyNumber}_PRESENTACION_DEMANDA`;
    case SinisterDocumentType.CONTRACT_TERMINATION:
      return `${policyNumber}_RESOLUCION_CONTRATO`;
    case SinisterDocumentType.SOCIAL_SERVICES_REQUEST:
      return `${policyNumber}_SOLICITUD_SERVICIOS_SOCIALES`;
    case SinisterDocumentType.PROCEDURE_RESOLUTION:
      return `${policyNumber}_RESOLUCIÓN_PROCEDIMIENTO`;
    case SinisterDocumentType.EXECUTION_CLAIM:
      return `${policyNumber}_DEMANDA DE EJECUCIÓN`;
    case SinisterDocumentType.OTHER_DOCUMENT:
      return `${policyNumber}_OTRO_DOCUMENTO`;
  }
}

export function base64ToBlob(
  base64: string,
  contentType: string = '',
  sliceSize: number = 512
): Blob {
  // Decodificar la cadena base64
  const byteCharacters: string = atob(base64);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice: string = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers: number[] = new Array(slice.length) as number[];
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray: Uint8Array = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // Crear un objeto Blob a partir del array de bytes
  const blob: Blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function getSinisterDocLabel(type: SinisterDocumentType): string {
  switch (type) {
    case SinisterDocumentType.RENTAL_CONTRACT:
      return 'Contrato de alquiler';
    case SinisterDocumentType.BUROFAX_SENT:
      return 'Justificante de envío de burofax';
    case SinisterDocumentType.DEBT_BREAKDOWN:
      return 'Desglose de deuda';
    case SinisterDocumentType.BUROFAX_RECEPTION:
      return 'Justificante de recepción del burofax';
    case SinisterDocumentType.COMPLAINT_DOC:
      return 'Presentación de la demanda';
    case SinisterDocumentType.CONTRACT_TERMINATION:
      return 'Resolución del contrato';
    case SinisterDocumentType.SOCIAL_SERVICES_REQUEST:
      return 'Solicitud de servicios sociales';
    case SinisterDocumentType.PROCEDURE_RESOLUTION:
      return 'Resolución del procedimiento';
    case SinisterDocumentType.EXECUTION_CLAIM:
      return 'Demanda de ejecución';
    case SinisterDocumentType.OTHER_DOCUMENT:
      return 'Otro documento';
  }
}

export function isCandidatureClosed(candidature: Candidature): boolean {
  if (
    isSameDay(new Date(), new Date(candidature.expirationDate)) ||
    isBefore(new Date(candidature.expirationDate), new Date())
  ) {
    return true;
  } else if (!!candidature.selectDate || !!candidature.rejectDate) {
    return true;
  } else {
    return false;
  }
}
