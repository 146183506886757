import { Component, Input, OnInit } from '@angular/core';
import { TenantCandidature } from '@core/models';

@Component({
  selector: 'el-buen-inquilino-name-avatar-generator',
  templateUrl: './name-avatar-generator.component.html'
})
export class NameAvatarGeneratorComponent implements OnInit {
  @Input() tenant: TenantCandidature;
  @Input() index: number;
  initials: string;
  colors: string[] = [
    '#005d83',
    '#D73828',
    '#609c40',
    '#901F46',
    '#FBAD18',
    '#F57E25',
    '#F55555',
    '#28C76F'
  ];

  ngOnInit(): void {
    const { firstname, firstName, surname1 } = this.tenant.user;
    const name = firstname || firstName;
    if (name != null && name.length > 1) {
      this.initials = `${name.charAt(0).toUpperCase()}${surname1
        .charAt(0)
        .toUpperCase()}`;
    } else {
      this.initials = 'N/A';
    }
  }
}
