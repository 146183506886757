import { Pipe, PipeTransform } from '@angular/core';
import { TenantCandidature } from '@core/models';

@Pipe({
  name: 'candidatureTenantRole'
})
export class CandidatureTenantRolePipe implements PipeTransform {
  transform(
    tenant: TenantCandidature,
    option: 'class' | 'text' = 'text'
  ): string {
    if (tenant.user.guarantor) {
      if (option === 'class') {
        return 'guarantor';
      } else {
        return 'components.change_tenant_role.guarantor';
      }
    } else if (tenant.user.student) {
      if (option === 'class') {
        return 'student';
      } else {
        return 'components.change_tenant_role.student';
      }
    } else if (tenant.user.freelance) {
      if (option === 'class') {
        return 'freelance';
      } else {
        return 'components.change_tenant_role.freelance';
      }
    } else if (tenant.user.retired) {
      if (option === 'class') {
        return 'retired';
      } else {
        return 'components.change_tenant_role.retired';
      }
    } else if (tenant.user.business) {
      if (option === 'class') {
        return 'business';
      } else {
        return 'components.change_tenant_role.business';
      }
    } else {
      if (option === 'class') {
        return 'worker';
      } else {
        return 'components.change_tenant_role.worker';
      }
    }
  }
}
