/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MessageBean } from '@shared/utils/message-bean.utils';

import {
  CustomerCareRequestCandidature,
  TypeUserEnum,
  User,
  UserRol
} from '../../models';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { StoreService } from '../store/store.service';

const LOGIN_PAGE = '/login';
export const MAINTENANCE_PAGE = '/maintenance';
const HOME_TENANT = '/tenant';
const HOME_HOMEOWNER_DEFAULT = '/home-owner';
const HOME_HOMEOWNER_PORTFOLIO = '/portfolio';
const HOME_GUARANTOR = '/guarantor';
const TENANTS_HOMEOWNER = '/tenants';
const LANDING_ANONYMOUS = '/landing-anonymous';
const LANDING_TENANT = '/landing-tenant';
const LANDING_HOME_OWNER = '/landing-homeowner';
const LANDING = '/landing';
const HOME_INCOFISA = '/request-analysis-list';
const SUBSCRIPTION = '/home-subscription';
const ENTERPRISE_LOGIN = '/enterprise-login';
const GUARANTOR_HOME = '/guarantor';
const ANALYTICS = '/analytics-login';
const ANALYTICS_DASHBOARD = 'analytics-dashboard';
const USER_ANALYTICS = 'analytics-user-detail';
const INSURANCE_HOME = '/insurance-fees-login/';
const LOGIN_CERTIFICATIONS_PAGE = '/certifications-login';
const DOCUMENT_CERTIFICATION_PAGE = '/certifications';
const SUCCESS_PAGE = '/success-page';
const LOGIN_BACKOFFICE = '/backoffice/login';
const LANDING_BACKOFFICE = '/backoffice/landing';

@Injectable()
export class NavigationService {
  constructor(
    private navController: NavController,
    private storeService: StoreService,
    private snackBarService: SnackBarService,
    private router: Router,
    private auth: Auth
  ) {}

  pathHomeTenant(): string {
    return HOME_TENANT;
  }

  pathLogin(): string {
    return LOGIN_PAGE;
  }

  pathLandingAnonymous(): string {
    return LANDING_ANONYMOUS;
  }

  pathEnterpriseLogin(): string {
    return ENTERPRISE_LOGIN;
  }

  goLandingTenant(): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(LANDING_TENANT);
  }

  goLandingHomeOwner(): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(LANDING_HOME_OWNER);
  }

  goIncofisaHome(): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(HOME_INCOFISA);
  }

  logout(message?: string, source?: string): void {
    this.snackBarService.hide();
    this.auth.signOut();
    this.storeService.removeCredentials();
    if (message) {
      this.snackBarService.show(MessageBean.buildMessage(message));
    }

    if (source?.includes('login/')) {
      this.go(source);
    } else {
      switch (source) {
        case 'certifications':
          this.go(LOGIN_CERTIFICATIONS_PAGE);
          break;
        case 'backoffice': {
          this.go(LOGIN_BACKOFFICE);
          break;
        }
        default:
          this.navController.navigateRoot(LANDING);
      }
    }
  }

  go(path: string): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(path);
  }

  goWithoutHideSnackBar(path: string): void {
    this.navController.navigateRoot(path);
  }

  goLanding(): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(LANDING);
  }

  goLoginByTypeUser(typeUser: TypeUserEnum, idContact?: string): void {
    this.snackBarService.hide();
    if (idContact) {
      this.router.navigate([LOGIN_PAGE], {
        queryParams: { typeUser, idContact }
      });
    } else {
      this.router.navigate([LOGIN_PAGE], { queryParams: { typeUser } });
    }
  }

  goRegisterByTypeUser(typeUser: TypeUserEnum): void {
    this.snackBarService.hide();
    this.router.navigate([LOGIN_PAGE], {
      queryParams: { typeUser, signin: true }
    });
  }

  goLoginByEmail(email: string): void {
    this.snackBarService.hide();
    this.router.navigate([LOGIN_PAGE], { queryParams: { email } });
  }

  goHome(user: User, idContact?: string): void {
    this.snackBarService.hide();
    if (idContact) {
      this.router.navigate([this.getHomePage(user, user.userType)], {
        queryParams: { idContact }
      });
    } else {
      this.navController.navigateRoot(this.getHomePage(user, user.userType));
    }
  }

  goCertificationHome(candidatureId?: string): void {
    this.snackBarService.hide();
    if (candidatureId) {
      this.goToLoginCertificationsHome(candidatureId);
    } else {
      this.navController.navigateRoot(LOGIN_CERTIFICATIONS_PAGE);
    }
  }

  goToLoginCertificationsHome(candidatureId?: string): void {
    this.router.navigate([LOGIN_CERTIFICATIONS_PAGE], {
      queryParams: { candidatureId }
    });
  }

  goToDocumentsCertification(candidatureId: string): void {
    if (candidatureId) {
      this.router.navigate([DOCUMENT_CERTIFICATION_PAGE + '/' + candidatureId]);
    } else {
      this.router.navigate([LOGIN_CERTIFICATIONS_PAGE]);
    }
  }

  goToSubscriptionPage(homeOwnerId: string): void {
    this.router.navigate([
      '/' + HOME_HOMEOWNER_DEFAULT + '/' + homeOwnerId + '/' + SUBSCRIPTION
    ]);
  }

  isLoginPage(loginPage: string): boolean {
    return loginPage.startsWith(LOGIN_PAGE);
  }

  goLoginPage(): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(LOGIN_PAGE);
  }

  goMaintenancePage(): void {
    this.navController.navigateRoot(MAINTENANCE_PAGE);
  }

  goTenantsOfHomeOwner(homeOwnerId: string): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(
      HOME_HOMEOWNER_DEFAULT + '/' + homeOwnerId + '/' + TENANTS_HOMEOWNER
    );
  }

  goTenantsHome(tenantId: string): void {
    this.snackBarService.hide();
    this.navController.navigateRoot(
      '/tenant/' + tenantId + '/home' 
    );
  }

  goToRequestAnalysis(requestAnalysisId: string): void {
    this.router.navigate([
      'backoffice/analysis/request-analysis/' + requestAnalysisId
    ]);
  }

  goToLoginBackoffice(): void {
    if (localStorage.getItem('dateFrom') && localStorage.getItem('dateTo')) {
      localStorage.removeItem('dateFrom');
      localStorage.removeItem('dateTo');
    }

    this.router.navigate([LOGIN_BACKOFFICE]);
  }

  goToLandingBackoffice(): void {
    this.router.navigate([LANDING_BACKOFFICE]);
  }

  goToAnalyticsHome(userAnalitycsId: string): void {
    this.router.navigate([ANALYTICS], { queryParams: { userAnalitycsId } });
  }

  goToVisualizeAnalytics(): void {
    this.router.navigate(['backoffice', 'analytics', ANALYTICS_DASHBOARD]);
  }

  goToUserAnalytics(id: string): void {
    this.router.navigate(['backoffice', 'analytics', USER_ANALYTICS, id]);
  }

  goToRequestAnalysisList(returnFlag: boolean = false): void {
    if (returnFlag) {
      this.router.navigate(['backoffice/analysis/request-analysis-list'], {
        state: { return: true }
      });
    } else {
      this.router.navigate(['backoffice/analysis/request-analysis-list']);
    }
  }

  goToInsuranceFeeHome(userInsuranceFeeDetailId: string): void {
    this.router.navigate([INSURANCE_HOME], {
      queryParams: { userInsuranceFeeDetailId }
    });
  }

  goToEnterpriseLogin(): void {
    this.router.navigate([ENTERPRISE_LOGIN]);
  }

  goToGuarantorHome(userId: string): void {
    this.router.navigateByUrl(GUARANTOR_HOME + '/' + userId);
  }

  goToSuccessPage(message: string): void {
    this.router.navigate([SUCCESS_PAGE], { queryParams: { message } });
  }

  //TODO: Ver si usaremos id como parametro de entrada o no
  goToCustomerCareRequestAnalysisList(): void {
    this.router.navigate([
      'backoffice/customer-care/customer-care-request-analysis-list'
    ]);
  }

  goToCustomerCareRequestAnalysis(
    request: CustomerCareRequestCandidature,
    from: string,
    to: string
  ): void {
    this.router.navigate(
      ['backoffice/customer-care/customer-care-request-analysis'],
      {
        queryParams: {
          candidatureId: request.candidatureId,
          assetId: request.candidature.asset.id,
          from,
          to
        },
        state: {
          request
        }
      }
    );
  }

  goToCustomerCareRequestAnalysisMinimal(
    candidatureId: string,
    from: string,
    to: string
  ): void {
    this.router.navigate(
      ['backoffice/customer-care/customer-care-request-analysis'],
      {
        queryParams: {
          candidatureId,
          from,
          to
        }
      }
    );
  }
  // END CUSTOMER CARE

  goToLastUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  private getHomePage(user: User, typeUser: TypeUserEnum): string {
    this.snackBarService.hide();
    if (user?.guarantor && typeUser === TypeUserEnum.TENANT) {
      return HOME_GUARANTOR + (user ? '/' + user.id : '');
    }
    // Redirect to the portfolio list for PORTFOLIO AND API_ADMIN to avoid to see the home page.
    if (user?.portfolioId || user?.apiRol === UserRol.API_ADMIN) {
      return (
        HOME_HOMEOWNER_DEFAULT +
        (user ? '/' + user.id : '') +
        HOME_HOMEOWNER_PORTFOLIO
      );
    }
    return (
      (typeUser === TypeUserEnum.TENANT
        ? HOME_TENANT
        : HOME_HOMEOWNER_DEFAULT) + (user ? '/' + user.id : '')
    );
  }
}
