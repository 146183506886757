import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController } from '@ionic/angular';
import { fromEvent, Subject, takeUntil } from 'rxjs';

import { SinisterDocumentExtension } from '../../models/view-sinister-document-modal.model';

@Component({
  selector: 'el-buen-inquilino-view-sinister-document-modal',
  templateUrl: './view-sinister-document-modal.component.html'
})
export class ViewSinisterDocumentModalComponent
  implements AfterViewInit, OnDestroy
{
  @Input() url: string;
  @Input() extension: string;
  @Input() name: string;

  docExtension = SinisterDocumentExtension;
  checkInterval: string | number | NodeJS.Timeout;
  loadError = false;
  errorNum = 0;

  private loadTimeout: NodeJS.Timeout | string | number | undefined;
  private destroy$ = new Subject<void>();

  get documentType(): SinisterDocumentExtension {
    const validExtensions = ['jpg', 'jpeg', 'png'];
    if (validExtensions.some(ext => this.extension.includes(ext))) {
      return SinisterDocumentExtension.IMAGE;
    } else if (this.extension.includes('pdf')) {
      return SinisterDocumentExtension.PDF;
    }
  }

  constructor(
    private modalController: ModalController,
    private loadingService: LoadingService
  ) {}

  ngAfterViewInit(): void {
    this.monitorIframeLoad();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
  }

  async monitorIframeLoad(): Promise<void> {
    if (this.extension.includes('pdf')) {
      await this.loadingService.presentSecondLoader('Cargando documento...');
      const iframe = document.getElementById(
        'googleViewer'
      ) as HTMLIFrameElement;

      fromEvent(iframe, 'load')
        .pipe(takeUntil(this.destroy$))
        .subscribe(async () => {
          clearTimeout(this.loadTimeout); // Si se carga correctamente, cancela el timeout
          this.loadError = false;
          await this.loadingService.dismissSecondLoader();
        });

      // Si el iframe no se carga en 10 segundos, muestra un error
      this.loadTimeout = setTimeout(async () => {
        this.loadError = true;
        await this.loadingService.dismissSecondLoader();
      }, 5000); // Tiempo en milisegundos (10 segundos)
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }
}
