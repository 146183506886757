/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { ContactsApiService } from '@core/api-services/contacts-api/contacts-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  ContactAssetDto,
  CreateContactUserDto,
  NotificationDto,
  NotificationTypeEnum,
  ShareProfileDto,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SnackBarService } from '@core/services/snack-bar/snack-bar.service';
import { environment } from '@environments/environment';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MessageBean } from '../../utils/message-bean.utils';

@Component({
  selector: 'el-buen-inquilino-send-request-homeowner',
  templateUrl: './send-request-homeowner.component.html'
})
export class SendRequestHomeownerComponent implements OnInit {
  link: string;
  email: string;
  createContactDto = new CreateContactUserDto();
  users$: Observable<User[]>;
  userNotFound: boolean;
  showInput = true;
  user: User;
  invitationSended = false;
  disableSendBtn = false;
  saving = false;

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthService,
    private navigationService: NavigationService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private contactService: ContactsApiService,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    private usersService: UsersApiService,
    private notificationsService: NotificationsApiService
  ) {}

  ngOnInit(): void {
    this.createContactDto.assetData = new ContactAssetDto();
  }

  generateBaseLink(): string {
    return (
      environment.domain +
      this.navigationService.pathLandingAnonymous() +
      '/' +
      this.authenticationService.user.id
    );
  }

  generateLink(data: string): void {
    this.link = this.generateBaseLink() + '?id=' + data;
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  clickGenerateLink(form: NgForm): void {
    if (form.invalid) {
      this.snackBarService.showAutoHide(
        MessageBean.buildMessages(form, this.translateService),
        2000
      );
    } else {
      this.generateContact().subscribe((data) => {
        this.generateLink(this.getIdContactFromResponse(data));
        this.email = '';
      });
    }
  }

  getIdContactFromResponse(response: HttpResponse<string>): string {
    const locationSnippets = response.headers.get('Location').split('/');
    return locationSnippets[locationSnippets.length - 1];
  }

  clickSendEmail(): void {
    if (this.email === undefined || this.email.trim().length === 0) {
      this.presentToast(
        'application.validations.errors.email2',
        'danger',
        true
      );
    } else {
      this.createContactDto.emailTarget = this.email;
      this.createContactDto.linkBase = environment.domain;
      this.disableSendBtn = true;
      this.saving = true;
      this.shareProfile().subscribe(
        () => {
          this.saving = false;
          this.disableSendBtn = false;
          this.presentToast(
            'pages.share-profile.email-sended',
            'success',
            true
          );
          this.email = '';
          this.createContactDto.clean();
          this.dismiss();
        },
        (_error) => {
          this.saving = false;
          this.disableSendBtn = false;
        }
      );
    }
  }

  async presentToast(
    message: string,
    type: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    translate: boolean
  ): Promise<void> {
    const messageKey = message;
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      position: 'top',
      color: type,
      duration: 2000
    });
    toast.present();
  }

  generateContact(): Observable<HttpResponse<string>> {
    const sharedType = 'OWNER';
    return this.contactService.registerContact(
      this.createContactDto,
      sharedType
    );
  }

  shareProfile(): Observable<ShareProfileDto> {
    const obj = new ShareProfileDto();
    obj.email = this.email;
    obj.asset = this.createContactDto.assetData;
    return this.candidatureService.share(obj);
  }

  searchUser(): void {
    this.userNotFound = false;
    this.users$ = this.usersService.getUserByEmail(this.email).pipe(
      tap(
        (user) => {
          this.showInput = false;
          this.email = '';
          this.user = user[0];
        },
        (error) => {
          if (error.error.code === '102') {
            this.userNotFound = true;
            this.showInput = false;
            this.addCotenant(true);
          }
        }
      )
    );
  }

  addCotenant(newUser?: boolean): void {
    const createNotification: NotificationDto = new NotificationDto();
    createNotification.recipientEmail = newUser ? this.email : this.user.email;
    createNotification.type = NotificationTypeEnum.COTENANT;
    createNotification.senderEmail = this.authenticationService.user.email;
    createNotification.senderFirstname =
      this.authenticationService.user.firstname;
    createNotification.senderSurname1 =
      this.authenticationService.user.surname1;
    createNotification.senderSurname2 =
      this.authenticationService.user.surname2;
    this.notificationsService.newNotification(createNotification).subscribe(
      () => {
        this.invitationSended = true;
        this.presentToast('pages.share-profile.email-sended', 'success', true);
        this.dismiss();
      },
      (_error) => {
        this.presentToast('cotenant.profile.duplicateRequest', 'danger', true);
      }
    );
  }
}
