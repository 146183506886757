/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { ApiDto, ApiMemberDto, AssetDto, LabelDto, User } from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { first } from 'rxjs/operators';

import { AssetsApiFilterPopoverComponent } from '../assets-api-filter-popover/assets-api-filter-popover.component';
import { AssetColorLabelPopoverComponent } from '../assets-color-label-popover/assets-color-label-popover.component';
import { ManageTagsComponent } from '../manage-tags/manage-tags.component';

@Component({
  selector: 'el-buen-inquilino-more-filters-popover',
  templateUrl: './more-filters-popover.component.html'
})
export class MoreFiltersPopoverComponent implements OnInit {
  @Input() candidaturesChecked = false;
  @Input() reportsChecked = false;
  @Input() incidencesChecked = false;
  @Input() labels: LabelDto[] = [];
  @Input() defaultLabels: LabelDto[] = [];
  @Input() filteredLabels: LabelDto[] = [];
  @Input() btnLabel: LabelDto;
  @Input() monthFilter: string = null;
  @Input() yearFilter: number = null;
  @Input() defaultMonth: string;
  @Input() defaultYear: number;
  @Input() years: number[] = [];
  @Input() filteredApisList: ApiDto[] = [];
  @Input() filteredMembersList: ApiMemberDto[] = [];
  @Input() hasApiFilter = false;
  @Input() btnApi: ApiDto;
  @Input() user: User;
  @Input() showApi = false;
  @Input() showMember = false;
  @Input() tenantEmail: string;
  @Input() tenantDni: string;

  hasFilter = false;
  labelsHaveChanged = false;

  showMonthError = false;
  showYearError = false;

  btnMember: ApiMemberDto;
  hasMemberFilter = false;

  lastValueCandidaturesChecked: boolean;
  lastValueReportsChecked: boolean;
  lastValueIncidencesChecked: boolean;
  lastValueBtnLabel: LabelDto;
  lastValueMonthFilter: string;
  lastValueYearFilter: number;
  lastValueBtnApi: ApiDto;
  lastValueBtnMember: ApiMemberDto;

  tenantEmailChange = false;
  tenantDniChange = false;

  constructor(
    private popoverCtrl: PopoverController,
    private modalController: ModalController,
    private assetService: AssetsApiService,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.btnLabel) {
      this.lastValueBtnLabel = this.btnLabel;
      this.hasFilter = true;
    }
    if (this.btnApi) {
      this.lastValueBtnApi = this.btnApi;
      this.hasApiFilter = true;
    }
    if (this.btnMember) {
      this.lastValueBtnMember = this.btnMember;
      this.hasMemberFilter = true;
    }
    if (this.monthFilter) {
      this.lastValueMonthFilter = this.monthFilter;
    }
    if (this.yearFilter) {
      this.lastValueYearFilter = this.yearFilter;
    }
    this.lastValueCandidaturesChecked = this.candidaturesChecked;
    this.lastValueReportsChecked = this.reportsChecked;
    this.lastValueIncidencesChecked = this.incidencesChecked;
  }

  showOnlyAssetsWithCandidatures(event): void {
    this.candidaturesChecked = event.detail.checked;
  }

  showOnlyAssetsWithReports(event): void {
    this.reportsChecked = event.detail.checked;
  }

  showOnlyAssetsWithIncidences(event): void {
    this.incidencesChecked = event.detail.checked;
  }

  async presentAssetLabelColorPopover(
    ev: any,
    showOptions: boolean,
    asset?: AssetDto
  ): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: AssetColorLabelPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      componentProps: {
        labels: this.filteredLabels,
        showOptions,
        asset: asset
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    if (data) {
      switch (data.options) {
        case 'FILTER':
          this.btnLabel = data.label;
          this.hasFilter = true;
          break;
        case 'RESET_FILTER':
          this.btnLabel = null;
          this.hasFilter = false;
          break;
        case 'MANAGE_TAGS':
          this.showManageTagsModal();
          break;
      }
    }
  }

  getColor(label: LabelDto): string {
    return label !== null &&
      label !== undefined &&
      label.value !== null &&
      label.value !== undefined
      ? label.value[0] === '#'
        ? label.value
        : '#' + label.value
      : '#fafafa';
  }

  handleChangeMonth(event): void {
    this.tenantEmail = null;
    if (this.showMonthError) {
      this.showMonthError = false;
    }
    this.monthFilter = event.detail.value;

    if (!this.yearFilter) {
      this.showYearError = true;
    } else {
      this.showYearError = false;
    }
  }

  handleChangeYear(event): void {
    this.tenantEmail = null;
    if (this.showYearError) {
      this.showYearError = false;
    }
    this.yearFilter = event.detail.value;

    if (!this.monthFilter) {
      this.showMonthError = true;
    } else {
      this.showMonthError = false;
    }
  }

  async presentApiFilterPopover(ev: any): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: AssetsApiFilterPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        apis: this.filteredApisList,
        members: this.filteredMembersList,
        user: this.user
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data) {
      switch (data.option) {
        case 'FILTER':
          if (data.type === 'api') {
            this.filterListByApi(data.object);
          } else if (data.type === 'member') {
            this.filterListByMember(data.object);
          }
          break;
        case 'RESET_FILTER':
          this.btnApi = null;
          this.btnMember = null;
          this.hasApiFilter = false;
          this.hasMemberFilter = false;
          break;
      }
    }
  }

  cancel(): void {
    this.popoverCtrl.dismiss({
      labels: this.labelsHaveChanged ? this.labels : null,
      btnLabel: this.lastValueBtnLabel,
      btnApi: this.lastValueBtnApi,
      btnMember: this.lastValueBtnMember,
      monthFilter: this.lastValueMonthFilter,
      yearFilter: this.lastValueYearFilter,
      candidaturesChecked: this.lastValueCandidaturesChecked,
      reportsChecked: this.lastValueReportsChecked,
      incidencesChecked: this.lastValueIncidencesChecked,
      tenantEmail: this.tenantEmail,
      tenantDni: this.tenantDni
    });
  }

  confirmFilters(): void {
    if (this.showMonthError || this.showYearError) {
      return;
    }

    this.popoverCtrl.dismiss({
      candidaturesChecked: this.candidaturesChecked,
      reportsChecked: this.reportsChecked,
      incidencesChecked: this.incidencesChecked,
      labels: this.labelsHaveChanged ? this.labels : null,
      btnLabel: this.btnLabel,
      monthFilter: this.monthFilter,
      yearFilter: this.yearFilter,
      btnApi: this.btnApi,
      btnMember: this.btnMember,
      tenantEmail: this.tenantEmail,
      tenantEmailChange: this.tenantEmailChange,
      tenantDni: this.tenantDni,
      tenantDniChange: this.tenantDniChange
    });
  }

  emailInputChange(): void {
    this.monthFilter = null;
    this.showMonthError = false;
    this.yearFilter = null;
    this.showYearError = false;
    this.tenantDni = null;
    this.changeDetector.detectChanges();

    this.tenantEmailChange = true;
  }

  dniInputChange(): void {
    this.monthFilter = null;
    this.showMonthError = false;
    this.yearFilter = null;
    this.showYearError = false;
    this.tenantEmail = null;
    this.changeDetector.detectChanges();

    this.tenantDniChange = true;
  }

  private async showManageTagsModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ManageTagsComponent,
      cssClass: 'auto-height modal-medium',
      componentProps: {
        labels: this.labels
      }
    });
    modal.onDidDismiss().then((close) => {
      if (close && close.data && close.data.saving) {
        this.labelsHaveChanged = true;
        this.getLabels();
      }
    });
    return await modal.present();
  }

  private getLabels(): void {
    this.assetService
      .getLabels(this.user.id)
      .pipe(first())
      .subscribe((labels: LabelDto[]) => {
        this.labels = labels;
        this.filteredLabels = this.utilsService.filterLabels(
          this.defaultLabels,
          JSON.parse(JSON.stringify(this.labels))
        );
      });
  }

  private filterListByApi(api: ApiDto): void {
    this.btnApi = api;
    this.hasApiFilter = true;
  }

  private filterListByMember(member: ApiMemberDto): void {
    this.btnMember = member;
    this.hasMemberFilter = true;
  }
}
