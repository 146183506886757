/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, OnInit } from '@angular/core';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import {
  DocumentDTO,
  MaritalStatusEnum,
  TypeDocumentEnum,
  User,
  VppSurvey
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { first } from 'rxjs/operators';

import {
  ViveAddDocumentModalClosingData,
  ViveDocTypes
} from '../../models/vive-modal.model';
import { ViveAddDocModalComponent } from '../vive-add-doc-modal/vive-add-doc-modal.component';

@Component({
  selector: 'el-buen-inquilino-vive-doc-modal',
  templateUrl: './vive-doc-modal.component.html',
  styleUrls: ['./vive-doc-modal.component.scss']
})
export class ViveDocModalComponent implements OnInit {
  @Input() private vppSurvey: VppSurvey;
  @Input() private documents: DocumentDTO[] = [];
  @Input() private user: User;

  docTypes = ViveDocTypes;
  private hasSaveDoc = false;

  // STATE OF INCOME
  showStateOfIncomeChip = false;
  stateOfIncomeDoc: DocumentDTO;
  private stateOfIncomeFile: File;

  // CHARGES CERTICATE
  showChargesCertificateChip = false;
  chargesCertificateDoc: DocumentDTO;
  private chargesCertificateFile: File;

  // PROPERTY REGISTRATION
  propertyRegistrationDoc: DocumentDTO;
  private propertyRegistrationFile: File;

  // MARITAL STATUS
  showMaritalStatusChip = false;
  maritalStatusDoc: DocumentDTO;
  private maritalStatusFile: File;

  worklifeDoc: DocumentDTO;
  private worklifeFile: File;

  constructor(
    private modalController: ModalController,
    private documentService: DocumentsApiService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.setShowStateOfIncomeChip();
    this.setShowPropertyRegistrationChip();
    this.setShowMaritalStatusChip();
    this.setShowStateOfWorklife();
  }

  close(): void {
    const role = this.hasSaveDoc ? 'search' : 'dismiss';
    this.modalController.dismiss(null, role);
  }

  docChipClicked(type: ViveDocTypes): void {
    switch (type) {
      case ViveDocTypes.STATE_OF_INCOME: {
        if (!this.stateOfIncomeDoc) {
          this.presentDocumentModal(
            type,
            TypeDocumentEnum.INCOME_TAX_DECLARATION,
            this.prepareDocName(type)
          );
        }
        break;
      }
      case ViveDocTypes.CHARGES_CERTIFICATE: {
        if (!this.chargesCertificateDoc) {
          this.presentDocumentModal(
            type,
            TypeDocumentEnum.OTHER_DOCUMENT,
            this.prepareDocName(type)
          );
        }
        break;
      }
      case ViveDocTypes.PROPERTY_REGISTRATION: {
        if (!this.propertyRegistrationDoc) {
          this.presentDocumentModal(
            type,
            TypeDocumentEnum.OTHER_DOCUMENT,
            this.prepareDocName(type)
          );
        }
        break;
      }
      case ViveDocTypes.MARITAL_STATUS: {
        if (!this.maritalStatusDoc) {
          this.presentDocumentModal(
            type,
            TypeDocumentEnum.OTHER_DOCUMENT,
            this.prepareDocName(type)
          );
        }
        break;
      }
      case ViveDocTypes.WORK_LIFE: {
        if (!this.worklifeDoc) {
          this.presentDocumentModal(
            type,
            TypeDocumentEnum.WORK_LIFE,
            this.prepareDocName(type)
          );
        }
        break;
      }
    }
  }

  private prepareDocName(type: ViveDocTypes): string {
    let name: string;
    switch (type) {
      case ViveDocTypes.STATE_OF_INCOME: {
        name = ViveDocTypes.STATE_OF_INCOME_DOC_NAME;
        break;
      }
      case ViveDocTypes.CHARGES_CERTIFICATE: {
        name = ViveDocTypes.CHARGES_CERTIFICATE_DOC_NAME;
        break;
      }
      case ViveDocTypes.PROPERTY_REGISTRATION: {
        name = ViveDocTypes.PROPERTY_REGISTRATION_DOC_NAME;
        break;
      }
      case ViveDocTypes.MARITAL_STATUS: {
        name = ViveDocTypes.MARITAL_STATUS_DOC_NAME;
        break;
      }
      case ViveDocTypes.WORK_LIFE: {
        name = ViveDocTypes.WORK_LIFE_DOC_NAME;
        break;
      }
    }
    const date = format(new Date(), 'dd-MM-yyyy');
    name = `${name}_${this.user.firstname}_${date}`;
    return name;
  }

  private async presentDocumentModal(
    viveDocType: ViveDocTypes,
    typeDocumentInput: TypeDocumentEnum,
    name: string
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: ViveAddDocModalComponent,
      cssClass: 'vive-doc-modal',
      showBackdrop: true,
      componentProps: {
        document: new DocumentDTO(),
        typeDocumentInput,
        user: this.user,
        name
      } as Partial<ViveAddDocModalComponent>
    });
    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<ViveAddDocumentModalClosingData>) => {
        this.onCloseAddDocumentModal(close, viveDocType);
      });
    return await modal.present();
  }

  private onCloseAddDocumentModal(
    close: OverlayEventDetail<ViveAddDocumentModalClosingData>,
    viveDocType: ViveDocTypes
  ): void {
    if (close?.data) {
      switch (viveDocType) {
        case ViveDocTypes.STATE_OF_INCOME: {
          this.setStateOfIncomeDoc(close.data, viveDocType);
          break;
        }
        case ViveDocTypes.CHARGES_CERTIFICATE: {
          this.setChargesCertificateDoc(close.data, viveDocType);
          break;
        }
        case ViveDocTypes.PROPERTY_REGISTRATION: {
          this.setPropertyRegistrationDoc(close.data, viveDocType);
          break;
        }
        case ViveDocTypes.MARITAL_STATUS: {
          this.setMaritalStatusDoc(close.data, viveDocType);
          break;
        }
        case ViveDocTypes.WORK_LIFE: {
          this.setWorklifeDoc(close.data, viveDocType);
          break;
        }
      }
    }
  }

  private setStateOfIncomeDoc(
    data: ViveAddDocumentModalClosingData,
    viveDocType: ViveDocTypes
  ): void {
    this.stateOfIncomeFile = data.file;
    this.stateOfIncomeDoc = data.document;
    this.saveDoc(data, viveDocType);
  }

  private setChargesCertificateDoc(
    data: ViveAddDocumentModalClosingData,
    viveDocType: ViveDocTypes
  ): void {
    this.chargesCertificateFile = data.file;
    this.chargesCertificateDoc = data.document;
    this.saveDoc(data, viveDocType);
  }

  private setPropertyRegistrationDoc(
    data: ViveAddDocumentModalClosingData,
    viveDocType: ViveDocTypes
  ): void {
    this.propertyRegistrationFile = data.file;
    this.propertyRegistrationDoc = data.document;
    this.saveDoc(data, viveDocType);
  }

  private setMaritalStatusDoc(
    data: ViveAddDocumentModalClosingData,
    viveDocType: ViveDocTypes
  ): void {
    this.maritalStatusFile = data.file;
    this.maritalStatusDoc = data.document;
    this.saveDoc(data, viveDocType);
  }

  private setWorklifeDoc(
    data: ViveAddDocumentModalClosingData,
    viveDocType: ViveDocTypes
  ): void {
    this.worklifeFile = data.file;
    this.worklifeDoc = data.document;
    this.saveDoc(data, viveDocType);
  }

  private async saveDoc(
    data: ViveAddDocumentModalClosingData,
    viveDocType: ViveDocTypes
  ): Promise<void> {
    await this.loadingService.presentLoading('Subiendo documento..');
    this.documentService
      .createDocument(data.document, data.file)
      .pipe(first())
      .subscribe(
        async () => {
          if (!this.hasSaveDoc) {
            this.hasSaveDoc = true;
            await this.loadingService.dismissLoading();
          }
        },
        async () => {
          this.onErrorSaveDoc(viveDocType);
          await this.loadingService.dismissLoading();
        }
      );
  }

  private onErrorSaveDoc(viveDocType: ViveDocTypes): void {
    this.presentToast();
    switch (viveDocType) {
      case ViveDocTypes.STATE_OF_INCOME: {
        this.stateOfIncomeDoc = null;
        this.stateOfIncomeFile = null;
        break;
      }
      case ViveDocTypes.CHARGES_CERTIFICATE: {
        this.chargesCertificateDoc = null;
        this.chargesCertificateFile = null;
        break;
      }
      case ViveDocTypes.PROPERTY_REGISTRATION: {
        this.propertyRegistrationDoc = null;
        this.propertyRegistrationFile = null;
        break;
      }
      case ViveDocTypes.MARITAL_STATUS: {
        this.maritalStatusDoc = null;
        this.maritalStatusFile = null;
        break;
      }
      case ViveDocTypes.WORK_LIFE: {
        this.worklifeDoc = null;
        this.worklifeFile = null;
        break;
      }
    }
  }

  private async presentToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translate.instant(
        'components.vive_doc_modal.upload_error'
      ) as string,
      duration: 3000,
      position: 'top',
      color: 'danger'
    });
    toast.present();
  }

  private setShowStateOfIncomeChip(): void {
    this.showStateOfIncomeChip = this.vppSurvey.incomeTaxDeclaration;

    if (this.showStateOfIncomeChip) {
      this.getStateOfIncomeDocFromList();
    } else {
      this.showChargesCertificateChip = true;
      this.getChargesCertificate();
    }
  }

  private getStateOfIncomeDocFromList(): void {
    this.stateOfIncomeDoc = this.getDocumentFromList(
      ViveDocTypes.STATE_OF_INCOME
    );
  }

  private getChargesCertificate(): void {
    this.chargesCertificateDoc = this.getDocumentFromList(
      ViveDocTypes.CHARGES_CERTIFICATE
    );
  }

  private setShowPropertyRegistrationChip(): void {
    this.propertyRegistrationDoc = this.getDocumentFromList(
      ViveDocTypes.PROPERTY_REGISTRATION,
      ViveDocTypes.PROPERTY_REGISTRATION_DOC_NAME
    );
  }

  private setShowMaritalStatusChip(): void {
    this.showMaritalStatusChip =
      this.vppSurvey.maritalStatus === MaritalStatusEnum.DIVORCED;

    if (this.showMaritalStatusChip) {
      this.getMaritalStatusDocFromList();
    }
  }

  private getMaritalStatusDocFromList(): void {
    this.maritalStatusDoc = this.getDocumentFromList(
      ViveDocTypes.MARITAL_STATUS,
      ViveDocTypes.MARITAL_STATUS_DOC_NAME
    );
  }

  private setShowStateOfWorklife(): void {
    this.worklifeDoc = this.getDocumentFromList(ViveDocTypes.WORK_LIFE);
  }

  private getDocumentFromList(
    docType: ViveDocTypes,
    name?: ViveDocTypes
  ): DocumentDTO {
    if (this.documents.length === 0) {
      return null;
    }

    if (docType === ViveDocTypes.STATE_OF_INCOME) {
      return this.documents.find(
        (document: DocumentDTO) =>
          document.typeDocument === TypeDocumentEnum.INCOME_TAX_DECLARATION
      );
    } else if (docType === ViveDocTypes.WORK_LIFE) {
      return this.documents.find(
        (document: DocumentDTO) =>
          document.typeDocument === TypeDocumentEnum.WORK_LIFE
      );
    } else {
      return this.documents.find(
        (document: DocumentDTO) =>
          document.typeDocument === TypeDocumentEnum.OTHER_DOCUMENT &&
          document.name.includes(name)
      );
    }
  }
}
