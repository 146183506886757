/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Auth,
  ConfirmationResult,
  PhoneAuthProvider,
  RecaptchaVerifier,
  UserCredential,
  signInWithCredential
} from '@angular/fire/auth';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { TypeUserEnum, UserDto } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { WindowService } from '@core/services/window/window.service';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';

import { CodeForm, PhoneForm } from '../../models/phone-form.model';
import { TermsOfUseResponseData } from '../../models/terms-of-use.model';
import { presentToast } from '../../utils/toast.utils';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';

@Component({
  selector: 'el-buen-inquilino-login-phone-form',
  templateUrl: './login-phone-form.component.html'
})
export class LoginPhoneFormComponent implements OnInit {
  @Input() role: TypeUserEnum;
  @Input() user: UserDto;

  showPhoneForm = false;
  phoneForm: FormGroup<PhoneForm>;
  showCodeForm = false;
  codeForm: FormGroup<CodeForm>;
  validationErrorMessages = appValidators.validationErrorMessages;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  windowRef: any;
  country: CountryCode = 'ES';

  @Output() returnEvent = new EventEmitter();

  get phoneControl(): FormControl<string> {
    return this.phoneForm.controls.phone;
  }

  get codeControl(): FormControl<string> {
    return this.codeForm.controls.code;
  }

  get acceptProfileStudyControl(): FormControl<boolean> {
    if (!!this.role && this.isTenant) {
      return this.codeForm.controls.acceptProfileStudy;
    } else {
      return null;
    }
  }

  get acceptConditionsCheckedControl(): FormControl<boolean> {
    if (!!this.role) {
      return this.codeForm.controls.acceptConditionsChecked;
    } else {
      return null;
    }
  }

  get isTenant(): boolean {
    return this.role === TypeUserEnum.TENANT;
  }

  constructor(
    private loadingService: LoadingService,
    private utilsService: UtilsService,
    private authService: AuthService,
    private translate: TranslateService,
    private toastController: ToastController,
    private auth: Auth,
    private win: WindowService,
    private modalController: ModalController,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.setPhoneForm();
    this.setWindowRef();
  }

  return(): void {
    this.returnEvent.emit();
  }

  async presentModal(type: number): Promise<void> {
    let privacy = false;
    let profilePrivacy = false;

    if (type === 1) {
      privacy = true;
    } else {
      profilePrivacy = true;
    }

    const modal = await this.modalController.create({
      component: TermsOfUseComponent,
      cssClass: 'terms-class',
      componentProps: {
        privacy,
        profilePrivacy
      }
    });

    await modal.present();

    const { data }: OverlayEventDetail<TermsOfUseResponseData> =
      await modal.onWillDismiss();
    if (data) {
      if (data.accept) {
        if (type === 1) {
          this.acceptConditionsCheckedControl.setValue(true);
        } else {
          this.acceptProfileStudyControl.setValue(true);
        }
      } else {
        if (type === 1) {
          this.acceptConditionsCheckedControl.setValue(false);
        } else {
          this.acceptProfileStudyControl.setValue(false);
        }
      }
    }
  }

  changeCountry(event: CountryCode): void {
    this.phoneControl.setValue(null);
    this.phoneControl.clearValidators();
    this.country = event;
    this.phoneControl.setValidators(this.getPhoneControlValidators());
    this.phoneControl.updateValueAndValidity();
  }

  loginPhone(): void {
    if (this.phoneForm.invalid) {
      this.utilsService.showFormErrors(this.phoneForm);
      return;
    }

    this.verifyPhone();
  }

  async verifyPhone(): Promise<void> {
    await this.loadingService.presentLoading(null);
    const appVerifier = this.windowRef.recaptchaVerifier;
    const formatedPhoneNumber = this.utilsService.formatPhone(
      this.phoneForm.value.phone
    );
    this.authService
      .phoneLogin(formatedPhoneNumber, appVerifier)
      .then(async (res: ConfirmationResult) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        await this.loadingService.dismissLoading();
        this.windowRef.confirmationResult = res;
        presentToast(
          this.toastController,
          this.translate.instant('pages.login.data.login.code_sent') as string
        );
        this.setCodeForm();
        this.showCodeForm = true;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch(async (err: any) => {
        await this.loadingService.dismissLoading();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err?.code === 'auth/too-many-requests') {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.login.data.login.too_many_failures'
            ) as string,
            'danger'
          );
        } else {
          presentToast(
            this.toastController,
            this.translate.instant(
              'pages.login.data.login.phone_not_valid_2'
            ) as string,
            'danger'
          );
        }
      });
  }

  async verifyCode(): Promise<void> {
    if (this.codeForm.invalid) {
      this.utilsService.showFormErrors(this.codeForm);
      return;
    }

    await this.loadingService.presentLoading(null);
    const code: string = this.codeForm.value.code.trim();
    const credentials = PhoneAuthProvider.credential(
      this.windowRef.confirmationResult.verificationId,
      code
    );
    signInWithCredential(this.auth, credentials)
      .then(async (p: UserCredential) => {
        await this.loadingService.dismissLoading();

        if (!this.role) {
          this.authService.processAlternativeTokenLogin(p, 'phone');
        } else {
          this.authService.processAlternativeTokenLogin(
            p,
            'phone',
            null,
            true,
            this.role
          );
        }
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        presentToast(
          this.toastController,
          this.translate.instant(
            'pages.login.data.login.incorrect_code'
          ) as string,
          'danger'
        );
      });
  }

  private setPhoneForm(): void {
    this.phoneForm = new FormGroup<PhoneForm>({
      phone: new FormControl<string>(
        this.user?.phone ?? '',
        this.getPhoneControlValidators()
      )
    });
  }

  private getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }

  private setCodeForm(): void {
    this.codeForm = new FormGroup<CodeForm>({
      code: new FormControl<string>(null, [Validators.required])
    });

    if (!!this.role) {
      if (this.isTenant) {
        this.codeForm.addControl(
          'acceptProfileStudy',
          new FormControl(false, [Validators.requiredTrue])
        );
      }
      this.codeForm.addControl(
        'acceptConditionsChecked',
        new FormControl(false, [Validators.requiredTrue])
      );
    }
  }

  private setWindowRef(): void {
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'sign-in-button',
      {
        size: 'invisible'
      }
    );
  }
}
