import { TypeDocumentEnum } from '@core/models';

export interface UploadTenantDocumentEvent {
  type: TypeDocumentEnum;
  name?: UploadTenantDocumentNameEnum;
}

export enum UploadTenantDocumentNameEnum {
  HAS_NOT_OPTIONAL_PAYSLIP = 'hasNotOptionalPayslip',
  HAS_ONE_MONTH_PAYSLIPS = 'hasOneMonthPayslips',
  HAS_TWO_MONTH_PAYSLIPS = 'hasTwoMonthPayslips',
  HAS_ONE_QUARTER_MODEL = 'hasOneQuarterModel',
  HAS_TWO_QUARTER_MODEL = 'hasTwoQuarterModel'
}
