import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areThereItems'
})
export class AreThereItemsPipe implements PipeTransform {
  transform(items: unknown[]): unknown {
    return !!items && items?.length > 0;
  }
}
