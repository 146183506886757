/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @angular-eslint/component-class-suffix */
import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { NotificationsApiService } from '@core/api-services/notifications-api/notifications-api.service';
import {
  NotificationDto,
  NotificationStatusEnum,
  NotificationTypeEnum,
  UpdateNotification,
  UpdateUserStatusResponseDto,
  User
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { MenuService } from '@core/services/menu/menu.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { showRejectCandidatureAlert } from '../../utils/reject-candidature-alert.utils';

@Component({
  selector: 'el-buen-inquilino-cotenant-modal',
  templateUrl: './cotenant-modal.page.html',
  encapsulation: ViewEncapsulation.None
})
export class CotenantModalPage implements OnInit {
  @Input() requestsReceived: NotificationDto[];
  @Input() user: User;
  allowPersonalDataHandlingChecked = false;
  allowDataProcessingChecked = false;
  screenWidth: number;
  candidaturesReceived: NotificationDto[];
  guarantorsReceived: NotificationDto[];
  cotenantsReceived: NotificationDto[];
  attachDocumentsReceived: NotificationDto[];

  constructor(
    private modalController: ModalController,
    private candidaturesService: CandidaturesApiService,
    private alertController: AlertController,
    private router: Router,
    private menuService: MenuService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private notificationsService: NotificationsApiService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.screenWidth = window.innerWidth;
  }

  getData(): void {
    if (this.requestsReceived !== null) {
      this.candidaturesReceived = this.requestsReceived.filter(
        (notification: NotificationDto) => {
          return notification.type === NotificationTypeEnum.CANDIDATURE;
        }
      );
      this.cotenantsReceived = this.requestsReceived.filter(
        (notification: NotificationDto) => {
          return notification.type === NotificationTypeEnum.COTENANT;
        }
      );
      this.guarantorsReceived = this.requestsReceived.filter(
        (notification: NotificationDto) => {
          return notification.type === NotificationTypeEnum.GUARANTOR;
        }
      );
      this.attachDocumentsReceived = this.requestsReceived.filter(
        (notification: NotificationDto) => {
          return notification.type === NotificationTypeEnum.UPLOAD_DOC;
        }
      );
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  acceptOrRejectCotenant(
    notificationId: string,
    isAccepted: boolean,
    requestingUserId: string
  ): void {
    this.loadingService.presentSecondLoader(null, true);
    const param: UpdateNotification = new UpdateNotification();
    param.newStatus = isAccepted
      ? NotificationStatusEnum.ACCEPTED
      : NotificationStatusEnum.REJECTED;
    this.notificationsService
      .updateNotification(notificationId, param)
      .subscribe(
        () => {
          this.dismiss();
          if (param.newStatus === NotificationStatusEnum.ACCEPTED) {
            this.candidaturesService
              .deletePreviousCandidatures(this.user.id)
              .subscribe(
                () => {
                  this.candidaturesService
                    .updateCandidaturesCotenants(requestingUserId, this.user.id)
                    .subscribe(
                      () => null,
                      (error) =>
                        console.error(
                          'error updateCandidaturesCotenants',
                          error
                        )
                    );
                },
                (error) => {
                  console.error('error delete', error);
                }
              );
          }
          this.loadingService.dismissSecondLoader();
        },
        (error) => {
          this.loadingService.dismissSecondLoader();
          console.error('error updateCotenant', error);
        }
      );
  }

  acceptOrRejectGuarantor(
    notificationId: string,
    isAccepted: boolean,
    requestingUserId: string
  ): void {
    const param: UpdateNotification = new UpdateNotification();
    param.newStatus = isAccepted
      ? NotificationStatusEnum.ACCEPTED
      : NotificationStatusEnum.REJECTED;
    this.notificationsService
      .updateNotification(notificationId, param)
      .subscribe(() => {
        this.candidaturesService
          .updateCandidaturesCotenants(requestingUserId, this.user.id)
          .subscribe(
            () => null,
            (error) => {
              console.error('error updateCandidaturesCotenants', error);
            }
          );
        this.dismiss();
        if (isAccepted && this.user.guarantor) {
          this.router.navigateByUrl('/guarantor/' + this.user.id);
        } else if (isAccepted) {
          this.presentAlertGuarantor();
        }
      });
  }

  acceptOrRejectAttachDocument(
    notificationId: string,
    isAccepted: boolean
  ): void {
    const param: UpdateNotification = new UpdateNotification();
    param.newStatus = isAccepted
      ? NotificationStatusEnum.ACCEPTED
      : NotificationStatusEnum.REJECTED;
    this.notificationsService
      .updateNotification(notificationId, param)
      .subscribe(() => {
        this.allowPersonalDataHandlingChecked = false;
        this.allowDataProcessingChecked = false;
        this.dismiss();
      });
  }

  acceptOrRejectCandidature(
    candidatureId: string,
    notificationId: string,
    isAccepted: boolean
  ): void {
    if (isAccepted) {
      this.acceptCandidatureCall(candidatureId, notificationId);
    } else {
      this.rejectCandidatureCall(candidatureId, notificationId);
    }
  }

  async presentAlertGuarantor(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant('cotenant.modal.guarantor_header'),
      message: this.translate.instant('cotenant.modal.guarantor_msg'),
      buttons: [
        {
          text: this.translate.instant('cotenant.modal.guarantor_button'),
          cssClass: 'danger',
          handler: (): void => {
            this.user.guarantor = true;
            this.menuService.loadMenuItems(this.user);
            this.router.navigateByUrl('/guarantor/' + this.user.id);
          }
        }
      ]
    });
    await alert.present();
  }

  getCompleteSenderName(noti: NotificationDto): string {
    let completeName = `${noti.senderFirstname}  ${noti.senderSurname1}`;
    if (noti.senderSurname2) {
      completeName += ' ' + noti.senderSurname2;
    }
    return completeName;
  }

  private acceptCandidatureCall(
    candidatureId: string,
    notificationId: string
  ): void {
    this.candidaturesService
      .updateUserStatus(candidatureId, this.user.id, 'ACCEPT')
      .subscribe((response: UpdateUserStatusResponseDto) => {
        const param: UpdateNotification = new UpdateNotification();
        param.newStatus = NotificationStatusEnum.ACCEPTED;
        param.candidatureId = candidatureId;
        this.notificationsService
          .updateNotification(notificationId, param)
          .subscribe(() => {
            this.dismiss();
          });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (response.convertToGuarantor) {
          //Si en la respuesta viene un true, este inquilino se convertirá
          // en guarantor y hay que hacerle la transición
          this.presentAlertGuarantor();
        }
      });
  }

  private async rejectCandidatureCall(
    candidatureId: string,
    notificationId: string
  ): Promise<void> {
    const reject = await showRejectCandidatureAlert(
      this.alertController,
      this.translate.instant(
        'components.vive_modal.reject_alert_msg'
      ) as string,
      this.translate.instant('common.cancel') as string,
      this.translate.instant('common.accept') as string
    );

    if (reject) {
      const updateNotification: UpdateNotification = {
        read: true,
        candidatureId
      };
      this.notificationsService
        .updateNotification(notificationId, updateNotification)
        .pipe(first())
        .subscribe(() => this.dismiss());
    }
  }
}
