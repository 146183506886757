/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { TrialFlowService } from '@core/services/trial-flow/trial-flow.service';

@Component({
  selector: 'confirm-candidature-step',
  templateUrl: './confirm-candidature-step.component.html'
})
export class ConfirmCandidatureStepComponent implements OnInit {
  contact: any = {};

  constructor(
    private trialFlowService: TrialFlowService,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {
    this.contact.userAccept = this.authenticationService.user;
    this.trialFlowService.createCandidatureAssetDto$.subscribe((val) => {
      if (val && val.asset) {
        this.contact.assetData = val.asset;
      }
      if (val && val.users) {
        this.contact.users = val.users;
      }
    });
  }
}
