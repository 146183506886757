/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
  BankAccountValidation,
  TenantOwnershipRequest
} from '@app/modules/shared/models/bbva-table.model';
import { environment } from '@environments/environment';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';
import { OpenClosedFilter } from '@shared/models/insurances-management-filters.model';
import {
  add,
  endOfMonth,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  sub
} from 'date-fns';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  BackendPayload,
  CotenantsArag,
  EnvelopeRequest,
  EnvelopeStatus,
  InsuranceDTO,
  InsuranceReceiveOffers,
  PageDto,
  PolicyIssue,
  Sinister,
  TemplatesDocumentDto,
  TemplatesDto,
  UpdateTemplateStatus
} from '../../models';
import { CandidaturesApiService } from '../candidatures-api/candidatures-api.service';

@Injectable()
export class InsurancesApiService {
  url: string;

  incofisaInsurances: PolicyIssue[] = [];
  getSignedDocuments = new Subject<void>();

  /*** Gestión de pólizas (backoffice) ***/
  incofisaInsurances$: BehaviorSubject<PolicyIssue[]> = new BehaviorSubject<
    PolicyIssue[]
  >([]);
  downloadEmitter: EventEmitter<{
    option: number;
    userId: string;
    policyNumber: string;
  }> = new EventEmitter<{
    option: number;
    userId: string;
    policyNumber: string;
  }>();
  getPoliciesEmitter: EventEmitter<string> = new EventEmitter<string>();
  clearPoliciesFilter: EventEmitter<null> = new EventEmitter<null>();
  clearPoliciesFilterFromParent: EventEmitter<null> = new EventEmitter<null>();
  isThereAnyFilterEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  presentUtilsOptionPopoverEmitter: EventEmitter<{
    ev: Event;
    insurance: PolicyIssue;
    unpaid: boolean;
  }> = new EventEmitter<{
    ev: Event;
    insurance: PolicyIssue;
    unpaid: boolean;
  }>();
  openSinisterModalEmitSubscription: EventEmitter<{
    insurance: PolicyIssue;
    seeDetails: boolean;
  }> = new EventEmitter<{
    insurance: PolicyIssue;
    seeDetails: boolean;
  }>();
  /*** END Gestión de pólizas (backoffice) ***/

  constructor(
    private http: HttpClient,
    private candidaturesService: CandidaturesApiService
  ) {
    this.url = environment.services.insurances;
  }

  getSignedDocumentsObs(): Observable<void> {
    return this.getSignedDocuments.asObservable();
  }

  nextSignedDocumentsObs(): void {
    this.getSignedDocuments.next();
  }

  getInsurances(
    numElements = '20',
    afterElementId?: string
  ): Observable<InsuranceDTO[]> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    return this.http
      .get<PageDto<InsuranceDTO>>(this.url + '/insurances', {
        params: httpParams
      })
      .pipe(map((res) => res.elements));
  }

  createInsurance(file: File, insuranceData: InsuranceDTO): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(insuranceData).forEach((data: string) => {
      if (data !== 'file') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        formData.append(`${data}`, insuranceData[data]);
      }
    });

    return this.http.post(this.url + '/insurances/tenant', formData);
  }

  updateInsurance(
    insuranceId: string,
    insuranceData: InsuranceDTO
  ): Observable<any> {
    return this.http.put(
      this.url + '/insurances' + `/${insuranceId}`,
      insuranceData
    );
  }

  sendTemplate(file: File, notes?: string): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    if (notes) {
      formData.append('notes', notes);
    }
    return this.http.post<void>(this.url + '/loans/templates', formData);
  }

  deleteInsurance(insuranceId: string): Observable<any> {
    return this.http.delete(this.url + '/insurances' + `/${insuranceId}`);
  }

  getModelData(payLoad: BackendPayload): Observable<any> {
    return this.http.post<any>(this.url + '/loans/models', payLoad);
  }

  addEnvelopeToCandidature(
    candidatureId: string,
    payload: EnvelopeRequest
  ): Observable<any> {
    return this.http.post<any>(
      this.url + '/loans/envelopes/' + candidatureId,
      payload
    );
  }

  updateReceiveOffers(
    keyCode: string,
    receiveOffers: InsuranceReceiveOffers
  ): Observable<any> {
    return this.http.patch(
      this.url + '/insurances' + '/' + keyCode,
      receiveOffers
    );
  }

  setIncofisaInsurancesObs(insurances: PolicyIssue[]): void {
    this.incofisaInsurances = insurances;
    this.incofisaInsurances$.next(this.incofisaInsurances);
  }

  getIncofisaInsurancesObs(): Observable<PolicyIssue[]> {
    return this.incofisaInsurances$.asObservable();
  }

  getHomeOwners(array: PolicyIssue[]): string[] {
    let homeowners = array.map((p: PolicyIssue) => p.emailPolicyHolder);

    // Eliminamos los duplicados
    homeowners = [...new Set(homeowners)];

    homeowners.sort((a: string, b: string) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );

    return homeowners;
  }

  getAddressFilters(array: PolicyIssue[]): {
    portalsList: string[];
    floorsList: string[];
    doorsList: string[];
  } {
    const portalsList = array.map((a: PolicyIssue) => a.asset?.portal);
    const floorsList = array.map((a: PolicyIssue) => a.asset?.floor);
    const doorsList = array.map((a: PolicyIssue) => a.asset?.door);

    return {
      portalsList,
      floorsList,
      doorsList
    };
  }

  filterPolicies(
    array: PolicyIssue[],
    homeOwnerFilterValue: string,
    portal: string,
    floor: string,
    door: string,
    searchInternalCode: string,
    searchbarValue?: string,
    openClosedFilterValue?: OpenClosedFilter,
    showOpenClosedFilter = true
  ): PolicyIssue[] {
    // Si hay filtro de home owner
    if (!!homeOwnerFilterValue && homeOwnerFilterValue !== '') {
      // Filtramos los análisis por el cliente
      array = array.filter(
        (p: PolicyIssue) => p.emailPolicyHolder === homeOwnerFilterValue
      );
    }

    // Si hay filtro de address
    if (portal) {
      array = array.filter((p: PolicyIssue) => p.asset?.portal === portal);
    }
    if (floor) {
      array = array.filter((p: PolicyIssue) => p.asset?.floor === floor);
    }
    if (door) {
      array = array.filter((p: PolicyIssue) => p.asset?.door === door);
    }

    // Si hay filtro de edificio
    if (searchInternalCode) {
      array = array.filter((p: PolicyIssue) => p.building?.internalCode);
    }

    // Si hay filtro de texto
    if (searchbarValue && searchbarValue.trim() !== '') {
      array = array.filter(
        (p: PolicyIssue) =>
          p.insuranceAsset?.address?.toLowerCase().includes(searchbarValue) ||
          p.policyNumber?.toLowerCase().includes(searchbarValue)
      );
    }

    // Si se está en la pestaña de siniestros
    if (array?.length > 0 && !!openClosedFilterValue && showOpenClosedFilter) {
      if (openClosedFilterValue === OpenClosedFilter.UNRESOLVED) {
        array = array.filter((p: PolicyIssue) =>
          p.sinisters.some((sinister: Sinister) => !sinister.endDate)
        );
      } else {
        array = array.filter((p: PolicyIssue) =>
          p.sinisters.every((sinister: Sinister) => !!sinister.endDate)
        );
      }
    }

    return array;
  }

  filterPoliciesOwner(
    array: PolicyIssue[] | Sinister[],
    type: ChildRoutesEnum,
    searchbarValue?: string,
    date?: string,
    renovationDate?: boolean
  ): PolicyIssue[] | Sinister[] {
    // Si hay filtro de texto
    if (searchbarValue && searchbarValue.trim() !== '') {
      if (type === ChildRoutesEnum.ACTIVE) {
        array = (array as PolicyIssue[]).filter(
          (p: PolicyIssue) =>
            this.includes(p.insuranceAsset?.address, searchbarValue) ||
            this.includes(p?.policyNumber, searchbarValue) ||
            this.includes(p?.address, searchbarValue) ||
            this.includes(p?.insuranceAsset?.city, searchbarValue) ||
            p.insuranceAsset.cotenants.some(
              (tenant: CotenantsArag) =>
                this.includes(tenant.name, searchbarValue) ||
                this.includes(tenant.surname, searchbarValue) ||
                this.includes(tenant.surname2, searchbarValue)
            )
        );
      } else {
        array = (array as Sinister[]).filter(
          (p: Sinister) =>
            this.includes(p.insuranceAsset?.address, searchbarValue) ||
            this.includes(p?.policyNumber, searchbarValue) ||
            this.includes(p?.insuranceAsset?.city, searchbarValue) ||
            p.insuranceAsset.cotenants.some(
              (tenant: CotenantsArag) =>
                this.includes(tenant.name, searchbarValue) ||
                this.includes(tenant.surname, searchbarValue) ||
                this.includes(tenant.surname2, searchbarValue)
            )
        );
      }
    }

    // Si hay filtro de fecha
    if (!!date) {
      const initDate = parse(date, 'dd/MM/yyyy', new Date());
      const endDate = endOfMonth(initDate);

      if (type === ChildRoutesEnum.ACTIVE) {
        array = (array as PolicyIssue[]).filter((p: PolicyIssue) =>
          this.isBetween(
            initDate,
            endDate,
            parse(p.inceptionDate, 'dd/MM/yyyy', new Date())
          )
        );
      } else {
        array = (array as Sinister[]).filter((p: Sinister) =>
          this.isBetween(initDate, endDate, new Date(p.creationDate))
        );
      }
    }

    // Si esta activado el check de renovacion
    if (renovationDate) {
      const initDate = sub(new Date(), { days: 90 });
      array = (array as PolicyIssue[]).filter((p: PolicyIssue) =>
        this.isBetween(
          initDate,
          new Date(),
          add(parse(p.inceptionDate, 'dd/MM/yyyy', new Date()), { years: 1 })
        )
      );
    }

    return array;
  }

  includes(field: string, filter: string): boolean {
    return field?.toLowerCase().includes(filter);
  }

  isBetween(initDate: Date, endDate: Date, compareDate: Date): boolean {
    return (
      (isAfter(compareDate, initDate) || isSameDay(compareDate, initDate)) &&
      (isBefore(compareDate, endDate) || isSameDay(compareDate, endDate))
    );
  }

  async searchByPolicyId(policyId: string): Promise<PolicyIssue[]> {
    try {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
      return await this.candidaturesService
        .getContractedPoliciesByPolicyNumber(policyId)
        .pipe(first())
        .toPromise();
    } catch (err) {
      throw new Error();
    }
  }

  getDocusSignedByCandidatureId(
    candidatureId: string
  ): Observable<EnvelopeStatus[]> {
    const endpoint = `${this.url}/loans/envelopes/${candidatureId}`;

    return this.http.get<EnvelopeStatus[]>(endpoint);
  }

  getDocusSignedByTenant(): Observable<EnvelopeStatus[]> {
    const endpoint = `${this.url}/loans/envelopes/tenant`;

    return this.http.get<EnvelopeStatus[]>(endpoint);
  }

  downloadDocusignDocument(envelopeId: string): Observable<Blob> {
    const endpoint = `${this.url}/loans/envelopes/${envelopeId}/download`;
    return this.http.get(endpoint, {
      responseType: 'blob'
    });
  }

  getBankAccountValidations(
    candidatureId: string
  ): Observable<BankAccountValidation[]> {
    return this.http.get<BankAccountValidation[]>(
      this.url + `/accounts/${candidatureId}/ownership-statuses`
    );
  }

  getTemplates(): Observable<TemplatesDto[]> {
    return this.http.get<TemplatesDto[]>(`${this.url}/loans/templates`);
  }

  documentTemplate(fileId: string): Observable<TemplatesDocumentDto> {
    const endpoint = `${this.url}/loans/templates/${fileId}/download`;
    return this.http.get<TemplatesDocumentDto>(endpoint);
  }

  updateTemplateStatus(
    fileId: string,
    update: UpdateTemplateStatus
  ): Observable<any> {
    return this.http.patch(
      `${this.url}/loans/templates/${fileId}/change-status`,
      update
    );
  }

  checkTenantOwnership(request: TenantOwnershipRequest): Observable<unknown> {
    return this.http.post<void>(
      this.url + '/accounts/tenant-ownership',
      request
    );
  }

  requestGuaranteeContract(
    candidatureId: string,
    tenantEmail: string
  ): Observable<unknown> {
    const request = {
      candidatureId,
      tenantEmail
    };
    return this.http.post<void>(this.url + '/rental-contracts', request);
  }
}
