/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { StepsService } from '@core/services/steps/steps.service';
import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'el-buen-inquilino-step-three-multiple',
  templateUrl: './step-three-multiple.component.html'
})
export class StepThreeMultipleComponent {
  contact: any = {};

  constructor(
    public stepsService: StepsService,
    private utilsService: UtilsService
  ) {}

  mask(name: string): string {
    return this.utilsService.mask(name);
  }
}
