/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
import { Component, Input, OnInit } from '@angular/core';
import { CertificationsApiService } from '@core/api-services/certifications-api/certifications-api.service';
import { InsurancesCompanyApiService } from '@core/api-services/insurances-company-api/insurances-company-api.service';
import {
  CandidatureDetailedDto,
  DocumentDTO,
  UserCandidatureDto
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController, ToastController } from '@ionic/angular';

import { MultipleDocumentModalComponent } from '../multiple-document-modal/multiple.document.modal.component';

@Component({
  selector: 'el-buen-inquilino-documentation-tenants-modal',
  templateUrl: './documentation-tenants-modal.component.html'
})
export class DocumentationTenantsModalComponent implements OnInit {
  @Input() certification: CandidatureDetailedDto;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private loadingService: LoadingService,
    private insuranceCompanyService: InsurancesCompanyApiService,
    private certificationService: CertificationsApiService
  ) {}

  ngOnInit(): void {
    this.addDocumentsToTenant();
  }

  addDocumentsToTenant(): void {
    this.certification.tenantCandidatureList.map((userCandidature, index) => {
      this.tenantDocuments(userCandidature.user.id, index);
    });
  }

  tenantDocuments(idUser: string, index: number): void {
    this.insuranceCompanyService
      .getDocuments('20', idUser)
      .subscribe((documents: DocumentDTO[]) => {
        this.certification.tenantCandidatureList[index].documents =
          this.verifyDocuments(documents);
      });
  }

  verifyDocuments(documents: DocumentDTO[]): DocumentDTO[] {
    const validatedDocument = documents.filter(
      (document: DocumentDTO) => document
    );
    return validatedDocument;
  }

  downloadReport(candidatureId: string): void {
    this.loadingService.presentSecondLoader('Generando pdf...', true);
    this.certificationService.getCertificationReport(candidatureId).subscribe(
      (data) => {
        const url = window.URL.createObjectURL(data);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
          alert(
            'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
          );
        }
        this.loadingService.dismissSecondLoader();
      },
      () => {
        this.presentToast(
          'No se puede generar informe para el propietario.',
          'danger'
        );
        this.loadingService.dismissSecondLoader();
      }
    );
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  launchViewDocuments(userDoc: UserCandidatureDto): void {
    if (userDoc.documents) {
      this.viewDocumentsFull(userDoc.documents);
    } else {
      this.presentToast(
        'No existe documentación para este inquilino',
        'danger'
      );
    }
  }

  async viewDocumentsFull(documents: DocumentDTO[]): Promise<void> {
    this.loadingService.presentSecondLoader(null, true);
    const modal = await this.modalController.create({
      component: MultipleDocumentModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        documentsData: documents,
        source: 'insuranceCompany'
      }
    });
    return await modal.present();
  }

  checkUserDocuments(documents: DocumentDTO[]): boolean {
    let hasUserDocuments = true;
    if (documents) {
      return documents.every((document) => {
        hasUserDocuments = this.checkDocument(document);
      });
    }
    return hasUserDocuments;
  }

  checkDocument(document: DocumentDTO): boolean {
    return document && document.id ? true : false;
  }
}
