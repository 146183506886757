export enum ChildRoutesEnum {
  ACTIVE = 'active',
  CANCELLED = 'cancelled'
}

import { FormControl } from '@angular/forms';

export enum TenantInsuranceType {
  TENANT_RC = 'TENANT_RC',
  ACCIDENTS = 'ACCIDENTS',
  OWNER_HOME = 'OWNER_HOME',
  CAR_INSURANCE = 'CAR_INSURANCE',
  MOTORBIKE_INSURANCE = 'MOTORBIKE_INSURANCE'
}

export enum TenantInsuranceOption {
  CONTRACTED = 'contracted',
  IN_PROGRESS = 'inProgress'
}

export interface InsuranceTypeModalForm {
  file: FormControl<File>;
  notes: FormControl<string>;
}

export interface DocusignTemplateForm {
  templateName: FormControl<string>;
  file: FormControl<File>;
  notes: FormControl<string>;
}
