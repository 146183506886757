import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { AssetUtilsPopoverOption } from '../../models/asset-utils-popover.model';

@Component({
  selector: 'el-buen-inquilino-asset-utils-popover',
  templateUrl: './asset-utils-popover.component.html'
})
export class AssetUtilsPopoverComponent implements OnInit {
  @Input() closedPage = false;
  @Input() userHasCalendar = false;
  options = AssetUtilsPopoverOption;
  showCalendarItem = false;

  constructor(private popoverController: PopoverController) {}

  ngOnInit(): void {
    this.setShowCalendarItem();
  }

  setShowCalendarItem(): void {
    this.showCalendarItem = !this.closedPage && this.userHasCalendar;
  }

  onClickUtilOptions(options: AssetUtilsPopoverOption): void {
    this.popoverController.dismiss({
      options
    });
  }
}
