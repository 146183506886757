import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomPopoverButtonRole } from '@core/models';
import { IonDatetime, PopoverController } from '@ionic/angular';
import { add, format, setMinutes } from 'date-fns';

@Component({
  selector: 'el-buen-inquilino-time-popover',
  templateUrl: './time-popover.component.html'
})
export class TimePopoverComponent implements OnInit {
  @ViewChild('datepicker') datepicker: IonDatetime;
  @Input() formDate: Date;
  @Input() private minHour = 0;
  @Input() private maxHour = 23;
  date = new Date().toISOString();
  hourValues: number[] = [];

  constructor(private popoverController: PopoverController) {}

  ngOnInit(): void {
    this.setDate();
    this.setHourValues();
  }

  dismiss(role: CustomPopoverButtonRole, data?: string): void {
    this.popoverController.dismiss(data, role);
  }

  cancel(): void {
    this.dismiss(CustomPopoverButtonRole.CANCEL, null);
  }

  async confirm(): Promise<void> {
    await this.datepicker.confirm();
    const time = format(new Date(this.datepicker.value as string), 'HH:mm');
    this.dismiss(CustomPopoverButtonRole.ACCEPT, time);
  }

  private setDate(): void {
    let date: Date;
    if (!!this.formDate) {
      date = this.formDate;
      date = add(date, { hours: 1 });
    } else {
      date = setMinutes(new Date(), 0);
      date = add(date, { hours: 1 });
    }
    this.date = date.toISOString();
  }

  private setHourValues(): void {
    for (let index = this.minHour; index <= this.maxHour; index++) {
      this.hourValues.push(index);
    }
  }
}
