import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DefaultUrlSerializer,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { StoreService } from '../../services/store/store.service';

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private storeService: StoreService
  ) {}

  canLoad(
    _route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn) {
      const lastUrlSetted = this.setLastUrl(segments);

      if (!lastUrlSetted) {
        this.navigationService.goLanding();
      }

      return false;
    }
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.canActivate(route, state);
  }

  private setLastUrl(segments: UrlSegment[]): boolean {
    let lastUrlSetted = false;

    if (segments[0].path === 'certifications') {
      const segment = segments.find((segment: UrlSegment) =>
        segment.parameterMap.has('id')
      );
      this.navigationService.goCertificationHome(
        segment.parameterMap.get('id')
      );
      lastUrlSetted = true;
    } else if (
      segments.some(
        (segment: UrlSegment) => segment.path === 'recommendation-form'
      )
    ) {
      this.storeService.setLastUrl(this.buildUrlFromSegment(segments));
      this.navigationService.goToEnterpriseLogin();
      lastUrlSetted = true;
    } else if (
      !this.navigationService.isLoginPage(this.buildUrlFromSegment(segments))
    ) {
      this.storeService.setLastUrl(this.buildUrlFromSegment(segments));
      this.navigationService.goLoginPage();
      lastUrlSetted = true;
    }

    return lastUrlSetted;
  }

  private buildUrlFromSegment(segments: UrlSegment[]): string {
    const urlTree: UrlTree = new DefaultUrlSerializer().parse(
      segments.map((seg: UrlSegment) => seg.path).join('/')
    );

    return urlTree.toString();
  }
}
