/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import {
  Auth,
  UserCredential,
  signInWithEmailAndPassword
} from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { MessageBean } from '@shared/utils/message-bean.utils';
import { Observable } from 'rxjs';

import { InsurancesCompanyApiService } from '../../api-services/insurances-company-api/insurances-company-api.service';
import { UsersApiService } from '../../api-services/users-api/users-api.service';
import { Credentials, InsuranceCompany } from '../../models';
import { LoadingService } from '../loading/loading.service';
import { NavigationService } from '../navigation/navigation.service';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { StoreService } from '../store/store.service';

@Injectable()
export class InsuranceCompanyAuthService {
  user: InsuranceCompany;
  credentials: Credentials;

  get token(): string | undefined {
    return this.storeService.getToken();
  }

  get isLoggedIn(): boolean {
    return !!this.token ? true : false;
  }

  constructor(
    private storeService: StoreService,
    private insuranceCompanyService: InsurancesCompanyApiService,
    private loadingService: LoadingService,
    private snackBarService: SnackBarService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private userService: UsersApiService,
    private auth: Auth
  ) {
    // TODO: quitar cuando se termine el desarrollo
    if (localStorage.getItem('companiesUser')) {
      this.user = JSON.parse(
        localStorage.getItem('companiesUser')
      ) as InsuranceCompany;
    }
  }

  login(email: string, password: string): void {
    this.snackBarService.hide();
    this.loadingService.presentLoading(null);
    if (this.isUserLogged()) {
      this.processLogin(this.user);
    } else {
      this.processLoginFirebase(email, password);
    }
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const credentials = (this.credentials = this.storeService.getCredentials());
    if (!this.user?.id) {
      this.user = null;
    }
    if (credentials?.token) {
      return true;
    } else {
      this.navigationService.goToLoginInsuranceCompanyPage();
    }
  }

  clearUser(): void {
    this.user = null;
  }

  isUserLogged(): boolean {
    return (
      this.user !== null &&
      this.user !== undefined &&
      this.user?.id !== null &&
      this.user?.id !== undefined &&
      this.storeService.getCredentials()?.token !== null
    );
  }

  recoveryPassword(email: string): void {
    this.snackBarService.hide();
    this.userService
      .sendPasswordResetEmail(email)
      .toPromise()
      .then(() => {
        this.snackBarService.showAutoHide(
          MessageBean.buildMessageWithTranslation(
            'application.validations.errors.recovery-password',
            this.translateService
          ),
          5000
        );
        this.loadingService.dismissLoading();
      })
      .catch(() => this.processError('auth/invalid-email'));
  }

  private processLoginFirebase(email: string, password: string): void {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(
        (dataFirebaseLogin) => {
          this.processToken(dataFirebaseLogin);
        },
        (error) => this.processError(error.code)
      )
      .catch((error) => this.processError(error));
  }

  private processToken(dataFirebaseLogin: UserCredential): void {
    if (dataFirebaseLogin && dataFirebaseLogin.user) {
      this.auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          this.storeService.setToken(idToken);
          this.processUser(dataFirebaseLogin);
        })
        .catch((error) => this.processError(error));
    } else {
      this.processError('error');
    }
  }

  private processUser(dataFirebaseLogin: UserCredential): void {
    this.insuranceCompanyService.getUser(dataFirebaseLogin.user.uid).then(
      (userServiceData) => {
        this.storeService.setTokenRefresh(dataFirebaseLogin.user.refreshToken);
        this.processLogin(userServiceData);
      },
      (error) => this.processError(error)
    );
  }

  private processLogin(user: InsuranceCompany): void {
    this.user = user;
    // TODO: quitar cuando se termine el desarrollo
    localStorage.setItem('companiesUser', JSON.stringify(user));
    const credentials = this.storeService.getCredentials();
    this.storeService.setCredentials(credentials);
    this.loadingService.dismissLoading();
    this.navigationService.goToInsuranceCompanyHome(user.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private processError(error: string): void {
    this.user = null;
    this.storeService.removeCredentials();
    this.loadingService.dismissLoading();
    this.snackBarService.show(
      MessageBean.buildMessage('Usuario o contraseña incorrectas'),
      'danger'
    );
  }
}
