import { MonthSelect } from '../models/month-year-selects.model';

export function calculateMonthDefaultValue(): string {
  let month = String(new Date().getMonth() + 1);
  if (month.length === 1) {
    month = '0' + month;
  }
  return month;
}

export function calculateYearDefaultValue(): number {
  const max = new Date().getFullYear();
  const min = max - 2;
  const yearsForDefault: number[] = [];
  for (let i = max; i >= min; i--) {
    yearsForDefault.push(i);
  }
  return yearsForDefault[0];
}

export function generateArrayOfYears(): number[] {
  const max = new Date().getFullYear();
  const min = max - 2;
  const years: number[] = [];
  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export const months: MonthSelect[] = [
  {
    value: '01',
    label: 'enums.months.jan'
  },
  {
    value: '02',
    label: 'enums.months.feb'
  },
  {
    value: '03',
    label: 'enums.months.mar'
  },
  {
    value: '04',
    label: 'enums.months.apr'
  },
  {
    value: '05',
    label: 'enums.months.may'
  },
  {
    value: '06',
    label: 'enums.months.jun'
  },
  {
    value: '07',
    label: 'enums.months.jul'
  },
  {
    value: '08',
    label: 'enums.months.aug'
  },
  {
    value: '09',
    label: 'enums.months.sep'
  },
  {
    value: '10',
    label: 'enums.months.oct'
  },
  {
    value: '11',
    label: 'enums.months.nov'
  },
  {
    value: '12',
    label: 'enums.months.dec'
  }
];
