/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import {
  AttachDocument,
  ChatThread,
  DocumentIncidenceDTO,
  Incidence,
  IncidenceFromEbiDto
} from '../../models';

@Injectable()
export class IncidencesApiService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.spaces;
  }

  getIncidencesByCandidature(candidatureId: string): Observable<Incidence[]> {
    const endpoint = `${this.url}/incidences/${candidatureId}/by-candidature`;

    return this.http.get<Incidence[]>(endpoint);
  }

  changeIncidenceStatus(incidence: Incidence): Observable<void> {
    const endpoint = `${this.url}/incidences/${incidence.id}/status`;

    return this.http.put<void>(endpoint, { status: incidence.status });
  }

  getIncidencesListByHomeowner(): Observable<Incidence[]> {
    const endpoint = `${this.url}/incidences/homeowner`;

    return this.http.get<Incidence[]>(endpoint);
  }

  createIncidence(params: IncidenceFromEbiDto): Observable<Incidence> {
    const endpoint = `${this.url}/incidences`;

    return this.http.post<Incidence>(endpoint, params);
  }

  addComment(incidenceId: string, comment: ChatThread): Observable<Incidence> {
    const endpoint = `${this.url}/incidences/${incidenceId}/comments/`;

    return this.http.patch<Incidence>(endpoint, comment);
  }

  attachDocument(
    params: { name: string; file: any },
    idIncidence: string
  ): Observable<AttachDocument> {
    const endpoint = `${this.url}/incidences/${idIncidence}/attach`;

    const formData = new FormData();
    formData.append('name', params.name);
    formData.append('file', params.file);

    return this.http.post<AttachDocument>(endpoint, formData);
  }

  deleteAttachDocument(
    incidenceId: string,
    documentId: string
  ): Observable<void> {
    const endpoint = `${this.url}/incidences/${incidenceId}/attach-document/${documentId}`;

    return this.http.delete<void>(endpoint);
  }

  getDocument(
    incidenceId: string,
    documentId: string
  ): Observable<DocumentIncidenceDTO> {
    const endpoint = `${this.url}/incidences/${incidenceId}/document/${documentId}`;

    return this.http.get<DocumentIncidenceDTO>(endpoint);
  }
}
