/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  DocumentDTO,
  ProfDataDto,
  ProfileDocumentEnum,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NoValidatedUserInfoComponent } from '@private/modules/tenant/modules/home/components/no-validated-user-info/no-validated-user-info.component';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';

import { BiometricPopoverComponent } from '../biometric-popover/biometric-popover.component';

@Component({
  selector: 'el-buen-inquilino-card-profile',
  templateUrl: './card-profile.component.html'
})
export class CardProfileComponent implements OnInit, OnChanges {
  @Input() user: User;
  private id: string;
  idContact: string;
  profileImage$: Observable<SafeUrl>;
  @Input() profData: ProfDataDto;

  constructor(
    private popoverController: PopoverController,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    private usersServices: UsersApiService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    if (this.user) {
      this.getProfileImage();
    }
    this.id = this.router.url.split('/')[this.router.url.split('/').length - 2];
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.idContact = params.idContact as string;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && !changes.user.firstChange) {
      this.user = changes.user.currentValue;
      this.getProfileImage();
    }
  }

  getProfileImage(): void {
    this.profileImage$ = this.utilsService.getImage(this.user.profilePhotoId);
  }

  getName(): string {
    const name = [];
    if (this.user) {
      name.push(this.user.firstname ? this.user.firstname : '');
      name.push(this.user.surname1 ? this.user.surname1 : '');
      name.push(this.user.surname2 ? this.user.surname2 : '');
    }
    return name.join(' ');
  }

  getEmail(): string {
    return this.user?.email;
  }

  async presentPopover(ev: any): Promise<void> {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      componentProps: {
        user: this.user
      },
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then((d: any) => {
      if (d && d.data && d.data.saving) {
        this.usersServices
          .getUserObservable(this.user.id)
          .pipe(first())
          .subscribe((user) => {
            this.user = user;
            this.getProfileImage();
          });
      }
    });
    return await popover.present();
  }

  isMismatchUser(): boolean {
    let mismatchUser = false;
    if (this.authenticationService.isUserLogged()) {
      if (this.idContact) {
        mismatchUser = this.authenticationService.user.id === this.idContact;
      } else {
        mismatchUser = this.authenticationService.user.id === this.id;
      }
    }
    return mismatchUser;
  }

  get linkedIn(): string {
    return this.profData?.linkedIn
      ?.replace('http://', '')
      .replace('https://', '');
  }

  getBiometricColor(): string {
    if (this.user?.biometricResult === 'PENDING') {
      return 'secondary';
    } else {
    }
    return 'danger';
  }

  async showPopover(event: any): Promise<void> {
    const popover = await this.popoverController.create({
      component: BiometricPopoverComponent,
      componentProps: { option: this.user?.biometricResult },
      event
    });
    await popover.present();
  }

  async showNoVerifiedModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: NoValidatedUserInfoComponent,
      showBackdrop: true,
      componentProps: {
        informative: true
      }
    });
    modal.present();
  }
}

@Component({
  selector: 'el-buen-inquilino-card-profile-popover',
  template: `
    <ion-list>
      <ion-item button (click)="file.click()">Cambiar imagen</ion-item>
      <input
        (change)="changeListener($event)"
        #file
        hidden
        type="file"
        name="file"
        id="file"
        class="fileInput"
      />
    </ion-list>
  `
})
export class PopoverComponent {
  file: File;
  @Input() user: User;
  constructor(
    private docService: DocumentsApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private popoverController: PopoverController
  ) {}

  uploadImage(): void {
    const docData: DocumentDTO = {
      typeDocument: ProfileDocumentEnum.PROFILE_PHOTO,
      name: this.file.name,
      file: this.file,
      tenantId: this.user.id
    };
    this.docService
      .createDocument(docData, this.file)
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.presentToast(
            this.translate.instant('components.card-profile.imageChanged')
          );
          this.dismissPopover(true);
        },
        () => {
          this.presentToast(
            this.translate.instant(
              'components.card-profile.imageChanged_error'
            ),
            'danger'
          );
        }
      );
  }

  async presentToast(message: string, color = 'success'): Promise<void> {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color,
      duration: 2000
    });
    toast.present();
  }

  changeListener($event): void {
    this.file = $event.target.files[0];
    this.uploadImage();
  }

  dismissPopover(saving: boolean): void {
    this.popoverController.dismiss({
      saving
    });
  }
}
