import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IncofisaAuthService } from '@core/services/auth/incofisa-auth.service';
import { Observable } from 'rxjs';

import { NavigationService } from '../../services/navigation/navigation.service';

@Injectable()
export class BackofficeGuard {
  constructor(
    private incofisaAuthService: IncofisaAuthService,
    private navigationService: NavigationService
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.incofisaAuthService.isLoggedIn) {
      this.navigationService.goToLoginBackoffice();
      return false;
    }

    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.incofisaAuthService.canActivate(route, state);
  }
}
