/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AMLCheck,
  AnalysisUser,
  Candidature,
  GetRequestAnalysisDto,
  Incidence,
  RequestAnalysisByCandidature,
  RequestAnalysisDto,
  SubjectAnalysis,
  User
} from '../../models';

@Injectable()
export class RequestAnalysisApiService {
  requestAnalysisListRawData: RequestAnalysisByCandidature[] = [];
  requestAnalysisClosedListRawData: RequestAnalysisByCandidature[] = [];
  requestAnalysesUser: AnalysisUser[] = [];
  unresolvedIncidences: Incidence[] = [];
  requestAnalysisWithConditioning: RequestAnalysisDto[] = [];
  url: string;
  urlCloudFunctions: string;
  private _changesRequestAnalysisListRawData: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  onChangesRequestAnalysisList$ =
    this._changesRequestAnalysisListRawData.asObservable();

  constructor(private http: HttpClient) {
    this.url = environment.services.users;
    this.urlCloudFunctions = environment.services.ebiCloudFunctions;
  }

  getAnalysisByDateFilter(
    dateFrom: string,
    dateTo: string,
    finalized: boolean = false
  ): Observable<RequestAnalysisByCandidature[]> {
    let from: string = moment(new Date()).add(-8, 'days').format('DD-MM-YYYY');
    let to: string = moment(new Date()).add(2, 'days').format('DD-MM-YYYY');
    if (
      dateFrom !== null &&
      dateFrom !== '' &&
      dateTo !== null &&
      dateTo !== ''
    ) {
      from = dateFrom;
      to = dateTo;
    }
    return this.http
      .get<
        RequestAnalysisByCandidature[]
      >(`${this.urlCloudFunctions}/requestList/${from}/${to}/${finalized}`)
      .pipe(
        map((resp) =>
          resp.map((request: RequestAnalysisByCandidature) => {
            if (request?.candidature) {
              request.candidature.archived =
                request?.candidature?.archived || false;
              return request;
            } else {
              return new RequestAnalysisByCandidature();
            }
          })
        )
      );
  }

  getRequestAnalysesByCandidature(
    candidatureId: string
  ): Observable<RequestAnalysisByCandidature> {
    return this.http.get<RequestAnalysisByCandidature>(
      this.url + '/requests-analysis/candidatures/' + candidatureId
    );
  }

  getAnalysisByOwner(
    homeownerId: string,
    tenantId: string,
    finalized: boolean = false
  ): Observable<RequestAnalysisByCandidature[]> {
    return this.http
      .get<
        RequestAnalysisByCandidature[]
      >(`${this.urlCloudFunctions}/requestListByUserId/${homeownerId}/${tenantId}/${finalized}`)
      .pipe(
        map((resp) =>
          resp.map((request: RequestAnalysisByCandidature) => {
            if (request?.candidature) {
              request.candidature.archived =
                request?.candidature?.archived || false;
              return request;
            } else {
              const newRequestAnalysis = new RequestAnalysisByCandidature();
              newRequestAnalysis.candidature = new Candidature();
              newRequestAnalysis.candidature.archived = false;
              return newRequestAnalysis;
            }
          })
        )
      );
  }

  getRequestAnalysisById(
    requestAnalysis: string
  ): Observable<GetRequestAnalysisDto> {
    return this.http.get<GetRequestAnalysisDto>(
      this.url + '/requests-analysis/' + requestAnalysis
    );
  }

  fillAnalysisAndUser(requestAnalys: RequestAnalysisDto[]): AnalysisUser[] {
    return requestAnalys.map((req) => {
      return new AnalysisUser(
        req.id,
        req.userId,
        req.endDate,
        req?.rentalPrice
      );
    });
  }

  searchByText(
    seed: string,
    type: string
  ): Observable<RequestAnalysisByCandidature[]> {
    return this.http
      .get<
        RequestAnalysisByCandidature[]
      >(`${this.urlCloudFunctions}/searchAnalyses/${type}/${seed}`)
      .pipe(
        map((resp) =>
          resp.map((request: RequestAnalysisByCandidature) => {
            if (request?.candidature) {
              request.candidature.archived =
                request?.candidature?.archived || false;
              return request;
            } else {
              const newRequestAnalysis = new RequestAnalysisByCandidature();
              newRequestAnalysis.candidature = new Candidature();
              newRequestAnalysis.candidature.archived = false;
              return newRequestAnalysis;
            }
          })
        )
      );
  }

  getSubjectAnalysis(user: User): Observable<SubjectAnalysis> {
    const obj = {
      nif: user.dni,
      name: user.firstName,
      surname: user.surname1,
      secondSurname: user.surname2
    };

    return this.http.post<SubjectAnalysis>(
      this.url + '/requests-analysis/' + user.id + '/subject-analysis',
      obj
    );
  }

  getUserSubjectAnalysis(userId: string): Observable<SubjectAnalysis> {
    return this.http.get<SubjectAnalysis>(
      this.url + '/requests-analysis/' + userId + '/subject-analysis'
    );
  }

  getAMLAnalysis(userId: string): Observable<AMLCheck> {
    return this.http.get<AMLCheck>(
      this.url + '/requests-analysis/' + userId + '/aml-check'
    );
  }

  notifyChanges(): void {
    this._changesRequestAnalysisListRawData.next(0);
  }
}
