import { Pipe, PipeTransform } from '@angular/core';
import { DocumentDTO } from '@core/models';

@Pipe({
  name: 'showDocumentInfoRow'
})
export class ShowDocumentInfoRowPipe implements PipeTransform {
  transform(document: DocumentDTO): unknown {
    return (
      !!document.birthDate ||
      !!document.expirationDate ||
      !!document.netAmount ||
      !!document.date ||
      !!document.taxBase ||
      !!document.year
    );
  }
}
